import useSWR from 'swr';
import api from 'services/api';
import { HRL } from 'models/hrl';

export const HlrFetch = () => {
  const { data } = useSWR<HRL[]>('hlrs', async (): Promise<HRL[]> => {
    const response = await api.get(`/core/hlrs`);
    return response.data as HRL[];
  });
  return { data };
};
