import { useToast } from 'hooks/toast';
import useBreakpoint from 'hooks/useBreakpoint';
import { useCallback, useState } from 'react';
import api from 'services/api';
import { LPBenefit } from 'models/lpBenefits';
import DeleteModal from 'pages/CmsLP/components/DeleteModal';
import { SwipeableDrawer } from '@mui/material';
import ContainerList from 'pages/CmsLP/components/ContainerList';
import Item from './Item';
import MobileItem from './MobileItem';
import { Header } from './styles';
import EditBenefit from '../EditBenefit';

interface BenefitListProps {
  benefits?: LPBenefit[];
  setBenefits(benefits: LPBenefit[]): void;
  updateBenefits?(benefits: LPBenefit[], showRevalid?: boolean): void;
}

interface BodyProps {
  benefits: LPBenefit[];
  setBenefits(benefits: LPBenefit[]): void;
  isMobile: boolean;
  setBenefitSelected(value: LPBenefit): void;
  setShowBenefitEdition(value: boolean): void;
  handleOpenModalDelete(): void;
}

interface LoadingListProps {
  isTableOrLess: boolean;
}

function LoadingList({ isTableOrLess }: LoadingListProps) {
  return isTableOrLess ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({
  benefits,
  setBenefits,
  isMobile,
  setBenefitSelected,
  setShowBenefitEdition,
  handleOpenModalDelete,
}: BodyProps) {
  const handleSaveOrderBenefits = (
    newList: { id: number; ordem: number }[],
  ) => {
    const updatedItems = newList.map((item, index) => ({
      id: item.id,
      ordem: index + 1,
    }));

    api
      .put('/core/lp-beneficios/ordem', updatedItems)
      .then(({ data: response }) => {
        setBenefits(response);
      });
  };

  const benefitUp = (item: LPBenefit) => {
    const index = benefits.findIndex(i => i.id === item.id);
    if (index > 0) {
      const newList = [...benefits];
      newList[index] = benefits[index - 1];
      newList[index - 1] = item;

      handleSaveOrderBenefits(newList);
    }
  };

  const benefitDown = (item: LPBenefit) => {
    const index = benefits.findIndex(i => i.id === item.id);
    if (index < benefits.length - 1) {
      const newList = [...benefits];
      newList[index] = benefits[index + 1];
      newList[index + 1] = item;

      handleSaveOrderBenefits(newList);
    }
  };

  return (
    <div>
      {benefits.length ? (
        benefits.map(data =>
          isMobile ? (
            <MobileItem
              key={data.id}
              benefit={data}
              lengthBenefits={benefits.length}
              setBenefitSelected={setBenefitSelected}
              setShowBenefitEdition={setShowBenefitEdition}
              handleOpenModalDelete={handleOpenModalDelete}
              benefitUp={benefitUp}
              benefitDown={benefitDown}
            />
          ) : (
            <Item
              key={data.id}
              benefit={data}
              lengthBenefits={benefits.length}
              setBenefitSelected={setBenefitSelected}
              setShowBenefitEdition={setShowBenefitEdition}
              handleOpenModalDelete={handleOpenModalDelete}
              benefitUp={benefitUp}
              benefitDown={benefitDown}
            />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhum benefício encontrado</h3>
      )}
    </div>
  );
}

function BenefitList({
  benefits,
  setBenefits,
  updateBenefits,
}: BenefitListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const [benefitSelected, setBenefitSelected] = useState<LPBenefit>();
  const [showBenefitEdition, setShowBenefitEdition] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const updateFaq = useCallback(
    (faq: LPBenefit) => {
      if (!benefits) return;
      const newFaq = benefits.map(item => {
        if (item.id === faq.id) {
          return { ...item, ...faq };
        }
        return item;
      });
      updateBenefits && updateBenefits(newFaq, true);
    },
    [updateBenefits, benefits],
  );

  const handleOpen = () => {
    setOpenModalDelete(true);
  };

  const handleClose = () => {
    setOpenModalDelete(false);
  };

  const handleDelete = useCallback(() => {
    setLoading(true);
    api
      .delete(`/core/lp-beneficios/${benefitSelected?.id}`)
      .then(() => {
        updateBenefits &&
          benefits &&
          updateBenefits(
            benefits.filter(benefit => benefit.id !== benefitSelected?.id),
            true,
          );

        addToast({
          type: 'success',
          title: 'Benefício excluído',
          description: `O benefício ${benefitSelected?.titulo} foi excluído com sucesso.`,
        });

        setLoading(false);
        handleClose();
      })
      .catch(error => {
        let { message } = error.data;

        if (!message) {
          message = error.data.errors[0]?.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Remover benefício ',
            description: error?.data?.message,
          });
        setLoading(false);
        handleClose();
      });
  }, [benefitSelected, updateBenefits, benefits, addToast]);

  return (
    <ContainerList>
      {!isTableOrLess && (
        <Header>
          <span>Ordem</span>
          <span>Título</span>
        </Header>
      )}

      {benefits ? (
        <Body
          benefits={benefits}
          setBenefits={setBenefits}
          isMobile={isTableOrLess}
          setBenefitSelected={setBenefitSelected}
          setShowBenefitEdition={setShowBenefitEdition}
          handleOpenModalDelete={handleOpen}
        />
      ) : (
        <LoadingList isTableOrLess={isTableOrLess} />
      )}

      <SwipeableDrawer
        anchor="right"
        onClose={() => {
          setBenefitSelected(undefined);
          setShowBenefitEdition(false);
        }}
        onOpen={() => setShowBenefitEdition(true)}
        open={showBenefitEdition}
      >
        {benefitSelected && (
          <EditBenefit
            data={benefitSelected}
            closeDrawer={() => {
              setBenefitSelected(undefined);
              setShowBenefitEdition(false);
            }}
            updateBenefits={updateFaq}
          />
        )}
      </SwipeableDrawer>
      <DeleteModal
        title="Deseja mesmo excluir o benefício?"
        loading={loading}
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        handleDelete={handleDelete}
        handleClose={handleClose}
      />
    </ContainerList>
  );
}

export default BenefitList;
