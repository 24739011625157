import { Box, Button, CardHeader, TextField } from '@mui/material';
import { ButtonProgress, Card, Header } from 'components/StyledComponents';
import { useCallback, useState } from 'react';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

function UtmDistributionByID() {
  const [medium, setMedium] = useState('');
  const [campaign, setCampaign] = useState('');
  const [ids, setIds] = useState('');
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const handleOnChangeMedium = (value: string) => {
    setMedium(value);

    if (value === 'organico') {
      setCampaign('organico');
    } else if (campaign === 'organico') {
      setCampaign('');
    }
  };
  function clearForm() {
    setIds('');
    setMedium('');
    setCampaign('');
  }

  const handleRequest = useCallback(() => {
    const body = {
      utm_medium: medium,
      utm_campaign: campaign,
      ids,
    };

    setLoading(true);

    api
      .put(`/core/vendas/atualizar-utms-por-id`, body)
      .then(() => {
        addToast({
          type: 'success',
          title: 'UTMs Atualizados',
          description:
            'As vendas cujos IDs foram passados foram atualizados com sucesso!',
        });
        clearForm();
      })
      .catch(error => {
        let { message } = error.data;

        if (!message) {
          message = error.data.errors[0]?.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao atualizar UTMs!',
            description: message,
          });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, campaign, ids, medium]);

  return (
    <Card>
      <Header>
        <CardHeader
          title="Distribuição UTM por ID"
          subheader="Painel de transferência dos valores de UTM usando os IDs das vendas."
        />
      </Header>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 2,
          marginTop: 4,
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <TextField
            label="UTM Medium"
            variant="outlined"
            select
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{ native: true }}
            size="small"
            onChange={e => handleOnChangeMedium(e.target.value)}
            value={medium}
            sx={{
              width: 260,
            }}
          >
            <option value="" disabled>
              Selecionar
            </option>
            <option value="sms">SMS</option>
            <option value="sms_plus">SMS Plus</option>
            <option value="whatsapp">Whatsapp</option>
            <option value="organico">Orgânico</option>
            <option value="landingpage">Landing Page</option>
            <option value="reaproveitamento">Reaproveitamento</option>
            <option value="email">E-mail</option>
          </TextField>
          <TextField
            label="UTM Campaign"
            variant="outlined"
            size="small"
            onChange={e => {
              setCampaign(e.target.value);
            }}
            value={campaign}
            sx={{
              width: 260,
            }}
            disabled={medium === 'organico'}
          />
        </Box>
        <TextField
          id="Dados"
          label="Digite os IDs"
          onChange={e => {
            setIds(e.target.value);
          }}
          value={ids}
          multiline
          fullWidth
          rows={4}
          helperText="Utilize vírgula para inserir múltiplos IDs"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          paddingLeft: 2,
          marginTop: 'auto',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={!medium || !ids || loading}
          onClick={() => {
            handleRequest();
          }}
        >
          Salvar
          {loading && <ButtonProgress size={24} />}
        </Button>
      </Box>
    </Card>
  );
}
export default UtmDistributionByID;
