import { ReactNode } from 'react';
import cardBenefit from 'assets/card_benefit_image.svg';
import {
  CardBenefitText,
  CardBenefitHeader,
  CardBenefitComposition,
  CardBenefitImage,
} from './styles';
import { LoadingInfo } from '../styles';

interface CardBenefitProps {
  image: string;
  title: string;
  children: ReactNode;
}

function CardBenefit({ image, title, children }: CardBenefitProps) {
  return (
    <CardBenefitComposition>
      <CardBenefitHeader>
        {title || <LoadingInfo width={144} />}
      </CardBenefitHeader>
      <CardBenefitImage>
        {image ? (
          <img src={image} width={270} height={270} alt="Card Benefícios" />
        ) : (
          <img
            src={cardBenefit}
            width={206}
            height={175}
            alt="Card Benefícios"
          />
        )}
      </CardBenefitImage>
      <CardBenefitText>{children}</CardBenefitText>
    </CardBenefitComposition>
  );
}

export default CardBenefit;
