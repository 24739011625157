import {
  CountStarsTotalizer,
  SatisfactionSurvey,
  SatisfactionSurveyResponse,
} from 'models/SatisfactionSurvey';
import api from 'services/api';
import useSWR from 'swr';

interface SatisfactionSurveyProps {
  page: number;
  nota: string | null;
  startDate: string;
  endDate: string;
  origem: string | null;
  onlyWithComment?: boolean;
}

interface Params {
  nota: string | null;
  startDate?: string;
  endDate?: string;
  origem: string | null;
  onlyWithComment?: boolean;
}

export const SatisfactionSurveyFetch = ({
  page,
  nota,
  startDate,
  endDate,
  origem,
  onlyWithComment,
}: SatisfactionSurveyProps) => {
  const { data } = useSWR(
    `satisfaction_surveys_${page}_${nota}_${startDate}_${endDate}_${origem}_${onlyWithComment}`,
    async (): Promise<SatisfactionSurveyResponse> => {
      const params: Params = {
        nota,
        startDate,
        endDate,
        origem,
        onlyWithComment,
      };

      const response = await api.get(
        `/core/pesquisa-satisfacao/todas/${page}`,
        {
          params,
        },
      );

      const paginationInfo = {
        currentPage: parseInt(response.headers['x-current-page'], 10),
        lastPage: parseInt(response.headers['x-last-page'], 10),
        perPage: parseInt(response.headers['x-per-page'], 10),
        total: parseInt(response.headers['x-total-count'], 10),
      };
      const satisfactionSurveys = response.data as SatisfactionSurvey[];

      return {
        satisfactionSurveys,
        paginationInfo,
      } as SatisfactionSurveyResponse;
    },
  );

  return { data };
};

interface TotalizerProps {
  nota: string | null;
  startDate?: string;
  endDate?: string;
  origem: string | null;
  onlyWithComment?: boolean;
}

export const AverageFetch = ({
  nota,
  startDate,
  endDate,
  origem,
  onlyWithComment,
}: TotalizerProps) => {
  const { data } = useSWR(
    `average_${nota}_${startDate}_${endDate}_${origem}_${onlyWithComment}`,
    async (): Promise<string> => {
      const params: Params = {
        nota,
        startDate,
        endDate,
        origem,
        onlyWithComment,
      };

      const response = await api.get(
        `/core/pesquisa-satisfacao/totalizador/media`,
        {
          params,
        },
      );

      return response.data as string;
    },
  );

  return data;
};

export const WithEvaluationFetch = ({
  nota,
  startDate,
  endDate,
  origem,
  onlyWithComment,
}: TotalizerProps) => {
  const { data } = useSWR(
    `with_evaluation_${nota}_${startDate}_${endDate}${origem}_${onlyWithComment}`,
    async (): Promise<string> => {
      const params: Params = {
        nota,
        startDate,
        endDate,
        origem,
        onlyWithComment,
      };

      const response = await api.get(
        `/core/pesquisa-satisfacao/totalizador/com-avaliacao`,
        {
          params,
        },
      );

      return response.data as string;
    },
  );

  return data;
};

export const NoEvaluationFetch = ({
  nota,
  startDate,
  endDate,
  origem,
  onlyWithComment,
}: TotalizerProps) => {
  const { data } = useSWR(
    `no_evaluation_${nota}_${startDate}_${endDate}_${origem}_${onlyWithComment}`,
    async (): Promise<string> => {
      const params: Params = {
        nota,
        startDate,
        endDate,
        origem,
        onlyWithComment,
      };

      const response = await api.get(
        `/core/pesquisa-satisfacao/totalizador/sem-avaliacao`,
        {
          params,
        },
      );

      return response.data as string;
    },
  );

  return data;
};

export const WithCommentFetch = ({
  nota,
  startDate,
  endDate,
  origem,
  onlyWithComment,
}: TotalizerProps) => {
  const { data } = useSWR(
    `with_comment_${nota}_${startDate}_${endDate}_${origem}_${onlyWithComment}`,
    async (): Promise<string> => {
      const params: Params = {
        nota,
        startDate,
        endDate,
        origem,
        onlyWithComment,
      };

      const response = await api.get(
        `/core/pesquisa-satisfacao/totalizador/com-comentario`,
        {
          params,
        },
      );

      return response.data as string;
    },
  );

  return data;
};

export const CountStarsFetch = ({
  nota,
  startDate,
  endDate,
  origem,
  onlyWithComment,
}: TotalizerProps) => {
  const { data } = useSWR(
    `stars_${nota}_${startDate}_${endDate}_${origem}_${onlyWithComment}`,
    async (): Promise<CountStarsTotalizer> => {
      const params: Params = {
        nota,
        startDate,
        endDate,
        origem,
        onlyWithComment,
      };

      const response = await api.get(
        `/core/pesquisa-satisfacao/totalizador/notas`,
        {
          params,
        },
      );
      const totalizer = response.data as CountStarsTotalizer;

      return totalizer;
    },
  );

  return { data };
};
