import { Button, FormControl, TextField } from '@mui/material';
import { useToast } from 'hooks/toast';
import { Setup } from 'models/setup';
import { useState } from 'react';
import api from 'services/api';
import { SaveButton } from '../NormalForm/styles';

interface EnumNormalFormProps {
  setup: Setup;
  closeDrawer(): void;
}

function EnumNormalForm({ setup, closeDrawer }: EnumNormalFormProps) {
  const [setupValue, setSetupValue] = useState(setup.value);

  const { addToast } = useToast();

  const handleSave = () => {
    api
      .put(`api/setups/${setup.key}`, {
        value: setupValue.toString(),
      })
      .then(() => {
        addToast({
          type: 'success',
          title: setup.title,
          description: 'Setup salvo com sucesso',
        });

        closeDrawer();
      })
      .catch(err => {
        let message = err.data.errors[0]?.message;
        if (!message) {
          message = err.data.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro!',
            description: message,
          });
      });
  };

  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      style={{ paddingBottom: 30 }}
    >
      <TextField
        label="Selecione"
        variant="outlined"
        value={setupValue}
        SelectProps={{ native: true }}
        select
        InputLabelProps={{
          shrink: true,
        }}
        onChange={e => setSetupValue(e.target.value)}
        size="small"
        fullWidth
      >
        <option value="" disabled>
          Selecione
        </option>
        {typeof setup.options !== 'string' &&
          setup.options.map(val => (
            <option key={val} value={val}>
              {val}
            </option>
          ))}
      </TextField>
      <SaveButton>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          Salvar
        </Button>
      </SaveButton>
    </FormControl>
  );
}

export default EnumNormalForm;
