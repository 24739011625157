import styled from 'styled-components';

interface HeaderProps {
  isDesktop: boolean;
}

export const Container = styled.div`
  margin-top: 15px;
  width: 100%;
  border-collapse: collapse;
`;

export const TopBar = styled.div<HeaderProps>`
  display: flex;

  justify-content: space-between;
  flex-direction: ${props => (props.isDesktop ? 'row' : 'column')};
  gap: ${props => !props.isDesktop && '30px'};
`;

export const Content = styled.div`
  width: 100%;
  padding: 0 20px;
`;

export const BoxHeader = styled.div<HeaderProps>`
  display: flex;
  flex-direction: ${props => (props.isDesktop ? 'row' : 'column')};
  margin-right: ${props => (props.isDesktop ? '16px' : '32px')};
`;
