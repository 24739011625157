import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  padding: 0px 16px 16px 0px;
  justify-content: space-between;
  align-items: center;

  button {
    color: #050c27;
    width: 169px;
    height: 36px;
    font-size: 14px;
    font-weight: 400;
  }
`;
