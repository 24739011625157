import { useStylesModal } from 'components/UseStylesModal';
import { useCallback, useState } from 'react';
import { Button, DialogActions, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { ButtonProgress } from 'components/StyledComponents';
import { ModalCard } from 'pages/CmsLP/components/DeleteModal/styles';

interface DispatchImportBaseProps {
  baseId: number;
  fetchBaseData(): void;
}
function DispatchImportBase({
  baseId,
  fetchBaseData,
}: DispatchImportBaseProps) {
  const classes = useStylesModal();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const handleClose = () => {
    setOpen(false);
  };

  const handleDispatchImportBase = useCallback(() => {
    setLoading(true);
    api
      .put(`/core/bases-clientes-impactar/processar-base/${baseId}`)
      .then(() => {
        fetchBaseData();
        addToast({
          type: 'success',
          title: 'Importação Disparada',
          description:
            'O processo de importação da base foi disparado com sucesso.',
        });
        setLoading(false);
        handleClose();
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro ao disparar importação',
          description:
            error.data && error.data.message
              ? error.data.message
              : error.message,
        });
        setLoading(false);
        handleClose();
      });
  }, [baseId, fetchBaseData, addToast]);

  return (
    <>
      <div style={{ marginLeft: 'auto' }}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
          disabled={loading}
        >
          Iniciar Importação
          {loading && <ButtonProgress size={24} />}
        </Button>
      </div>
      <Modal
        aria-labelledby="tile-modal"
        aria-describedby="modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={open}>
          <ModalCard>
            <Typography>
              Você deseja realmente iniciar a importação desta base?
            </Typography>
            <DialogActions className={classes.dialogActions}>
              <Button variant="outlined" onClick={() => handleClose()}>
                Voltar
              </Button>
              <Button
                onClick={handleDispatchImportBase}
                variant="contained"
                disabled={loading}
              >
                Iniciar Importação
                {loading && <ButtonProgress size={24} />}
              </Button>
            </DialogActions>
          </ModalCard>
        </Fade>
      </Modal>
    </>
  );
}

export default DispatchImportBase;
