import styled from 'styled-components';

interface ContainerProps {
  isDesktop: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  padding: ${props =>
    props.isDesktop ? '15px 0px 15px 5px ' : '15px 0px 15px 0px '};
  width: 100%;
`;
