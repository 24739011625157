import create from 'zustand';

type simcardStockStore = {
  hlrSelected: string;
  setHlrSelected(hlr: string): void;
  batchSelected: number;
  setBatchSelected(batch: number): void;
  showActiveDialog: boolean;
  setShowActiveDialog(show: boolean): void;
  showDeactivateDialog: boolean;
  setShowDeactivateDialog(show: boolean): void;
  showDeleteDialog: boolean;
  setShowDeleteDialog(show: boolean): void;
  showSaleInfo: boolean;
  setShowSaleInfo(show: boolean): void;
  simCardSaleselected: number;
  setSimCardSaleselected(simCardSaleselected: number): void;
};

export const useSimcardStock = create<simcardStockStore>(set => ({
  hlrSelected: '',
  setHlrSelected(hlr: string) {
    set(state => ({ ...state, hlrSelected: hlr }));
  },
  batchSelected: 0,
  setBatchSelected(batch: number) {
    set(state => ({ ...state, batchSelected: batch }));
  },
  showActiveDialog: false,
  setShowActiveDialog(show: boolean) {
    set(state => ({ ...state, showActiveDialog: show }));
  },
  showDeactivateDialog: false,
  setShowDeactivateDialog(show: boolean) {
    set(state => ({ ...state, showDeactivateDialog: show }));
  },
  showDeleteDialog: false,
  setShowDeleteDialog(show: boolean) {
    set(state => ({ ...state, showDeleteDialog: show }));
  },
  showSaleInfo: false,
  setShowSaleInfo(show: boolean) {
    set(state => ({ ...state, showSaleInfo: show }));
  },
  simCardSaleselected: 0,
  setSimCardSaleselected(simcard: number) {
    set(state => ({ ...state, simCardSaleselected: simcard }));
  },
}));
