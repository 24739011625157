import { useEffect, useState } from 'react';
import {
  MobileContainer,
  MobileData,
  MobileDataLabel,
} from 'components/StyledComponents';
import { GroupData, MobileDataText } from 'components/MobileList';
import { Skeleton } from '@mui/material';
import { ItemProps } from '../Item';
import Status from '../components/Status';
import LinearConversionProgress from '../components/LinearConversionProgress';
import { Box, MobileTextName } from './styles';

function MobileItem({ data, loading = false }: ItemProps) {
  const [conversion, setConversion] = useState(0);

  const leadsConvertidos =
    data?.leads_convertidos && parseInt(data.leads_convertidos, 10);
  const leadsCriados = data?.leads_criados;

  useEffect(() => {
    if (leadsConvertidos && leadsCriados) {
      const conversionLeads = (leadsConvertidos / leadsCriados) * 100;
      const conversionLeadsInt = conversionLeads.toFixed();
      setConversion(parseInt(conversionLeadsInt, 10));
    }
  }, [leadsConvertidos, leadsCriados]);

  return (
    <MobileContainer>
      {loading ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        data && (
          <>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Nome</MobileDataLabel>
                <MobileTextName style={{ wordBreak: 'keep-all' }}>
                  {data.nome}
                </MobileTextName>
              </MobileData>
              <MobileData>
                <MobileDataLabel>Tipo de base</MobileDataLabel>
                <MobileDataText>{data.tipo}</MobileDataText>
              </MobileData>
            </GroupData>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Status</MobileDataLabel>
                <MobileDataText>
                  <Status status={data.status} />
                </MobileDataText>
              </MobileData>
              <MobileData>
                <MobileDataLabel>Leads Criados</MobileDataLabel>
                <MobileDataText>{data.leads_criados}</MobileDataText>
              </MobileData>
            </GroupData>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Leads Convertidos</MobileDataLabel>
                <MobileDataText style={{ color: 'rgb(43, 138, 62)' }}>
                  {data.leads_convertidos}
                </MobileDataText>
              </MobileData>
              <MobileData>
                <MobileDataLabel>Total de linhas</MobileDataLabel>
                <MobileDataText>
                  {data.total_linhas ? data.total_linhas : '-'}
                </MobileDataText>
              </MobileData>
            </GroupData>

            <MobileDataLabel>Conversão</MobileDataLabel>
            <Box>
              <LinearConversionProgress
                value={conversion}
                status={data.status}
              />
            </Box>
          </>
        )
      )}
    </MobileContainer>
  );
}

export default MobileItem;
