import React, { useEffect, useState } from 'react';
import { Container, TopBar } from 'components/CardList';
import { CardHeader } from '@mui/material';
import useBreakpoint from 'hooks/useBreakpoint';
import { List } from 'components/StyledComponents';
import { SIMCardOfBatch } from 'models/simcards';
import { Header } from './styles';
import Item from './Item';
import MobileItem from './MobileItem';
import Filters from './Filters';

interface BodyProps {
  simcards: SIMCardOfBatch[];
}

interface SIMCardListProps {
  simcards?: SIMCardOfBatch[];
  batchId: number;
}

interface LoadingListProps {
  isTableOrLess: boolean;
}

function LoadingSIMCardList({ isTableOrLess }: LoadingListProps) {
  return isTableOrLess ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({ simcards }: BodyProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <List>
      {simcards && simcards.length ? (
        simcards.map((item: SIMCardOfBatch) =>
          isTableOrLess ? (
            <MobileItem key={item.id} data={item} />
          ) : (
            <Item key={item.id} data={item} />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhum chip cadastrado</h3>
      )}
    </List>
  );
}

function SIMCardList({ simcards, batchId }: SIMCardListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const [filterByStatus, setFilterByStatus] = useState('');
  const [filterBySearch, setFilterBySearch] = useState('');
  const [simcardList, setSimcardList] = useState(simcards);

  useEffect(() => {
    if (!simcards) {
      return;
    }

    let newSimcardList = [...simcards];

    if (filterByStatus) {
      newSimcardList = newSimcardList.filter(
        item => item.status === filterByStatus,
      );
    }

    if (filterBySearch) {
      newSimcardList = newSimcardList.filter(
        item => item.iccid.indexOf(filterBySearch) > -1,
      );
    }

    setSimcardList(newSimcardList);
  }, [filterBySearch, filterByStatus, simcards]);

  return (
    <Container>
      <TopBar isDesktop={!isTableOrLess}>
        <CardHeader
          style={{ padding: '0px 0px 15px 0px' }}
          title={`Lista de SIM Cards do lote ${batchId}`}
        />

        <Filters
          setSearch={setFilterBySearch}
          setStatus={setFilterByStatus}
          status={filterByStatus}
        />
      </TopBar>

      {!isTableOrLess && (
        <Header>
          <span>ICCID</span>
          <span>HLR</span>
          <span>Status</span>
          <span>Data Atualização</span>
          <span> </span>
        </Header>
      )}

      {simcardList ? (
        <Body simcards={simcardList} />
      ) : (
        <LoadingSIMCardList isTableOrLess={isTableOrLess} />
      )}
    </Container>
  );
}

export default SIMCardList;
