import create from 'zustand';
import { DateRange } from 'materialui-daterange-picker';

type FilterFetchStoreState = {
  ordemFetch: string;
  setOrdemFetch: (ordem: string) => void;

  todayFetch: boolean;
  setTodayFetch: (value: boolean) => void;

  yesterdayFetch: boolean;
  setYesterdayFetch: (value: boolean) => void;

  lastWeekFetch: boolean;
  setLastWeekFetch: (value: boolean) => void;

  monthFetch: boolean;
  setMonthFetch: (value: boolean) => void;

  dateFetch: DateRange | null;
  setDateFetch: (newDate: DateRange | null) => void;

  segmentoFetch: string;
  setSegmentoFetch: (segmento: string) => void;

  tipoFetch: string;
  setTipoFetch: (tipo: string) => void;

  statusFetch: string;
  setStatusFetch: (status: string) => void;

  ufFetch: string;
  setUfFetch: (uf: string) => void;

  withReprocessedFetch: boolean;
  setWithReprocessedFetch: (show: boolean) => void;

  withManuallyProcessedFetch: boolean;
  setWithManuallyProcessedFetch: (show: boolean) => void;

  withAffiliatesFetch: boolean;
  setWithAffiliatesFetch: (show: boolean) => void;

  withTreatablesFetch: boolean;
  setWithTreatablesFetch: (show: boolean) => void;

  withPortabilityTreatmentFetch: boolean;
  setWithPortabilityTreatmentFetch: (show: boolean) => void;

  withExpressShippingFetch: boolean;
  setWithExpressShippingFetch: (show: boolean) => void;

  withOrderErrorFetch: boolean;
  setWithOrderErrorFetch: (show: boolean) => void;

  withEngagementFetch: boolean;
  setWithEngagementFetch: (show: boolean) => void;

  onlyNewFetch: boolean;
  setOnlyNewFetch: (show: boolean) => void;

  requestedDocumentationFetch: string;
  setRequestedDocumentationFetch: (requestedDocumentation: string) => void;

  statusCrossCoreFetch: string;
  setStatusCrossCoreFetch: (statusCrosscore: string) => void;

  dateStartFilterFetch: string;
  setDateStartFilterFetch: (dateStartFilter: string) => void;

  dateEndFilterFetch: string;
  setDateEndFilterFetch: (dateEndFilter: string) => void;

  msisdnFetch: string;
  setMsisdnFetch: (msisdn: string) => void;

  provisorioFetch: string;
  setProvisorioFetch: (provisorio: string) => void;

  iccidFetch: string;
  setIccidFetch: (iccid: string) => void;

  rastreamentoFetch: string;
  setRastreamentoFetch: (rastreamento: string) => void;

  protocoloFetch: string;
  setProtocoloFetch: (protocolo: string) => void;

  cpfFetch: string;
  setCpfFetch: (cpf: string) => void;

  nomeFetch: string;
  setNomeFetch: (nome: string) => void;

  filiacaoFetch: string;
  setFiliacaoFetch: (filiacao: string) => void;

  emailFetch: string;
  setEmailFetch: (email: string) => void;

  rgFetch: string;
  setRgFetch: (rg: string) => void;

  tokenFetch: string;
  setTokenFetch: (token: string) => void;

  utmMediumFetch: string;
  setUTMMediumFetch: (utmMedium: string) => void;

  utmCampaignFetch: string;
  setUTMCampaignFetch: (utmCampaign: string) => void;

  mensagemErroFetch: string;
  setMensagemErroFetch: (mensagemErro: string) => void;
};

export const useFilterFetchStore = create<FilterFetchStoreState>(set => ({
  ordemFetch: 'ordenar_data_criacao',
  setOrdemFetch: (ordem: string) => {
    set(state => ({ ...state, ordemFetch: ordem }));
  },

  todayFetch: false,
  setTodayFetch: (value: boolean) => {
    set(state => ({ ...state, todayFetch: value }));
  },

  yesterdayFetch: false,
  setYesterdayFetch: (value: boolean) => {
    set(state => ({ ...state, yesterdayFetch: value }));
  },

  lastWeekFetch: false,
  setLastWeekFetch: (value: boolean) => {
    set(state => ({ ...state, lastWeekFetch: value }));
  },

  monthFetch: false,
  setMonthFetch: (value: boolean) => {
    set(state => ({ ...state, monthFetch: value }));
  },

  dateFetch: null,
  setDateFetch: (newDate: DateRange | null) => {
    set(state => ({ ...state, dateFetch: newDate }));
  },

  segmentoFetch: '',
  setSegmentoFetch: (segmento: string) => {
    set(state => ({ ...state, segmentoFetch: segmento }));
  },

  tipoFetch: '',
  setTipoFetch: (tipo: string) => {
    set(state => ({ ...state, tipoFetch: tipo }));
  },

  statusFetch: '',
  setStatusFetch: (status: string) => {
    set(state => ({ ...state, statusFetch: status }));
  },
  ufFetch: '',
  setUfFetch: (uf: string) => {
    set(state => ({ ...state, ufFetch: uf }));
  },

  withReprocessedFetch: false,
  setWithReprocessedFetch: (show: boolean) => {
    set(state => ({ ...state, withReprocessedFetch: show }));
  },

  withManuallyProcessedFetch: false,
  setWithManuallyProcessedFetch: (show: boolean) => {
    set(state => ({ ...state, withManuallyProcessedFetch: show }));
  },

  withAffiliatesFetch: false,
  setWithAffiliatesFetch: (show: boolean) => {
    set(state => ({ ...state, withAffiliatesFetch: show }));
  },

  withTreatablesFetch: false,
  setWithTreatablesFetch: (show: boolean) => {
    set(state => ({ ...state, withTreatablesFetch: show }));
  },

  withPortabilityTreatmentFetch: false,
  setWithPortabilityTreatmentFetch: (show: boolean) => {
    set(state => ({ ...state, withPortabilityTreatmentFetch: show }));
  },

  withExpressShippingFetch: false,
  setWithExpressShippingFetch: (show: boolean) => {
    set(state => ({ ...state, withExpressShippingFetch: show }));
  },

  withOrderErrorFetch: false,
  setWithOrderErrorFetch: (show: boolean) => {
    set(state => ({ ...state, withOrderErrorFetch: show }));
  },

  withEngagementFetch: false,
  setWithEngagementFetch: (show: boolean) => {
    set(state => ({ ...state, withEngagementFetch: show }));
  },

  onlyNewFetch: false,
  setOnlyNewFetch: (show: boolean) => {
    set(state => ({ ...state, onlyNewFetch: show }));
  },

  requestedDocumentationFetch: 'all',
  setRequestedDocumentationFetch: (requestedDocumentation: string) => {
    set(state => ({
      ...state,
      requestedDocumentationFetch: requestedDocumentation,
    }));
  },

  statusCrossCoreFetch: '',
  setStatusCrossCoreFetch: (statusCrosscore: string) => {
    set(state => ({ ...state, statusCrossCoreFetch: statusCrosscore }));
  },

  dateStartFilterFetch: '',
  setDateStartFilterFetch: (dateStartFilter: string) => {
    set(state => ({ ...state, dateStartFilterFetch: dateStartFilter }));
  },

  dateEndFilterFetch: '',
  setDateEndFilterFetch: (dateEndFilter: string) => {
    set(state => ({ ...state, dateEndFilterFetch: dateEndFilter }));
  },

  msisdnFetch: '',
  setMsisdnFetch: (msisdn: string) => {
    set(state => ({ ...state, msisdnFetch: msisdn }));
  },

  provisorioFetch: '',
  setProvisorioFetch: (provisorio: string) => {
    set(state => ({ ...state, provisorioFetch: provisorio }));
  },

  iccidFetch: '',
  setIccidFetch: (iccid: string) => {
    set(state => ({ ...state, iccidFetch: iccid }));
  },

  rastreamentoFetch: '',
  setRastreamentoFetch: (rastreamento: string) => {
    set(state => ({ ...state, rastreamentoFetch: rastreamento }));
  },

  protocoloFetch: '',
  setProtocoloFetch: (protocolo: string) => {
    set(state => ({ ...state, protocoloFetch: protocolo }));
  },

  cpfFetch: '',
  setCpfFetch: (cpf: string) => {
    set(state => ({ ...state, cpfFetch: cpf }));
  },

  nomeFetch: '',
  setNomeFetch: (nome: string) => {
    set(state => ({ ...state, nomeFetch: nome }));
  },

  filiacaoFetch: '',
  setFiliacaoFetch: (filiacao: string) => {
    set(state => ({ ...state, filiacaoFetch: filiacao }));
  },

  emailFetch: '',
  setEmailFetch: (email: string) => {
    set(state => ({ ...state, emailFetch: email }));
  },

  rgFetch: '',
  setRgFetch: (rg: string) => {
    set(state => ({ ...state, rgFetch: rg }));
  },

  tokenFetch: '',
  setTokenFetch: (token: string) => {
    set(state => ({ ...state, tokenFetch: token }));
  },

  utmMediumFetch: '',
  setUTMMediumFetch: (utmMedium: string) => {
    set(state => ({ ...state, utmMediumFetch: utmMedium }));
  },

  utmCampaignFetch: '',
  setUTMCampaignFetch: (utmCampaign: string) => {
    set(state => ({ ...state, utmCampaignFetch: utmCampaign }));
  },

  mensagemErroFetch: '',
  setMensagemErroFetch: (mensagemErro: string) => {
    set(state => ({ ...state, mensagemErroFetch: mensagemErro }));
  },
}));
