import { CardHeader } from '@mui/material';
import { List } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { SIMCard } from 'models/simcards';
import { Container } from 'components/CardList';
import Item from './Item';
import MobileItem from './MobileItem';
import { Header } from './styles';
import { Sale } from './types';

interface SIMCardListProps {
  simcards?: SIMCard[];
  setSaleUpdateToSent?(value: Sale): void;
  updateSimCards(): void;
}
interface BodyProps {
  simcards?: SIMCard[];
  isMobile: boolean;
  setSaleUpdateToSent?(value: Sale): void;
  updateSimCards(): void;
}
interface LoadingListProps {
  isTableOrLess: boolean;
}

function LoadingSIMCarList({ isTableOrLess }: LoadingListProps) {
  if (isTableOrLess) {
    return (
      <>
        <MobileItem loading />
        <MobileItem loading />
        <MobileItem loading />
        <MobileItem loading />
      </>
    );
  }

  return (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({
  simcards,
  setSaleUpdateToSent,
  isMobile,
  updateSimCards,
}: BodyProps) {
  return (
    <List>
      {simcards?.length ? (
        simcards.map((item: SIMCard) =>
          isMobile ? (
            <MobileItem
              key={String(item.simcard_id)}
              data={item}
              setSaleUpdateToSent={setSaleUpdateToSent}
              updateSimCards={updateSimCards}
            />
          ) : (
            <Item
              key={String(item.simcard_id)}
              data={item}
              setSaleUpdateToSent={setSaleUpdateToSent}
              updateSimCards={updateSimCards}
            />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhum chip pendente para envio</h3>
      )}
    </List>
  );
}

function SIMCardList({
  simcards,
  setSaleUpdateToSent,
  updateSimCards,
}: SIMCardListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  return (
    <Container>
      <CardHeader title="Caixas de chip" />
      {!isTableOrLess && (
        <Header>
          <span style={{ width: 195 }}>Nome do Cliente</span>
          <span style={{ width: 195 }}>ICCID </span>
          <span style={{ marginLeft: 5 }}>MSISDN</span>
          <span>Data da aprovação</span>
        </Header>
      )}
      {simcards ? (
        <Body
          simcards={simcards}
          setSaleUpdateToSent={setSaleUpdateToSent}
          isMobile={isTableOrLess}
          updateSimCards={updateSimCards}
        />
      ) : (
        <LoadingSIMCarList isTableOrLess={isTableOrLess} />
      )}
    </Container>
  );
}

export default SIMCardList;
