import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';

import { AverageFetch } from 'fetches/averageFetch';
import { exibirValorFinanceiro } from 'constants/permissions';
import { useAuth } from 'hooks/auth';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useReportFilterFlagFetchStore } from 'store/useReportFilterFlagFetchStore';
import useStyles from './styles';

function ProjectedSales() {
  const start = useReportFilterFlagFetchStore(state => state.startFetch);
  const end = useReportFilterFlagFetchStore(state => state.endFetch);
  const reprocessed = useReportFilterFlagFetchStore(
    state => state.withReprocessedFetch,
  );
  const inReprocessing = useReportFilterFlagFetchStore(
    state => state.inReprocessingFetch,
  );
  const engagement = useReportFilterFlagFetchStore(
    state => state.withEngagementFetch,
  );

  const { data } = AverageFetch(
    start,
    end,
    reprocessed,
    inReprocessing,
    engagement,
  );

  const classes = useStyles();
  const { hasPermission } = useAuth();

  const [
    showFinancialValueInReaisPermission,
    setShowFinancialValueInReaisPermission,
  ] = useState(false);
  const [showFinancialValue, setShowFinancialValue] = useState(true);

  const handleClickShowFinancialValue = () => {
    setShowFinancialValue(!showFinancialValue);
  };

  const handleMouseDownFinancialValue = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    const shouldShow = hasPermission(exibirValorFinanceiro);

    setShowFinancialValueInReaisPermission(shouldShow);
  }, [hasPermission]);

  return (
    <Card className={classes.root}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item>
          <Box className={classes.box}>
            <EventNoteOutlinedIcon className={classes.icon} />
          </Box>
        </Grid>
        <Grid item className={classes.content}>
          <Box>
            <Typography className={classes.title} variant="body2">
              Total{' '}
              {showFinancialValueInReaisPermission &&
                (!data
                  ? '(Ticket Médio: (-))'
                  : `(Ticket Médio: ${data?.ticket_medio})`)}
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle1">
              Projeção {(data?.dias ?? 0) > 0 ? 'Mensal' : 'Hoje'}
            </Typography>
          </Box>
          <Typography className={classes.h3} variant="h3">
            {!data ? (
              <Skeleton variant="rectangular" width={80} height={30} />
            ) : (
              <>
                {data.projecao}
                {showFinancialValueInReaisPermission && (
                  <span className={classes.span}>
                    {data.valor_financeiro !== '' &&
                      (showFinancialValue ? (
                        ` R$ ${data.valor_financeiro}`
                      ) : (
                        <Skeleton
                          variant="rectangular"
                          width={76}
                          height={20}
                          animation={false}
                          className={classes.skeleton}
                        />
                      ))}

                    {data.valor_financeiro !== '' && (
                      <IconButton
                        className={classes.buttonIcon}
                        color="inherit"
                        onClick={handleClickShowFinancialValue}
                        onMouseDown={handleMouseDownFinancialValue}
                      >
                        {showFinancialValue ? (
                          <VisibilityOff
                            className={classes.buttonIconVisibility}
                          />
                        ) : (
                          <Visibility
                            className={classes.buttonIconVisibility}
                          />
                        )}
                      </IconButton>
                    )}
                  </span>
                )}
              </>
            )}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

export default ProjectedSales;
