import { vendaExtrairRelatorio } from 'constants/permissions';
import { format, isAfter, isValid, subDays } from 'date-fns';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { isEqual } from 'lodash';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import api from 'services/api';
import { useFlagFilterStore } from 'store/flagFilterStore';
import { useSelectFilterStore } from 'store/selectFilterStore';
import { downloadXlsFile } from 'util/blob';
import useBreakpoint from 'hooks/useBreakpoint';
import { useSearchFieldsFilterStore } from 'store/searchFieldsFilterStore';
import { useFilterFetchStore } from 'store/filterFetchStore';

export function useFilters() {
  const { addToast } = useToast();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [anchorElementSearchTransactions, setAnchorElementSearchTransactions] =
    useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const [localSearchID, setLocalSearchID] = useState(String(''));
  const [showButtonExtractReport, setShowButtonExtractReport] = useState(false);

  const tiposSelecionados = useSelectFilterStore(
    store => store.tiposSelecionados,
  );
  const statusSelecionados = useSelectFilterStore(
    store => store.statusSelecionados,
  );
  const ufsSelecionadas = useSelectFilterStore(store => store.ufsSelecionadas);
  const segmentosSelecionados = useSelectFilterStore(
    store => store.segmentosSelecionados,
  );
  const statusCrosscoreSelecionados = useSelectFilterStore(
    store => store.statusCrosscoreSelecionados,
  );
  const requestedDocumentation = useSelectFilterStore(
    store => store.requestedDocumentation,
  );
  const dateStart = useSelectFilterStore(store => store.dateStartSelecionado);
  const setDateStart = useSelectFilterStore(
    store => store.setDateStartSelecionado,
  );
  const dateEnd = useSelectFilterStore(store => store.dateEndSelecionado);
  const setDateEnd = useSelectFilterStore(store => store.setDateEndSelecionado);

  const withReprocessed = useFlagFilterStore(store => store.withReprocessed);
  const withManuallyProcessed = useFlagFilterStore(
    store => store.withManuallyProcessed,
  );
  const withAffiliates = useFlagFilterStore(store => store.withAffiliates);
  const withTreatables = useFlagFilterStore(store => store.withTreatables);
  const withPortabilityTreatment = useFlagFilterStore(
    store => store.withPortabilityTreatment,
  );
  const withExpressShipping = useFlagFilterStore(
    store => store.withExpressShipping,
  );
  const withOrderError = useFlagFilterStore(store => store.withOrderError);
  const withEngagement = useFlagFilterStore(store => store.withEngagement);
  const onlyNew = useFlagFilterStore(store => store.onlyNew);

  const msisdn = useSearchFieldsFilterStore(state => state.msisdn);
  const provisorio = useSearchFieldsFilterStore(state => state.provisorio);
  const iccid = useSearchFieldsFilterStore(state => state.iccid);
  const rastreamento = useSearchFieldsFilterStore(state => state.rastreamento);
  const protocolo = useSearchFieldsFilterStore(state => state.protocolo);
  const cpf = useSearchFieldsFilterStore(state => state.cpf);
  const nome = useSearchFieldsFilterStore(state => state.nome);
  const filiacao = useSearchFieldsFilterStore(state => state.filiacao);
  const email = useSearchFieldsFilterStore(state => state.email);
  const rg = useSearchFieldsFilterStore(state => state.rg);
  const token = useSearchFieldsFilterStore(state => state.token);
  const utmMedium = useSearchFieldsFilterStore(state => state.utmMedium);
  const utmCampaign = useSearchFieldsFilterStore(state => state.utmCampaign);
  const mensagemErro = useSearchFieldsFilterStore(state => state.mensagemErro);
  const ordemFetch = useFilterFetchStore(state => state.ordemFetch);
  const setOrdemFetch = useFilterFetchStore(state => state.setOrdemFetch);
  const todayFetch = useFilterFetchStore(state => state.todayFetch);
  const setTodayFetch = useFilterFetchStore(state => state.setTodayFetch);
  const yesterdayFetch = useFilterFetchStore(state => state.yesterdayFetch);
  const setYesterdayFetch = useFilterFetchStore(
    state => state.setYesterdayFetch,
  );
  const lastWeekFetch = useFilterFetchStore(state => state.lastWeekFetch);
  const setLastWeekFetch = useFilterFetchStore(state => state.setLastWeekFetch);
  const monthFetch = useFilterFetchStore(state => state.monthFetch);
  const setMonthFetch = useFilterFetchStore(state => state.setMonthFetch);
  const setDateFetch = useFilterFetchStore(state => state.setDateFetch);
  const segmentoFetch = useFilterFetchStore(state => state.segmentoFetch);
  const setSegmentoFetch = useFilterFetchStore(state => state.setSegmentoFetch);
  const tipoFetch = useFilterFetchStore(state => state.tipoFetch);
  const setTipoFetch = useFilterFetchStore(state => state.setTipoFetch);
  const statusFetch = useFilterFetchStore(state => state.statusFetch);
  const setStatusFetch = useFilterFetchStore(state => state.setStatusFetch);
  const ufFetch = useFilterFetchStore(state => state.ufFetch);
  const setUfFetch = useFilterFetchStore(state => state.setUfFetch);
  const withReprocessedFetch = useFilterFetchStore(
    state => state.withReprocessedFetch,
  );
  const setWithReprocessedFetch = useFilterFetchStore(
    state => state.setWithReprocessedFetch,
  );
  const withManuallyProcessedFetch = useFilterFetchStore(
    state => state.withManuallyProcessedFetch,
  );
  const setWithManuallyProcessedFetch = useFilterFetchStore(
    state => state.setWithManuallyProcessedFetch,
  );
  const withAffiliatesFetch = useFilterFetchStore(
    state => state.withAffiliatesFetch,
  );
  const setWithAffiliatesFetch = useFilterFetchStore(
    state => state.setWithAffiliatesFetch,
  );
  const withTreatablesFetch = useFilterFetchStore(
    state => state.withTreatablesFetch,
  );
  const setWithTreatablesFetch = useFilterFetchStore(
    state => state.setWithTreatablesFetch,
  );
  const withPortabilityTreatmentFetch = useFilterFetchStore(
    state => state.withPortabilityTreatmentFetch,
  );
  const setWithPortabilityTreatmentFetch = useFilterFetchStore(
    state => state.setWithPortabilityTreatmentFetch,
  );
  const withExpressShippingFetch = useFilterFetchStore(
    state => state.withExpressShippingFetch,
  );
  const setWithExpressShippingFetch = useFilterFetchStore(
    state => state.setWithExpressShippingFetch,
  );
  const withOrderErrorFetch = useFilterFetchStore(
    state => state.withOrderErrorFetch,
  );
  const setWithOrderErrorFetch = useFilterFetchStore(
    state => state.setWithOrderErrorFetch,
  );
  const withEngagementFetch = useFilterFetchStore(
    state => state.withEngagementFetch,
  );
  const setWithEngagementFetch = useFilterFetchStore(
    state => state.setWithEngagementFetch,
  );
  const onlyNewFetch = useFilterFetchStore(state => state.onlyNewFetch);
  const setOnlyNewFetch = useFilterFetchStore(state => state.setOnlyNewFetch);
  const requestedDocumentationFetch = useFilterFetchStore(
    state => state.requestedDocumentationFetch,
  );
  const setRequestedDocumentationFetch = useFilterFetchStore(
    state => state.setRequestedDocumentationFetch,
  );
  const statusCrossCoreFetch = useFilterFetchStore(
    state => state.statusCrossCoreFetch,
  );
  const setStatusCrossCoreFetch = useFilterFetchStore(
    state => state.setStatusCrossCoreFetch,
  );
  const msisdnFetch = useFilterFetchStore(state => state.msisdnFetch);
  const setMsisdnFetch = useFilterFetchStore(state => state.setMsisdnFetch);
  const provisorioFetch = useFilterFetchStore(state => state.provisorioFetch);
  const setProvisorioFetch = useFilterFetchStore(
    state => state.setProvisorioFetch,
  );
  const iccidFetch = useFilterFetchStore(state => state.iccidFetch);
  const setIccidFetch = useFilterFetchStore(state => state.setIccidFetch);
  const rastreamentoFetch = useFilterFetchStore(
    state => state.rastreamentoFetch,
  );
  const setRastreamentoFetch = useFilterFetchStore(
    state => state.setRastreamentoFetch,
  );
  const protocoloFetch = useFilterFetchStore(state => state.protocoloFetch);
  const setProtocoloFetch = useFilterFetchStore(
    state => state.setProtocoloFetch,
  );
  const cpfFetch = useFilterFetchStore(state => state.cpfFetch);
  const setCpfFetch = useFilterFetchStore(state => state.setCpfFetch);

  const nomeFetch = useFilterFetchStore(state => state.nomeFetch);
  const setNomeFetch = useFilterFetchStore(state => state.setNomeFetch);

  const filiacaoFetch = useFilterFetchStore(state => state.filiacaoFetch);
  const setFiliacaoFetch = useFilterFetchStore(state => state.setFiliacaoFetch);

  const emailFetch = useFilterFetchStore(state => state.emailFetch);
  const setEmailFetch = useFilterFetchStore(state => state.setEmailFetch);

  const rgFetch = useFilterFetchStore(state => state.rgFetch);
  const setRgFetch = useFilterFetchStore(state => state.setRgFetch);

  const tokenFetch = useFilterFetchStore(state => state.tokenFetch);
  const setTokenFetch = useFilterFetchStore(state => state.setTokenFetch);

  const utmMediumFetch = useFilterFetchStore(state => state.utmMediumFetch);
  const setUTMMediumFetch = useFilterFetchStore(
    state => state.setUTMMediumFetch,
  );

  const utmCampaignFetch = useFilterFetchStore(state => state.utmCampaignFetch);
  const setUTMCampaignFetch = useFilterFetchStore(
    state => state.setUTMCampaignFetch,
  );

  const mensagemErroFetch = useFilterFetchStore(
    state => state.mensagemErroFetch,
  );
  const setMensagemErroFetch = useFilterFetchStore(
    state => state.setMensagemErroFetch,
  );
  const dateStartFilterFetch = useFilterFetchStore(
    state => state.dateStartFilterFetch,
  );
  const setDateStartFilterFetch = useFilterFetchStore(
    state => state.setDateStartFilterFetch,
  );
  const dateEndFilterFetch = useFilterFetchStore(
    state => state.dateEndFilterFetch,
  );
  const setDateEndFilterFetch = useFilterFetchStore(
    state => state.setDateEndFilterFetch,
  );

  const { hasPermission } = useAuth();
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1200);

  useEffect(() => {
    const shouldShow = hasPermission(vendaExtrairRelatorio);

    setShowButtonExtractReport(shouldShow);
  }, [hasPermission]);

  useEffect(() => {
    if (!todayFetch && !yesterdayFetch && !lastWeekFetch && !monthFetch) {
      setDateStartFilterFetch('');
      setDateEndFilterFetch('');
    }
  }, [
    lastWeekFetch,
    monthFetch,
    setDateEndFilterFetch,
    setDateStartFilterFetch,
    todayFetch,
    yesterdayFetch,
  ]);

  const onFilter = () => {
    setSegmentoFetch(segmentosSelecionados.map(item => item.value).toString());
    setTipoFetch(tiposSelecionados.map(item => item.value).toString());
    setStatusFetch(statusSelecionados.map(item => item.value).toString());
    setUfFetch(ufsSelecionadas.map(item => item.value).toString());
    setWithReprocessedFetch(withReprocessed);
    setWithManuallyProcessedFetch(withManuallyProcessed);
    setWithAffiliatesFetch(withAffiliates);
    setWithTreatablesFetch(withTreatables);
    setWithPortabilityTreatmentFetch(withPortabilityTreatment);
    setWithExpressShippingFetch(withExpressShipping);
    setWithOrderErrorFetch(withOrderError);
    setWithEngagementFetch(withEngagement);
    setOnlyNewFetch(onlyNew);
    setRequestedDocumentationFetch(requestedDocumentation);
    setStatusCrossCoreFetch(
      statusCrosscoreSelecionados.map(item => item.value).toString(),
    );

    if (
      dateStart &&
      dateEnd &&
      isValid(dateStart) &&
      isValid(dateEnd) &&
      (isAfter(dateEnd, dateStart) || isEqual(dateEnd, dateStart))
    ) {
      setDateStartFilterFetch(format(dateStart, 'yyyy-MM-dd'));
      setDateEndFilterFetch(format(dateEnd, 'yyyy-MM-dd'));
    }
  };

  const onFilterSearchFields = () => {
    setMsisdnFetch(msisdn);
    setProvisorioFetch(provisorio);
    setIccidFetch(iccid);
    setRastreamentoFetch(rastreamento);
    setProtocoloFetch(protocolo);
    setCpfFetch(cpf);
    setNomeFetch(nome);
    setFiliacaoFetch(filiacao);
    setEmailFetch(email);
    setRgFetch(rg);
    setTokenFetch(token);
    setUTMMediumFetch(utmMedium);
    setUTMCampaignFetch(utmCampaign);
    setMensagemErroFetch(mensagemErro);
  };

  const handleToday = useCallback(
    (value: boolean) => {
      if (value) {
        setDateFetch(null);
        setYesterdayFetch(false);
        setLastWeekFetch(false);
        setMonthFetch(false);
      }

      setDateStart(new Date());
      setDateEnd(new Date());
      setDateStartFilterFetch(format(new Date(), 'yyyy-MM-dd'));
      setDateEndFilterFetch(format(new Date(), 'yyyy-MM-dd'));
      setTodayFetch(value);
    },
    [
      setDateStart,
      setDateEnd,
      setDateStartFilterFetch,
      setDateEndFilterFetch,
      setTodayFetch,
      setDateFetch,
      setYesterdayFetch,
      setLastWeekFetch,
      setMonthFetch,
    ],
  );

  const handleYesterday = useCallback(
    (value: boolean) => {
      if (value) {
        setDateFetch(null);
        setTodayFetch(false);
        setLastWeekFetch(false);
        setMonthFetch(false);
      }

      setDateStart(subDays(new Date(), 1));
      setDateEnd(subDays(new Date(), 1));
      setDateStartFilterFetch(format(subDays(new Date(), 1), 'yyyy-MM-dd'));
      setDateEndFilterFetch(format(subDays(new Date(), 1), 'yyyy-MM-dd'));
      setYesterdayFetch(value);
    },
    [
      setDateStart,
      setDateEnd,
      setDateStartFilterFetch,
      setDateEndFilterFetch,
      setYesterdayFetch,
      setDateFetch,
      setTodayFetch,
      setLastWeekFetch,
      setMonthFetch,
    ],
  );

  const handleLastWeek = useCallback(
    (value: boolean) => {
      if (value) {
        setDateFetch(null);
        setTodayFetch(false);
        setYesterdayFetch(false);
        setMonthFetch(false);
      }

      setDateStart(subDays(new Date(), 7));
      setDateEnd(new Date());
      setDateStartFilterFetch(format(subDays(new Date(), 7), 'yyyy-MM-dd'));
      setDateEndFilterFetch(format(new Date(), 'yyyy-MM-dd'));
      setLastWeekFetch(value);
    },
    [
      setDateStart,
      setDateEnd,
      setDateStartFilterFetch,
      setDateEndFilterFetch,
      setLastWeekFetch,
      setDateFetch,
      setTodayFetch,
      setYesterdayFetch,
      setMonthFetch,
    ],
  );

  const handleMonth = useCallback(
    (value: boolean) => {
      if (value) {
        setDateFetch(null);
        setTodayFetch(false);
        setYesterdayFetch(false);
        setLastWeekFetch(false);
      }

      setDateStart(subDays(new Date(), 30));
      setDateEnd(new Date());
      setDateStartFilterFetch(format(subDays(new Date(), 30), 'yyyy-MM-dd'));
      setDateEndFilterFetch(format(new Date(), 'yyyy-MM-dd'));
      setMonthFetch(value);
    },
    [
      setDateStart,
      setDateEnd,
      setDateStartFilterFetch,
      setDateEndFilterFetch,
      setMonthFetch,
      setDateFetch,
      setTodayFetch,
      setYesterdayFetch,
      setLastWeekFetch,
    ],
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleClickSearchTransactions = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorElementSearchTransactions(event.currentTarget);
  };

  const handleCloseSearchTransactions = () => {
    setAnchorElementSearchTransactions(null);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const params = {
      inicio: dateStartFilterFetch || null,
      fim: dateEndFilterFetch || null,
      tipo: tipoFetch.toString(),
      segmento: segmentoFetch.toString() || null,
      status: statusFetch.toString(),
      uf: ufFetch.toString(),
      id: localSearchID,
      tratavel: withTreatablesFetch ? '1' : '',
      tratamento_portabilidade: withPortabilityTreatmentFetch ? '1' : '',
      lead_afiliado: withAffiliatesFetch ? '1' : '',
      status_crosscore: statusCrossCoreFetch.toString(),
      trazer_reprocessados: withReprocessedFetch ? '1' : '',
      trazer_reprocessada_manual: withManuallyProcessedFetch ? '1' : '',
      documentacao_solicitada: requestedDocumentationFetch,
      trazer_erro_ordem: withOrderErrorFetch ? '1' : '',
      trazer_engajamento: withEngagementFetch ? '1' : '',
      trazer_apenas_novas: onlyNewFetch ? '1' : '',
      trazer_transporte_expresso: withExpressShippingFetch ? '1' : '',
      ordenar_data_criacao: ordemFetch === 'ordenar_data_criacao' ? '1' : '',
      ordenar_data_ativacao: ordemFetch === 'ordenar_data_ativacao' ? '1' : '',
      msisdn: msisdnFetch || null,
      provisorio: provisorioFetch || null,
      iccid: iccidFetch || null,
      rastreamento: rastreamentoFetch || null,
      protocolo: protocoloFetch || null,
      cpf: cpfFetch || null,
      nome: nomeFetch || null,
      filiacao: filiacaoFetch || null,
      email: emailFetch || null,
      rg: rgFetch || null,
      token: tokenFetch || null,
      utm_medium: utmMediumFetch || null,
      utm_campaign: utmCampaignFetch || null,
      mensagem_erro: mensagemErroFetch || null,
    };

    setLoading(true);

    api
      .get('/core/relatorios', { responseType: 'blob', params })
      .then(({ data }) => {
        const filename = `relatório-${dateStartFilterFetch}-${dateEndFilterFetch}-${tipoFetch}-${localSearchID}-${segmentoFetch}-${statusFetch}-${ufFetch}`;
        downloadXlsFile({ filename, file: data });
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro o baixar o relatório!',
          description:
            err.data && err.data.message ? err.data.message : err.message,
        });
      })
      .finally(() => setLoading(false));
  };

  const openPopover = Boolean(anchorElement);
  const idPopover = openPopover ? 'popover-actions' : undefined;

  const openPopoverSearchTransactions = Boolean(
    anchorElementSearchTransactions,
  );
  const idPopoverSearchTransactions = openPopoverSearchTransactions
    ? 'popover-search-transactions'
    : undefined;

  return {
    idPopover,
    openPopover,
    openPopoverSearchTransactions,
    idPopoverSearchTransactions,
    anchorElement,
    anchorElementSearchTransactions,
    loading,
    localSearchID,
    showButtonExtractReport,
    isTableOrLess,
    ordemFetch,
    today: todayFetch,
    yesterday: yesterdayFetch,
    lastWeek: lastWeekFetch,
    month: monthFetch,
    setLocalSearchID,
    setOrdemFetch,
    onFilter,
    handleToday,
    handleYesterday,
    handleLastWeek,
    handleMonth,
    handleClick,
    handleClose,
    handleSubmit,
    handleClickSearchTransactions,
    handleCloseSearchTransactions,
    onFilterSearchFields,
  };
}
