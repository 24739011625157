import { IconButton, Skeleton } from '@mui/material';
import {
  ActionButtons,
  Container,
  Data,
  DataInfo,
  Divider,
} from 'components/StyledComponents';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ItemProps } from '../types';
import { DataText, DataTextName, useStyles } from './styles';

function Item({
  data,
  loading = false,
  setFAQSelected,
  setShowFAQEdition,
  handleOpenModalDelete,
}: ItemProps) {
  const classes = useStyles();
  return (
    <Container>
      <Data>
        <DataInfo>
          <DataText>
            {loading ? (
              <Skeleton width={300} style={{ marginLeft: 28 }} />
            ) : (
              data?.pergunta
            )}
          </DataText>
          <DataTextName>
            {loading ? (
              <Skeleton width={500} style={{ marginRight: 600 }} />
            ) : (
              data?.resposta
            )}
          </DataTextName>
        </DataInfo>
        <ActionButtons>
          <IconButton
            className={classes.icon}
            onClick={() => {
              handleOpenModalDelete && handleOpenModalDelete();
              setFAQSelected && data && setFAQSelected(data);
            }}
            disabled={loading}
          >
            <RiDeleteBinLine />
          </IconButton>
          <IconButton
            className={classes.icon}
            onClick={() => {
              setShowFAQEdition && setShowFAQEdition(true);
              setFAQSelected && data && setFAQSelected(data);
            }}
            disabled={loading}
          >
            <FiEdit />
          </IconButton>
        </ActionButtons>
      </Data>
      <Divider />
    </Container>
  );
}

export default Item;
