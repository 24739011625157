import React from 'react';

import Indicator, { IndicatorProps } from './Indicator';
import { IndicatorsGroup, IndicatorsKeyBox } from './styles';

export interface IndicatorsProp {
  indicators: Array<IndicatorProps>;
}

function Indicators({ indicators }: IndicatorsProp) {
  return (
    <IndicatorsGroup>
      {indicators &&
        indicators.map(indicator => (
          <IndicatorsKeyBox key={indicator.description}>
            <Indicator
              description={indicator.description}
              value={indicator.value}
            />
          </IndicatorsKeyBox>
        ))}
    </IndicatorsGroup>
  );
}

export default Indicators;
