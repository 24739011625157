import React, { useEffect, useState } from 'react';
import Close from '@mui/icons-material/Close';
import {
  cell as celFormatter,
  cpf as cpfFormatter,
  date as dateFormatter,
  hour as hourFormatter,
} from 'util/formatter';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';

import {
  ButtonProgress,
  CloseButton,
  DividerDrawer,
  SessionTitle,
} from 'components/StyledComponents';
import Status from 'pages/Sale/InfoSale/Status';
import useBreakpoint from 'hooks/useBreakpoint';

import { DateTimePicker } from '@mui/x-date-pickers';
import OpenTracking from 'pages/Sale/InfoSale/OpenTracking';
import api from 'services/api';
import { format } from 'date-fns';
import { useToast } from 'hooks/toast';
import WarningIcon from 'assets/icons/warning.svg';
import { useSaleSelected } from 'store/saleSelected';
import { Alert, AlertText, Container, TextName } from './styles';
import TextInfo from './TextInfo';
import { InfoSaleFormProps, Sale as ISale, saleInfoFormType } from './types';
import AuthorizeForm from './AuthorizeForm';
import CancelForm from './CancelForm';
import TreatmentForm from './TreatmentForm';

interface InfoSaleProps {
  closeDrawer(): void;
  sale: ISale;
  setSale(sale: ISale): void;
  updateSelectedSale?(sale: ISale): void;
  removeSale(sale: ISale): void;
  module?: string;
}

function ValidationForm({
  sale,
  setSale,
  closeDrawer,
  removeSale,
  openModal,
  setOpenModal,
}: InfoSaleFormProps) {
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  return isTabletSizeOrSmaller ? (
    <>
      {sale && (
        <>
          <Box display="flex" flexDirection="column">
            <AuthorizeForm sale={sale} setSale={setSale} />

            <CancelForm sale={sale} setSale={setSale} />
          </Box>
          <TreatmentForm
            sale={sale}
            setSale={setSale}
            closeDrawer={closeDrawer}
            removeSale={removeSale}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </>
      )}

      <Box marginTop="20px">
        {sale?.url_rastreamento && (
          <div style={{ marginBottom: 30 }}>
            <OpenTracking
              trackingURL={sale.url_rastreamento}
              noHorizontalMargin
            />
          </div>
        )}
      </Box>
    </>
  ) : (
    <>
      {sale && (
        <>
          <Box display="flex" alignItems="center">
            <CancelForm sale={sale} setSale={setSale} />

            <AuthorizeForm sale={sale} setSale={setSale} />
          </Box>

          <TreatmentForm
            sale={sale}
            setSale={setSale}
            closeDrawer={closeDrawer}
            removeSale={removeSale}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </>
      )}

      <Box marginTop="20px">
        {sale?.url_rastreamento && (
          <div style={{ marginBottom: 30 }}>
            <OpenTracking
              trackingURL={sale.url_rastreamento}
              noHorizontalMargin
            />
          </div>
        )}
      </Box>
    </>
  );
}

function PortabilityTreatmentForm({
  sale,
  setSale,
  closeDrawer,
  removeSale,
}: InfoSaleFormProps) {
  const [newDate, setNewDate] = useState(new Date());
  const setSalesSelected = useSaleSelected(state => state.setSalesSelected);
  const [loading, setLoading] = useState(false);
  const [loadingPortabilityTreatment, setLoadingPortabilityTreatment] =
    useState(false);
  const [open, setOpen] = useState(false);

  const { addToast } = useToast();
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);

  const handleClick = () => {
    setLoading(true);
    api
      .patch(`/core/vendas/${sale?.id}`, {
        data_portabilidade: format(newDate, 'yyyy-MM-dd HH:mm:ss'),
      })
      .then(({ data }) => {
        setSale(data);
        addToast({
          type: 'success',
          title: 'Reagendamento da portabilidade',
          description: 'Atualizada com sucesso',
        });
      })
      .catch(error => {
        setLoading(false);
        error.data &&
          addToast({
            type: 'error',
            title: 'Erro ao reagendar',
            description: error.data.message,
          });
      })
      .finally(() => setLoading(false));
  };

  const handlePortabilityInTreatment = () => {
    setLoadingPortabilityTreatment(true);
    const url = '/core/vendas/atualizar-detalhe';

    api
      .put(`${url}/${sale?.id}`, {
        status: 'TRATAMENTO_PORTABILIDADE',
        tratamento_portabilidade: false,
        motivo_tratamento: sale?.motivo_tratamento,
      })
      .then(({ data: res }) => {
        setLoadingPortabilityTreatment(false);
        addToast({
          type: 'success',
          title: 'Tratamento concluido!',
          description: 'Tratamento de Portabilidade concluido com sucesso!',
        });
        setSalesSelected(0);
        closeDrawer();
        removeSale && removeSale({ ...sale, ...res });
      })
      .catch(error => {
        setLoadingPortabilityTreatment(false);
        error.data &&
          addToast({
            type: 'error',
            title: 'Erro ao concluir Tratamento',
            description: error.data.message,
          });
      })
      .finally(() => {
        api
          .get(`core/vendas/${sale?.id}`)
          .then(({ data }) => setSale({ ...sale, ...data }));
      });
  };

  return (
    <>
      <Box display="flex" flexDirection="column" marginTop="10px">
        <SessionTitle>
          Atualizar agendamento de portabilidade
          <DividerDrawer />
        </SessionTitle>
        <Box margin="10px 0 ">
          <DateTimePicker
            label="Data e hora"
            inputFormat="dd/MM/yyyy HH:mm:ss"
            value={newDate}
            InputAdornmentProps={{ position: 'end' }}
            // invalidDateMessage="Data inválida"
            onChange={e => setNewDate(e as Date)}
            renderInput={params => <TextField {...params} fullWidth />}
          />
        </Box>
        <Box margin="10px 0 ">
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            style={{ padding: 15 }}
            onClick={handleClick}
          >
            Reagendar portabilidade
            {loading && <ButtonProgress size={24} />}
          </Button>
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ padding: 15, marginTop: 10 }}
          disabled={loadingPortabilityTreatment}
          onClick={() => setOpen(true)}
        >
          Tratamento Concluido
          {loadingPortabilityTreatment && <ButtonProgress size={24} />}
        </Button>
        <Box marginTop="20px">
          {sale?.url_rastreamento && (
            <div style={{ marginBottom: 20 }}>
              <OpenTracking
                trackingURL={sale.url_rastreamento}
                noHorizontalMargin
              />
            </div>
          )}
        </Box>
      </Box>
      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullScreen={isTabletSizeOrSmaller}
      >
        <Box padding="30px">
          <DialogTitle id="alert-dialog-slide-title">
            Deseja continuar?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Ao confirmar, o tratamento desta portabilidade será considerado
              concluído e o pedido não mais aparecerá nesta listagem.
            </DialogContentText>
            <Box display="flex" justifyContent="space-between" marginTop="40px">
              <Button
                onClick={() => setOpen(false)}
                type="button"
                variant="contained"
                style={{ backgroundColor: 'white', color: '#C92A2A' }}
              >
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  setOpen(false);
                  handlePortabilityInTreatment();
                }}
                color="primary"
                type="button"
                variant="contained"
              >
                Confirmar
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
}

function DayByDayForm({
  sale,
  setSale,
  closeDrawer,
  removeSale,
  openModal,
  setOpenModal,
}: InfoSaleFormProps) {
  const [newDate, setNewDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const handleClick = () => {
    setLoading(true);
    api
      .patch(`/core/vendas/${sale?.id}`, {
        data_portabilidade: format(newDate, 'yyyy-MM-dd HH:mm:ss'),
      })
      .then(({ data }) => {
        setSale(data);
        addToast({
          type: 'success',
          title: 'Reagendamento da portabilidade',
          description: 'Atualizada com sucesso',
        });
      })
      .catch(error => {
        setLoading(false);
        error.data &&
          addToast({
            type: 'error',
            title: 'Erro ao reagendar',
            description: error.data.message,
          });
      })
      .finally(() => setLoading(false));
  };
  return (
    <Box display="flex" flexDirection="column" marginTop="10px">
      <SessionTitle>
        Atualizar agendamento de portabilidade
        <DividerDrawer />
      </SessionTitle>
      <Box margin="10px 0 ">
        <DateTimePicker
          label="Data e hora"
          inputFormat="dd/MM/yyyy HH:mm:ss"
          value={newDate}
          InputAdornmentProps={{ position: 'end' }}
          renderInput={params => <TextField {...params} fullWidth />}
          onChange={e => setNewDate(e as Date)}
        />
      </Box>
      <Box margin="10px 0 ">
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          style={{ padding: 15 }}
          onClick={handleClick}
        >
          Reagendar portabilidade
          {loading && <ButtonProgress size={24} />}
        </Button>
      </Box>
      {sale && (
        <TreatmentForm
          sale={sale}
          setSale={setSale}
          closeDrawer={closeDrawer}
          removeSale={removeSale}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      )}

      <Box marginTop="20px">
        {sale?.url_rastreamento && (
          <div style={{ marginBottom: 30 }}>
            <OpenTracking
              trackingURL={sale.url_rastreamento}
              noHorizontalMargin
            />
          </div>
        )}
      </Box>
    </Box>
  );
}

function FinalReschedulingForm({
  sale,
  setSale,
  closeDrawer,
  removeSale,
  openModal,
  setOpenModal,
}: InfoSaleFormProps) {
  const [newDate, setNewDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const handleClick = () => {
    setLoading(true);
    api
      .put(`/core/vendas/atualizar-detalhe/${sale?.id}`, {
        status: 'AGUARDANDO_PORTABILIDADE',
        data_portabilidade: format(newDate, 'yyyy-MM-dd HH:mm:ss'),
      })
      .then(({ data }) => {
        setSale(data.venda);
        addToast({
          type: 'success',
          title: 'Reagendamento da portabilidade',
          description: 'Atualizada com sucesso',
        });
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Reagendamento da portabilidade',
          description:
            'Houve um problema no reagendamento, verifique se os dados estão corretos',
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <Box display="flex" flexDirection="column" marginTop="10px">
      <Box margin="10px 0 ">
        <Alert>
          <img src={WarningIcon} alt="Alerta" />
          <AlertText>
            Importante: Escolha a data mais próxima possível. Assim garantiremos
            uma melhor experiencia para que o cliente tenha a linha liberada
            mais rápidamente.
          </AlertText>
        </Alert>
      </Box>
      <SessionTitle>
        Atualizar agendamento de portabilidade
        <DividerDrawer />
      </SessionTitle>
      <Box margin="10px 0 ">
        <DateTimePicker
          label="Data e hora"
          inputFormat="dd/MM/yyyy HH:mm:ss"
          value={newDate}
          InputAdornmentProps={{ position: 'end' }}
          // invalidDateMessage="Data inválida"
          onChange={e => setNewDate(e as Date)}
          renderInput={params => <TextField {...params} fullWidth />}
        />
      </Box>
      <Box margin="10px 0 ">
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          style={{ padding: 15 }}
          onClick={handleClick}
        >
          Reagendar portabilidade
          {loading && <ButtonProgress size={24} />}
        </Button>
      </Box>
      {sale && (
        <TreatmentForm
          sale={sale}
          setSale={setSale}
          closeDrawer={closeDrawer}
          removeSale={removeSale}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      )}

      <Box marginTop="20px">
        {sale?.url_rastreamento && (
          <div style={{ marginBottom: 30 }}>
            <OpenTracking
              trackingURL={sale.url_rastreamento}
              noHorizontalMargin
            />
          </div>
        )}
      </Box>
    </Box>
  );
}

const saleInfoForm: saleInfoFormType = {
  Validation: ValidationForm,
  DayByDay: DayByDayForm,
  FinalRescheduling: FinalReschedulingForm,
  Treatment: PortabilityTreatmentForm,
};

function InfoSale({
  sale,
  setSale,
  closeDrawer,
  updateSelectedSale,
  removeSale,
  module,
}: InfoSaleProps) {
  const [status, setStatus] = useState('');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setStatus(sale?.status || '');
    sale && updateSelectedSale && updateSelectedSale(sale);
  }, [sale, updateSelectedSale]);

  const Form = saleInfoForm[module || 'Validation'];

  return (
    <Container>
      <CloseButton onClick={() => closeDrawer()}>
        <IconButton>
          <Close />
        </IconButton>
      </CloseButton>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Status loading={!sale} status={status} />
          {Form && (
            <Form
              sale={sale}
              setSale={setSale}
              closeDrawer={closeDrawer}
              removeSale={removeSale}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          )}
        </Grid>
        <Grid item xs={12} style={{ height: 45 }}>
          <SessionTitle>
            Dados da portabilidade
            <DividerDrawer />
          </SessionTitle>
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Número portado"
            info={celFormatter(sale?.msisdn)}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextInfo
            loading={!sale}
            label="Número Provisório"
            info={celFormatter(sale?.provisorio)}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="Data de Agendamento"
            info={dateFormatter(sale?.data_portabilidade)}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="Hora"
            info={hourFormatter(sale?.data_portabilidade)}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="Data de ativação"
            info={dateFormatter(sale?.data_ativacao)}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="Hora"
            info={hourFormatter(sale?.data_ativacao)}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextInfo
            loading={!sale}
            label="Venda ID"
            info={sale?.id.toString()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Motivo tratamento"
            info={sale?.motivo_tratamento || '-'}
          />
        </Grid>
        <Grid item xs={12} style={{ height: 45 }}>
          <SessionTitle>
            Dados do Cliente
            <DividerDrawer />
          </SessionTitle>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo loading={!sale} label="Nome" info={sale?.customer?.nome} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="CPF"
            info={sale && cpfFormatter(sale.customer.cpf)}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextName>
            <TextInfo
              loading={!sale}
              label="E-mail"
              info={sale?.customer.email}
            />
          </TextName>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Data de Nascimeto"
            info={dateFormatter(sale?.customer.nascimento)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Nome da Mãe"
            info={sale?.customer.filiacao}
            canCopy
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Telefone Pessoal"
            info={celFormatter(sale?.customer.telefone)}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={12} style={{ height: 45 }}>
          <SessionTitle>
            Endereço
            <DividerDrawer />
          </SessionTitle>
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="CEP"
            info={sale?.cep}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo loading={!sale} label="Estado" info={sale?.uf} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInfo loading={!sale} label="Cidade" info={sale?.cidade} />
        </Grid>
        <Grid item xs={6}>
          <TextInfo loading={!sale} label="Rua" info={sale?.logradouro} />
        </Grid>
        {sale?.tipo_identificador === 'NUMERO' && (
          <Grid item xs={6} md={3}>
            <TextInfo loading={!sale} label="Número" info={sale?.numero} />
          </Grid>
        )}
        {sale?.tipo_identificador === 'QUADRA_LOTE' && (
          <>
            <Grid item xs={3} md={3}>
              <TextInfo
                loading={!sale}
                label="Quadra"
                info={`Q${sale?.quadra}`}
              />
            </Grid>
            <Grid item xs={3} md={3}>
              <TextInfo loading={!sale} label="Lote" info={`L${sale?.lote}`} />
            </Grid>
          </>
        )}
        {sale?.tipo_identificador === 'SEM_NUMERO' && (
          <Grid item xs={6} md={3}>
            <TextInfo loading={!sale} label="Número" info="SN" />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextInfo loading={!sale} label="Bairro" info={sale?.bairro} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Complemento ( Apto / Bloco / Sala )"
            info={sale?.complemento || '-'}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default InfoSale;
