import useSWR from 'swr';
import api from 'services/api';
import { LPOffer } from 'models/lpOffers';

export const LPOffersFetch = () => {
  const { data, mutate } = useSWR<LPOffer>(
    'offer',
    async (): Promise<LPOffer> => {
      const { data: offers } = await api.get('/core/lp-ofertas');

      return offers as LPOffer;
    },
  );

  return { data, mutate };
};
