import styled from 'styled-components';
import palette from 'theme/palette';

export const IndicatorBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;

  border: 1px solid gray;
  box-sizing: border-box;
  border-radius: 6px;
`;

export const IndicatorDescription = styled.div`
  font-size: 12px;
`;

export const IndicatorValue = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${palette.primary.main};
`;
