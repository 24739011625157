import { DataEligibilitySetups } from 'models/dataEligibilitySetups';
import { CategorySetup } from 'models/setup';
import userSWR from 'swr';
import api from '../services/api';

interface FetchProps {
  type?: string;
}

export const SetupsFetch = () => {
  const { data, mutate } = userSWR<CategorySetup[]>(
    'setups',
    async (): Promise<CategorySetup[]> => {
      const { data: setups } = await api.get('api/setups');

      return setups as CategorySetup[];
    },
  );

  return { data, mutate };
};

export const DataEligibilitySetupsFetch = ({ type }: FetchProps) => {
  const { data, mutate } = userSWR<DataEligibilitySetups>(
    `setups_${type}`,
    async (): Promise<DataEligibilitySetups> => {
      const { data: setups } = await api.get(
        `api/setups/dados-elegibilidade/${type}`,
      );

      return setups as DataEligibilitySetups;
    },
  );

  return { data, mutate };
};
