import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

export const DataInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5px;
`;

export const DataText = styled.span`
  width: 462px;
  white-space: nowrap;
  font-weight: 400;
  overflow: hidden;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 24px;
  text-overflow: ellipsis;
`;

export const useStyles = makeStyles(() => ({
  textData: {
    width: '140px',
  },
  textID: {
    width: '120px',
  },
  textMelhoria: {
    width: '462px',
    overflow: 'hidden',
  },
}));
