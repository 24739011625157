import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 185px;

  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;
