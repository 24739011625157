import styled from 'styled-components';

interface CardProps {
  planSelected: number;
}

export const Card = styled.div<CardProps>`
  padding: 15px;
  margin-top: 24px;
  border: 1px solid #f2f2f2;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  height: ${props => (props.planSelected !== 0 ? 215 : 96)}px;
`;
