import { Button } from '@mui/material';
import { NewAffiliateGoal } from 'pages/ManagerGoal/types';
import { useGoalSelectStore } from 'store/goalSelectStore';

interface SelectAllProps {
  daysOfMonthByGoal: NewAffiliateGoal[];
  currentDate: string;
  monthSelected: number;
  yearSelected: number;
}
function SelectAll({
  daysOfMonthByGoal,
  currentDate,
  monthSelected,
  yearSelected,
}: SelectAllProps) {
  const [currentYear, currentMonth] = currentDate.split('-').slice(0, 2);

  const selectedGoalsCheckbox = useGoalSelectStore(
    store => store.selectedGoalsCheckbox,
  );
  const setSelectedGoalsCheckbox = useGoalSelectStore(
    store => store.setSelectedGoalsCheckbox,
  );
  const hasSelected = useGoalSelectStore(store => store.hasSelected);
  const setHasSelected = useGoalSelectStore(store => store.setHasSelected);

  const handleSelectAllClick = () => {
    const filteredAffiliates = daysOfMonthByGoal.filter(
      affiliate => affiliate.date >= currentDate,
    );

    const unselectedAffiliates = filteredAffiliates.filter(
      affiliate =>
        !selectedGoalsCheckbox.some(goal => goal.date === affiliate.date),
    );

    const updatedSelectedGoalsCheckbox = hasSelected
      ? []
      : [...selectedGoalsCheckbox, ...unselectedAffiliates];

    setSelectedGoalsCheckbox(updatedSelectedGoalsCheckbox);
    setHasSelected(!hasSelected);
  };

  return (
    <Button
      variant="outlined"
      onClick={handleSelectAllClick}
      disabled={
        yearSelected < parseInt(currentYear, 10) ||
        (yearSelected === parseInt(currentYear, 10) &&
          monthSelected < parseInt(currentMonth, 10))
      }
    >
      {hasSelected ? 'Desmarcar selecionados' : 'Selecionar tudo'}
    </Button>
  );
}

export default SelectAll;
