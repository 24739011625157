import React from 'react';

import Skeleton from '@mui/material/Skeleton';

import ReasonCard from 'pages/FraudKeeper/ReasonCard';

import { IconButton } from '@mui/material';
import copyToClipboard from 'util/copyToClipboard';
import copyIcon from 'assets/icons/small/copy.svg';
import { MobileTextName } from 'components/StyledComponents';
import { Container, Data, DataLabel, Infos } from 'components/MobileList';
import { Search } from '@mui/icons-material';
import { cell as cellFormatter, cpf as cpfFormatter } from 'util/formatter';
import { useNavigate } from 'react-router-dom';
import { Action, DataColumn, DataText } from './styles';
import { ItemProps } from '../Item';

function MobileItem({ data, loading = false }: ItemProps) {
  const navigate = useNavigate();
  return (
    <Container
      onClick={() => data && navigate(`/curadoria-fraude/${data.curadoria_id}`)}
    >
      {loading ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        data && (
          <div>
            <Action>
              <Search />
            </Action>
            <Infos>
              <DataColumn>
                <Data>
                  <DataLabel>CPF</DataLabel>
                  <DataText>
                    {cpfFormatter(data.cliente_cpf)}
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        copyToClipboard(data.cliente_cpf);
                      }}
                      color="inherit"
                    >
                      <img src={copyIcon} alt="copiar" />
                    </IconButton>
                  </DataText>
                </Data>
                <Data>
                  <DataLabel>Número da linha</DataLabel>
                  <DataText>
                    {cellFormatter(data.cliente_telefone)}
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        copyToClipboard(data.cliente_telefone);
                      }}
                      color="inherit"
                    >
                      <img src={copyIcon} alt="copiar" />
                    </IconButton>
                  </DataText>
                </Data>
                <Data className="item item-email">
                  <DataLabel>E-mail</DataLabel>
                  <DataText>
                    <MobileTextName>{data.cliente_email}</MobileTextName>
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        copyToClipboard(data.cliente_email);
                      }}
                      color="inherit"
                    >
                      <img src={copyIcon} alt="copiar" />
                    </IconButton>
                  </DataText>
                </Data>
                <Data>
                  <DataLabel>Motivo de fraude</DataLabel>
                  <DataText style={{ marginRight: 50 }}>
                    <ReasonCard
                      reasons={data.motivos}
                      curadoriaId={data.curadoria_id}
                    />
                  </DataText>
                </Data>
                <Data>
                  <DataLabel>Último pedido</DataLabel>
                  <DataText>{data.data_criacao}</DataText>
                </Data>
              </DataColumn>
            </Infos>
          </div>
        )
      )}
    </Container>
  );
}

export default MobileItem;
