import React, { FormEvent, useEffect, useState } from 'react';
import { mutate } from 'swr';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import * as Yup from 'yup';
import { RolesFetch } from 'fetches/rolesFetch';

import { useToast } from 'hooks/toast';
import { ButtonProgress } from 'components/StyledComponents';
import api from 'services/api';

import { Erros, Props } from './types';
import useStyles from '../FormEditUser/styles';

const EmailSchema = Yup.object().shape({
  email: Yup.string().required('Campo obrigatório').email('Email inválido'),
});

const defaultErros = {
  email: '',
};

function FormAddUser({ currentPage }: Props) {
  const { addToast } = useToast();
  const classes = useStyles();

  const [allFilled, setAllFilled] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [funcao, setFuncao] = useState('');
  const [loading, setLoading] = useState(false);
  const [erros, setErros] = useState<Erros>(defaultErros);

  const { data: funcoes } = RolesFetch();

  useEffect(() => {
    setAllFilled(name !== '' && email !== '' && funcao !== '');
  }, [name, email, funcao]);

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    const data = { nome: name, email, funcao_id: funcao };
    setErros(defaultErros);
    EmailSchema.validate(
      { email },
      {
        abortEarly: false,
      },
    )
      .then(async () => {
        setLoading(true);
        api
          .post('/core/usuarios', { user_form: data })
          .then(() => {
            mutate(`users_${currentPage}`);

            api.post('/core/senha/enviar-email', { email });
            addToast({
              type: 'success',
              title: 'Usuário Inserido',
              description:
                'Usuário criado e email de troca de senha enviado com sucesso!',
            });
            setName('');
            setEmail('');
            setFuncao('');
          })
          .catch(error => {
            addToast({
              type: 'error',
              title: 'Erro na autenticação',
              description: error?.data?.message,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro na Criação de Usuário.',
          description: err.message,
        });
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" justifyContent="space-between">
        <TextField
          id={name}
          value={name}
          aria-readonly
          fullWidth
          label="Nome"
          margin="dense"
          name="name"
          required
          variant="outlined"
          className={classes.input}
          onChange={e => setName(e.target.value)}
        />
        <TextField
          id={email}
          value={email}
          inputProps={{
            form: {
              autocomplete: 'off',
            },
          }}
          aria-readonly
          fullWidth
          label="Email"
          margin="dense"
          name="email"
          required
          variant="outlined"
          className={classes.input}
          error={!email && !!erros.email}
          helperText={!email && erros.email}
          onFocus={() => setErros(defaultErros)}
          onChange={e => setEmail(e.target.value)}
        />
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel>Função</InputLabel>
          <Select
            value={funcao}
            required
            onChange={e => {
              setFuncao(e.target.value as string);
            }}
            label="Função"
          >
            {funcoes?.map(item => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.nome}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box textAlign="right" marginTop={2}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!allFilled || loading}
        >
          Cadastrar novo usuário
          {loading && <ButtonProgress size={24} />}
        </Button>
      </Box>
    </form>
  );
}

export default FormAddUser;
