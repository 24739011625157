import {
  CardContent as MuiCardContent,
  Card as MuiCard,
  Button as MuiButton,
  DialogActions as MuiDialogActions,
  Typography as MuiTypography,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import palette from 'theme/palette';

export const PageContent = styled(MuiCardContent)`
  display: flex;
  flex-direction: column;
  width: 632px;
  gap: 15px;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export const Card = styled(MuiCard)`
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 20px;
  box-shadow: 0px 2px 12px 0px ${palette.shadow.primary};
  border-radius: 6px;
`;

export const Item = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

export const Button = styled(MuiButton)`
  border-radius: 6px;
  height: 36px;
  border-radius: 6px;
  padding: 6px 16px 6px 16px;
`;

export const DialogActions = styled(MuiDialogActions)`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

export const ItemButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
`;

export const HelperTextButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const HelperText = styled(Typography)`
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #00000080;
`;

export const Total = styled(MuiCard)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 69px;
  height: 36px;
  padding: 6px 10px;
  gap: 5px;
`;

export const LabelTotal = styled(MuiTypography)`
  font-weight: 400;
  font-size: 10px;
  line-height: 11.72px;
  color: #00000080;
`;
