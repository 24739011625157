import { useCallback, useState } from 'react';
import {
  Button,
  Popper,
  Modal,
  Backdrop,
  Fade,
  DialogActions,
  ClickAwayListener,
  Box,
} from '@mui/material';
import { ContentCut, Delete, Edit } from '@mui/icons-material';
import { Actions, UseStyles } from 'components/PopperActions';
import api from 'services/api';
import { useNavigate } from 'react-router-dom';

import { ModalCard, UseStylesModal } from 'components/ModalCard';
import { ButtonProgress } from 'components/StyledComponents';
import { useToast } from 'hooks/toast';

interface PopperActionsProps {
  id: string;
  open: boolean;
  anchorElement: null | HTMLElement;
  configurationId: number;
  configurationName: string;
  handleClick(event: React.MouseEvent<HTMLElement>): void;
  updateConfigurations?(): void;
  handleClickAway(): void;
}

function PopperActions({
  id,
  open,
  anchorElement,
  configurationId,
  configurationName,
  handleClick,
  updateConfigurations,
  handleClickAway,
}: PopperActionsProps) {
  const classes = UseStyles();
  const classesModal = UseStylesModal();

  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const nome = openModal ? `${configurationName}` : undefined;

  const navigate = useNavigate();
  const { addToast } = useToast();

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      setLoading(true);
      api
        .delete(`/core/pacote-configuracoes/${configurationId}`)
        .then(() => {
          addToast({
            type: 'success',
            title: 'Exclusão de definição de oferta',
            description: 'Oferta  excluida com sucesso',
          });
          updateConfigurations && updateConfigurations();
          setLoading(false);
        })
        .catch(error => {
          addToast({
            type: 'error',
            title: 'Error',
            description: error?.data?.message,
          });
          handleClose();
          setLoading(false);
        });
      handleClick(e);
    },
    [configurationId, handleClick, addToast, updateConfigurations],
  );

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClickAway}
    >
      <Box>
        {open ? (
          <Popper
            id={id}
            open={open}
            anchorEl={anchorElement}
            placement="bottom"
            modifiers={[
              {
                name: 'flip',
                enabled: true,
              },
              {
                name: 'preventOverflow',
                enabled: true,
                options: {
                  boundariesElement: 'scrollParent',
                },
              },
              {
                name: 'arrow',
                enabled: true,
                options: {
                  element: arrowRef,
                },
              },
            ]}
            className={classes.popper}
          >
            <span className={classes.arrow} ref={setArrowRef} />
            <Actions className={classes.paper}>
              <Button
                startIcon={<Edit />}
                fullWidth
                className={classes.button}
                onClick={() =>
                  navigate(`/flash-sales/edita-oferta/${configurationId}`)
                }
              >
                Editar
              </Button>
              <Button
                startIcon={<ContentCut />}
                fullWidth
                className={classes.button}
                onClick={() =>
                  navigate(`/flash-sales/cria-apartir/${configurationId}`)
                }
              >
                Criar a partir
              </Button>
              <Button
                onClick={handleOpen}
                startIcon={<Delete />}
                fullWidth
                className={classes.button}
              >
                Deletar
              </Button>
            </Actions>
            <Modal
              aria-labelledby="tile-modal"
              aria-describedby="modal-description"
              className={classesModal.modal}
              open={openModal}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 200,
              }}
            >
              <Fade in={openModal}>
                <ModalCard className={classesModal.paper}>
                  <h2 id="title-modal">Deseja deletar a oferta?</h2>
                  <p id="modal-description">
                    Essa operação deletará a oferta {nome}. Não será possivel
                    voltar essa operação, após confirmação.
                  </p>

                  <DialogActions
                    style={{
                      justifyContent: 'space-between',
                      paddingRight: '24px',
                      paddingLeft: '32px',
                      paddingTop: '24px',
                      paddingBottom: '32px',
                    }}
                  >
                    <Button
                      style={{ color: '#C92A2A' }}
                      onClick={() => setOpenModal(false)}
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={handleDelete}
                      color="primary"
                      type="button"
                      variant="contained"
                    >
                      Confirmar
                      {loading && <ButtonProgress size={24} />}
                    </Button>
                  </DialogActions>
                </ModalCard>
              </Fade>
            </Modal>
          </Popper>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
}

export default PopperActions;
