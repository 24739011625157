import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  margin-bottom: 30px;
`;

export const Action = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const DataColumn = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: auto auto;

  @media (min-width: 768px) {
    display: grid;
    grid-column-gap: 50px;
    grid-template-columns: auto auto auto auto;
  }
`;

export const Infos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const DataLabel = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 5px;
`;

export const GroupData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MobileDataText = styled.span`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 15px;

  min-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Status = styled.img`
  align-self: flex-end;
`;

export const MobileTextName = styled.span`
  width: 250px;
  font-size: 16px;
  word-break: break-word;
  margin-bottom: 15px;
  font-weight: 500;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.8);
`;

export const Row = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const MobileDataTextName = styled.span`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 15px;

  min-width: 250px;
  word-break: break-word;
  text-overflow: ellipsis;
`;
