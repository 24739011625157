import { Add } from '@mui/icons-material';
import { Button, CardHeader } from '@mui/material';
import { Content } from 'components/CardList';
import { Card, Header } from 'components/StyledComponents';
import { OffersMarketingFetch } from 'fetches/offersMarketingFetch';
import useBreakpoint from 'hooks/useBreakpoint';
import { OfferMarketing } from 'models/offersMarketing';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import palette from 'theme/palette';
import DialogDeleteOfferLink from './components/DialogDeleteOfferLink';
import OffersLinksList from './components/OffersLinksList';

function OfferLinks() {
  const { data: offers, mutate } = OffersMarketingFetch();

  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [offerSelected, setOfferSelected] = useState<OfferMarketing>();

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const navigate = useNavigate();

  const handleOpenDeleteOfferDialog = () => {
    setOpenDialogDelete(true);
  };

  const handleCloseDeleteOfferDialog = () => {
    setOpenDialogDelete(false);
  };
  return (
    <Card>
      <Header>
        <CardHeader
          title="Links de Ofertas"
          subheader="Painel de criação e edição de links de ofertas."
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate('/links-ofertas/cadastro-link');
          }}
        >
          {isTableOrLess ? (
            <Add style={{ color: palette.white }} />
          ) : (
            'Criar novo'
          )}
        </Button>
      </Header>
      <Content>
        <OffersLinksList
          offers={offers}
          setOfferSelected={setOfferSelected}
          handleOpenDeleteOfferDialog={handleOpenDeleteOfferDialog}
        />
      </Content>

      {offers && offerSelected && (
        <DialogDeleteOfferLink
          id={offerSelected.id}
          slug={offerSelected.url_slug}
          offers={offers}
          openDialogDeleteOffer={openDialogDelete}
          handleClose={handleCloseDeleteOfferDialog}
          updateOffers={mutate}
        />
      )}
    </Card>
  );
}

export default OfferLinks;
