import { TextField } from '@mui/material';
import { CardOffers, TitleOffers, useStyles } from '../styles';

interface WhatsappProps {
  whatsappUrl: string;

  setWhatsappUrl(value: string): void;
}

function Whatsapp({ whatsappUrl, setWhatsappUrl }: WhatsappProps) {
  const classes = useStyles();
  return (
    <CardOffers>
      <TitleOffers>Whatsapp</TitleOffers>
      <TextField
        fullWidth
        label="Inserir URL"
        margin="dense"
        name="inserirURL"
        value={whatsappUrl || ''}
        onChange={e => {
          setWhatsappUrl(e.target.value);
        }}
        size="small"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Digite aqui"
        InputProps={{
          classes: { input: classes.input },
        }}
      />
    </CardOffers>
  );
}

export default Whatsapp;
