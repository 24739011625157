import styled from 'styled-components';

export const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 42px 15px 58px;
  color: rgba(0, 0, 0, 0.4);
  span {
    :nth-child(1) {
      width: 600px;
    }
    :nth-child(2) {
      margin-left: auto;
      width: 103px;
    }
    :nth-child(3) {
      width: 103px;
    }
    :nth-child(4) {
      width: 103px;
    }
    :nth-child(5) {
      width: 103px;
      margin-left: 20px;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    align-self: flex-start;
  }

  @media (max-width: 530px) {
    margin: 15px 22px 15px 51px;

    span {
      :nth-child(1) {
        width: 240px;
      }
      :nth-child(2) {
        margin-left: auto;
      }
      :nth-child(3) {
        margin-left: auto;
      }
      :nth-child(4) {
        margin-left: 10px;
      }
      overflow: visible;
    }
  }
`;

export const DataInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 24px;

  & span:first-child {
    width: 595px;
  }

  & span:nth-child(2) {
    margin-left: auto;
  }

  @media (max-width: 920px) {
    & span:first-child {
      width: 108px;
    }
  }
`;

export const DataText = styled.span`
  min-width: 105px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  display: flex;
  align-items: center;

  @media (max-width: 920px) {
    min-width: 84px;
  }

  @media (max-width: 530px) {
    min-width: 60px;
  }

  @media (max-width: 404px) {
    min-width: 52px;
  }
`;

export const TableRow = styled.div`
  margin-left: auto;
  margin-bottom: 15px;
  display: flex;
`;

export const TableInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 24px;

  span {
    font-size: 14px;
    font-weight: 700;
    line-height: 24.5px;
  }
`;
