import { Modal, Backdrop, Fade, DialogActions, Button } from '@mui/material';
import { ButtonProgress } from 'components/StyledComponents';
import { useStylesModal } from 'components/UseStylesModal';
import { ModalCard, TextModal, TitleModal } from './styles';

interface DeleteModalProps {
  title?: string;

  text?: string;

  loading: boolean;

  openModalDelete: boolean;

  setOpenModalDelete(value: boolean): void;

  handleDelete(): void;

  handleClose(): void;
}

function DeleteModal({
  title,
  text,
  loading,
  openModalDelete,
  setOpenModalDelete,
  handleDelete,
  handleClose,
}: DeleteModalProps) {
  const classes = useStylesModal();
  return (
    <Modal
      aria-labelledby="tile-modal"
      aria-describedby="modal-description"
      className={classes.modal}
      open={openModalDelete}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 200,
      }}
    >
      <Fade in={openModalDelete}>
        <ModalCard>
          <TitleModal id="title-modal">{title}</TitleModal>
          <TextModal>{text}</TextModal>
          <DialogActions className={classes.dialogActions}>
            <Button
              className={classes.buttonCancel}
              variant="outlined"
              onClick={() => setOpenModalDelete(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={handleDelete}
              color="primary"
              disabled={loading}
            >
              Confirmar
              {loading && <ButtonProgress size={24} />}
            </Button>
          </DialogActions>
        </ModalCard>
      </Fade>
    </Modal>
  );
}

export default DeleteModal;
