import styled from 'styled-components';

interface BadgeProps {
  type: 'ativo' | 'inativo';
}

export const Badge = styled.span<BadgeProps>`
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  padding: 2px 0;
  border-radius: 6px;
  width: 60px;
  height: 18px;

  color: ${props => (props.type === 'ativo' ? '#30C952' : '#00364D')};
  background: ${props => (props.type === 'ativo' ? '#D6F4DC' : '#F2F2F2')};
`;
