import useSWR from 'swr';
import api from 'services/api';
import { LoyaltyByPlan } from 'models/loyaltyByPlan';

export const LoyaltyByPlanFetch = (plano_tim_id: string) => {
  const { data, mutate } = useSWR<LoyaltyByPlan[]>(
    `loyalty_${plano_tim_id}`,
    async (): Promise<LoyaltyByPlan[]> => {
      const params = {
        plano_tim_id,
      };
      const { data: loyalties } = await api.get(`/core/fidelidades/plano`, {
        params,
      });

      return loyalties as LoyaltyByPlan[];
    },
  );
  return { data, mutate };
};
