import { Checkbox, Grid, Skeleton } from '@mui/material';
import { format } from 'date-fns';
import { NewAffiliateGoal } from 'pages/ManagerGoal/types';
import { useGoalSelectStore } from 'store/goalSelectStore';
import { Box, Day, MetaEdited, MetaText, useStyles } from './styles';

interface ItemProps {
  affiliate: NewAffiliateGoal;
  isMobile: boolean;
  setGoalSelected(value: NewAffiliateGoal): void;
  setShowEditGoal(value: boolean): void;
}

function Item({
  affiliate,
  isMobile,
  setGoalSelected,
  setShowEditGoal,
}: ItemProps) {
  const selectedGoalsCheckbox = useGoalSelectStore(
    store => store.selectedGoalsCheckbox,
  );
  const setSelectedGoalsCheckbox = useGoalSelectStore(
    store => store.setSelectedGoalsCheckbox,
  );

  const classes = useStyles();
  const currentDate = format(new Date(), 'yyyy-MM-dd');

  const isPossibleEdit = affiliate.date >= currentDate;

  return (
    <Box
      onClick={() => {
        setGoalSelected(affiliate);
        setShowEditGoal(true);
      }}
      isMobile={isMobile}
      isPossibleEdit={isPossibleEdit}
    >
      <Grid container className={classes.gridContainer}>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <Day edited={affiliate.edited}>{affiliate.dayOfMonth}</Day>
          </Grid>
          {!isMobile && (
            <Grid item className={classes.gridItem}>
              <MetaText>Meta</MetaText>
              <p>{affiliate.meta ? affiliate.meta : <Skeleton width={85} />}</p>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          onClick={e => {
            e.stopPropagation();
          }}
          className={classes.gridAreaCheckbox}
        >
          <Checkbox
            style={{ padding: 0 }}
            checked={selectedGoalsCheckbox.some(
              goal => goal.date === affiliate.date,
            )}
            onChange={event => {
              const isChecked = event.target.checked;
              const dateToFilter = affiliate.date;
              const updatedSelectedGoalsCheckbox = isChecked
                ? [...selectedGoalsCheckbox, affiliate]
                : selectedGoalsCheckbox.filter(
                    goal => goal.date !== dateToFilter,
                  );
              setSelectedGoalsCheckbox(updatedSelectedGoalsCheckbox);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
            disabled={!affiliate.meta || !isPossibleEdit}
          />
          {!isMobile && affiliate.edited && <MetaEdited>Editado</MetaEdited>}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Item;
