import { MobileContainer, MobileDataLabel } from 'components/StyledComponents';
import { MobileDataText } from 'components/MobileList';
import { Box, Skeleton } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useSatisfactionSurveyStore } from 'store/satisfactionSurveyStore';
import { ItemProps } from '../Item';
import { GroupData, MobileData, MobileTextName } from './styles';
import { StyledBoxRating, StyledRating } from '../styles';

function MobileItem({ data, loading = false }: ItemProps) {
  const setIdSatisfactionSurveySelected = useSatisfactionSurveyStore(
    state => state.setIdSatisfactionSurveySelected,
  );
  return (
    <MobileContainer>
      {loading ? (
        <Box>
          <GroupData>
            <MobileData>
              <MobileDataLabel>Data</MobileDataLabel>
              <MobileTextName>
                <Skeleton />
              </MobileTextName>
            </MobileData>
            <MobileData>
              <MobileDataLabel>ID</MobileDataLabel>
              <MobileTextName>
                <Skeleton />
              </MobileTextName>
            </MobileData>
          </GroupData>

          <GroupData>
            <MobileData>
              <MobileDataLabel>Origem</MobileDataLabel>
              <MobileTextName>
                <Skeleton />
              </MobileTextName>
            </MobileData>
          </GroupData>

          <GroupData>
            <MobileData>
              <MobileDataLabel>Comentário</MobileDataLabel>
              <MobileTextName>
                <Skeleton />
              </MobileTextName>
            </MobileData>
          </GroupData>

          <GroupData>
            <MobileData>
              <MobileDataLabel>Avaliação</MobileDataLabel>
              <MobileTextName>
                <Skeleton />
              </MobileTextName>
            </MobileData>
          </GroupData>
        </Box>
      ) : (
        data && (
          <Box
            onClick={() => setIdSatisfactionSurveySelected(data.id.toString())}
          >
            <GroupData>
              <MobileData>
                <MobileDataLabel>Data</MobileDataLabel>
                <MobileDataText style={{ wordBreak: 'keep-all' }}>
                  {`${format(
                    parseISO(data.created_at),
                    'yyyy/MM/dd',
                  )} às ${format(parseISO(data.created_at), 'hh:mm')}`}
                </MobileDataText>
              </MobileData>
              <MobileData>
                <MobileDataLabel>ID</MobileDataLabel>
                <MobileDataText>{data.id}</MobileDataText>
              </MobileData>
            </GroupData>

            <GroupData>
              <MobileData>
                <MobileDataLabel>Origem</MobileDataLabel>
                <MobileTextName>{data.origem}</MobileTextName>
              </MobileData>
            </GroupData>

            <GroupData>
              <MobileData>
                <MobileDataLabel>Comentário</MobileDataLabel>
                <MobileTextName>{data.melhoria}</MobileTextName>
              </MobileData>
            </GroupData>

            <GroupData>
              <MobileData>
                <MobileDataLabel>Avaliação</MobileDataLabel>
                <StyledBoxRating borderColor="transparent">
                  <StyledRating value={data.nota} readOnly />
                </StyledBoxRating>
              </MobileData>
            </GroupData>
          </Box>
        )
      )}
    </MobileContainer>
  );
}

export default MobileItem;
