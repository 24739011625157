import useSWR from 'swr';
import api from 'services/api';
import { CategoryPackage, Package } from 'models/packages';

interface Params {
  ativo?: string | null;
  plano_id?: number;
}
interface FetchProps {
  status?: string;
  plano_id?: number;
}

export const ActivePackagesFetch = () => {
  const { data, mutate } = useSWR<Package[]>(
    'packages',
    async (): Promise<Package[]> => {
      const { data: packages } = await api.get('/core/pacotes/ativos');

      return packages as Package[];
    },
  );
  return { data, mutate };
};

export const PackageWithCategoryFetch = ({ status }: FetchProps) => {
  const { data, mutate } = useSWR<CategoryPackage[]>(
    `packagesWithCategory_${status}`,
    async (): Promise<CategoryPackage[]> => {
      const params: Params = {};

      if (status) {
        params.ativo = status;
      }

      const { data: packages } = await api.get('/core/pacotes/categorias', {
        params,
      });

      return packages as CategoryPackage[];
    },
  );
  return { data, mutate };
};

export const PackageByPlanFetch = ({ plano_id }: FetchProps) => {
  const { data, mutate } = useSWR<CategoryPackage[]>(
    `packagesByPlan_${plano_id}`,
    async (): Promise<CategoryPackage[]> => {
      const params: Params = {};

      if (plano_id) {
        params.plano_id = plano_id;
      }

      const { data: packages } = await api.get('/core/pacotes/plano', {
        params,
      });

      return packages as CategoryPackage[];
    },
  );
  return { data, mutate };
};

export const PackageWithUnratedFetch = () => {
  const { data, mutate } = useSWR<boolean>(
    'packagesUnrated',
    async (): Promise<boolean> => {
      const { data: packages } = await api.get(
        '/core/pacotes/sem-classificacao',
      );
      return packages as boolean;
    },
  );
  return { data, mutate };
};
