import { createStyles, makeStyles } from '@mui/styles';
import styled from 'styled-components';

interface LoadingInfo {
  width: number;
}

export const HelperText = styled.p`
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: 0.12px;
`;

export const CardSimulatorBenefit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  gap: 15px;
`;

export const BoxSimulatorBenefit = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #0026d9;
  border-radius: 6px;
  padding: 32px;
`;

export const LoadingInfo = styled.div<LoadingInfo>`
  width: ${props => props.width}px;
  height: 20px;
  background-color: rgba(204, 204, 204, 1);
  align-self: stretch;
  flex-grow: 0;
`;

export const useStyles = makeStyles(() =>
  createStyles({
    input: {
      '&::placeholder': {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14px',
      },
    },
    boxLoadingInfoTextBenefit: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2px',
    },
    simulationText: {
      color: 'rgba(0, 0, 0, 0.5)',
      marginBottom: 14,
    },
    saveButton: {
      marginBottom: 30,
    },
  }),
);
