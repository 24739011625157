import styled from 'styled-components';

interface CardProp {
  badgeColor: string;
}

export const DataInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 10px;
`;

export const DataTextName = styled.span`
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Badge = styled.div<CardProp>`
  align-items: center;
  background-color: ${props => props.badgeColor || 'rgba(48, 174, 201, 0.2)'};
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  padding: 2px 6px 2px 6px;
  display: inline-block;
  align-content: center;
  border-radius: 6px;
  cursor: pointer;
`;
