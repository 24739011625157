import { useState } from 'react';
import { Card } from 'components/StyledComponents';
import { CardContent, CardHeader } from '@mui/material';
import useBreakpoint from 'hooks/useBreakpoint';
import { AffiliatesFetch } from 'fetches/affiliatesFetch';
import Pagination from 'components/Pagination';
import { TotalizatorUsefulLeadsFetch } from 'fetches/totalizatorUsefulLeadsFetch';
import TotalizerCard from 'components/TotalizerCard';
import ImportBase from './ImportBase';
import { Header } from './styles';
import AffiliateBaseList from './AffiliateBaseList';

function AffiliateBase() {
  const [currentPage, setCurrentPage] = useState(1);

  const { data: response, mutate } = AffiliatesFetch({
    page: currentPage,
  });
  const { data: responseTotalizator } = TotalizatorUsefulLeadsFetch();

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Card>
      <Header isDesktop={!isTableOrLess}>
        <CardHeader
          title="Bases de afiliados"
          subheader="Painel de importação de bases tratáveis de afiliados."
          style={{ padding: 20 }}
        />
        <TotalizerCard
          title="TOTAL DE LEADS ÚTEIS"
          total={responseTotalizator?.total}
          isDesktop={!isTableOrLess}
        />
      </Header>

      <CardContent>
        <ImportBase appendNewAffiliateBase={mutate} />
        <AffiliateBaseList affiliates={response?.affiliates} />
        {response && (
          <Pagination
            currentPage={currentPage}
            lastPage={response?.paginationInfo.lastPage}
            perPage={response?.paginationInfo.perPage}
            total={response?.paginationInfo.total}
            setCurrentPage={setCurrentPage}
          />
        )}
      </CardContent>
    </Card>
  );
}

export default AffiliateBase;
