import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import { MTheme } from 'models/theme';

export const Logo = styled.img`
  width: 44px;
`;

const useStyles = makeStyles((theme: MTheme) => ({
  root: {
    height: 124,
    backgroundColor: theme.palette.white,
    borderRadius: 6,
    filter: 'box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2)',
    boxShadow: '0px 2px 12px 0px #0000001A',
  },
  dividerTop: {
    backgroundColor: '#F04E98',
    height: 6,
  },
  box: {
    width: 80,
    minWidth: 60,
    height: 124,
    paddingTop: 20,
    paddingLeft: 25,
    [theme.breakpoints.down(1343)]: {
      width: 75,
      paddingLeft: 20,
    },
  },
  icon: {
    width: 45,
    height: 45,
  },
  title: {
    fontSize: 22,
    fontWeight: 400,
    color: '#050C27',
    marginBottom: 8,
  },
  subtitle: {
    fontWeight: 400,
    color: theme.palette.primary.gray,
    [theme.breakpoints.down(1343)]: {
      width: 200,
    },
    [theme.breakpoints.down(600)]: {
      width: 160,
    },
  },
}));

export default useStyles;
