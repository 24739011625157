import { useToast } from 'hooks/toast';
import { ButtonProgress, ContainerForm } from 'components/StyledComponents';
import React, { useState } from 'react';
import api from 'services/api';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Sale } from '../types';

interface ReprocessSaleProps {
  sale: Sale;
  setSale(sale: Sale): void;
}

function ReprocessSale({ sale, setSale }: ReprocessSaleProps) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { addToast } = useToast();

  const reprocessSale = () => {
    setOpen(false);
    setLoading(true);
    const url = '/core/vendas/atualizar-detalhe';

    return api
      .put(`${url}/${sale?.id}`, {
        status: 'REPROCESSAR_VENDA',
      })
      .then(() => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Venda reprocessada com sucesso!',
        });
        setOpen(false);
      })
      .catch(error => {
        setLoading(false);

        addToast({
          type: 'error',
          title: 'Erro no reprocessamento',
          description: error?.data?.message,
        });
      })
      .finally(() => {
        api
          .get(`core/vendas/${sale.id}`)
          .then(({ data }) => setSale({ ...sale, ...data }));
      });
  };

  return (
    <ContainerForm>
      <Button
        type="button"
        variant="contained"
        color="primary"
        disabled={loading}
        fullWidth
        size="large"
        onClick={() => setOpen(true)}
      >
        Reprocessar
        {loading && <ButtonProgress size={24} />}
      </Button>

      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Deseja realmente reprocessar esta venda?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Ao clicar em Sim, a venda será REPROCESSADA e não será mais possível
            desfazer esta ação.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Não
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              reprocessSale();
            }}
            color="primary"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </ContainerForm>
  );
}

export default ReprocessSale;
