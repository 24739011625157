import { SwipeableDrawer } from '@mui/material';
import { LPBenefit } from 'models/lpBenefits';
import { useState } from 'react';
import HeaderList from '../components/HeaderList';
import BenefitList from './BenefitsList';
import NewBenefit from './NewBenefit';

interface BenefitsProps {
  benefits?: LPBenefit[];
  setBenefits(benefits: LPBenefit[]): void;
  appendNewBenefit(newBenefit: LPBenefit): void;
  updateBenefits(faqs: LPBenefit[], showRevalid?: boolean): void;
}

function Benefits({
  benefits,
  setBenefits,
  appendNewBenefit,
  updateBenefits,
}: BenefitsProps) {
  const [showBenefitNew, setShowBenefitNew] = useState(false);

  const lastOrderBenefit = benefits?.reduce(
    (max, benefit) => (benefit.ordem > max ? benefit.ordem : max),
    0,
  );

  return (
    <>
      <HeaderList
        title="Benefícios"
        buttonInfo="Novo benefício"
        setShowRegistrationDialog={setShowBenefitNew}
      />

      <BenefitList
        benefits={benefits}
        setBenefits={setBenefits}
        updateBenefits={updateBenefits}
      />

      <SwipeableDrawer
        anchor="right"
        onClose={() => {
          setShowBenefitNew(false);
        }}
        onOpen={() => setShowBenefitNew(true)}
        open={showBenefitNew}
      >
        <NewBenefit
          closeDrawer={() => {
            setShowBenefitNew(false);
          }}
          appendNewBenefit={appendNewBenefit}
          lastOrderBenefit={lastOrderBenefit}
        />
      </SwipeableDrawer>
    </>
  );
}

export default Benefits;
