import {
  ActionButtons,
  Container,
  Data,
  DataInfo,
  Divider,
  useStylesActionButtons,
} from 'components/StyledComponents';
import { convertDateInString as dateFormatter } from 'util/formatter';
import { IconButton, Skeleton } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useNavigate } from 'react-router-dom';
import { ReprocessingOfReachedCustomerBase } from 'models/reprocessingOfReachedCustomerBase';
import React from 'react';
import { DataText, DataTextName } from './styles';
import StatusCard from '../../../StatusCard';

export interface ItemProps {
  data?: ReprocessingOfReachedCustomerBase;
  loading?: boolean;
}

function Item({ data, loading }: ItemProps) {
  const navigate = useNavigate();

  const classes = useStylesActionButtons();
  return (
    <Container>
      <Data>
        {loading ? (
          <DataInfo>
            <DataText>
              <Skeleton width={85} />
            </DataText>
            <DataText>
              <Skeleton width={85} />
            </DataText>
            <DataText>
              <Skeleton width={90} />
            </DataText>
            <DataText>
              <Skeleton width={90} />
            </DataText>
            <DataText>
              <Skeleton width={90} />
            </DataText>
            <DataText>
              <Skeleton width={90} />
            </DataText>
          </DataInfo>
        ) : (
          data && (
            <DataInfo>
              <DataText style={{ width: 250 }}>
                {dateFormatter(data.created_at)}
              </DataText>
              <DataTextName style={{ width: 150 }}>
                <StatusCard status={data.status} />
              </DataTextName>
              <DataTextName style={{ width: '40%', maxWidth: '40%' }}>
                {data.nome}
              </DataTextName>
              <DataTextName style={{ width: 150 }}>
                {data.para_despausar}
              </DataTextName>
              <DataTextName style={{ width: 150 }}>
                {data.para_processar}
              </DataTextName>
              <DataTextName style={{ width: 150 }}>
                {data.nao_responderam}
              </DataTextName>
            </DataInfo>
          )
        )}
      </Data>
      <ActionButtons>
        <IconButton
          onClick={() =>
            navigate(`/reprocessamento-total/visualiza-base/${data?.id}`)
          }
          disabled={loading}
          className={classes.iconView}
        >
          <VisibilityOutlinedIcon />
        </IconButton>
      </ActionButtons>
      <Divider />
    </Container>
  );
}

export default Item;
