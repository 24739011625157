import { useState } from 'react';
import { useToast } from 'hooks/toast';
import api from 'services/api';

import { ButtonProgress, ContainerForm } from 'components/StyledComponents';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { Sale } from '../types';

interface ConsultStatusProps {
  sale: Sale;
  setSale(sale: Sale): void;
}

function ConsultStatus({ sale, setSale }: ConsultStatusProps) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { addToast } = useToast();

  const consultStatusSale = () => {
    setOpen(false);
    setLoading(true);
    const url = '/core/vendas/atualizar-detalhe';

    return api
      .put(`${url}/${sale?.id}`, {
        status: 'CONSULTAR_STATUS',
      })
      .then(() => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Consulta efetuada com sucesso!',
        });
        setOpen(false);
      })
      .catch(error => {
        setLoading(false);
        let message = error.data.errors[0]?.message;
        if (!message) {
          message = error.data.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro na consulta',
            description: message,
          });
      })
      .finally(() => {
        api
          .get(`core/vendas/${sale.id}`)
          .then(({ data }) => setSale({ ...sale, ...data }));
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ContainerForm>
      <Button
        type="button"
        variant="contained"
        color="primary"
        disabled={loading}
        fullWidth
        size="large"
        onClick={() => setOpen(true)}
      >
        Consulta de Status
        {loading && <ButtonProgress size={24} />}
      </Button>

      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Deseja realmente consultar o status desta venda?
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Não
          </Button>
          <Button onClick={consultStatusSale} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </ContainerForm>
  );
}

export default ConsultStatus;
