import styled from 'styled-components';

export const FilterMargin = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 1023px) {
    flex-direction: column;
    margin: 5px 0;
    width: 100%;
  }
`;
