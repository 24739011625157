import styled from 'styled-components';

interface HeaderProps {
  plan_id?: number;
}

export const Header = styled.div<HeaderProps>`
  flex: 1;
  display: flex;
  align-items: center;
  margin: ${props =>
    props.plan_id ? '15px 70px 15px 25px' : '15px 25px 15px 25px'};
  color: rgba(0, 0, 0, 0.4);

  & span:first-child {
    width: 170px;
  }

  span {
    width: 150px;
    white-space: nowrap;
    font-size: 15px;
    align-self: flex-start;
  }
`;

export const TitleTypeBenefit = styled.p`
  display: flex;
  align-items: center;
  padding-top: 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #546e7a;
`;
