import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { statusColors, useStyles } from './styles';

interface StatusProps {
  status: string;
}

interface StatusConfType {
  name: string;
  color: string;
}

interface statusConfListType {
  NOVA: StatusConfType;
  PROCESSANDO: StatusConfType;
  PROCESSADA: StatusConfType;
  FALHOU: StatusConfType;
  [key: string]: StatusConfType;
}

const statusConfList: statusConfListType = {
  NOVA: {
    name: 'Nova',
    color: statusColors.new,
  },
  PROCESSANDO: {
    name: 'Processando',
    color: statusColors.processing,
  },
  PROCESSADA: {
    name: 'Processada',
    color: statusColors.processed,
  },
  FALHOU: {
    name: 'Falhou',
    color: statusColors.failed,
  },
};

function Status({ status }: StatusProps) {
  const [statusConf, setStatusConf] = useState<StatusConfType>(
    statusConfList[status],
  );

  useEffect(() => {
    status && setStatusConf(statusConfList[status]);
  }, [status]);

  const classes = useStyles();

  return (
    <Box
      className={classes.status}
      style={{
        color: statusConf.color,
      }}
    >
      <span>{statusConf.name}</span>
    </Box>
  );
}

export default Status;
