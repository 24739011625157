import { Card } from 'components/StyledComponents';
import { OfferMarketingByIdFetch } from 'fetches/offerMarketingByIdFetch';
import { PlansPriceFetch } from 'fetches/plansPriceFetch';
import { useToast } from 'hooks/toast';
import { OfferMarketing } from 'models/offersMarketing';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from 'services/api';
import { ConfigurationPackageByIdPlanFetch } from 'fetches/configurationPackageByIdPlanFetch';
import { PlanPrice } from 'models/plansPrice';
import FormOfferLink from '../FormOfferLink';
import { useStyles } from '../styles';

function EditOfferLink() {
  const params = useParams();

  const { id } = params;
  const { data: response, mutate } = OfferMarketingByIdFetch(
    parseInt(id || '0', 10),
  );

  const [offer, setOffer] = useState<OfferMarketing>({} as OfferMarketing);
  const [urlSlug, setUrlSlug] = useState('');
  const [fidelity, setFidelity] = useState('');
  const [prohibitPaymentMethod, setProhibitPaymentMethod] = useState('');
  const [planSelected, setPlanSelected] = useState<PlanPrice | null>(null);
  const [idOfferSelected, setIdOfferSelected] = useState('');
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [segment, setSegment] = useState('');

  const { data: pricesPlan } = PlansPriceFetch(segment);
  const { data: configs } = ConfigurationPackageByIdPlanFetch(
    typeof planSelected?.plano_id === 'number' ? planSelected.plano_id : 0,
  );

  const classes = useStyles();
  const navigate = useNavigate();
  const { addToast } = useToast();

  useEffect(() => {
    if (!response) {
      return;
    }

    setOffer(response);
  }, [response]);

  useEffect(() => {
    setIsValid(!!(urlSlug && fidelity && planSelected?.preco_id));
  }, [fidelity, planSelected?.preco_id, urlSlug]);

  useEffect(() => {
    setUrlSlug(offer.url_slug || '');
    setFidelity(offer.fidelidade ? 'sim' : 'nao');
    setProhibitPaymentMethod(
      offer.proibir_troca_forma_pagamento ? 'sim' : 'nao',
    );
    const selectedPlan = pricesPlan?.find(
      plan => plan.preco_id === offer.preco_id,
    );

    setPlanSelected(selectedPlan || null);
    setIdOfferSelected(offer.pacote_configuracoes_id || '');
  }, [offer, pricesPlan]);

  useEffect(() => {
    const selectedPlan = pricesPlan?.find(
      plan => plan.preco_id === offer.preco_id,
    );
    setPlanSelected(selectedPlan || null);
  }, [offer.preco_id, pricesPlan]);

  const handleEdit = useCallback(() => {
    setLoading(true);
    api
      .put(`/core/ofertas/${id}`, {
        id,
        url_slug: urlSlug,
        fidelidade: fidelity === 'sim' ? 1 : 0,
        proibir_troca_forma_pagamento: prohibitPaymentMethod === 'sim' ? 1 : 0,
        preco_id: planSelected?.preco_id,
        pacote_configuracoes_id: idOfferSelected,
      })
      .then(({ data: responseData }) => {
        mutate({ ...response, ...responseData });
        addToast({
          type: 'success',
          title: 'Link editado',
          description: `O link ${urlSlug} foi editado com sucesso no sistema.`,
        });

        navigate('/links-ofertas/');
      })
      .catch(error => {
        let { message } = error.data;

        if (!message) {
          message = error.data.errors[0]?.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao editar link!',
            description: message,
          });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    addToast,
    fidelity,
    id,
    idOfferSelected,
    planSelected?.preco_id,
    mutate,
    navigate,
    prohibitPaymentMethod,
    response,
    urlSlug,
  ]);

  return (
    <Card className={classes.card}>
      <FormOfferLink
        urlSlug={urlSlug}
        setUrlSlug={setUrlSlug}
        fidelity={fidelity}
        setFidelity={setFidelity}
        prohibitPaymentMethod={prohibitPaymentMethod}
        setProhibitPaymentMethod={setProhibitPaymentMethod}
        planSelected={planSelected}
        setPlanSelected={setPlanSelected}
        idOfferSelected={idOfferSelected}
        setIdOfferSelected={setIdOfferSelected}
        segment={segment}
        setSegment={setSegment}
        plans={pricesPlan}
        configs={configs}
        classes={classes}
        isValid={isValid}
        loading={loading}
        navigate={navigate}
        handleRequisitionRequest={handleEdit}
      />
    </Card>
  );
}

export default EditOfferLink;
