import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useToast } from 'hooks/toast';
import { ButtonProgress, ContainerForm } from 'components/StyledComponents';
import api from 'services/api';

import { Sale } from '../types';

interface MarkAsNotFraudProps {
  sale: Sale;
  setSale(sale: Sale): void;
}
function MarkAsNotFraud({ sale, setSale }: MarkAsNotFraudProps) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { addToast } = useToast();

  const markAsNotFraudSale = () => {
    setLoading(true);
    const url = '/core/vendas/atualizar-detalhe';

    return api
      .put(`${url}/${sale?.id}`, {
        status: 'NAO_FRAUDE',
      })
      .then(() => {
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Venda marcada como não fraude com Sucesso!',
        });
      })
      .catch(error => {
        error.data &&
          addToast({
            type: 'error',
            title: 'Erro ao marcar como não fraude',
            description: error.data.message,
          });
      })
      .finally(() => {
        api.get(`core/vendas/${sale.id}`).then(({ data }) => {
          setSale({ ...sale, ...data });
          setLoading(false);
          setOpen(false);
        });
      });
  };
  return (
    <ContainerForm>
      <Button
        type="button"
        variant="contained"
        color="primary"
        disabled={loading}
        fullWidth
        size="large"
        onClick={() => setOpen(true)}
      >
        Marcar como Não Fraude
        {loading && <ButtonProgress size={24} />}
      </Button>

      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Deseja realmente marcar esta venda como não fraude?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Ao clicar em Sim, a venda será marcada como não fraude e não será
            mais possível desfazer esta ação.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Não
          </Button>
          <Button
            onClick={() => {
              markAsNotFraudSale();
            }}
            color="primary"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </ContainerForm>
  );
}

export default MarkAsNotFraud;
