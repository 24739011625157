import userSWR from 'swr';
import api from 'services/api';
import { PlanPrice } from 'models/plansPrice';

interface Params {
  segmento?: string;
}

export const PlansPriceFetch = (segment?: string) => {
  const { data } = userSWR<PlanPrice[]>(
    `prices_${segment}`,
    async (): Promise<PlanPrice[]> => {
      const params: Params = {};

      if (segment !== 'todos') {
        params.segmento = segment;
      }

      const { data: prices } = await api.get(
        '/core/planos/precos/ddd-ataque-defesa',
        { params },
      );

      return prices;
    },
  );

  return { data };
};
