import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSaleSelected } from 'store/saleSelected';
import {
  cell as celFormatter,
  cpf as cpfFormatter,
  dateTime as dateFormatter,
} from 'util/formatter';

import copyIcon from 'assets/icons/small/copy.svg';
import { IconButton, Skeleton } from '@mui/material';
import copyToClipboard from 'util/copyToClipboard';
import {
  Container,
  Data,
  DataInfo,
  DataText,
  Divider,
} from 'components/StyledComponents';
import StatusSale from 'components/StatusSale';
import { DataTextName, WrapperNameInfo } from './styles';

export interface ItemProps {
  loading?: boolean;
  data?: {
    id: number;
    segmento: string;
    status: string;
    data_portabilidade: string;
    cpf: string;
    nome: string;
    provisorio: string;
    msisdn: string;
    data_ativacao: string;
  };
}

function Item({ data, loading = false }: ItemProps) {
  const setSalesSelected = useSaleSelected(state => state.setSalesSelected);

  return (
    <Container onClick={() => data && setSalesSelected(data.id)}>
      <Data>
        {loading ? (
          <>
            <DataText>
              <Skeleton width={150} />
            </DataText>
            <DataText>
              <Skeleton width={150} />
            </DataText>
            <DataText>
              <Skeleton width={150} />
            </DataText>
            <DataText>
              <Skeleton width={150} />
            </DataText>
            <DataText>
              <Skeleton width={150} />
            </DataText>
          </>
        ) : (
          data && (
            <>
              <StatusSale status={data.status} />
              <DataInfo>
                <WrapperNameInfo>
                  <DataTextName>{data.nome}</DataTextName>
                </WrapperNameInfo>
                <DataText>
                  {cpfFormatter(data.cpf)}
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      copyToClipboard(data.cpf);
                    }}
                    color="inherit"
                  >
                    <img src={copyIcon} alt="copiar" />
                  </IconButton>
                </DataText>

                <DataText>
                  {celFormatter(data.msisdn)}
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      copyToClipboard(data.msisdn);
                    }}
                    color="inherit"
                  >
                    <img src={copyIcon} alt="copiar" />
                  </IconButton>
                </DataText>
                <DataText>{dateFormatter(data.data_portabilidade)}</DataText>
                <DataText>{dateFormatter(data.data_ativacao)}</DataText>
              </DataInfo>

              <MoreVertIcon />
            </>
          )
        )}
      </Data>

      <Divider />
    </Container>
  );
}

export default Item;
