import styled from 'styled-components';

interface GridItemProps {
  colunm: number;
}

export const WeekDay = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 5px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 15px;

  flex: none;
  order: 1;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);
  width: 100%;
`;

export const GridItem = styled.div<GridItemProps>`
  grid-column: ${props => props.colunm} / span 1;
  border-right: ${props => props.colunm === 1 && '1px #e2e9ef solid'};
  border-top: 1px #e2e9ef solid;
  border-left: ${props =>
    props.colunm !== 1 && props.colunm !== 2 && '1px #e2e9ef solid'};

  :first-child {
    border-top: none;
  }

  :last-child {
    border-right: 1px #e2e9ef solid;
    border-bottom: none;
  }

  :nth-last-child(-n + 7) {
    border-bottom: 1px #e2e9ef solid;
    margin-bottom: -1px;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  @media (max-width: 1023px) {
    width: 100%;
    flex-direction: column;
    gap: 15px;
  }
`;

export const BoxButtons = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
`;
