import New from 'assets/icons/statusIcons/new.svg';
import Processing from 'assets/icons/statusIcons/processing.svg';
import PendingShipping from 'assets/icons/statusIcons/pendingShipping.svg';
import Sent from 'assets/icons/statusIcons/sent.svg';
import ExpressShipment from 'assets/icons/statusIcons/expressShipment.svg';
import Activated from 'assets/icons/statusIcons/activated.svg';
import Approved from 'assets/icons/statusIcons/approved.svg';
import Divergent from 'assets/icons/statusIcons/divergent.svg';
import Canceled from 'assets/icons/statusIcons/canceled.svg';
import Recused from 'assets/icons/statusIcons/recused.svg';
import Returned from 'assets/icons/statusIcons/returned.svg';
import AwaitingWithdraw from 'assets/icons/statusIcons/awaiting_withdraw.svg';
import Misplaced from 'assets/icons/statusIcons/misplaced.svg';
import TreatableLead from 'assets/icons/statusIcons/treatableLead.svg';
import FraudAnalysis from 'assets/icons/statusIcons/fraud.svg';
import Legitimate from 'assets/icons/statusIcons/legitimate.svg';
import PortabilityWindow from 'assets/icons/statusIcons/portability_window.svg';
import AwaitingPortability from 'assets/icons/statusIcons/awaiting_portability.svg';
import PortabilityAnalysis from 'assets/icons/statusIcons/portabilityAnalysis.svg';
import palette from 'theme/palette';

export interface StatusConf {
  icon: string;
  title: string;
  subTitle: string;
  color: string;
  backgroud: string;
}

interface StatusConfProps {
  NOVO: StatusConf;
  EM_PROCESSAMENTO: StatusConf;
  PENDENTE_ENVIO: StatusConf;
  ENVIADO: StatusConf;
  EM_TRANSPORTE_EXPRESSO: StatusConf;
  ATIVADO: StatusConf;
  APROVADO: StatusConf;
  DIVERGENTE: StatusConf;
  CANCELADO: StatusConf;
  RECUSADO: StatusConf;
  DEVOLVIDO: StatusConf;
  AGUARDANDO_RETIRADA: StatusConf;
  EXTRAVIADO: StatusConf;
  LEAD_TRATAVEL: StatusConf;
  ANALISE_FRAUDE: StatusConf;
  LEGITIMO: StatusConf;
  AGUARDANDO_JANELA: StatusConf;
  AGUARDANDO_PORTABILIDADE: StatusConf;
  ANALISE_PORTABILIDADE: StatusConf;

  [key: string]: StatusConf;
}

export const statusConf: StatusConfProps = {
  NOVO: {
    icon: New,
    title: 'Novo',
    subTitle: 'Venda que acabou de entrar e ainda não foi processada',
    color: palette.status.new.color,
    backgroud: palette.status.new.backgroud,
  },
  EM_PROCESSAMENTO: {
    icon: Processing,
    title: 'Em processamento',
    subTitle: 'Em validação com a operadora',
    color: palette.status.stardardFlow.color,
    backgroud: palette.status.stardardFlow.backgroud,
  },
  PENDENTE_ENVIO: {
    icon: PendingShipping,
    title: 'Pendente envio',
    subTitle: 'O chip está pronto para ser enviado para o cliente',
    color: palette.status.stardardFlow.color,
    backgroud: palette.status.stardardFlow.backgroud,
  },
  ENVIADO: {
    icon: Sent,
    title: 'Enviado',
    subTitle: 'O chip foi enviado para o endereço do cliente',
    color: palette.status.stardardFlow.color,
    backgroud: palette.status.stardardFlow.backgroud,
  },
  EM_TRANSPORTE_EXPRESSO: {
    icon: ExpressShipment,
    title: 'Em Transporte Expresso',
    subTitle: 'O chip foi enviado via transporte expresso para o cliente',
    color: palette.status.stardardFlow.color,
    backgroud: palette.status.stardardFlow.backgroud,
  },
  ATIVADO: {
    icon: Activated,
    title: 'Ativado',
    subTitle: 'O Chip foi entregue e ativado',
    color: palette.status.stardardFlow.color,
    backgroud: palette.status.stardardFlow.backgroud,
  },
  APROVADO: {
    icon: Approved,
    title: 'Aprovado',
    subTitle: 'A venda foi validada pela operadora',
    color: palette.status.new.color,
    backgroud: palette.status.new.backgroud,
  },
  DIVERGENTE: {
    icon: Divergent,
    title: 'Divergente',
    subTitle: 'Protocolo da ativação não encontrado na TIM',
    color: palette.status.generalFailures.color,
    backgroud: palette.status.generalFailures.backgroud,
  },
  CANCELADO: {
    icon: Canceled,
    title: 'Cancelado',
    subTitle: 'A venda foi cancelada',
    color: palette.status.generalFailures.color,
    backgroud: palette.status.generalFailures.backgroud,
  },
  RECUSADO: {
    icon: Recused,
    title: 'Recusado',
    subTitle: 'Chip recusado pelo cliente',
    color: palette.status.chipProblems.color,
    backgroud: palette.status.chipProblems.backgroud,
  },
  DEVOLVIDO: {
    icon: Returned,
    title: 'Devolvido',
    subTitle: 'Destinatário não encontrado, o chip foi devolvido',
    color: palette.status.deliveryProblems.color,
    backgroud: palette.status.deliveryProblems.backgroud,
  },
  AGUARDANDO_RETIRADA: {
    icon: AwaitingWithdraw,
    title: 'Aguardando retirada',
    subTitle: 'Destinatário deve retirar o chip nos Correios em até 7 dias',
    color: palette.status.deliveryProblems.color,
    backgroud: palette.status.deliveryProblems.backgroud,
  },
  EXTRAVIADO: {
    icon: Misplaced,
    title: 'Extraviado',
    subTitle: 'O Chip foi extraviado',
    color: palette.status.deliveryProblems.color,
    backgroud: palette.status.deliveryProblems.backgroud,
  },
  LEAD_TRATAVEL: {
    icon: TreatableLead,
    title: 'Lead tratável',
    subTitle: 'Lead tratável passível de virar venda ativa',
    color: palette.status.leadProblems.color,
    backgroud: palette.status.leadProblems.backgroud,
  },
  ANALISE_FRAUDE: {
    icon: FraudAnalysis,
    title: 'Análise Fraude',
    subTitle: 'A venda está em análise de fraude',
    color: palette.status.leadProblems.color,
    backgroud: palette.status.leadProblems.backgroud,
  },
  LEGITIMO: {
    icon: Legitimate,
    title: 'Legítimo',
    subTitle: 'A análise de fraude determinou que esta venda é legítima',
    color: palette.status.portability.color,
    backgroud: palette.status.portability.backgroud,
  },
  AGUARDANDO_JANELA: {
    icon: PortabilityWindow,
    title: 'Janela de portabilidade',
    subTitle:
      'O chip já foi enviado para o cliente e está em período de portabilidade',
    color: palette.status.portability.color,
    backgroud: palette.status.portability.backgroud,
  },
  AGUARDANDO_PORTABILIDADE: {
    icon: AwaitingPortability,
    title: 'Aguardando portabilidade',
    subTitle:
      'O chip está foi entregue para o cliente e está aguardando o período de portabilidade',
    color: palette.status.portability.color,
    backgroud: palette.status.portability.backgroud,
  },
  ANALISE_PORTABILIDADE: {
    icon: PortabilityAnalysis,
    title: 'Análise de Portabilidade',
    subTitle: 'O pedido está em análise das informações de portabilidade ',
    color: palette.status.portability.color,
    backgroud: palette.status.portability.backgroud,
  },
};
