import styled from 'styled-components';

export const Info = styled.span`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.8);
  max-width: 600px;
  white-space: normal;
  word-wrap: normal;
  word-break: break-word;
  display: block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  .buttonCopyInfo {
    margin-left: 4px;
    visibility: hidden;
    height: 19px;
    border: none;
    background: transparent;
  }
  &:hover {
    .buttonCopyInfo {
      visibility: visible;
    }
  }

  @media (max-width: 1024px) {
    max-width: 500px;
    white-space: normal;
    word-wrap: normal;
    word-break: break-word;
    display: block;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;
