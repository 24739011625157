import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

export const ContainerItem = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-left: 15px;

  @media (max-width: 600px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const List = styled.div`
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.1));
  border-radius: 6px;
  width: 100%;
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  div {
    &:hover {
      color: rgba(0, 54, 77, 1);

      div {
        div {
          button {
            svg {
              color: rgba(0, 54, 77, 1);
            }
          }
        }
      }
    }
  }
`;

export const useStyles = makeStyles(() => ({
  typography: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  iconArrow: {
    backgroundColor: '#F5FCFF',
    border: '1px solid #B5D2E0',
    borderRadius: '6px',
    padding: 10,
    '&:hover': {
      backgroundColor: '#F5FCFF',
      border: '1px solid #B5D2E0',
    },
    '& svg': {
      color: '#B5D2E0',
      '&:hover': {
        color: '#00364C',
      },
    },
  },
  buttonGroup: {
    '& .Mui-disabled': {
      border: '1px solid #B5D2E0',
      '& svg': {
        opacity: 0.2,
      },
    },
  },
  actionButton: {
    display: 'flex',
  },
  icon: {
    color: 'rgba(204, 204, 204, 1)',
    display: 'flex',
    alignItems: 'center',
    minWidth: 30,
    '& svg': {
      height: 22,
      width: 22,
    },
  },
}));
