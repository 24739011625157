import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  Container,
  Data,
  DataTextName,
  Divider,
} from 'components/StyledComponents';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FiEdit } from 'react-icons/fi';
import { ContainerItem, List, useStyles } from './styles';
import { ItemProps } from '../types';

function Item({
  benefit,
  lengthBenefits,
  loading,
  setBenefitSelected,
  setShowBenefitEdition,
  handleOpenModalDelete,
  benefitUp,
  benefitDown,
}: ItemProps) {
  const classes = useStyles();

  return (
    <ContainerItem>
      <Typography className={classes.typography}>
        {loading ? <Skeleton width={20} /> : benefit?.ordem}
      </Typography>

      <ButtonGroup
        className={classes.buttonGroup}
        variant="outlined"
        aria-label="outlined button group"
      >
        {loading ? (
          <Skeleton width={75} />
        ) : (
          benefit && (
            <>
              <Button
                className={classes.iconArrow}
                disabled={benefit.ordem === 1}
                onClick={() => {
                  benefitUp && benefitUp(benefit);
                }}
              >
                <ArrowUpwardIcon />
              </Button>
              <Button
                className={classes.iconArrow}
                disabled={benefit.ordem === lengthBenefits}
                onClick={() => benefitDown && benefitDown(benefit)}
              >
                <ArrowDownwardIcon />
              </Button>
            </>
          )
        )}
      </ButtonGroup>

      <List>
        <Container>
          <Data>
            <DataTextName>
              {loading ? <Skeleton width={500} /> : benefit?.titulo}
            </DataTextName>

            {loading ? (
              <Skeleton width={70} />
            ) : (
              <Box className={classes.actionButton}>
                <IconButton
                  onClick={() => {
                    handleOpenModalDelete && handleOpenModalDelete();
                    setBenefitSelected &&
                      benefit &&
                      setBenefitSelected(benefit);
                  }}
                  className={classes.icon}
                  disabled={loading}
                >
                  <RiDeleteBinLine />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setShowBenefitEdition && setShowBenefitEdition(true);
                    setBenefitSelected &&
                      benefit &&
                      setBenefitSelected(benefit);
                  }}
                  className={classes.icon}
                  disabled={loading}
                >
                  <FiEdit />
                </IconButton>
              </Box>
            )}
          </Data>

          <Divider style={{ borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)' }} />
        </Container>
      </List>
    </ContainerItem>
  );
}

export default Item;
