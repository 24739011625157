import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 16px;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 70px 8px 16px;
  color: rgba(0, 0, 0, 0.4);

  span {
    :nth-child(1) {
      width: 139px;
    }
    :nth-child(2) {
      width: 120px;
    }
    :nth-child(3) {
      width: 135px;
    }
    :nth-child(4) {
      width: 465px;
    }
    :nth-child(5) {
      width: 60px;
    }

    white-space: nowrap;
    font-size: 12px;
    align-self: flex-start;
    font-weight: 400;
  }
`;
