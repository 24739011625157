import { IconButton, Skeleton } from '@mui/material';
import {
  MobileContainer,
  MobileDataLabel,
  MobileData,
} from 'components/StyledComponents';
import { MobileTextName, MobileDataText } from 'components/MobileList';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FiEdit } from 'react-icons/fi';
import { ItemProps } from '../types';
import { ActionFAQMobile, useStyles } from '../Item/styles';

function MobileItem({
  data,
  loading = false,
  setFAQSelected,
  setShowFAQEdition,
  handleOpenModalDelete,
}: ItemProps) {
  const classes = useStyles();

  return (
    <MobileContainer>
      {loading ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        data && (
          <>
            <ActionFAQMobile>
              <IconButton
                className={classes.icon}
                onClick={() => {
                  handleOpenModalDelete && handleOpenModalDelete();
                  setFAQSelected && data && setFAQSelected(data);
                }}
                disabled={loading}
              >
                <RiDeleteBinLine />
              </IconButton>
              <IconButton
                className={classes.icon}
                onClick={() => {
                  setShowFAQEdition && setShowFAQEdition(true);
                  setFAQSelected && data && setFAQSelected(data);
                }}
                disabled={loading}
              >
                <FiEdit />
              </IconButton>
            </ActionFAQMobile>
            <MobileData>
              <MobileData>
                <MobileDataLabel>Pergunta</MobileDataLabel>
                <MobileTextName>{data.pergunta}</MobileTextName>
              </MobileData>
              <MobileData>
                <MobileDataLabel>Resposta</MobileDataLabel>
                <MobileDataText>{data.resposta}</MobileDataText>
              </MobileData>
            </MobileData>
          </>
        )
      )}
    </MobileContainer>
  );
}

export default MobileItem;
