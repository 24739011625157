import React, { useCallback, useEffect, useState } from 'react';
import { ButtonProgress, Card } from 'components/StyledComponents';
import {
  Button,
  CardContent,
  CardHeader,
  DialogActions,
  Grid,
  Skeleton,
  TextField,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useNavigate } from 'react-router-dom';
import { useToast } from 'hooks/toast';
import useBreakpoint from 'hooks/useBreakpoint';

import { PermissionsFetch } from 'fetches/permissionFetch';
import api from 'services/api';
import { makeStyles } from '@mui/styles';
import { Header } from '../styles';
import { PermissoesSelecionadas } from '../types';

const useStyles = makeStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.4)',
  },
  labelSelecionada: {
    color: 'black',
    fontWeight: 'bold',
  },
});

function NewRole() {
  const { data: permissoes } = PermissionsFetch();

  const navigate = useNavigate();
  const { addToast } = useToast();
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const classes = useStyles();

  const [nome, setNome] = useState('');
  const [permissoesSelecionadas, setPermissoes] =
    useState<PermissoesSelecionadas>({});
  const [loading, setLoading] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [nomeIsValid, setNomeIsValid] = useState(true);

  useEffect(() => {
    const contemPermissaoSelecionada = !!Object.keys(
      permissoesSelecionadas,
    ).find(key => permissoesSelecionadas[key]);
    setFormIsValid(contemPermissaoSelecionada && !!nome);
  }, [nome, permissoesSelecionadas]);

  const handleSave = useCallback(() => {
    const data = {
      role_form: {
        nome,
        permissions: Object.keys(permissoesSelecionadas).reduce(
          (acc, current) => {
            if (permissoesSelecionadas[current]) {
              acc.push(current);
            }
            return acc;
          },
          [] as string[],
        ),
      },
    };
    setLoading(true);

    api
      .post(`/core/funcoes`, data)
      .then(({ data: permission }) => {
        setPermissoes(permission);
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Função criada com sucesso',
        });
        navigate('/gestao-funcao/');
      })
      .catch(() => {
        if (nome === data.role_form.nome) {
          setNomeIsValid(!nomeIsValid);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, navigate, nome, nomeIsValid, permissoesSelecionadas]);

  return (
    <Card style={{ height: isTableOrLess ? '80%' : '60%' }}>
      <CardHeader
        title="Cadastrar nova função"
        subheader="Crie um nome e defina as permissões desejadas para a nova função."
      />
      <CardContent>
        <TextField
          fullWidth
          label="Nome da função"
          margin="dense"
          name="nome_funcao"
          value={nome}
          error={!nomeIsValid}
          helperText={!nomeIsValid && 'Nome da função já existente'}
          onChange={e => {
            setNome(e.target.value);
          }}
          variant="outlined"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: isTableOrLess ? 'column' : 'row',
          }}
        />

        <Header>
          <span>Permissões</span>
        </Header>
        <Grid
          container
          spacing={1}
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          {!permissoes ? (
            <Skeleton width={140} />
          ) : (
            permissoes?.map(permissao => (
              <Grid key={permissao.id} item xs={12} sm={4}>
                <FormControlLabel
                  classes={{
                    root: classes.root,
                    label: permissoesSelecionadas[permissao.id]
                      ? classes.labelSelecionada
                      : '',
                  }}
                  value="end"
                  control={
                    <Checkbox
                      value={permissoesSelecionadas[permissao.id]}
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxOutlinedIcon color="primary" />}
                      onChange={e => {
                        setPermissoes({
                          ...permissoesSelecionadas,
                          [permissao.id]: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={permissao.descricao}
                  labelPlacement="end"
                />
              </Grid>
            ))
          )}
        </Grid>
      </CardContent>
      <DialogActions
        style={{
          justifyContent: 'space-between',
          paddingRight: '24px',
          paddingLeft: '16px',
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            navigate('/gestao-funcao/');
          }}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={!formIsValid || loading}
        >
          Salvar
          {loading && <ButtonProgress size={24} />}
        </Button>
      </DialogActions>
    </Card>
  );
}

export default NewRole;
