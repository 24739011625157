import styled, { css } from 'styled-components';
import palette from 'theme/palette';

interface PageLink {
  selected: boolean;
}
interface ContainerProps {
  isDesktop: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.isDesktop ? 'flex-end' : 'flex-start')};
  flex-wrap: wrap;
`;
export const Ul = styled.ul`
  display: inline-block;
  padding: 0;
  margin: 0;
`;

export const Li = styled.li`
  display: inline;
`;

export const A = styled.a<PageLink>`
  color: black;
  float: left;
  padding: 5px 12px;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 5px;

  &:hover {
    background-color: #ddd;
  }
  ${props =>
    props.selected &&
    css`
      background-color: ${palette.primary.main};
      color: #fff;
      font-weight: bold;
      &:hover {
        background-color: ${palette.primary.main};
      }
    `}
`;

export const ActionButtonPagination = styled.button.attrs(() => ({
  type: 'button',
}))`
  font-size: 14px;
  line-height: 24px;
  border: none;
  background: transparent;

  color: rgba(0, 0, 0, 0.8);
  margin: 0 10px;
  cursor: pointer;
`;

export const InforQuantityItems = styled.span`
  margin-left: 20px;
  font-size: 14px;
  line-height: 24px;

  color: rgba(0, 0, 0, 0.4);
`;
