import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

export const useStyles = makeStyles((theme: Theme) => ({
  cardContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    gap: '20px',

    [theme.breakpoints.down(1023)]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
}));
