import { FormControlLabel, Typography } from '@mui/material';
import { SwitchIOS } from 'components/SwitchIOS';

import { Container } from './styles';
import { useFlags } from './hooks/useFlags';

function Flags() {
  const {
    withReprocessed,
    withManuallyProcessed,
    withAffiliates,
    withTreatables,
    withPortabilityTreatment,
    withExpressShipping,
    withOrderError,
    withEngagement,
    onlyNew,
    handleChangeWithReprocessed,
    handleChangeWithManuallyProcessed,
    handleChangeWithAffiliates,
    handleChangeWithTreatables,
    handleChangeWithPortabilityTreatment,
    handleChangeWithExpressShipping,
    handleChangeWithOrderError,
    handleChangeWithEngagement,
    handleChangeOnlyNew,
  } = useFlags();

  return (
    <Container>
      <FormControlLabel
        style={{ marginLeft: 0, marginBottom: 10 }}
        label={
          <Typography variant="body2">Reprocessadas Manualmente</Typography>
        }
        control={
          <SwitchIOS
            size="medium"
            color="secondary"
            checked={withManuallyProcessed}
            onChange={handleChangeWithManuallyProcessed}
          />
        }
        labelPlacement="end"
      />

      <FormControlLabel
        style={{ marginLeft: 0, marginBottom: 10 }}
        label={<Typography variant="body2">Reprocessadas</Typography>}
        control={
          <SwitchIOS
            size="medium"
            color="secondary"
            checked={withReprocessed}
            onChange={handleChangeWithReprocessed}
          />
        }
        labelPlacement="end"
      />

      <FormControlLabel
        style={{ marginLeft: 0, marginBottom: 10 }}
        label={<Typography variant="body2">Afiliados</Typography>}
        control={
          <SwitchIOS
            size="medium"
            color="secondary"
            checked={withAffiliates}
            onChange={handleChangeWithAffiliates}
          />
        }
        labelPlacement="end"
      />

      <FormControlLabel
        style={{ marginLeft: 0, marginBottom: 10 }}
        label={<Typography variant="body2">Tratável</Typography>}
        control={
          <SwitchIOS
            size="medium"
            color="secondary"
            checked={withTreatables}
            onChange={handleChangeWithTreatables}
          />
        }
        labelPlacement="end"
      />
      <FormControlLabel
        style={{ marginLeft: 0, marginBottom: 10 }}
        label={
          <Typography variant="body2">Tratamento Portabilidade</Typography>
        }
        control={
          <SwitchIOS
            size="medium"
            color="secondary"
            checked={withPortabilityTreatment}
            onChange={handleChangeWithPortabilityTreatment}
          />
        }
        labelPlacement="end"
      />

      <FormControlLabel
        style={{ marginLeft: 0, marginBottom: 10 }}
        label={<Typography variant="body2">Transporte Expresso</Typography>}
        control={
          <SwitchIOS
            size="medium"
            color="secondary"
            checked={withExpressShipping}
            onChange={handleChangeWithExpressShipping}
          />
        }
        labelPlacement="end"
      />

      <FormControlLabel
        style={{ marginLeft: 0, marginBottom: 10 }}
        label={<Typography variant="body2">Erro na Ordem</Typography>}
        control={
          <SwitchIOS
            size="medium"
            color="secondary"
            checked={withOrderError}
            onChange={handleChangeWithOrderError}
          />
        }
        labelPlacement="end"
      />

      <FormControlLabel
        style={{ marginLeft: 0, marginBottom: 10 }}
        label={<Typography variant="body2">Engajamento</Typography>}
        control={
          <SwitchIOS
            size="medium"
            color="secondary"
            checked={withEngagement}
            onChange={handleChangeWithEngagement}
          />
        }
        labelPlacement="end"
      />

      <FormControlLabel
        style={{ marginLeft: 0, marginBottom: 10 }}
        label={<Typography variant="body2">Apenas Novas</Typography>}
        control={
          <SwitchIOS
            size="medium"
            color="secondary"
            checked={onlyNew}
            onChange={handleChangeOnlyNew}
          />
        }
        labelPlacement="end"
      />
    </Container>
  );
}

export default Flags;
