import styled from 'styled-components';

export const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 48px 15px 38px;
  color: rgba(0, 0, 0, 0.4);

  & span:first-child {
    width: 105px;
  }
  & span:nth-child(2) {
    width: 195px;
  }
  & span:nth-child(3) {
    width: 195px;
  }
  & span:nth-child(5) {
    margin-left: 5px;
  }
  span {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    align-self: flex-start;
  }
`;
