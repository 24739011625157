import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Popover,
  Select,
} from '@mui/material';
import { Container, LineControl, UseStyles } from './styles';

interface PopoverActionsProps {
  id: string;
  openPopover: boolean;
  anchorEl: null | HTMLElement;
  segmento: string;
  status: string;
  handleClick(event: React.MouseEvent<HTMLElement>): void;
  setSegmento(segmento: string): void;
  setStatus(status: string): void;
  onFilter(): void;
  onClose(value: boolean): void;
}

function PopoverActions({
  id,
  openPopover,
  anchorEl,
  segmento,
  status,
  handleClick,
  onFilter,
  setSegmento,
  setStatus,
  onClose,
}: PopoverActionsProps) {
  const classes = UseStyles();

  return (
    <Popover
      id={id}
      open={openPopover}
      onClose={handleClick}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Container className={classes.root}>
        <LineControl>
          <FormControl variant="outlined" margin="none" size="small" fullWidth>
            <InputLabel htmlFor="segmento" focused shrink>
              Filtrar por segmento
            </InputLabel>
            <Select
              native
              value={segmento}
              onChange={e => setSegmento(e.target.value as string)}
              input={
                <OutlinedInput
                  notched
                  label="Filtrar por segmento"
                  name="age"
                  id="outlined-age-always-notched"
                />
              }
            >
              <option aria-label="Todos" value="">
                Todos
              </option>
              <option value="CONTROLE">Controle</option>
              <option value="EXPRESS">Express</option>
              <option value="POS_PAGO">Pós Pago</option>
              <option value="POS_EXPRESS">Pós Express</option>
            </Select>
          </FormControl>

          <FormControl variant="outlined" margin="none" size="small" fullWidth>
            <InputLabel htmlFor="status" focused shrink>
              Filtrar por status
            </InputLabel>
            <Select
              native
              value={status}
              onChange={e => setStatus(e.target.value as string)}
              input={
                <OutlinedInput
                  notched
                  label="Filtrar por status"
                  name="age"
                  id="outlined-age-always-notched"
                />
              }
            >
              <option aria-label="Todos" value="all">
                Todos
              </option>
              <option value="1">Ativo</option>
              <option value="0">Inativo</option>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              onFilter();
              onClose(false);
            }}
          >
            Filtrar
          </Button>
        </LineControl>
      </Container>
    </Popover>
  );
}

export default PopoverActions;
