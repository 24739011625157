import { useCallback, useState } from 'react';
import { Button, DialogActions, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { ButtonProgress } from 'components/StyledComponents';
import { ModalCard } from 'pages/CmsLP/components/DeleteModal/styles';
import { useStylesModal } from 'components/UseStylesModal';
import { UTMConfiguration } from 'models/utmConfiguration';

interface DialogDeleteUtmConfigurationProps {
  id: number;
  name: string;
  utmConfigurations: UTMConfiguration[];
  openDialogDeleteUtmConfiguration: boolean;
  handleClose(): void;
  updateUtmConfigurations(
    utmConfigurations: UTMConfiguration[],
    showRevalid?: boolean,
  ): void;
}

function DialogDeleteUtmConfiguration({
  id,
  name,
  utmConfigurations,
  openDialogDeleteUtmConfiguration,
  handleClose,
  updateUtmConfigurations,
}: DialogDeleteUtmConfigurationProps) {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();
  const classes = useStylesModal();

  const handleDelete = useCallback(() => {
    setLoading(true);
    api
      .delete(`/core/utm-configuracoes/${id}`)
      .then(() => {
        updateUtmConfigurations(
          utmConfigurations?.filter(utm => utm.id !== id),
          true,
        );
        addToast({
          type: 'success',
          title: 'Configuraçao excluída',
          description: `A configuração ${name} foi excluída com sucesso do sistema.`,
        });
        setLoading(false);
        handleClose();
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Remover configuração ',
          description: error?.data?.message,
        });
        setLoading(false);
        handleClose();
      });
  }, [
    id,
    updateUtmConfigurations,
    utmConfigurations,
    addToast,
    name,
    handleClose,
  ]);

  return (
    <Modal
      aria-labelledby="tile-modal"
      aria-describedby="modal-description"
      className={classes.modal}
      open={openDialogDeleteUtmConfiguration}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 200,
      }}
    >
      <Fade in={openDialogDeleteUtmConfiguration}>
        <ModalCard>
          <Typography>
            Deseja mesmo excluir a configuração <strong>{name}</strong>?
          </Typography>
          <DialogActions className={classes.dialogActions}>
            <Button
              className={classes.buttonCancel}
              variant="outlined"
              onClick={() => handleClose()}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={handleDelete}
              color="primary"
              disabled={loading}
            >
              Confirmar
              {loading && <ButtonProgress size={24} />}
            </Button>
          </DialogActions>
        </ModalCard>
      </Fade>
    </Modal>
  );
}

export default DialogDeleteUtmConfiguration;
