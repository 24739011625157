import { EvolutionByMediaFetch } from 'fetches/evolutionByMediaFetch';
import palette from 'theme/palette';
import { shade } from 'polished';
import { Bar } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useReportFilterFlagFetchStore } from 'store/useReportFilterFlagFetchStore';

interface EvolutionByTypeOfMediaProps {
  day: string;
}
function EvolutionByTypeOfMedia({ day }: EvolutionByTypeOfMediaProps) {
  const reprocessed = useReportFilterFlagFetchStore(
    state => state.withReprocessedFetch,
  );
  const inReprocessing = useReportFilterFlagFetchStore(
    state => state.inReprocessingFetch,
  );
  const engagement = useReportFilterFlagFetchStore(
    state => state.withEngagementFetch,
  );

  const { data: evolution } = EvolutionByMediaFetch(
    day,
    reprocessed,
    inReprocessing,
    engagement,
  );

  const data = {
    labels: evolution ? Object.keys(evolution).map(key => key) : [],
    datasets: [
      {
        data: evolution
          ? Object.keys(evolution).map(key => evolution[key].sms)
          : [],
        label: 'SMS',
        backgroundColor: palette.sms,
        hoverBackgroundColor: shade(0.2, palette.sms),
        borderWidth: 1,
      },
      {
        data: evolution
          ? Object.keys(evolution).map(key => evolution[key].facebook)
          : [],
        label: 'Facebook',
        backgroundColor: palette.facebook,
        hoverBackgroundColor: shade(0.2, palette.facebook),
      },
      {
        data: evolution
          ? Object.keys(evolution).map(key => evolution[key].google)
          : [],
        label: 'Google',
        backgroundColor: palette.google,
        hoverBackgroundColor: shade(0.2, palette.google),
      },
      {
        data: evolution
          ? Object.keys(evolution).map(key => evolution[key].organico)
          : [],
        label: 'Orgânico',
        backgroundColor: palette.organico,
        hoverBackgroundColor: shade(0.2, palette.organico),
        borderWidth: 1,
      },
      {
        data: evolution
          ? Object.keys(evolution).map(key => evolution[key].email)
          : [],
        label: 'Email',
        backgroundColor: palette.email,
        hoverBackgroundColor: shade(0.2, palette.email),
      },

      {
        data: evolution
          ? Object.keys(evolution).map(key => evolution[key].landingpage)
          : [],
        label: 'Landing Page',
        backgroundColor: palette.landingpage,
        hoverBackgroundColor: shade(0.2, palette.landingpage),
      },
      {
        data: evolution
          ? Object.keys(evolution).map(key => evolution[key].WhatsApp)
          : [],
        label: 'WhatsApp',
        backgroundColor: palette.whatsApp,
        hoverBackgroundColor: shade(0.2, palette.whatsApp),
      },
      {
        data: evolution
          ? Object.keys(evolution).map(key => evolution[key].WhatsAppLPTIM)
          : [],
        label: 'WhatsApp LP TIM',
        backgroundColor: palette.whatsAppLPTIM,
        hoverBackgroundColor: shade(0.2, palette.whatsAppLPTIM),
      },
      {
        data: evolution
          ? Object.keys(evolution).map(key => evolution[key].WhatsAppMETA)
          : [],
        label: 'WhatsApp META',
        backgroundColor: palette.whatsAppMeta,
        hoverBackgroundColor: shade(0.2, palette.whatsAppMeta),
      },
      {
        data: evolution
          ? Object.keys(evolution).map(key => evolution[key].WhatsAppOutros)
          : [],
        label: 'WhatsApp Outros',
        backgroundColor: palette.whatsAppOutros,
        hoverBackgroundColor: shade(0.2, palette.whatsAppOutros),
      },
      {
        data: evolution
          ? Object.keys(evolution).map(key => evolution[key].afiliado)
          : [],
        label: 'Afiliado',
        backgroundColor: palette.afiliado,
        hoverBackgroundColor: shade(0.2, palette.afiliado),
      },
      {
        data: evolution
          ? Object.keys(evolution).map(
              key => evolution[key].SMSAbandonoOUCancelados,
            )
          : [],
        label: 'SMS Abandono/Cancelados',
        backgroundColor: palette.smsAbandono,
        hoverBackgroundColor: shade(0.2, palette.smsAbandono),
      },
    ],
  };

  return (
    <div style={{ marginTop: 25, height: '100%', maxHeight: '250px' }}>
      <h2>Evolução por tipo de Mídia</h2>
      <Bar
        data={data}
        options={{
          maintainAspectRatio: false,
          scales: {
            y: {
              ticks: {
                minRotation: 0,
              },
            },
          },
          plugins: {
            datalabels: {
              anchor: 'end',
              align: 'end',
              offset: -2,
              color: 'rgba(0, 0, 0, 0.4)',
              font: { size: 10 },
            },
          },
        }}
        plugins={[ChartDataLabels]}
      />
    </div>
  );
}

export default EvolutionByTypeOfMedia;
