import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ButtonProgress } from 'components/StyledComponents';
import { useToast } from 'hooks/toast';
import { useState } from 'react';
import api from 'services/api';
import { dateTime as dateFormatter } from 'util/formatter';
import { isSameDay, parseISO } from 'date-fns';
import { Sale } from '../types';
import { Alert, AlertText, Form } from './styles';

interface InfoEligibilityFormProps {
  eligibilityId: number;
  created_at?: string;
}

function InfoEligibilityForm({
  eligibilityId,
  created_at,
}: InfoEligibilityFormProps) {
  return (
    <Box>
      <Alert>
        <AlertText>Venda elegível: {eligibilityId},</AlertText>
        <AlertText>{dateFormatter(created_at)}</AlertText>
      </Alert>
    </Box>
  );
}

interface EligibilityFormProps {
  sale: Sale;
  setSale(sale: Sale): void;
}

function ExecuteEligibilityForm({ sale, setSale }: EligibilityFormProps) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { addToast } = useToast();

  const handleClick = () => {
    setOpen(false);
    setLoading(true);
    api
      .put(`/core/vendas/atualizar-detalhe/${sale.id}`, {
        status: 'EXECUTAR_ELEGIBILIDADE',
      })
      .then(() => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Execução da elegibilidade',
          description: 'Execução com sucesso',
        });
      })
      .catch(err => {
        setLoading(false);
        addToast({
          type: 'error',
          title: err.data.shortMessage ?? 'Execução da elegibilidade',
          description:
            err.data.message ??
            'Houve um problema na execução, verifique se os dados estão corretos',
        });
      })
      .finally(() =>
        api
          .get(`core/vendas/${sale.id}`)
          .then(({ data }) => setSale({ ...sale, ...data })),
      );
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button
        type="button"
        variant="contained"
        color="primary"
        disabled={loading}
        fullWidth
        size="large"
        onClick={() => setOpen(true)}
      >
        Executar Elegibilidade
        {loading && <ButtonProgress size={24} />}
      </Button>
      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Deseja realmente executar esta elegibilidade?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Ao clicar em Sim, a elegibilidade será executada.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Não
          </Button>
          <Button onClick={handleClick} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function EligibilityForm({ sale, setSale }: EligibilityFormProps) {
  function isValidEligibility() {
    return isSameDay(new Date(), parseISO(sale.eligibility?.created_at));
  }

  return (
    <Form>
      {(sale.status === 'NOVO' || sale.status === 'LEGITIMO') &&
        (!sale.eligibility || (sale.eligibility && !isValidEligibility())) && (
          <ExecuteEligibilityForm sale={sale} setSale={setSale} />
        )}
      {sale.eligibility && (
        <InfoEligibilityForm
          eligibilityId={sale.eligibility.id}
          created_at={sale.eligibility.created_at}
        />
      )}
    </Form>
  );
}

export default EligibilityForm;
