import { SwipeableDrawer } from '@mui/material';
import { LPFAQ } from 'models/lpFaqs';
import { useState } from 'react';
import HeaderList from '../components/HeaderList';
import FAQList from './FAQList';
import NewFAQ from './NewFAQ';

interface FAQProps {
  faqs?: LPFAQ[];
  appendNewFAQ(faq: LPFAQ): void;
  updateFAQs(faqs: LPFAQ[], showRevalid?: boolean): void;
}

function FAQ({ faqs, appendNewFAQ, updateFAQs }: FAQProps) {
  const [showFAQNew, setShowFAQNew] = useState(false);
  return (
    <>
      <HeaderList
        title="FAQ"
        buttonInfo="Nova pergunta"
        setShowRegistrationDialog={setShowFAQNew}
      />

      <FAQList faqs={faqs} updateFAQs={updateFAQs} />

      <SwipeableDrawer
        anchor="right"
        onClose={() => {
          setShowFAQNew(false);
        }}
        onOpen={() => setShowFAQNew(true)}
        open={showFAQNew}
      >
        <NewFAQ
          closeDrawer={() => {
            setShowFAQNew(false);
          }}
          appendNewFAQ={appendNewFAQ}
        />
      </SwipeableDrawer>
    </>
  );
}

export default FAQ;
