import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 33px 15px 40px;
  color: rgba(0, 0, 0, 0.4);
  span {
    :nth-child(1) {
      width: 85px;
    }
    :nth-child(2) {
      width: 85px;
    }
    :nth-child(4) {
      width: 300px;
    }
    width: 150px;
    white-space: nowrap;
    font-size: 15px;
    align-self: flex-start;
  }
`;
