import { useStylesModal } from 'components/UseStylesModal';
import React, { useCallback, useState } from 'react';
import { Button, DialogActions, TextField, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { ButtonProgress } from 'components/StyledComponents';
import { ModalCard } from 'pages/CmsLP/components/DeleteModal/styles';

interface ActivateSomeLeadsProps {
  baseId: number;
  fetchBaseData(): void;
}
function ActivateSomeLeads({ baseId, fetchBaseData }: ActivateSomeLeadsProps) {
  const classes = useStylesModal();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState(0);

  const { addToast } = useToast();

  const handleClose = () => {
    setOpen(false);
  };

  const handleActivateSomeLeads = useCallback(() => {
    setLoading(true);

    const body = {
      quantidade: number,
    };

    api
      .post(`/core/impactos/ativar-alguns-leads/${baseId}`, body)
      .then(() => {
        fetchBaseData();
        addToast({
          type: 'success',
          title: 'Ativações disparadas',
          description: `Foram disparadas ${number} ativações. Se existirem essa quantidade de leads disponíveis, em breve eles estarão ativados.`,
        });
        setLoading(false);
        handleClose();
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro ao disparar ativações',
          description: error?.data?.message,
        });
        setLoading(false);
        handleClose();
      });
  }, [number, baseId, fetchBaseData, addToast]);

  return (
    <>
      <div style={{ marginLeft: 'auto' }}>
        <Button
          type="button"
          variant="contained"
          onClick={() => setOpen(true)}
          disabled={loading}
        >
          Ativar X Leads
        </Button>
      </div>
      <Modal
        aria-labelledby="tile-modal"
        aria-describedby="modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={open}>
          <ModalCard>
            <Typography>Você deseja disparar quantas ativações?</Typography>
            <TextField
              value={number}
              aria-readonly
              fullWidth
              label="Quantidade"
              margin="dense"
              name="quantidade"
              onKeyPress={event => {
                if (/[0-9]/.test(event.key)) {
                  return;
                }
                event.preventDefault();
              }}
              onChange={e => setNumber(Number(e.target.value))}
              variant="outlined"
              style={{ paddingBottom: 10 }}
            />
            <DialogActions className={classes.dialogActions}>
              <Button variant="outlined" onClick={() => handleClose()}>
                Voltar
              </Button>
              <Button
                onClick={handleActivateSomeLeads}
                variant="contained"
                disabled={loading}
              >
                Ativar {number} Leads
                {loading && <ButtonProgress size={24} />}
              </Button>
            </DialogActions>
          </ModalCard>
        </Fade>
      </Modal>
    </>
  );
}

export default ActivateSomeLeads;
