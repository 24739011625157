import React, { useState } from 'react';
import { Button, Popper } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { Actions, UseStyles } from 'components/PopperActions';
import { useNavigate } from 'react-router-dom';
import { TbListSearch } from 'react-icons/tb';

interface PopperActionsProps {
  id: string;
  open: boolean;
  planId: number;
  anchorElement: null | HTMLElement;
  handleClick(event: React.MouseEvent<HTMLElement>): void;
}

function PopperActions({
  id,
  open,
  planId,
  anchorElement,
  handleClick,
}: PopperActionsProps) {
  const classes = UseStyles();

  const navigate = useNavigate();
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorElement}
      placement="bottom"
      modifiers={[
        {
          name: 'flip',
          enabled: true,
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            boundariesElement: 'scrollParent',
          },
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      className={classes.popper}
    >
      <span className={classes.arrow} ref={setArrowRef} />
      <Actions className={classes.paper}>
        <Button
          startIcon={<Edit />}
          fullWidth
          className={classes.editButton}
          onClick={e => {
            navigate(`/gestao-planos/edita-plano/${planId}`);
            handleClick(e);
          }}
        >
          Editar plano
        </Button>
        <div
          style={{
            width: '100%',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          }}
        />
        <Button
          startIcon={<TbListSearch />}
          fullWidth
          onClick={e => {
            navigate(`/gestao-planos/tabela-precos-plano/${planId}`);
            handleClick(e);
          }}
        >
          Tabela de preços
        </Button>
      </Actions>
    </Popper>
  );
}

export default PopperActions;
