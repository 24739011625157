import { Button, Grid, TextField } from '@mui/material';
import { ButtonProgress } from 'components/StyledComponents';
import { useStyles } from './styles';

interface FormFAQProps {
  id?: number;

  question: string;

  setQuestion(question: string): void;

  answer: string;

  setAnswer(answer: string): void;

  loading: boolean;

  isValid: boolean;

  handleBenefitRequest(): void;
}

function FormFAQ({
  id,
  question,
  setQuestion,
  answer,
  setAnswer,
  loading,
  isValid,
  handleBenefitRequest,
}: FormFAQProps) {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Pergunta"
          value={question}
          onChange={e => {
            setQuestion(e.target.value);
          }}
          margin="dense"
          name="pergunta"
          size="small"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Digite aqui"
          InputProps={{
            classes: { input: classes.input },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Resposta"
          margin="dense"
          value={answer}
          onChange={e => {
            setAnswer(e.target.value);
          }}
          name="resporta"
          size="small"
          variant="outlined"
          multiline
          rows={6}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Digite aqui"
          InputProps={{
            classes: { input: classes.input },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleBenefitRequest}
          disabled={!isValid || loading}
        >
          {id ? 'Editar' : 'Criar'}
          {loading && <ButtonProgress size={24} />}
        </Button>
      </Grid>
    </Grid>
  );
}

export default FormFAQ;
