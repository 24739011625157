import React from 'react';
import { animated, useTransition } from 'react-spring';

import { ToastMessage } from 'hooks/toast';
import { Container } from './styles';
import Toast from './Toast';

interface ToastContainerProps {
  messages: ToastMessage[];
}
function ToastContainer({ messages }: ToastContainerProps) {
  const messagesWithTransition = useTransition(messages, {
    from: { right: '-120%', opacity: 0 },
    enter: { right: '0%', opacity: 1 },
    leave: { right: '-120%', opacity: 0 },
  });

  return (
    <Container messagesExist={!!messages.length}>
      {messagesWithTransition(
        (styles, item) =>
          item && (
            <animated.div style={styles}>
              <Toast message={item} />
            </animated.div>
          ),
      )}
    </Container>
  );
}

export default ToastContainer;
