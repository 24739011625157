import styled from 'styled-components';

interface HeaderProps {
  isDesktop: boolean;
}
export const Header = styled.div<HeaderProps>`
  display: flex;

  justify-content: space-between;
  padding-right: ${props => (props.isDesktop ? '5px' : '32px')};
  flex-direction: ${props => (props.isDesktop ? 'row' : 'column')};
`;
