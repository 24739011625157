import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    height: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,

    [theme.breakpoints.down(1024)]: {
      flexDirection: 'column',
    },
  },
  cardContent: {
    height: '100%',
  },
}));
