import useSWR from 'swr';
import api from 'services/api';
import {
  SIMCard,
  SIMCardFetchResponse,
  SIMCardOfBatch,
  SimcardsOfBatchResponse,
  StockSIMCard,
} from 'models/simcards';
import { Indicator } from 'models/iccidBatch';

interface Params {
  hlrs?: string;
}
export const SimcardsFetch = (hlrs: string, page: number) => {
  const { data, mutate } = useSWR<SIMCardFetchResponse>(
    `simcardsFetch_${hlrs}_${page}`,
    async (): Promise<SIMCardFetchResponse> => {
      const params: Params = {};
      if (hlrs) {
        params.hlrs = hlrs;
      }
      const response = await api.get(`/core/simcards?page=${page}`, { params });

      const paginationInfo = {
        currentPage: parseInt(response.headers['x-current-page'], 10),
        lastPage: parseInt(response.headers['x-last-page'], 10),
        perPage: parseInt(response.headers['x-per-page'], 10),
        total: parseInt(response.headers['x-total-count'], 10),
      };

      const simcards = response.data as SIMCard[];

      return { simcards, paginationInfo } as SIMCardFetchResponse;
    },
  );
  return { data, mutate };
};

export const StockSimcardsFetch = () => {
  const { data, mutate } = useSWR<StockSIMCard[]>(
    `stockSimcardsFetch`,
    async (): Promise<StockSIMCard[]> => {
      const { data: stock } = await api.get(`/core/simcards/estoque`);

      return stock as StockSIMCard[];
    },
  );

  return { data, mutate };
};

export const SimCardsOfBatchFetch = (batch: number) => {
  const { data, mutate } = useSWR<SimcardsOfBatchResponse>(
    `simcardsOfBatch`,
    async (): Promise<SimcardsOfBatchResponse> => {
      const response = await api.get(`/core/simcards/lotes/simcards/${batch}`);

      const simcards = response.data.simcards as SIMCardOfBatch[];
      const indicators = response.data.indicadores as Indicator;

      return { simcards, indicators } as SimcardsOfBatchResponse;
    },
  );

  return { data, mutate };
};
