import styled from 'styled-components';
import { makeStyles, createStyles } from '@mui/styles';
import { MTheme } from 'models/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    width: 366px;
  }
`;

export const LineControl = styled.div`
  display: flex;
  vertical-align: top;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 350px;
  .MuiOutlinedInput-adornedEnd {
    padding-right: 1px;
  }

  .MuiFormControl-root {
    width: 48%;
    margin-bottom: 24px;
  }

  .MuiButton-containedSizeLarge {
    width: 100%;
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 16px;
    }
    .MuiButton-containedSizeLarge {
      width: 100%;
    }
  }
`;

export const UseStyles = makeStyles((theme: MTheme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
  }),
);
