import React, { useCallback, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Skeleton } from '@mui/material';
import { Action, Data, DataLabel } from 'components/MobileList';
import { MobileContainer } from 'components/StyledComponents';
import { format, parseISO } from 'date-fns';
import PopperActions from '../PopperActions';

import ItemProps from '../Item/types';
import { Badge } from '../Item/styles';
import { DataColumn, DataText } from './styles';

function MobileItem({ user, currentPage, loading }: ItemProps) {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElement(anchorElement ? null : event.currentTarget);
    },
    [anchorElement],
  );

  return (
    <MobileContainer>
      <Action>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </Action>
      <PopperActions
        anchorElement={anchorElement}
        handleClick={handleClick}
        user={user}
        currentPage={currentPage}
      />
      <DataColumn>
        <Data>
          <DataLabel>Nome</DataLabel>
          <DataText>{loading ? <Skeleton width={180} /> : user?.nome}</DataText>
        </Data>
        <Data>
          <DataLabel>Status</DataLabel>
          {user?.ativo ? (
            <Badge type="ativo">Ativo</Badge>
          ) : (
            <Badge type="inativo">Inativo</Badge>
          )}
        </Data>
        <Data className="item item-email">
          <DataLabel>E-mail</DataLabel>
          <DataText>
            {loading ? <Skeleton width={180} /> : user?.email}
          </DataText>
        </Data>
        <Data>
          <DataLabel>Função</DataLabel>
          <DataText>
            {loading ? <Skeleton width={180} /> : user?.role.nome}
          </DataText>
        </Data>
        <Data>
          <DataLabel>Ultimo Acesso</DataLabel>
          <DataText>
            {loading ? (
              <Skeleton width={180} />
            ) : (
              user?.ultimo_acesso &&
              format(parseISO(user.ultimo_acesso), 'dd/MM/yyyy - HH:mm')
            )}
          </DataText>
        </Data>
      </DataColumn>
    </MobileContainer>
  );
}

export default MobileItem;
