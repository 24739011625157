import useSWR from 'swr';
import api from 'services/api';
import {
  BlacklistDataBase,
  BlacklistDataFetchResponse,
} from 'models/blacklistData';

interface Params {
  buscar?: string;
  tipo?: string;
}

interface FetchProps {
  page?: number;
  type?: string;
  search?: string;
}

export const BlacklistDataFetch = ({ page, type, search }: FetchProps) => {
  const { data, mutate } = useSWR<BlacklistDataFetchResponse>(
    `blacklist_${page}_${type}_${search}`,
    async (): Promise<BlacklistDataFetchResponse> => {
      const params: Params = {};

      if (search) {
        params.buscar = search;
      }

      if (type) {
        params.tipo = type;
      }

      const response = await api.get(`/core/dados-blacklist?page=${page}`, {
        params,
      });
      const paginationInfo = {
        currentPage: parseInt(response.headers['x-current-page'], 10),
        lastPage: parseInt(response.headers['x-last-page'], 10),
        perPage: parseInt(response.headers['x-per-page'], 10),
        total: parseInt(response.headers['x-total-count'], 10),
      };

      const blacklists = response.data as BlacklistDataBase[];

      return { blacklists, paginationInfo } as BlacklistDataFetchResponse;
    },
  );
  return { data, mutate };
};
