import { WorkersRunning } from 'models/workersRunning';
import api from 'services/api';
import userSWR from 'swr';

export const WorkersRunningFetch = (processName: string) => {
  const { data, mutate: mutateTotalLeads } = userSWR<WorkersRunning>(
    `workers_running_batch`,
    async (): Promise<WorkersRunning> => {
      const { data: total } = await api.get(
        `core/relatorios/workers-rodando/${processName}`,
      );

      return total as WorkersRunning;
    },
  );

  return { data, mutateTotalLeads };
};
