import TotalizerCard from 'components/TotalizerCard';
import { TotalCreditAnalysisFetch } from '../../../../fetches/totalCreditAnalysisFetch';

interface TotalConsultsProps {
  isDesktop: boolean;
}

function TotalConsults({ isDesktop }: TotalConsultsProps) {
  const { data: consults } = TotalCreditAnalysisFetch();

  return (
    <TotalizerCard
      key="total-consultas"
      title="Total de Consultas"
      total={consults?.total || 0}
      isDesktop={isDesktop}
    />
  );
}

export default TotalConsults;
