import userSWR from 'swr';
import { parseISO, startOfDay, endOfDay, format } from 'date-fns';
import api from 'services/api';
import { TotalizatorByReprocessed } from 'models/reports';

export const TotalizatorByReprocessedFetch = (
  start: string,
  end: string,
  inReprocessing: boolean,
) => {
  const inicio = format(startOfDay(parseISO(start)), 'yyyy-MM-dd HH:mm:ss');
  const fim = format(endOfDay(parseISO(end)), 'yyyy-MM-dd HH:mm:ss');

  const { data, mutate } = userSWR<TotalizatorByReprocessed>(
    `reprocessedFetch_${inicio}_${fim}_${inReprocessing}`,

    async (): Promise<TotalizatorByReprocessed> => {
      const params = {
        inicio,
        fim,
        trazer_em_processamento: inReprocessing ? 1 : 0,
      };
      const { data: result } = await api.get(
        `core/relatorios/dashboards/totalizador-reprocessadas`,
        {
          params,
        },
      );

      return result as TotalizatorByReprocessed;
    },
  );
  return { data, mutate };
};
