import { Skeleton } from '@mui/material';
import {
  Container,
  Data,
  DataInfo,
  DataText,
  Divider,
} from 'components/StyledComponents';
import { Media } from 'models/reports';
import icons from 'pages/ManagementReport/reports/TotalByMedia/icons';
import source from 'pages/ManagementReport/reports/TotalByMedia/source';
import { DataTextName } from 'pages/SalesCorrection/SalesCorrectionList/Item/styles';
import { capitalize } from 'util/formatter';

export interface ItemProps {
  utm?: Media;
  loading?: boolean;
}

function Item({ utm, loading }: ItemProps) {
  return (
    <Container>
      <Data>
        {loading ? (
          <DataInfo>
            <DataText style={{ width: 205 }}>
              <Skeleton width={150} />
            </DataText>
            <DataText>
              <Skeleton width={300} />
            </DataText>
            <DataText>
              <Skeleton width={150} />
            </DataText>
          </DataInfo>
        ) : (
          utm && (
            <>
              <img
                src={icons[utm.source] || icons.default}
                alt="icons"
                width={20}
              />

              <DataInfo>
                <DataTextName>
                  {source[utm.source] || capitalize(utm.source)}
                </DataTextName>
                <DataText>
                  <DataText>{utm.pedidos}</DataText>
                  <DataText>{utm.quantidade}</DataText>
                  <DataText>{utm.conversao}%</DataText>
                  <DataText>{utm.porcentagem}%</DataText>
                </DataText>
                <Divider />
              </DataInfo>
            </>
          )
        )}
      </Data>
    </Container>
  );
}

export default Item;
