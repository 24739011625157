import useSWR from 'swr';
import api from 'services/api';
import {
  SaleCorrection,
  SaleCorrectionFetchResponse,
} from 'models/salesCorrection';

interface Params {
  tratavel?: string;
}

interface FetchProps {
  page?: number;
  tratavel?: string;
}

export const SalesCorrectionFetch = ({ page, tratavel }: FetchProps) => {
  const { data, mutate } = useSWR<SaleCorrectionFetchResponse>(
    `sales_${page}_${tratavel}`,
    async (): Promise<SaleCorrectionFetchResponse> => {
      const params: Params = {};

      if (tratavel) {
        params.tratavel = tratavel;
      }

      const response = await api.get(`/core/correcao-vendas?page=${page}`, {
        params,
      });

      const paginationInfo = {
        currentPage: parseInt(response.headers['x-current-page'], 10),
        lastPage: parseInt(response.headers['x-last-page'], 10),
        perPage: parseInt(response.headers['x-per-page'], 10),
        total: parseInt(response.headers['x-total-count'], 10),
      };

      const sales = response.data as SaleCorrection[];

      return { sales, paginationInfo } as SaleCorrectionFetchResponse;
    },
  );
  return { data, mutate };
};
