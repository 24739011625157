import useSWR from 'swr';
import api from 'services/api';
import {
  ReprocessingOfReachedCustomerBase,
  ReprocessingOfReachedCustomerFetchBaseResponse,
} from 'models/reprocessingOfReachedCustomerBase';

interface Params {
  status?: string;
  nome?: string;
}
interface FetchProps {
  page?: number;
  statusFetch?: string;
  search?: string;
}

export const ReprocessingOfReachedCustomerBaseFetch = ({
  page,
  statusFetch,
  search,
}: FetchProps) => {
  const { data, mutate } =
    useSWR<ReprocessingOfReachedCustomerFetchBaseResponse>(
      `bases${page}_${statusFetch}_${search}`,
      async (): Promise<ReprocessingOfReachedCustomerFetchBaseResponse> => {
        const params: Params = {};

        if (statusFetch) {
          params.status = statusFetch;
        }

        if (search) {
          params.nome = search;
        }

        const response = await api.get(
          `/core/bases-clientes-impactar?page=${page}`,
          { params },
        );
        const paginationInfo = {
          currentPage: parseInt(response.headers['x-current-page'], 10),
          lastPage: parseInt(response.headers['x-last-page'], 10),
          perPage: parseInt(response.headers['x-per-page'], 10),
          total: parseInt(response.headers['x-total-count'], 10),
        };
        const basesReprocessed =
          response.data as ReprocessingOfReachedCustomerBase[];

        return {
          basesReprocessed,
          paginationInfo,
        } as ReprocessingOfReachedCustomerFetchBaseResponse;
      },
    );
  return { data, mutate };
};
