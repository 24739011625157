import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
  buttonUpload: {
    color: 'rgba(79, 79, 79, 1)',
    fontWeight: 400,
    padding: 8,
    marginBottom: 12,
    border: '1px solid rgba(204, 204, 204, 1)',
    '& .MuiButton-label': {
      color: '#4F4F4F',
      marginLeft: 11,
      marginRight: 11,
      justifyContent: 'space-between ',
    },
    '& .MuiSvgIcon-root': {
      color: '#B3B3B3',
    },
  },
}));

export const BoxConfiguration = styled.div`
  display: flex;
  justify-content: center;
  min-height: 92px;
  padding: 16px;
  border-radius: 6px;
  gap: 15px;

  color: rgba(84, 110, 122, 1);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
`;

export const BoxContainerConfiguration = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
`;
