import { UtmCampaign } from 'models/utmCampaign';
import api from 'services/api';
import useSWR from 'swr';

export const UtmCampaignsFetch = (sourceID?: string) => {
  const { data, mutate } = useSWR(
    sourceID ? `utm_campaigns${sourceID}` : null,
    async () => {
      if (sourceID) {
        const response = await api.get(`/core/utm-campanha/${sourceID}`);
        const campaigns = response.data as UtmCampaign[];
        return campaigns;
      }
      return null;
    },
  );

  return { data, mutate };
};

export const UtmDefaultCampaign = () => {
  const { data, mutate } = useSWR(
    `default_campaign`,
    async (): Promise<UtmCampaign[]> => {
      const response = await api.get(`/core/utm-campanha/padrao`);
      const campaign = response.data as UtmCampaign[];

      return campaign;
    },
  );

  return { data, mutate };
};
