import { createStyles, makeStyles } from '@mui/styles';
import { MTheme } from 'models/theme';
import styled from 'styled-components';

export const UseStyles = makeStyles((theme: MTheme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      border: '1px solid rgba(0,0,0,0.14)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
    popper: {
      backgroundColor: '#FFFFFF',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      justifyContent: 'center',
      alignItems: 'flex-start',
      boxSizing: 'border-box',
      boxShadow:
        '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 10px 26px rgba(0, 0, 0, 0.25)',
      borderRadius: '6px',
      zIndex: 1,
      '&[data-popper-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '0 1em 1em 1em',
          borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
        },
      },
    },
    arrow: {
      position: 'absolute',
      fontSize: 7,
      width: '3em',
      height: '3em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
    },
    button: {
      justifyContent: 'start',
    },
    editButton: {
      width: '100%',
      justifyContent: 'flex-start',
    },
  }),
);

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
`;
