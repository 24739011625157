import styled from 'styled-components';

export const FilterMargin = styled.div`
  margin: 0 10px 0 10px;
  padding-bottom: 10px;

  .buttonSearch {
    width: 255px;
    margin-right: 15px;
  }

  .buttonSegmento {
    width: 165px;
    margin-right: 15px;
  }

  .buttonStatus {
    width: 165px;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-top: 10px;
    margin: 0;

    .buttonStatus {
      width: 100%;
      margin-bottom: 15px;
      margin-right: 0px;
    }

    .buttonSearch {
      width: 100%;
    }

    .buttonSegmento {
      width: 100%;
    }

    .buttonAdvancedFilters {
      padding-top: 15px;
      margin: 0 auto;
      width: 50%;
    }
  }
`;
