import { subMonths } from 'date-fns';
import create from 'zustand';

type SelectOptions = {
  value: string;
  text: string;
};

type SelectFilterStore = {
  tiposSelecionados: SelectOptions[];
  setTiposSelecionados(tipos: SelectOptions[]): void;

  segmentosSelecionados: SelectOptions[];
  setSegmentosSelecionados(segmentos: SelectOptions[]): void;

  statusSelecionados: SelectOptions[];
  setStatusSelecionados(status: SelectOptions[]): void;

  ufsSelecionadas: SelectOptions[];
  setUFsSelecionadas(ufs: SelectOptions[]): void;

  statusCrosscoreSelecionados: SelectOptions[];
  setStatusCrosscoreSelecionados(status: SelectOptions[]): void;

  requestedDocumentation: string;
  setRequestedDocumentation(requestedDocumentation: string): void;

  dateStartSelecionado: Date;
  setDateStartSelecionado(newDate: Date): void;

  dateEndSelecionado: Date;
  setDateEndSelecionado(newDate: Date): void;
};

export const useSelectFilterStore = create<SelectFilterStore>(set => ({
  tiposSelecionados: [],
  setTiposSelecionados(tipos) {
    set(state => ({ ...state, tiposSelecionados: tipos }));
  },

  segmentosSelecionados: [],
  setSegmentosSelecionados(segmentos) {
    set(state => ({ ...state, segmentosSelecionados: segmentos }));
  },

  statusSelecionados: [],
  setStatusSelecionados(status) {
    set(state => ({ ...state, statusSelecionados: status }));
  },

  ufsSelecionadas: [],
  setUFsSelecionadas(ufs) {
    set(state => ({ ...state, ufsSelecionadas: ufs }));
  },

  statusCrosscoreSelecionados: [],
  setStatusCrosscoreSelecionados(status) {
    set(state => ({ ...state, statusCrosscoreSelecionados: status }));
  },

  requestedDocumentation: '',
  setRequestedDocumentation(requestedDocumentation) {
    set(state => ({
      ...state,
      requestedDocumentation,
    }));
  },

  dateStartSelecionado: subMonths(new Date().setHours(0, 0, 0, 0), 1),
  setDateStartSelecionado(start) {
    set(state => ({ ...state, dateStartSelecionado: start }));
  },

  dateEndSelecionado: new Date(new Date().setHours(0, 0, 0, 0)),
  setDateEndSelecionado(end) {
    set(state => ({ ...state, dateEndSelecionado: end }));
  },
}));
