import styled from 'styled-components';

interface HeaderProps {
  isDesktop: boolean;
}

export const Header = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  flex-direction: ${props => (props.isDesktop ? 'row' : 'column')};
`;

export const FraudKeeperPagination = styled.div`
  margin-top: 10px;
`;
