import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;

  @media (max-width: 380px) {
    display: grid;
    align-items: center;
    grid-template-columns: auto;
  }
`;
