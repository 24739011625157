import React from 'react';

import Skeleton from '@mui/material/Skeleton';
import { date as dateFormatter } from 'util/formatter';
import copyIcon from 'assets/icons/small/copy.svg';
import copyToClipboard from 'util/copyToClipboard';

import {
  MobileContainer,
  MobileData,
  MobileDataLabel,
} from 'components/StyledComponents';
import { LinkOutlined } from '@mui/icons-material';
import { MobileTextData } from 'pages/SIMCardStock/components/BatchesList/MobileItem/styles';
import { useSimcardStock } from 'store/simcardStock';
import { GroupData } from 'components/MobileList';
import { IconButton } from '@mui/material';
import { Action, GroupCard, MobileDataText } from './styles';
import { ItemProps } from '../Item';
import Status from '../Status';

function MobileItem({ data, loading = false }: ItemProps) {
  const setSimCardSaleselected = useSimcardStock(
    state => state.setSimCardSaleselected,
  );
  const setShowSaleInfo = useSimcardStock(state => state.setShowSaleInfo);

  return (
    <MobileContainer>
      {loading ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        data && (
          <GroupCard>
            <Action>
              {data?.venda_id && (
                <IconButton
                  onClick={() => {
                    setSimCardSaleselected(data.venda_id);
                    setShowSaleInfo(true);
                  }}
                >
                  <LinkOutlined />
                </IconButton>
              )}
            </Action>
            <MobileData>
              <MobileDataLabel>ICCID</MobileDataLabel>
              <MobileDataText>
                {data.iccid}
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    copyToClipboard(data.iccid);
                  }}
                  color="inherit"
                >
                  <img src={copyIcon} alt="copiar" />
                </IconButton>
              </MobileDataText>
            </MobileData>
            <GroupData>
              <MobileData>
                <MobileDataLabel>HRL</MobileDataLabel>
                <MobileDataText>
                  {data?.hlr ? data?.hlr : 'Sem HRL'}
                </MobileDataText>
              </MobileData>
            </GroupData>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Data Atualização</MobileDataLabel>
                <MobileTextData>
                  {dateFormatter(data.updated_at)}
                </MobileTextData>
              </MobileData>
              <MobileData>
                <MobileDataLabel>Status</MobileDataLabel>
                <MobileTextData>
                  {data && <Status status={data.status} />}
                </MobileTextData>
              </MobileData>
            </GroupData>
          </GroupCard>
        )
      )}
    </MobileContainer>
  );
}

export default MobileItem;
