import styled, { keyframes } from 'styled-components';
import logo from 'assets/Admin_Logo.svg';
import palette from 'theme/palette';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: ${palette.primary.main};

  border-radius: 0;
  box-shadow: none;

  justify-content: center;
  width: 50%;
  @media (max-width: 768px) {
    flex: 4;
    width: 100%;
    justify-content: flex-start;
    box-shadow: 0px -26px 19px -10px rgba(5, 12, 39, 0.2);
    border-radius: 30px 30px 0px 0px;
  }
`;

const apperatFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-50px)
  }
  to{
    opacity: 1;
    transform: translateX(0)
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;

  animation: ${apperatFromLeft} 1s;
  form {
    margin: 50px 0;
    width: 340px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #050c27 inset !important;
      -webkit-text-fill-color: #fff !important;
    }

    h3 {
      color: #fff;
      font-weight: bold;
      align-self: flex-start;
      margin-bottom: 14px;
    }

    input {
      color: #fff;
    }
  }
`;
export const Backgroud = styled.div`
  flex: 1;
  align-items: flex-end;
  background: url(${logo}) no-repeat right;
  margin-right: 50px;
  @media (max-width: 768px) {
    background: url(${logo});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 210px 100px;
    width: 100%;
  }
`;
