import React, { useEffect, useState } from 'react';
import { Card, Header } from 'components/StyledComponents';
import { CardContent, CardHeader, Grid } from '@mui/material';
import { useAuth } from 'hooks/auth';
import {
  acessarGrafana,
  acessarKibana,
  acessarOmegaMessenger,
} from 'constants/permissions';
import OmegaMessenger from './components/OmegaMessenger';
import Kibana from './components/Kibana';
import Grafana from './components/Grafana';

function ManagerAccess() {
  const { hasPermission } = useAuth();

  const [showMessenger, setShowMessenger] = useState(false);
  const [showKibana, setShowKibana] = useState(false);
  const [showGrafana, setShowGrafana] = useState(false);

  useEffect(() => {
    const shouldShow = {
      messenger: hasPermission(acessarOmegaMessenger),
      kibana: hasPermission(acessarKibana),
      grafana: hasPermission(acessarGrafana),
    };

    setShowMessenger(shouldShow.messenger);
    setShowKibana(shouldShow.kibana);
    setShowGrafana(shouldShow.grafana);
  }, [hasPermission]);

  return (
    <Card>
      <Header>
        <CardHeader
          title="Gestão de acessos"
          subheader="Painel de acessos aos nossos sistemas auxiliares"
        />
      </Header>
      <CardContent>
        <Grid container spacing={2}>
          {showMessenger && (
            <Grid item xl={4} lg={4} md={6} xs={12}>
              <OmegaMessenger />
            </Grid>
          )}
          {showKibana && (
            <Grid item xl={4} lg={4} md={6} xs={12}>
              <Kibana />
            </Grid>
          )}
          {showGrafana && (
            <Grid item xl={4} lg={4} md={6} xs={12}>
              <Grafana />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ManagerAccess;
