import NotFound from 'assets/icons/big/notFound.svg';
import NotActivated from 'assets/icons/big/notActivated.svg';
import Failed from 'assets/icons/big/failed.svg';

export interface Status {
  icon: string;
  title: string;
  subTitle: string;
  color: string;
}

interface StatusSIMCardConf {
  FALHA: Status;
  NAO_ATIVADO: Status;
  NAO_ENCONTRADO: Status;
  [key: string]: Status;
}

export const statusSIMCardConf: StatusSIMCardConf = {
  FALHA: {
    icon: Failed,
    title: 'Falha na ativação chip',
    subTitle: 'Falha na ativação chip',
    color: '#C92A2A',
  },
  NAO_ATIVADO: {
    icon: NotActivated,
    title: 'Chip sem ativação',
    subTitle: 'Chip sem ativação',
    color: '#A61E4D',
  },
  NAO_ENCONTRADO: {
    icon: NotFound,
    title: 'Chip inexistente ou erro na leitura',
    subTitle: 'Não foi possível encontrar o chip, tente novamente.',
    color: '#E67700',
  },
};
