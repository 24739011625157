import React from 'react';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface LogHighlighterProps {
  log: string;
}

function LogHighlighter({ log }: LogHighlighterProps) {
  return (
    <SyntaxHighlighter
      language="json"
      style={dracula}
      showLineNumbers
      wrapLongLines
      wrapLines
    >
      {log}
    </SyntaxHighlighter>
  );
}

export default LogHighlighter;
