// import { Fraud } from 'pages/FraudKeeper/FraudDetail/types';
import create from 'zustand';
import ReactDOM from 'react-dom';

interface Sale {
  venda_id: number;
  cliente_nome: string;
  cliente_cpf: string;
  cliente_telefone: string;
  cliente_email: string;
  cliente_endereco: string;
  status: string;
  segmento: string;
  tipo: string;
  data_criacao: string;
  motivo_cancelamento: string;
  ip: string;
  utm_medium: string;
  utm_campaign: string;
}

interface Fraud {
  curadoria_id: number;
  motivos: string[];
  vendas: Sale[];
}

type FraudStore = {
  fraud: Fraud;
  setFraud(fraud: Fraud): void;
};

export const useFraud = create<FraudStore>(set => ({
  fraud: {} as Fraud,
  setFraud(fraud: Fraud) {
    ReactDOM.unstable_batchedUpdates(() => set(state => ({ ...state, fraud })));
  },
}));
