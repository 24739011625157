import { SatisfactionSurveyFetch } from 'fetches/satisfactionSurvey';
import useBreakpoint from 'hooks/useBreakpoint';
import { SatisfactionSurvey as ISatisfactionSurvey } from 'models/SatisfactionSurvey';
import { useCallback, useEffect, useState } from 'react';
import api from 'services/api';
import { useSatisfactionSurveyStore } from 'store/satisfactionSurveyStore';
import { format, subDays } from 'date-fns';

export function useSatisfactionSurvey() {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const [satisfactionSurvey, setSatisfactionSurvey] =
    useState<ISatisfactionSurvey>();
  const [ratingSelected, setRatingSelected] = useState('Todas');
  const [origemSelected, setOrigemSelected] = useState('Todas');
  const [onlyWithComment, setOnlyWithComment] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);

  const startDate = useSatisfactionSurveyStore(state => state.startDate);
  const endDate = useSatisfactionSurveyStore(state => state.endDate);

  const setToday = useSatisfactionSurveyStore(state => state.setToday);
  const setYesterday = useSatisfactionSurveyStore(state => state.setYesterday);
  const setLastWeek = useSatisfactionSurveyStore(state => state.setLastWeek);
  const setMonth = useSatisfactionSurveyStore(state => state.setMonth);
  const setStartDate = useSatisfactionSurveyStore(state => state.setStartDate);
  const setEndDate = useSatisfactionSurveyStore(state => state.setEndDate);

  const { data: response } = SatisfactionSurveyFetch({
    page: currentPage,
    nota: ratingSelected === 'Todas' ? '' : ratingSelected,
    origem: origemSelected === 'Todas' ? '' : origemSelected,
    startDate,
    endDate,
    onlyWithComment,
  });

  const showSatisfactionSurvey = useSatisfactionSurveyStore(
    state => state.showSatisfactionSurvey,
  );
  const setShowSatisfactionSurvey = useSatisfactionSurveyStore(
    state => state.setShowSatisfactionSurvey,
  );
  const idSatisfactionSurveySelected = useSatisfactionSurveyStore(
    state => state.idSatisfactionSurveySelected,
  );
  const setIdSatisfactionSurveySelected = useSatisfactionSurveyStore(
    state => state.setIdSatisfactionSurveySelected,
  );

  const closeDrawer = useCallback(() => {
    setShowSatisfactionSurvey(false);
    setIdSatisfactionSurveySelected('');
  }, [setIdSatisfactionSurveySelected, setShowSatisfactionSurvey]);

  const handleToday = useCallback(
    (value: boolean) => {
      if (value) {
        setYesterday(false);
        setLastWeek(false);
        setMonth(false);
      }
      setStartDate(format(new Date(), 'yyyy-MM-dd'));
      setEndDate(format(new Date(), 'yyyy-MM-dd'));
      setToday(value);
    },
    [setEndDate, setLastWeek, setMonth, setStartDate, setToday, setYesterday],
  );

  const handleYesterday = useCallback(
    (yesterday: boolean) => {
      if (yesterday) {
        setToday(false);
        setLastWeek(false);
        setMonth(false);
      }
      setStartDate(format(subDays(new Date(), 1), 'yyyy-MM-dd'));
      setEndDate(format(subDays(new Date(), 1), 'yyyy-MM-dd'));
      setYesterday(yesterday);
    },
    [setEndDate, setLastWeek, setMonth, setStartDate, setToday, setYesterday],
  );

  const handleLastWeek = useCallback(
    (lasWeek: boolean) => {
      if (lasWeek) {
        setToday(false);
        setYesterday(false);
        setMonth(false);
      }
      setStartDate(format(subDays(new Date(), 7), 'yyyy-MM-dd'));
      setEndDate(format(new Date(), 'yyyy-MM-dd'));
      setLastWeek(lasWeek);
    },
    [setEndDate, setLastWeek, setMonth, setStartDate, setToday, setYesterday],
  );

  const handleMonth = useCallback(
    (month: boolean) => {
      if (month) {
        setToday(false);
        setYesterday(false);
        setLastWeek(false);
      }
      setStartDate(format(subDays(new Date(), 30), 'yyyy-MM-dd'));
      setEndDate(format(new Date(), 'yyyy-MM-dd'));
      setMonth(month);
    },
    [setEndDate, setLastWeek, setMonth, setStartDate, setToday, setYesterday],
  );

  useEffect(() => {
    setShowSatisfactionSurvey(!!idSatisfactionSurveySelected);
    if (idSatisfactionSurveySelected) {
      setSatisfactionSurvey(undefined);
      api
        .get(`/core/pesquisa-satisfacao/${idSatisfactionSurveySelected}`)
        .then(({ data }) => {
          setSatisfactionSurvey(data);
        });
    }

    if (!idSatisfactionSurveySelected) {
      setSatisfactionSurvey(undefined);
    }
  }, [idSatisfactionSurveySelected, setShowSatisfactionSurvey]);

  return {
    ratingSelected,
    origemSelected,
    onlyWithComment,
    response,
    currentPage,
    showSatisfactionSurvey,
    satisfactionSurvey,
    isTableOrLess,
    setOrigemSelected,
    setRatingSelected,
    setOnlyWithComment,
    setCurrentPage,
    setShowSatisfactionSurvey,
    closeDrawer,
    handleToday,
    handleYesterday,
    handleLastWeek,
    handleMonth,
  };
}
