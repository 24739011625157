import { useState } from 'react';
import { Button } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { green } from '@mui/material/colors';
import api from 'services/api';
import { ButtonProgress } from 'components/StyledComponents';
import { useToast } from 'hooks/toast';
import { DataEligibilitySetupsFetch } from 'fetches/setupsFetch';
import { Ellipse, Row, SubTitle } from '../styles';
import { Plans } from '../types';

interface DefenseGroupEligibilityProps {
  successDefenseEligibility: boolean;
  setSuccessDefenseEligibility(value: boolean): void;
}

function DefenseGroupEligibility({
  successDefenseEligibility,
  setSuccessDefenseEligibility,
}: DefenseGroupEligibilityProps) {
  const { data: response } = DataEligibilitySetupsFetch({
    type: 'defesa',
  });

  const [loadingDefenseEligibility, setLoadingDefenseEligibility] =
    useState(false);

  const { addToast } = useToast();

  const handleDefenseEligibility = () => {
    setLoadingDefenseEligibility(true);

    const dataResponse = {
      customer: {
        cpf: response?.cpf,
        birthday: response?.nascimento,
        name: response?.nome,
        filiation: response?.filiacao,
        zipCode: response?.cep,
        email: response?.email,
      },
      areaCode: response?.ddd,
      operation: 'POS_PAGO',
    };
    api
      .post(`/core/planos/sincronizar-planos`, dataResponse)
      .then(({ data: planos }) => {
        const totalSegmentos = planos.reduce(
          (segmentos: string[], plano: Plans) => {
            if (!segmentos.find(segmento => segmento === plano.segmento)) {
              segmentos.push(plano.segmento);
            }
            return segmentos;
          },
          [],
        );
        addToast({
          type: 'success',
          title: 'Sucesso na Elegibilidade',
          description: `Foram sincronizados ${planos.length} planos em ${totalSegmentos.length} segmentos`,
        });
        setLoadingDefenseEligibility(false);
        setSuccessDefenseEligibility(true);
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro na Elegibilidade',
          description: error.data,
        });

        setLoadingDefenseEligibility(false);
        setSuccessDefenseEligibility(false);
      });
  };
  return (
    <>
      <Row>
        <Ellipse>
          <span>2</span>
        </Ellipse>
        <SubTitle>Elegibilidade do grupo de defesa</SubTitle>
      </Row>

      <Button
        type="button"
        color="secondary"
        fullWidth
        size="large"
        variant="outlined"
        onClick={() => handleDefenseEligibility()}
        disabled={loadingDefenseEligibility}
      >
        Realizar elegibilidade
        {loadingDefenseEligibility && <ButtonProgress size={24} />}
      </Button>
      {successDefenseEligibility && (
        <Row>
          <DoneIcon style={{ color: green[500], marginRight: 5 }} />
          <SubTitle>Elegibilidade realizada com sucesso </SubTitle>
        </Row>
      )}
    </>
  );
}

export default DefenseGroupEligibility;
