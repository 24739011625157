import { useEffect, useState } from 'react';
import { endOfMonth, format, parseISO, startOfMonth, subDays } from 'date-fns';
import { useReportFilterFlagFetchStore } from 'store/useReportFilterFlagFetchStore';
import { useReportFilterStore } from 'store/useReportFilterStore';
import { FilterDateMonth, MonthSelected } from '../PopoverActions';

interface FiltersProps {
  anchorElement: null | HTMLElement;
  setAnchorElement(anchorElement: null | HTMLElement): void;
}

export function useFilters({ anchorElement, setAnchorElement }: FiltersProps) {
  const startFetch = useReportFilterFlagFetchStore(state => state.startFetch);
  const endFetch = useReportFilterFlagFetchStore(state => state.endFetch);
  const dateStart = useReportFilterFlagFetchStore(state => state.dateStart);
  const setDateStart = useReportFilterFlagFetchStore(
    state => state.setDateStart,
  );
  const dateEnd = useReportFilterFlagFetchStore(state => state.dateEnd);
  const setDateEnd = useReportFilterFlagFetchStore(state => state.setDateEnd);
  const setWithReprocessed = useReportFilterFlagFetchStore(
    state => state.setWithReprocessedFetch,
  );
  const setInReprocessing = useReportFilterFlagFetchStore(
    state => state.setInReprocessingFetch,
  );
  const setWithEngagement = useReportFilterFlagFetchStore(
    state => state.setWithEngagementFetch,
  );

  const localDateStart = useReportFilterStore(state => state.localDateStart);
  const setLocalDateStart = useReportFilterStore(
    state => state.setLocalDateStart,
  );
  const localDateEnd = useReportFilterStore(state => state.localDateEnd);
  const setLocalDateEnd = useReportFilterStore(state => state.setLocalDateEnd);
  const localWithReprocessed = useReportFilterStore(
    state => state.localWithReprocessed,
  );
  const localWithInReprocessing = useReportFilterStore(
    state => state.localWithInReprocessing,
  );
  const localWithEngagement = useReportFilterStore(
    state => state.localWithEngagement,
  );
  const localToday = useReportFilterStore(state => state.localToday);
  const setLocalToday = useReportFilterStore(state => state.setLocalToday);
  const localYesterday = useReportFilterStore(state => state.localYesterday);
  const setLocalYesterday = useReportFilterStore(
    state => state.setLocalYesterday,
  );
  const localLastWeek = useReportFilterStore(state => state.localLastWeek);
  const setLocalLastWeek = useReportFilterStore(
    state => state.setLocalLastWeek,
  );

  const [monthsSelected, setMonthsSelected] = useState<MonthSelected>(
    {} as MonthSelected,
  );
  const [firstSelected, setFirstSelected] = useState('');
  const [lastSelected, setLastSelected] = useState('');
  const [today, setToday] = useState(true);
  const [yesterday, setYesterday] = useState(false);
  const [lastWeek, setLastWeek] = useState(false);

  const initMonthSelected = (value: MonthSelected) => {
    setMonthsSelected(old => {
      return { ...old, ...value };
    });
  };

  const onFilter = () => {
    setWithReprocessed(localWithReprocessed);
    setInReprocessing(localWithInReprocessing);
    setWithEngagement(localWithEngagement);
    setToday(localToday);
    setYesterday(localYesterday);
    setLastWeek(localLastWeek);

    if (
      localToday === false &&
      localLastWeek === false &&
      localYesterday === false &&
      Object.values(monthsSelected).every(value => value === false)
    ) {
      setDateStart(parseISO(startFetch));
      setDateEnd(parseISO(endFetch));
    }

    if (Object.values(monthsSelected).some(value => value === true)) {
      setDateStart(localDateStart);
      setDateEnd(localDateEnd);
    }
  };

  useEffect(() => {
    if (today) {
      setDateStart(new Date());
      setDateEnd(new Date());
    } else if (yesterday) {
      setDateStart(subDays(new Date(), 1));
      setDateEnd(subDays(new Date(), 1));
    } else if (lastWeek) {
      setDateStart(subDays(new Date(), 7));
      setDateEnd(new Date());
    }
  }, [lastWeek, setDateEnd, setDateStart, today, yesterday]);

  useEffect(() => {
    if (localToday || localYesterday || localLastWeek) {
      setFirstSelected('');
      setLastSelected('');
    }
  }, [localLastWeek, localToday, localYesterday]);

  useEffect(() => {
    const currentDate = format(new Date(), 'yyyy-MM-dd');

    if (!firstSelected && !lastSelected) {
      setMonthsSelected(old => {
        return Object.keys(old).reduce(
          (acc: MonthSelected, current: string) => {
            acc[current] = false;
            return acc;
          },
          {} as MonthSelected,
        );
      });

      setLocalDateStart(new Date());
      setLocalDateEnd(new Date());

      return;
    }
    if (firstSelected && lastSelected) {
      setMonthsSelected(old => {
        return Object.keys(old).reduce(
          (acc: MonthSelected, current: string) => {
            acc[current] = current <= firstSelected && current >= lastSelected;
            return acc;
          },
          {} as MonthSelected,
        );
      });
      setLocalToday(false);
      setLocalYesterday(false);
      setLocalLastWeek(false);

      if (firstSelected === currentDate) {
        setLocalDateStart(
          parseISO(format(startOfMonth(parseISO(lastSelected)), 'yyyy-MM-dd')),
        );
        setLocalDateEnd(new Date());

        return;
      }

      setLocalDateStart(
        parseISO(format(startOfMonth(parseISO(lastSelected)), 'yyyy-MM-dd')),
      );
      setLocalDateEnd(
        parseISO(format(endOfMonth(parseISO(firstSelected)), 'yyyy-MM-dd')),
      );

      return;
    }
    if (firstSelected) {
      setMonthsSelected(old => {
        return Object.keys(old).reduce(
          (acc: MonthSelected, current: string) => {
            acc[current] = current === firstSelected;
            return acc;
          },
          {} as MonthSelected,
        );
      });
      setLocalToday(false);
      setLocalYesterday(false);
      setLocalLastWeek(false);

      if (firstSelected === currentDate) {
        setLocalDateStart(
          parseISO(format(startOfMonth(parseISO(firstSelected)), 'yyyy-MM-dd')),
        );
        setLocalDateEnd(new Date());

        return;
      }

      setLocalDateStart(
        parseISO(format(startOfMonth(parseISO(firstSelected)), 'yyyy-MM-dd')),
      );
      setLocalDateEnd(
        parseISO(format(endOfMonth(parseISO(firstSelected)), 'yyyy-MM-dd')),
      );
    }
  }, [
    firstSelected,
    lastSelected,
    setLocalDateEnd,
    setLocalDateStart,
    setLocalLastWeek,
    setLocalToday,
    setLocalYesterday,
    setMonthsSelected,
  ]);

  const handleRangeMonth = (filterDateMonth: FilterDateMonth) => {
    if (!firstSelected) {
      setFirstSelected(filterDateMonth.key);

      return;
    }
    if (firstSelected === filterDateMonth.key) {
      setLastSelected('');
      setFirstSelected('');

      return;
    }
    if (firstSelected <= filterDateMonth.key) {
      setLastSelected(firstSelected);
      setFirstSelected(filterDateMonth.key);

      return;
    }
    if (lastSelected === filterDateMonth.key) {
      setLastSelected('');
      setFirstSelected(firstSelected);

      return;
    }
    setLastSelected(filterDateMonth.key);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleStartChange = (date: Date | string | null) => {
    if (typeof date === 'string') {
      setDateStart(new Date(date));
      return;
    }

    date && setDateStart(date);
  };
  const handleEndChange = (date: Date | string | null) => {
    if (typeof date === 'string') {
      setDateEnd(new Date(date));
      return;
    }

    date && setDateEnd(date);
  };

  const openPopover = Boolean(anchorElement);
  const id = openPopover ? 'popover-actions' : undefined;

  return {
    id,
    openPopover,
    dateStart,
    dateEnd,
    monthsSelected,
    initMonthSelected,
    onFilter,
    handleRangeMonth,
    handleClose,
    handleStartChange,
    handleEndChange,
  };
}
