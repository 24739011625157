import { IntegrationError } from 'models/integrationErrors';
import { Typography, IconButton, Skeleton } from '@mui/material';
import { date as dateFormatter, hour as hourFormatter } from 'util/formatter';

import { TbEdit } from 'react-icons/tb';
import { Card, Item, MessageActions } from './styles';

interface DesktopBodyProps {
  integrationErrors?: IntegrationError[];
  setOpenDrawer(openDrawer: boolean): void;
  setIntegrationErrorIdSelected(integrationErrorIdSelected: number): void;
}

function DesktopBody({
  integrationErrors,
  setOpenDrawer,
  setIntegrationErrorIdSelected,
}: DesktopBodyProps) {
  function loadingList() {
    const skeletons = [];

    for (let i = 0; i < 10; i++) {
      skeletons.push(
        <Item key={i}>
          <Typography fontSize={14} display="flex" alignItems="center">
            <Skeleton sx={{ width: '40%' }} />
          </Typography>
          <Typography fontSize={14} display="flex" alignItems="center">
            <Skeleton sx={{ width: '40%' }} />
          </Typography>
          <MessageActions>
            <Typography fontSize={14}>
              <Skeleton sx={{ width: '100%' }} />
            </Typography>
            <IconButton>
              <Skeleton sx={{ width: '30px' }} />
            </IconButton>
          </MessageActions>
        </Item>,
      );
    }

    return skeletons;
  }

  return (
    <Card>
      {(integrationErrors &&
        ((integrationErrors.length &&
          integrationErrors.map(integrationError => (
            <Item key={integrationError.id}>
              <Typography fontSize={14} display="flex" alignItems="center">
                {`${dateFormatter(
                  integrationError.created_at,
                )} às ${hourFormatter(integrationError.created_at)}`}
              </Typography>
              <Typography fontSize={14} display="flex" alignItems="center">
                {integrationError.tipo}
              </Typography>
              <MessageActions>
                <Typography fontSize={14}>
                  {integrationError.mensagem}
                </Typography>
                <IconButton
                  onClick={() => {
                    setOpenDrawer(true);
                    setIntegrationErrorIdSelected(integrationError.id);
                  }}
                >
                  <TbEdit color="gray" />
                </IconButton>
              </MessageActions>
            </Item>
          ))) || <Typography>Nenhuma mensagem de erro</Typography>)) ||
        loadingList()}
    </Card>
  );
}

export default DesktopBody;
