import styled from 'styled-components';

export const DataInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
`;

export const DataText = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;
  width: 140px;
  white-space: nowrap;
  font-size: 15px;
`;
