import {
  Modal,
  Backdrop,
  Fade,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { ButtonProgress } from 'components/StyledComponents';
import { format, parseISO } from 'date-fns';
import { useToast } from 'hooks/toast';
import { useCallback, useEffect, useState } from 'react';
import api from 'services/api';
import { useGoalSelectStore } from 'store/goalSelectStore';
import { useStylesModal } from 'components/UseStylesModal';
import { ModalCard, TextModal } from './styles';

export interface DateGoal {
  date: string;
  hourInicial: string;
}

interface DefaultParametersProps {
  currentDate: string;
  appendGoals(): void;
}

function DefaultParameters({
  currentDate,
  appendGoals,
}: DefaultParametersProps) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedDateGoals, setSelectedDateGoals] = useState<DateGoal[]>([]);

  const selectedGoalsCheckbox = useGoalSelectStore(
    store => store.selectedGoalsCheckbox,
  );

  const classes = useStylesModal();

  const { addToast } = useToast();

  const selectedDates = selectedGoalsCheckbox.map(goal => goal.date);
  const now = new Date();
  const formattedNow = format(now, 'HH:mm:ss');

  const updateSelectedDateGoals = useCallback(() => {
    return selectedDates.map(date => {
      const isCurrentDate = date === currentDate;
      const hourInicial = isCurrentDate ? formattedNow : '00:00:00';
      const dateObj = parseISO(date);

      const hourInicialFormatted = format(
        parseISO(`${date}T${hourInicial}`),
        'HH:mm',
      );

      const dateFormatted = format(dateObj, 'dd/MM/yyyy');
      return {
        date: dateFormatted,
        hourInicial: hourInicialFormatted,
      };
    });
  }, [currentDate, formattedNow, selectedDates]);

  useEffect(() => {
    const updatedSelectedDateGoals = updateSelectedDateGoals();
    if (
      JSON.stringify(updatedSelectedDateGoals) !==
      JSON.stringify(selectedDateGoals)
    ) {
      setSelectedDateGoals(updatedSelectedDateGoals);
    }
  }, [selectedDateGoals, updateSelectedDateGoals]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = useCallback(() => {
    setLoading(true);

    const dates = selectedGoalsCheckbox.map(goal => ({ date: goal.date }));

    api
      .put(`/core/metas-afiliados/parametros-padroes/`, dates)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Parâmetros padrões definidos',
          description:
            'Os parâmetros padrões foram definidos nos dias selecionados com sucesso.',
        });
        setLoading(false);
        appendGoals();
        handleClose();
      })
      .catch(error => {
        let message = error.data.errors[0]?.message;

        if (!message) {
          message = error.data.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao definir parâmetros!',
            description: message,
          });

        setLoading(false);
      });
  }, [addToast, appendGoals, selectedGoalsCheckbox]);

  return (
    <Box>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        disabled={!selectedGoalsCheckbox.length || loading}
      >
        Parâmetros padrões
      </Button>
      <Modal
        aria-labelledby="tile-modal"
        aria-describedby="modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={open}>
          <ModalCard>
            <TextModal>
              Você realmente deseja definir os parâmetros padrões nos dias
              selecionados? Essa ação não pode ser desfeita.
            </TextModal>
            <DialogActions className={classes.dialogActions}>
              <Button
                className={classes.buttonCancel}
                variant="outlined"
                onClick={() => setOpen(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={handleSave}
                color="primary"
                disabled={loading}
              >
                Confirmar
                {loading && <ButtonProgress size={24} />}
              </Button>
            </DialogActions>
          </ModalCard>
        </Fade>
      </Modal>
    </Box>
  );
}

export default DefaultParameters;
