import userSWR from 'swr';
import api from 'services/api';
import { Loyalty } from 'models/loyalties';

interface Response {
  id: number;
  ativo: number;
  tim_id: string;
  descricao: string;
  nome: string;
}

export const LoyaltiesFetch = () => {
  const { data } = userSWR<Loyalty[]>(
    'loyalty',
    async (): Promise<Loyalty[]> => {
      const { data: results } = await api.get('/core/fidelidades');
      const loyalties = results.map((result: Response) => {
        return {
          id: result.id,
          ativo: result.ativo,
          nome: result.nome,
          tim_id: result.tim_id,
          descricao: result.descricao,
        };
      });

      return loyalties as Loyalty[];
    },
  );
  return { data };
};
