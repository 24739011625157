import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  SwipeableDrawer,
} from '@mui/material';
import { Header } from 'components/StyledComponents';
import InfoSale from 'pages/Sale/InfoSale';
import { useNavigate } from 'react-router-dom';
import { Sale } from 'pages/Sale/InfoSale/types';
import { SimCardsOfBatchFetch } from 'fetches/simcardsFetch';
import { useSimcardStock } from 'store/simcardStock';
import api from 'services/api';
import SIMCardList from './SIMCardList';
import Indicators, { IndicatorsProp } from '../components/Indicators';
import { BackButton, Footer } from './styles';

function BatchDetails() {
  const batchSelected = useSimcardStock(state => state.batchSelected);
  const showSaleInfo = useSimcardStock(state => state.showSaleInfo);
  const setSimCardSaleselected = useSimcardStock(
    state => state.setSimCardSaleselected,
  );
  const setShowSaleInfo = useSimcardStock(state => state.setShowSaleInfo);
  const simCardSaleselected = useSimcardStock(
    state => state.simCardSaleselected,
  );
  const { data: response } = SimCardsOfBatchFetch(batchSelected);

  const [indicators, setIndicators] = useState<IndicatorsProp>({
    indicators: [
      {
        description: 'SIMCARDS DISPONÍVEIS',
        value: '0',
      },
      {
        description: 'SIMCARDS ATIVADOS',
        value: '0',
      },
      {
        description: 'SIMCARDS COM FALHA',
        value: '0',
      },
    ],
  } as IndicatorsProp);

  const [sale, setSale] = useState<Sale>();
  const navigate = useNavigate();

  const closeDrawer = useCallback(() => {
    setShowSaleInfo(false);
    setSimCardSaleselected(0);
  }, [setShowSaleInfo, setSimCardSaleselected]);

  useEffect(() => {
    if (simCardSaleselected) {
      setSale(undefined);
      api.get(`/core/vendas/${simCardSaleselected}`).then(({ data }) => {
        setSale(data);
      });
    }
  }, [simCardSaleselected]);

  useEffect(() => {
    if (!response) {
      return;
    }

    const newIndicators = {
      indicators: [
        {
          description: 'SIMCARDS DISPONÍVEIS',
          value: String(response?.indicators.NOVO.total),
        },
        {
          description: 'SIMCARDS ATIVADOS',
          value: String(response?.indicators.ATIVADO.total),
        },
        {
          description: 'SIMCARDS COM FALHA',
          value: String(response?.indicators.FALHOU.total),
        },
      ],
    };

    setIndicators(newIndicators);
  }, [response]);

  return (
    <Box>
      <Header>
        <CardHeader
          title="Lote de SIM Cards"
          subheader="Painel de gestão de estoque de chips"
        />
      </Header>

      <Indicators indicators={indicators.indicators} />

      <CardContent>
        <SIMCardList simcards={response?.simcards} batchId={batchSelected} />
      </CardContent>

      <Footer>
        <BackButton>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate('/estoque-simcard/estoque-lotes/');
            }}
          >
            Voltar
          </Button>
        </BackButton>
      </Footer>

      <SwipeableDrawer
        anchor="right"
        open={showSaleInfo}
        onOpen={() => setShowSaleInfo(true)}
        onClose={() => {
          closeDrawer();
        }}
      >
        <InfoSale sale={sale} setSale={setSale} closeDrawer={closeDrawer} />
      </SwipeableDrawer>
    </Box>
  );
}

export default BatchDetails;
