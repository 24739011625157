import React, { FormEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import InputMask, { Props } from 'react-input-mask';
import { cpf as cpfIsValid } from 'util/validator';
import useBreakpoint from 'hooks/useBreakpoint';
import { ButtonProgress } from 'components/StyledComponents';
import ufs from 'constants/ufs.json';
import { useToast } from 'hooks/toast';
import api from 'services/api';
import { VirtualSeller } from 'models/virtualSeller';
import { GroupInput } from './styles';

import { UF } from '../types';

interface FormAddVirtualSellerProps {
  showNewVirtualSelllerMobile: boolean;
  setShowNewVirtualSelllerMobile(value: boolean): void;
  appendVirtualSeller(virtualSeller: VirtualSeller): void;
}
const url = '/core/vendedores-virtuais';
function FormAddVirtualSellerMobile({
  showNewVirtualSelllerMobile,
  setShowNewVirtualSelllerMobile,
  appendVirtualSeller,
}: FormAddVirtualSellerProps) {
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  const [cpf, setCpf] = useState('');
  const [custcode, setCustcode] = useState('');
  const [uf, setUf] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    setIsValid(!!(cpf && cpfIsValid(cpf) && uf && custcode));
  }, [cpf, uf, custcode]);

  const clearForm = () => {
    setShowNewVirtualSelllerMobile(false);
    setCpf('');
    setUf('');
    setCustcode('');
    setSaveLoading(false);
  };
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSaveLoading(true);
    const data = {
      seller_form: {
        cpf: cpf.replace(/[^0-9]+/g, ''),
        uf,
        custcode,
      },
    };
    api
      .post(url, data)
      .then(({ data: virtualSeller }) => {
        clearForm();
        appendVirtualSeller(virtualSeller);
        addToast({
          type: 'success',
          title: 'Vendedor Cadastrado',
          description: 'Novo vendedor virtual cadastrado com sucesso',
        });
      })
      .catch(err => {
        setSaveLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao criar venderdor Virtual',
          description: err?.data?.message,
        });
      });
  };

  const handleCancel = () => {
    clearForm();
  };
  return (
    <Dialog
      open={showNewVirtualSelllerMobile}
      onClose={() => setShowNewVirtualSelllerMobile(false)}
      aria-labelledby="form-dialog-title"
      fullScreen={isTabletSizeOrSmaller}
    >
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Box padding="30px">
          <CardHeader
            title="Cadastrar vendedor virtual"
            style={{ padding: 10, marginLeft: 9 }}
          />
          <CardContent>
            <GroupInput>
              <InputMask
                mask="999.999.999-99"
                maskChar=""
                value={cpf}
                onChange={e => setCpf(e.target.value)}
              >
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (inputProps: Props & TextFieldProps) => (
                    <TextField
                      {...inputProps}
                      aria-readonly
                      fullWidth
                      variant="outlined"
                      id="cpf"
                      label="CPF"
                      name="cpf"
                      error={cpf ? !cpfIsValid(cpf) : false}
                    />
                  )
                }
              </InputMask>
            </GroupInput>
            <TextField
              aria-readonly
              fullWidth
              id="custcode"
              label="Cust Code"
              margin="dense"
              name="custCode"
              variant="outlined"
              value={custcode}
              onChange={e => setCustcode(e.target.value)}
            />
            <FormControl variant="outlined" margin="dense" fullWidth>
              <InputLabel>UF</InputLabel>
              <Select
                value={uf}
                required
                onChange={e => {
                  setUf(e.target.value as string);
                }}
                label="UF"
              >
                {ufs.map((option: UF) => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </CardContent>
          <DialogActions
            style={{
              justifyContent: 'space-between',
              paddingRight: '24px',
              paddingLeft: '16px',
            }}
          >
            <Button
              style={{ color: '#C92A2A' }}
              onClick={() => {
                setShowNewVirtualSelllerMobile(false);
                handleCancel();
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={!isValid || saveLoading}
              variant="contained"
              color="primary"
              type="submit"
            >
              Salvar
              {saveLoading && <ButtonProgress size={24} />}
            </Button>
          </DialogActions>
        </Box>
      </form>
    </Dialog>
  );
}

export default FormAddVirtualSellerMobile;
