import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 0 62px 15px 38px;
  color: rgba(0, 0, 0, 0.4);

  span {
    min-width: 144px;
    width: 150px;
    white-space: nowrap;
    font-size: 12px;
    align-self: flex-start;
  }
`;

export const Data = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const DataText = styled.span`
  min-width: 150px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  color: rgba(5, 12, 39, 1);
`;
