import { Grid } from '@mui/material';
import {
  Container,
  DataInfo,
  DataText,
  Divider,
  List,
} from 'components/StyledComponents';
import { Data } from 'components/MobileList';
import { UTMConfigurationWithUTMSource } from 'models/utmConfiguration';
import { BoxBase, TextBoxBase, TitleBase, TitleBoxBase } from '../../styles';

interface CardConfigurationProps {
  configurationData: UTMConfigurationWithUTMSource;
}

function CardConfiguration({ configurationData }: CardConfigurationProps) {
  return (
    <Grid container item spacing={2}>
      <Grid item md={12} xs={12}>
        <TitleBase>Configuração</TitleBase>
      </Grid>
      <Grid item md={12} xs={12}>
        <BoxBase>
          <TitleBoxBase>Nome</TitleBoxBase>
          <TextBoxBase>{configurationData.nome}</TextBoxBase>
        </BoxBase>
      </Grid>
      <Grid item md={12} xs={12}>
        <List>
          {configurationData.sources.map(source => (
            <Container key={source.id}>
              <Data>
                <DataInfo>
                  <DataText>{source.nome}</DataText>
                  <DataText>{source.porcentagem}%</DataText>
                  <Divider />
                </DataInfo>
              </Data>
            </Container>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}

export default CardConfiguration;
