import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ButtonProgress, ContainerForm } from 'components/StyledComponents';
import { useToast } from 'hooks/toast';
import api from 'services/api';
import { Sale } from '../types';

interface ActivateBlueChipProps {
  sale: Sale;
  setSale(sale: Sale): void;
}

function ActivateBlueChip({ sale, setSale }: ActivateBlueChipProps) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const activateBlueChipSale = () => {
    setLoading(true);
    const url = '/core/vendas/ativar-bluechip';

    return api
      .post(`${url}/${sale?.id}`)
      .then(({ data }) => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Venda Ativada com Sucesso!',
        });
        setSale({ ...sale, ...data });
      })
      .catch(error => {
        setLoading(false);
        error.data &&
          addToast({
            type: 'error',
            title: 'Erro na ativação',
            description: error.data.message,
          });
        api
          .get(`core/vendas/${sale.id}`)
          .then(({ data }) => setSale({ ...sale, ...data }));
      });
  };

  return (
    <ContainerForm>
      <Button
        type="button"
        variant="contained"
        color="primary"
        disabled={loading}
        fullWidth
        size="large"
        onClick={() => setOpen(true)}
      >
        Ativar BlueChip
        {loading && <ButtonProgress size={24} />}
      </Button>

      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Deseja realmente ativar esta venda?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Ao clicar em Sim, a venda será enviada para a TIM e não será mais
            possível desfazer esta ação.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Não
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              activateBlueChipSale();
            }}
            color="primary"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </ContainerForm>
  );
}

export default ActivateBlueChip;
