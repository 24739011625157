import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import {
  FilterDateMonth,
  MonthSelected,
} from 'pages/ManagementReport/Filters/PopoverActions';
import { LineControl } from 'pages/ManagementReport/Filters/PopoverActions/style';
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns/esm';
import { ptBR } from 'date-fns/esm/locale';
import { capitalize } from 'util/formatter';

import { ButtonProgress, Form } from 'components/StyledComponents';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useAuth } from 'hooks/auth';
import {
  relatoriosEspeciaisAtivados,
  relatoriosEspeciaisCustoEnvio,
  relatoriosEspeciaisPedidos,
  relatoriosEspeciaisReprocessamentoManual,
} from 'constants/permissions';
import {
  ButtonFilter,
  ButtonType,
  Container,
  Content,
  GridMonths,
  GridReport,
  useStyles,
} from './styles';

interface FiltersProps {
  start: Date;

  setStart(newDate: Date): void;

  end: Date;

  setEnd(newDate: Date): void;

  today: boolean;

  setToday(value: boolean): void;

  yesterday: boolean;

  setYesterday(value: boolean): void;

  lastWeek: boolean;

  setLastWeek(value: boolean): void;

  order: boolean;

  setOrder(value: boolean): void;

  activated: boolean;

  setActivated(value: boolean): void;

  shippingCost: boolean;

  setShippingCost(value: boolean): void;

  manualReprocessing: boolean;

  setManualReprocessing(value: boolean): void;

  monthsSelected: MonthSelected;

  initMonthSelected(value: MonthSelected): void;

  handleRangeMonth(value: FilterDateMonth): void;

  handleSubmit(event: React.FormEvent<HTMLFormElement>): void;

  loading: boolean;

  formIsValid: boolean;
}

function Filters({
  start,
  setStart,
  end,
  setEnd,
  today,
  setToday,
  yesterday,
  setYesterday,
  lastWeek,
  setLastWeek,
  monthsSelected,
  initMonthSelected,
  handleRangeMonth,
  handleSubmit,
  order,
  setOrder,
  activated,
  setActivated,
  shippingCost,
  setShippingCost,
  manualReprocessing,
  setManualReprocessing,
  loading,
  formIsValid,
}: FiltersProps) {
  const classes = useStyles();

  const [months, setMonths] = useState<FilterDateMonth[]>([]);
  const [showButtonOrder, setShowButtonOrder] = useState(false);
  const [showButtonActivated, setShowButtonActivated] = useState(false);
  const [showButtonShippingCost, setShowButtonShippingCost] = useState(false);
  const [showButtonManualReprocessing, setShowButtonManualReprocessing] =
    useState(false);

  const { hasPermission } = useAuth();

  useEffect(() => {
    const shouldShow = {
      pedidos: hasPermission(relatoriosEspeciaisPedidos),
      ativados: hasPermission(relatoriosEspeciaisAtivados),
      custoEnvio: hasPermission(relatoriosEspeciaisCustoEnvio),
      reprocessamentoManual: hasPermission(
        relatoriosEspeciaisReprocessamentoManual,
      ),
    };

    setShowButtonOrder(shouldShow.pedidos);
    setShowButtonActivated(shouldShow.ativados);
    setShowButtonShippingCost(shouldShow.custoEnvio);
    setShowButtonManualReprocessing(shouldShow.reprocessamentoManual);
  }, [hasPermission]);

  useEffect(() => {
    const currentDate = new Date();

    setMonths([]);
    for (let i = 0; i < 12; i++) {
      const date = subMonths(currentDate, i);

      const filterDateMonth = {
        month: format(date, 'MMMM', {
          locale: ptBR,
        }),
        firstDay: format(startOfMonth(date), 'yyyy-MM-dd'),
        lastDay: format(endOfMonth(date), 'yyyy-MM-dd'),
        key: format(date, 'yyyy-MM-dd'),
      };

      setMonths(old => [...old, filterDateMonth]);
      initMonthSelected({ [filterDateMonth.key]: false });
    } // eslint-disable-next-line
  }, []);

  const handleToday = useCallback(
    (value: boolean) => {
      if (value) {
        setYesterday(false);
        setLastWeek(false);
      }
      setToday(value);
    },
    [setLastWeek, setToday, setYesterday],
  );

  const handleYesterday = useCallback(
    (value: boolean) => {
      if (value) {
        setToday(false);
        setLastWeek(false);
      }

      setYesterday(value);
    },
    [setLastWeek, setToday, setYesterday],
  );

  const handleLastWeek = useCallback(
    (value: boolean) => {
      if (value) {
        setToday(false);
        setYesterday(false);
      }

      setLastWeek(value);
    },
    [setLastWeek, setToday, setYesterday],
  );

  const handleOrder = useCallback(
    (value: boolean) => {
      if (value) {
        setActivated(false);
        setShippingCost(false);
        setManualReprocessing(false);
      }
      setOrder(value);
    },
    [setActivated, setManualReprocessing, setOrder, setShippingCost],
  );

  const handleActivated = useCallback(
    (value: boolean) => {
      if (value) {
        setOrder(false);
        setShippingCost(false);
        setManualReprocessing(false);
      }
      setActivated(value);
    },
    [setActivated, setManualReprocessing, setOrder, setShippingCost],
  );

  const handleShippingCost = useCallback(
    (value: boolean) => {
      if (value) {
        setOrder(false);
        setActivated(false);
        setManualReprocessing(false);
      }
      setShippingCost(value);
    },
    [setActivated, setManualReprocessing, setOrder, setShippingCost],
  );

  const handleManualReprocessing = useCallback(
    (value: boolean) => {
      if (value) {
        setOrder(false);
        setActivated(false);
        setShippingCost(false);
      }
      setManualReprocessing(value);
    },
    [setActivated, setManualReprocessing, setOrder, setShippingCost],
  );

  const handleStartChange = (date: Date | string | null) => {
    if (typeof date === 'string') {
      setStart(new Date(date));
      return;
    }
    date && setStart(date);
  };
  const handleEndChange = (date: Date | string | null) => {
    if (typeof date === 'string') {
      setEnd(new Date(date));
      return;
    }
    date && setEnd(date);
  };
  return (
    <Container>
      <Content>
        <Typography className={classes.title}>Dias</Typography>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={6}>
            <ButtonFilter onClick={() => handleToday(!today)} actived={today}>
              HOJE
            </ButtonFilter>
            <ButtonFilter
              onClick={() => handleYesterday(!yesterday)}
              actived={yesterday}
            >
              ONTEM
            </ButtonFilter>
            <ButtonFilter
              onClick={() => handleLastWeek(!lastWeek)}
              actived={lastWeek}
            >
              D-7
            </ButtonFilter>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <DesktopDatePicker
                  label="Início"
                  inputFormat="dd/MM/yyyy"
                  InputAdornmentProps={{ position: 'end' }}
                  // invalidDateMessage="Data inválida"
                  value={start}
                  onChange={date => handleStartChange(date)}
                  className={classes.keyboardDate}
                  renderInput={params => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <DesktopDatePicker
                  label="Fim"
                  minDate={start}
                  inputFormat="dd/MM/yyyy"
                  InputAdornmentProps={{ position: 'end' }}
                  // invalidDateMessage="Data inválida"
                  value={end}
                  onChange={date => handleEndChange(date)}
                  // minDateMessage="A data não deve ser anterior à data mínima"
                  className={classes.keyboardDate}
                  renderInput={params => <TextField {...params} fullWidth />}
                  // style={{ marginTop: 10 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <LineControl>
          <Typography className={classes.title}>Últimos 12 meses</Typography>
          <Typography variant="body2" style={{ marginLeft: 8, marginTop: 1 }}>
            Selecione meses adjacentes
          </Typography>
        </LineControl>
        <GridMonths>
          {months.map(item => (
            <Button
              key={item.firstDay}
              variant="contained"
              color={monthsSelected[item.key] ? 'primary' : 'inherit'}
              className={classes.buttonMonth}
              onClick={() => handleRangeMonth(item)}
            >
              {capitalize(item.month)}
            </Button>
          ))}
        </GridMonths>
        <LineControl>
          <Typography className={classes.title}>Tipo de relatório</Typography>
          <Typography variant="body2" style={{ marginLeft: 8, marginTop: 1 }}>
            Selecione apenas um
          </Typography>
        </LineControl>
        <GridReport>
          {showButtonOrder && (
            <ButtonType onClick={() => handleOrder(!order)} actived={order}>
              PEDIDOS
            </ButtonType>
          )}
          {showButtonActivated && (
            <ButtonType
              onClick={() => handleActivated(!activated)}
              actived={activated}
            >
              ATIVADOS
            </ButtonType>
          )}
          {showButtonShippingCost && (
            <ButtonType
              onClick={() => handleShippingCost(!shippingCost)}
              actived={shippingCost}
            >
              CUSTO DE ENVIO
            </ButtonType>
          )}

          {showButtonManualReprocessing && (
            <ButtonType
              onClick={() => handleManualReprocessing(!manualReprocessing)}
              actived={manualReprocessing}
            >
              REPROCESSAMENTO MANUAL
            </ButtonType>
          )}
        </GridReport>
      </Content>
      <Form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          className={classes.buttonExport}
          disabled={!formIsValid || loading}
        >
          Exportar
          {loading && <ButtonProgress size={24} />}
        </Button>
      </Form>
    </Container>
  );
}

export default Filters;
