import userSWR from 'swr';
import api from 'services/api';
import { Role } from 'models/roles';

export const RolesFetch = () => {
  const { data, mutate } = userSWR<Role[]>(
    'role',
    async (): Promise<Role[]> => {
      const { data: results } = await api.get('/core/funcoes');

      return results as Role[];
    },
  );
  return { data, mutate };
};
