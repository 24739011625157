import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonProgress, Card } from 'components/StyledComponents';
import {
  Button,
  CardContent,
  CardHeader,
  Checkbox,
  DialogActions,
  Grid,
  Skeleton,
  TextField,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import { RoleByIdFetch } from 'fetches/roleByIdFetch';
import { PermissionsFetch } from 'fetches/permissionFetch';
import useBreakpoint from 'hooks/useBreakpoint';
import api from 'services/api';
import { useToast } from 'hooks/toast';

import { Permission } from 'models/permission';
import { PermissoesSelecionadas } from '../types';
import { Header, useStyles } from '../styles';

function EditRole() {
  const params = useParams();
  const { id } = params;
  const { data: role, mutate } = RoleByIdFetch(parseInt(id || '0', 10));
  const { data: permissoes } = PermissionsFetch();

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const classes = useStyles();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [nome, setNome] = useState(role?.nome || '');
  const [formIsValid, setFormIsValid] = useState(false);
  const [permissoesSelecionadas, setPermissoes] =
    useState<PermissoesSelecionadas>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setNome(role?.nome || '');
    let loadPermissions = {};

    role?.permissions.map((permission: Permission) => {
      loadPermissions = { ...loadPermissions, [permission.id]: true };
      return loadPermissions;
    });
    setPermissoes(loadPermissions);
  }, [role, setPermissoes]);

  useEffect(() => {
    const contemPermissaoSelecionada = !!Object.keys(
      permissoesSelecionadas,
    ).find(key => permissoesSelecionadas[key]);
    setFormIsValid(contemPermissaoSelecionada && !!nome);
  }, [nome, permissoesSelecionadas]);

  const handleUpdate = useCallback(() => {
    const data = {
      role_form: {
        nome,
        permissions: Object.keys(permissoesSelecionadas).reduce(
          (acc, current) => {
            if (permissoesSelecionadas[current]) {
              acc.push(current);
            }
            return acc;
          },
          [] as string[],
        ),
      },
    };
    setLoading(true);

    api
      .put(`/core/funcoes/${role?.id}`, data)
      .then(({ data: responseRole }) => {
        mutate(responseRole, true);
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Função editada com sucesso',
        });
        navigate('/gestao-funcao/');
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro ao Atualizar a Função.',
          description:
            err.data && err.data.message ? err.data.message : err.message,
        });
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, mutate, navigate, nome, permissoesSelecionadas, role?.id]);

  return (
    <Card>
      <CardHeader
        title="Editar função"
        subheader="Edite o nome e permissões da função e depois clique em salvar."
      />
      <CardContent>
        <TextField
          id="nome"
          aria-readonly
          fullWidth
          label="Nome da função"
          margin="dense"
          required
          name="nome"
          value={nome}
          onChange={e => setNome(e.target.value)}
          variant="outlined"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: isTableOrLess ? 'column' : 'row',
          }}
        />

        <Header>
          <span>Permissões</span>
        </Header>
        <Grid
          container
          spacing={1}
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          {!permissoes ? (
            <Skeleton width={140} />
          ) : (
            permissoes?.map(permissao => (
              <Grid key={permissao.id} item xs={12} sm={4}>
                <FormControlLabel
                  classes={{
                    root: classes.root,
                    label: permissoesSelecionadas[permissao.id]
                      ? classes.labelSelecionada
                      : '',
                  }}
                  value="end"
                  control={
                    <Checkbox
                      checked={!!permissoesSelecionadas[permissao.id]}
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxOutlinedIcon color="primary" />}
                      value={!!permissoesSelecionadas[permissao.id]}
                      onChange={e => {
                        setPermissoes({
                          ...permissoesSelecionadas,
                          [permissao.id]: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={permissao.descricao}
                  labelPlacement="end"
                />
              </Grid>
            ))
          )}
        </Grid>
      </CardContent>
      <DialogActions
        style={{
          justifyContent: 'space-between',
          paddingRight: '24px',
          paddingLeft: '16px',
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            navigate('/gestao-funcao/');
          }}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdate}
          disabled={!formIsValid || loading}
        >
          Salvar
          {loading && <ButtonProgress size={24} />}
        </Button>
      </DialogActions>
    </Card>
  );
}

export default EditRole;
