import { IconButton, TextField } from '@mui/material';
import { Button, Item } from 'pages/UtmDefinition/styles';
import { ReactComponent as CloseIcon } from 'assets/icons/small/vector.svg';
import { FiEdit } from 'react-icons/fi';
import { TbTrash } from 'react-icons/tb';
import palette from 'theme/palette';
import { UtmCampaign } from 'models/utmCampaign';
import { Content } from './styles';

interface CampaignListProps {
  campaigns: UtmCampaign[];
  campaignName: string;
  showEditCampaign: boolean;
  campaignIdSelected: string | null;
  setCampaignName(campaignName: string): void;
  handlePercentChange(percent: string, index: number): void;
  handleEditCampaignName(index: number): void;
  setShowEditCampaign(showEditCampaign: boolean): void;
  setCampaignIdSelected(campaignIdSelected: string | null): void;
  handleOpenDialog(campaignId: string): void;
}

function CampaignList({
  campaigns,
  campaignName,
  setCampaignName,
  showEditCampaign,
  campaignIdSelected,
  handlePercentChange,
  handleEditCampaignName,
  setShowEditCampaign,
  setCampaignIdSelected,
  handleOpenDialog,
}: CampaignListProps) {
  return (
    <Content>
      {campaigns.map((campaign, index) => (
        <Item key={campaign.id}>
          {showEditCampaign && String(campaign.id) === campaignIdSelected ? (
            <TextField
              fullWidth
              label={(!showEditCampaign && campaign.nome) || ''}
              size="small"
              value={campaignName}
              onChange={e => setCampaignName(e.target.value)}
            />
          ) : (
            <TextField
              fullWidth
              label={campaign.nome}
              size="small"
              value={campaign.porcentagem}
              placeholder="%"
              onKeyDown={event => {
                if (
                  !/[0-9]|Backspace|Delete|ArrowLeft|ArrowRight|Home|End/.test(
                    event.key,
                  )
                ) {
                  event.preventDefault();
                }
              }}
              onChange={e => handlePercentChange(e.target.value, index)}
            />
          )}
          {showEditCampaign && String(campaign.id) === campaignIdSelected ? (
            <>
              <Button
                variant="contained"
                onClick={() => handleEditCampaignName(index)}
              >
                Salvar
              </Button>
              <IconButton
                onClick={() => {
                  setShowEditCampaign(false);
                  setCampaignIdSelected(null);
                  setCampaignName('');
                }}
              >
                <CloseIcon stroke="red" />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                onClick={() => {
                  setShowEditCampaign(true);
                  setCampaignName(campaign.nome);
                  setCampaignIdSelected(String(campaign.id));
                }}
              >
                <FiEdit />
              </IconButton>
              {campaigns.length > 1 && (
                <IconButton
                  onClick={() => handleOpenDialog(String(campaign.id))}
                >
                  <TbTrash color={palette.error.main} />
                </IconButton>
              )}
            </>
          )}
        </Item>
      ))}
    </Content>
  );
}

export default CampaignList;
