import { IconButton, Skeleton, TextField, Typography } from '@mui/material';
import vectorClose from 'assets/icons/small/vector.svg';
import { date as dateFormatter, hour as hourFormatter } from 'util/formatter';
import { ButtonProgress } from 'components/StyledComponents';
import {
  DivClose,
  ContentDrawer,
  CreatedAndType,
  DataLabel,
  DivSwitches,
  Button,
} from './styles';
import IntegrationErrorSwitch from './Switch';
import { useInfoIntegrationError } from './hooks/useInfoIntegrationError';

interface InfoIntegrationErrorProps {
  integrationErrorIdSelected: number;
  setOpenDrawer(openDrawer: boolean): void;
}

function InfoIntegrationError({
  integrationErrorIdSelected,
  setOpenDrawer,
}: InfoIntegrationErrorProps) {
  const {
    fetchResponse,
    description,
    setDescription,
    friendlyMessage,
    setFriendlyMessage,
    emailMessage,
    setEmailMessage,
    blocker,
    setBlocker,
    treatable,
    setTreatable,
    sieve,
    setSieve,
    reprocessable,
    setReprocessable,
    saving,
    handleSave,
  } = useInfoIntegrationError(integrationErrorIdSelected);

  return (
    <ContentDrawer>
      <DivClose>
        <IconButton size="small" onClick={() => setOpenDrawer(false)}>
          <img src={vectorClose} alt="fechar" />
        </IconButton>
      </DivClose>
      {(fetchResponse && (
        <>
          <CreatedAndType>
            <DataLabel>
              <Typography color="gray">Criado</Typography>
              <Typography>{`${dateFormatter(
                fetchResponse.created_at,
              )} às ${hourFormatter(fetchResponse.created_at)}`}</Typography>
            </DataLabel>
            <DataLabel>
              <Typography color="gray">Tipo</Typography>
              <Typography>{fetchResponse.tipo}</Typography>
            </DataLabel>
          </CreatedAndType>

          <TextField
            fullWidth
            label="Descrição"
            size="small"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          <TextField
            fullWidth
            label="Mensagem amigável"
            size="small"
            value={friendlyMessage}
            onChange={e => setFriendlyMessage(e.target.value)}
          />
          <TextField
            fullWidth
            label="Mensagem email"
            size="small"
            value={emailMessage}
            onChange={e => setEmailMessage(e.target.value)}
          />

          <DivSwitches>
            <IntegrationErrorSwitch
              label="Bloqueante"
              checked={blocker}
              setChecked={setBlocker}
            />
            <IntegrationErrorSwitch
              label="Tratável"
              checked={treatable}
              setChecked={setTreatable}
            />
          </DivSwitches>

          <DivSwitches>
            <IntegrationErrorSwitch
              label="Crivo"
              checked={sieve}
              setChecked={setSieve}
            />
            <IntegrationErrorSwitch
              label="Reprocessável"
              checked={reprocessable}
              setChecked={setReprocessable}
            />
          </DivSwitches>

          <Button
            variant="contained"
            disabled={saving}
            onClick={() => handleSave()}
          >
            Salvar
            {saving && <ButtonProgress size={22} />}
          </Button>
        </>
      )) || <Skeleton />}
    </ContentDrawer>
  );
}

export default InfoIntegrationError;
