import useBreakpoint from 'hooks/useBreakpoint';
import { List } from 'components/StyledComponents';
import { ConfigurationPackage } from 'models/configurationsPackage';
import { Container } from 'components/CardList';
import Item from './Item';
import MobileItem from './MobileItem';
import { Header } from './styles';

interface OfferDefinitionListProps {
  configurations?: ConfigurationPackage[];
  updateConfigurations(): void;
}
interface LoadingListProps {
  isTableOrLess: boolean;
}
function LoadingList({ isTableOrLess }: LoadingListProps) {
  if (isTableOrLess) {
    return (
      <>
        <MobileItem loading />
        <MobileItem loading />
        <MobileItem loading />
        <MobileItem loading />
      </>
    );
  }

  return (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function OfferDefinitionList({
  configurations,
  updateConfigurations,
}: OfferDefinitionListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  return (
    <Container>
      {!isTableOrLess && (
        <Header>
          <span>Nome</span>
          <span>Plano base</span>
          <span>Flash Sales</span>
          <span>C6</span>
          <span>Portabilidade</span>
          <span>Dedicado</span>
          <span>Total</span>
          <span>Data Início</span>
          <span>Data Fim</span>
        </Header>
      )}

      <List>
        {!configurations && <LoadingList isTableOrLess={isTableOrLess} />}
        {configurations &&
          configurations.map(flashSale => {
            if (isTableOrLess) {
              return (
                <MobileItem
                  key={flashSale.id}
                  data={flashSale}
                  updateConfigurations={updateConfigurations}
                />
              );
            }
            return (
              <Item
                key={flashSale.id}
                data={flashSale}
                updateConfigurations={updateConfigurations}
              />
            );
          })}
      </List>
    </Container>
  );
}

export default OfferDefinitionList;
