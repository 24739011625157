import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 20px;
`;

export const FilterMargin = styled.div`
  margin: 0 15px;

  .selectFilter {
    width: 150px;
    margin-right: 16px;
  }

  @media (max-width: 1023px) {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    align-self: center;

    .selectFilter {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 16px;
    }
  }
`;

export const FilterReason = styled.div`
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  flex-direction: column;
  vertical-align: top;
  width: 200px;
  margin-right: 16px;

  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const Item = styled.div`
  color: #263238;
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 16px;
  box-sizing: border-box;
  align-items: center;
  font-weight: 400;
  border-radius: 4px;
`;

export const Input = styled.input`
  border-radius: 4px;
  width: 100%;
  border: 0;
  height: 27px;
  margin: 0;
  display: block;
  padding: 6px 10px 7px;
  box-sizing: content-box;
`;

export const Label = styled.label`
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  z-index: 1;
  transform: translate(14px, -6px) scale(0.75);
  transform-origin: top left;

  color: #263238;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
`;

export const Fieldset = styled.fieldset`
  border-color: rgba(0, 0, 0, 0.23);
  top: -5px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  border-style: solid;
  border-width: 1px;
  border-radius: inherit;
  pointer-events: none;
`;

export const Legend = styled.legend`
  width: auto;
  max-width: 1000px;
  height: 11px;
  display: block;
  padding: 0;
  font-size: 0.75em;
  text-align: left;
  visibility: hidden;
  transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
`;
