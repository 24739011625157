import styled from 'styled-components';

export const Status = styled.img`
  align-self: flex-end;
`;

export const Action = styled.div`
  display: flex;
  padding: 5px;
  color: #546e7a;
  margin-left: auto;
  justify-content: flex-end;
`;

export const DataText = styled.span`
  min-width: 100px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.8);
  button {
    margin-right: 10px;
    height: 19px;
    border: none;
    background: transparent;
  }
`;

export const DataColumn = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: auto auto;

  .item-email {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @media (min-width: 768px) {
    display: grid;
    grid-column-gap: 50px;
    grid-template-columns: auto auto auto auto;

    .item-email {
      grid-column-start: 3;
      grid-column-end: 5;
    }
  }
  @media (min-width: 500px) and (max-width: 768px) {
    display: grid;
    grid-template-columns: auto auto auto;

    .item-email {
      grid-column-start: 3;
      grid-column-end: 4;
    }
  }
`;
