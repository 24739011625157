import useSWR from 'swr';

import api from 'services/api';
import { EvolutionThirtyDay } from 'models/reports';

export const EvolutionThirtyDayFetch = (
  day: string,
  reprocessed: boolean,
  inReprocessing: boolean,
  engagement: boolean,
) => {
  const { data, mutate } = useSWR<EvolutionThirtyDay>(
    `evolution_thirty_day_${day}_${reprocessed}_${inReprocessing}_${engagement}`,

    async (): Promise<EvolutionThirtyDay> => {
      const params = {
        dia: day,
        trazer_reprocessados: reprocessed ? 1 : 0,
        trazer_em_processamento: inReprocessing ? 1 : 0,
        trazer_engajadas: engagement ? 1 : 0,
      };
      const { data: segments } = await api.get(
        'core/relatorios/dashboards/evolucao-trinta-dias',
        {
          params,
        },
      );
      return segments as EvolutionThirtyDay;
    },
  );
  return { data, mutate };
};
