import useSWR from 'swr';
import api from 'services/api';
import { Error } from 'models/bugReport';

interface Params {
  inicio?: string;
  fim?: string;
  only_order_requests?: boolean;
  only_eligibility_requests?: boolean;
  only_transaction_requests?: boolean;
  only_post_code_requests?: boolean;
  only_domain_requests?: boolean;
}
interface FetchProps {
  start?: string;
  end?: string;
  only_order_requests?: boolean;
  only_eligibility_requests?: boolean;
  only_transaction_requests?: boolean;
  only_post_code_requests?: boolean;
  only_domain_requests?: boolean;
}
export const BugReportFetch = ({
  start,
  end,
  only_order_requests,
  only_eligibility_requests,
  only_transaction_requests,
  only_post_code_requests,
  only_domain_requests,
}: FetchProps) => {
  const { data, mutate } = useSWR<Error[]>(
    `bugFetch_${start}_${end}_${only_order_requests}_${only_eligibility_requests}_${only_transaction_requests}_${only_post_code_requests}_${only_domain_requests}`,

    async (): Promise<Error[]> => {
      const filterDate = {
        inicio: start,
        fim: end,
      };
      const params: Params = start && end ? { ...filterDate } : {};
      if (only_order_requests) {
        params.only_order_requests = only_order_requests;
      }

      if (only_eligibility_requests) {
        params.only_eligibility_requests = only_eligibility_requests;
      }

      if (only_transaction_requests) {
        params.only_transaction_requests = only_transaction_requests;
      }

      if (only_post_code_requests) {
        params.only_post_code_requests = only_post_code_requests;
      }

      if (only_domain_requests) {
        params.only_domain_requests = only_domain_requests;
      }

      const response = await api.get(`core/chamados`, {
        params,
      });
      const called = response.data as Error[];

      return called;
    },
  );
  return { data, mutate };
};
