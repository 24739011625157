import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Container, LineControl, useStyles } from './styles';
import Flags from './Flags';
import Selects, { SelectOptions } from './Selects';
import { statusCrosscoreOptions } from './options';
import { usePopoverActions } from './hooks/usePopoverActions';

interface PopoverActionsProps {
  id: string;
  openPopover: boolean;
  anchorEl: null | HTMLElement;
  handleClick(event: React.MouseEvent<HTMLElement>): void;
  onFilter(): void;
  onClose(value: boolean): void;
}

function PopoverActions({
  id,
  openPopover,
  anchorEl,
  handleClick,
  onFilter,
  onClose,
}: PopoverActionsProps) {
  const {
    statusCrossCoreSelecionados,
    requestedDocumentationSelecionado,
    start,
    end,
    handleStartChange,
    handleEndChange,
    handleCheckStatusCrosscore,
    setRequestedDocumentationSelecionado,
  } = usePopoverActions();

  const classes = useStyles();

  return (
    <Popover
      id={id}
      open={openPopover}
      onClose={handleClick}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Container className={classes.root}>
        <LineControl>
          <DesktopDatePicker
            inputFormat="dd/MM/yyyy"
            // invalidDateMessage="Data inválida"
            value={start}
            openTo="day"
            onChange={date => handleStartChange(date)}
            renderInput={params => <TextField fullWidth {...params} />}
            // size="small"
          />
          <DesktopDatePicker
            minDate={start}
            inputFormat="dd/MM/yyyy"
            InputAdornmentProps={{ position: 'end' }}
            // invalidDateMessage="Data inválida"
            value={end}
            onChange={date => handleEndChange(date)}
            // size="small"
            renderInput={params => <TextField {...params} />}
            // minDateMessage="A data não deve ser anterior à data mínima"
          />
          <Selects />
          <Flags />
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <FormControl
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                style={{ width: '100%' }}
              >
                <InputLabel htmlFor="documentos-solicitados" focused shrink>
                  Filtrar por documentos solicitados
                </InputLabel>
                <Select
                  native
                  value={requestedDocumentationSelecionado}
                  onChange={e =>
                    setRequestedDocumentationSelecionado(
                      e.target.value as string,
                    )
                  }
                  input={
                    <OutlinedInput
                      notched
                      label="Filtrar por documentos solicitados"
                      name="documentos-solicitados"
                      id="outlined-documentos-solicitados"
                    />
                  }
                >
                  <option aria-label="Todos" value="all">
                    Todos
                  </option>
                  <option value="1">Solicitados</option>
                  <option value="0">Não solicitados</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl
                variant="outlined"
                margin="none"
                size="small"
                fullWidth
                style={{ width: '100%', marginTop: 8 }}
              >
                <InputLabel>Filtrar por Status Crosscore</InputLabel>
                <Select
                  labelId="statusCrosscore"
                  id="statusCrosscore"
                  multiple
                  value={statusCrossCoreSelecionados}
                  input={
                    <OutlinedInput
                      id="select-status-crosscore"
                      label="Filtrar por Status Crosscore"
                    />
                  }
                  renderValue={() => (
                    <Box className={classes.chips}>
                      {statusCrossCoreSelecionados.map(
                        (value: SelectOptions) => (
                          <Chip
                            key={value.value}
                            label={value.text}
                            className={classes.chip}
                          />
                        ),
                      )}
                    </Box>
                  )}
                >
                  <Box style={{ boxSizing: 'border-box' }}>
                    <Box
                      style={{
                        display: 'grid',
                        gridAutoColumns: '1fr',
                        gridTemplateColumns: 'repeat(1, 1fr)',
                      }}
                    >
                      {statusCrosscoreOptions.map((item: SelectOptions) => (
                        <MenuItem
                          key={item.value}
                          value={item.text}
                          onClick={() => handleCheckStatusCrosscore(item)}
                        >
                          <Checkbox
                            checked={
                              !!statusCrossCoreSelecionados.find(
                                selected => selected.value === item.value,
                              )
                            }
                          />
                          <ListItemText primary={`${item.text}`} />
                        </MenuItem>
                      ))}
                    </Box>
                  </Box>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ textTransform: 'capitalize', padding: 1 }}
            onClick={() => {
              onFilter();
              onClose(false);
            }}
          >
            Filtrar
          </Button>
        </LineControl>
      </Container>
    </Popover>
  );
}

export default PopoverActions;
