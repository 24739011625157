import React, { useCallback, useState } from 'react';
import copyIcon from 'assets/icons/small/copy.svg';
import copyToClipboard from 'util/copyToClipboard';

import {
  MobileContainer,
  MobileData,
  MobileDataLabel,
  MobileDataText,
} from 'components/StyledComponents';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSimcardStock } from 'store/simcardStock';
import { useNavigate } from 'react-router-dom';
import { GroupData } from 'components/MobileList';
import { format, parseISO } from 'date-fns';
import { IconButton, Skeleton } from '@mui/material';
import { Action, GroupCard, MobileTextData } from './styles';
import { ItemProps, simcardBatchStatus } from '../Item';
import PopperActions from '../PopperActions';

function MobileItem({ data, loading = false }: ItemProps) {
  const setBatchSelected = useSimcardStock(state => state.setBatchSelected);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElement(anchorElement ? null : event.currentTarget);
    },
    [anchorElement],
  );

  const navigate = useNavigate();

  const open = Boolean(anchorElement);
  const id = open ? `popperAction_${data?.lote_id}` : undefined;

  return (
    <MobileContainer>
      {loading ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        data && (
          <>
            <Action>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <PopperActions
                id={id || ''}
                open={open}
                batch={data}
                anchorElement={anchorElement}
              />
            </Action>
            <GroupCard
              onClick={() => {
                data && setBatchSelected(data.lote_id);
                navigate('/estoque-simcard/estoque-lotes-simcards/');
              }}
            >
              <GroupData>
                <MobileData>
                  <MobileDataLabel>Quantidade</MobileDataLabel>
                  <MobileTextData>{data.quantidade}</MobileTextData>
                </MobileData>
                <MobileData>
                  <MobileDataLabel>HRL</MobileDataLabel>
                  <MobileTextData>
                    {data?.hlr ? data?.hlr : 'Sem HRL'}
                  </MobileTextData>
                </MobileData>
              </GroupData>
              <MobileData>
                <MobileDataLabel>ICCID Inicial</MobileDataLabel>
                <MobileDataText>
                  {data?.primeiro_iccid}
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      copyToClipboard(data.primeiro_iccid);
                    }}
                    color="inherit"
                  >
                    <img src={copyIcon} alt="copiar" />
                  </IconButton>
                </MobileDataText>
              </MobileData>
              <MobileData>
                <MobileDataLabel>ICCID Final</MobileDataLabel>
                <MobileDataText>
                  {data?.ultimo_iccid}
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      copyToClipboard(data.ultimo_iccid);
                    }}
                    color="inherit"
                  >
                    <img src={copyIcon} alt="copiar" />
                  </IconButton>
                </MobileDataText>
              </MobileData>
              <GroupData>
                <MobileData>
                  <MobileDataLabel>Data da Cadastro</MobileDataLabel>
                  <MobileTextData>
                    {format(parseISO(data.data_criacao), 'dd/MM/yyyy - HH:mm')}
                  </MobileTextData>
                </MobileData>
                <MobileData>
                  <MobileDataLabel>Status</MobileDataLabel>
                  <MobileTextData>
                    {data && simcardBatchStatus[data.status].name}
                  </MobileTextData>
                </MobileData>
              </GroupData>
            </GroupCard>
          </>
        )
      )}
    </MobileContainer>
  );
}

export default MobileItem;
