import { Box, Button, Grid, TextField } from '@mui/material';
import { NoteAddOutlined } from '@mui/icons-material';
import { ButtonProgress } from 'components/StyledComponents';
import { useToast } from 'hooks/toast';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import api from 'services/api';
import { BoxButton, useStyles } from './styles';

interface ImportBaseProps {
  appendNewAffiliateBase(): void;
}
function ImportBase({ appendNewAffiliateBase }: ImportBaseProps) {
  const fileRef = useRef<HTMLInputElement>(null);

  const { addToast } = useToast();
  const [baseType, setBaseType] = useState('');
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    setFormIsValid(!!(file && baseType));
  }, [baseType, file]);

  const classes = useStyles();

  const clearForm = useCallback(() => {
    setBaseType('');
    setFile(undefined);
  }, []);

  const handleUpload = (files: FileList | null) => {
    if (files) {
      setFile(files[0]);
    }
  };

  const handleImportBase = useCallback(() => {
    const data = new FormData();

    if (file) {
      data.append('arquivo', file);
    }

    setLoading(true);
    api
      .post(`core/afiliados/importar-base/${baseType}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        appendNewAffiliateBase();
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Base importada com sucesso!',
        });
        clearForm();
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro na importação',
          description: error?.data?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, appendNewAffiliateBase, baseType, clearForm, file]);

  return (
    <Box display="flex" justifyContent="space-between">
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} xs={12}>
          <div>
            <input
              ref={fileRef}
              accept=".csv"
              id="contained-button-file"
              type="file"
              onChange={e => {
                handleUpload(e.target.files);
              }}
              style={{ display: 'none' }}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="outlined"
                component="span"
                className={classes.buttonUpload}
                fullWidth
                endIcon={<NoteAddOutlined />}
              >
                {file ? file.name : 'Selecionar arquivo'}
              </Button>
            </label>
          </div>
        </Grid>
        <Grid item lg={4} md={3} xs={6}>
          <TextField
            label="Tipo de base"
            variant="outlined"
            select
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{ native: true }}
            size="small"
            fullWidth
            className={classes.buttonSelect}
            onChange={e => {
              setBaseType(e.target.value);
            }}
            value={baseType}
            required
          >
            <option value="" disabled>
              Selecionar opção
            </option>
            <option value="reprocessamento">Reprocessamento</option>
            <option value="tratamento">Tratamento</option>
          </TextField>
        </Grid>

        <BoxButton>
          <Button
            disabled={!formIsValid || loading}
            onClick={() => {
              handleImportBase();
            }}
            variant="contained"
            color="primary"
            className={classes.buttonImport}
          >
            {loading && <ButtonProgress size={24} />}
            Importar
          </Button>
        </BoxButton>
      </Grid>
    </Box>
  );
}

export default ImportBase;
