import useBreakpoint from 'hooks/useBreakpoint';
import React, { useEffect, useState } from 'react';

import {
  A,
  ActionButtonPagination,
  Container,
  InforQuantityItems,
  Li,
  Ul,
} from './styles';

interface PaginationProps {
  currentPage?: number;
  lastPage: number;
  perPage: number;
  total: number;
  setCurrentPage(page: number): void;
}

function Pagination({
  currentPage = 1,
  setCurrentPage,
  lastPage,
  perPage,
  total,
}: PaginationProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  const [pages, setPages] = useState<number[]>([]);
  const [itensInfo, setItensInfo] = useState('');
  useEffect(() => {
    const array = [];
    let startPage = currentPage - 2 > 0 ? currentPage - 2 : 1;
    const endPage =
      currentPage + 2 <= lastPage ? currentPage + 2 : (lastPage as number);
    while (startPage <= endPage) {
      array.push(startPage++);
    }
    setPages(array);
    setItensInfo(() => {
      if (currentPage === 1) {
        return `1 - ${perPage}`;
      }
      return `${(currentPage - 1) * perPage + 1} - ${
        currentPage * perPage > total ? total : currentPage * perPage
      }`;
    });
  }, [currentPage, lastPage, perPage, total]);

  return (
    <Container isDesktop={!isTableOrLess}>
      {currentPage > 1 && (
        <>
          <ActionButtonPagination onClick={() => setCurrentPage(1)}>
            Primeiro
          </ActionButtonPagination>
          <ActionButtonPagination
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            Anterior
          </ActionButtonPagination>
        </>
      )}

      <Ul>
        {pages.map(page => (
          <Li key={page}>
            <A
              selected={!(page - currentPage)}
              onClick={() => setCurrentPage(page)}
            >
              {page}
            </A>
          </Li>
        ))}
      </Ul>
      {currentPage !== lastPage && lastPage > currentPage && (
        <>
          <ActionButtonPagination
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Próximo
          </ActionButtonPagination>
          <ActionButtonPagination onClick={() => setCurrentPage(lastPage)}>
            Último
          </ActionButtonPagination>
        </>
      )}
      <InforQuantityItems>
        exibindo {itensInfo} de {total}{' '}
      </InforQuantityItems>
    </Container>
  );
}

export default Pagination;
