import { Button, Grid, TextField } from '@mui/material';
import { ButtonProgress } from 'components/StyledComponents';
import { useToast } from 'hooks/toast';
import { LPOffer } from 'models/lpOffers';
import { useCallback, useEffect, useState } from 'react';
import api from 'services/api';
import HeaderList from '../components/HeaderList';
import { TextHelper } from './styles';

interface BaseboardProps {
  offers?: LPOffer;
  appendNewOffer(): void;
}

function Baseboard({ offers, appendNewOffer }: BaseboardProps) {
  const [textBaseboard, setTextBaseboard] = useState('');
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    offers && setTextBaseboard(offers.rodape);
  }, [offers]);

  const handleSave = useCallback(() => {
    setLoading(true);
    api
      .post(`/core/lp-ofertas/`, {
        lp_offer_form: {
          melhor_oferta: offers?.melhor_oferta,
          melhor_oferta_kv: offers?.melhor_oferta_kv,
          primeira_oferta: offers?.primeira_oferta,
          segunda_oferta: offers?.segunda_oferta,
          pos_pago: offers?.pos_pago,
          whatsapp_url: offers?.whatsapp_url,
          rodape: textBaseboard,
        },
      })
      .then(() => {
        appendNewOffer();
        addToast({
          type: 'success',
          title: 'Informações salvas',
          description: 'Informações salvas com sucesso.',
        });
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        let { message } = error.data;

        if (!message) {
          message = error.data.errors[0]?.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao criar!',
            description: message,
          });
      });
  }, [addToast, appendNewOffer, offers, textBaseboard]);
  return (
    <>
      <HeaderList title="Rodapé" />
      <Grid container sx={{ padding: '0px 16px' }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Texto"
            margin="dense"
            value={textBaseboard || ''}
            onChange={e => {
              setTextBaseboard(e.target.value);
            }}
            name="texto"
            size="small"
            variant="outlined"
            multiline
            rows={6}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Digite aqui"
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        sx={{ padding: 2 }}
      >
        <Grid item xs={7} md="auto">
          <TextHelper>
            Para deixar em branco, apenas apague o texto e salve novamente.
          </TextHelper>
        </Grid>
        <Grid item xs={5} md="auto">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={loading}
          >
            Salvar rodapé
            {loading && <ButtonProgress size={24} />}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default Baseboard;
