import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { ClassNameMap } from '@mui/styles';
import { SwitchIOS } from 'components/SwitchIOS';
import { useConfigurationPackageStore } from 'store/configurationPackageStore';
import { CategoryPackage } from 'models/packages';
import { Card } from '../styles';

interface PackageC6Props {
  planSelected: number;
  c6?: CategoryPackage[];
  gb: number;
  handleChangeWithC6(event: boolean): void;
  classes: ClassNameMap;
}
function PackageC6({
  planSelected,
  c6,
  gb,
  handleChangeWithC6,
  classes,
}: PackageC6Props) {
  const configurationStore = useConfigurationPackageStore(
    state => state.configuration,
  );
  const setConfigurationStore = useConfigurationPackageStore(
    state => state.setConfigurationPackage,
  );
  const showBonusC6 = useConfigurationPackageStore(
    state => state.configuration.mostrar_bonus_c6,
  );

  const handleChangePackageC6 = (valor: string) => {
    const c6Id = parseInt(valor, 10);
    setConfigurationStore({ ...configurationStore, pacote_c6: c6Id });
  };

  return (
    <Card planSelected={planSelected}>
      <Typography variant="h5" gutterBottom className={classes.title}>
        Pacote C6
      </Typography>
      {planSelected !== 0 ? (
        c6?.length ? (
          c6?.map(item => (
            <div key={item.categoria}>
              <FormControl
                variant="outlined"
                margin="none"
                size="small"
                fullWidth
                className={classes.field}
              >
                <InputLabel htmlFor="pacote" focused shrink>
                  Pacote
                </InputLabel>
                <Select
                  native
                  value={configurationStore.pacote_c6 || 0}
                  label="pacote"
                  onChange={e => {
                    handleChangePackageC6(e.target.value as string);
                  }}
                >
                  <option aria-label="vazio" value="null">
                    Sem pacote
                  </option>
                  {item.pacotes?.map(value => (
                    <option aria-label="plan" key={value.id} value={value.id}>
                      {value.nome}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                value={gb || 0}
                label="GB"
                margin="dense"
                name="gb"
                size="small"
                variant="outlined"
                className={classes.field}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormControlLabel
                label={
                  <Typography variant="body2">
                    Somar GB no site e carrinho
                  </Typography>
                }
                control={
                  <SwitchIOS
                    size="medium"
                    color="secondary"
                    checked={!!showBonusC6}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeWithC6(!!e.target.checked)
                    }
                  />
                }
                style={{ marginLeft: 0 }}
                labelPlacement="end"
              />
            </div>
          ))
        ) : !c6 ? (
          <Skeleton />
        ) : (
          <Typography
            variant="subtitle2"
            gutterBottom
            className={classes.subtitle}
          >
            Nenhum pacote encontrado
          </Typography>
        )
      ) : (
        <Typography
          variant="subtitle2"
          gutterBottom
          className={classes.subtitle}
        >
          Selecione o plano primeiro
        </Typography>
      )}
    </Card>
  );
}

export default PackageC6;
