import React, { useCallback, useState } from 'react';
import api from 'services/api';
import { SaleResponse } from 'pages/Operation';
import { useToast } from 'hooks/toast';
import CardCTA from 'components/CardCTA';

interface PrintTagProps {
  saleFounded: SaleResponse | null;
  openModal: boolean;
  setOpenModal(value: boolean): void;
  updateSimCards(): void;
}

function PrintTag({
  saleFounded,
  openModal,
  setOpenModal,
  updateSimCards,
}: PrintTagProps) {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const handleUpdateSaleToSent = useCallback(() => {
    setLoading(true);
    saleFounded &&
      api
        .put(`/core/vendas/atualizar-detalhe/${saleFounded?.id}`, {
          status: 'ENVIADO',
        })
        .then(({ data }) => {
          addToast({
            type: 'success',
            title: 'Sucesso!',
            description: data.mensagem,
          });

          updateSimCards();
        })
        .catch(error => {
          addToast({
            type: 'error',
            title: 'Erro na atualização',
            description: error?.data?.message,
          });
        })
        .finally(() => {
          setLoading(false);
          setOpenModal(false);
        });
  }, [saleFounded, addToast, updateSimCards, setOpenModal]);
  return (
    <CardCTA
      title="A etiqueta foi impressa?"
      subtitle="Se a etiqueta foi impressa, o pacote é considerado enviado e o cliente
    será notificado."
      loading={loading}
      openModal={openModal}
      setOpenModal={setOpenModal}
      handleRequest={handleUpdateSaleToSent}
    />
  );
}

export default PrintTag;
