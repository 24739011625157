import {
  Container,
  Data,
  DataInfo,
  DataText,
  Divider,
  List,
  MobileContainer,
  MobileData,
  MobileDataLabel,
} from 'components/StyledComponents';
import { MobileTextName } from 'components/MobileList';
import { Grid } from '@mui/material';
import useBreakpoint from 'hooks/useBreakpoint';
import { EligibilityData } from 'models/baseWithConfigurationById';
import { Header, TitleBase } from '../../styles';

interface CardResultEligibilityProps {
  eligibilityData: EligibilityData[];
  baseStatus: string;
}

function CardResultEligibility({
  eligibilityData,
  baseStatus,
}: CardResultEligibilityProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(900);

  return (
    <Grid container item spacing={2}>
      <Grid item md={12} xs={12}>
        <TitleBase>Resultado Elegibilidade</TitleBase>
      </Grid>
      <Grid item md={12} xs={12}>
        {!isTableOrLess && (
          <Header>
            <span>Mensagem</span>
            <span>Qtd</span>
          </Header>
        )}

        <List>
          {eligibilityData.length ? (
            eligibilityData.map(eligibility =>
              isTableOrLess ? (
                <MobileContainer key={eligibility.mensagem}>
                  <MobileData>
                    <MobileDataLabel>Mensagem</MobileDataLabel>
                    <MobileTextName>{eligibility.mensagem}</MobileTextName>
                  </MobileData>
                  <MobileData>
                    <MobileDataLabel>Qtd</MobileDataLabel>
                    <MobileTextName>
                      {eligibility.quantidade_mensagem}
                    </MobileTextName>
                  </MobileData>
                </MobileContainer>
              ) : (
                <Container key={eligibility.mensagem}>
                  <Data>
                    <DataInfo>
                      <DataText>{eligibility.mensagem}</DataText>
                      <DataText>{eligibility.quantidade_mensagem}</DataText>
                      <Divider />
                    </DataInfo>
                  </Data>
                </Container>
              ),
            )
          ) : baseStatus === 'ARMAZENADO' ? (
            <span>Essa base já foi armazenada</span>
          ) : (
            <span>Não há resultados disponíveis</span>
          )}
        </List>
      </Grid>
    </Grid>
  );
}

export default CardResultEligibility;
