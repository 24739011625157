import styled from 'styled-components';

export const DataText = styled.span`
  min-width: 60px;
  width: 70px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
  display: flex;
  align-items: center;
`;

export const DataTextName = styled.span`
  min-width: 110px;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
  display: flex;
  align-items: center;
`;
