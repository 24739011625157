import React, { useState } from 'react';
import { Box, Button, ClickAwayListener, Popper } from '@mui/material';
import { Actions, UseStyles } from 'components/PopperActions';
import { useNavigate } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { BiWindowClose } from 'react-icons/bi';
import DialogConfirmIntractableSale from 'pages/SalesCorrection/EditSaleCorrection/DialogConfirmIntractableSale';

interface PopperActionsProps {
  id: string;
  open: boolean;
  saleId: number;
  anchorElement: null | HTMLElement;
  handleClick(event: React.MouseEvent<HTMLElement>): void;
  handleClickAway(): void;
  updateSales(): void;
}

function PopperActions({
  id,
  open,
  saleId,
  anchorElement,
  handleClick,
  handleClickAway,
  updateSales,
}: PopperActionsProps) {
  const classes = UseStyles();
  const navigate = useNavigate();

  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [openDialogIntractableSale, setOpenDialogIntractableSale] =
    useState(false);

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClickAway}
    >
      <Box>
        {open ? (
          <Popper
            id={id}
            open={open}
            anchorEl={anchorElement}
            placement="bottom"
            modifiers={[
              {
                name: 'flip',
                enabled: true,
              },
              {
                name: 'preventOverflow',
                enabled: true,
                options: {
                  boundariesElement: 'scrollParent',
                },
              },
              {
                name: 'arrow',
                enabled: true,
                options: {
                  element: arrowRef,
                },
              },
            ]}
            className={classes.popper}
          >
            <span className={classes.arrow} ref={setArrowRef} />
            <Actions className={classes.paper}>
              <Button
                startIcon={<FiEdit />}
                fullWidth
                className={classes.editButton}
                onClick={e => {
                  navigate(`/correcao-vendas/edita-venda/${saleId}`);
                  handleClick(e);
                }}
              >
                Editar venda
              </Button>
              <div
                style={{
                  width: '100%',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                }}
              />
              <Button
                startIcon={<BiWindowClose />}
                fullWidth
                onClick={() => setOpenDialogIntractableSale(true)}
              >
                Venda intratável
              </Button>
            </Actions>
          </Popper>
        ) : null}

        <DialogConfirmIntractableSale
          saleId={saleId}
          open={openDialogIntractableSale}
          setOpen={setOpenDialogIntractableSale}
          updateSales={updateSales}
        />
      </Box>
    </ClickAwayListener>
  );
}

export default PopperActions;
