import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  margin-bottom: 30px;
  .Button {
    display: flex;
    padding: 5px;
    margin-left: auto;
    justify-content: flex-end;
  }
`;

export const Action = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const Infos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
`;

export const Divider = styled.div`
  width: 100%;
  padding: 0 16px;
  border-top: 1px solid #f2f2f2;
`;
export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const DataInfo = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.8);
`;
export const MobileTextName = styled.span`
  width: 250px;
  font-size: 12px;
  word-break: break-word;
`;
