import { useCallback, useState } from 'react';
import { Button, DialogActions, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import api from 'services/api';
import { OfferMarketing } from 'models/offersMarketing';
import { useToast } from 'hooks/toast';
import { ButtonProgress } from 'components/StyledComponents';
import { ModalCard } from 'pages/CmsLP/components/DeleteModal/styles';
import { useStylesModal } from 'components/UseStylesModal';

interface DialogDeleteOfferLinkProps {
  id: number;
  slug: string;
  offers: OfferMarketing[];
  openDialogDeleteOffer: boolean;
  handleClose(): void;
  updateOffers(faqs: OfferMarketing[], showRevalid?: boolean): void;
}

function DialogDeleteOfferLink({
  id,
  offers,
  slug,
  openDialogDeleteOffer,
  handleClose,
  updateOffers,
}: DialogDeleteOfferLinkProps) {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();
  const classes = useStylesModal();

  const handleDelete = useCallback(() => {
    setLoading(true);
    api
      .delete(`/core/ofertas/${id}`)
      .then(() => {
        updateOffers(
          offers?.filter(offer => offer.id !== id),
          true,
        );
        addToast({
          type: 'success',
          title: 'Link excluído',
          description: `O link ${slug} foi excluído com sucesso do sistema.`,
        });
        setLoading(false);
        handleClose();
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Remover link ',
          description: error?.data?.message,
        });
        setLoading(false);
        handleClose();
      });
  }, [id, updateOffers, offers, addToast, slug, handleClose]);

  return (
    <Modal
      aria-labelledby="tile-modal"
      aria-describedby="modal-description"
      className={classes.modal}
      open={openDialogDeleteOffer}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 200,
      }}
    >
      <Fade in={openDialogDeleteOffer}>
        <ModalCard>
          <Typography>Deseja mesmo excluir o link?</Typography>
          <DialogActions className={classes.dialogActions}>
            <Button
              className={classes.buttonCancel}
              variant="outlined"
              onClick={() => handleClose()}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={handleDelete}
              color="primary"
              disabled={loading}
            >
              Confirmar
              {loading && <ButtonProgress size={24} />}
            </Button>
          </DialogActions>
        </ModalCard>
      </Fade>
    </Modal>
  );
}

export default DialogDeleteOfferLink;
