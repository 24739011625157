import { useCallback, useEffect, useState } from 'react';
import { CardHeader, Grid, SwipeableDrawer } from '@mui/material';
import { format, subDays } from 'date-fns';
import { Card } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { BugReportFetch } from 'fetches/bugReportFetch';
import { Error } from 'models/bugReport';
import BugReportList from './BugReportList';
import ErrorInfo from './ErrorInfo';
import Filters from './Filters';
import Eligibility from './reports/Eligibility';
import Order from './reports/Order';
import { ContentBugReport, Header } from './styles';

function BugReport() {
  const [fifteenMinutes, setFifteenMinutes] = useState(false);
  const [oneHour, setOneHour] = useState(false);
  const [today, setToday] = useState(false);
  const [yesterday, setYesterday] = useState(false);
  const [dateStart, setDateStart] = useState<Date>(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [dateEnd, setDateEnd] = useState<Date>(
    new Date(new Date().setHours(23, 59, 0, 0)),
  );

  const start = format(dateStart, 'yyyy-MM-dd HH:mm:ss');
  const end = format(dateEnd, 'yyyy-MM-dd HH:mm:ss');
  const [context, setContext] = useState('');
  const [showErrorInfo, setShowErrorInfo] = useState(false);
  const [errorSelected, setErrorSelected] = useState<Error>();

  const { data: response } = BugReportFetch({
    start,
    end,
    only_order_requests: context === 'ORDEM' && true,
    only_post_code_requests: context === 'CEP' && true,
    only_eligibility_requests: context === 'ELEGIBILIDADE' && true,
    only_transaction_requests: context === 'TRANSACAO' && true,
    only_domain_requests: context === 'DOMINIO' && true,
  });

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  useEffect(() => {
    if (fifteenMinutes) {
      let min = 15;
      min *= 60;

      const subMin = new Date().getTime() - min * 1000;

      setDateStart(new Date(subMin));
      setDateEnd(new Date());
    }
    if (oneHour) {
      let hour = 1;
      hour = hour * 60 * 60;

      const subHour = new Date().getTime() - hour * 1000;

      setDateStart(new Date(subHour));
      setDateEnd(new Date());
    }
    if (today) {
      setDateStart(new Date(new Date().setHours(0, 0, 0, 0)));
      setDateEnd(new Date(new Date().setHours(23, 59, 0, 0)));
    }
    if (yesterday) {
      setDateStart(subDays(new Date(new Date().setHours(0, 0, 0, 0)), 1));
      setDateEnd(subDays(new Date(new Date().setHours(23, 59, 0, 0)), 1));
    }
  }, [fifteenMinutes, oneHour, setDateEnd, setDateStart, today, yesterday]);

  const closeDrawer = useCallback(() => {
    setErrorSelected(undefined);
    setShowErrorInfo(false);
  }, []);

  return (
    <Card>
      <Header isDesktop={!isTableOrLess}>
        <CardHeader
          title="Relatório de Erros"
          subheader="Painel para exportação de relatórios"
          className="cardHeader"
        />
        <Filters
          fifteenMinutes={fifteenMinutes}
          setFifteenMinutes={setFifteenMinutes}
          oneHour={oneHour}
          setOneHour={setOneHour}
          today={today}
          setToday={setToday}
          yesterday={yesterday}
          setYesterday={setYesterday}
          start={dateStart}
          end={dateEnd}
          setStart={setDateStart}
          setEnd={setDateEnd}
          context={context}
          setContext={setContext}
          isTableOrLess={isTableOrLess}
        />
      </Header>
      <ContentBugReport>
        <Grid container spacing={1}>
          <Grid item xl={6} lg={6} md={6} xs={12}>
            <Eligibility start={start} end={end} />
          </Grid>
          <Grid item xl={6} lg={6} md={6} xs={12}>
            <Order start={start} end={end} />
          </Grid>
        </Grid>
        <BugReportList
          start={start}
          errors={response}
          setShowErrorInfo={setShowErrorInfo}
          setErrorSelected={setErrorSelected}
        />
      </ContentBugReport>
      <SwipeableDrawer
        anchor="right"
        open={showErrorInfo}
        onOpen={() => setShowErrorInfo(true)}
        onClose={() => {
          closeDrawer();
        }}
      >
        {errorSelected && (
          <ErrorInfo error={errorSelected} closeDrawer={closeDrawer} />
        )}
      </SwipeableDrawer>
    </Card>
  );
}

export default BugReport;
