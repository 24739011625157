import { CardHeader, Divider } from '@mui/material';
import { Card, Header } from 'components/StyledComponents';
import { useEffect, useState } from 'react';
import { TotalizatorByMediaTypeFetch } from 'fetches/totalizatorByMediaTypeFetch';
import { IndicatorsProps } from 'pages/ManagementReport/reports/TotalByMedia';
import { format, isValid, subDays } from 'date-fns';
import Filters from './components/Filters';
import UtmDistributionList from './components/UtmDistributionList';
import UtmDistribuitionActions from './components/UtmDistribuitionActions';

function UtmDistribution() {
  const [today, setToday] = useState(false);
  const [yesterday, setYesterday] = useState(false);
  const [dayBeforeYesterday, setDayBeforeYesterday] = useState(false);
  const [date, setDate] = useState<Date>(new Date());
  const [day, setDay] = useState('');

  const { data: response, mutate } = TotalizatorByMediaTypeFetch(
    day || format(date, 'yyyy-MM-dd'),
    day || format(date, 'yyyy-MM-dd'),
    true,
    true,
    true,
  );

  const [indicators, setIndicators] = useState<IndicatorsProps>({
    indicators: [
      {
        porcentagem_total: '0',
        total: 0,
      },
    ],
  } as IndicatorsProps);

  useEffect(() => {
    if (!response) {
      return;
    }

    const newIndicators = {
      indicators: [
        {
          pedidos: response?.indicators.pedidos,
          total: response?.indicators.total,
          conversao: response?.indicators.conversao,
          porcentagem_total: response?.indicators.porcentagem_total,
        },
      ],
    };

    setIndicators(newIndicators);
  }, [response]);

  useEffect(() => {
    if (today) {
      setDate(new Date());
    }
    if (yesterday) {
      setDate(subDays(new Date(), 1));
    }
    if (dayBeforeYesterday) {
      setDate(subDays(new Date(), 2));
    }
  }, [dayBeforeYesterday, setDate, today, yesterday]);

  useEffect(() => {
    if (date && isValid(date)) {
      setDay(format(date, 'yyyy-MM-dd'));
    }
  }, [date]);

  return (
    <Card>
      <Header>
        <CardHeader
          title="Distribuição de UTM"
          subheader="Painel de transferência dos valores de UTM."
        />
      </Header>
      <Filters
        day={date}
        setDay={setDate}
        today={today}
        setToday={setToday}
        yesterday={yesterday}
        setYesterday={setYesterday}
        dayBeforeYesterday={dayBeforeYesterday}
        setDayBeforeYesterday={setDayBeforeYesterday}
      />

      <UtmDistributionList utms={response} indicators={indicators} />
      <div style={{ margin: '32px 0px' }}>
        <Divider />
      </div>
      {response && (
        <UtmDistribuitionActions
          utms={response?.medias}
          day={day}
          updateUtms={mutate}
        />
      )}
    </Card>
  );
}

export default UtmDistribution;
