import React, { FormEvent, useEffect, useState } from 'react';
import { CardHeader } from '@mui/material';

import { Card, Header } from 'components/StyledComponents';
import {
  endOfMonth,
  format,
  isAfter,
  isEqual,
  isValid,
  parseISO,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
import {
  FilterDateMonth,
  MonthSelected,
} from 'pages/ManagementReport/Filters/PopoverActions';
import api from 'services/api';
import { downloadXlsFile } from 'util/blob';
import { useToast } from 'hooks/toast';
import { Content } from 'components/CardList';
import Filters from './Filters';

function SpecialReports() {
  const [monthsSelected, setMonthsSelected] = useState<MonthSelected>(
    {} as MonthSelected,
  );

  const [firstSelected, setFirstSelected] = useState('');
  const [lastSelected, setLastSelected] = useState('');

  const [today, setToday] = useState(false);
  const [yesterday, setYesterday] = useState(false);
  const [lastWeek, setLastWeek] = useState(false);

  const [order, setOrder] = useState(false);
  const [activated, setActivated] = useState(false);
  const [shippingCost, setShippingCost] = useState(false);
  const [manualReprocessing, setManualReprocessing] = useState(false);
  const [reportType, setReportType] = useState('');

  const [dateStart, setDateStart] = useState<Date>(
    subMonths(new Date().setHours(0, 0, 0, 0), 1),
  );
  const [dateEnd, setDateEnd] = useState<Date>(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );

  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  const [loading, setLoading] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    setFormIsValid(!!reportType);
  }, [reportType]);

  const initMonthSelected = (value: MonthSelected) => {
    setMonthsSelected(old => {
      return { ...old, ...value };
    });
  };

  useEffect(() => {
    if (today) {
      setDateStart(new Date());
      setDateEnd(new Date());
    } else if (yesterday) {
      setDateStart(subDays(new Date(), 1));
      setDateEnd(subDays(new Date(), 1));
    } else if (lastWeek) {
      setDateStart(subDays(new Date(), 7));
      setDateEnd(new Date());
    }
  }, [lastWeek, setDateEnd, setDateStart, today, yesterday]);

  useEffect(() => {
    if (order) {
      setReportType('PEDIDOS');
    }
    if (activated) {
      setReportType('ATIVADOS');
    }
    if (shippingCost) {
      setReportType('CUSTO_DE_ENVIO');
    }
    if (manualReprocessing) {
      setReportType('REPROCESSAMENTO_MANUAL');
    }
    if (!order && !activated && !shippingCost && !manualReprocessing) {
      setReportType('');
    }
  }, [activated, manualReprocessing, order, shippingCost]);

  useEffect(() => {
    if (today || yesterday || lastWeek) {
      setFirstSelected('');
      setLastSelected('');
    }
  }, [lastWeek, today, yesterday]);

  useEffect(() => {
    const currentDate = format(new Date(), 'yyyy-MM-dd');

    if (!firstSelected && !lastSelected) {
      setMonthsSelected(old => {
        return Object.keys(old).reduce(
          (acc: MonthSelected, current: string) => {
            acc[current] = false;
            return acc;
          },
          {} as MonthSelected,
        );
      });

      setDateStart(new Date());
      setDateEnd(new Date());

      return;
    }
    if (firstSelected && lastSelected) {
      setMonthsSelected(old => {
        return Object.keys(old).reduce(
          (acc: MonthSelected, current: string) => {
            acc[current] = current <= firstSelected && current >= lastSelected;
            return acc;
          },
          {} as MonthSelected,
        );
      });
      setToday(false);
      setYesterday(false);
      setLastWeek(false);

      if (firstSelected === currentDate) {
        setDateStart(
          parseISO(format(startOfMonth(parseISO(lastSelected)), 'yyyy-MM-dd')),
        );
        setDateEnd(new Date());

        return;
      }

      setDateStart(
        parseISO(format(startOfMonth(parseISO(lastSelected)), 'yyyy-MM-dd')),
      );
      setDateEnd(
        parseISO(format(endOfMonth(parseISO(firstSelected)), 'yyyy-MM-dd')),
      );

      return;
    }
    if (firstSelected) {
      setMonthsSelected(old => {
        return Object.keys(old).reduce(
          (acc: MonthSelected, current: string) => {
            acc[current] = current === firstSelected;
            return acc;
          },
          {} as MonthSelected,
        );
      });
      setToday(false);
      setYesterday(false);
      setLastWeek(false);

      if (firstSelected === currentDate) {
        setDateStart(
          parseISO(format(startOfMonth(parseISO(firstSelected)), 'yyyy-MM-dd')),
        );
        setDateEnd(new Date());

        return;
      }

      setDateStart(
        parseISO(format(startOfMonth(parseISO(firstSelected)), 'yyyy-MM-dd')),
      );
      setDateEnd(
        parseISO(format(endOfMonth(parseISO(firstSelected)), 'yyyy-MM-dd')),
      );
    }
  }, [firstSelected, lastSelected, setMonthsSelected]);

  useEffect(() => {
    if (
      dateStart &&
      dateEnd &&
      isValid(dateStart) &&
      isValid(dateEnd) &&
      (isAfter(dateEnd, dateStart) || isEqual(dateEnd, dateStart))
    ) {
      setStart(format(dateStart, 'yyyy-MM-dd'));
      setEnd(format(dateEnd, 'yyyy-MM-dd'));
    }
  }, [dateStart, dateEnd]);

  const handleRangeMonth = (filterDateMonth: FilterDateMonth) => {
    if (!firstSelected) {
      setFirstSelected(filterDateMonth.key);

      return;
    }
    if (firstSelected === filterDateMonth.key) {
      setLastSelected('');
      setFirstSelected('');

      return;
    }
    if (firstSelected <= filterDateMonth.key) {
      setLastSelected(firstSelected);
      setFirstSelected(filterDateMonth.key);

      return;
    }
    if (lastSelected === filterDateMonth.key) {
      setLastSelected('');
      setFirstSelected(firstSelected);

      return;
    }
    setLastSelected(filterDateMonth.key);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const params = {
      inicio: start || null,
      fim: end || null,
      tipo_relatorio_especial: reportType,
    };

    setLoading(true);

    api
      .get('/core/relatorios/especial/', { responseType: 'blob', params })
      .then(({ data }) => {
        const filename = `relatório-${start}-${end}-${reportType}`;
        downloadXlsFile({ filename, file: data });
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro o baixar o relatório!',
          description:
            err.data && err.data.message ? err.data.message : err.message,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Card>
      <Header>
        <CardHeader
          title="Exportar"
          subheader="Módulo para exportação de relatórios"
        />
      </Header>
      <Content>
        <Filters
          start={dateStart}
          end={dateEnd}
          setStart={setDateStart}
          setEnd={setDateEnd}
          today={today}
          setToday={setToday}
          yesterday={yesterday}
          setYesterday={setYesterday}
          lastWeek={lastWeek}
          setLastWeek={setLastWeek}
          order={order}
          setOrder={setOrder}
          activated={activated}
          setActivated={setActivated}
          shippingCost={shippingCost}
          setShippingCost={setShippingCost}
          manualReprocessing={manualReprocessing}
          setManualReprocessing={setManualReprocessing}
          monthsSelected={monthsSelected}
          initMonthSelected={initMonthSelected}
          handleRangeMonth={handleRangeMonth}
          handleSubmit={handleSubmit}
          loading={loading}
          formIsValid={formIsValid}
        />
      </Content>
    </Card>
  );
}

export default SpecialReports;
