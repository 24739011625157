import { Grid, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useCallback } from 'react';
import { ButtonFilter } from './styles';

interface FiltersProps {
  day: Date;

  setDay(day: Date): void;

  today: boolean;

  setToday(value: boolean): void;

  yesterday: boolean;

  setYesterday(value: boolean): void;

  dayBeforeYesterday: boolean;

  setDayBeforeYesterday(value: boolean): void;
}
function Filters({
  day,
  setDay,
  today,
  setToday,
  yesterday,
  setYesterday,
  dayBeforeYesterday,
  setDayBeforeYesterday,
}: FiltersProps) {
  const handleDayChange = (date: Date | string | null) => {
    if (typeof date === 'string') {
      setDay(new Date(date));
      return;
    }
    date && setDay(date);
  };

  const handleYesterday = useCallback(
    (value: boolean) => {
      if (value) {
        setToday(false);
        setDayBeforeYesterday(false);
      }

      setYesterday(value);
    },
    [setDayBeforeYesterday, setToday, setYesterday],
  );

  const handleToday = useCallback(
    (value: boolean) => {
      if (value) {
        setYesterday(false);
        setDayBeforeYesterday(false);
      }

      setToday(value);
    },
    [setDayBeforeYesterday, setToday, setYesterday],
  );

  const handleDayBeforeYesterday = useCallback(
    (value: boolean) => {
      if (value) {
        setToday(false);
        setYesterday(false);
      }

      setDayBeforeYesterday(value);
    },
    [setDayBeforeYesterday, setToday, setYesterday],
  );

  return (
    <Grid
      container
      spacing={2}
      padding={2}
      alignItems="center"
      justifyContent="flex-start"
    >
      <Grid item md={3} xs={12}>
        <DesktopDatePicker
          label="Dia"
          inputFormat="dd/MM/yyyy"
          InputAdornmentProps={{ position: 'end' }}
          value={day}
          onChange={date => handleDayChange(date)}
          renderInput={params => (
            <TextField {...params} fullWidth size="small" />
          )}
        />
      </Grid>

      <Grid item sx={{ display: 'flex' }} md={4} xs={12}>
        <ButtonFilter
          onClick={() => handleYesterday(!yesterday)}
          actived={yesterday}
        >
          Ontem
        </ButtonFilter>
        <ButtonFilter onClick={() => handleToday(!today)} actived={today}>
          Hoje
        </ButtonFilter>
        <ButtonFilter
          onClick={() => handleDayBeforeYesterday(!dayBeforeYesterday)}
          actived={dayBeforeYesterday}
        >
          Anteontem
        </ButtonFilter>
      </Grid>
    </Grid>
  );
}

export default Filters;
