import {
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import {
  Button,
  Card,
  DialogActions,
  ItemButtons,
  Item,
  LabelTotal,
  Total,
  HelperTextButton,
  HelperText,
} from 'pages/UtmDefinition/styles';
import palette from 'theme/palette';
import { ReactComponent as CloseIcon } from 'assets/icons/small/vector.svg';
import { ContentDrawer, HeaderDrawer } from './styles';
import { useEditCampaign } from './hooks/useEditCampaign';
import CampaignList from './components/CampaignList';
import SkeletionList from '../SkeletonList';

interface EditCampaignProps {
  sourceId: string;
  setShowDrawerToEditCampaign(showEditCampaign: boolean): void;
}

function EditCampaign({
  sourceId,
  setShowDrawerToEditCampaign,
}: EditCampaignProps) {
  const {
    showEditCampaign,
    campaignIdSelected,
    campaignName,
    campaingsList,
    showDialog,
    showAddCampaign,
    isFormValid,
    total,
    setCampaignName,
    handlePercentChange,
    handleEditCampaignName,
    setShowEditCampaign,
    setCampaignIdSelected,
    handleOpenDialog,
    handleCreateCampaign,
    setShowAddCampaign,
    setShowDialog,
    handleDeleteCampaign,
    handleReset,
  } = useEditCampaign({
    sourceId,
  });

  return (
    <ContentDrawer>
      <HeaderDrawer>
        <Typography>Campanha</Typography>
        <IconButton onClick={() => setShowDrawerToEditCampaign(false)}>
          <CloseIcon stroke="gray" />
        </IconButton>
      </HeaderDrawer>
      <Card>
        {(campaingsList && campaingsList.length && (
          <CampaignList
            campaigns={campaingsList}
            campaignName={campaignName}
            showEditCampaign={showEditCampaign}
            setShowEditCampaign={setShowEditCampaign}
            setCampaignIdSelected={setCampaignIdSelected}
            setCampaignName={setCampaignName}
            campaignIdSelected={campaignIdSelected}
            handlePercentChange={handlePercentChange}
            handleEditCampaignName={handleEditCampaignName}
            handleOpenDialog={handleOpenDialog}
          />
        )) || <SkeletionList />}

        {showAddCampaign && (
          <Item>
            <TextField
              fullWidth
              label="Nome"
              size="small"
              onChange={e => setCampaignName(e.target.value)}
            />
            <Button
              disabled={!campaignName}
              variant="contained"
              onClick={() => handleCreateCampaign()}
            >
              Adicionar
            </Button>
            <IconButton onClick={() => setShowAddCampaign(!showAddCampaign)}>
              <CloseIcon stroke="red" />
            </IconButton>
          </Item>
        )}

        <Divider />

        <ItemButtons>
          <Total>
            <LabelTotal>Total</LabelTotal>
            <Typography
              color={
                !isFormValid ? palette.error.main : palette.status.new.color
              }
            >{`${total}%`}</Typography>
          </Total>
          <Button
            disabled={showAddCampaign}
            onClick={() => setShowAddCampaign(!showAddCampaign)}
            variant="outlined"
          >
            Nova campanha
          </Button>
        </ItemButtons>
      </Card>

      <ItemButtons>
        <Button variant="outlined" color="error" onClick={() => handleReset()}>
          Redefinir
        </Button>

        <HelperTextButton>
          {!isFormValid && <HelperText>Obrigatório atingir 100% </HelperText>}
        </HelperTextButton>
      </ItemButtons>

      <Dialog
        open={showDialog}
        keepMounted
        onClose={() => setShowDialog(false)}
      >
        <DialogTitle>Você deseja mesmo remover esta campanha?</DialogTitle>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setShowDialog(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setShowDialog(false);
              handleDeleteCampaign();
            }}
          >
            Remover
          </Button>
        </DialogActions>
      </Dialog>
    </ContentDrawer>
  );
}

export default EditCampaign;
