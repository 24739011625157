import { useCallback, useEffect, useState } from 'react';
import { SalesFetch } from 'fetches/salesFetch';
import { useSaleSelected } from 'store/saleSelected';
import { format } from 'date-fns';
import { subDays } from 'date-fns/esm';
import { Sale as TSale } from 'models/sale';
import { useFilterFetchStore } from 'store/filterFetchStore';
import { Sale as ISale } from '../InfoSale/types';

export function useSale() {
  const saleSelected = useSaleSelected(state => state.saleSelected);
  const setSalesSelected = useSaleSelected(state => state.setSalesSelected);

  const [showInfoSale, setShowInfoSale] = useState(false);
  const [sale, setSale] = useState<ISale>();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchID, setSearchID] = useState('');

  const ordem = useFilterFetchStore(state => state.ordemFetch);
  const today = useFilterFetchStore(state => state.todayFetch);
  const yesterday = useFilterFetchStore(state => state.yesterdayFetch);
  const lastWeek = useFilterFetchStore(state => state.lastWeekFetch);
  const month = useFilterFetchStore(state => state.monthFetch);
  const date = useFilterFetchStore(state => state.dateFetch);
  const segmento = useFilterFetchStore(state => state.segmentoFetch);
  const tipo = useFilterFetchStore(state => state.tipoFetch);
  const status = useFilterFetchStore(state => state.statusFetch);
  const uf = useFilterFetchStore(state => state.ufFetch);
  const withReprocessed = useFilterFetchStore(
    state => state.withReprocessedFetch,
  );
  const withManuallyProcessed = useFilterFetchStore(
    state => state.withManuallyProcessedFetch,
  );
  const withAffiliates = useFilterFetchStore(
    state => state.withAffiliatesFetch,
  );
  const withTreatables = useFilterFetchStore(
    state => state.withTreatablesFetch,
  );
  const withPortabilityTreatment = useFilterFetchStore(
    state => state.withPortabilityTreatmentFetch,
  );
  const withExpressShipping = useFilterFetchStore(
    state => state.withExpressShippingFetch,
  );
  const withOrderError = useFilterFetchStore(
    state => state.withOrderErrorFetch,
  );
  const withEngagement = useFilterFetchStore(
    state => state.withEngagementFetch,
  );
  const onlyNew = useFilterFetchStore(state => state.onlyNewFetch);
  const requestedDocumentation = useFilterFetchStore(
    state => state.requestedDocumentationFetch,
  );
  const statusCrossCore = useFilterFetchStore(
    state => state.statusCrossCoreFetch,
  );
  const startDate = useFilterFetchStore(state => state.dateStartFilterFetch);
  const setStartDate = useFilterFetchStore(
    state => state.setDateEndFilterFetch,
  );
  const endDate = useFilterFetchStore(state => state.dateEndFilterFetch);
  const setEndDate = useFilterFetchStore(state => state.setDateEndFilterFetch);
  const msisdn = useFilterFetchStore(state => state.msisdnFetch);
  const provisorio = useFilterFetchStore(state => state.provisorioFetch);
  const iccid = useFilterFetchStore(state => state.iccidFetch);
  const rastreamento = useFilterFetchStore(state => state.rastreamentoFetch);
  const protocolo = useFilterFetchStore(state => state.protocoloFetch);
  const cpf = useFilterFetchStore(state => state.cpfFetch);
  const nome = useFilterFetchStore(state => state.nomeFetch);
  const filiacao = useFilterFetchStore(state => state.filiacaoFetch);
  const email = useFilterFetchStore(state => state.emailFetch);
  const rg = useFilterFetchStore(state => state.rgFetch);
  const token = useFilterFetchStore(state => state.tokenFetch);
  const utm_medium = useFilterFetchStore(state => state.utmMediumFetch);
  const utm_campaign = useFilterFetchStore(state => state.utmCampaignFetch);
  const mensagem_erro = useFilterFetchStore(state => state.mensagemErroFetch);

  const { data: response, mutate } = SalesFetch({
    page: currentPage,
    start: startDate,
    end: endDate,
    status,
    tipo,
    segmento,
    uf,
    reprocessada: withReprocessed,
    reprocessada_manual: withManuallyProcessed,
    lead_afiliado: withAffiliates ? '1' : '',
    status_crosscore: statusCrossCore,
    tratavel: withTreatables ? '1' : '',
    tratamento_portabilidade: withPortabilityTreatment ? '1' : '',
    documentacao_solicitada: requestedDocumentation,
    transporte_expresso: withExpressShipping,
    erro_ordem: withOrderError,
    engajamento: withEngagement,
    apenas_novas: onlyNew,
    searchID,
    order:
      ordem === 'ordenar_data_criacao'
        ? 'vendas.created_at'
        : 'vendas.data_ativacao',
    orderType: 'DESC',
    ordenar_data_criacao: ordem === 'ordenar_data_criacao' ? '1' : '',
    ordenar_data_ativacao: ordem === 'ordenar_data_ativacao' ? '1' : '',
    msisdn,
    provisorio,
    iccid,
    rastreamento,
    protocolo,
    cpf,
    nome,
    filiacao,
    email,
    rg,
    token,
    utm_medium,
    utm_campaign,
    mensagem_erro,
  });

  useEffect(() => {
    const currenteDate = new Date();
    if (today) {
      const start = format(currenteDate, 'yyyy-MM-dd');
      const end = format(currenteDate, 'yyyy-MM-dd');

      setStartDate(start);
      setEndDate(end);
      setCurrentPage(1);
    } else if (yesterday) {
      const start = format(subDays(currenteDate, 1), 'yyyy-MM-dd');
      const end = format(subDays(currenteDate, 1), 'yyyy-MM-dd');

      setStartDate(start);
      setEndDate(end);
      setCurrentPage(1);
    } else if (lastWeek) {
      const start = format(subDays(currenteDate, 7), 'yyyy-MM-dd');
      const end = format(currenteDate, 'yyyy-MM-dd');

      setStartDate(start);
      setEndDate(end);
      setCurrentPage(1);
    } else if (month) {
      const start = format(subDays(currenteDate, 30), 'yyyy-MM-dd');
      const end = format(currenteDate, 'yyyy-MM-dd');

      setStartDate(start);
      setEndDate(end);
      setCurrentPage(1);
    } else if (date && date.startDate && date.endDate) {
      const start = format(date.startDate, 'yyyy-MM-dd');
      const end = format(date.endDate, 'yyyy-MM-dd');

      setStartDate(start);
      setEndDate(end);
      setCurrentPage(1);
    } else {
      setStartDate('');
      setEndDate('');
    }
  }, [today, yesterday, lastWeek, date, month, setStartDate, setEndDate]);

  useEffect(() => {
    setCurrentPage(1);
  }, []);

  const closeDrawer = useCallback(() => {
    setSalesSelected(0);
    setShowInfoSale(false);
  }, [setSalesSelected]);

  const updateSelectedSale = (newSale: ISale) => {
    if (!response) {
      return;
    }

    const newListSale = response?.sales.map((item: TSale) => {
      if (item.id === newSale.id) {
        return {
          ...item,
          status: newSale.status ? newSale.status : item.status,
          nome: newSale.customer.nome ? newSale.customer.nome : item.nome,
        };
      }
      return item;
    });
    const newResponse = { ...response, ...{ sales: newListSale } };

    mutate(newResponse, false);
  };

  return {
    response,
    saleSelected,
    showInfoSale,
    sale,
    currentPage,
    ordem,
    setSale,
    setShowInfoSale,
    setStartDate,
    setEndDate,
    setSearchID,
    closeDrawer,
    updateSelectedSale,
    setCurrentPage,
  };
}
