import TotalizerCard from 'components/TotalizerCard';
import { QueueSizeFetch } from '../../../../fetches/queueSizeFetch';

interface QueueSizeProps {
  isDesktop: boolean;
  connection: string;
  queue: string;
}

function QueueSize({ isDesktop, connection, queue }: QueueSizeProps) {
  const { data: size } = QueueSizeFetch(connection, queue);

  return (
    <TotalizerCard
      key="processos-fila"
      title="Processos na Fila"
      total={size?.total || 0}
      isDesktop={isDesktop}
    />
  );
}

export default QueueSize;
