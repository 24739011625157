import React, { useCallback, useState } from 'react';
import {
  MobileData,
  MobileDataLabel,
  MobileDataText,
} from 'components/StyledComponents';
import { cpf as cpfFormatter } from 'util/formatter';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Container,
  Row,
} from 'pages/OfferDefinition/OfferDefinitionList/MobileItem/styles';
import { IconButton, Skeleton } from '@mui/material';
import { VirtualSeller } from 'models/virtualSeller';
import ufs from '../../../../constants/ufs.json';
import { DataColumn } from './styles';
import PopperActions from '../PopperActions';
import EditForm from '../Item/EditForm';

interface MobileItemProps {
  loading?: boolean;
  data?: VirtualSeller;
  updateVirtualSeller?(
    virtualSeller: VirtualSeller,
    shouldRevalid?: boolean,
  ): void;
}

function MobileItem({
  loading = false,
  data,
  updateVirtualSeller,
}: MobileItemProps) {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElement(anchorElement ? null : event.currentTarget);
    },
    [anchorElement],
  );

  const open = Boolean(anchorElement);
  const id = open ? `popperAction_${data?.id}` : undefined;

  return (
    <Container>
      <DataColumn>
        <Row>
          <MobileData>
            <MobileDataLabel>CPF</MobileDataLabel>
            <MobileDataText>
              {loading ? <Skeleton width={100} /> : cpfFormatter(data?.cpf)}
            </MobileDataText>
          </MobileData>
          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        </Row>
        <Row>
          <MobileData>
            <MobileDataLabel>UF</MobileDataLabel>
            <MobileDataText>
              {loading ? (
                <Skeleton width={100} />
              ) : (
                ufs.find(ufItem => ufItem.value === data?.uf)?.text
              )}
            </MobileDataText>
          </MobileData>
        </Row>
        <Row>
          <MobileData>
            <MobileDataLabel>Cust Code</MobileDataLabel>
            <MobileDataText>
              {loading ? <Skeleton width={100} /> : data?.custcode}
            </MobileDataText>
          </MobileData>
        </Row>
        <PopperActions
          id={id || ''}
          open={open}
          anchorElement={anchorElement}
          handleClick={handleClick}
          setShowEditFormMobile={setShowEditForm}
        />
        {data && (
          <EditForm
            showEditForm={showEditForm}
            setShowEditForm={setShowEditForm}
            virtualSeller={data}
            updateVirtualSeller={updateVirtualSeller}
          />
        )}
      </DataColumn>
    </Container>
  );
}

export default MobileItem;
