import styled from 'styled-components';

export const Data = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  margin-bottom: 15px;
  width: 100%;
`;

export const DataInfo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin: 0 10px;
`;

export const DataText = styled.span`
  min-width: 250px;
  font-size: 15px;
  display: flex;
  align-items: start;
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0px;
`;
