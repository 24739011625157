import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';
import { useToast } from 'hooks/toast';
import { ButtonProgress } from 'components/StyledComponents';
import React, { useEffect, useState } from 'react';
import api from 'services/api';

import useBreakpoint from 'hooks/useBreakpoint';
import { CancellationReasonsFetch } from 'fetches/cancellationReasonsFetch';
import { Container } from './styles';
import { Sale } from '../types';

interface CancelFormProps {
  sale: Sale;
  setSale(sale: Sale): void;
}

function CancelForm({ sale, setSale }: CancelFormProps) {
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState('');
  const [anotherReason, setAnotherReason] = useState('');
  const [isAbleToCancel, setIsAbleToCancel] = useState(false);

  const { addToast } = useToast();

  const { data: reasons } = CancellationReasonsFetch();

  useEffect(() => {
    const allValuesValid =
      reason !== '' &&
      reason !== 'none' &&
      (reason !== 'outro' || (reason === 'outro' && anotherReason !== ''));
    setIsAbleToCancel(allValuesValid);
  }, [anotherReason, reason]);

  const cancelSale = () => {
    setLoading(true);

    const url = '/core/vendas/atualizar-detalhe';
    const finalReason = reason === 'outro' ? anotherReason : reason;

    return api
      .put(`${url}/${sale?.id}`, {
        status: 'CANCELADO',
        motivo_cancelamento: finalReason,
      })
      .then(() => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Portabilidade não autorizada!',
          description: 'Análise de portabilidade concluída com sucesso!',
        });
        setOpen(false);
      })
      .catch(error => {
        setLoading(false);
        let message = error?.data?.errors[0]?.message;
        if (!message) {
          message = error.data.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro no cancelamento',
            description: message,
          });
      })
      .finally(() => {
        api
          .get(`core/vendas/${sale.id}`)
          .then(({ data }) => setSale({ ...sale, ...data }));
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container isDesktop={!isTabletSizeOrSmaller}>
      <Button
        type="button"
        variant="contained"
        disabled={loading}
        fullWidth
        size="large"
        style={{ backgroundColor: 'white', color: '#C92A2A' }}
        onClick={() => setOpen(true)}
      >
        Cancelar Venda / Pedido
        {loading && <ButtonProgress size={24} />}
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullScreen={isTabletSizeOrSmaller}
      >
        <Box padding="30px">
          <DialogTitle id="form-dialog-title">
            Deseja realmente Cancelar Venda / Pedido?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Depois disso, você não poderá mais voltar essa operação.
            </DialogContentText>
            <FormControl fullWidth margin="dense" variant="outlined">
              <InputLabel htmlFor="Motivo" focused>
                Motivo
              </InputLabel>
              <Select
                fullWidth
                label="Selecione"
                margin="dense"
                name="motivo"
                variant="outlined"
                native
                placeholder="Selecione uma opção"
                value={reason}
                onChange={e => setReason(e.target.value as string)}
              >
                <option aria-label="none" value="" />
                {reasons?.map(cancellationReason => (
                  <option
                    key={cancellationReason.id}
                    value={cancellationReason.motivo}
                  >
                    {cancellationReason.motivo}
                  </option>
                ))}
                <option value="outro">Outro</option>
              </Select>
            </FormControl>
            {reason === 'outro' && (
              <TextField
                autoFocus
                margin="dense"
                id="another-reason"
                name="anotherReason"
                value={anotherReason}
                onChange={e => {
                  setAnotherReason(e.target.value);
                }}
                label="Outro motivo"
                type="text"
                fullWidth
                variant="outlined"
              />
            )}
            <Box display="flex" justifyContent="space-between" marginTop="40px">
              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                disabled={loading}
                style={{ backgroundColor: 'white', color: '#C92A2A' }}
              >
                Cancelar
              </Button>
              <Button
                onClick={cancelSale}
                color="primary"
                type="button"
                variant="contained"
                disabled={!isAbleToCancel || loading}
              >
                Confirmar
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </Container>
  );
}

export default CancelForm;
