import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  padding: 60px 40px 24px 40px;
  display: flex;
  flex-direction: column;
  max-width: 650px;
  min-width: 500px;

  .subtitleEdit {
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 15px;
    width: 570px;
  }

  @media (max-width: 1024px) {
    min-width: 300px;
    padding: 20px 20px 24px 20px;

    .subtitleEdit {
      width: 100%;
    }
  }
`;

export const ButtonDrawer = styled.div`
  position: absolute;
  top: 10px;
  bottom: 0px;
  right: 24px;
  margin: auto;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;
