import styled from 'styled-components';

export const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 140px 15px 56px;
  color: rgba(0, 0, 0, 0.4);
  span {
    :nth-child(1) {
      width: 175px;
    }
    :nth-child(2) {
      margin-left: 15px;
    }
    :nth-child(3) {
      margin-left: 25px;
    }
    :nth-child(4) {
      width: 150px;
    }
    min-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    align-self: flex-start;
  }
`;
