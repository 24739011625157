import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useToast } from 'hooks/toast';
import { ButtonProgress } from 'components/StyledComponents';
import React, { useState } from 'react';
import api from 'services/api';

import useBreakpoint from 'hooks/useBreakpoint';
import { Container } from './styles';
import { Sale } from '../types';

interface AuthorizeFormProps {
  sale: Sale;
  setSale(sale: Sale): void;
}

function AuthorizeForm({ sale, setSale }: AuthorizeFormProps) {
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { addToast } = useToast();

  const authorizePortability = () => {
    setLoading(true);
    const url = '/core/vendas/atualizar-detalhe';

    return api
      .put(`${url}/${sale?.id}`, {
        status: 'PENDENTE_ENVIO',
      })
      .then(() => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Portabilidade autorizada!',
          description: 'Análise de portabilidade concluída com sucesso!',
        });
      })
      .catch(error => {
        setLoading(false);
        error.data &&
          addToast({
            type: 'error',
            title: 'Erro ao autorizar',
            description: error.data.message,
          });
      })
      .finally(() => {
        api
          .get(`core/vendas/${sale.id}`)
          .then(({ data }) => setSale({ ...sale, ...data }));
      });
  };

  return (
    <Container isDesktop={!isTabletSizeOrSmaller}>
      <Button
        type="button"
        variant="contained"
        color="primary"
        disabled={loading}
        fullWidth
        size="large"
        onClick={() => setOpen(true)}
      >
        Autorizar portabilidade
        {loading && <ButtonProgress size={24} />}
      </Button>

      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullScreen={isTabletSizeOrSmaller}
      >
        <Box padding="30px">
          <DialogTitle id="alert-dialog-slide-title">
            Deseja realmente autorizar esta portabilidade?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Ao clicar em Autorizar, a etiqueta de envio será gerada e o chip
              estará pronto para ser enviado.
            </DialogContentText>
            <Box display="flex" justifyContent="space-between" marginTop="40px">
              <Button
                onClick={() => setOpen(false)}
                type="button"
                variant="contained"
                disabled={loading}
                style={{ backgroundColor: 'white', color: '#C92A2A' }}
              >
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  setOpen(false);
                  authorizePortability();
                }}
                color="primary"
                type="button"
                variant="contained"
                disabled={loading}
              >
                Autorizar
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </Container>
  );
}

export default AuthorizeForm;
