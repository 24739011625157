import { FormEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { RolesFetch } from 'fetches/rolesFetch';
import { useToast } from 'hooks/toast';
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import api from 'services/api';
import { ButtonProgress, Card } from 'components/StyledComponents';
import { useNavigate, useParams } from 'react-router-dom';
import { UserByIdFetch } from 'fetches/userByIdFetch';
import useStyles from './styles';
import { Erros } from './types';

const EmailSchema = Yup.object().shape({
  email: Yup.string().required('Campo obrigatório').email('Email inválido'),
});

const defaultErros = {
  email: '',
};

function FormEditUser() {
  const params = useParams();
  const { id } = params;
  const { data: user, mutate } = UserByIdFetch(parseInt(id || '0', 10));

  const classes = useStyles();
  const navigate = useNavigate();

  const { addToast } = useToast();

  const [allFilled, setAllFilled] = useState(false);
  const [name, setName] = useState(user?.nome);
  const [email, setEmail] = useState(user?.email);
  const [funcao, setFuncao] = useState(user?.funcao_id.toString());
  const [loading, setLoading] = useState(false);
  const [erros, setErros] = useState<Erros>(defaultErros);

  const { data: funcoes } = RolesFetch();

  useEffect(() => {
    setName(user?.nome);
    setEmail(user?.email);
    setFuncao(user?.funcao_id.toString());
  }, [user]);

  useEffect(() => {
    setAllFilled(name !== '' && email !== '' && funcao !== '');
  }, [name, email, funcao]);

  const handleEditUser = (e: FormEvent): void => {
    e.preventDefault();
    setErros(defaultErros);
    EmailSchema.validate(
      { email },
      {
        abortEarly: false,
      },
    )
      .then(async ({ data: response }) => {
        setLoading(true);
        await api.put(`/core/usuarios/${id}`, {
          user_form: { nome: name, email, funcao_id: funcao },
        });
        mutate(response, true);
        addToast({
          type: 'success',
          title: 'Gestão de Usuário',
          description: 'Dados Atualizados com Sucesso.',
        });
        navigate('/gestao-usuarios/');
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro ao Atualizar o Usuário.',
          description:
            err.data && err.data.message ? err.data.message : err.message,
        });
        setLoading(false);
      });
  };

  return (
    <Card>
      <CardHeader
        title="Gestão Usuário"
        subheader="Painel de edição de usuário no sistema"
      />
      <CardContent>
        <form onSubmit={handleEditUser}>
          <Box display="flex" justifyContent="space-between">
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} xs={12}>
                <TextField
                  id={name}
                  value={name}
                  aria-readonly
                  fullWidth
                  label="Nome"
                  margin="dense"
                  name="name"
                  required
                  variant="outlined"
                  className={classes.input}
                  onChange={e => setName(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <TextField
                  id={email}
                  value={email}
                  inputProps={{
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  aria-readonly
                  fullWidth
                  label="Email"
                  margin="dense"
                  name="email"
                  required
                  variant="outlined"
                  className={classes.input}
                  error={!email && !!erros.email}
                  helperText={!email && erros.email}
                  onFocus={() => setErros(defaultErros)}
                  onChange={e => setEmail(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <FormControl variant="outlined" margin="dense" fullWidth>
                  <InputLabel>Função</InputLabel>
                  <Select
                    value={funcao}
                    required
                    onChange={e => {
                      setFuncao(e.target.value as string);
                    }}
                    label="Função"
                  >
                    {funcoes?.map(item => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nome}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" justifyContent="space-between" marginTop={2}>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              onClick={() => {
                navigate('/gestao-usuarios/');
              }}
            >
              Voltar
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!allFilled || loading}
            >
              Salvar
              {loading && <ButtonProgress size={24} />}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
}

export default FormEditUser;
