import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import api from 'services/api';

interface SignInRequest {
  email: string;
  password: string;
  token?: string;
}

interface Permission {
  id: number;
  permissao: string;
}
interface Role {
  id: number;
  slug: string;
  nome: string;
  permissions: Permission[];
}
interface User {
  id: string;
  nome: string;
  email: string;
  avatar: string;
  role: Role;
}

interface Auth {
  token: string;
  user: User;
}

interface IAuthProvider {
  children: ReactNode;
}

interface AuthContextData {
  user: User;
  signIn(data: SignInRequest): Promise<number>;
  signOut(): void;
  hasPermission(permission?: string): boolean;
}
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export function AuthProvider({ children }: IAuthProvider) {
  const [auth, setAuth] = useState<Auth>(() => {
    const token = localStorage.getItem('@Movel:token');
    const user = localStorage.getItem('@Movel:user');
    if (token && user) {
      return { token, user: JSON.parse(user) };
    }
    return {} as Auth;
  });

  const hasPermission = useCallback(
    (permission: string): boolean => {
      if (!permission) {
        return false;
      }

      const permissionExist = auth.user?.role.permissions.find(
        p => p.permissao === permission,
      );
      return !!permissionExist;
    },
    [auth.user?.role.permissions],
  );

  const signIn = useCallback(
    ({ email, password, token = '' }: SignInRequest): Promise<number> => {
      return api
        .post('/core/auth/login', { email, password, token })
        .then(({ data, status }) => {
          if (status === 200) {
            const { user, access_token } = data;
            setAuth({ user, token: access_token });
            localStorage.setItem('@Movel:token', access_token);
            localStorage.setItem('@Movel:user', JSON.stringify(user));
          }
          return status;
        });
    },
    [],
  );
  const signOut = useCallback(() => {
    localStorage.removeItem('@Movel:token');
    localStorage.removeItem('@Movel:user');
    setAuth({} as Auth);
  }, []);

  const value = useMemo(
    () => ({
      user: auth.user,
      signIn,
      signOut,
      hasPermission,
    }),
    [auth.user, signIn, signOut, hasPermission],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
