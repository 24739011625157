import { CardHeader, Grid, SwipeableDrawer } from '@mui/material';
import useBreakpoint from 'hooks/useBreakpoint';
import { AffiliateGoal } from 'models/affiliateGoal';
import { capitalize } from 'util/formatter';
import { AffiliateGoalDefaultParameter } from 'models/affiliateGoalDefaultParameter';
import EditGoal from '../EditGoal';
import Item from './Item';
import {
  ActionButtons,
  BoxButtons,
  GridContainer,
  GridItem,
  List,
  WeekDay,
} from './styles';
import { weekDays } from './weekDays';
import DefaultParameters from './DefaultParameters';
import SelectAll from './SelectAll';
import CopyAndPasteInto from './CopyAndPasteInto';
import { UseAffiliateGoal } from '../hooks/useAffiliateGoal';

interface GoalListProps {
  affiliates?: AffiliateGoal[];
  defaultParameters?: AffiliateGoalDefaultParameter;
  monthSelected: number;
  yearSelected: number;
  appendGoal(): void;
}

function GoalList({
  affiliates,
  defaultParameters,
  monthSelected,
  yearSelected,
  appendGoal,
}: GoalListProps) {
  const {
    goalSelected,
    setGoalSelected,
    showEditGoal,
    setShowEditGoal,
    monthInPt,
    currentDate,
    daysOfMonthByGoal,
  } = UseAffiliateGoal({
    yearSelected,
    monthSelected,
    affiliates,
    defaultParameters,
  });

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(700);

  return (
    <>
      <CardHeader title={`${capitalize(monthInPt)} de ${yearSelected}`} />
      <Grid container>
        {weekDays.map(item => (
          <Grid key={item.day} item xs={1.714} sx={{ textAlign: 'center' }}>
            <WeekDay>
              {isTableOrLess ? item.day.substring(0, 1) : item.day}
            </WeekDay>
          </Grid>
        ))}
      </Grid>
      <List>
        <GridContainer>
          {daysOfMonthByGoal.map(affiliate => (
            <GridItem key={affiliate.dayOfMonth} colunm={affiliate.column}>
              <Item
                affiliate={affiliate}
                isMobile={isTableOrLess}
                setGoalSelected={setGoalSelected}
                setShowEditGoal={setShowEditGoal}
              />
            </GridItem>
          ))}
        </GridContainer>
      </List>
      <ActionButtons>
        <CopyAndPasteInto
          daysOfMonthByGoal={daysOfMonthByGoal}
          appendGoals={appendGoal}
        />

        <BoxButtons>
          <SelectAll
            daysOfMonthByGoal={daysOfMonthByGoal}
            currentDate={currentDate}
            monthSelected={monthSelected}
            yearSelected={yearSelected}
          />
          <DefaultParameters
            currentDate={currentDate}
            appendGoals={appendGoal}
          />
        </BoxButtons>
      </ActionButtons>
      {goalSelected && goalSelected.date >= currentDate && (
        <SwipeableDrawer
          anchor="right"
          onClose={() => {
            setGoalSelected(undefined);
            setShowEditGoal(false);
          }}
          onOpen={() => setShowEditGoal(true)}
          open={showEditGoal}
        >
          <EditGoal
            date={goalSelected.date}
            month={monthInPt}
            day={goalSelected.dayOfMonth}
            dayOfWeek={goalSelected.dayOfWeek}
            closeDrawer={() => {
              setGoalSelected(undefined);
              setShowEditGoal(false);
              appendGoal();
            }}
          />
        </SwipeableDrawer>
      )}
    </>
  );
}

export default GoalList;
