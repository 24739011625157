import styled from 'styled-components';

export const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px 15px 36px;
  color: rgba(0, 0, 0, 0.4);

  span {
    :nth-child(2) {
      width: 298px;
    }
    :nth-child(7) {
      width: 140px;
    }
  }

  span {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    align-self: flex-start;
  }

  @media (max-width: 1560px) {
    & span:nth-child(2) {
      width: 215px;
    }
  }
`;
