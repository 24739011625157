import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { ButtonProgress } from 'components/StyledComponents';
import { cleanHtmlString } from 'util/cleanHtmlString';
import CardBenefit from './CardBenefit';
import {
  BoxSimulatorBenefit,
  CardSimulatorBenefit,
  HelperText,
  LoadingInfo,
  useStyles,
} from './styles';

interface FormBenefitProps {
  title: string;

  setTitle(title: string): void;

  urlImage: string;

  setUrlImage(url: string): void;

  text: string;

  setText(text: string): void;

  loading: boolean;

  isValid: boolean;

  handleBenefitRequest(): void;
}

function FormBenefit({
  title,
  setTitle,
  urlImage,
  setUrlImage,
  text,
  setText,
  loading,
  isValid,
  handleBenefitRequest,
}: FormBenefitProps) {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Título"
          value={title}
          onChange={e => {
            setTitle(e.target.value);
          }}
          margin="dense"
          name="o"
          size="small"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Digite o título"
          InputProps={{
            classes: { input: classes.input },
          }}
        />
        <HelperText>Máximo de 22 caracteres.</HelperText>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Imagem"
          value={urlImage}
          onChange={e => {
            setUrlImage(e.target.value);
          }}
          margin="dense"
          name="imagem"
          size="small"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="URL"
          InputProps={{
            classes: { input: classes.input },
          }}
        />
        <HelperText>Tamanho da imagem: 270 x 270 pixels.</HelperText>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Texto"
          value={text}
          onChange={e => {
            setText(e.target.value);
          }}
          margin="dense"
          name="texto"
          size="small"
          variant="outlined"
          multiline
          rows={6}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Digite o texto"
          InputProps={{
            classes: { input: classes.input },
          }}
        />
        <HelperText>
          Utilize as tags <strong>{'<strong>'}</strong> no início e{' '}
          <strong>{'</strong>'}</strong> no final para deixar o texto em
          negrito. Máximo de caracteres sugerido: 130.
        </HelperText>
      </Grid>
      <Grid item xs={12}>
        <CardSimulatorBenefit>
          <BoxSimulatorBenefit>
            <CardBenefit title={title} image={urlImage}>
              {text ? (
                <p> {cleanHtmlString(text)}</p>
              ) : (
                <Box className={classes.boxLoadingInfoTextBenefit}>
                  <LoadingInfo width={181} />
                  <LoadingInfo width={206} />
                  <LoadingInfo width={194} />
                  <LoadingInfo width={117} />
                </Box>
              )}
            </CardBenefit>
          </BoxSimulatorBenefit>
          <Typography
            variant="body2"
            gutterBottom
            className={classes.simulationText}
          >
            Simulação Benefício
          </Typography>
        </CardSimulatorBenefit>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleBenefitRequest}
          className={classes.saveButton}
          disabled={!isValid || loading}
        >
          Salvar
          {loading && <ButtonProgress size={24} />}
        </Button>
      </Grid>
    </Grid>
  );
}

export default FormBenefit;
