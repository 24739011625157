import { useCallback, useEffect, useState } from 'react';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import {
  BaseWithConfigurationById,
  EligibleCustomersData,
} from 'models/baseWithConfigurationById';

interface UseBaseViewProps {
  id?: string;
}

export function useBaseView({ id }: UseBaseViewProps) {
  const { addToast } = useToast();

  const [baseWithConfiguration, setBaseWithConfiguration] =
    useState<BaseWithConfigurationById>();
  const [eligibleCustomers, setEligibleCustomers] = useState<
    EligibleCustomersData[]
  >([]);
  const [customerLoading, setCustomerLoading] = useState<{
    [key: number]: boolean;
  }>({});
  const [selectedCustomers, setSelectedCustomers] = useState<number[]>([]);

  const isBasePaused =
    (Number(baseWithConfiguration?.dados_base?.para_processar) ?? 0) >= 1;

  const isBaseRetorned =
    (Number(baseWithConfiguration?.dados_base?.para_despausar) ?? 0) >= 1;

  const canBeStored =
    baseWithConfiguration?.dados_base?.status === 'EM_ANDAMENTO' &&
    baseWithConfiguration?.dados_base?.para_processar === '0' &&
    baseWithConfiguration?.dados_base?.nao_responderam === '0' &&
    baseWithConfiguration?.dados_base?.para_despausar === '0';

  const fetchBaseData = useCallback(async () => {
    const response = await api.get(`/core/bases-clientes-impactar/${id}`);
    setBaseWithConfiguration(response.data);
    setEligibleCustomers(response.data?.dados_clientes_elegiveis || []);
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchBaseData();
    }
  }, [fetchBaseData, id]);

  const removeCustomerFromList = (customerIdToRemove: number) => {
    setEligibleCustomers(prevCustomers =>
      prevCustomers.filter(customer => customer.id !== customerIdToRemove),
    );
  };

  const activeCustomers = async (customerId: number) => {
    setCustomerLoading(prevCustomerLoading => ({
      ...prevCustomerLoading,
      [customerId]: true,
    }));

    try {
      await api.post(`/core/impactos/${customerId}`, { resposta: 'Sim' });
      removeCustomerFromList(customerId);
      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: `Cliente ${customerId} ativado com sucesso!`,
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao ativar cliente.',
        description: `Erro ao ativar cliente ${customerId}`,
      });
    } finally {
      setCustomerLoading(prevCustomerLoading => ({
        ...prevCustomerLoading,
        [customerId]: false,
      }));
    }
    setSelectedCustomers([]);
  };

  return {
    baseWithConfiguration,
    eligibleCustomers,
    customerLoading,
    selectedCustomers,
    activeCustomers,
    isBasePaused,
    isBaseRetorned,
    setSelectedCustomers,
    fetchBaseData,
    canBeStored,
  };
}
