import { Close } from '@mui/icons-material';
import { Button, Divider, Grid, IconButton, TextField } from '@mui/material';
import {
  ButtonProgress,
  CloseButton,
  ContainerDrawer,
} from 'components/StyledComponents';
import { format } from 'date-fns';
import { AffiliateGoalByDateFetch } from 'fetches/affiliateGoalByDateFetch';
import { useToast } from 'hooks/toast';
import { Hour } from 'models/affiliateGoalByDate';
import { ButtonDrawer } from 'pages/CmsLP/components/CMSSlidingDrawer/styles';
import { useCallback, useEffect, useState } from 'react';
import api from 'services/api';
import { capitalize, convertUSToBRDate } from 'util/formatter';
import { WeekDay } from '../GoalList/styles';
import DefaultParameters from './DefaultParameters';
import Hours from './Hours';
import { DayLabel, MonthLabel } from './styles';

interface EditGoalProps {
  date: string;
  month: string;
  day: number;
  dayOfWeek: string;
  closeDrawer(): void;
}

function EditGoal({ date, month, day, dayOfWeek, closeDrawer }: EditGoalProps) {
  const { data: affiliate } = AffiliateGoalByDateFetch(date);

  const [hours, setHours] = useState<Hour>();
  const [totalGoal, setTotalGoal] = useState('');
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    if (!hours) {
      return;
    }

    const hasEmptyHour = Object.values(hours).some(value => value === '');
    setIsValid(!hasEmptyHour && !!totalGoal);
  }, [hours, totalGoal]);

  const currentDateFormated = format(new Date(), 'yyyy-MM-dd');
  const isNotEditHour = currentDateFormated === date;

  useEffect(() => {
    if (affiliate) {
      setHours(affiliate?.horas);
      setTotalGoal(affiliate?.total.toString());
    }
  }, [affiliate]);

  const clearForm = useCallback(() => {
    if (!hours) {
      return;
    }

    const newHours: Hour = {};
    Object.keys(hours).forEach(key => {
      const current = new Date();
      const currentHour = current.getHours();
      const currentDay = current.getDate();
      newHours[key] =
        parseInt(key, 10) < currentHour && currentDay === day ? hours[key] : '';
    });
    setHours(newHours);

    setTotalGoal('');
  }, [day, hours]);

  const handleHourChange = (key: string, value: string) => {
    setHours(prevHours => ({
      ...prevHours,
      [key]: value ? (Number(value) / 100).toString() : value,
    }));
  };

  const handleSave = useCallback(() => {
    setLoading(true);
    api
      .post(`/core/metas-afiliados/${date}`, {
        goal_form: {
          dia: date,
          total: totalGoal,
          horas: hours,
        },
      })
      .then(() => {
        addToast({
          type: 'success',
          title: 'Valores alterados',
          description: `Os valores no dia ${day} foram alterados com sucesso.`,
        });
        setLoading(false);
        closeDrawer();
      })
      .catch(error => {
        let message = error.data.errors[0]?.message;

        if (!message) {
          message = error.data.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao alterar valores!',
            description: message,
          });
        setLoading(false);
      });
  }, [addToast, closeDrawer, date, day, hours, totalGoal]);

  return (
    <ContainerDrawer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MonthLabel>{capitalize(month)}</MonthLabel>
        </Grid>
        <ButtonDrawer>
          <CloseButton onClick={() => closeDrawer()}>
            <IconButton>
              <Close />
            </IconButton>
          </CloseButton>
        </ButtonDrawer>

        <Grid item xs={12}>
          <DayLabel>Dia {day}</DayLabel>
          <WeekDay>
            {dayOfWeek} {convertUSToBRDate(date)}
          </WeekDay>
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="outlined-controlled"
            variant="outlined"
            value={totalGoal}
            fullWidth
            size="small"
            placeholder="total"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setTotalGoal(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        {hours && (
          <Hours
            key={Object.keys(hours)[0]}
            hora={hours}
            isNotEditHour={isNotEditHour}
            onChange={handleHourChange}
          />
        )}

        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button variant="outlined" onClick={() => clearForm()}>
              Limpar campos
            </Button>
            {hours && (
              <DefaultParameters
                isNotEditHour={isNotEditHour}
                hours={hours}
                setHours={setHours}
                setTotalGoal={setTotalGoal}
              />
            )}
          </div>
        </Grid>
      </Grid>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        disabled={!isValid || loading}
      >
        Salvar
        {loading && <ButtonProgress size={24} />}
      </Button>
    </ContainerDrawer>
  );
}

export default EditGoal;
