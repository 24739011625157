import { useCallback, useState } from 'react';
import api from 'services/api';
import { useNavigate } from 'react-router-dom';
import { useToast } from 'hooks/toast';
import FormBlacklistData from '../FormBlacklistData';

function NewBlacklistData() {
  const [type, setType] = useState('');
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();
  const navigate = useNavigate();

  const handleSave = useCallback(() => {
    const dataRequest = {
      tipo: type,
      dados: data,
    };

    setLoading(true);

    api
      .post(`/core/dados-blacklist`, dataRequest)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Dados salvos',
          description: 'Os dados de blacklist foram salvos com sucesso.',
        });
        navigate('/dados-blacklist/');
      })
      .catch(error => {
        let { message } = error.data;

        if (!message) {
          message = error.data.errors[0]?.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao salvar dados!',
            description: message,
          });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, data, navigate, type]);
  return (
    <FormBlacklistData
      type={type}
      setType={setType}
      data={data}
      setData={setData}
      loading={loading}
      handleRequest={handleSave}
    />
  );
}

export default NewBlacklistData;
