import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 650px;
`;

export const TextName = styled.span`
  width: 250px;
  font-size: 15px;
  word-break: break-word;
`;

export const Alert = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  max-width: 540px;
  background: #f5fcff;
`;

export const AlertText = styled.span`
  font-size: 12px;
  line-height: 14px;
  padding-left: 16px;
  color: #00408c;
`;
