import {
  UTMConfiguration,
  UTMConfigurationWithUTMSource,
} from 'models/utmConfiguration';
import api from 'services/api';
import useSWR from 'swr';

export const UtmConfigurationFetch = () => {
  const { data, mutate } = useSWR(
    `utm-configuration`,
    async (): Promise<UTMConfiguration[]> => {
      const response = await api.get(`/core/utm-configuracoes/`);
      const utmComfiguration = response.data as UTMConfiguration[];

      return utmComfiguration;
    },
  );

  return { data, mutate };
};

export const UtmConfigurationByIdFetch = (id?: number) => {
  const { data, mutate } = useSWR(
    `utm-configuration_${id}}`,
    async (): Promise<UTMConfigurationWithUTMSource> => {
      if (id) {
        const response = await api.get(`/core/utm-configuracoes/${id}`);
        const utmComfiguration = response.data as UTMConfigurationWithUTMSource;

        return utmComfiguration;
      }
      return {} as UTMConfigurationWithUTMSource;
    },
  );

  return { data, mutate };
};
