import React, { useCallback, useEffect, useState } from 'react';
import { Box, CardHeader, SwipeableDrawer } from '@mui/material';
import useBreakpoint from 'hooks/useBreakpoint';
import { useSaleSelected } from 'store/saleSelected';
import { SalesFetch } from 'fetches/salesFetch';
import api from 'services/api';
import SimplePagination from 'components/SimplePagination';
import { Sale } from 'models/sale';
import Filters from '../components/Filters';
import {
  Content,
  Header,
  SalesPagination,
} from '../components/styledComponents';
import SaleList from '../components/SaleList';
import InfoSale from '../components/InfoSale';
import { Sale as ISale } from '../components/InfoSale/types';
import CancelPortability from '../components/InfoSale/CancelPortability';

function PortabilityDayByDay() {
  const setSalesSelected = useSaleSelected(state => state.setSalesSelected);
  const saleSelected = useSaleSelected(state => state.saleSelected);
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  const [showInfoSale, setShowInfoSale] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sale, setSale] = useState<ISale>();
  const [search, setSearch] = useState('');
  const [uf, setUf] = useState('');

  const { data: response, mutate } = SalesFetch({
    page: currentPage,
    status:
      'PENDENTE_ENVIO,ENVIADO,AGUARDANDO_RETIRADA,DEVOLVIDO,ANALISE_PORTABILIDADE',
    tipo: 'PORTABILIDADE',
    uf,
    search,
    order: 'vendas.data_portabilidade',
    orderType: 'ASC',
    tratamento_portabilidade: '0',
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  useEffect(() => {
    if (saleSelected) {
      setSale(undefined);
      setShowInfoSale(!!saleSelected);
      api.get(`/core/vendas/${saleSelected}`).then(({ data }) => {
        setSale(data);
      });
    }
  }, [saleSelected]);

  const removeSale = useCallback(() => {
    if (!response) {
      return;
    }

    const newListSale = response?.sales.filter(
      (item: Sale) => item.id !== sale?.id,
    );
    const newResponse = { ...response, ...{ sales: newListSale } };

    mutate(newResponse, true);
  }, [mutate, response, sale]);

  const closeDrawer = useCallback(() => {
    setSalesSelected(0);
    setShowInfoSale(false);
  }, [setSalesSelected]);

  const updateSelectedSale = useCallback(() => {
    if (!response) {
      return;
    }

    const newListSale = response?.sales.sort((a: Sale, b: Sale) => {
      if (a.data_portabilidade < b.data_portabilidade) {
        return -1;
      }

      return 0;
    });

    const newResponse = { ...response, ...{ sales: newListSale } };
    mutate(newResponse, true);
  }, [mutate, response]);

  return (
    <>
      <Header isDesktop={!isTabletSizeOrSmaller}>
        <CardHeader
          title="Portabilidade"
          subheader="Linhas pendentes de reagendamento."
        />
        <Filters setSearch={setSearch} uf={uf} setUf={setUf} />
      </Header>
      <Content>
        <SaleList sales={response?.sales} componente="reagendamento" />
        <SalesPagination>
          {response && (
            <SimplePagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isEmpty={response?.paginationInfo.isEmpty}
            />
          )}
        </SalesPagination>
        <SwipeableDrawer
          anchor="right"
          open={showInfoSale}
          onOpen={() => setShowInfoSale(true)}
          onClose={() => {
            closeDrawer();
          }}
        >
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            paddingBottom="5px"
          >
            {sale && (
              <>
                <InfoSale
                  sale={sale}
                  setSale={setSale}
                  closeDrawer={closeDrawer}
                  module="DayByDay"
                  removeSale={removeSale}
                  updateSelectedSale={updateSelectedSale}
                />

                <CancelPortability
                  sale={sale}
                  setSale={setSale}
                  closeDrawer={closeDrawer}
                  updateSelectedSale={removeSale}
                />
              </>
            )}
          </Box>
        </SwipeableDrawer>
      </Content>
    </>
  );
}

export default PortabilityDayByDay;
