import React from 'react';

import {
  Container,
  Data,
  DataInfo,
  Divider,
} from 'components/StyledComponents';
import { date as dateFormatter } from 'util/formatter';
import {
  ClearOutlined,
  DeleteOutlineOutlined,
  DoneOutlined,
} from '@mui/icons-material';
import { IconButton, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useSimcardStock } from 'store/simcardStock';
import { Batch } from 'models/iccidBatch';
import { DataText, DataTextName } from './styles';

export interface ItemProps {
  data?: Batch;
  loading?: boolean;
}

interface Status {
  name: string;
}

interface SimcardBatchStatusConf {
  EM_USO: Status;
  ESTOQUE: Status;
  [key: string]: Status;
}

export const simcardBatchStatus: SimcardBatchStatusConf = {
  EM_USO: {
    name: 'Em uso',
  },
  ESTOQUE: {
    name: 'Estoque',
  },
};

function Item({ data, loading = false }: ItemProps) {
  const setBatchSelected = useSimcardStock(state => state.setBatchSelected);
  const setShowActiveDialog = useSimcardStock(
    state => state.setShowActiveDialog,
  );
  const setShowDeactivateDialog = useSimcardStock(
    state => state.setShowDeactivateDialog,
  );
  const setShowDeleteDialog = useSimcardStock(
    state => state.setShowDeleteDialog,
  );
  const navigate = useNavigate();

  const showHLR = data?.hlr ? data?.hlr : 'Sem HLR';

  return (
    <Container>
      <Data
        onClick={() => {
          data && setBatchSelected(data.lote_id);
          navigate('/estoque-simcard/estoque-lotes-simcards/');
        }}
      >
        <DataInfo>
          <DataText>
            {loading ? <Skeleton width={140} /> : data?.quantidade}
          </DataText>
          <DataTextName>
            {loading ? <Skeleton width={140} /> : data?.primeiro_iccid}
          </DataTextName>
          <DataTextName>
            {loading ? <Skeleton width={140} /> : data?.ultimo_iccid}
          </DataTextName>
          <DataText>{loading ? <Skeleton width={140} /> : showHLR}</DataText>
          <DataText>
            {loading ? (
              <Skeleton width={140} />
            ) : (
              data && simcardBatchStatus[data.status].name
            )}
          </DataText>
          <DataText>
            {loading ? (
              <Skeleton width={140} />
            ) : (
              dateFormatter(data?.data_criacao)
            )}
          </DataText>
          <DataText>
            {data?.status === 'ESTOQUE' && (
              <IconButton
                onClick={event => {
                  event.stopPropagation();
                  data && setBatchSelected(data.lote_id);
                  setShowActiveDialog(true);
                }}
              >
                <DoneOutlined />
              </IconButton>
            )}

            {data?.status === 'EM_USO' && (
              <IconButton
                onClick={event => {
                  event.stopPropagation();
                  data && setBatchSelected(data.lote_id);
                  setShowDeactivateDialog(true);
                }}
              >
                <ClearOutlined />
              </IconButton>
            )}

            {data?.can_batch_be_deleted && (
              <IconButton
                onClick={event => {
                  event.stopPropagation();
                  data && setBatchSelected(data.lote_id);
                  setShowDeleteDialog(true);
                }}
                style={{ paddingLeft: '8px' }}
              >
                <DeleteOutlineOutlined />
              </IconButton>
            )}
          </DataText>
        </DataInfo>
      </Data>
      <Divider />
    </Container>
  );
}

export default Item;
