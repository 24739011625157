import React, { useCallback, useEffect, useState } from 'react';
import useBreakpoint from 'hooks/useBreakpoint';
import { useForm } from 'react-hook-form';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { HlrFetch } from 'fetches/hlrFetch';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { useSimcardStock } from 'store/simcardStock';
import { HRL } from 'models/hrl';
import { ButtonProgress } from './styles';

interface FormData {
  primeiro_iccid: string;
  ultimo_iccid: string;
  hlr?: number;
}

interface CreateDialogProps {
  showCreateDialog: boolean;
  setShowCreateDialog(value: boolean): void;
  createAction(): void;
}

function CreateDialog({
  showCreateDialog,
  setShowCreateDialog,
  createAction,
}: CreateDialogProps) {
  const hlrSelected = useSimcardStock(state => state.hlrSelected);
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const { data: hlrs } = HlrFetch();

  const [fomIsValid, setFomIsValid] = useState(false);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, watch, reset, setValue } =
    useForm<FormData>();
  const [iccidStartValidating, setIccidStartValidating] = useState(false);
  const [iccidEndValidating, setIccidEndValidating] = useState(false);
  const [iccidStartIsValid, setIccidStartIsValid] = useState(true);
  const [iccidEndIsValidad, setIccidEndIsValidad] = useState(true);
  const [batchState, setBatchState] = useState('EM_USO');
  const watchAll = watch();

  const onSubmit = useCallback(
    (data: FormData) => {
      if (String(data.hlr) === '99') {
        // eslint-disable-next-line no-param-reassign
        delete data.hlr;
      }

      if (hlrSelected && hlrSelected !== 'SEM HLR') {
        // eslint-disable-next-line no-param-reassign
        data.hlr = +hlrSelected;
      }

      const payload = { ...data, ...{ batchState } };

      setLoading(true);
      api
        .post('/core/simcards/cadastrar-lote', payload)
        .then(() => {
          createAction();
          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Cadastro realizado com sucesso',
          });
          reset();
          setBatchState('EM_USO');
        })
        .catch(error => {
          addToast({
            type: 'error',
            title: 'Falha',
            description: error.data.message,
          });
        })
        .finally(() => {
          setLoading(false);
          setShowCreateDialog(false);
        });
    },
    [
      hlrSelected,
      batchState,
      createAction,
      addToast,
      reset,
      setShowCreateDialog,
    ],
  );

  useEffect(() => {
    setFomIsValid(
      !!(
        watchAll.primeiro_iccid &&
        watchAll.ultimo_iccid &&
        watchAll.hlr &&
        iccidStartIsValid &&
        iccidEndIsValidad
      ),
    );
  }, [watchAll, iccidStartIsValid, iccidEndIsValidad]);
  useEffect(() => {
    hlrSelected &&
      setValue(
        'hlr',
        hlrSelected === 'SEM HLR' ? 99 : parseInt(hlrSelected, 10),
      );
  }, [hlrSelected, setValue]);

  const handleStartdIccidBlur = useCallback((iccid: string) => {
    setIccidStartValidating(true);
    api
      .get(`/core/simcards/validar/${iccid}`)
      .then(({ data: response }) => {
        setIccidStartIsValid(response.isValid);
      })
      .finally(() => setIccidStartValidating(false));
  }, []);
  const handleEndIccidBlur = useCallback((iccid: string) => {
    setIccidEndValidating(false);
    api
      .get(`/core/simcards/validar/${iccid}`)
      .then(({ data: response }) => {
        setIccidEndIsValidad(response.isValid);
      })
      .finally(() => setIccidEndValidating(false));
  }, []);

  const handleBatchStatusChange = (
    event: React.MouseEvent,
    newBatchStatus: string,
  ) => {
    setBatchState(newBatchStatus);
  };

  const hlrOptions = (hlrsOptions: Array<HRL>) => {
    if (hlrSelected) {
      if (hlrSelected === 'SEM HLR') return <option value={99}>Sem HLR</option>;

      return (
        <option key={hlrSelected} value={hlrSelected}>
          {hlrSelected}
        </option>
      );
    }

    return (
      <>
        <option aria-label="Todos" value="" />
        <option value={99}>Sem HLR</option>
        {hlrsOptions?.map((item: { id: number; hlr: string }) => (
          <option key={item.id} value={item.id}>
            {item.hlr}
          </option>
        ))}
      </>
    );
  };

  return (
    <Dialog
      open={showCreateDialog}
      fullWidth
      maxWidth="sm"
      style={{ height: isTableOrLess ? '80%' : '60%' }}
    >
      <DialogTitle id="create-simcard">Cadastro de simcards</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Preencha as informações para realizar o cadastro das caixas de
          simcards em seu estoque.
        </DialogContentText>

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register('primeiro_iccid', { required: true })}
            disabled={iccidStartValidating}
            fullWidth
            label="ICCID Inicial"
            margin="dense"
            name="primeiro_iccid"
            variant="outlined"
            error={!iccidStartIsValid}
            helperText={!iccidStartIsValid && 'ICCID inválido'}
            onBlur={e => handleStartdIccidBlur(e.target.value)}
            // inputProps={{ maxLength: 20 }}
            InputProps={{
              endAdornment: iccidStartValidating && (
                <InputAdornment position="end">
                  <ButtonProgress size={24} style={{ position: 'relative' }} />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            {...register('ultimo_iccid', { required: true })}
            disabled={iccidEndValidating}
            fullWidth
            label="ICCID Final"
            margin="dense"
            name="ultimo_iccid"
            variant="outlined"
            error={!iccidEndIsValidad}
            helperText={!iccidEndIsValidad && 'ICCID inválido'}
            onBlur={e => handleEndIccidBlur(e.target.value)}
            InputProps={{
              endAdornment: iccidEndValidating && (
                <InputAdornment position="end">
                  <ButtonProgress size={24} style={{ position: 'relative' }} />
                </InputAdornment>
              ),
            }}
          />
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel htmlFor="HLR" focused>
              HLR
            </InputLabel>

            {hlrs && (
              <Select
                {...register('hlr', { required: true })}
                fullWidth
                label="HLR"
                margin="dense"
                name="hlr"
                variant="outlined"
                native
                disabled={!!hlrSelected}
              >
                {hlrOptions(hlrs)}
              </Select>
            )}
          </FormControl>

          <ToggleButtonGroup
            value={batchState}
            exclusive
            aria-label="text alignment"
            onChange={handleBatchStatusChange}
            style={{ width: '100%' }}
          >
            <ToggleButton
              value="ESTOQUE"
              aria-label="right"
              style={
                batchState === 'ESTOQUE'
                  ? {
                      width: '100%',
                      color: 'white',
                      backgroundColor: '#050C27',
                    }
                  : { width: '100%' }
              }
            >
              Estoque
            </ToggleButton>
            <ToggleButton
              value="EM_USO"
              style={
                batchState === 'EM_USO'
                  ? {
                      width: '100%',
                      color: 'white',
                      backgroundColor: '#050C27',
                    }
                  : { width: '100%' }
              }
              aria-label="left aligned"
            >
              Em uso
            </ToggleButton>
          </ToggleButtonGroup>

          <DialogActions
            style={{
              justifyContent: 'space-between',
              paddingRight: '24px',
              paddingLeft: '16px',
            }}
          >
            <Button
              onClick={() => {
                setBatchState('EM_USO');
                setShowCreateDialog(false);
              }}
              style={{ color: '#C92A2A' }}
            >
              Cancelar
            </Button>
            <Button
              disabled={!fomIsValid || loading}
              type="submit"
              variant="contained"
              color="primary"
            >
              {loading && <ButtonProgress size={24} />}
              Salvar
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default CreateDialog;
