import { TestContext } from 'yup';

export const cpf = (cpfValue: string): boolean => {
  let numeros;
  let digitos;
  let soma;
  let i;
  let resultado;
  let digitosIguais;

  digitosIguais = 1;
  const value = cpfValue.replace(/[^0-9]+/g, '');
  if (value.length < 11) {
    return false;
  }
  for (i = 0; i < value.length - 1; i++) {
    if (value.charAt(i) !== value.charAt(i + 1)) {
      digitosIguais = 0;
      break;
    }
  }
  if (!digitosIguais) {
    numeros = value.substring(0, 9);
    digitos = value.substring(9);
    soma = 0;
    for (i = 10; i > 1; i--) {
      soma += Number(numeros.charAt(10 - i)) * i;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado.toString() !== digitos.charAt(0).toString()) {
      return false;
    }
    numeros = value.substring(0, 10);
    soma = 0;
    for (i = 11; i > 1; i--) {
      soma += Number(numeros.charAt(11 - i)) * i;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    return resultado.toString() === digitos.charAt(1).toString();
  }
  return false;
};

/* eslint-disable */
export const DDDs = new Set([
  61, 62, 64, 65, 66, 67, 82, 71, 73, 74, 75, 77, 85, 88, 98, 99, 83, 81, 87,
  86, 89, 84, 79, 68, 96, 92, 97, 91, 93, 94, 69, 95, 63, 27, 28, 31, 32, 33,
  34, 35, 37, 38, 21, 22, 24, 11, 12, 13, 14, 15, 16, 17, 18, 19, 41, 42, 43,
  44, 45, 46, 51, 53, 54, 55, 47, 48, 49,
]);
/* eslint-enable */

export const validateDDD = (DDDString: string) => {
  const string = DDDString.replace(/[^0-9]+/g, '');

  if (string.length < 2) {
    return false;
  }
  const ddd = +string.slice(0, 2);
  return DDDs.has(ddd);
};

export const validateTel = (TelString: string) => {
  if (TelString.length === 0) {
    return true;
  }

  const string = TelString.replace(/[^0-9]+/g, '');
  if (string.length < 11) {
    return false;
  }
  return validateDDD(string);
};

const ZIP_CODE_REGEX = /[0-9]{5}[0-9]{3}/;

const validateZipCodeStructure = (zipCode: string) => {
  return ZIP_CODE_REGEX.test(zipCode);
};

export const validateZipCode = (zipCode: string) => {
  if (zipCode.length === 0) {
    return true;
  }

  const isValidStructure = validateZipCodeStructure(zipCode);

  if (!isValidStructure) {
    return false;
  }

  return isValidStructure;
};

const DATE_LENGTH = '99/99/9999'.length;
const DATE_REGEX = /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/;

export function validateDate(
  this: TestContext,
  string: string,
  [minAge, maxAge]: number[],
) {
  if (string.length === 0) {
    return true;
  }

  if (string.length < DATE_LENGTH) {
    return false;
  }
  if (!DATE_REGEX.test(string)) {
    return false;
  }

  const [day, month, year] = string.split('/');

  const today = new Date();
  const birthday = new Date(+year, +month - 1, +day);

  let age = today.getFullYear() - birthday.getFullYear();
  const monthDiff = today.getMonth() - birthday.getMonth();
  const dayDiff = today.getDate() - birthday.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff <= 0)) {
    age--;
  }
  if (age < minAge) {
    return this.createError({
      message: `Deve ser maior de ${minAge} anos`,
    });
  }
  if (age > maxAge) {
    return this.createError({
      message: `Deve ser menor de ${maxAge} anos`,
    });
  }
  return true;
}
