import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  padding: 40px;
  display: flex;
  flex-direction: column;
  max-width: 650px;
  min-width: 500px;

  @media (max-width: 1024px) {
    min-width: 300px;
  }
`;

export const Button = styled.div`
  position: absolute;
  top: 10px;
  bottom: 0px;
  right: 24px;
  margin: auto;
`;

export const SaveButton = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
`;
