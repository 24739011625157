import styled from 'styled-components';
import { Toolbar } from '@mui/material';

interface TextProps {
  isDesktop: boolean;
}

export const HomeTitle = styled.img`
  width: 120px;
`;

export const TextName = styled.div<TextProps>`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-left: auto;
  margin-right: 10px;
  padding-left: 20px;

  padding-top: ${props => (props.isDesktop ? '0px' : '24px')};
`;

export const ToolBar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
