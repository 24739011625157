import { useConfigurationUtmStore } from 'store/configurationUtmStore';
import SourceItem from '../SourceItem';

interface SourcesListProps {
  handlePercentChange(percent: string, index: number): void;
  setShowDrawerToEditCampaign(showDrawerToEditCampaign: boolean): void;
  setSourceIdSelected(sourceIdSelected: string): void;
  handleOpenDialog(sourceIdSelected: string): void;
}

function SourcesList({
  handlePercentChange,
  setShowDrawerToEditCampaign,
  setSourceIdSelected,
  handleOpenDialog,
}: SourcesListProps) {
  const configurationUtm = useConfigurationUtmStore(state => state.value);

  return (
    <>
      {configurationUtm.sources.map((source, index) => (
        <SourceItem
          key={source.id}
          source={source}
          index={index}
          handlePercentChange={handlePercentChange}
          setShowDrawerToEditCampaign={setShowDrawerToEditCampaign}
          setSourceIdSelected={setSourceIdSelected}
          handleOpenDialog={handleOpenDialog}
        />
      ))}
    </>
  );
}

export default SourcesList;
