import styled from 'styled-components';

export const ModalCard = styled.div`
  border-radius: 6px;
  width: 400px;
  padding: 20px;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.14);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 10px 26px rgba(0, 0, 0, 0.25);
  z-index: 9000;
  background-color: #ffff;

  @media (max-width: 1024px) {
    width: 318px;
  }
`;

export const TitleModal = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
`;

export const TextModal = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #828282;
`;
