import api from 'services/api';
import { useToast } from 'hooks/toast';
import { useState } from 'react';
import CardCTA from 'components/CardCTA';

interface SIMCardRecusedProps {
  saleID: number;
  open: boolean;
  setOpen(value: boolean): void;
  updateSimCards(): void;
}

function SIMCardRecused({
  saleID,
  open,
  setOpen,
  updateSimCards,
}: SIMCardRecusedProps) {
  const [loadingRequest, setLoadingRequest] = useState(false);

  const { addToast } = useToast();

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = () => {
    setLoadingRequest(true);
    const url = '/core/vendas/atualizar-detalhe';

    return api
      .put(`${url}/${saleID}`, {
        status: 'RECUSADO',
      })
      .then(() => {
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Venda recusada com sucesso!',
        });
        updateSimCards();
      })
      .catch(error => {
        let message = error.data.errors[0]?.message;
        if (!message) {
          message = error.data.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao recusar venda.',
            description: message,
          });
      })
      .finally(() => {
        setLoadingRequest(false);
        handleClose();
      });
  };

  return (
    <CardCTA
      title="Deseja realmente recusar esta venda?"
      subtitle="Não será possível desfazer esta ação."
      loading={loadingRequest}
      openModal={open}
      setOpenModal={setOpen}
      handleRequest={handleUpdate}
    />
  );
}

export default SIMCardRecused;
