import { SaleCorrectionByIdFetch } from 'fetches/saleCorrectionByIdFetch';

import { FormEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { convertBRToUSDate, date as dateFormatter } from 'util/formatter';
import { useToast } from 'hooks/toast';
import { useAuth } from 'hooks/auth';
import { defaultErros, Erros, infoSchema } from '../infoShemaYup';

export function useEditSaleCorrection(id: string | undefined) {
  const { data: sale, mutate } = SaleCorrectionByIdFetch(
    parseInt(id || '0', 10),
  );

  const [fidelidade, setFidelidade] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [filiacao, setFiliacao] = useState('');
  const [msisdn, setMsisdn] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [nome, setNome] = useState('');
  const [cep, setCep] = useState('');
  const phone = '';
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [uf, setUf] = useState('');
  const [tipoIdentificador, setTipoIdentificador] = useState('');
  const [numero, setNumero] = useState('');
  const [quadra, setQuadra] = useState('');
  const [lote, setLote] = useState('');
  const [complemento, setComplemento] = useState('');
  const [formaPagamento, setFormaPagamento] = useState('');
  const [tipoFatura, setTipoFatura] = useState('');
  const [vencimento, setVencimento] = useState('');
  const [banco, setBanco] = useState('');
  const [agencia, setAgencia] = useState('');
  const [conta, setConta] = useState('');
  const [cepIsValid, setCepIsValid] = useState(true);
  const [formCustomerIsValid, setFormCustomerIsValid] = useState(false);
  const [formAddressIsValid, setFormAddressIsValid] = useState(false);
  const [formPaymentIsValid, setFormPaymentIsValid] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [erros, setErros] = useState<Erros>(defaultErros);
  const [loading, setLoading] = useState(false);
  const [openDialogEditSale, setOpenDialogEditSale] = useState(false);
  const [openDialogIntractableSale, setOpenDialogIntractableSale] =
    useState(false);

  const navigate = useNavigate();

  const { addToast } = useToast();

  const { user } = useAuth();

  useEffect(() => {
    setFormIsValid(
      !!formCustomerIsValid && !!formAddressIsValid && !!formPaymentIsValid,
    );
  }, [formAddressIsValid, formCustomerIsValid, formPaymentIsValid]);

  useEffect(() => {
    if (!sale) {
      return;
    }

    setFidelidade(sale.fidelidade || '');
    setCpf(sale.cpf || '');
    setEmail(sale.email || '');
    setFiliacao(sale.filiacao || '');
    setMsisdn(sale.msisdn || '');
    setNascimento(
      (sale.nascimento &&
        sale.nascimento !== '0000-00-00' &&
        dateFormatter(sale.nascimento)) ||
        '',
    );
    setNome(sale.nome || '');
    setCep(sale.cep || '');
    setBairro(sale.bairro || '');
    setCidade(sale.cidade || '');
    setLogradouro(sale.logradouro || '');
    setUf(sale.uf || '');

    setTipoIdentificador(sale.tipo_identificador || '');
    setNumero(sale.numero || '');
    setQuadra(sale.quadra || '');
    setLote(sale.lote || '');
    setComplemento(sale.complemento || '');

    setFormaPagamento(sale.forma_pagamento || '');
    setTipoFatura(sale.tipo_fatura || '');
    setVencimento(sale.vencimento || '');
    setBanco(sale.banco || '');
    setAgencia(sale.agencia || '');
    setConta(sale.conta || '');
  }, [sale]);

  const handleUpdate = () => {
    const data = {
      sale_form: {
        correcao_vendas_id: sale?.id,
        bairro,
        cep,
        cidade,
        complemento,
        tipo_identificador: tipoIdentificador,
        quadra,
        lote,
        cpf,
        email,
        filiacao,
        forma_pagamento: formaPagamento,
        logradouro,
        msisdn,
        nascimento: convertBRToUSDate(nascimento),
        nome,
        numero,
        fidelidade,
        tipo_fatura: tipoFatura,
        uf,
        vencimento,
        banco,
        agencia,
        conta,
      },
    };
    setLoading(true);
    api
      .put(`/core/correcao-vendas/${id}`, data)
      .then(({ data: response }) => {
        setLoading(false);

        mutate(response, true);

        addToast({
          type: 'success',
          title: 'Venda corrigida',
          description: 'Venda corrigida com sucesso pelo sistema.',
        });
      })
      .finally(() => {
        navigate('/correcao-vendas/');
      });
  };

  const handleSave = (e: FormEvent): void => {
    e.preventDefault();

    const typeIdentifierIsNumberOrCourAndLot =
      tipoIdentificador === 'NUMERO' || tipoIdentificador === 'SEM_NUMERO';

    const fidelidadeTIMId = {
      fidelidade,
    };

    const loyaltyData = fidelidade ? fidelidadeTIMId : {};

    const sale_form = {
      correcao_vendas_id: sale?.id,
      uuid: sale?.uuid,
      bairro,
      cep,
      cidade,
      complemento,
      tipo_identificador: tipoIdentificador,
      quadra: typeIdentifierIsNumberOrCourAndLot ? '' : quadra,
      lote: typeIdentifierIsNumberOrCourAndLot ? '' : lote,
      cpf,
      email,
      filiacao,
      forma_pagamento: formaPagamento,
      logradouro,
      msisdn,
      autorizar_receber_contato: sale?.autorizar_receber_contato,
      nascimento: convertBRToUSDate(nascimento),
      nome,
      numero:
        tipoIdentificador === 'QUADRA_LOTE' ||
        tipoIdentificador === 'SEM_NUMERO'
          ? ''
          : numero,
      plano: sale?.plano,
      fidelidade,
      telefone: sale?.telefone,
      tipo_fatura: tipoFatura,
      tipo: sale?.tipo,
      uf,
      vencimento,
      utm_campaign: sale?.utm_campaign,
      utm_medium: sale?.utm_medium,
      utm_source: sale?.utm_source,
      banco,
      agencia,
      conta,
      reprocessada_manual: true,
      operador_id: user.id,
      ...loyaltyData,
    };

    const subCpf = cpf.substr(0, 4);
    const subTel = phone.substr(7);

    const joinSubCpfAndTel = `${subCpf}${subTel}`;

    setErros(defaultErros);
    infoSchema
      .validate(
        {
          cpf,
          email,
          msisdn,
          nascimento,
          cep,
          tipoIdentificador,
          numero,
          quadra,
          lote,
          complemento,
        },
        {
          abortEarly: false,
        },
      )
      .then(async () => {
        setLoading(true);
        api
          .post('/core/vendas', { sale_form })
          .then(({ data: responseSale }) => {
            if (responseSale.id !== joinSubCpfAndTel) {
              handleUpdate();
            }
            if (responseSale.id === joinSubCpfAndTel) {
              mutate();
              addToast({
                type: 'info',
                title: 'Novo erro encontrado',
                description:
                  'Erro anterior validado, porém, foi encontrado um novo erro nesta venda.',
              });
            }
          })
          .finally(() => {
            setLoading(false);
            setOpenDialogEditSale(false);
          });
      })
      .catch(err => {
        if (err instanceof Yup.ValidationError) {
          const newErros: Erros = { ...defaultErros };
          err.inner.forEach(error => {
            if (error.path) {
              newErros[error.path] = error.message;
            }
          });
          setErros(newErros);
        }

        setOpenDialogEditSale(false);
      });
  };

  return {
    sale,
    fidelidade,
    setFidelidade,
    cpf,
    setCpf,
    email,
    setEmail,
    filiacao,
    setFiliacao,
    msisdn,
    setMsisdn,
    nascimento,
    setNascimento,
    nome,
    setNome,
    cep,
    setCep,
    bairro,
    setBairro,
    cidade,
    setCidade,
    logradouro,
    setLogradouro,
    uf,
    setUf,
    tipoIdentificador,
    setTipoIdentificador,
    numero,
    setNumero,
    quadra,
    setQuadra,
    lote,
    setLote,
    complemento,
    setComplemento,
    formaPagamento,
    setFormaPagamento,
    tipoFatura,
    setTipoFatura,
    vencimento,
    setVencimento,
    banco,
    setBanco,
    agencia,
    setAgencia,
    conta,
    setConta,
    cepIsValid,
    setCepIsValid,
    setFormCustomerIsValid,
    setFormAddressIsValid,
    setFormPaymentIsValid,
    formIsValid,
    setFormIsValid,
    erros,
    setErros,
    loading,
    setLoading,
    openDialogEditSale,
    setOpenDialogEditSale,
    openDialogIntractableSale,
    setOpenDialogIntractableSale,
    navigate,
    handleUpdate,
    mutate,
    handleSave,
  };
}
