import styled from 'styled-components';

export const Row = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const MobileDataTextName = styled.span`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 15px;
  min-width: 150px;
  font-size: 15px;
`;
