import { useCallback } from 'react';
import useBreakpoint from 'hooks/useBreakpoint';
import { List } from 'components/StyledComponents';
import { Container } from 'components/CardList';
import { Plan } from 'models/plans';
import MobileItem from './MobileItem';
import Item from './Item';

import { Header } from './styles';

interface BodyProps {
  plans: Plan[];
  isMobile: boolean;
  updatePlan?(plans: Plan, shouldRevalid?: boolean): void;
}

interface PlansListProps {
  plans?: Plan[];
  updatePlans(plans: Plan[], showRevalid?: boolean): void;
}

interface LoadingListProps {
  isMobile: boolean;
}

function LoadingList({ isMobile }: LoadingListProps) {
  if (isMobile) {
    return (
      <>
        <MobileItem loading />
        <MobileItem loading />
        <MobileItem loading />
        <MobileItem loading />
      </>
    );
  }
  return (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({ plans, isMobile, updatePlan }: BodyProps) {
  return (
    <List>
      {plans.length > 0 ? (
        plans?.map(data =>
          isMobile ? (
            <MobileItem key={data.id} data={data} updatePlan={updatePlan} />
          ) : (
            <Item key={data.id} data={data} updatePlan={updatePlan} />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhum plano</h3>
      )}
    </List>
  );
}

function PlanList({ plans, updatePlans }: PlansListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const updatePlan = useCallback(
    (plan: Plan) => {
      if (!plans) return;
      const newPlans = plans.map(item => {
        if (item.id === plan.id) {
          return { ...item, ...plan };
        }
        return item;
      });
      updatePlans(newPlans, true);
    },
    [plans, updatePlans],
  );

  return (
    <Container>
      {!isTableOrLess && (
        <Header>
          <span>TIM ID</span>
          <span>Nome</span>
          <span>GB</span>
          <span>Segmento</span>
          <span>Exibir ecommerce</span>
          <span>Travar sincronia</span>
          <span>Status</span>
        </Header>
      )}

      {plans ? (
        <Body plans={plans} isMobile={isTableOrLess} updatePlan={updatePlan} />
      ) : (
        <LoadingList isMobile={isTableOrLess} />
      )}
    </Container>
  );
}

export default PlanList;
