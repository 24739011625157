import create from 'zustand';

type SaleSelectedStore = {
  saleSelected: number;
  setSalesSelected(id: number): void;
};

export const useSaleSelected = create<SaleSelectedStore>(set => ({
  saleSelected: 0,
  setSalesSelected(id: number) {
    set(state => ({ ...state, saleSelected: id }));
  },
}));
