import styled from 'styled-components';

interface ContainerProps {
  color?: string;
  backgroudColor?: string;
}

export const Container = styled.div<ContainerProps>`
  border: 1px solid ${props => props.color || '#666'};
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  height: 56px;
  border-radius: 6px;
  color: ${props => props.color};
  background-color: ${props => props.backgroudColor};
  display: flex;
  align-items: center;
  padding: 30px;
`;
export const Descriptions = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

interface TextProps {
  textColor?: string;
}

export const Title = styled.span<TextProps>`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.textColor};
`;
export const SubTitle = styled.span<TextProps>`
  font-size: 12px;
  line-height: 14px;
  color: ${props => props.textColor};
`;
