import { useState } from 'react';
import { Button } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { green } from '@mui/material/colors';
import api from 'services/api';
import { ButtonProgress } from 'components/StyledComponents';
import { useToast } from 'hooks/toast';
import { DataEligibilitySetupsFetch } from 'fetches/setupsFetch';
import { Ellipse, Row, SubTitle } from '../styles';
import { Plans } from '../types';

interface AttackGroupEligibilityProps {
  successAttackEligibility: boolean;
  setSuccessAttackEligibility(value: boolean): void;
}

function AttackGroupEligibility({
  successAttackEligibility,
  setSuccessAttackEligibility,
}: AttackGroupEligibilityProps) {
  const { data: response } = DataEligibilitySetupsFetch({
    type: 'ataque',
  });

  const [loadingAttackEligibility, setLoadingAttackEligibility] =
    useState(false);

  const { addToast } = useToast();

  const handleAttackEligibility = () => {
    setLoadingAttackEligibility(true);

    const dataResponse = {
      customer: {
        cpf: response?.cpf,
        birthday: response?.nascimento,
        name: response?.nome,
        filiation: response?.filiacao,
        zipCode: response?.cep,
        email: response?.email,
      },
      areaCode: response?.ddd,
      operation: 'POS_PAGO',
    };

    api
      .post(`/core/planos/sincronizar-planos`, dataResponse)
      .then(({ data: planos }) => {
        const totalSegmentos = planos.reduce(
          (segmentos: string[], plano: Plans) => {
            if (!segmentos.find(segmento => segmento === plano.segmento)) {
              segmentos.push(plano.segmento);
            }
            return segmentos;
          },
          [],
        );
        addToast({
          type: 'success',
          title: 'Sucesso na Elegibilidade',
          description: `Foram sincronizados ${planos.length} planos em ${totalSegmentos.length} segmentos`,
        });
        setLoadingAttackEligibility(false);
        setSuccessAttackEligibility(true);
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro na Elegibilidade',
          description: error.data,
        });

        setLoadingAttackEligibility(false);
        setSuccessAttackEligibility(false);
      });
  };
  return (
    <>
      <Row>
        <Ellipse>
          <span>1</span>
        </Ellipse>
        <SubTitle>Elegibilidade do grupo de ataque</SubTitle>
      </Row>

      <Button
        type="button"
        color="secondary"
        fullWidth
        size="large"
        variant="outlined"
        onClick={() => handleAttackEligibility()}
        disabled={loadingAttackEligibility}
      >
        Realizar elegibilidade
        {loadingAttackEligibility && <ButtonProgress size={24} />}
      </Button>
      {successAttackEligibility && (
        <Row>
          <DoneIcon style={{ color: green[500], marginRight: 5 }} />
          <SubTitle>Elegibilidade realizada com sucesso </SubTitle>
        </Row>
      )}
    </>
  );
}

export default AttackGroupEligibility;
