import userSWR from 'swr';
import api from 'services/api';
import { SaleCorrection } from 'models/salesCorrection';

export const SaleCorrectionByIdFetch = (id: number) => {
  const { data, mutate } = userSWR<SaleCorrection>(
    `sale_${id}`,
    async (): Promise<SaleCorrection> => {
      const { data: results } = await api.get(`/core/correcao-vendas/${id}`);

      return results;
    },
  );
  return { data, mutate };
};
