import React, { useState } from 'react';
import { Button, FormControl } from '@mui/material';
import api from 'services/api';
import { SwitchIOS } from 'components/SwitchIOS';
import { useToast } from 'hooks/toast';
import { Setup } from 'models/setup';
import { SaveButton } from './styles';

interface BooleanFormProps {
  setup: Setup;
  closeDrawer(): void;
}

function BooleanForm({ setup, closeDrawer }: BooleanFormProps) {
  const [setupValue, setSetupValue] = useState(setup.value === 'true');

  const { addToast } = useToast();

  const handleSave = () => {
    api
      .put(`api/setups/${setup.key}`, {
        value: setupValue.toString(),
      })
      .then(() => {
        addToast({
          type: 'success',
          title: setup.title,
          description: 'Setup salvo com sucesso',
        });

        closeDrawer();
      })
      .catch(err => {
        let message = err.data.errors[0]?.message;
        if (!message) {
          message = err.data.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro!',
            description: message,
          });
      });
  };

  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      style={{ paddingBottom: 30 }}
    >
      <SwitchIOS
        size="medium"
        color="secondary"
        checked={setupValue}
        onChange={() => setSetupValue(!setupValue)}
      />
      <SaveButton>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          Salvar
        </Button>
      </SaveButton>
    </FormControl>
  );
}

export default BooleanForm;
