import { NoteAddOutlined } from '@mui/icons-material';
import { Box, Button, Grid, TextField } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ButtonProgress } from 'components/StyledComponents';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import {
  UtmConfigurationByIdFetch,
  UtmConfigurationFetch,
} from 'fetches/utmConfigurationFetch';
import { UtmSource } from 'models/utmSource';
import useBreakpoint from 'hooks/useBreakpoint';
import { useNavigate } from 'react-router-dom';
import {
  BoxConfiguration,
  BoxContainerConfiguration,
  useStyles,
} from './styles';
import SourceList from './SourceList';

interface BaseImportProps {
  updateReprocessing(): void;
}

function BaseImport({ updateReprocessing }: BaseImportProps) {
  const fileRef = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File>();
  const [idConfiguration, setIdConfiguration] = useState('');
  const [loading, setLoading] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [configSourcesSelected, setConfigSourcesSelected] =
    useState<UtmSource[]>();

  const { data: utmConfiguration } = UtmConfigurationByIdFetch(
    Number(idConfiguration),
  );
  const { data: utmConfigurations } = UtmConfigurationFetch();

  const classes = useStyles();
  const { addToast } = useToast();
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(900);
  const navigate = useNavigate();

  useEffect(() => {
    setFormIsValid(!!(file && configSourcesSelected));
  }, [configSourcesSelected, file]);

  useEffect(() => {
    if (utmConfiguration) {
      setConfigSourcesSelected(utmConfiguration.sources);
    }
  }, [idConfiguration, utmConfiguration]);

  const handleUpload = (files: FileList | null) => {
    if (files) {
      setFile(files[0]);
    }
  };

  const clearForm = useCallback(() => {
    setIdConfiguration('');
    setFile(undefined);
    setConfigSourcesSelected([]);
  }, []);

  const handleImportBase = useCallback(() => {
    const data = new FormData();

    if (file) {
      data.append('arquivo', file);
    }

    if (idConfiguration) {
      data.append('configuracao_id', idConfiguration);
    }

    setLoading(true);
    api
      .post(`core/bases-clientes-impactar/importar-base/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Base importada com sucesso!',
        });

        updateReprocessing();
        clearForm();
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro na importação',
          description: error?.data?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, clearForm, file, idConfiguration, updateReprocessing]);

  const isSpacing = isTableOrLess ? 2 : 0;

  return (
    <Box padding={2}>
      <Grid container spacing={2} rowSpacing={2}>
        <Grid item md={6} xs={12}>
          <Grid container item spacing={isSpacing}>
            <Grid item md={12} xs={12}>
              <div>
                <input
                  ref={fileRef}
                  accept=".csv"
                  id="contained-button-file"
                  type="file"
                  onChange={e => {
                    handleUpload(e.target.files);
                  }}
                  style={{ display: 'none' }}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="outlined"
                    component="span"
                    className={classes.buttonUpload}
                    fullWidth
                    endIcon={<NoteAddOutlined />}
                  >
                    {file ? file.name : 'Selecionar base'}
                  </Button>
                </label>
              </div>
            </Grid>
            <Grid item md={12} xs={7}>
              <TextField
                label="Configuração"
                variant="outlined"
                select
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{ native: true }}
                size="small"
                fullWidth
                onChange={e => {
                  setIdConfiguration(e.target.value as string);
                }}
                value={idConfiguration}
              >
                <option value="" disabled>
                  Selecione
                </option>
                {utmConfigurations?.map(config => (
                  <option key={config.id} value={config.id}>
                    {config.nome}
                  </option>
                ))}
              </TextField>
            </Grid>
            {isTableOrLess && (
              <Grid item xs={5}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(`/definicao-utm/sources/${idConfiguration}`);
                  }}
                  fullWidth
                  style={{ height: 40 }}
                >
                  Editar sources
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <BoxConfiguration>
            {configSourcesSelected && idConfiguration ? (
              <SourceList configSourcesSelected={configSourcesSelected} />
            ) : (
              <BoxContainerConfiguration>
                Sources da configuração
              </BoxContainerConfiguration>
            )}
          </BoxConfiguration>
        </Grid>

        <Grid item md={12} xs={12}>
          <Grid
            container
            item
            justifyContent="space-between"
            alignItems="flex-end"
          >
            {!isTableOrLess && (
              <Grid item md="auto">
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(`/definicao-utm/sources/${idConfiguration}`);
                  }}
                  disabled={!idConfiguration}
                >
                  Editar sources
                </Button>
              </Grid>
            )}
            <Grid item md="auto" xs={12}>
              <Button
                disabled={!formIsValid || loading}
                onClick={() => {
                  handleImportBase();
                }}
                variant="contained"
                color="primary"
                fullWidth
              >
                {loading && <ButtonProgress size={24} />}
                Importar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BaseImport;
