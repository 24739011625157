import React from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
} from '@mui/material';
import useBreakpoint from 'hooks/useBreakpoint';

interface DialogPlanProps {
  title: string;
  subtitle?: string;
  label?: string | 'Selecione uma opção';
  isOpen: boolean;
  onUpdate?: (value?: string) => void;
  onClose: () => void;
  onChange?: (value: string) => void;
  children: React.ReactNode;
}

function DialogPlan({
  title,
  subtitle,
  label,
  isOpen,
  onUpdate,
  onClose,
  onChange,
  children,
}: DialogPlanProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      style={{ height: isTableOrLess ? '80%' : '60%' }}
    >
      <DialogTitle
        id="form-dialog-title"
        style={{ padding: '16px 24px 0px 24px' }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 30 }}>
          {subtitle}
        </DialogContentText>
        <TextField
          fullWidth
          label={label}
          margin="dense"
          name="state"
          onChange={event => {
            onChange?.(event.target.value);
          }}
          required
          select
          SelectProps={{ native: true }}
          variant="outlined"
          size="small"
        >
          {children}
        </TextField>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'space-between',
          paddingRight: '24px',
          paddingLeft: '16px',
        }}
      >
        <Button onClick={onClose} style={{ color: '#C92A2A' }}>
          Cancelar
        </Button>
        <Button
          onClick={() => onUpdate?.()}
          variant="contained"
          color="primary"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default DialogPlan;
