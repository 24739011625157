import New from 'assets/icons/statusIcons/new.svg';
import Processing from 'assets/icons/statusIcons/processing.svg';
import PendingShipping from 'assets/icons/statusIcons/pendingShipping.svg';
import Sent from 'assets/icons/statusIcons/sent.svg';
import ExpressShipment from 'assets/icons/statusIcons/expressShipment.svg';
import Activated from 'assets/icons/statusIcons/activated.svg';
import Approved from 'assets/icons/statusIcons/approved.svg';
import Divergent from 'assets/icons/statusIcons/divergent.svg';
import Canceled from 'assets/icons/statusIcons/canceled.svg';
import Recused from 'assets/icons/statusIcons/recused.svg';
import Returned from 'assets/icons/statusIcons/returned.svg';
import AwaitingWithdraw from 'assets/icons/statusIcons/awaiting_withdraw.svg';
import Misplaced from 'assets/icons/statusIcons/misplaced.svg';
import TreatableLead from 'assets/icons/statusIcons/treatableLead.svg';
import FraudAnalysis from 'assets/icons/statusIcons/fraud.svg';
import Legitimate from 'assets/icons/statusIcons/legitimate.svg';
import PortabilityWindow from 'assets/icons/statusIcons/portability_window.svg';
import AwaitingPortability from 'assets/icons/statusIcons/awaiting_portability.svg';
import PortabilityAnalysis from 'assets/icons/statusIcons/portabilityAnalysis.svg';

interface IconProps {
  NOVO: string;
  EM_PROCESSAMENTO: string;
  PENDENTE_ENVIO: string;
  ENVIADO: string;
  EM_TRANSPORTE_EXPRESSO: string;
  ATIVADO: string;
  APROVADO: string;
  DIVERGENTE: string;
  CANCELADO: string;
  RECUSADO: string;
  DEVOLVIDO: string;
  AGUARDANDO_RETIRADA: string;
  EXTRAVIADO: string;
  LEAD_TRATAVEL: string;
  ANALISE_FRAUDE: string;
  LEGITIMO: string;
  AGUARDANDO_JANELA: string;
  AGUARDANDO_PORTABILIDADE: string;
  ANALISE_PORTABILIDADE: string;
  [key: string]: string;
}

const Icons: IconProps = {
  NOVO: New,
  EM_PROCESSAMENTO: Processing,
  PENDENTE_ENVIO: PendingShipping,
  ENVIADO: Sent,
  EM_TRANSPORTE_EXPRESSO: ExpressShipment,
  ATIVADO: Activated,
  APROVADO: Approved,
  DIVERGENTE: Divergent,
  CANCELADO: Canceled,
  RECUSADO: Recused,
  DEVOLVIDO: Returned,
  AGUARDANDO_RETIRADA: AwaitingWithdraw,
  EXTRAVIADO: Misplaced,
  LEAD_TRATAVEL: TreatableLead,
  ANALISE_FRAUDE: FraudAnalysis,
  LEGITIMO: Legitimate,
  AGUARDANDO_JANELA: PortabilityWindow,
  AGUARDANDO_PORTABILIDADE: AwaitingPortability,
  ANALISE_PORTABILIDADE: PortabilityAnalysis,
};
export default Icons;
