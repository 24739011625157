import styled from 'styled-components';

export const Card = styled.div`
  padding: 15px;
`;

export const ReasonTable = styled.div`
  display: flex;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #f2f2f2;
  align-items: flex-start;
`;

export const ReasonBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ReasonTableLabel = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 14px;
`;
