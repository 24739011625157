import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { NewAffiliateGoal } from '../../../types';

interface SelectDaysProps {
  daysOfMonthByGoal: NewAffiliateGoal[];
  selectedDays: number[];
  setSelectedDays(selectedDays: number[]): void;
}

function SelectDays({
  daysOfMonthByGoal,
  selectedDays,
  setSelectedDays,
}: SelectDaysProps) {
  const [selectAll, setSelectAll] = useState(false);

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const value = event.target.value as number[];

    setSelectedDays(value);
    setSelectAll(value.length === daysOfMonthByGoal.length);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedDays([]);
      setSelectAll(false);
    } else {
      const allDays = daysOfMonthByGoal.map(day => day.dayOfMonth);
      setSelectedDays(allDays);
      setSelectAll(true);
    }
  };

  useEffect(() => {
    setSelectAll(selectedDays.length === daysOfMonthByGoal.length);
  }, [selectedDays, daysOfMonthByGoal]);

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel htmlFor="Dias" focused shrink>
        Dias
      </InputLabel>
      <Select
        multiple
        value={selectedDays}
        input={<OutlinedInput id="select-dias" label="Dias" notched />}
        onChange={handleChange}
        displayEmpty
        renderValue={selected => {
          if (selectAll) {
            return <span>Todos</span>;
          }
          if (selected.length === 1) {
            return <span>{selected[0]}</span>;
          }
          if (selected.length > 1) {
            return <span>{selected.length} selecionados</span>;
          }

          return <span>Selecione</span>;
        }}
      >
        <MenuItem onClick={handleSelectAll}>
          <Checkbox checked={selectAll} />
          <ListItemText primary="Selecionar tudo" />
        </MenuItem>
        {daysOfMonthByGoal.map(day => (
          <MenuItem key={day.dayOfMonth} value={day.dayOfMonth}>
            <Checkbox checked={selectedDays.indexOf(day.dayOfMonth) > -1} />
            <ListItemText primary={day.dayOfMonth} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectDays;
