import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';
import { ButtonProgress } from 'components/StyledComponents';
import { useSaleSelected } from 'store/saleSelected';
import useBreakpoint from 'hooks/useBreakpoint';
import { TreatmentReasonsFetch } from 'fetches/treatmentReasonsFetch';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { Container } from '../CancelPortability/styles';
import { Sale as ISale } from '../types';

interface TreatmentFormProps {
  sale: ISale;
  setSale(sale: ISale): void;
  removeSale(sale: ISale): void;
  closeDrawer(): void;
  openModal: boolean;
  setOpenModal(value: boolean): void;
}

function TreatmentForm({
  sale,
  setSale,
  closeDrawer,
  removeSale,
  openModal,
  setOpenModal,
}: TreatmentFormProps) {
  const setSalesSelected = useSaleSelected(state => state.setSalesSelected);
  const [loading, setLoading] = useState(false);
  const [motive, setMotive] = useState('');
  const [anotherReason, setAnotherReason] = useState('');
  const [isAbleToCancel, setIsAbleToCancel] = useState(false);

  const { addToast } = useToast();

  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);

  const { data: motives } = TreatmentReasonsFetch();

  useEffect(() => {
    const allValuesValid =
      motive !== '' &&
      motive !== 'none' &&
      (motive !== 'outro' || (motive === 'outro' && anotherReason !== ''));
    setIsAbleToCancel(allValuesValid);
  }, [anotherReason, motive]);

  const handlePortabilityInTreatment = () => {
    setLoading(true);
    const url = '/core/vendas/atualizar-detalhe';
    const finalReason = motive === 'outro' ? anotherReason : motive;

    return api
      .put(`${url}/${sale?.id}`, {
        status: 'TRATAMENTO_PORTABILIDADE',
        tratamento_portabilidade: true,
        motivo_tratamento: finalReason,
      })
      .then(({ data: res }) => {
        setLoading(false);
        removeSale && removeSale({ ...sale, ...res });
        addToast({
          type: 'success',
          title: 'Portabilidade em tratamento!',
          description: 'Portabilidade adicionada em tratamento com sucesso!',
        });
        setOpenModal(false);
        setSalesSelected(0);
        closeDrawer();
      })
      .catch(error => {
        setLoading(false);
        error.data &&
          addToast({
            type: 'error',
            title: 'Erro ao concluir Tratamento',
            description: error.data.message,
          });
      })
      .finally(() => {
        api
          .get(`core/vendas/${sale.id}`)
          .then(({ data }) => setSale({ ...sale, ...data }));
      });
  };
  return (
    <Container>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        style={{ padding: 15, marginTop: 10 }}
        onClick={() => setOpenModal(true)}
      >
        Portabilidade em Tratamento
      </Button>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="form-dialog-title"
        fullScreen={isTabletSizeOrSmaller}
      >
        <Box padding="30px">
          <DialogTitle id="form-dialog-title">
            Deseja colocar a portabilidade em Tratamento?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Essa operação abordará a portabilidade do cliente, ao selecionar o
              motivo da portabilidade, será colocada em tratamento e a venda
              será listada na Seção &ldquo;Portabilidade em Tratamento&ldquo;
              <br />
              <b>
                Por favor, informe o motivo pelo qual você está colocando esta
                venda em tratamento.
              </b>
            </DialogContentText>
            <FormControl fullWidth margin="dense" variant="outlined">
              <InputLabel htmlFor="Motivo" focused>
                Selecione o motivo de tratamento
              </InputLabel>
              <Select
                fullWidth
                label="Selecione o motivo de tratamento"
                margin="dense"
                name="motivo"
                variant="outlined"
                native
                value={motive}
                onChange={e => setMotive(e.target.value as string)}
              >
                <option aria-label="none" value="" />
                {motives?.map(treatmentReason => (
                  <option
                    key={treatmentReason.id}
                    value={treatmentReason.motivo}
                  >
                    {treatmentReason.motivo}
                  </option>
                ))}
                <option value="outro">Outro</option>
              </Select>
            </FormControl>
            {motive === 'outro' && (
              <TextField
                autoFocus
                margin="dense"
                id="another-reason"
                name="anotherReason"
                value={anotherReason}
                onChange={e => {
                  setAnotherReason(e.target.value);
                }}
                label="Infome o motivo de tratamento"
                type="text"
                fullWidth
                variant="outlined"
              />
            )}

            <Box display="flex" justifyContent="space-between" marginTop="40px">
              <Button
                onClick={() => setOpenModal(false)}
                type="button"
                variant="contained"
                disabled={loading}
                style={{ backgroundColor: 'white', color: '#C92A2A' }}
              >
                Cancelar
              </Button>
              <Button
                onClick={handlePortabilityInTreatment}
                color="primary"
                type="button"
                variant="contained"
                disabled={!isAbleToCancel || loading}
              >
                Confirmar
                {loading && <ButtonProgress size={24} />}
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </Container>
  );
}

export default TreatmentForm;
