import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;
const responseType = 'json';

const api = axios.create({
  baseURL,
  responseType,
});

api.interceptors.request.use(
  config => {
    const authToken = localStorage.getItem('@Movel:token');
    const configHeaders = config.headers;
    if (authToken && configHeaders) {
      // eslint-disable-next-line no-param-reassign
      configHeaders.Authorization = `Bearer ${authToken}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

api.interceptors.response.use(
  response => {
    const authorization = response.headers.authorization?.split(' ');

    if (!authorization) {
      response.headers.Authorization = response?.data?.access_token;
    }

    if (authorization) {
      localStorage.setItem('@Movel:token', authorization[1]);
    }

    return response;
  },
  error => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('@Movel:token');
      if (window.location.pathname === '/login') {
        window.location.href = '/login/?error';

        return false;
      }
      window.location.href = '/login/?login';
    }
    if (
      error?.response?.status === 409 &&
      error?.response?.data.shortMessage !== 'ConfigurationPackageConflict'
    ) {
      localStorage.removeItem('@Movel:token');
      window.location.href = '/login/?conflict';
    }
    return Promise.reject(error.response);
  },
);

export default api;
