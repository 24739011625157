import { useEffect, useState } from 'react';
import { Container, Data, Divider } from 'components/StyledComponents';
import { Skeleton } from '@mui/material';

import { AffiliateBase } from 'models/affiliates';
import useBreakpoint from 'hooks/useBreakpoint';
import { DataInfo, DataText, DataTextName } from './styles';
import Status from '../components/Status';
import LinearConversionProgress from '../components/LinearConversionProgress';

export interface ItemProps {
  loading?: boolean;
  data?: AffiliateBase;
}

function Item({ data, loading = false }: ItemProps) {
  const [conversion, setConversion] = useState(0);

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1290);

  const leadsConvertidos =
    data?.leads_convertidos && parseInt(data.leads_convertidos, 10);
  const leadsCriados = data?.leads_criados;

  useEffect(() => {
    if (leadsConvertidos && leadsCriados) {
      const conversionLeads = (leadsConvertidos / leadsCriados) * 100;
      const conversionLeadsInt = conversionLeads.toFixed();
      setConversion(parseInt(conversionLeadsInt, 10));
    }
  }, [leadsConvertidos, leadsCriados]);

  return (
    <Container>
      <Data>
        {loading ? (
          <DataInfo>
            <DataText>
              <Skeleton width={isTableOrLess ? 300 : 350} />
            </DataText>
            <DataText>
              <Skeleton width={85} />
            </DataText>
            <DataText>
              <Skeleton width={90} />
            </DataText>
            <DataText style={{ marginRight: 30 }}>
              <Skeleton width={75} />
            </DataText>
            <DataText style={{ marginRight: 10 }}>
              <Skeleton width={75} />
            </DataText>
            <DataText>
              <Skeleton width={75} />
            </DataText>
            <DataText
              style={isTableOrLess ? { marginRight: 22 } : { marginRight: 95 }}
            >
              <Skeleton width={120} />
            </DataText>
          </DataInfo>
        ) : (
          data && (
            <DataInfo>
              <DataTextName>{data.nome}</DataTextName>
              <DataText>{data.tipo}</DataText>
              <DataText>
                <Status status={data.status} />
              </DataText>
              <DataText>{data.leads_criados}</DataText>
              <DataText>{data.leads_convertidos}</DataText>
              <DataText>{data.total_linhas ? data.total_linhas : '-'}</DataText>
              <DataText>
                <LinearConversionProgress
                  value={conversion}
                  status={data.status}
                />
              </DataText>
            </DataInfo>
          )
        )}
      </Data>
      <Divider />
    </Container>
  );
}

export default Item;
