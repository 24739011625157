import userSWR from 'swr';
import api from 'services/api';
import { Reason } from 'models/reasons';

export const CancellationReasonsFetch = () => {
  const { data, mutate } = userSWR<Reason[]>(
    'reasons',
    async (): Promise<Reason[]> => {
      const { data: reasons } = await api.get(
        '/core/vendas/motivos-cancelamento',
      );
      return reasons;
    },
  );
  return { data, mutate };
};
