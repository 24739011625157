import useSWR from 'swr';
import api from 'services/api';
import { UserFetchResponse } from 'models/user';

interface Params {
  busca?: string;
}

export const UsersFetch = (page?: number, search?: string) => {
  const { data, mutate } = useSWR<UserFetchResponse>(
    `users_${page}_${search}`,
    async (): Promise<UserFetchResponse> => {
      const params: Params = {};
      if (search) {
        params.busca = search;
      }

      const { data: users, headers } = await api.get(
        `/core/usuarios?page=${page}`,
        { params },
      );

      const paginationInfo = {
        currentPage: parseInt(headers['x-current-page'], 10),
        lastPage: parseInt(headers['x-last-page'], 10),
        perPage: parseInt(headers['x-per-page'], 10),
        total: parseInt(headers['x-total-count'], 10),
      };

      return { users, paginationInfo } as UserFetchResponse;
    },
  );

  return { data, mutate };
};
