import { Skeleton } from '@mui/material';
import React from 'react';
import {
  BooleanValue,
  Container,
  Description,
  NormalValue,
  Sequence,
  SequenceValue,
  Title,
} from './styles';

interface SetupProps {
  loading?: boolean;
  title?: string;
  value?: string | Array<string>;
  typeVision?: 'normal' | 'sequence' | 'boolean';
  description?: string;
}

function SetupItem({
  title,
  value,
  description,
  typeVision = 'normal',
  loading = false,
}: SetupProps) {
  return (
    <Container>
      <Title>{loading ? <Skeleton width={180} /> : title}</Title>

      {(() => {
        switch (typeVision) {
          case 'sequence':
            return (
              typeof value !== 'string' && (
                <Sequence>
                  {value?.map(val => (
                    <SequenceValue key={val}>{val}</SequenceValue>
                  ))}
                </Sequence>
              )
            );

          case 'boolean':
            return (
              typeof value === 'string' && (
                <BooleanValue value={value}>
                  {value === 'true' ? 'Ligado' : 'Desligado'}
                </BooleanValue>
              )
            );

          default:
            return (
              <NormalValue>
                {loading ? <Skeleton width="100%" /> : value}
              </NormalValue>
            );
        }
      })()}
      <Description>
        {loading ? <Skeleton width="100%" /> : description}
      </Description>
    </Container>
  );
}

export default SetupItem;
