import { Card, Grid, Typography } from '@mui/material';
import { CategoryPackage } from 'models/packages';
import { useEffect, useState } from 'react';
import { useConfigurationPackageStore } from 'store/configurationPackageStore';
import { useStyles } from '../../styles';
import PackageC6 from './PackageC6';
import PackageFlashSales from './PackageFlashSales';
import PackagePortability from './PackagePortability';
import PackageDedicate from './PackageDedicated';

interface FormPackageByPlanProps {
  planSelected: number;
  gbPlan: number;
  packageByPlan?: CategoryPackage[];
  handleChangeWithPortability(event: boolean): void;
  handleChangeWithC6(event: boolean): void;
  handleChangeWithDedicated(event: boolean): void;
}

function FormPackageByPlan({
  planSelected,
  gbPlan,
  packageByPlan,
  handleChangeWithPortability,
  handleChangeWithC6,
  handleChangeWithDedicated,
}: FormPackageByPlanProps) {
  const configurationStore = useConfigurationPackageStore(
    state => state.configuration,
  );

  const [gbC6, setGbC6] = useState(0);
  const [gbFlashSale, setGbFlashSale] = useState(0);
  const [gbPortability, setGbPortability] = useState(0);
  const [gbDedicate, setGbDedicate] = useState(0);

  const classes = useStyles();

  const flashSalePackage = packageByPlan?.filter(
    item => item.categoria === 'FLASHSALE',
  );
  const c6Package = packageByPlan?.filter(item => item.categoria === 'C6');
  const portabilityPackage = packageByPlan?.filter(
    item => item.categoria === 'PORTABILIDADE',
  );
  const dedicatedPackage = packageByPlan?.filter(
    item => item.categoria === 'DEDICADO',
  );

  useEffect(() => {
    const pacoteFlashSaleFiltrado = flashSalePackage?.map(item =>
      item.pacotes.find(
        pacoteItem => pacoteItem.id === configurationStore.pacote_flashsale,
      ),
    );

    const gbFlashSaleFilter = pacoteFlashSaleFiltrado?.map(pacoteItem => {
      const gbSelected = pacoteItem?.gb && parseInt(pacoteItem.gb, 10);

      return gbSelected || 0;
    });

    const pacoteC6Filtrado = c6Package?.map(item =>
      item.pacotes.find(
        pacoteItem => pacoteItem.id === configurationStore.pacote_c6,
      ),
    );

    const gbC6Filter = pacoteC6Filtrado?.map(pacoteItem => {
      const gbSelected = pacoteItem?.gb && parseInt(pacoteItem.gb, 10);

      return gbSelected || 0;
    });

    const pacotePortabilityFiltrado = portabilityPackage?.map(item =>
      item.pacotes.find(
        pacoteItem => pacoteItem.id === configurationStore.pacote_portabilidade,
      ),
    );

    const gbPortabilityFilter = pacotePortabilityFiltrado?.map(pacoteItem => {
      const gbSelected = pacoteItem?.gb && parseInt(pacoteItem.gb, 10);

      return gbSelected || 0;
    });

    const pacoteDedicatedFiltrado = dedicatedPackage?.map(item =>
      item.pacotes.find(
        pacoteItem => pacoteItem.id === configurationStore.pacote_dedicado,
      ),
    );

    const gbDedicatedFilter = pacoteDedicatedFiltrado?.map(pacoteItem => {
      const gbSelected = pacoteItem?.gb && parseInt(pacoteItem.gb, 10);

      return gbSelected || 0;
    });

    gbFlashSaleFilter && setGbFlashSale(gbFlashSaleFilter[0]);
    gbC6Filter && setGbC6(gbC6Filter[0]);
    gbPortabilityFilter && setGbPortability(gbPortabilityFilter[0]);
    gbDedicatedFilter && setGbDedicate(gbDedicatedFilter[0]);
  }, [
    c6Package,
    flashSalePackage,
    portabilityPackage,
    dedicatedPackage,
    configurationStore,
  ]);

  const totalGBC6 = gbC6 || 0;
  const totalGBFlashSale = gbFlashSale || 0;
  const totalGBPortability = gbPortability || 0;
  const totalGBDedicated = gbDedicate || 0;

  let totalGb = gbPlan + totalGBFlashSale;
  totalGb = configurationStore.mostrar_bonus_c6 ? totalGBC6 + totalGb : totalGb;
  totalGb = configurationStore.mostrar_bonus_portabilidade
    ? totalGBPortability + totalGb
    : totalGb;
  totalGb = configurationStore.mostrar_bonus_dedicado
    ? totalGBDedicated + totalGb
    : totalGb;

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <PackageFlashSales
          planSelected={planSelected}
          flashSale={flashSalePackage}
          gb={gbFlashSale}
          classes={classes}
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <PackageC6
          planSelected={planSelected}
          c6={c6Package}
          gb={gbC6}
          handleChangeWithC6={handleChangeWithC6}
          classes={classes}
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <PackagePortability
          planSelected={planSelected}
          portability={portabilityPackage}
          gb={gbPortability}
          handleChangeWithPortability={handleChangeWithPortability}
          classes={classes}
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <PackageDedicate
          planSelected={planSelected}
          dedicated={dedicatedPackage}
          gb={gbDedicate}
          handleChangeWithDedicated={handleChangeWithDedicated}
          classes={classes}
        />
      </Grid>

      <Grid item xs={12}>
        <Card className={classes.cardTotal}>
          <Typography variant="h5">Total</Typography>
          <Typography variant="subtitle2" className={classes.subtitle}>
            {totalGb} GB
          </Typography>
        </Card>
      </Grid>
    </Grid>
  );
}

export default FormPackageByPlan;
