import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  status: {
    width: '74px',
    height: '16px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 6px',
    borderRadius: '8px',
    justifyContent: 'center',
  },
}));

export default useStyles;
