import React, { useCallback, useState } from 'react';
import { Button, DialogActions, Popper } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { Actions, UseStyles } from 'components/PopperActions';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { RolesFetch } from 'fetches/rolesFetch';
import { useToast } from 'hooks/toast';
import { ModalCard, UseStylesModal } from 'components/ModalCard';

interface PopperActionsProps {
  id: string;
  open: boolean;
  roleId: number;
  roleNome: string;
  anchorElement: null | HTMLElement;
  handleClick(event: React.MouseEvent<HTMLElement>): void;
}

function PopperActions({
  id,
  open,
  roleId,
  roleNome,
  anchorElement,
  handleClick,
}: PopperActionsProps) {
  const { data: roles, mutate } = RolesFetch();

  const classes = UseStyles();
  const classesModal = UseStylesModal();

  const navigate = useNavigate();
  const { addToast } = useToast();

  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const nome = openModal ? `${roleNome}` : undefined;

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      api
        .delete(`/core/funcoes/${roleId}`)
        .then(() => {
          mutate(
            roles?.filter(role => role.id !== roleId),
            true,
          );
          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Função excluida com sucesso',
          });
        })
        .catch(error => {
          addToast({
            type: 'error',
            title: 'Remover função',
            description: error?.data?.message,
          });
          handleClose();
        });
      handleClick(e);
    },
    [addToast, handleClick, mutate, roleId, roles],
  );

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorElement}
      placement="bottom"
      modifiers={[
        {
          name: 'flip',
          enabled: true,
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            boundariesElement: 'scrollParent',
          },
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      className={classes.popper}
    >
      <span className={classes.arrow} ref={setArrowRef} />
      <Actions className={classes.paper}>
        <Button
          startIcon={<Edit />}
          fullWidth
          onClick={() => navigate(`/gestao-funcao/edita-funcao/${roleId}`)}
        >
          Editar
        </Button>
        <div
          style={{
            width: '100%',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          }}
        />
        <Button startIcon={<Delete />} onClick={handleOpen} fullWidth>
          Deletar
        </Button>
      </Actions>
      <Modal
        aria-labelledby="tile-modal"
        aria-describedby="modal-description"
        className={classesModal.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={openModal}>
          <ModalCard className={classesModal.paper}>
            <h2 id="title-modal">Deseja excluir está função?</h2>
            <p id="modal-description">
              A função <b>{nome}</b> será excluida permanentemente!
            </p>
            <p>
              Caso a função esteja sendo utilizada por algum usuário,
              <br /> não será possivel remove-la.
            </p>
            <DialogActions
              style={{
                justifyContent: 'space-between',
                paddingRight: '24px',
                paddingLeft: '32px',
                paddingTop: '24px',
                paddingBottom: '32px',
              }}
            >
              <Button
                style={{ color: '#C92A2A' }}
                onClick={() => setOpenModal(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={handleDelete}
                color="primary"
              >
                Excluir
              </Button>
            </DialogActions>
          </ModalCard>
        </Fade>
      </Modal>
    </Popper>
  );
}

export default PopperActions;
