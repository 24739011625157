import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useToast } from 'hooks/toast';
import React, { useState } from 'react';
import api from 'services/api';
import { ButtonProgress, ContainerForm } from 'components/StyledComponents';

import { Sale } from '../types';

interface ChangeICCIDProps {
  sale: Sale;
  setSale(sale: Sale): void;
}

function ChangeICCID({ sale, setSale }: ChangeICCIDProps) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [iccid, setIccid] = useState('');

  const { addToast } = useToast();

  const changeICCID = () => {
    setLoading(true);
    const url = '/core/vendas/atualizar-detalhe';

    return api
      .put(`${url}/${sale?.id}`, {
        status: 'TROCAR_ICCID',
        iccid,
      })
      .then(() => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'ICCID atualizado com sucesso!',
        });
        setOpen(false);
      })
      .catch(error => {
        setLoading(false);
        const message =
          (error.data.errors && error.data.errors[0].message) ||
          error.data.message;
        message &&
          addToast({
            type: 'error',
            title: 'Erro na troca de ICCID',
            description: message,
          });
      })
      .finally(() => {
        api
          .get(`core/vendas/${sale.id}`)
          .then(({ data }) => setSale({ ...sale, ...data }));
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ContainerForm>
      <Button
        type="button"
        variant="contained"
        color="primary"
        disabled={loading}
        fullWidth
        size="large"
        onClick={() => setOpen(true)}
      >
        Trocar ICCID
        {loading && <ButtonProgress size={24} />}
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Troca de ICCID</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Informe um ICCID válido. Ao efetuar a troca, o Simcard será atrelado
            a venda, e a mesma será direcionada para o fluxo de envio.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="reason"
            name="reason"
            value={iccid}
            onChange={e => {
              setIccid(e.target.value);
            }}
            label="ICCID"
            type="text"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            Fechar
          </Button>
          <Button onClick={changeICCID} color="primary" disabled={loading}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </ContainerForm>
  );
}

export default ChangeICCID;
