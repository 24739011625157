import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  padding: 0px 20px;

  p {
    :nth-child(1) {
      width: 35%;
    }
    :nth-child(2) {
      width: 35%;
    }
    :nth-child(3) {
      width: 100%;
    }
  }
`;
