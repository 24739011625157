import styled from 'styled-components';
import { MTheme } from 'models/theme';
import palette from 'theme/palette';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: MTheme) => ({
  buttonUpload: {
    color: '#B3B3B3',
    fontWeight: 400,
    padding: 8,
    '& .MuiButton-label': {
      color: '#4F4F4F',
      marginLeft: 11,
      marginRight: 11,
      justifyContent: 'space-between ',
    },
    '& .MuiSvgIcon-root': {
      color: '#B3B3B3',
    },
  },
  buttonSelect: {
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      fontSize: 14,
      color: 'rgba(0, 0, 0, 0.5)',
      transition: 'color 0s 2147483647s',
      '&:active': {
        color: `${palette.primary.main}`,
        transition: '0s',
      },
    },
  },
  buttonImport: {
    width: 150,
    [theme.breakpoints.down(1024)]: {
      width: 140,
      marginRight: 6,
    },
  },
}));

export const BoxButton = styled.div`
  margin-left: auto;
  margin-top: 20px;
`;
