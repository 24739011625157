import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormLabel,
  TextField,
  Grid,
  Skeleton,
  IconButton,
} from '@mui/material';
import { AntSwitch } from 'components/Switch';
import { ButtonProgress, Form } from 'components/StyledComponents';
import { GetApp, NoteAddOutlined, RemoveRedEye } from '@mui/icons-material';
import { Package } from 'models/packages';
import {
  CardFormPDF,
  TextFormPDF,
  TitleFormPDF,
} from 'pages/OfferDefinition/OfferDefinitionList/FormOfferDefinition/FormPDF/styles';
import FidelityList from './FidelityList';
import PackagesList from './PackagesList';
import BenefitsList from './BenefitsList';
import { SubHeader } from './styles';
import DialogPlanBenefit from './Dialog/DialogPlanBenefit';
import DialogPlanFidelity from './Dialog/DialogPlanFidelity';
import DialogPlanPackage from './Dialog/DialogPlanPackage';
import { useEditPlan } from './hooks/useEditPlan';

function EditPlan() {
  const {
    id,
    planStore,
    plansResponse,
    loyalties,
    packages,
    benefits,
    loading,
    saveLoading,
    correspondingLoading,
    loyaltyFiltered,
    packagesFiltered,
    benefitsFiltered,
    isOpenDialogPlanFidelity,
    isOpenDialogPlanBenefit,
    isOpenDialogPlanPackage,
    classes,
    fileRef,
    isTableOrLess,
    setPlanStore,
    setIsOpenDialogPlanFidelity,
    setIsOpenDialogPlanBenefit,
    setIsOpenDialogPlanPackage,
    setCorrespondingLoading,
    loadingDownload,
    handleChangeNome,
    handleChangeTimID,
    handleChangeStatus,
    handleChangeTravarSincronia,
    handleChangeExibirEcommerce,
    handleChangeM4uID,
    handleChangeDescricao,
    handleChangeCorrespondente,
    handleChangeSumarioUrl,
    handleChangeAtaqueUrl,
    handleChangeDefesaUrl,
    handleChangeUploadPDF,
    handleSave,
    handleDownload,
    navigate,
  } = useEditPlan();

  return (
    <Box style={{ padding: '5px 15px' }}>
      <CardHeader
        title="Gestão de planos"
        subheader="Painel de informações dos planos e ofertas TIM. "
      />
      <SubHeader>Dados do plano</SubHeader>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card style={{ margin: '8px 16px 16px' }}>
            <CardContent style={{ padding: '16px 15px' }}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <TextField
                      aria-readonly
                      fullWidth
                      label="Nome"
                      margin="dense"
                      name="nome"
                      value={planStore.nome || ''}
                      onChange={e => handleChangeNome(e.target.value)}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </Grid>
                <Grid item md={3} xs={12}>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <TextField
                      aria-readonly
                      fullWidth
                      label="TIM ID"
                      margin="dense"
                      name="tim ID"
                      value={planStore.tim_id || ''}
                      onChange={e => handleChangeTimID(e.target.value)}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </Grid>
                <Grid item md={3} xs={12}>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        gap: 15,
                      }}
                    >
                      <FormControl component="fieldset">
                        <FormLabel
                          component="legend"
                          style={{ paddingBottom: 6, fontSize: '12px' }}
                        >
                          Status
                        </FormLabel>
                        <AntSwitch
                          size="medium"
                          checked={!!planStore.ativo}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeStatus(!!e.target.checked)
                          }
                          color="secondary"
                        />
                      </FormControl>
                      <FormControl component="fieldset">
                        <FormLabel
                          component="legend"
                          style={{ paddingBottom: 6, fontSize: '12px' }}
                        >
                          Travar sincronia
                        </FormLabel>
                        <AntSwitch
                          size="medium"
                          checked={!!planStore.travar_sincronia}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeTravarSincronia(!!e.target.checked)
                          }
                          color="secondary"
                        />
                      </FormControl>
                      <FormControl component="fieldset">
                        <FormLabel
                          component="legend"
                          style={{ paddingBottom: 6, fontSize: '12px' }}
                        >
                          Exibir Ecommerce
                        </FormLabel>
                        <AntSwitch
                          size="medium"
                          checked={!!planStore.exibir_ecommerce}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeExibirEcommerce(!!e.target.checked)
                          }
                          color="secondary"
                        />
                      </FormControl>
                    </div>
                  )}
                </Grid>
                {(planStore.segmento === 'POS_EXPRESS' ||
                  planStore.segmento === 'EXPRESS') && (
                  <Grid item md={6} xs={12}>
                    {loading ? (
                      <Skeleton />
                    ) : (
                      <TextField
                        aria-readonly
                        fullWidth
                        label="M4U ID"
                        margin="dense"
                        name="m4u ID"
                        value={planStore.m4u_id || ''}
                        onChange={e => handleChangeM4uID(e.target.value)}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  </Grid>
                )}

                <Grid item md={6} xs={12}>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <TextField
                      aria-readonly
                      fullWidth
                      label="Descricao"
                      margin="dense"
                      name="descricao"
                      value={planStore.descricao || ''}
                      onChange={e => handleChangeDescricao(e.target.value)}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </Grid>

                {planStore.segmento !== 'PRE_PAGO' && (
                  <Grid item md={6} xs={12}>
                    {loading ? (
                      <Skeleton />
                    ) : (
                      <TextField
                        label="Correspondente"
                        variant="outlined"
                        select
                        value={planStore?.correspondente || ''}
                        SelectProps={{ native: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e =>
                          handleChangeCorrespondente(e.target.value)
                        }
                        size="small"
                        margin="dense"
                        fullWidth
                      >
                        <option value="">Sem correspondente</option>
                        {plansResponse &&
                          plansResponse.map(option => (
                            <option key={option.id} value={String(option.id)}>
                              {option.nome}
                            </option>
                          ))}
                      </TextField>
                    )}
                  </Grid>
                )}

                <Grid item md={6} xs={12}>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <TextField
                      aria-readonly
                      fullWidth
                      label="Sumário URL"
                      margin="dense"
                      name="sumario_url"
                      value={planStore.sumario_url || ''}
                      onChange={e => handleChangeSumarioUrl(e.target.value)}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <TextField
                      aria-readonly
                      fullWidth
                      label="Ataque URL"
                      margin="dense"
                      name="ataque_url"
                      value={planStore.ataque_url || ''}
                      onChange={e => handleChangeAtaqueUrl(e.target.value)}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <TextField
                      aria-readonly
                      fullWidth
                      label="Defesa URL"
                      margin="dense"
                      name="defesa_url"
                      value={planStore.defesa_url || ''}
                      onChange={e => handleChangeDefesaUrl(e.target.value)}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <CardFormPDF>
                      <Grid container item spacing={1} alignItems="end">
                        <Grid item md={planStore.regulamento ? 8 : 12} xs={12}>
                          <Grid
                            container
                            item
                            spacing={1}
                            flexDirection="column"
                          >
                            <Grid item md={12}>
                              <TitleFormPDF>Regulamento</TitleFormPDF>
                            </Grid>
                            <Grid item md={12}>
                              <div>
                                <input
                                  ref={fileRef}
                                  accept=".pdf"
                                  id="file-adicional-package"
                                  type="file"
                                  onChange={e => {
                                    handleChangeUploadPDF(e.target.files);
                                  }}
                                  style={{ display: 'none' }}
                                />
                                <label htmlFor="file-adicional-package">
                                  <Button
                                    variant="outlined"
                                    component="span"
                                    className={`${
                                      planStore.regulamento_plano
                                        ? classes.pdfFile
                                        : classes.buttonUpload
                                    }`}
                                    fullWidth
                                    endIcon={<NoteAddOutlined />}
                                  >
                                    {planStore.regulamento_plano
                                      ? planStore.regulamento_plano.name
                                      : `Anexar arquivo`}
                                  </Button>
                                </label>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        {planStore.regulamento && (
                          <Grid item md={4} xs={12}>
                            <Grid
                              container
                              item
                              spacing={0}
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent={isTableOrLess ? 'end' : 'center'}
                            >
                              <Grid item md={8}>
                                <TextFormPDF>Baixar arquivo atual</TextFormPDF>
                              </Grid>
                              <Grid item md="auto">
                                <Form noValidate autoComplete="off">
                                  <IconButton
                                    type="submit"
                                    color="primary"
                                    disabled={loadingDownload}
                                    onClick={() => {
                                      handleDownload();
                                    }}
                                  >
                                    <GetApp
                                      fontSize="medium"
                                      sx={{ color: '#b4b6be' }}
                                    />
                                    {loadingDownload && (
                                      <ButtonProgress size={24} />
                                    )}
                                  </IconButton>
                                </Form>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </CardFormPDF>
                  )}
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  display="flex"
                  justifyContent="end"
                  gap={2}
                >
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <>
                      {planStore.segmento !== 'PRE_PAGO' &&
                        planStore.correspondente && (
                          <Button
                            type="button"
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                              setCorrespondingLoading(true);
                              navigate(
                                `/gestao-planos/edita-plano/${planStore?.correspondente}`,
                              );
                            }}
                            startIcon={<RemoveRedEye />}
                            disabled={correspondingLoading}
                          >
                            Ir para correspondente
                            {correspondingLoading && (
                              <ButtonProgress size={24} />
                            )}
                          </Button>
                        )}
                      <Button
                        type="button"
                        color="secondary"
                        variant="outlined"
                        onClick={() =>
                          navigate(`/gestao-planos/tabela-precos-plano/${id}`)
                        }
                        startIcon={<RemoveRedEye />}
                      >
                        Tabela de preços do plano
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <SubHeader>Lista de Fidelidades</SubHeader>
          <FidelityList data={planStore.fidelidades} loading={loading} />

          {planStore.fidelidades && (
            <Box textAlign="center">
              <Button
                type="button"
                color="secondary"
                variant="outlined"
                value="fidelidade"
                onClick={() => setIsOpenDialogPlanFidelity(true)}
                disabled={loading}
              >
                Anexar fidelidade
              </Button>

              <DialogPlanFidelity
                data={loyaltyFiltered}
                isOpen={isOpenDialogPlanFidelity}
                onUpdate={(value: string) => {
                  const newLoyaltiItem = loyalties?.find(fidelidade => {
                    return String(fidelidade.id) === String(value);
                  });

                  newLoyaltiItem &&
                    setPlanStore({
                      ...planStore,
                      fidelidades: [...planStore.fidelidades, newLoyaltiItem],
                    });

                  setIsOpenDialogPlanFidelity(false);
                }}
                onClose={() => setIsOpenDialogPlanFidelity(false)}
              />
            </Box>
          )}
          <SubHeader>Lista de Pacotes</SubHeader>

          <PackagesList loading={loading} />

          {planStore.pacotes && (
            <Box textAlign="center">
              <Button
                type="button"
                color="secondary"
                variant="outlined"
                onClick={() => setIsOpenDialogPlanPackage(true)}
                disabled={loading}
                style={{ marginRight: 16 }}
              >
                Anexar pacote
              </Button>
              <Button
                type="button"
                color="secondary"
                variant="outlined"
                onClick={() => navigate(`/gestao-pacotes/`)}
                disabled={loading}
              >
                Editar pacotes
              </Button>

              <DialogPlanPackage
                data={packagesFiltered}
                isOpen={isOpenDialogPlanPackage}
                onUpdate={(value: string) => {
                  const newPackages = packages?.find((pacotes: Package) => {
                    return String(pacotes.id) === String(value);
                  });

                  newPackages &&
                    setPlanStore({
                      ...planStore,
                      pacotes: [...planStore.pacotes, newPackages],
                    });

                  setIsOpenDialogPlanPackage(false);
                }}
                onClose={() => setIsOpenDialogPlanPackage(false)}
              />
            </Box>
          )}
          <SubHeader>Lista de Benefícios</SubHeader>
          {planStore && (
            <BenefitsList data={planStore.beneficios} loading={loading} />
          )}
          {planStore.beneficios && (
            <Box textAlign="center">
              <Button
                type="button"
                color="secondary"
                variant="outlined"
                onClick={() => setIsOpenDialogPlanBenefit(true)}
                style={{ marginRight: 16 }}
                disabled={loading}
              >
                Anexar benefício
              </Button>
              <Button
                type="button"
                color="secondary"
                variant="outlined"
                onClick={() => navigate(`/gestao-planos/beneficios/${id}`)}
                disabled={loading}
              >
                Editar benefícios
              </Button>

              <DialogPlanBenefit
                data={benefitsFiltered}
                isOpen={isOpenDialogPlanBenefit}
                onUpdate={(value: string) => {
                  const newBenefitItem = benefits?.find(beneficio => {
                    return String(beneficio.id) === String(value);
                  });

                  newBenefitItem &&
                    setPlanStore({
                      ...planStore,
                      beneficios: [...planStore.beneficios, newBenefitItem],
                    });
                  setIsOpenDialogPlanBenefit(false);
                }}
                onClose={() => setIsOpenDialogPlanBenefit(false)}
              />
            </Box>
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            margin="8px 16px 16px"
          >
            <Button
              onClick={() => navigate('/gestao-planos/')}
              type="button"
              variant="outlined"
            >
              Voltar
            </Button>

            <Button
              onClick={() => handleSave()}
              color="primary"
              type="button"
              variant="contained"
              disabled={saveLoading}
            >
              Salvar
              {saveLoading && <ButtonProgress size={24} />}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EditPlan;
