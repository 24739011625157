import { useCallback, useEffect, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Skeleton } from '@mui/material';
import { format, isAfter, isBefore, parseISO } from 'date-fns';

import { Container, Data, Divider } from 'components/StyledComponents';
import { ConfigurationPackage } from 'models/configurationsPackage';
import {
  Badge,
  DataInfo,
  DataText,
  WrapperNameInfo,
  AddBadge,
  DataTextGB,
} from './styles';
import PopperActions from '../PopperActions';

export interface ItemProps {
  loading?: boolean;
  data?: ConfigurationPackage;
  updateConfigurations?(): void;
}

function Item({ data, loading = false, updateConfigurations }: ItemProps) {
  const [open, setOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [isCurrentBadge, setIsCurrentBadge] = useState(false);

  useEffect(() => {
    if (!data) {
      return;
    }

    const now = new Date();
    const start =
      typeof data.inicio === 'string' ? parseISO(data.inicio) : data.inicio;
    const end = typeof data.fim === 'string' ? parseISO(data.fim) : data.fim;
    const isBetween = isAfter(now, start) && isBefore(now, end);

    setIsCurrentBadge(isBetween);
  }, [data, setIsCurrentBadge]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
    setOpen(previousOpen => !previousOpen);
  }, []);

  const handleClickAway = () => {
    setOpen(false);
  };

  const canBeOpen = open && Boolean(anchorElement);
  const id = canBeOpen ? `popperAction_${data?.id}` : undefined;

  const planGb = data?.plan ? data?.plan.gb : 0;
  const flashSaleGb = data?.flash_sale ? data.flash_sale.gb : 0;
  const c6Gb = data?.c6 ? data.c6.gb : 0;
  const portabilityGb = data?.portability ? data.portability.gb : 0;
  const dedicatedGb = data?.dedicated ? data.dedicated.gb : 0;

  let totalGb = planGb + flashSaleGb;
  totalGb = data?.mostrar_bonus_portabilidade
    ? portabilityGb + totalGb
    : totalGb;
  totalGb = data?.mostrar_bonus_c6 ? c6Gb + totalGb : totalGb;
  totalGb = data?.mostrar_bonus_dedicado ? dedicatedGb + totalGb : totalGb;

  return (
    <Container style={{ height: 90 }}>
      <Data>
        {loading ? (
          <DataInfo style={{ margin: '0 150px 0 24px' }}>
            <Skeleton width={300} />
            <Skeleton width={85} />
            <Skeleton width={85} />
            <Skeleton width={85} />
            <Skeleton width={85} />
            <Skeleton width={85} />
            <Skeleton width={85} />
            <Skeleton width={85} />
          </DataInfo>
        ) : (
          data && (
            <>
              <DataInfo isCurrent={isCurrentBadge}>
                <WrapperNameInfo>
                  <DataText>{data.nome}</DataText>
                </WrapperNameInfo>

                <DataTextGB>+{planGb}GB</DataTextGB>
                <DataTextGB>{flashSaleGb}GB</DataTextGB>
                <DataTextGB>
                  {c6Gb}GB {data.mostrar_bonus_c6 ? <AddBadge>+</AddBadge> : ''}
                </DataTextGB>
                <DataTextGB>
                  {portabilityGb}GB{' '}
                  {data.mostrar_bonus_portabilidade ? (
                    <AddBadge>+</AddBadge>
                  ) : (
                    ''
                  )}
                </DataTextGB>
                <DataTextGB>
                  {dedicatedGb}GB{' '}
                  {data.mostrar_bonus_dedicado ? <AddBadge>+</AddBadge> : ''}
                </DataTextGB>
                <DataTextGB>{totalGb}GB</DataTextGB>
                <DataText>
                  {format(parseISO(data.inicio), 'dd/MM/yyyy - HH:mm')}
                </DataText>
                <DataText>
                  {format(parseISO(data.fim), 'dd/MM/yyyy - HH:mm')}
                </DataText>
              </DataInfo>
              {isCurrentBadge ? (
                <Badge style={{ marginRight: 10 }}>vigente</Badge>
              ) : (
                <span style={{ visibility: 'hidden', marginRight: 20 }}>
                  N/A
                </span>
              )}
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <PopperActions
                id={id || ''}
                open={open}
                configurationId={data.id}
                configurationName={data.nome || ''}
                anchorElement={anchorElement}
                handleClick={handleClick}
                handleClickAway={handleClickAway}
                updateConfigurations={updateConfigurations}
              />
            </>
          )
        )}
      </Data>
      <Divider />
    </Container>
  );
}

export default Item;
