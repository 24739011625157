import useSWR from 'swr';
import api from 'services/api';
import { LPFAQ } from 'models/lpFaqs';

export const LPFAQsFetch = () => {
  const { data, mutate } = useSWR<LPFAQ[]>(
    'faqs',
    async (): Promise<LPFAQ[]> => {
      const { data: faqs } = await api.get('/core/lp-faqs');

      return faqs as LPFAQ[];
    },
  );
  return { data, mutate };
};
