import { List } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { Container } from 'components/CardList';
import api from 'services/api';
import { usePlanStore } from 'store/planStore';
import { useToast } from 'hooks/toast';
import { capitalize } from 'util/formatter';
import { Box } from '@mui/material';
import { Benefit, TypeBenefit } from 'models/benefits';
import MobileItem from './MobileItem';
import { Header, TitleTypeBenefit } from './styles';
import Item from './Item';

interface BodyProps {
  plan_id?: number;
  benefits: TypeBenefit[];
  isMobile: boolean;
  updateBenefits(benefits: TypeBenefit[], showRevalid?: boolean): void;
  setBenefitSelected(value: Benefit): void;
}

interface BenefitsListProps {
  plan_id?: number;
  benefits: TypeBenefit[];
  updateBenefits(benefits: TypeBenefit[], showRevalid?: boolean): void;
  setBenefitSelected(value: Benefit): void;
}

interface LoadingListProps {
  isMobile: boolean;
}

function LoadingList({ isMobile }: LoadingListProps) {
  if (isMobile) {
    return (
      <>
        <MobileItem loading />
        <MobileItem loading />
        <MobileItem loading />
        <MobileItem loading />
      </>
    );
  }
  return (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({
  plan_id,
  benefits,
  isMobile,
  updateBenefits,
  setBenefitSelected,
}: BodyProps) {
  const planStore = usePlanStore(state => state.plan);
  const setPlanStore = usePlanStore(state => state.setPlan);

  const { addToast } = useToast();

  const removeBenefitPlan = (id: number) => {
    const newBenefits = planStore.beneficios.filter(
      beneficio => beneficio.id !== id,
    );
    setPlanStore({ ...planStore, beneficios: newBenefits });
  };

  const handleRemove = (id: number) => {
    api
      .put(`/core/planos/${plan_id}/remover-beneficio/${id}`)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Removido com sucesso',
        });
        updateBenefits(
          benefits?.filter(item =>
            item.beneficios.filter(benefit => benefit.id !== id),
          ),
        );
        removeBenefitPlan(id);
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro ao remover',
          description: error?.data?.message,
        });
      });
  };

  return (
    <Box>
      {benefits.length > 0 ? (
        benefits.map(type => (
          <Box key={type.tipo}>
            <TitleTypeBenefit>{capitalize(type.tipo)}</TitleTypeBenefit>
            {!isMobile && (
              <Header plan_id={plan_id}>
                <span>Componente</span>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flex: 1,
                  }}
                >
                  <span style={{ width: 400 }}>Nome</span>
                  <span>Quantidade</span>
                  <span />
                </div>
              </Header>
            )}
            <List>
              {type.beneficios?.map(data =>
                isMobile ? (
                  <MobileItem
                    key={data.id}
                    plan_id={plan_id}
                    data={data}
                    typeBenefit={type.tipo}
                    setBenefitSelected={setBenefitSelected}
                    handleRemove={handleRemove}
                  />
                ) : (
                  <Item
                    key={data.id}
                    plan_id={plan_id}
                    data={data}
                    typeBenefit={type.tipo}
                    setBenefitSelected={setBenefitSelected}
                    handleRemove={handleRemove}
                  />
                ),
              )}
            </List>
          </Box>
        ))
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhum benefício encontrado</h3>
      )}
    </Box>
  );
}

function BenefitsList({
  plan_id,
  benefits,
  updateBenefits,
  setBenefitSelected,
}: BenefitsListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Container style={{ marginTop: 0 }}>
      {benefits ? (
        <Body
          plan_id={plan_id}
          benefits={benefits}
          isMobile={isTableOrLess}
          updateBenefits={updateBenefits}
          setBenefitSelected={setBenefitSelected}
        />
      ) : (
        <LoadingList isMobile={isTableOrLess} />
      )}
    </Container>
  );
}

export default BenefitsList;
