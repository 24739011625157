import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

import { DomainFetch } from 'fetches/domainFetch';
import { Domain } from 'models/domain';

interface Props {
  type: string;
}

function DomainTable({ type }: Props) {
  const { data: domains } = DomainFetch(type);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell>Tipo</TableCell>
            <TableCell>Valor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {domains
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((domain: Domain) => (
              <TableRow key={domain.value}>
                <TableCell>{domain.type}</TableCell>
                <TableCell>
                  {domain.value}
                  <span>
                    {domain.value !== domain.label && ` (${domain.label})`}
                  </span>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        {domains && domains.length > 10 && (
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={3}
                count={domains?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'linhas por págna' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
}

export default DomainTable;
