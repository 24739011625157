import styled from 'styled-components';

interface BadgeProps {
  type: 'ativo' | 'inativo';
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 14px 14px 0;

  background: #fff;
  transition: background 0.2s;

  position: relative;

  &:hover {
    background: #d0edfb;
    cursor: pointer;
  }
`;

export const Divider = styled.div`
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const Data = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const DataInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 24px;
`;
export const WrapperNameInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 200px;
`;

export const DataTextName = styled.span`
  width: 182px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  margin-right: 15px;
`;
export const DataText = styled.span`
  min-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  display: flex;
  align-items: center;
`;

export const Badge = styled.span<BadgeProps>`
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  padding: 2px 0;
  border-radius: 6px;
  width: 60px;
  height: 18px;

  color: ${props => (props.type === 'ativo' ? '#30C952' : '#00364D')};
  background: ${props => (props.type === 'ativo' ? '#D6F4DC' : '#F2F2F2')};
`;
