import { SwitchIOS } from 'components/SwitchIOS';
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Popover,
  Typography,
} from '@mui/material';
import { capitalize } from 'lodash';
import { ButtonFilter, LineControl, useStyles } from './style';
import { usePopoverActions } from './hooks/usePopoverActions';

export interface FilterDateMonth {
  month: string;
  firstDay: string;
  lastDay: string;
  key: string;
}

export interface MonthSelected {
  [key: string]: boolean;
}

interface PopoverActionsProps {
  id: string;

  openPopover: boolean;

  anchorEl: null | HTMLElement;

  monthsSelected: MonthSelected;

  initMonthSelected(value: MonthSelected): void;

  handleRangeMonth(value: FilterDateMonth): void;

  showReprocessedSalesButton: boolean;

  handleClick(event: React.MouseEvent<HTMLElement>): void;

  onFilter(): void;

  onClose(value: boolean): void;
}

function PopoverActions({
  id,
  openPopover,
  anchorEl,
  monthsSelected,
  initMonthSelected,
  handleRangeMonth,
  handleClick,
  showReprocessedSalesButton,
  onFilter,
  onClose,
}: PopoverActionsProps) {
  const {
    withReprocessed,
    inReprocessing,
    withEngagement,
    today,
    yesterday,
    lastWeek,
    months,
    handleChangeWithReprocessed,
    handleChangeInReprocessing,
    handleChangeWithEngagement,
    handleToday,
    handleYesterday,
    handleLastWeek,
  } = usePopoverActions({ initMonthSelected });

  const classes = useStyles();
  return (
    <Popover
      id={id}
      open={openPopover}
      onClose={handleClick}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Container className={classes.root}>
        <Typography className={classes.title}>Dias</Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ButtonFilter onClick={() => handleToday(!today)} actived={today}>
            HOJE
          </ButtonFilter>

          <ButtonFilter
            onClick={() => handleYesterday(!yesterday)}
            actived={yesterday}
          >
            ONTEM
          </ButtonFilter>

          <ButtonFilter
            onClick={() => handleLastWeek(!lastWeek)}
            actived={lastWeek}
          >
            D-7
          </ButtonFilter>
        </Box>
        <LineControl>
          <Typography className={classes.title}>Últimos 12 meses</Typography>
          <Typography variant="body2">Selecione meses adjacentes</Typography>
        </LineControl>

        <Box
          style={{
            display: 'grid',
            gridAutoColumns: '1fr',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridGap: '16px',
          }}
        >
          {months.map(item => (
            <Button
              key={item.firstDay}
              variant="contained"
              color={monthsSelected[item.key] ? 'primary' : 'inherit'}
              className={classes.buttonMonth}
              onClick={() => handleRangeMonth(item)}
            >
              {capitalize(item.month)}
            </Button>
          ))}
        </Box>

        {showReprocessedSalesButton && (
          <>
            <Box>
              <FormControlLabel
                label={<Typography variant="body2">Reprocessadas</Typography>}
                control={
                  <SwitchIOS
                    size="medium"
                    color="secondary"
                    checked={withReprocessed}
                    onChange={handleChangeWithReprocessed}
                  />
                }
                labelPlacement="end"
                className={classes.switchButton}
              />
              <FormControlLabel
                label={
                  <Typography variant="body2">Em processamento</Typography>
                }
                control={
                  <SwitchIOS
                    size="medium"
                    color="secondary"
                    checked={inReprocessing}
                    onChange={handleChangeInReprocessing}
                  />
                }
                labelPlacement="end"
                className={classes.switchButton}
              />
            </Box>
            <Box>
              <FormControlLabel
                label={<Typography variant="body2">Engajamento</Typography>}
                control={
                  <SwitchIOS
                    size="medium"
                    color="secondary"
                    checked={withEngagement}
                    onChange={handleChangeWithEngagement}
                  />
                }
                labelPlacement="end"
                className={classes.switchButton}
              />
            </Box>
          </>
        )}

        <LineControl>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              onFilter();
              onClose(false);
            }}
          >
            Filtrar
          </Button>
        </LineControl>
      </Container>
    </Popover>
  );
}

export default PopoverActions;
