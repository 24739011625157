import { ReactNode, useMemo } from 'react';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';

interface Props {
  children: ReactNode;
}

function ThemeConfig({ children }: Props) {
  const themeOptions = useMemo(
    () => ({
      palette,
      typography,
      components: overrides(),
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },
    }),
    [],
  );

  const theme = createTheme(themeOptions);

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeConfig;
