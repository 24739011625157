import { CardHeader, Divider, SwipeableDrawer } from '@mui/material';
import Pagination from 'components/Pagination';
import { Card } from 'components/StyledComponents';
import Filters from './Filters';
import { InfoSatisfactionSurvey } from './InfoSatisfactionSurvey';
import SatisfactionSurveyList from './SatisfactionSurveyList/index';
import { BoxFilters, CardContent, FilterTypography, TopBar } from './styles';
import Totalizer from './Totalizer';
import { useSatisfactionSurvey } from './hooks/useSatisfactionSurvey';

function SatisfactionSurvey() {
  const {
    ratingSelected,
    origemSelected,
    onlyWithComment,
    response,
    currentPage,
    showSatisfactionSurvey,
    satisfactionSurvey,
    isTableOrLess,
    setOrigemSelected,
    setRatingSelected,
    setOnlyWithComment,
    setCurrentPage,
    setShowSatisfactionSurvey,
    closeDrawer,
  } = useSatisfactionSurvey();

  return (
    <Card>
      <TopBar>
        <CardHeader
          title="Avaliações"
          subheader="Painel de visualização de avaliações do ecommerce."
        />
        <Totalizer
          nota={ratingSelected === 'Todas' ? null : ratingSelected}
          origem={origemSelected === 'Todas' ? null : origemSelected}
          onlyWithComment={onlyWithComment}
        />
        <Divider />
        <BoxFilters>
          <FilterTypography>Listagem</FilterTypography>
          <Filters
            origemSelected={origemSelected}
            setOrigemSelected={setOrigemSelected}
            ratingSelected={ratingSelected}
            setRatingSelected={setRatingSelected}
            onlyWithComment={onlyWithComment}
            setOnlyWithComment={setOnlyWithComment}
          />
        </BoxFilters>
      </TopBar>

      <CardContent>
        <SatisfactionSurveyList
          satisfactionSurveys={response?.satisfactionSurveys}
        />
        {response && (
          <Pagination
            currentPage={currentPage}
            lastPage={response?.paginationInfo.lastPage}
            perPage={response?.paginationInfo.perPage}
            total={response?.paginationInfo.total}
            setCurrentPage={setCurrentPage}
          />
        )}
      </CardContent>
      <SwipeableDrawer
        anchor="right"
        open={showSatisfactionSurvey}
        onOpen={() => setShowSatisfactionSurvey(true)}
        onClose={() => {
          closeDrawer();
        }}
      >
        <InfoSatisfactionSurvey
          satisfactionSurvey={satisfactionSurvey}
          isTableOrLess={isTableOrLess}
          closeDrawer={closeDrawer}
        />
      </SwipeableDrawer>
    </Card>
  );
}

export default SatisfactionSurvey;
