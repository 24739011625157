import { merge } from 'lodash';
import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';

const ComponentsOverrides = () => {
  return merge(
    MuiButton(),
    MuiIconButton(),
    MuiPaper(),
    MuiTableCell(),
    MuiTableHead(),
    MuiTypography(),
  );
};
export default ComponentsOverrides;
