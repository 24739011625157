import userSWR from 'swr';
import api from 'services/api';
import { Benefit, TypeBenefit } from 'models/benefits';

interface Params {
  plano_id?: number;
}
interface FetchProps {
  searchPlanID?: string;
}
export const BenefitsFetch = () => {
  const { data, mutate: mutateBenefit } = userSWR<Benefit[]>(
    'benefits',
    async (): Promise<Benefit[]> => {
      const { data: benefits } = await api.get('/core/beneficios');

      return benefits as Benefit[];
    },
  );
  return { data, mutateBenefit };
};

export const BenefitsByTypeFetch = ({ searchPlanID }: FetchProps) => {
  const { data, mutate: mutateBenefit } = userSWR<TypeBenefit[]>(
    `benefits_${searchPlanID}`,
    async (): Promise<TypeBenefit[]> => {
      const params: Params = {};

      if (searchPlanID) {
        params.plano_id = parseInt(searchPlanID, 10);
      }

      const { data: benefits } = await api.get(`/core/beneficios/tipos`, {
        params,
      });

      return benefits as TypeBenefit[];
    },
  );
  return { data, mutateBenefit };
};

export const AllQuantityOrComponentInBenefitsFetch = <Data>(type: string) => {
  const { data, mutate: mutateBenefit } = userSWR<Data>(
    `benefits_${type}`,
    async (): Promise<Data> => {
      const { data: response } = await api.get(
        `/core/beneficios/listar/${type}`,
      );

      return response;
    },
  );
  return { data, mutateBenefit };
};
