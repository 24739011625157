import styled from 'styled-components';

export const IconsComponent = styled.div`
  min-width: 150px;
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

export const DataInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 18px 0 18px;
`;

export const DataTextName = styled.span`
  min-width: 100px;
  font-size: 15px;
  margin-right: 15px;
`;

export const WrapperNameInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 350px;
  width: 350px;
`;

export const DataText = styled.span`
  min-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  display: flex;
  align-items: center;
`;

export const DataTextLong = styled.span`
  min-width: 150px;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
