import styled from 'styled-components';

interface HeaderProps {
  isDesktop: boolean;
}
export const Header = styled.div<HeaderProps>`
  display: flex;

  justify-content: space-between;
  padding-right: 20px;
  flex-direction: ${props => (props.isDesktop ? 'row' : 'column')};
`;

export const SalesPagination = styled.div`
  margin-top: 10px;
`;

export const Logs = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
`;
