import {
  Button,
  ButtonGroup,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { RiDeleteBinLine } from 'react-icons/ri';
import {
  MobileContainer,
  MobileData,
  MobileDataLabel,
} from 'components/StyledComponents';
import { FiEdit } from 'react-icons/fi';
import useBreakpoint from 'hooks/useBreakpoint';
import { Action } from 'components/MobileList';
import { ItemProps } from '../types';
import { ContainerItem, List, useStyles } from '../Item/styles';
import { MobileTextName } from './styles';

function MobileItem({
  benefit,
  lengthBenefits,
  loading,
  setBenefitSelected,
  setShowBenefitEdition,
  handleOpenModalDelete,
  benefitUp,
  benefitDown,
}: ItemProps) {
  const classes = useStyles();
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(600);

  return (
    <ContainerItem>
      <Typography className={classes.typography}>
        {loading ? <Skeleton width={20} /> : benefit?.ordem}
      </Typography>
      <ButtonGroup
        className={classes.buttonGroup}
        orientation={isTableOrLess ? 'vertical' : 'horizontal'}
        variant="outlined"
        aria-label="outlined button group"
      >
        {loading ? (
          <Skeleton width={35} height={92} />
        ) : (
          benefit && (
            <>
              <Button
                className={classes.iconArrow}
                disabled={benefit.ordem === 1}
                onClick={() => {
                  benefitUp && benefitUp(benefit);
                }}
              >
                <ArrowUpwardIcon />
              </Button>
              <Button
                className={classes.iconArrow}
                disabled={benefit.ordem === lengthBenefits}
                onClick={() => benefitDown && benefitDown(benefit)}
              >
                <ArrowDownwardIcon />
              </Button>
            </>
          )
        )}
      </ButtonGroup>

      <List>
        <MobileContainer style={{ marginBottom: 0, margin: 15 }}>
          {loading ? (
            <>
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
            </>
          ) : (
            benefit && (
              <>
                <Action className={classes.actionButton}>
                  <IconButton
                    onClick={() => {
                      handleOpenModalDelete && handleOpenModalDelete();
                      setBenefitSelected &&
                        benefit &&
                        setBenefitSelected(benefit);
                    }}
                    className={classes.icon}
                    disabled={loading}
                  >
                    <RiDeleteBinLine />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setShowBenefitEdition && setShowBenefitEdition(true);
                      setBenefitSelected &&
                        benefit &&
                        setBenefitSelected(benefit);
                    }}
                    className={classes.icon}
                    disabled={loading}
                  >
                    <FiEdit />
                  </IconButton>
                </Action>
                <MobileData>
                  <MobileData>
                    <MobileDataLabel>Título</MobileDataLabel>
                    <MobileTextName>{benefit.titulo}</MobileTextName>
                  </MobileData>
                </MobileData>
              </>
            )
          )}
        </MobileContainer>
      </List>
    </ContainerItem>
  );
}

export default MobileItem;
