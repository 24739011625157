import {
  MobileContainer,
  MobileData,
  MobileDataLabel,
} from 'components/StyledComponents';
import { Skeleton } from '@mui/material';
import {
  GroupData,
  MobileDataText,
  MobileDataTextName,
} from 'components/MobileList';
import { ItemProps } from '../Item';

function MobileItem({ utm, loading }: ItemProps) {
  return (
    <MobileContainer>
      {loading ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        utm && (
          <>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Mídia</MobileDataLabel>
                <MobileDataTextName>{utm.source}</MobileDataTextName>
              </MobileData>
            </GroupData>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Pedidos</MobileDataLabel>
                <MobileDataText>{utm.pedidos}</MobileDataText>
              </MobileData>
              <MobileData>
                <MobileDataLabel>Ativados</MobileDataLabel>
                <MobileDataText>{utm.quantidade}</MobileDataText>
              </MobileData>
            </GroupData>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Conversão</MobileDataLabel>
                <MobileDataText>{utm.conversao}</MobileDataText>
              </MobileData>
              <MobileData>
                <MobileDataLabel>Participação</MobileDataLabel>
                <MobileDataText>{utm.porcentagem}</MobileDataText>
              </MobileData>
            </GroupData>
          </>
        )
      )}
    </MobileContainer>
  );
}

export default MobileItem;
