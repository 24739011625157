import { RiDeleteBinLine } from 'react-icons/ri';
import DotStatus from '../../components/DotStatus';
import { BenefitsItemAction, BenefitsItemList } from './styles';

export type BenefitsProps = {
  descricao?: string;
  onRemoveItem?: () => void;
};

function BenefitsListItem({ descricao, onRemoveItem }: BenefitsProps) {
  return (
    <BenefitsItemList>
      <div className="status">
        <DotStatus active />
      </div>
      <p className="text description">{descricao}</p>
      <div className="actions">
        <BenefitsItemAction onClick={onRemoveItem}>
          <RiDeleteBinLine style={{ width: 24, height: 24 }} />
        </BenefitsItemAction>
      </div>
    </BenefitsItemList>
  );
}

export default BenefitsListItem;
