import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 20px;
`;

export const FilterMargin = styled.div`
  margin: 0 15px;

  .buttonUF {
    width: 150px;
    margin-right: 16px;
  }

  @media (max-width: 1023px) {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    align-self: center;

    .buttonUF {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 16px;
    }
  }
`;
