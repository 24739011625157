import { IconButton, Skeleton } from '@mui/material';
import { Container, Data, Divider } from 'components/StyledComponents';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { hour as hourFormatter, date as dateFormatter } from 'util/formatter';
import { useCallback, useState } from 'react';
import { ItemProps } from '../types';
import {
  DataInfo,
  DataName,
  DataShortText,
  DataText,
  DataTextName,
} from './styles';
import PopperActions from '../PopperActions';

function Item({ data, loading, updateSales }: ItemProps) {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
    setOpen(previousOpen => !previousOpen);
  }, []);

  const handleClickAway = () => {
    setOpen(false);
  };
  const canBeOpen = open && Boolean(anchorElement);
  const id = canBeOpen ? `popperAction_${data?.id}` : undefined;
  return (
    <Container>
      <Data>
        {loading ? (
          <DataInfo>
            <DataText>
              <Skeleton width={90} />
            </DataText>
            <DataText>
              <Skeleton width={100} />
            </DataText>
            <DataText style={{ marginRight: 70 }}>
              <Skeleton width={100} />
            </DataText>
            <DataText>
              <Skeleton width={250} />
            </DataText>
            <DataText />
          </DataInfo>
        ) : (
          data && (
            <DataInfo>
              <DataShortText>{hourFormatter(data.created_at)}</DataShortText>
              <DataText>{dateFormatter(data.created_at)}</DataText>
              <DataText style={{ marginRight: 70 }}>{data.tipo}</DataText>
              <DataTextName>
                <DataName>{data.erro}</DataName>
              </DataTextName>
              <DataText />
            </DataInfo>
          )
        )}
        <IconButton onClick={handleClick} disabled={loading}>
          <MoreVertIcon />
        </IconButton>
        {data && updateSales && (
          <PopperActions
            id={id || ''}
            open={open}
            saleId={data.id || 0}
            anchorElement={anchorElement}
            handleClick={handleClick}
            handleClickAway={handleClickAway}
            updateSales={updateSales}
          />
        )}
      </Data>
      <Divider />
    </Container>
  );
}

export default Item;
