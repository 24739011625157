import { CardContent, CardHeader } from '@mui/material';
import useBreakpoint from 'hooks/useBreakpoint';
import { List } from 'components/StyledComponents';
import { User } from 'models/user';
import { Header, SubHeader } from './styles';
import Item from './Item';

import UserListProps from './types';
import MobileItem from './MobileItem';
import FormAddUser from '../FormAddUser';
import Filters from '../Filters';

interface BodyProps {
  users: User[];
  isMobile: boolean;
  currentPage: number;
}

interface LoadingListProps {
  isMobile: boolean;
}

function LoadingList({ isMobile }: LoadingListProps) {
  if (isMobile) {
    return (
      <>
        <MobileItem loading />
        <MobileItem loading />
        <MobileItem loading />
        <MobileItem loading />
      </>
    );
  }
  return (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({ users, isMobile, currentPage }: BodyProps) {
  return (
    <List>
      {users.length > 0 ? (
        users.map(item =>
          isMobile ? (
            <MobileItem
              key={String(item.id)}
              user={item}
              currentPage={currentPage}
            />
          ) : (
            <Item key={String(item.id)} user={item} currentPage={currentPage} />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhum usuário</h3>
      )}
    </List>
  );
}

function UserList({ users, currentPage, setSearch }: UserListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  return (
    <>
      {!isTableOrLess && <FormAddUser currentPage={currentPage} />}
      <SubHeader isDesktop={!isTableOrLess}>
        <CardHeader title="Listagem de Usuários" />
        <Filters setSearch={setSearch} />
      </SubHeader>
      <CardContent>
        {!isTableOrLess && (
          <Header>
            <span style={{ minWidth: '180px', width: '200px' }}>Nome</span>
            <span style={{ minWidth: '180px', width: '200px' }}>Email</span>
            <span>Função</span>
            <span>Ultimo Acesso</span>
            <span>Status</span>
          </Header>
        )}
        {users ? (
          <Body
            users={users}
            isMobile={isTableOrLess}
            currentPage={currentPage}
          />
        ) : (
          <LoadingList isMobile={isTableOrLess} />
        )}
      </CardContent>
    </>
  );
}

export default UserList;
