import styled from 'styled-components';

interface ContainerSelectionActionsProps {
  isMobile: boolean;
}

export const ContainerSelectionActions = styled.div<ContainerSelectionActionsProps>`
  display: flex;
  gap: ${props => (props.isMobile ? 8 : 16)}px;
  justify-content: ${props => (props.isMobile ? 'space-between' : 'end')};
  align-items: end;
  align-content: end;
`;
