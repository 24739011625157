import { useEffect, useState } from 'react';
import { Card, Header } from 'components/StyledComponents';
import { Button, CardContent, CardHeader } from '@mui/material';
import { BenefitsByTypeFetch } from 'fetches/benefitFetch';
import useBreakpoint from 'hooks/useBreakpoint';
import { useParams } from 'react-router-dom';
import { PlanByIdFetch } from 'fetches/planByIdFetch';
import { usePlanStore } from 'store/planStore';
import { useBenefitStore } from 'store/benefitStore';
import { Benefit } from 'models/benefits';
import { SubHeader } from '../ManagerPlan/EditPlan/styles';
import BenefitsList from './BenefitsList';
import { Actions } from './styles';
import AttachBenefit from './AttachBenefit';
import Filters from './Filters';
import EditBenefit from './BenefitsList/EditBenefit';

function ManagerBenefits() {
  const params = useParams();
  const { id } = params;

  const [benefitSelected, setBenefitSelected] = useState<Benefit>();
  const [searchPlanID, setSearchPlanID] = useState('');
  const [showAttachBenefit, setShowAttachBenefit] = useState(false);

  const setShowBenefitEdit = useBenefitStore(state => state.setShowBenefitEdit);

  const { data: plan, mutate } = PlanByIdFetch(parseInt(id || '0', 10));
  const { data: benefits, mutateBenefit } = BenefitsByTypeFetch({
    searchPlanID,
  });

  const setPlanStore = usePlanStore(state => state.setPlan);

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  useEffect(() => {
    if (!plan) return;

    setPlanStore(plan);
  }, [plan, setPlanStore]);

  useEffect(() => {
    if (id) {
      setSearchPlanID(id);
    }
  }, [id]);

  return (
    <Card>
      {id ? (
        <>
          <Header>
            <CardHeader
              title="Gestão de planos"
              subheader="Painel de informações dos planos e ofertas TIM."
            />
            {isTableOrLess && (
              <AttachBenefit
                plan_id={parseInt(id, 10)}
                mutatePlan={mutate}
                showAttachBenefit={showAttachBenefit}
                setShowAttachBenefit={setShowAttachBenefit}
                closeAttachDialog={() => {
                  setShowAttachBenefit(false);
                  mutateBenefit();
                }}
              />
            )}
          </Header>
          <SubHeader style={{ padding: '16px 16px 0px 16px' }}>
            Editar benefícios
          </SubHeader>
        </>
      ) : (
        <>
          <Header>
            <CardHeader
              title="Gestão de benefícios"
              subheader="Painel de informações dos benefícios da TIM."
            />
            {isTableOrLess && (
              <AttachBenefit
                showAttachBenefit={showAttachBenefit}
                setShowAttachBenefit={setShowAttachBenefit}
                closeAttachDialog={() => {
                  setShowAttachBenefit(false);
                  mutateBenefit();
                }}
              />
            )}
          </Header>
          <Header>
            <SubHeader style={{ padding: '16px 16px 0px 16px' }}>
              Listagem de benefícios
            </SubHeader>
            <Filters setSearchPlanID={setSearchPlanID} />
          </Header>
        </>
      )}

      <CardContent style={{ padding: '0px 16px 16px 16px' }}>
        {id
          ? benefits && (
              <BenefitsList
                plan_id={parseInt(id, 10)}
                benefits={benefits}
                updateBenefits={mutateBenefit}
                setBenefitSelected={setBenefitSelected}
              />
            )
          : benefits && (
              <BenefitsList
                benefits={benefits}
                updateBenefits={mutateBenefit}
                setBenefitSelected={setBenefitSelected}
              />
            )}
      </CardContent>

      <Actions>
        <Button
          onClick={() => window.history.back()}
          type="button"
          variant="outlined"
        >
          Voltar
        </Button>

        {!isTableOrLess &&
          (id ? (
            <AttachBenefit
              plan_id={parseInt(id, 10)}
              showAttachBenefit={showAttachBenefit}
              setShowAttachBenefit={setShowAttachBenefit}
              closeAttachDialog={() => {
                setShowAttachBenefit(false);
                mutateBenefit();
              }}
            />
          ) : (
            <AttachBenefit
              showAttachBenefit={showAttachBenefit}
              setShowAttachBenefit={setShowAttachBenefit}
              closeAttachDialog={() => {
                setShowAttachBenefit(false);
                mutateBenefit();
              }}
            />
          ))}
      </Actions>
      {benefitSelected && (
        <EditBenefit
          benefit={benefitSelected}
          closeDialog={() => {
            setBenefitSelected(undefined);
            setShowBenefitEdit(false);
            mutateBenefit();
          }}
        />
      )}
    </Card>
  );
}

export default ManagerBenefits;
