import { Typography } from '@mui/material';
import { IntegrationError } from 'models/integrationErrors';
import useBreakpoint from 'hooks/useBreakpoint';
import { Header } from './styles';
import DesktopBody from './DesktopBody';
import MobileBody from './MobileBody';

interface IntegrationErrorsListProps {
  integrationErrors?: IntegrationError[];
  setIntegrationErrorIdSelected(integrationErrorIdSelected: number): void;
  setOpenDrawer(openDrawer: boolean): void;
}

function IntegrationErrorsList({
  integrationErrors,
  setIntegrationErrorIdSelected,
  setOpenDrawer,
}: IntegrationErrorsListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <>
      {!isTableOrLess && (
        <Header>
          <Typography fontSize={14} color="gray">
            Criado
          </Typography>
          <Typography fontSize={14} color="gray">
            Tipo
          </Typography>
          <Typography fontSize={14} color="gray">
            Mensagem
          </Typography>
        </Header>
      )}

      {(!isTableOrLess && (
        <DesktopBody
          integrationErrors={integrationErrors}
          setOpenDrawer={setOpenDrawer}
          setIntegrationErrorIdSelected={setIntegrationErrorIdSelected}
        />
      )) || (
        <MobileBody
          integrationErrors={integrationErrors}
          setOpenDrawer={setOpenDrawer}
          setIntegrationErrorIdSelected={setIntegrationErrorIdSelected}
        />
      )}
    </>
  );
}

export default IntegrationErrorsList;
