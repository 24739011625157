import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 15px;

  .selectSegment {
    width: 200px;
  }

  .selectPlan {
    width: 350px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    .selectSegment {
      width: 100%;
    }

    .selectPlan {
      width: 100%;
    }
  }
`;
