import { CardHeader } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { FraudKeepersFetch } from 'fetches/fraudKeepersFetch';
import Pagination from 'components/Pagination';
import { Card } from 'components/StyledComponents';
import { Content } from 'components/CardList';
import Filters from './Filters';
import { FraudKeeperPagination } from './style';
import FraudKeeperList from './FraudKeeperList';

function FraudKeeper() {
  const [currentPage, setCurrentPage] = useState(1);

  const [reason, setReason] = useState('todos');

  const { data: response } = FraudKeepersFetch(currentPage, reason);

  useEffect(() => {
    setCurrentPage(1);
  }, []);

  return (
    <Card>
      <CardHeader
        title="Curadoria de Fraude"
        subheader="Painel de curadoria de fraude em pedidos contestáveis"
      />
      <Filters reason={reason} setReason={setReason} />
      <Content>
        <FraudKeeperList
          fraudKeepers={response?.fraudKeepers}
          loading={!response}
        />
        <FraudKeeperPagination>
          {response && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              lastPage={response?.paginationInfo?.lastPage}
              perPage={response?.paginationInfo?.perPage}
              total={response?.paginationInfo?.total}
            />
          )}
        </FraudKeeperPagination>
      </Content>
    </Card>
  );
}

export default FraudKeeper;
