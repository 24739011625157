import React, { useCallback, useEffect, useState } from 'react';

import { Divider, Container, Data } from 'components/StyledComponents';
import Skeleton from '@mui/material/Skeleton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import { Plan } from 'models/plans';
import { DataInfo, DataText, DataTextName } from './styles';
import PopperActions from '../PopperActions';
import FlagToggle from '../FlagToggle';

interface ItemProps {
  data?: Plan;
  loading?: boolean;
  updatePlan?(data: Plan): void;
}

function Item({ data, loading = false, updatePlan }: ItemProps) {
  const [status, setStatus] = useState(!!data?.ativo);
  const [showEcommerce, setShowEcommerce] = useState(!!data?.exibir_ecommerce);
  const [lockSync, setLockSync] = useState(!!data?.travar_sincronia);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  useEffect(() => {
    setStatus(!!data?.ativo);
    setShowEcommerce(!!data?.exibir_ecommerce);
    setLockSync(!!data?.travar_sincronia);
  }, [data, updatePlan]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElement(anchorElement ? null : event.currentTarget);
    },
    [anchorElement],
  );

  const open = Boolean(anchorElement);
  const id = open ? `popperAction_${data?.id}` : undefined;

  return (
    <Container>
      <Data>
        <DataInfo>
          <DataText>
            {loading ? <Skeleton width={140} /> : data?.tim_id}
          </DataText>
          <DataTextName>
            {loading ? <Skeleton width={140} /> : data?.nome}
          </DataTextName>
          <DataText>{loading ? <Skeleton width={140} /> : data?.gb}</DataText>
          <DataText>
            {loading ? <Skeleton width={140} /> : data?.segmento}
          </DataText>
          {loading ? (
            <Skeleton width={80} />
          ) : (
            <DataText>
              {data && (
                <FlagToggle
                  data={data}
                  toggleValue="exibir_ecommerce"
                  toggleName="Exibir ecommerce"
                  toggleState={showEcommerce}
                  setToggleState={setShowEcommerce}
                  updatePlan={updatePlan}
                />
              )}
            </DataText>
          )}
          {loading ? (
            <Skeleton width={80} />
          ) : (
            <DataText>
              {data && (
                <FlagToggle
                  data={data}
                  toggleValue="travar_sincronia"
                  toggleName="Travar sincronia"
                  toggleState={lockSync}
                  setToggleState={setLockSync}
                  updatePlan={updatePlan}
                />
              )}
            </DataText>
          )}
          {loading ? (
            <Skeleton width={80} />
          ) : (
            data && (
              <FlagToggle
                data={data}
                toggleValue="ativo"
                toggleName="Status"
                toggleState={status}
                setToggleState={setStatus}
                updatePlan={updatePlan}
              />
            )
          )}
        </DataInfo>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        {data && (
          <PopperActions
            id={id || ''}
            open={open}
            planId={data.id || 0}
            anchorElement={anchorElement}
            handleClick={handleClick}
          />
        )}
      </Data>
      <Divider />
    </Container>
  );
}

export default Item;
