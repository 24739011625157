import { useState } from 'react';
import { Button, FormControl, TextField } from '@mui/material';

import api from 'services/api';
import { useToast } from 'hooks/toast';
import { Setup } from 'models/setup';
import { TotalizatorLeadsPerBatchFetch } from 'fetches/totalizatorLeadsPerBatchFetch';
import { SaveButton } from './styles';

interface NormalFormProps {
  setup: Setup;
  closeDrawer(): void;
}

function NormalForm({ setup, closeDrawer }: NormalFormProps) {
  const [setupValue, setSetupValue] = useState(setup.value);

  const isNumeroDeLeadsPorLote = setup.key === 'NUMERO_DE_LEADS_POR_LOTE';

  const { mutateTotalLeads } = isNumeroDeLeadsPorLote
    ? TotalizatorLeadsPerBatchFetch()
    : { mutateTotalLeads: null };

  const { addToast } = useToast();

  const handleUpdateTotalLeads = () => {
    if (isNumeroDeLeadsPorLote && mutateTotalLeads) {
      mutateTotalLeads();
    }
  };

  const handleSave = () => {
    api
      .put(`api/setups/${setup.key}`, {
        value: setupValue,
      })
      .then(() => {
        addToast({
          type: 'success',
          title: setup.title,
          description: 'Setup salvo com sucesso',
        });

        closeDrawer();
      })
      .catch(err => {
        let message = err.data.errors[0]?.message;
        if (!message) {
          message = err.data.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro!',
            description: message,
          });
      })
      .finally(() => {
        handleUpdateTotalLeads();
      });
  };

  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      style={{ paddingBottom: 30 }}
    >
      <TextField
        label={setup.type === 'number' ? 'Digite o número' : 'Digite o valor'}
        variant="outlined"
        value={setupValue}
        onChange={e => setSetupValue(e.target.value)}
        type={setup.type === 'number' ? 'number' : 'text'}
      />

      <SaveButton>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          Salvar
        </Button>
      </SaveButton>
    </FormControl>
  );
}

export default NormalForm;
