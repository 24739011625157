import {
  Button,
  Card,
  DialogActions,
  HelperText,
  HelperTextButton,
  Item,
  ItemButtons,
  LabelTotal,
  PageContent,
  Total,
} from 'pages/UtmDefinition/styles';
import palette from 'theme/palette';
import {
  CardHeader,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  SwipeableDrawer,
  TextField,
  Typography,
} from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/small/vector.svg';
import { useUtmDefinition } from 'pages/UtmDefinition/hooks/useUtmDefinition';
import { ButtonProgress } from 'components/StyledComponents';
import SourcesList from '../SourcesList';
import EditCampaign from '../EditCampaign';

function NewUtmConfiguration() {
  const {
    configurationUtm,
    nameConfiguration,
    sourceName,
    sourceIdSelected,
    showDialog,
    isFormValid,
    total,
    showAddSource,
    showDrawerToEditCampaign,
    loading,
    setShowDrawerToEditCampaign,
    setNameConfiguration,
    setSourceIdSelected,
    setSourceName,
    setShowAddSource,
    setShowDialog,
    handlePercentChange,
    handleOpenDialog,
    handleCreateNewSource,
    handleResetNewConfig,
    handleDeleteSource,
    navigate,
    handleSaveDistribution,
  } = useUtmDefinition({});

  return (
    <>
      <CardHeader title="Nova configuração" />
      <PageContent>
        <TextField
          fullWidth
          label="Nome da configuração"
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          value={nameConfiguration}
          onChange={e => setNameConfiguration(e.target.value)}
          placeholder="Digite aqui"
        />
        <Card>
          {(configurationUtm.sources[0].id !== null &&
            configurationUtm.sources.length && (
              <SourcesList
                setShowDrawerToEditCampaign={setShowDrawerToEditCampaign}
                handlePercentChange={handlePercentChange}
                setSourceIdSelected={setSourceIdSelected}
                handleOpenDialog={handleOpenDialog}
              />
            )) || <Typography> Nenhuma source cadastrada</Typography>}

          {showAddSource && (
            <Item>
              <TextField
                fullWidth
                label="Nome"
                size="small"
                onChange={e => setSourceName(e.target.value)}
              />
              <Button
                disabled={!sourceName}
                variant="contained"
                onClick={() => handleCreateNewSource()}
              >
                Adicionar
              </Button>
              <IconButton onClick={() => setShowAddSource(!showAddSource)}>
                <CloseIcon stroke="red" />
              </IconButton>
            </Item>
          )}

          <Divider />

          <ItemButtons>
            <Total>
              <LabelTotal>Total</LabelTotal>
              <Typography
                color={
                  !isFormValid ? palette.error.main : palette.status.new.color
                }
              >{`${total}%`}</Typography>
            </Total>

            <Button
              disabled={showAddSource}
              onClick={() => setShowAddSource(!showAddSource)}
              variant="outlined"
            >
              Nova source
            </Button>
          </ItemButtons>
        </Card>
        <ItemButtons>
          <Button
            variant="outlined"
            color="error"
            onClick={() => handleResetNewConfig()}
          >
            Redefinir
          </Button>

          <HelperTextButton>
            {!isFormValid && (
              <HelperText>Obrigatório atingir 100% para salvar</HelperText>
            )}
            <Button
              disabled={!isFormValid || loading}
              variant="contained"
              onClick={() => handleSaveDistribution()}
            >
              Salvar
              {loading && <ButtonProgress size={24} />}
            </Button>
          </HelperTextButton>
        </ItemButtons>
        <ItemButtons>
          <Button
            variant="outlined"
            onClick={() => {
              navigate('/definicao-utm');
            }}
          >
            Voltar
          </Button>
        </ItemButtons>
      </PageContent>
      <SwipeableDrawer
        anchor="right"
        onClose={() => {
          setShowDrawerToEditCampaign(false);
          setSourceIdSelected(null);
        }}
        onOpen={() => setShowDrawerToEditCampaign(true)}
        open={showDrawerToEditCampaign}
      >
        {sourceIdSelected && (
          <EditCampaign
            sourceId={sourceIdSelected}
            setShowDrawerToEditCampaign={setShowDrawerToEditCampaign}
          />
        )}
      </SwipeableDrawer>
      <Dialog
        open={showDialog}
        keepMounted
        onClose={() => setShowDialog(false)}
      >
        <DialogTitle>Você deseja mesmo remover esta source?</DialogTitle>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setShowDialog(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setShowDialog(false);
              handleDeleteSource();
            }}
          >
            Remover
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NewUtmConfiguration;
