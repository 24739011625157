import userSWR from 'swr';
import api from 'services/api';
import { Permission } from 'models/permission';

export const PermissionsFetch = () => {
  const { data } = userSWR<Permission[]>(
    'permission',
    async (): Promise<Permission[]> => {
      const { data: permissions } = await api.get('/core/permissoes');

      return permissions as Permission[];
    },
  );
  return { data };
};
