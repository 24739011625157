import { MTheme } from 'models/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: MTheme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: 113,
    borderRadius: 6,
    border: '1px solid #F2F2F2',
    boxShadow: '2px 2px 8px 0px #00000033',

    [theme.breakpoints.down(1023)]: {
      height: 68,
    },
  },
  content: {
    display: 'flex',
    width: '100%',
  },
  contentTotal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    padding: '15px',

    [theme.breakpoints.down(1023)]: {
      flexDirection: 'row',
      height: '100%',
      padding: '0 15px',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  boxTotal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgba(5, 12, 39, 0.03)',
    width: 54,
    padding: '15px 18px',
    height: '100%',

    [theme.breakpoints.down(1023)]: {
      justifyContent: 'center',
      padding: '0px',
    },
  },
  title: {
    fontWeight: 400,
  },
  subtitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  h3: {
    fontWeight: 700,
  },
}));

export default useStyles;
