// import { Sale } from 'pages/FraudKeeper/FraudDetail/InfoSale/types';
import create from 'zustand';
import ReactDOM from 'react-dom';

export interface Sale {
  id: number;
  tipo?: string;
  msisdn?: string;
  ddd?: string;
  iccid?: string;
  provisorio?: string;
  valor?: number;
  motivo_cancelamento?: string;
  status?: string;
  forma_pagamento?: string;
  vencimento?: string;
  data_portabilidade: string;
  cep?: string;
  tipo_identificador?: string;
  quadra?: string;
  lote?: string;
  tipo_logradouro?: string;
  numero?: string;
  complemento?: string;
  customer: {
    id: number;
    cpf: string;
    nome?: string;
    filiacao?: string;
    nascimento?: string;
    email?: string;
    telefone?: string;
  };
  plan: {
    id: number;
    segmento?: string;
    nome?: string;
  };
  loyalty?: {
    id: number;
    descricao: string;
  };
}

type SaleDrawerStore = {
  saleDrawer: Sale;
  setSaleDrawer(saleDrawer: Sale): void;
};

export const useSaleDrawer = create<SaleDrawerStore>(set => ({
  saleDrawer: {} as Sale,
  setSaleDrawer(saleDrawer: Sale) {
    ReactDOM.unstable_batchedUpdates(() =>
      set(state => ({ ...state, saleDrawer })),
    );
  },
}));
