import React, { useCallback, useEffect, useState } from 'react';
import { CardHeader, SwipeableDrawer } from '@mui/material';
import { useSaleSelected } from 'store/saleSelected';
import api from 'services/api';

import useBreakpoint from 'hooks/useBreakpoint';
import Pagination from 'components/Pagination';
import { Card } from 'components/StyledComponents';
import { TractableSalesFetch } from 'fetches/tractableSalesFetch';
import { Content } from 'components/CardList';
import { Sale } from 'models/tractableSales';
import { Header, SalesPagination } from '../Sale/style';

import { Sale as ISale } from '../Sale/InfoSale/types';
import ErrorTreatmentList from './ErrorTreatmentList';
import Filters from './Filters';
import InfoSale from './InfoSale';

function ErrorTreatment() {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const saleSelected = useSaleSelected(state => state.saleSelected);
  const setSalesSelected = useSaleSelected(state => state.setSalesSelected);

  const [showInfoSale, setShowInfoSale] = useState(false);
  const [sale, setSale] = useState<ISale>();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [treatmentReason, setTreatmentReason] = useState('');

  const { data: response, mutate } = TractableSalesFetch({
    page: currentPage,
    search,
    treatmentReason,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  useEffect(() => {
    setShowInfoSale(!!saleSelected);
    if (saleSelected) {
      setSale(undefined);
      api.get(`/core/vendas/${saleSelected}`).then(({ data }) => {
        setSale(data);
      });
    }
  }, [saleSelected]);

  const closeDrawer = useCallback(() => {
    setSalesSelected(0);
    setShowInfoSale(false);
  }, [setSalesSelected]);

  const removeSale = useCallback(() => {
    if (!response) {
      return;
    }

    const newListSale = response?.sales.filter(
      (item: Sale) => item.id !== sale?.id,
    );
    const newResponse = { ...response, ...{ sales: newListSale } };
    mutate(newResponse, true);
  }, [mutate, response, sale]);

  const updateSelectedSale = (newSale: ISale) => {
    if (!response) {
      return;
    }

    const newListSale = response?.sales.map((item: Sale) => {
      if (item.id === newSale.id) {
        return {
          ...item,
          msisdn: newSale.msisdn ? newSale.msisdn : item.msisdn,
          nome: newSale.customer.nome ? newSale.customer.nome : item.nome,
        };
      }
      return item;
    });
    const newResponse = { ...response, ...{ sales: newListSale } };
    mutate(newResponse, false);
  };

  return (
    <Card>
      <Header isDesktop={!isTableOrLess}>
        <CardHeader
          title="Tratamento de Erros"
          subheader="Pedidos pendentes para tratamento de erros"
          style={{ padding: 20 }}
        />

        <Filters
          setSearch={setSearch}
          treatmentReason={treatmentReason}
          setTreatmentReason={setTreatmentReason}
        />
      </Header>
      <Content>
        <ErrorTreatmentList sales={response?.sales} />
        <SalesPagination>
          {response && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              lastPage={response?.paginationInfo.lastPage}
              perPage={response?.paginationInfo.perPage}
              total={response?.paginationInfo.total}
            />
          )}
        </SalesPagination>
      </Content>
      <SwipeableDrawer
        anchor="right"
        open={showInfoSale}
        onOpen={() => setShowInfoSale(true)}
        onClose={() => {
          closeDrawer();
        }}
      >
        <InfoSale
          sale={sale}
          setSale={setSale}
          closeDrawer={closeDrawer}
          removeSale={removeSale}
          updateSelectedSale={updateSelectedSale}
          updateReprocessSale={mutate}
        />
      </SwipeableDrawer>
    </Card>
  );
}

export default ErrorTreatment;
