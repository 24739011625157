import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useSaleSelected } from 'store/saleSelected';
import {
  cell as cellFormatter,
  cpf as cpfFormatter,
  dateTime as dateFormatter,
} from 'util/formatter';

import copyIcon from 'assets/icons/small/copy.svg';
import copyToClipboard from 'util/copyToClipboard';
import {
  Container,
  Data,
  DataTextName,
  Divider,
} from 'components/StyledComponents';
import { IconButton, Skeleton } from '@mui/material';
import StatusSale from 'components/StatusSale';
import { DataInfo, DataText } from './style';

export interface ItemProps {
  loading?: boolean;
  data?: {
    id: number;
    segmento: string;
    status: string;
    created_at: string;
    cpf: string;
    nome: string;
    msisdn: string;
    uf: string;
    data_ativacao: string;
  };
  ordem?: string;
}

function Item({ data, loading = false, ordem }: ItemProps) {
  const setSalesSelected = useSaleSelected(state => state.setSalesSelected);

  return (
    <Container onClick={() => data && setSalesSelected(data.id)}>
      <Data>
        {loading ? (
          <>
            <DataText style={{ minWidth: 320, marginLeft: 30 }}>
              <Skeleton width={300} />
            </DataText>
            <DataText>
              <Skeleton width={100} />
            </DataText>
            <DataText>
              <Skeleton width={100} />
            </DataText>
            <DataText style={{ width: 60 }}>
              <Skeleton width={60} />
            </DataText>
            <DataText style={{ marginLeft: '-21px', marginRight: 30 }}>
              <Skeleton width={100} />
            </DataText>
          </>
        ) : (
          data && (
            <>
              <StatusSale status={data.status} />
              <DataInfo>
                <DataTextName>{data.nome}</DataTextName>
                <DataText>
                  {cpfFormatter(data.cpf)}
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      copyToClipboard(data.cpf);
                    }}
                    color="inherit"
                  >
                    <img src={copyIcon} alt="copiar" />
                  </IconButton>
                </DataText>

                <DataText>
                  {cellFormatter(data.msisdn)}
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      copyToClipboard(data.msisdn);
                    }}
                    color="inherit"
                  >
                    <img src={copyIcon} alt="copiar" />
                  </IconButton>
                </DataText>
                <DataText style={{ width: 50, minWidth: 45 }}>
                  {data.uf}
                </DataText>
                {ordem === 'ordenar_data_criacao' ? (
                  <DataText>{dateFormatter(data.created_at)}</DataText>
                ) : (
                  <DataText>{dateFormatter(data.data_ativacao)}</DataText>
                )}
              </DataInfo>
              <MoreVertIcon />
            </>
          )
        )}
      </Data>

      <Divider />
    </Container>
  );
}

export default Item;
