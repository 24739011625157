import React from 'react';

import { Outlet } from 'react-router-dom';
import { Backgroud, Container, Content } from './styles';

function AuthLayout() {
  return (
    <Container>
      <Backgroud />
      <Content>
        <Outlet />
      </Content>
    </Container>
  );
}

export default AuthLayout;
