import { MTheme } from 'models/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: MTheme) => ({
  boxTypeIndicator: {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '10px',

    [theme.breakpoints.down(960)]: {
      flexDirection: 'row',
      justifyContent: 'start',
      paddingTop: '0px',
      gap: 20,
    },
  },
  boxCount: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
  },
  typeIndicatorText: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
  },
}));

export default useStyles;
