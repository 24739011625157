import {
  Button,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { ClassNameMap } from '@mui/styles';
import { ButtonProgress, Form } from 'components/StyledComponents';
import { PlanPrice } from 'models/plansPrice';
import { useEffect } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { ConfigurationByIdPlan } from 'models/configurationsPackage';
import { ActionButtons, ButtonsBaseboard } from '../styles';

interface FormOfferLinkProps {
  urlSlug: string;

  setUrlSlug(urlSlug: string): void;

  fidelity: string;

  setFidelity(fidelity: string): void;

  prohibitPaymentMethod: string;

  setProhibitPaymentMethod(prohibitPaymentMethod: string): void;

  segment: string;

  setSegment(segment: string): void;

  idOfferSelected: string;

  setIdOfferSelected(idOfferselected: string): void;

  planSelected: PlanPrice | null;

  setPlanSelected(planSelected: PlanPrice | null): void;

  plans?: PlanPrice[];

  configs?: ConfigurationByIdPlan[];

  classes: ClassNameMap<string>;

  isValid: boolean;

  loading: boolean;

  navigate: NavigateFunction;

  handleRequisitionRequest(): void;
}

function FormOfferLink({
  urlSlug,
  setUrlSlug,
  fidelity,
  setFidelity,
  prohibitPaymentMethod,
  setProhibitPaymentMethod,
  segment,
  setSegment,
  idOfferSelected,
  setIdOfferSelected,
  planSelected,
  setPlanSelected,
  plans,
  configs,
  classes,
  isValid,
  loading,
  navigate,
  handleRequisitionRequest,
}: FormOfferLinkProps) {
  const handleSegmentChange = (event: SelectChangeEvent<string>) => {
    setSegment(event.target.value);
    setPlanSelected(null);
    setIdOfferSelected('');
  };

  useEffect(() => {
    const planPrice = plans?.find(
      plan => plan.preco_id === planSelected?.preco_id,
    );
    if (planPrice) {
      setSegment(planPrice.segmento);
    }
  }, [planSelected, plans, setSegment]);

  return (
    <>
      <CardHeader title="Detalhes do link" className={classes.header} />

      <ActionButtons>
        <Button
          variant="outlined"
          onClick={() => {
            navigate(-1);
          }}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRequisitionRequest}
          disabled={!isValid || loading}
        >
          Salvar
          {loading && <ButtonProgress size={24} />}
        </Button>
      </ActionButtons>

      <Form className={classes.form}>
        <TextField
          fullWidth
          label="URL"
          value={urlSlug}
          onChange={e => {
            setUrlSlug(e.target.value);
          }}
          margin="dense"
          name="url"
          size="small"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Digite aqui"
          InputProps={{
            classes: { input: classes.input },
          }}
          helperText={
            urlSlug
              ? `${process.env.REACT_APP_LINK_ECOMMERCE}/planos/${urlSlug}`
              : ''
          }
        />

        <FormControl className={classes.formControlFidelity}>
          <FormLabel id="fidelidade" className={classes.labelFormFidelity}>
            Fidelidade
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="fidelidade"
            name="row-radio-buttons-group"
            value={fidelity}
            onChange={e => {
              setFidelity(e.target.value);
            }}
          >
            <FormControlLabel
              value="sim"
              control={<Radio />}
              label="Sim"
              className={classes.radioOption}
            />
            <FormControlLabel
              value="nao"
              control={<Radio />}
              label="Não"
              className={classes.radioOption}
            />
          </RadioGroup>
        </FormControl>

        <FormControl className={classes.formControlMethodPayment} margin="none">
          <FormLabel
            id="proibir-forma-pagamento"
            className={classes.labelFormFidelity}
          >
            Proibir troca de forma de pagamento
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="proibir-forma-pagamento"
            name="row-radio-buttons-group"
            value={prohibitPaymentMethod}
            onChange={e => {
              setProhibitPaymentMethod(e.target.value);
            }}
          >
            <FormControlLabel
              value="sim"
              control={<Radio />}
              label="Sim"
              className={classes.radioOption}
            />
            <FormControlLabel
              value="nao"
              control={<Radio />}
              label="Não"
              className={classes.radioOption}
            />
          </RadioGroup>
        </FormControl>

        <FormControl
          variant="outlined"
          margin="none"
          size="small"
          className={classes.selectSegment || ''}
        >
          <InputLabel htmlFor="Segmento" focused shrink>
            Segmento
          </InputLabel>
          <Select
            labelId="segmento"
            id="segmento"
            native
            value={segment || ''}
            input={
              <OutlinedInput id="select-segmento" label="Segmento" notched />
            }
            onChange={e => {
              handleSegmentChange(e);
            }}
          >
            <option value="">Todos</option>
            <option value="CONTROLE">Controle</option>
            <option value="EXPRESS">Express</option>
            <option value="POS_PAGO">Pós Pago</option>
            <option value="POS_EXPRESS">Pós Express</option>
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          margin="none"
          size="small"
          fullWidth
          className={classes.selectSegment || ''}
        >
          <InputLabel htmlFor="Plano" focused shrink>
            Plano
          </InputLabel>
          {plans && (
            <Select
              value={planSelected?.preco_id || ''}
              onChange={e => {
                const selectedValue = e.target.value as string;

                const selectedPlan = plans.find(
                  plan => plan.preco_id === selectedValue,
                );

                setPlanSelected(selectedPlan || null);
              }}
              label="Plano"
              displayEmpty
              input={<OutlinedInput id="plano" label="plano" notched />}
              renderValue={selected => {
                if (selected === '') {
                  return (
                    <span className={classes.selectPlaceholder}>Selecione</span>
                  );
                }

                const filterSelectedPlan = plans.find(
                  plan => plan.preco_id === selected,
                );

                return <span>{filterSelectedPlan?.descricao}</span>;
              }}
            >
              <MenuItem value="" disabled>
                <span className={classes.selectPlaceholder}>Selecione</span>
              </MenuItem>

              {plans.map((plan, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={index} value={plan.preco_id}>
                  {plan.descricao}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>

        <FormControl variant="outlined" margin="none" size="small" fullWidth>
          <InputLabel htmlFor="configuracoes" focused shrink>
            Configurações
          </InputLabel>

          <Select
            value={idOfferSelected || ''}
            onChange={e => setIdOfferSelected(e.target.value as string)}
            label="configuracoes"
            displayEmpty
            input={
              <OutlinedInput id="configuracoes" label="configuracoes" notched />
            }
            renderValue={selected => {
              if (selected === '' || selected === 'NULL') {
                return <span>Oferta Vigente</span>;
              }

              if (!configs?.length) {
                return (
                  <span className={classes.selectPlaceholder}>
                    Não há configurações para esse plano
                  </span>
                );
              }

              const filterSelectedOffer = configs?.find(config => {
                return config.id === Number(selected);
              });

              return <span>{filterSelectedOffer?.nome}</span>;
            }}
            disabled={!planSelected || !configs?.length}
          >
            <MenuItem value="" disabled>
              <span className={classes.selectPlaceholder}> Selecione</span>
            </MenuItem>
            <MenuItem aria-label="vazio" value="NULL">
              <span>Oferta vigente</span>
            </MenuItem>
            {configs &&
              configs.map(config => (
                <MenuItem key={config.id} value={config.id}>
                  {config.nome}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Form>
      <ButtonsBaseboard>
        <Button
          variant="outlined"
          onClick={() => {
            navigate(-1);
          }}
        >
          Voltar
        </Button>
        <Button
          onClick={handleRequisitionRequest}
          variant="contained"
          color="primary"
          disabled={!isValid || loading}
        >
          Salvar
          {loading && <ButtonProgress size={24} />}
        </Button>
      </ButtonsBaseboard>
    </>
  );
}

export default FormOfferLink;
