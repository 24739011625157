import { useSelectFilterStore } from 'store/selectFilterStore';
import { SelectOptions } from '..';

export function useSelects() {
  const segmentosSelecionados = useSelectFilterStore(
    store => store.segmentosSelecionados,
  );
  const setSegmentosSelecionados = useSelectFilterStore(
    store => store.setSegmentosSelecionados,
  );
  const tiposSelecionados = useSelectFilterStore(
    store => store.tiposSelecionados,
  );
  const setTiposSelecionados = useSelectFilterStore(
    store => store.setTiposSelecionados,
  );
  const statusSelecionados = useSelectFilterStore(
    store => store.statusSelecionados,
  );
  const setStatusSelecionados = useSelectFilterStore(
    store => store.setStatusSelecionados,
  );
  const ufsSelecionadas = useSelectFilterStore(store => store.ufsSelecionadas);
  const setUfsSelecionadas = useSelectFilterStore(
    store => store.setUFsSelecionadas,
  );

  const handleCheckType = (selected: SelectOptions) => {
    const typeExist = tiposSelecionados.find(
      item => selected.value === item.value,
    );
    if (typeExist) {
      setTiposSelecionados(
        tiposSelecionados.filter(item => selected.value !== item.value),
      );
      return;
    }
    setTiposSelecionados([...tiposSelecionados, selected]);
  };

  const handleCheckSegment = (selected: SelectOptions) => {
    const segmentExist = segmentosSelecionados.find(
      item => selected.value === item.value,
    );
    if (segmentExist) {
      setSegmentosSelecionados(
        segmentosSelecionados.filter(item => selected.value !== item.value),
      );
      return;
    }
    setSegmentosSelecionados([...segmentosSelecionados, selected]);
  };

  const handleCheckStatus = (selected: SelectOptions) => {
    const statusExist = statusSelecionados.find(
      item => selected.value === item.value,
    );
    if (statusExist) {
      setStatusSelecionados(
        statusSelecionados.filter(item => selected.value !== item.value),
      );
      return;
    }
    setStatusSelecionados([...statusSelecionados, selected]);
  };

  const handleCheckUf = (selected: SelectOptions) => {
    const ufExist = ufsSelecionadas.find(item => selected.value === item.value);
    if (ufExist) {
      setUfsSelecionadas(
        ufsSelecionadas.filter(item => selected.value !== item.value),
      );
      return;
    }
    setUfsSelecionadas([...ufsSelecionadas, selected]);
  };

  return {
    tiposSelecionados,
    segmentosSelecionados,
    statusSelecionados,
    ufsSelecionadas,
    handleCheckType,
    handleCheckSegment,
    handleCheckStatus,
    handleCheckUf,
  };
}
