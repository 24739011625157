import palette from '../palette';

export default function MuiIconButton() {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: palette.icon,
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
          },
        },
      },
    },
  };
}
