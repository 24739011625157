import styled from 'styled-components';

interface HeaderProps {
  isDesktop: boolean;
}

export const Header = styled.div<HeaderProps>`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  flex-direction: ${props => (props.isDesktop ? 'row' : 'column')};
`;
export const Content = styled.div`
  width: 100%;
  padding: 0 30px;
`;
export const SalesPagination = styled.div`
  margin-top: 10px;
`;
