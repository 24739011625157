import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import styled from 'styled-components';

interface BoxProps {
  isMobile: boolean;
  isPossibleEdit: boolean;
}

interface DayProps {
  edited: boolean;
}

export const Box = styled.div<BoxProps>`
  flex-grow: 1;
  cursor: ${props => props.isPossibleEdit && 'pointer'};
  padding: ${props => (props.isMobile ? '9' : '15')}px;
  background-color: ${props =>
    props.isPossibleEdit ? '#FFFFFF' : 'rgba(204, 204, 204, 0.15)'};
  color: ${props => (props.isPossibleEdit ? '#546E7A' : '#CCCCCC')};
  &:hover {
    background-color: ${props => props.isPossibleEdit && '#f5fcff'};
  }
`;

export const Day = styled.p<DayProps>`
  font-size: 16px;
  line-height: 24px;
  color: #546e7a;
  font-weight: 400;

  @media (max-width: 700px) {
    color: ${props => props.edited && '#2381F0'};
  }
`;

export const MetaText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.5);
`;

export const MetaStyleText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

export const MetaEdited = styled(MetaStyleText)`
  color: #2381f0;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down(700)]: {
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      },
    },
    gridItem: {
      display: 'flex',
      flexDirection: 'column',
    },
    gridAreaCheckbox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'end',
    },
  }),
);
