import userSWR from 'swr';
import { endOfDay, format, parseISO, startOfDay } from 'date-fns';
import api from 'services/api';
import { Totalizator } from 'models/reports';

export const TotalizatorFetch = (
  segment: string | undefined,
  start: string,
  end: string,
  reprocessed: boolean,
  inReprocessing: boolean,
  engagement: boolean,
) => {
  const inicio = format(startOfDay(parseISO(start)), 'yyyy-MM-dd HH:mm:ss');
  const fim = format(endOfDay(parseISO(end)), 'yyyy-MM-dd HH:mm:ss');
  const { data, mutate } = userSWR<Totalizator>(
    `totalizatorFetch_${segment}_${inicio}_${fim}_${reprocessed}_${inReprocessing}_${engagement}`,

    async (): Promise<Totalizator> => {
      const params = {
        segmento: segment,
        inicio,
        fim,
        trazer_reprocessados: reprocessed ? 1 : 0,
        trazer_em_processamento: inReprocessing ? 1 : 0,
        trazer_engajadas: engagement ? 1 : 0,
      };
      !segment && delete params.segmento;
      const { data: totalizator } = await api.get(
        `core/relatorios/dashboards/totalizadores`,
        { params },
      );
      return totalizator as Totalizator;
    },
  );
  return { data, mutate };
};
