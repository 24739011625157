import { Benefit } from 'models/benefits';
import { useEffect, useState } from 'react';
import DialogPlan from '../DialogPlan';

interface DialogPlanBenefitProps {
  data?: Benefit[];
  isOpen: boolean;
  onUpdate?: (value: string) => void;
  onClose: () => void;
}

function DialogPlanBenefit({
  data,
  isOpen,
  onUpdate,
  onClose,
}: DialogPlanBenefitProps) {
  const [selectedData, setSelectedData] = useState('');

  useEffect(() => {
    if (!data?.length) return;
    setSelectedData(String(data[0].id));
  }, [data]);

  return (
    <DialogPlan
      title="Anexar Beneficio"
      subtitle="Selecione um beneficio que deseja adicionar ao plano"
      label="Selecione um beneficio"
      isOpen={isOpen}
      onClose={onClose}
      onChange={setSelectedData}
      onUpdate={() => {
        onUpdate?.(selectedData);
      }}
    >
      {data
        ?.sort?.((a, b) => {
          if (!a.descricao || !b.descricao) return 0;

          return a.descricao.localeCompare(b.descricao);
        })
        ?.map(items => (
          <option
            aria-label={items.tipo}
            key={items.id}
            value={String(items.id)}
          >
            {items.descricao} - {items.quantidade}
          </option>
        ))}
    </DialogPlan>
  );
}

export default DialogPlanBenefit;
