import React from 'react';

import { MTheme } from 'models/theme';
import { makeStyles } from '@mui/styles';
import { Container } from './styles';
import MenuItems from './MenuItems';

const useStyles = makeStyles((theme: MTheme) => ({
  drawer: {
    width: 280,
    [theme.breakpoints.up('lg')]: {
      marginTop: 65,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

interface MenuProps {
  open: boolean;
  variant: 'persistent' | 'temporary';
  onClose(): void;
}

function Menu({ open, variant, onClose }: MenuProps) {
  const classes = useStyles();
  return (
    <Container
      anchor="left"
      onClose={onClose}
      open={open}
      variant={variant}
      classes={{ paper: classes.drawer }}
    >
      <div className={classes.root}>
        <MenuItems />
      </div>
    </Container>
  );
}

export default Menu;
