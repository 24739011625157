import { Grid, TextField } from '@mui/material';
import { Hour } from 'models/affiliateGoalByDate';
import { useEffect, useState } from 'react';

interface HoursProps {
  hora: Hour;
  isNotEditHour: boolean;
  onChange(key: string, newValue: string): void;
}

function Hours({ hora, isNotEditHour, onChange }: HoursProps) {
  const [inputValues, setInputValues] = useState<Hour>({});

  useEffect(() => {
    const multipliedValues: Hour = Object.entries(hora).reduce(
      (acc: Hour, [key, value]) => {
        acc[String(key)] = value
          ? Math.round(Number(value) * 100).toString()
          : value;
        return acc;
      },
      {},
    );

    setInputValues(multipliedValues);
  }, [hora]);

  const handleInputChange = (key: string, newValue: string) => {
    setInputValues(prevState => ({
      ...prevState,
      [key]: newValue,
    }));

    onChange(key, newValue);
  };

  return (
    <>
      {Object.entries(inputValues).map(([key, value]) => (
        <Grid item xs={4} key={key}>
          <TextField
            id={`hour-${key}`}
            label={`Hora ${key}`}
            value={value}
            aria-readonly
            fullWidth
            variant="outlined"
            name={key}
            placeholder="%"
            size="small"
            onChange={event => {
              handleInputChange(key, event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={
              isNotEditHour && parseInt(key, 10) < new Date().getHours()
            }
          />
        </Grid>
      ))}
    </>
  );
}

export default Hours;
