import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 14px;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 15px;
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DataLabel = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 5px;
`;

export const DataText = styled.span`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 15px;

  button {
    margin-left: 4px;
    height: 19px;
    border: none;
    background: transparent;
  }
`;
