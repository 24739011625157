import { Card as MuiCard } from '@mui/material';
import styled from 'styled-components';

export const Card = styled(MuiCard)`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
`;

export const Item = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 20px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);

  p {
    :nth-child(1) {
      width: 35%;
    }
    :nth-child(2) {
      width: 35%;
    }
  }
`;

export const MessageActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  p {
    :nth-child(1) {
      width: 100%;
    }
  }
`;
