import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0px 0px 15px 25px;
  color: rgba(0, 0, 0, 0.4);
  span {
    :nth-child(1) {
      width: 150px;
    }

    width: 180px;
    white-space: nowrap;
    font-size: 15px;
    align-self: flex-start;
  }
`;
