import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  padding: 16px;

  .grid-item {
    @media (max-width: 600px) {
      width: 360px;
    }
  }
`;

export const Box = styled.div`
  margin-bottom: 30px;

  @media (max-width: 1023px) {
    margin-top: 30px;
    margin-bottom: 0px;
  }
`;

export const CategoryTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;

  @media (max-width: 1023px) {
    margin-bottom: 15px;
  }
`;

export const CategoryHeader = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 90px 15px 38px;
  color: rgba(0, 0, 0, 0.4);

  span {
    :nth-child(1) {
      width: 100px;
    }
    :nth-child(2) {
      width: 246px;
    }
    :nth-child(4) {
      width: 125px;
    }

    width: 130px;
    white-space: nowrap;
    font-size: 15px;
    align-self: flex-start;
  }
`;

export const NotList = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: #fafafa;
  box-shadow: inset 0px -1px 0px #f2f2f2;
  border-radius: 6px;
  width: 500px;
  margin-top: 50px;

  @media (max-width: 1023px) {
    margin-top: 30px;
    width: 330px;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
    margin: auto;
  }
`;
