import { Divider, Container, Data } from 'components/StyledComponents';
import { Skeleton, IconButton } from '@mui/material';
import { RiDeleteBinLine } from 'react-icons/ri';
import Default from 'assets/icons/benefitIcons/default.svg';
import benefit4GInternet from 'assets/icons/benefitIcons/benefit4GInternet.svg';
import { FiEdit } from 'react-icons/fi';
import { useBenefitStore } from 'store/benefitStore';
import { Benefit } from 'models/benefits';
import Icons from '../icons';
import {
  DataInfo,
  DataTextName,
  IconsComponent,
  WrapperNameInfo,
  DataText,
} from './styles';

export interface ItemProps {
  plan_id?: number;
  data?: Benefit;
  loading?: boolean;
  typeBenefit?: string;
  setBenefitSelected?(value: Benefit): void;
  handleRemove?(id: number): void;
}

function Item({
  plan_id,
  data,
  typeBenefit,
  loading = false,
  setBenefitSelected,
  handleRemove,
}: ItemProps) {
  const setShowBenefitEdit = useBenefitStore(state => state.setShowBenefitEdit);
  const setType = useBenefitStore(state => state.setType);

  return (
    <Container
      onClick={() => {
        setBenefitSelected && data && setBenefitSelected(data);
        setShowBenefitEdit(true);
        typeBenefit && setType(typeBenefit);
      }}
    >
      <Data>
        <IconsComponent>
          {data &&
            (data.descricao === 'Pacote de internet' ? (
              <img src={benefit4GInternet} alt="componente" height={24} />
            ) : (
              <img
                src={data.componente ? Icons[data.componente] : Default}
                alt="componente"
                height={data.componente && 24}
              />
            ))}
        </IconsComponent>

        <DataInfo>
          <WrapperNameInfo>
            <DataTextName>
              {loading ? <Skeleton width={140} /> : data?.descricao}
            </DataTextName>
          </WrapperNameInfo>
          <DataTextName>
            {loading ? <Skeleton width={140} /> : data?.quantidade}
          </DataTextName>
          <DataText />
        </DataInfo>
        <IconButton>
          <FiEdit style={{ width: 18, height: 18, color: ' #000000' }} />
        </IconButton>
        {plan_id && (
          <IconButton
            onClick={e => {
              e.stopPropagation();
              handleRemove && data && handleRemove(data.id);
            }}
          >
            <RiDeleteBinLine
              style={{ width: 18, height: 18, color: ' #000000' }}
            />
          </IconButton>
        )}
      </Data>
      <Divider />
    </Container>
  );
}

export default Item;
