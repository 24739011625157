import React from 'react';

import {
  Container,
  Data,
  DataInfo,
  Divider,
} from 'components/StyledComponents';
import { date as dateFormatter } from 'util/formatter';

import { LinkOutlined } from '@mui/icons-material';
import { IconButton, Skeleton } from '@mui/material';
import { useSimcardStock } from 'store/simcardStock';
import { SIMCardOfBatch } from 'models/simcards';
import { DataText } from './styles';
import Status from '../Status';

export interface ItemProps {
  data?: SIMCardOfBatch;
  loading?: boolean;
}

function Item({ data, loading = false }: ItemProps) {
  const setSimCardSaleselected = useSimcardStock(
    state => state.setSimCardSaleselected,
  );
  const setShowSaleInfo = useSimcardStock(state => state.setShowSaleInfo);

  const showHLR = data?.hlr ? data?.hlr : 'Sem HLR';

  return (
    <Container>
      <Data>
        <DataInfo>
          <DataText>
            {loading ? <Skeleton width={140} /> : data?.iccid}
          </DataText>
          <DataText>{loading ? <Skeleton width={140} /> : showHLR}</DataText>
          <DataText>
            {loading ? (
              <Skeleton width={140} />
            ) : (
              data && <Status status={data.status} />
            )}
          </DataText>
          <DataText>
            {loading ? (
              <Skeleton width={140} />
            ) : (
              dateFormatter(data?.updated_at)
            )}
          </DataText>
          <DataText>
            {data?.venda_id && (
              <IconButton
                onClick={() => {
                  setSimCardSaleselected(data.venda_id);
                  setShowSaleInfo(true);
                }}
              >
                <LinkOutlined />
              </IconButton>
            )}
          </DataText>
        </DataInfo>
      </Data>
      <Divider />
    </Container>
  );
}

export default Item;
