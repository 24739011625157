import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react';
import {
  Checkbox,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { HlrFetch } from 'fetches/hlrFetch';
import useBreakpoint from 'hooks/useBreakpoint';
import { HRL } from 'models/hrl';

export interface RefFunction {
  clearLocalIccid: () => void;
}
interface FiltersProps {
  handleSearchSIMCard(value: string): void;
  setHlrs(value: string): void;
}

function Filters(
  { handleSearchSIMCard, setHlrs }: FiltersProps,
  ref?: Ref<RefFunction>,
) {
  const { data: hlrs } = HlrFetch();
  const [localIccId, setLocalIccid] = useState('');
  const [hlrsSelected, setHlrsSelected] = useState<number[]>([]);

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(960);

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    setHlrsSelected(event.target.value as number[]);
  };
  const clearLocalIccid = () => {
    setLocalIccid('');
  };

  useImperativeHandle(ref, () => ({ clearLocalIccid }));
  return (
    <Grid container spacing={3} justifyContent="space-between">
      <Grid item md={6} xs={12} style={{ paddingTop: 0 }}>
        <TextField
          fullWidth
          label="ICCID Inicial"
          margin="dense"
          name="primeiro_iccid"
          variant="outlined"
          helperText="Ao preencher o ICCID, você será direcionado diretamente para a impressão da etiqueta"
          value={localIccId}
          onChange={e => setLocalIccid(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    handleSearchSIMCard(localIccId);
                  }}
                >
                  <Search style={{ position: 'relative' }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {isTableOrLess ? (
        <div style={{ marginLeft: 13, marginTop: 10 }}>
          <span>Filtre por HLR</span>
        </div>
      ) : (
        <div style={{ marginLeft: 'auto', marginTop: 22 }}>
          <span>Filtre por HLR</span>
        </div>
      )}
      <Grid item md={5} xs={12} style={{ paddingTop: 0 }}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel htmlFor="HLR" focused>
            HLR
          </InputLabel>
          <Select
            fullWidth
            multiple
            label="HLR"
            margin="dense"
            name="hlr"
            variant="outlined"
            value={hlrsSelected}
            onChange={handleChange}
            onClose={() => setHlrs(hlrsSelected.join(','))}
            renderValue={selected => (
              <div>
                {(selected as number[]).map(value => (
                  <Chip
                    key={value}
                    label={value}
                    style={{ margin: 2, height: '25px', fontSize: 12 }}
                    color="secondary"
                  />
                ))}
              </div>
            )}
          >
            {hlrs?.map((item: HRL) => (
              <MenuItem key={item.id} value={item.id}>
                <Checkbox checked={hlrsSelected.indexOf(item.id) > -1} />
                <ListItemText primary={item.hlr} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default forwardRef(Filters);
