import styled from 'styled-components';

export const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 20px;
`;
export const FilterMargin = styled.div`
  margin: 0 15px;

  @media (max-width: 768px) {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    align-self: center;
  }
`;
