import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import palette from 'theme/palette';

export const useStyles = makeStyles((theme: Theme) => ({
  eligibilityResponse: {
    display: 'flex',
    width: '100%',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '15px',
    gap: '10px',
    borderRadius: '6px',
    boxShadow: '0px 2px 12px 0px #0000001A',

    [theme.breakpoints.down(1023)]: {
      width: '100%',
      minHeight: '160px',
    },
  },
  boxResponse: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  downloadButton: {
    alignSelf: 'end',
    marginBottom: '-35px',
  },
  highlighter: {
    width: '100%',
    fontWeight: 500,
    fontSize: 10,
    height: '160px',
  },
  expandedHighlighter: {
    width: '100%',
    fontWeight: 500,
    fontSize: 10,
    height: '380px',
  },
  expandButton: {
    width: '66px',
    height: '19px',
    marginTop: '-18px',
    color: palette.white,
    backgroundColor: '#252A3F',
    '&:hover': {
      backgroundColor: '#252A3F',
    },
  },
}));
