import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  padding: 16px;
`;

export const CategoryHeader = styled.span`
  font-size: 16px;
  line-height: 24px;
`;

export const CategoryItems = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
`;

export const CategoryContainer = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`;
