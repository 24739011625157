interface Attributes {
  label: string;
  color: string;
}

interface ReasonCardSetup {
  endereco: Attributes;
  email: Attributes;
  telefone: Attributes;
  cpf: Attributes;

  [key: string]: Attributes;
}

const reasonCardSetup: ReasonCardSetup = {
  endereco: {
    label: 'Endereço',
    color: 'rgba(48, 174, 201, 0.2)',
  },
  email: {
    label: 'E-mail',
    color: 'rgba(242, 246, 12, 0.39)',
  },
  telefone: {
    label: 'Telefone',
    color: 'rgba(255, 168, 0, 0.31)',
  },
  cpf: {
    label: 'CPF',
    color: 'rgba(201, 48, 85, 0.2)',
  },
  funcionario_tim: {
    label: 'Funcionário TIM',
    color: 'rgba(255, 10, 10, 0.7)',
  },
};

export default reasonCardSetup;
