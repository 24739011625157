import styled from 'styled-components';

export const DataText = styled.span`
  width: 100px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
  font-size: 15px;

  &:hover {
    overflow: visible;
  }
`;

export const DataTextName = styled.span`
  width: 190px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
  font-size: 15px;

  &:hover {
    overflow: visible;
  }
`;
