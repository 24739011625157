import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import styled from 'styled-components';

export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const ButtonsBaseboard = styled(ActionButtons)`
  margin-top: auto;
`;

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '95%',
    },
    header: {
      padding: '15px 0px 15px 0px',
    },
    form: {
      width: '50%',
      [theme.breakpoints.down(1024)]: {
        width: '100%',
      },
    },
    formControlFidelity: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 15,
      marginTop: 15,
      marginBottom: 5,
    },
    formControlMethodPayment: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 15,
      marginBottom: 15,
    },
    labelFormFidelity: {
      fontSize: 12,
    },
    radioOption: {
      '& .MuiFormControlLabel-label': {
        color: 'rgba(117, 117, 117, 1)',
        '& .Mui-checked': {
          color: '#050c27',
        },
      },
    },
    input: {
      '&::placeholder': {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14px',
      },
    },
    selectPlaceholder: {
      color: 'rgba(0, 0, 0, 0.4)',
    },
    selectSegment: {
      marginBottom: 20,
    },
  }),
);
