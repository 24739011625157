import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

export const useStyles = makeStyles((theme: Theme) => ({
  eligibilityData: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '20px',
  },
  eligibilityAllForms: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: '20px',
  },
  span: {
    display: 'flex',
    width: '100%',
  },
  eligibilityForms: {
    display: 'flex',
    width: '100%',
    gap: '20px',
  },
  eligibilityButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',

    [theme.breakpoints.down(1023)]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  dataButtons: {
    display: 'flex',
    gap: '20px',

    [theme.breakpoints.down(1023)]: {
      justifyContent: 'space-between',
    },
  },
}));
