import { Box, CardContent, CardHeader } from '@mui/material';
import { useStyles } from './styles';
import EligibilityData from './components/EligibilityData';
import EligibilityResponse from './components/EligibilityResponse';

function ExecuteEligibility() {
  const classes = useStyles();

  return (
    <Box>
      <CardHeader
        title="Execução de Elegibilidade"
        subheader="Painel de execução de elegibilidade."
      />

      <CardContent className={classes.cardContent}>
        <EligibilityData />
        <EligibilityResponse />
      </CardContent>
    </Box>
  );
}

export default ExecuteEligibility;
