import { useSaleSelected } from 'store/saleSelected';
import { FindInPageOutlined } from '@mui/icons-material';
import {
  cell as cellFormatter,
  cpf as cpfFormatter,
  date as dateFormatter,
} from 'util/formatter';

import copyIcon from 'assets/icons/small/copy.svg';
import { IconButton, Skeleton } from '@mui/material';
import copyToClipboard from 'util/copyToClipboard';
import {
  Container,
  Data,
  DataText,
  Divider,
} from 'components/StyledComponents';
import Icons from 'pages/Sale/SaleList/Item/icons';
import { Sale } from 'models/tractableSales';
import { DataInfo, DataName, DataTextName } from './style';

export interface ItemProps {
  loading?: boolean;
  data?: Sale;
}

function Item({ data, loading = false }: ItemProps) {
  const setSalesSelected = useSaleSelected(state => state.setSalesSelected);

  return (
    <Container onClick={() => data && setSalesSelected(data.id)}>
      <Data>
        {loading ? (
          <>
            <DataText>
              <Skeleton width={250} style={{ marginLeft: 28 }} />
            </DataText>
            <DataText>
              <Skeleton width={150} />
            </DataText>
            <DataText>
              <Skeleton width={150} />
            </DataText>
            <DataText>
              <Skeleton width={150} />
            </DataText>
            <DataText>
              <Skeleton width={150} style={{ marginRight: 95 }} />
            </DataText>
          </>
        ) : (
          data && (
            <>
              <img src={Icons[data.status]} alt="status" />
              <DataInfo>
                <DataName>{data.nome}</DataName>
                <DataText>
                  {cpfFormatter(data.cpf)}
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      copyToClipboard(data.cpf);
                    }}
                    color="inherit"
                  >
                    <img src={copyIcon} alt="copiar" />
                  </IconButton>
                </DataText>

                <DataText>
                  {cellFormatter(data.msisdn)}
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      copyToClipboard(data.msisdn as string);
                    }}
                    color="inherit"
                  >
                    <img src={copyIcon} alt="copiar" />
                  </IconButton>
                </DataText>
                <DataText style={{ minWidth: 115 }}>
                  {dateFormatter(data.created_at)}
                </DataText>
                <DataTextName>
                  {data.motivo_tratamento ? data.motivo_tratamento : '-'}
                </DataTextName>
              </DataInfo>
              <IconButton>
                {loading ? <Skeleton width={20} /> : <FindInPageOutlined />}
              </IconButton>
            </>
          )
        )}
      </Data>
      <Divider />
    </Container>
  );
}

export default Item;
