import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

export const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 15px;
  margin-bottom: 15px;
`;

export const useStyles = makeStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.4)',
  },
  labelSelecionada: {
    color: 'black',
    fontWeight: 'bold',
  },
});
