import React, { useCallback, useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CardHeader,
  DialogActions,
  Fade,
  Modal,
  SwipeableDrawer,
} from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import { useFraud } from 'store/fraudStore';
import { ModalCard, UseStylesModal } from 'components/ModalCard';
import { ButtonProgress } from 'components/StyledComponents';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import useBreakpoint from 'hooks/useBreakpoint';
import { useSaleSelected } from 'store/saleSelected';
import { Card, ReasonBox, ReasonTable, ReasonTableLabel } from './styles';
import { Card as ReasonCard } from '../ReasonCard/styles';
import reasonCardSetup from '../ReasonCard/reasonCardSetup';
import SaleCard from './SaleCard';
import { FraudAnalysis as IFraudAnalysis } from './types';

import MobileSaleCard from './MobileSaleCard';
import SideButtons from './SideButtons';
import MobileSideButtons from './MobileSideButtons';
import InfoSale from './InfoSale';
import { useSaleDrawer } from '../../../store/saleDrawerStore';

function FraudDetail() {
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();

  const fraud = useFraud(state => state.fraud);
  const setFraud = useFraud(state => state.setFraud);
  const [loading, setLoading] = useState(false);
  const [allFilled, setAllFilled] = useState(false);
  const [FraudAnalysis, setFraudAnalysis] = useState<IFraudAnalysis>({});
  const [openModal, setOpenModal] = useState(false);

  const { addToast } = useToast();
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);

  const classesModal = UseStylesModal();

  const saleSelected = useSaleSelected(state => state.saleSelected);
  const setSaleDrawer = useSaleDrawer(state => state.setSaleDrawer);
  const [showInfoSale, setShowInfoSale] = useState(!!saleSelected);
  const setSalesSelected = useSaleSelected(state => state.setSalesSelected);

  const closeDrawer = useCallback(() => {
    setSalesSelected(0);
    setShowInfoSale(false);
  }, [setSalesSelected]);

  useEffect(() => {
    if (saleSelected) {
      api.get(`/core/vendas/${saleSelected}`).then(({ data }) => {
        setSaleDrawer(data);
      });
    }
    setShowInfoSale(!!saleSelected);
  }, [saleSelected, setSaleDrawer]);

  useEffect(() => {
    if (id) {
      api.get(`/core/curadorias/${id}`).then(({ data }) => {
        setFraud(data);
      });
    }
  }, [id, setFraud]);

  useEffect(() => {
    if (Object.keys(FraudAnalysis).length === 0) return;
    const isAllFilled = !Object.values(FraudAnalysis).includes(null);
    setAllFilled(isAllFilled);
  }, [FraudAnalysis]);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleUpdateSales = () => {
    setLoading(true);

    const data = Object.keys(FraudAnalysis).map(saleID => {
      return {
        venda_id: saleID,
        status: FraudAnalysis[saleID],
      };
    });
    handleClose();
    api
      .put('/core/vendas/atualizar-status-em-lote', data)
      .then(() => {
        navigate('/curadoria-fraude');
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Curadorias finalizadas com sucesso',
        });
      })
      .catch(error => {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao atualizar curadoria',
          description: error?.data?.message,
        });
      });
  };

  const handleSetStatusToAll = useCallback(
    (status: string | null) => {
      const selectedStatusBySale =
        fraud?.vendas?.reduce((accumulator, current) => {
          if (current.status === 'ANALISE_FRAUDE')
            accumulator[current.venda_id] = status;
          return accumulator;
        }, {} as IFraudAnalysis) || {};

      setFraudAnalysis(selectedStatusBySale);
    },
    [fraud],
  );

  useEffect(() => {
    if (!fraud?.vendas) {
      return;
    }
    const hasSaleInAnalysis = fraud.vendas.reduce((accumulator, current) => {
      return accumulator || current.status === 'ANALISE_FRAUDE';
    }, false);
    if (!hasSaleInAnalysis) {
      navigate('/curadoria-fraude');
    }
  }, [fraud, navigate]);

  useEffect(() => {
    handleSetStatusToAll(null);
  }, [handleSetStatusToAll]);

  return (
    <Card>
      <CardHeader
        title="Detalhes da contestação"
        subheader="Navegação e análise de contestações"
      />
      <Button
        variant="outlined"
        color="primary"
        style={{ marginLeft: '16px' }}
        onClick={() => navigate(-1)}
      >
        Voltar início
      </Button>
      <CardHeader title="Pedidos realizados" />
      <Card>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ReasonTable>
            <ReasonTableLabel>Motivos</ReasonTableLabel>
            <ReasonBox>
              {fraud?.motivos?.map(motivo => (
                <Box key={motivo} margin="0px 5px 5px 5px">
                  <ReasonCard bgcolor={reasonCardSetup[motivo]?.color}>
                    {reasonCardSetup[motivo]?.label || motivo}
                  </ReasonCard>
                </Box>
              ))}
            </ReasonBox>
          </ReasonTable>
          {isTabletSizeOrSmaller ? (
            <MobileSideButtons handleSetStatusToAll={handleSetStatusToAll} />
          ) : (
            <SideButtons handleSetStatusToAll={handleSetStatusToAll} />
          )}
        </Box>
      </Card>
      <Box margin="30px 16px">
        {isTabletSizeOrSmaller
          ? fraud?.vendas?.map(venda => {
              return (
                <MobileSaleCard
                  sale={venda}
                  key={`venda_${venda.venda_id}`}
                  FraudAnalysis={FraudAnalysis}
                  setFraudAnalysis={setFraudAnalysis}
                />
              );
            })
          : fraud?.vendas?.map(venda => {
              return (
                <SaleCard
                  sale={venda}
                  key={`venda_${venda.venda_id}`}
                  FraudAnalysis={FraudAnalysis}
                  setFraudAnalysis={setFraudAnalysis}
                />
              );
            })}
        <Box display="flex" justifyContent="flex-end" paddingRight="16px">
          <Button
            variant="contained"
            color="primary"
            disabled={!allFilled || loading}
            onClick={handleOpen}
          >
            Definir pedidos
            {loading && <ButtonProgress size={24} />}
          </Button>
        </Box>
      </Box>
      <Modal
        aria-labelledby="tile-modal"
        aria-describedby="modal-description"
        className={classesModal.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={openModal}>
          <ModalCard className={classesModal.paper}>
            <h2 id="title-modal" style={{ textAlign: 'center' }}>
              Deseja mesmo definir essa contestação?
            </h2>
            <p id="modal-description" style={{ textAlign: 'center' }}>
              Depois disso, você não poderá mais voltar a essa contestação.
            </p>
            <DialogActions
              style={{
                justifyContent: 'space-between',
                paddingRight: '24px',
                paddingLeft: '32px',
                paddingTop: '24px',
                paddingBottom: '32px',
              }}
            >
              <Button
                style={{ color: '#C92A2A' }}
                onClick={() => setOpenModal(false)}
              >
                Voltar
              </Button>
              <Button
                variant="contained"
                onClick={handleUpdateSales}
                color="primary"
              >
                Definir
              </Button>
            </DialogActions>
          </ModalCard>
        </Fade>
      </Modal>
      <SwipeableDrawer
        anchor="right"
        open={showInfoSale}
        onOpen={() => setShowInfoSale(true)}
        onClose={() => {
          closeDrawer();
        }}
      >
        <InfoSale closeDrawer={closeDrawer} />
      </SwipeableDrawer>
    </Card>
  );
}

export default FraudDetail;
