import styled from 'styled-components';

export const IndicatorsGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;

  & div:first-child {
    margin-right: 5px;
  }
  & div:nth-child(2) {
    margin-right: 5px;
  }
`;

export const IndicatorsKeyBox = styled.div`
  width: 100%;
`;
