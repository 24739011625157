import { Button, CardHeader } from '@mui/material';
import { ConfigurationPackageFetch } from 'fetches/configurationPackageFetch';
import { useState } from 'react';
import useBreakpoint from 'hooks/useBreakpoint';
import { useNavigate } from 'react-router-dom';
import Pagination from 'components/Pagination';
import { Card } from 'components/StyledComponents';
import { Content } from 'components/CardList';
import OfferDefinitionList from './OfferDefinitionList';
import { Header, Actions } from './styles';
import Filters from './Filters';

function OfferDefinition() {
  const [currentPage, setCurrentPage] = useState(1);
  const [segment, setSegment] = useState('');
  const [planID, setPlanID] = useState('');
  const [current, setCurrent] = useState(true);

  const { data: response, mutate } = ConfigurationPackageFetch({
    page: currentPage,
    segment,
    plan: planID,
    current,
  });

  const navigate = useNavigate();

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Card>
      <Header isDesktop={!isTableOrLess}>
        <CardHeader
          title="Definição de Oferta"
          subheader="Painel de definição de ofertas"
          style={{ padding: 0 }}
        />

        <Actions>
          <Filters
            segment={segment}
            setSegment={setSegment}
            planID={planID}
            setPlanID={setPlanID}
            current={current}
            setCurrent={setCurrent}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate('/flash-sales/cadastro-oferta');
            }}
            fullWidth={isTableOrLess}
            className="buttonNewOffer"
          >
            Criar Oferta
          </Button>
        </Actions>
      </Header>
      <Content>
        <OfferDefinitionList
          configurations={response?.configurations}
          updateConfigurations={mutate}
        />
        {response && (
          <Pagination
            currentPage={currentPage}
            lastPage={response?.paginationInfo?.lastPage}
            perPage={response?.paginationInfo?.perPage}
            total={response?.paginationInfo?.total}
            setCurrentPage={setCurrentPage}
          />
        )}
      </Content>
    </Card>
  );
}

export default OfferDefinition;
