import { makeStyles } from '@mui/styles';

export const useStylesModal = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogActions: {
    justifyContent: 'space-between',
    marginTop: 30,
    padding: 0,
  },
  buttonCancel: {
    color: '#C92A2A',
    border: '1px solid #C92A2A',
    '&:hover': {
      border: '1px solid #C92A2A',
    },
  },
}));
