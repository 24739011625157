import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Media } from 'models/reports';
import { capitalize } from 'util/formatter';
import source from 'pages/ManagementReport/reports/TotalByMedia/source';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { useStyles } from './styles';

interface UtmDistribuitionProps {
  utms: Media[];
  day: string;
  updateUtms(): void;
}

function UtmDistribuitionActions({
  utms,
  day,
  updateUtms,
}: UtmDistribuitionProps) {
  const [fromMedia, setFromMedia] = useState('');
  const [toMedia, setToMedia] = useState('');
  const [type, setType] = useState('');
  const [valueToTransfer, setValueToTransfer] = useState('');
  const [valueMax, setValueMax] = useState(0);
  const [formIsValid, setFormIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorValueMax, setErrorValueMax] = useState(false);

  const classes = useStyles();
  const { addToast } = useToast();

  const sources = utms?.map(utm => utm.source);

  useEffect(() => {
    setFormIsValid(!!fromMedia && !!toMedia && !!type && !errorValueMax);
  }, [fromMedia, toMedia, type, valueMax, errorValueMax]);

  useEffect(() => {
    const selectedUtmFrom = utms.find(utm => utm.source === fromMedia);

    if (selectedUtmFrom && type) {
      const valueMaxCalculation =
        type === 'pedidos'
          ? selectedUtmFrom.pedidos - selectedUtmFrom.quantidade
          : selectedUtmFrom.quantidade;

      const value = valueMaxCalculation >= 0 ? valueMaxCalculation : 0;

      setValueMax(value);
    }
  }, [fromMedia, utms, type]);

  const handleFromMediaChange = (value: string) => {
    setValueToTransfer('');
    setFromMedia(value);

    const filteredSources = sources.filter(midia => midia !== value);
    setToMedia(filteredSources.length > 0 ? filteredSources[0] : '');
  };

  const handleToMediaChange = (value: string) => {
    setToMedia(value);
  };

  const clearFields = () => {
    setFromMedia('');
    setToMedia('');
    setType('');
    setValueToTransfer('');
    setValueMax(0);
  };

  const handleValueToTransfer = (newValue: string) => {
    setValueToTransfer(newValue);
    setErrorValueMax(Number(newValue) !== 0 && Number(newValue) > valueMax);
  };

  const handleTypeChange = (newValue: string) => {
    setValueToTransfer('');

    setType(newValue);
  };

  const handleUpdate = () => {
    setLoading(true);
    const url = '/core/vendas/atualizar-utms';

    return api
      .put(`${url}/${type}`, {
        de_utm_medium: fromMedia,
        para_utm_medium: toMedia,
        dia: day,
        valor_para_transferir: valueToTransfer,
      })
      .then(() => {
        updateUtms();
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'UTMs atualizadas com sucesso!',
        });
      })
      .catch(error => {
        let { message } = error.data;

        if (!message) {
          message = error.data.errors[0]?.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao atualizar UTMs.',
            description: message,
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid container spacing={2} justifyContent="flex-end" marginBottom={4}>
      <Grid item xs={6} md={3}>
        <FormControl fullWidth size="small">
          <InputLabel id="de" focused shrink>
            De
          </InputLabel>
          <Select
            labelId="de"
            value={fromMedia}
            label="De"
            onChange={e => handleFromMediaChange(e.target.value)}
            input={<OutlinedInput id="de" label="De" notched />}
            displayEmpty
            renderValue={selected => {
              if (selected === '') {
                return <span>Selecione</span>;
              }
              return <span>{source[selected] || capitalize(selected)}</span>;
            }}
          >
            {sources.map(midia => (
              <MenuItem key={midia} value={midia}>
                {source[midia] || capitalize(midia)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} md={3}>
        <FormControl fullWidth size="small">
          <InputLabel id="para" focused shrink>
            Para
          </InputLabel>
          <Select
            labelId="para"
            value={toMedia}
            label="Para"
            onChange={e => handleToMediaChange(e.target.value)}
            input={<OutlinedInput id="para" label="Para" notched />}
            displayEmpty
            renderValue={selected => {
              if (selected === '') {
                return <span>Selecione</span>;
              }
              return <span>{source[selected] || capitalize(selected)}</span>;
            }}
            disabled={!fromMedia}
          >
            {fromMedia &&
              sources.map(midia => (
                <MenuItem
                  key={midia}
                  value={midia}
                  disabled={midia === fromMedia}
                >
                  {source[midia] || capitalize(midia)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} md={3}>
        <FormControl fullWidth size="small">
          <InputLabel id="tipo" focused shrink>
            Tipo
          </InputLabel>
          <Select
            labelId="tipo"
            id="tipo"
            value={type}
            label="Tipo"
            onChange={e => handleTypeChange(e.target.value)}
            input={<OutlinedInput id="tipo" label="Tipo" notched />}
            displayEmpty
            renderValue={selected => {
              if (selected === '') {
                return (
                  <span className={classes.selectPlaceholder}>Selecione</span>
                );
              }

              return <span>{capitalize(selected)}</span>;
            }}
            disabled={!fromMedia}
          >
            <MenuItem value="pedidos">Pedidos</MenuItem>
            <MenuItem value="vendas">Vendas</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} md={3}>
        <Grid
          item
          container
          spacing={2}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item md={7}>
            <TextField
              id="valor-para-transferir"
              label="Valor para transferir"
              value={valueToTransfer}
              size="small"
              onChange={e => handleValueToTransfer(e.target.value)}
              error={errorValueMax}
              helperText={errorValueMax && 'Excede valor máximo'}
              type="number"
              disabled={!type}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item style={{ paddingTop: 10 }} alignItems="self-end">
            <Typography variant="body2">Valor máximo</Typography>
            <Typography variant="body1">{valueMax}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6}>
        <Grid
          item
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Grid item md={1.5}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                clearFields();
              }}
            >
              Limpar
            </Button>
          </Grid>
          <Grid item md={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={!formIsValid || loading}
              onClick={() => {
                handleUpdate();
              }}
            >
              Atualizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UtmDistribuitionActions;
