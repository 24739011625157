import styled from 'styled-components';

export const DataText = styled.span`
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  display: flex;
  align-items: center;
`;
