import React, { useState } from 'react';
import { mutate } from 'swr';
import { Button, Popper } from '@mui/material';
import {
  EditOutlined,
  SendOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import api from 'services/api';
import { UsersFetch } from 'fetches/usersFetch';
import { useToast } from 'hooks/toast';
import { User } from 'models/user';
import { useNavigate } from 'react-router-dom';
import { Actions, useStyles } from './styles';

import PopperActionsProps, { Error } from './types';

function PopperActions({
  anchorElement,
  handleClick,
  user,
  currentPage,
}: PopperActionsProps) {
  const { addToast } = useToast();
  const classes = useStyles();
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const { data: usersResponse } = UsersFetch(currentPage);

  const navigate = useNavigate();

  const handleInactiveUser = async () => {
    const newStatus = user?.ativo ? 0 : 1;

    mutate(
      `users_${currentPage}`,
      {
        ...usersResponse,
        users: usersResponse?.users.map((userResponse: User) => {
          if (userResponse.id === user?.id) {
            return {
              ...userResponse,
              ativo: newStatus,
            };
          }

          return userResponse;
        }),
      },
      false,
    );

    await api.put(`/core/usuarios/${user?.id}`, {
      user_form: { ativo: newStatus },
    });
  };

  const handleResetUser = async () => {
    try {
      await api.post('/core/senha/enviar-email', {
        email: user?.email,
      });

      addToast({
        type: 'success',
        title: 'Reset de Usuário',
        description: 'Favor verificar o email',
      });
    } catch (err) {
      const error = err as Error;
      addToast({
        type: 'error',
        title: 'Erro no Reset de Usuário',
        description: error.data.message,
      });
    }
  };

  return (
    <Popper
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      placement="bottom"
      modifiers={[
        {
          name: 'flip',
          enabled: true,
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            boundariesElement: 'scrollParent',
          },
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      className={classes.popper}
    >
      <span className={classes.arrow} ref={setArrowRef} />
      <Actions className={classes.paper}>
        <Button
          className={classes.editButton}
          onClick={() => navigate(`/gestao-usuarios/edita-usuario/${user?.id}`)}
          startIcon={<EditOutlined />}
        >
          Editar
        </Button>
        <Button
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            handleClick(event);
            handleInactiveUser();
          }}
          startIcon={
            user?.ativo ? <VisibilityOffOutlined /> : <VisibilityOutlined />
          }
        >
          {user?.ativo ? 'Inativar Usuário' : 'Ativar Usuário'}
        </Button>
        {Boolean(user?.ativo) && (
          <Button
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              handleClick(event);
              handleResetUser();
            }}
            startIcon={<SendOutlined />}
          >
            Resetar Acesso
          </Button>
        )}
      </Actions>
    </Popper>
  );
}

export default PopperActions;
