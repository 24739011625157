import React from 'react';
import { Card, CardContent, CardHeader, Grid, TextField } from '@mui/material';
import { useAuth } from 'hooks/auth';

function UserInfo() {
  const { user } = useAuth();
  return (
    <Card style={{ flex: 1, margin: 20 }}>
      <CardHeader title="Dados do usuário" subheader="Informações do usuário" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              aria-readonly
              disabled
              fullWidth
              label="Nome Completo"
              margin="dense"
              name="name"
              value={user.nome}
              required
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              aria-readonly
              disabled
              fullWidth
              label="Email"
              margin="dense"
              name="email"
              value={user.email}
              required
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              aria-readonly
              disabled
              fullWidth
              label="Perfil"
              margin="dense"
              name="perfil"
              value={user.role.nome}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default UserInfo;
