import api from 'services/api';
import { DDDs } from 'util/validator';

import { usePlanStore } from 'store/planStore';
import { useToast } from 'hooks/toast';
import { useEffect, useState } from 'react';

interface DDDsSelecteds {
  [ddd: string]: boolean;
}

interface UsePriceProps {
  priceDDDsSelecteds?: string[];
  oldCurrentPrice?: string;
  oldPreviousPrice?: string;
  setShowDrawerEditGroupPrice?(showDrawerEditGroupPrice: boolean): void;
  setShowDrawerNewGroupPrice?(showDrawerNewGroupPrice: boolean): void;
  updatePriceGroups?(): void;
}

export function usePrice({
  priceDDDsSelecteds,
  oldCurrentPrice,
  oldPreviousPrice,
  setShowDrawerEditGroupPrice,
  setShowDrawerNewGroupPrice,
  updatePriceGroups,
}: UsePriceProps) {
  const planStore = usePlanStore(state => state.plan);
  const { addToast } = useToast();
  const [dddsSelecteds, setDddsSelecteds] = useState<DDDsSelecteds>({});
  const [currentPrice, setCurrentPrice] = useState<string>(
    oldCurrentPrice || '',
  );
  const [previousPrice, setPreviousPrice] = useState<string>(
    oldPreviousPrice || '',
  );
  const [isDisabledDDDs, setIsDisabledDDDs] = useState<{
    [ddd: string]: boolean;
  }>({});
  const [loading, setLoading] = useState(false);

  const DDDsArray = Array.from(DDDs).sort();

  useEffect(() => {
    const disabledDDDs = planStore.precos.reduce(
      (acc, preco) => ({
        ...acc,
        [preco.ddd]: String(preco.preco_atual) !== currentPrice,
      }),
      {},
    );

    setIsDisabledDDDs(disabledDDDs);
  }, [planStore.precos, currentPrice]);

  const changeDddsSelecteds = (ddd: string) => {
    setDddsSelecteds(state => ({ ...state, [ddd]: !state[ddd] }));
  };

  useEffect(() => {
    if (priceDDDsSelecteds && !Object.keys(dddsSelecteds).length) {
      priceDDDsSelecteds.map(priceDDD => changeDddsSelecteds(priceDDD));
    }
  }, [dddsSelecteds, priceDDDsSelecteds]);

  const markAllAreaCodes = () => {
    const updatedSelecteds: DDDsSelecteds = DDDsArray.reduce((acc, ddd) => {
      const dddString = ddd.toString();
      if (!isDisabledDDDs[dddString]) {
        acc[dddString] = true;
      }
      return acc;
    }, {} as DDDsSelecteds);

    setDddsSelecteds(updatedSelecteds);
  };

  const uncheckAllAreaCodes = () => {
    const updatedSelecteds: DDDsSelecteds = DDDsArray.reduce((acc, ddd) => {
      if (!isDisabledDDDs[ddd]) {
        acc[ddd] = false;
      }
      return acc;
    }, {} as DDDsSelecteds);

    setDddsSelecteds(updatedSelecteds);
  };

  const savePriceDDDs = () => {
    setLoading(true);

    const dddsFiltereds = Object.keys(dddsSelecteds).filter(
      key => dddsSelecteds[key],
    );
    api
      .post(`/core/planos/adicionar-preco/${planStore.id}`, {
        precos: {
          preco_atual: currentPrice,
          preco_anterior: previousPrice,
        },
        ddds: dddsFiltereds,
      })
      .then(() => {
        setShowDrawerNewGroupPrice && setShowDrawerNewGroupPrice(false);
        addToast({
          type: 'success',
          title: 'Grupo de preços salvos',
          description: 'Os grupos de preços atuais foram salvos com sucesso.',
        });
        updatePriceGroups && updatePriceGroups();
      })
      .catch(error => {
        let { message } = error.data;

        if (!message) {
          message = error.data.errors[0]?.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao criar!',
            description: message,
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updatePriceDDDs = () => {
    setLoading(true);

    const dddsFiltereds = Object.keys(dddsSelecteds).filter(
      key => dddsSelecteds[key],
    );

    api
      .put(`/core/planos/editar-preco/${planStore.id}`, {
        precos: {
          old_preco_atual: oldCurrentPrice,
          preco_atual: currentPrice,
          preco_anterior: previousPrice,
        },
        ddds: dddsFiltereds,
      })
      .then(() => {
        setShowDrawerEditGroupPrice && setShowDrawerEditGroupPrice(false);
        addToast({
          type: 'success',
          title: 'Grupo de preços salvos',
          description:
            'Os grupos de preços atuais foram atualizados com sucesso.',
        });
        updatePriceGroups && updatePriceGroups();
      })
      .catch(error => {
        let { message } = error.data;

        if (!message) {
          message = error.data.errors[0]?.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao atualizar!',
            description: message,
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setShowDrawerEditGroupPrice && setShowDrawerEditGroupPrice(false);
  };
  return {
    planStore,
    currentPrice,
    previousPrice,
    DDDsArray,
    dddsSelecteds,
    isDisabledDDDs,
    loading,
    setCurrentPrice,
    setPreviousPrice,
    changeDddsSelecteds,
    uncheckAllAreaCodes,
    markAllAreaCodes,
    savePriceDDDs,
    updatePriceDDDs,
    handleClose,
  };
}
