import { ReactComponent as StarsIcon } from 'assets/icons/small/average.svg';
import { ReactComponent as ValuedSales } from 'assets/icons/small/valued_sales.svg';
import { ReactComponent as UnvaluedSales } from 'assets/icons/small/unvalued_sales.svg';
import { ReactComponent as CommentsIcon } from 'assets/icons/small/comments.svg';
import palette from 'theme/palette';
import { FunctionComponent, SVGProps } from 'react';

export type Types =
  | 'media'
  | 'com_avaliacao'
  | 'sem_avaliacao'
  | 'com_comentario';

interface CardConf {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  color: string;
}

interface CardTypes {
  media: CardConf;
  com_avaliacao: CardConf;
  sem_avaliacao: CardConf;
  com_comentario: CardConf;
  [key: string]: CardConf;
}

const CardTypes: CardTypes = {
  media: {
    icon: StarsIcon,
    color: palette.status.deliveryProblems.color,
  },
  com_avaliacao: {
    icon: ValuedSales,
    color: palette.status.stardardFlow.color,
  },
  sem_avaliacao: {
    icon: UnvaluedSales,
    color: palette.status.generalFailures.color,
  },
  com_comentario: {
    icon: CommentsIcon,
    color: palette.controle,
  },
};

export default CardTypes;
