import { useEffect, useState } from 'react';
import { UtmCampaign } from 'models/utmCampaign';
import { v4 as uuidv4 } from 'uuid';
import { useConfigurationUtmStore } from 'store/configurationUtmStore';
import {
  UtmCampaignsFetch,
  UtmDefaultCampaign,
} from 'fetches/utmCampaignsFetch';

interface useEditCampaignProps {
  sourceId: string;
}

export function useEditCampaign({ sourceId }: useEditCampaignProps) {
  const configurationUtm = useConfigurationUtmStore(state => state.value);
  const setConfigurationUtm = useConfigurationUtmStore(
    state => state.setConfigurationUtm,
  );
  const [campaingsList, setCampaingsList] = useState<UtmCampaign[]>();
  const [isFormValid, setIsFormValid] = useState(false);
  const [showAddCampaign, setShowAddCampaign] = useState(false);
  const [showEditCampaign, setShowEditCampaign] = useState(false);
  const [campaignName, setCampaignName] = useState('');
  const [total, setTotal] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const [campaignIdSelected, setCampaignIdSelected] = useState<string | null>(
    null,
  );

  const { data: campaignsResponse } = UtmCampaignsFetch(sourceId);
  const { data: campaignDefault } = UtmDefaultCampaign();

  useEffect(() => {
    const sourceFound = configurationUtm.sources.find(
      source => source.id === sourceId,
    );
    if (sourceFound?.campaigns) {
      setCampaingsList(sourceFound.campaigns);
    }
  }, [configurationUtm.sources, sourceId]);

  useEffect(() => {
    total === 100 && setIsFormValid(true);
    total !== 100 && setIsFormValid(false);
  }, [total]);

  useEffect(() => {
    !showDialog && setCampaignIdSelected(null);
  }, [showDialog]);

  useEffect(() => {
    if (
      configurationUtm.sources &&
      configurationUtm.sources.length > 0 &&
      sourceId
    ) {
      const sourceToCalculate = configurationUtm.sources.find(
        source => String(source.id) === String(sourceId),
      );

      if (sourceToCalculate && sourceToCalculate.campaigns) {
        const sumPercentages = sourceToCalculate.campaigns.reduce(
          (accumulator, campanha) => {
            return accumulator + campanha.porcentagem;
          },
          0,
        );

        setTotal(sumPercentages);
      }
    }
  }, [configurationUtm.sources, sourceId]);

  function handlePercentChange(percent: string, index: number) {
    const updatedSources = [...configurationUtm.sources];

    const sourceToUpdate = updatedSources.find(
      source => source.id === sourceId,
    );

    if (
      sourceToUpdate &&
      sourceToUpdate.campaigns &&
      index >= 0 &&
      index < sourceToUpdate.campaigns.length
    ) {
      const updatedCampaigns = [...sourceToUpdate.campaigns];

      updatedCampaigns[index] = {
        ...updatedCampaigns[index],
        porcentagem: !Number.isNaN(parseFloat(percent))
          ? parseFloat(percent)
          : 0,
      };

      sourceToUpdate.campaigns = updatedCampaigns;

      setConfigurationUtm({ ...configurationUtm, sources: updatedSources });
    }
  }

  function handleCreateCampaign() {
    const newCampaign = {
      id: uuidv4() as unknown as number,
      nome: campaignName,
      porcentagem: 0,
      utm_source_id: sourceId,
    };

    const updatedSources = [...configurationUtm.sources];
    const sourceToUpdate = updatedSources.find(
      source => source.id === sourceId,
    );

    if (sourceToUpdate) {
      sourceToUpdate.campaigns = [
        ...(sourceToUpdate.campaigns || []),
        newCampaign,
      ];
      setConfigurationUtm({ ...configurationUtm, sources: updatedSources });
    }

    setShowAddCampaign(false);
    setCampaignName('');
  }

  function handleEditCampaignName(index: number) {
    const updatedSources = [...configurationUtm.sources];
    const sourceToUpdate = updatedSources.find(
      source => source.id === sourceId,
    );

    if (sourceToUpdate) {
      sourceToUpdate.campaigns?.forEach((campaign, campaignIndex) => {
        if (campaignIndex === index) {
          const updatedCampaign = {
            ...campaign,
            nome: campaignName,
          };
          sourceToUpdate.campaigns[campaignIndex] = updatedCampaign;
        }
      });

      setConfigurationUtm({ ...configurationUtm, sources: updatedSources });
    }

    setCampaignName('');
    setCampaignIdSelected(null);
  }

  function handleOpenDialog(campaignId: string) {
    setShowDialog(true);
    setCampaignIdSelected(campaignId);
  }

  function handleDeleteCampaign() {
    if (campaignIdSelected) {
      const updatedSources = [...configurationUtm.sources];
      const sourceToUpdate = updatedSources.find(
        source => source.id === sourceId,
      );

      if (sourceToUpdate) {
        sourceToUpdate.campaigns = sourceToUpdate.campaigns?.filter(
          campaign => String(campaign.id) !== campaignIdSelected,
        );

        setConfigurationUtm({ ...configurationUtm, sources: updatedSources });
      }

      setCampaignIdSelected(null);
    }
  }

  function handleReset() {
    const updatedConfigUtm = {
      ...configurationUtm,
      sources: configurationUtm.sources.map(source => {
        if (source.id === sourceId) {
          return {
            ...source,
            campaigns: campaignsResponse?.length
              ? campaignsResponse
              : campaignDefault?.map(campanha => ({
                  id: uuidv4() as unknown as number,
                  nome: campanha.nome,
                  porcentagem: campanha.porcentagem,
                  utm_source_id: sourceId,
                })),
          };
        }
        return source;
      }),
    };

    setConfigurationUtm(updatedConfigUtm);
    setShowAddCampaign(false);
    setCampaignName('');
    setShowEditCampaign(false);
  }

  return {
    showEditCampaign,
    campaignIdSelected,
    campaignName,
    campaingsList,
    showAddCampaign,
    isFormValid,
    total,
    showDialog,
    setCampaignName,
    handlePercentChange,
    handleEditCampaignName,
    setShowEditCampaign,
    setCampaignIdSelected,
    handleOpenDialog,
    handleCreateCampaign,
    setShowAddCampaign,
    setShowDialog,
    handleDeleteCampaign,
    handleReset,
  };
}
