import React, { FormEvent, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';

import AuthTextField from 'components/AuthTextField';
import { AnimationContainer } from 'pages/_layouts/auth/styles';
import { Button, Typography } from '@mui/material';

interface Erros {
  email: string;
  password: string;
  token: string;

  [x: string]: string;
}

const schema = Yup.object().shape({
  email: Yup.string().required('Campo obrigatório').email('Email inválido'),
  password: Yup.string().required('Campo Obrigatório'),
  showToken: Yup.boolean(),
  token: Yup.string().when('showToken', {
    is: true,
    then: Yup.string().required('Campo Obrigatório'),
  }),
});
const defaultErros = {
  email: '',
  password: '',
  token: '',
};

function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();

  const { signIn } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    if (location.search === '?error') {
      addToast({
        type: 'error',
        title: 'Erro na autenticação',
        description: 'Ocorreu um erro ao fazer login, cheque as credenciais',
      });
    } else if (location.search === '?conflict') {
      addToast({
        type: 'error',
        title: 'Login duplicado',
        description: 'Usuário com outra sessão ativa.',
      });
    } else if (location.search === '?login') {
      addToast({
        type: 'error',
        title: 'Login expirado',
        description: 'Efetuar login novamente.',
      });
    }
  }, [location, addToast]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');

  const [loading, setLoading] = useState(false);
  const [showToken, setShowToken] = useState(false);
  const [erros, setErros] = useState<Erros>(defaultErros);

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    setErros(defaultErros);
    schema
      .validate(
        { email, password, token, showToken },
        {
          abortEarly: false,
        },
      )
      .then(async () => {
        setLoading(true);
        signIn({ email, password, token })
          .then(status => {
            if (status === 200) {
              navigate('/');
              return;
            }
            addToast({
              type: 'success',
              title: 'Sucesso!!!',
              description: `Token enviado para o email ${email}`,
            });
            setShowToken(true);
          })
          .catch(error => {
            addToast({
              type: 'error',
              title: 'Erro na autenticação',
              description:
                error.status === 307
                  ? error?.data?.message
                  : 'Ocorreu um erro ao fazer login, cheque as credenciais',
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(err => {
        if (err instanceof Yup.ValidationError) {
          const newErros: Erros = { ...defaultErros };
          err.inner.forEach(error => {
            if (error.path) {
              newErros[error.path] = error.message;
            }
          });
          setErros(newErros);
        }
      });
  };

  return (
    <AnimationContainer>
      <form onSubmit={handleSubmit}>
        <Typography gutterBottom variant="h3">
          Seja bem-vindo!
        </Typography>
        <AuthTextField
          focused
          label="E-mail"
          name="email"
          placeholder="E-mail"
          type="email"
          value={email}
          fullWidth
          margin="normal"
          required
          variant="outlined"
          color="secondary"
          helperText={erros.email}
          error={!!erros.email}
          onChange={e => setEmail(e.target.value)}
        />
        <AuthTextField
          name="password"
          type="password"
          placeholder="Password"
          value={password}
          fullWidth
          label="Password"
          margin="normal"
          required
          color="secondary"
          variant="outlined"
          helperText={erros.password}
          error={!!erros.password}
          onChange={e => setPassword(e.target.value)}
        />
        {showToken && (
          <AuthTextField
            name="token"
            type="password"
            fullWidth
            label="Token"
            margin="dense"
            required
            color="secondary"
            variant="outlined"
            value={token}
            helperText={erros.token}
            onChange={e => setToken(e.target.value)}
          />
        )}
        <Link
          style={{
            color: '#fff',
            textDecoration: 'none',
            alignSelf: 'flex-end',
          }}
          to="/resetar-senha"
        >
          Esqueci a senha
        </Link>
        <Button
          style={{
            marginTop: 34,
            background: '#00C2FF',
            color: '#fff',
            fontWeight: 'bold',
            fontSize: 16,
            padding: '12px 32px',
            textTransform: 'capitalize',
          }}
          variant="contained"
          fullWidth
          size="large"
          type="submit"
        >
          {loading ? 'Carregando ...' : 'Entrar'}
        </Button>
      </form>
    </AnimationContainer>
  );
}

export default SignIn;
