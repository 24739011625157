import userSWR from 'swr';
import api from 'services/api';
import { Sale, TractableSalesFetchResponse } from 'models/tractableSales';

interface Params {
  busca?: string;
  motivo_tratamento?: string;
}

interface FetchProps {
  page?: number;
  search?: string;
  treatmentReason?: string;
}

export const TractableSalesFetch = ({
  page,
  search,
  treatmentReason,
}: FetchProps) => {
  const { data, mutate } = userSWR<TractableSalesFetchResponse>(
    `sales_${page}_${search}_${treatmentReason}`,
    async (): Promise<TractableSalesFetchResponse> => {
      const params: Params = {
        busca: search,
        motivo_tratamento: treatmentReason,
      };
      const response = await api.get(`/core/vendas/trataveis?page=${page}`, {
        params,
      });
      const paginationInfo = {
        currentPage: parseInt(response.headers['x-current-page'], 10),
        lastPage: parseInt(response.headers['x-last-page'], 10),
        perPage: parseInt(response.headers['x-per-page'], 10),
        total: parseInt(response.headers['x-total-count'], 10),
      };

      const sales = response.data as Sale[];

      return { sales, paginationInfo } as TractableSalesFetchResponse;
    },
  );
  return { data, mutate };
};
