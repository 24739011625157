import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { ActivePlansFetch } from 'fetches/activePlansFetch';
import { useCallback } from 'react';
import { Container } from './styles';

interface FilterProps {
  segment: string;
  setSegment(value: string): void;
  planID: string;
  setPlanID(value: string): void;
  current: boolean;
  setCurrent(current: boolean): void;
}

function Filters({
  segment,
  setSegment,
  planID,
  setPlanID,
  current,
  setCurrent,
}: FilterProps) {
  const { data: plans } = ActivePlansFetch(segment);

  const clearSelectPlanID = useCallback(() => {
    setPlanID('');
  }, [setPlanID]);

  return (
    <Container>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={current}
          onChange={e => setCurrent(e.target.checked)}
        />
        <Typography>Vigentes</Typography>
      </Box>
      <FormControl
        variant="outlined"
        margin="none"
        size="small"
        className="selectSegment"
      >
        <InputLabel htmlFor="Segmento" focused shrink>
          Segmento
        </InputLabel>
        <Select
          labelId="segmento"
          id="segmento"
          native
          value={segment || ''}
          input={
            <OutlinedInput id="select-segmento" label="Segmento" notched />
          }
          onChange={e => {
            setSegment(e.target.value);
            clearSelectPlanID();
          }}
          margin="none"
        >
          <option value="">Todos</option>
          <option value="CONTROLE">Controle</option>
          <option value="EXPRESS">Express</option>
          <option value="POS_PAGO">Pós Pago</option>
          <option value="POS_EXPRESS">Pós Express</option>
        </Select>
      </FormControl>
      <FormControl
        variant="outlined"
        margin="none"
        size="small"
        className="selectPlan"
      >
        <InputLabel htmlFor="Plano" focused shrink>
          Plano
        </InputLabel>
        <Select
          native
          value={planID || '0'}
          onChange={e => setPlanID(e.target.value as string)}
          label="Plano"
          labelId="plano"
          id="plano"
          input={<OutlinedInput id="select-plano" label="Plano" notched />}
          margin="none"
          disabled={!plans}
        >
          {plans?.length ? (
            <>
              <option value="">Todos</option>
              {plans?.map(plano => (
                <option key={plano.id} aria-label="plan" value={plano.id}>
                  {plano.tim_id} - {plano.nome}
                </option>
              ))}
            </>
          ) : (
            <option value="" disabled>
              Não há plano disponível
            </option>
          )}
        </Select>
      </FormControl>
    </Container>
  );
}

export default Filters;
