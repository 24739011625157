import useSWR from 'swr';
import api from 'services/api';

export const ManualPDFFetch = () => {
  const { data } = useSWR<string>(
    'manual',
    async (): Promise<string> => {
      const results = await api.get(`/core/manual/`, { responseType: 'blob' });

      const fileURL = URL.createObjectURL(results.data);

      return fileURL as string;
    },
    { revalidateOnFocus: false },
  );
  return { data };
};
