import { useCallback, useEffect, useState } from 'react';
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns/esm';
import { ptBR } from 'date-fns/esm/locale';

import { useReportFilterStore } from 'store/useReportFilterStore';

export interface FilterDateMonth {
  month: string;
  firstDay: string;
  lastDay: string;
  key: string;
}

export interface MonthSelected {
  [key: string]: boolean;
}

interface PopoverActionsProps {
  initMonthSelected(value: MonthSelected): void;
}

export function usePopoverActions({ initMonthSelected }: PopoverActionsProps) {
  const withReprocessed = useReportFilterStore(
    state => state.localWithReprocessed,
  );
  const setWithReprocessed = useReportFilterStore(
    state => state.setLocalWithReprocessed,
  );
  const inReprocessing = useReportFilterStore(
    state => state.localWithInReprocessing,
  );
  const setInReprocessing = useReportFilterStore(
    state => state.setLocalWithInReprocessing,
  );
  const withEngagement = useReportFilterStore(
    state => state.localWithEngagement,
  );
  const setWithEngagement = useReportFilterStore(
    state => state.setLocalWithEngagement,
  );
  const today = useReportFilterStore(state => state.localToday);
  const setToday = useReportFilterStore(state => state.setLocalToday);
  const yesterday = useReportFilterStore(state => state.localYesterday);
  const setYesterday = useReportFilterStore(state => state.setLocalYesterday);
  const lastWeek = useReportFilterStore(state => state.localLastWeek);
  const setLastWeek = useReportFilterStore(state => state.setLocalLastWeek);

  const [months, setMonths] = useState<FilterDateMonth[]>([]);

  useEffect(() => {
    const currentDate = new Date();

    setMonths([]);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 12; i++) {
      const date = subMonths(currentDate, i);

      const filterDateMonth = {
        month: format(date, 'MMMM', {
          locale: ptBR,
        }),
        firstDay: format(startOfMonth(date), 'yyyy-MM-dd'),
        lastDay: format(endOfMonth(date), 'yyyy-MM-dd'),
        key: format(date, 'yyyy-MM-dd'),
      };

      setMonths(old => [...old, filterDateMonth]);
      initMonthSelected({ [filterDateMonth.key]: false });
    } // eslint-disable-next-line
  }, []);

  const handleChangeWithReprocessed = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setWithReprocessed(event.target.checked);
  };

  const handleChangeInReprocessing = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setInReprocessing(event.target.checked);
  };

  const handleChangeWithEngagement = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setWithEngagement(event.target.checked);
  };

  const handleToday = useCallback(
    (value: boolean) => {
      if (value) {
        setYesterday(false);
        setLastWeek(false);
      }
      setToday(value);
    },
    [setLastWeek, setToday, setYesterday],
  );

  const handleYesterday = useCallback(
    (value: boolean) => {
      if (value) {
        setToday(false);
        setLastWeek(false);
      }

      setYesterday(value);
    },
    [setLastWeek, setToday, setYesterday],
  );

  const handleLastWeek = useCallback(
    (value: boolean) => {
      if (value) {
        setToday(false);
        setYesterday(false);
      }

      setLastWeek(value);
    },
    [setLastWeek, setToday, setYesterday],
  );
  return {
    withReprocessed,
    inReprocessing,
    withEngagement,
    today,
    yesterday,
    lastWeek,
    months,
    handleChangeWithReprocessed,
    handleChangeInReprocessing,
    handleChangeWithEngagement,
    handleToday,
    handleYesterday,
    handleLastWeek,
  };
}
