import { CardHeader, Grid, TextField } from '@mui/material';
import { Card } from 'components/StyledComponents';
import { useEffect } from 'react';
import ReactInputMask from 'react-input-mask';
import { Erros } from '../infoShemaYup';
import { useStyles } from '../styles';

interface InfoCustomerProps {
  cpf: string;
  setCpf(value: string): void;

  email: string;
  setEmail(value: string): void;

  filiacao: string;
  setFiliacao(value: string): void;

  msisdn: string;
  setMsisdn(value: string): void;

  nascimento: string;
  setNascimento(value: string): void;

  nome: string;
  setNome(value: string): void;

  erros: Erros;

  setFormCustomerIsValid(value: boolean): void;
}

function InfoCustomer({
  cpf,
  setCpf,
  email,
  setEmail,
  filiacao,
  setFiliacao,
  msisdn,
  setMsisdn,
  nascimento,
  setNascimento,
  nome,
  setNome,
  erros,
  setFormCustomerIsValid,
}: InfoCustomerProps) {
  const classes = useStyles();

  useEffect(() => {
    setFormCustomerIsValid(
      !!cpf && !!nome && !!email && !!filiacao && !!msisdn && !!nascimento,
    );
  }, [cpf, email, filiacao, msisdn, nascimento, nome, setFormCustomerIsValid]);

  return (
    <Card className={classes.root}>
      <CardHeader title="Cliente" className={classes.card} />
      <Grid container spacing={2} className={classes.grid}>
        <Grid item md={6} xs={12}>
          <TextField
            id={cpf}
            value={cpf}
            aria-readonly
            onKeyPress={event => {
              if (/[0-9]/.test(event.key)) {
                return;
              }
              event.preventDefault();
            }}
            inputProps={{ maxLength: 11 }}
            fullWidth
            label="CPF"
            name="cpf"
            onChange={e => setCpf(e.target.value)}
            variant="outlined"
            placeholder="Não preenchido"
            InputLabelProps={{
              shrink: true,
            }}
            error={!!erros.cpf || cpf === ''}
            helperText={
              erros.cpf
                ? erros.cpf
                : cpf === ''
                ? 'Campo CPF é obrigatório.'
                : ' '
            }
            size="small"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            id={email}
            value={email}
            onChange={e => setEmail(e.target.value)}
            aria-readonly
            fullWidth
            label="E-mail"
            name="email"
            variant="outlined"
            placeholder="Não preenchido"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            error={!!erros.email || email === ''}
            helperText={
              erros.email
                ? erros.email
                : email === ''
                ? 'Campo email é obrigatório.'
                : ' '
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            id={filiacao}
            value={filiacao}
            onChange={e => setFiliacao(e.target.value)}
            aria-readonly
            fullWidth
            label="Filiação"
            name="filiacao"
            variant="outlined"
            placeholder="Não preenchido"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            error={!!erros.filiacao || filiacao === ''}
            helperText={
              erros.filiacao
                ? erros.filiacao
                : filiacao === ''
                ? 'Campo filiação é obrigatório.'
                : ' '
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            id={msisdn}
            value={msisdn}
            onChange={e => setMsisdn(e.target.value)}
            onKeyPress={event => {
              if (/[0-9]/.test(event.key)) {
                return;
              }
              event.preventDefault();
            }}
            inputProps={{ maxLength: 11 }}
            aria-readonly
            fullWidth
            label="MSISDN"
            name="msisdn"
            variant="outlined"
            placeholder="Não preenchido"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            error={!!erros.msisdn || msisdn === ''}
            helperText={
              erros.msisdn
                ? erros.msisdn
                : msisdn === ''
                ? 'Campo msisdn é obrigatório.'
                : ' '
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <ReactInputMask
            mask="99/99/9999"
            type="tel"
            maskChar=" "
            value={nascimento}
            onChange={e => setNascimento(e.target.value)}
          >
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              InputProps => (
                <TextField
                  aria-readonly
                  fullWidth
                  variant="outlined"
                  label="Nascimento"
                  name="nascimento"
                  placeholder="Não preenchido"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!erros.nascimento || nascimento === ''}
                  helperText={
                    erros.nascimento
                      ? erros.nascimento
                      : nascimento === ''
                      ? 'Campo nascimento é obrigatório.'
                      : ' '
                  }
                  {...InputProps}
                />
              )
            }
          </ReactInputMask>
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            id={nome}
            value={nome}
            onChange={e => setNome(e.target.value)}
            aria-readonly
            fullWidth
            label="Nome"
            name="nome"
            variant="outlined"
            placeholder="Não preenchido"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            error={nome === ''}
            helperText={nome === '' ? 'Campo nome é obrigatório.' : ' '}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

export default InfoCustomer;
