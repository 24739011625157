import styled from 'styled-components';

export const Values = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
`;

export const Value = styled.div`
  padding: 12px 6px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  margin-right: 16px;
  margin-bottom: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SaveButton = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
`;

export const FieldInsertValues = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
`;

export const AddSetup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 16px;
`;
