import { colors } from '@mui/material';

export default function MuiTableHead() {
  return {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: colors.grey[50],
        },
      },
    },
  };
}
