import { useToast } from 'hooks/toast';
import { useState } from 'react';
import api from 'services/api';

import { ButtonProgress } from 'components/StyledComponents';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import { Sale } from '../types';

interface RequestedDocumentsProps {
  sale: Sale;
  setSale(sale: Sale): void;
}

function RequestedDocuments({ sale, setSale }: RequestedDocumentsProps) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { addToast } = useToast();

  const returnSale = () => {
    setOpen(false);
    setLoading(true);
    const url = '/core/vendas/atualizar-detalhe';

    return api
      .put(`${url}/${sale?.id}`, {
        status: 'DOCUMENTOS_SOLICITADOS',
        documentos_solicitados: true,
      })
      .then(() => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Documentação solicitada com sucesso!',
        });
        setOpen(false);
      })
      .catch(error => {
        setLoading(false);
        let message = error.data.errors[0]?.message;
        if (!message) {
          message = error.data.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro',
            description: message,
          });
      })
      .finally(() => {
        api
          .get(`core/vendas/${sale.id}`)
          .then(({ data }) => setSale({ ...sale, ...data }));
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box>
      <Button
        type="button"
        color="secondary"
        fullWidth
        size="large"
        variant="outlined"
        startIcon={<GradingOutlinedIcon />}
        onClick={() => setOpen(true)}
      >
        Documentos Solicitados
        {loading && <ButtonProgress size={24} />}
      </Button>
      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Deseja realmente marcar esta venda?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Ao clicar em Sim, a venda será marcada com DOCUMENTAÇÃO SOLICITADA e
            não será mais possível desfazer esta ação.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Não
          </Button>
          <Button onClick={returnSale} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default RequestedDocuments;
