import { QueueSize } from 'models/queueSize';
import api from 'services/api';
import userSWR from 'swr';

export const QueueSizeFetch = (connection: string, queue: string) => {
  const { data, mutate: mutateTotalLeads } = userSWR<QueueSize>(
    `queue_size_batch`,
    async (): Promise<QueueSize> => {
      const { data: total } = await api.get(
        `core/relatorios/tamanho-fila/${connection}/${queue}`,
      );

      return total as QueueSize;
    },
  );

  return { data, mutateTotalLeads };
};
