import { List } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { Container } from 'components/CardList';
import { ReprocessingOfReachedCustomerBase } from 'models/reprocessingOfReachedCustomerBase';
import { Header } from './styles';
import MobileItem from './components/MobileItem';
import Item from './components/Item';

interface EngagingListProps {
  basesReprocessed?: ReprocessingOfReachedCustomerBase[];
}

interface BodyProps {
  basesReprocessed: ReprocessingOfReachedCustomerBase[];
  isMobile: boolean;
}
interface LoadingListProps {
  isTableOrLess: boolean;
}

function LoadingList({ isTableOrLess }: LoadingListProps) {
  return isTableOrLess ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({ basesReprocessed, isMobile }: BodyProps) {
  return (
    <List>
      {basesReprocessed.length ? (
        basesReprocessed.map(item =>
          isMobile ? (
            <MobileItem key={item.id} data={item} />
          ) : (
            <Item key={item.id} data={item} />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhuma base encontrada</h3>
      )}
    </List>
  );
}

function EngagingList({ basesReprocessed }: EngagingListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Container>
      {!isTableOrLess && (
        <Header>
          <span>Criado</span>
          <span>Status</span>
          <span>Base</span>
          <span>Para Despausar</span>
          <span>Para Processar</span>
          <span>Para Responder</span>
        </Header>
      )}
      {basesReprocessed ? (
        <Body basesReprocessed={basesReprocessed} isMobile={isTableOrLess} />
      ) : (
        <LoadingList isTableOrLess={isTableOrLess} />
      )}
    </Container>
  );
}

export default EngagingList;
