export const typeOptions = [
  { value: 'NOVA_LINHA', text: 'Nova Linha' },
  { value: 'MIGRACAO', text: 'Migração' },
  { value: 'PORTABILIDADE', text: 'Portabilidade' },
];

export const statusOptions = [
  { value: 'NOVO', text: 'Novo' },
  { value: 'EM_PROCESSAMENTO', text: 'Em processamento' },
  { value: 'PENDENTE_ENVIO', text: 'Pendente de envio' },
  { value: 'ENVIADO', text: 'Enviado' },
  { value: 'EM_TRANSPORTE_EXPRESSO', text: 'Transporte Expresso' },
  { value: 'POS_ACTIVATED', text: 'Pós Ativação' },
  { value: 'ATIVADO', text: 'Ativado' },
  { value: 'CANCELADO', text: 'Cancelado' },
  { value: 'DIVERGENTE', text: 'Divergente' },
  { value: 'EXTRAVIADO', text: 'Extraviado' },
  { value: 'ANALISE_FRAUDE', text: 'Análise Fraude' },
  { value: 'LEGITIMO', text: 'Legítimo' },
  { value: 'AGUARDANDO_RETIRADA', text: 'Aguardando Retirada' },
  { value: 'DEVOLVIDO', text: 'Devolvido' },
  { value: 'AGUARDANDO_PORTABILIDADE', text: 'Aguardando Portabilidade' },
  { value: 'AGUARDANDO_JANELA', text: 'Aguardando Janela' },
  { value: 'LEAD_TRATAVEL', text: 'Lead Tratável' },
  { value: 'RECUSADO', text: 'Recusado' },
];

export const segmentOptions = [
  { value: 'CONTROLE', text: 'Controle' },
  { value: 'EXPRESS', text: 'Express' },
  { value: 'POS_PAGO', text: 'Pós Pago' },
  { value: 'POS_EXPRESS', text: 'Pós Express' },
];

export const ufOptions = [
  { value: 'AC', text: 'Acre' },
  { value: 'AL', text: 'Alagoas' },
  { value: 'AP', text: 'Amapá' },
  { value: 'AM', text: 'Amazonas' },
  { value: 'BA', text: 'Bahia' },
  { value: 'CE', text: 'Ceará' },
  { value: 'DF', text: 'Distrito Federal' },
  { value: 'ES', text: 'Espírito Santo' },
  { value: 'GO', text: 'Goiás' },
  { value: 'MA', text: 'Maranhão' },
  { value: 'MT', text: 'Mato Grosso' },
  { value: 'MS', text: 'Mato Grosso do Sul' },
  { value: 'MG', text: 'Minas Gerais' },
  { value: 'PA', text: 'Pará' },
  { value: 'PB', text: 'Paraíba' },
  { value: 'PR', text: 'Paraná' },
  { value: 'PE', text: 'Pernambuco' },
  { value: 'PI', text: 'Piauí' },
  { value: 'RJ', text: 'Rio de Janeiro' },
  { value: 'RN', text: 'Rio Grande do Norte' },
  { value: 'RS', text: 'Rio Grande do Sul' },
  { value: 'RO', text: 'Rondônia' },
  { value: 'RR', text: 'Roraima' },
  { value: 'SC', text: 'Santa Catarina' },
  { value: 'SP', text: 'São Paulo' },
  { value: 'SE', text: 'Sergipe' },
  { value: 'TO', text: 'Tocantins' },
];

export const statusCrosscoreOptions = [
  { value: 'CONTINUE', text: 'Continue' },
  { value: 'ERROR', text: 'Error' },
  { value: 'NODECISION', text: 'No Decision' },
  { value: 'REFER', text: 'Refer' },
  { value: 'STOP', text: 'Stop' },
  { value: 'SEM_STATUS', text: 'Sem status' },
];
