import styled from 'styled-components';

export const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 62px 15px 38px;
  color: rgba(0, 0, 0, 0.4);
  span {
    :nth-child(1) {
      width: 100px;
    }
    min-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    align-self: flex-start;
  }
`;

export const List = styled.div`
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.1));
  border-radius: 6px;
  width: 100%;
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

export const EmptyTable = styled.h3`
  color: #ccc;
  padding: 50px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px;
`;

export const GroupInput = styled.div`
  display: flex;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
