import { createStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { Switch } from '@mui/material';

export const SwitchIOS = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 51,
      height: 31,
      padding: 0,
      display: 'flex',
      marginRight: 10,
    },
    switchBase: {
      padding: 2,
      '&$checked': {
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          border: 0,
        },
      },
    },
    thumb: {
      width: 27,
      height: 27,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: '999px',
      opacity: 1,
      backgroundColor: '#C4C4C4',
    },
    checked: {},
  }),
)(Switch);
