import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import api from 'services/api';
import { useToast } from 'hooks/toast';

function ResetPassword() {
  const { addToast } = useToast();

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [formIsValid, setFormIsValid] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setconfirmPasswordError] = useState(false);

  useEffect(() => {
    const newPassSameCurrent = !!(
      newPassword && newPassword === currentPassword
    );
    const newPassDiffConfirm = !!(
      confirmPassword && confirmPassword !== newPassword
    );
    setNewPasswordError(newPassSameCurrent);
    setconfirmPasswordError(newPassDiffConfirm);
    setFormIsValid(
      !newPassSameCurrent &&
        !newPassDiffConfirm &&
        !!currentPassword &&
        !!newPassword &&
        !!confirmPassword,
    );
  }, [currentPassword, newPassword, confirmPassword]);
  const handleSaveNewPassword = () => {
    const data = {
      password_atual: currentPassword,
      password_novo: newPassword,
    };
    api
      .post('/core/senha/trocar', data)
      .then(() => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        addToast({
          type: 'success',
          title: 'Resete de senha',
          description: 'Sua senha foi atualizada com sucesso!',
        });
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Resete de senha',
          description: error.data.message,
        });
      });
  };

  return (
    <Card style={{ flex: 1, margin: 20 }}>
      <CardHeader title="Senha" subheader="Resete de Senha" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                Senha Atual
              </InputLabel>
              <OutlinedInput
                label="Senha Atual"
                type={showPassword ? 'text' : 'password'}
                onChange={e => setCurrentPassword(e.target.value)}
                value={currentPassword}
                autoComplete="off"
                id="current_password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPassword(old => !old);
                      }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControl error={newPasswordError} variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                Nova Senha
              </InputLabel>
              <OutlinedInput
                label="Nova Senha"
                type={showNewPassword ? 'text' : 'password'}
                onChange={e => setNewPassword(e.target.value)}
                value={newPassword}
                autoComplete="off"
                id="new_password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowNewPassword(old => !old);
                      }}
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {newPasswordError && (
                <FormHelperText error id="standard-weight-helper-text">
                  A nova senha de ser diferente da atual.
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControl
              error={confirmPasswordError}
              variant="outlined"
              fullWidth
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Confirmar nova senha
              </InputLabel>
              <OutlinedInput
                label="Confirmar nova senha"
                type={showConfirm ? 'text' : 'password'}
                onChange={e => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                autoComplete="off"
                id="confirm_password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowConfirm(old => !old);
                      }}
                    >
                      {showConfirm ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {confirmPasswordError && (
                <FormHelperText error id="standard-weight-helper-text">
                  Campo confirmar deve ser diferente do campo nova senha.
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Box
            width="100%"
            margin="10px 8px"
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              disabled={!formIsValid}
              onClick={() => handleSaveNewPassword()}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </Box>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ResetPassword;
