import { IndicatorBox, IndicatorDescription, IndicatorValue } from './styles';

export interface IndicatorProps {
  description: string;
  value: string;
}

function Indicator({ description, value }: IndicatorProps) {
  return (
    <IndicatorBox>
      <IndicatorDescription>{description}</IndicatorDescription>
      <IndicatorValue>{value}</IndicatorValue>
    </IndicatorBox>
  );
}

export default Indicator;
