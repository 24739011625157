import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 15px;
  width: 100%;
  border-collapse: collapse;
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  padding: 15px;

  .button {
    width: 155px;
    height: 40px;
  }

  @media (max-width: 1023px) {
    padding: 0px;
    padding-bottom: 15px;

    form {
      width: 100%;
    }

    .button {
      width: 100%;
      height: 40px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0px 112px 15px 25px;
  color: rgba(0, 0, 0, 0.4);
  span {
    width: 150px;
    white-space: nowrap;
    font-size: 15px;
    align-self: flex-start;
  }
`;
