import styled from 'styled-components';

export const CardFormPDF = styled.div`
  padding: 15px;
  border: 1px solid #f2f2f2;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
`;

export const TitleFormPDF = styled.span`
  font-size: 10px;
  line-height: normal;
`;

export const TextFormPDF = styled.span`
  font-size: 14px;
  line-height: normal;
  color: #b4b6be;
`;
