import { SwapVert } from '@mui/icons-material';
import {
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { CardOffers, TitleOffers, useStyles } from '../styles';
import { Plan } from '../types';

interface OthersOffersProps {
  planos: Plan[];

  primeiraOferta: string;

  setPrimeiraOferta(primeiraOferta: string): void;

  segundaOferta: string;

  setSegundaOferta(segundaOferta: string): void;

  posPago: string;

  setPosPago(posPago: string): void;

  setMelhorOferta(melhorOferta: string): void;

  isChecked: boolean;
}

function OthersOffers({
  planos,
  primeiraOferta,
  segundaOferta,
  posPago,
  setPrimeiraOferta,
  setSegundaOferta,
  setPosPago,
  setMelhorOferta,
  isChecked,
}: OthersOffersProps) {
  const filterPlansControl = planos?.filter(
    plan => plan.segmento === 'CONTROLE',
  );

  const filterPlansPosPaid = planos?.filter(
    plan => plan.segmento === 'POS_PAGO',
  );

  const classes = useStyles();

  const handleSwap = () => {
    const [novaSegundaOferta, novaPrimeiraOferta] = [
      segundaOferta,
      primeiraOferta,
    ];

    setPrimeiraOferta(novaSegundaOferta);
    setSegundaOferta(novaPrimeiraOferta);

    if (isChecked) {
      setMelhorOferta(novaSegundaOferta);
    }
  };
  return (
    <CardOffers>
      <TitleOffers>Demais ofertas</TitleOffers>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <FormControl variant="outlined" margin="none" size="small" fullWidth>
          <InputLabel htmlFor="primeira" focused shrink>
            Primeira
          </InputLabel>
          {filterPlansControl && (
            <Select
              value={primeiraOferta}
              label="Primeira"
              onChange={e => {
                setPrimeiraOferta(e.target.value);
              }}
              input={<OutlinedInput id="primeira" label="Primeira" notched />}
              displayEmpty
              renderValue={selected => {
                if (selected === '') {
                  return (
                    <span className={classes.selectPlaceholder}>Selecione</span>
                  );
                }

                const filterSelectedPlan = filterPlansControl.find(
                  plan => plan.tim_id === selected,
                );

                return (
                  <span>
                    {filterSelectedPlan?.tim_id} -{filterSelectedPlan?.nome}
                  </span>
                );
              }}
            >
              <MenuItem value="">
                <span className={classes.selectPlaceholder} />
              </MenuItem>
              {filterPlansControl.map(plan => (
                <MenuItem key={plan.id} value={plan.tim_id}>
                  {plan.tim_id} - {plan.nome}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
        <FormGroup>
          <IconButton style={{ marginLeft: 'auto' }} onClick={handleSwap}>
            <SwapVert sx={{ fontSize: '1.9rem' }} />
          </IconButton>
        </FormGroup>
        <FormControl variant="outlined" margin="none" size="small" fullWidth>
          <InputLabel htmlFor="segunda" focused shrink>
            Segunda
          </InputLabel>
          {filterPlansControl && (
            <Select
              value={segundaOferta}
              label="Segunda"
              onChange={e => {
                setSegundaOferta(e.target.value);
              }}
              input={<OutlinedInput id="segunda" label="Segunda" notched />}
              displayEmpty
              renderValue={selected => {
                if (selected === '') {
                  return (
                    <span className={classes.selectPlaceholder}>Selecione</span>
                  );
                }

                const filterSelectedPlan = filterPlansControl.find(
                  plan => plan.tim_id === selected,
                );

                return (
                  <span>
                    {filterSelectedPlan?.tim_id} -{filterSelectedPlan?.nome}
                  </span>
                );
              }}
            >
              <MenuItem value="">
                <span className={classes.selectPlaceholder} />
              </MenuItem>
              {filterPlansControl.map(plan => (
                <MenuItem key={plan.id} value={plan.tim_id}>
                  {plan.tim_id} - {plan.nome}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </div>
      <FormControl variant="outlined" margin="none" size="small" fullWidth>
        <InputLabel htmlFor="pos-pago" focused shrink>
          Pós-pago
        </InputLabel>
        {filterPlansPosPaid && (
          <Select
            value={posPago}
            label="Pos-pago"
            onChange={e => {
              setPosPago(e.target.value);
            }}
            input={<OutlinedInput id="pos-pago" label="Pos-pago" notched />}
            displayEmpty
            renderValue={selected => {
              if (selected === '') {
                return (
                  <span className={classes.selectPlaceholder}>Selecione</span>
                );
              }

              const filterSelectedPlan = filterPlansPosPaid.find(
                plan => plan.tim_id === selected,
              );

              return (
                <span>
                  {filterSelectedPlan?.tim_id} -{filterSelectedPlan?.nome}
                </span>
              );
            }}
          >
            <MenuItem value="">
              <span className={classes.selectPlaceholder} />
            </MenuItem>
            {filterPlansPosPaid.map(plan => (
              <MenuItem key={plan.id} value={plan.tim_id}>
                {plan.tim_id} - {plan.nome}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </CardOffers>
  );
}

export default OthersOffers;
