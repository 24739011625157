import { useCallback, useEffect, useRef, useState } from 'react';
import { CardContent, CardHeader, SwipeableDrawer } from '@mui/material';
import { Card } from 'components/StyledComponents';
import { SimcardsFetch } from 'fetches/simcardsFetch';
import InfoSale from 'pages/Sale/InfoSale';
import { Sale } from 'pages/Sale/InfoSale/types';
import api from 'services/api';
import { useSaleSelected } from 'store/saleSelected';
import Pagination from 'components/Pagination';
import Filters, { RefFunction } from './Filters';
import SIMCardList from './SIMCardList';
import StatusSIMCard from './StatusSIMCard';
import PrintTag from './components/PrintTag';

export interface SaleResponse {
  id: number;
  url_etiqueta_envio: string;
}
interface DataResponse {
  status: string;
  sale: SaleResponse;
}

function Operation() {
  const filterRef = useRef<RefFunction>(null);

  const [hlrs, setHlrs] = useState('');
  const [sale, setSale] = useState<Sale>();
  const [currentPage, setCurrentPage] = useState(1);
  const { data: simcardsResponse, mutate } = SimcardsFetch(hlrs, currentPage);
  const [openModal, setOpenModal] = useState(false);
  const [statusSIMCard, setStatusSIMCard] = useState('');
  const [showInfoSale, setShowInfoSale] = useState(false);
  const [showStatusSIMCard, setShowStatusSIMCard] = useState(false);
  const saleSelected = useSaleSelected(state => state.saleSelected);
  const [saleFounded, setSaleFounded] = useState<SaleResponse | null>(null);
  const setSalesSelected = useSaleSelected(state => state.setSalesSelected);

  useEffect(() => {
    setShowInfoSale(!!saleSelected);
    if (saleSelected) {
      setSale(undefined);
      api.get(`/core/vendas/${saleSelected}`).then(({ data }) => {
        setSale(data);
      });
    }
  }, [saleSelected]);

  const handleSearchSIMCard = useCallback((iccid: string) => {
    setShowStatusSIMCard(false);
    iccid &&
      api
        .get(`/core/simcards/${iccid}`)
        .then(({ data }) => {
          const { status } = data as DataResponse;
          const saleResponse = (data as DataResponse).sale;
          if (status === 'NOVO') {
            setStatusSIMCard('NAO_ATIVADO');
            setShowStatusSIMCard(true);
            filterRef?.current?.clearLocalIccid();
            return;
          }
          window.open(saleResponse?.url_etiqueta_envio, '_blank');
          setSaleFounded(saleResponse);
          setOpenModal(true);
          filterRef?.current?.clearLocalIccid();
        })
        .catch(error => {
          if (error?.status === 404) {
            setStatusSIMCard('NAO_ENCONTRADO');
            setShowStatusSIMCard(true);
          }
        });
  }, []);
  const closeDrawer = useCallback(() => {
    setSalesSelected(0);
    setShowInfoSale(false);
  }, [setSalesSelected]);

  const updateSimCards = useCallback(() => {
    if (!simcardsResponse) {
      return;
    }
    const simcardsListFilters = simcardsResponse?.simcards.filter(
      simcard => simcard,
    );

    mutate(
      {
        ...simcardsResponse,
        ...{ simcards: simcardsListFilters },
      },
      true,
    );
  }, [mutate, simcardsResponse]);

  useEffect(() => {
    saleFounded && setOpenModal(true);
  }, [saleFounded]);
  return (
    <Card>
      <CardHeader
        title="Preparação de Pacotes"
        subheader="Painel para imprimir etiquetas de chips pendentes para envio"
      />
      <CardContent>
        <Filters
          ref={filterRef}
          handleSearchSIMCard={handleSearchSIMCard}
          setHlrs={setHlrs}
        />
        {showStatusSIMCard && <StatusSIMCard status={statusSIMCard} />}
        <SIMCardList
          simcards={simcardsResponse?.simcards}
          setSaleUpdateToSent={setSaleFounded}
          updateSimCards={updateSimCards}
        />
        {simcardsResponse && (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            lastPage={simcardsResponse?.paginationInfo.lastPage}
            perPage={simcardsResponse?.paginationInfo.perPage}
            total={simcardsResponse?.paginationInfo.total}
          />
        )}

        <SwipeableDrawer
          anchor="right"
          open={showInfoSale}
          onOpen={() => setShowInfoSale(true)}
          onClose={() => {
            closeDrawer();
          }}
        >
          <InfoSale
            sale={sale}
            setSale={setSale}
            closeDrawer={closeDrawer}
            showActionInSale={false}
          />
        </SwipeableDrawer>
        <PrintTag
          saleFounded={saleFounded}
          openModal={openModal}
          setOpenModal={setOpenModal}
          updateSimCards={updateSimCards}
        />
      </CardContent>
    </Card>
  );
}

export default Operation;
