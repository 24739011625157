import React, { useState } from 'react';

import { Button, FormControl, IconButton, TextField } from '@mui/material';
import { Add } from '@mui/icons-material';
import { GoTrashcan } from 'react-icons/go';
import api from 'services/api';
import { CancelButton } from 'components/StyledComponents';
import { useToast } from 'hooks/toast';
import { Setup } from 'models/setup';
import { TotalizatorDaysForCronFetch } from 'fetches/totalizatorDaysForCronFetch';
import {
  AddSetup,
  SaveButton,
  FieldInsertValues,
  Value,
  Values,
} from './styles';

interface SequenceFormProps {
  setup: Setup;
  closeDrawer(): void;
}

function SequenceForm({ setup, closeDrawer }: SequenceFormProps) {
  const [setupValue, setSetupValue] = useState(setup.value);
  const [newSetupValue, setNewSetupValue] = useState('');
  const isDiasReprocessamento = setup.key === 'DIAS_REPROCESSAMENTO';

  const { mutateTotalDays } = isDiasReprocessamento
    ? TotalizatorDaysForCronFetch()
    : { mutateTotalDays: null };

  const { addToast } = useToast();

  const handleUpdateTotalDays = () => {
    if (isDiasReprocessamento && mutateTotalDays) {
      mutateTotalDays();
    }
  };

  const handleSave = () => {
    api
      .put(`api/setups/${setup.key}`, {
        value: setupValue.toString(),
      })
      .then(() => {
        addToast({
          type: 'success',
          title: setup.title,
          description: 'Setup salvo com sucesso',
        });

        closeDrawer();
      })
      .catch(err => {
        let message = err.data.errors[0]?.message;
        if (!message) {
          message = err.data.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro!',
            description: message,
          });
      })
      .finally(() => {
        handleUpdateTotalDays();
      });
  };

  const handleAddNewSetup = () => {
    if (typeof setupValue !== 'string') {
      const newArray = [...setupValue];

      const newSetupValueSplited = newSetupValue.split(',');

      newSetupValueSplited.slice(0).reverse();

      newSetupValueSplited.map(current => {
        return (
          !newArray.find(e => e === current) &&
          current &&
          newArray.unshift(current)
        );
      });

      setSetupValue(newArray);
      setNewSetupValue('');
    }
  };

  const handleRemoveAllSetupValue = () => {
    setSetupValue([]);
  };

  const handleRemoveSetupValue = (key: number) => {
    if (typeof setupValue !== 'string') {
      const values = [...setupValue];
      if (key !== -1) {
        values.splice(key, 1);
        setSetupValue(values);
      }
    }
  };

  const handleTypedNewSetup = (newValue: string) => {
    if (setup.type === 'number') {
      setNewSetupValue(newValue.replace(/[^\d,.]/g, ''));
    }

    if (setup.type === 'string') {
      setNewSetupValue(newValue);
    }
  };

  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      style={{ paddingBottom: 30 }}
    >
      <AddSetup>
        <FieldInsertValues>
          <TextField
            fullWidth
            autoFocus
            label="Digite o novo valor"
            variant="outlined"
            value={newSetupValue}
            style={{ marginRight: '8px' }}
            type="text"
            onChange={e => {
              handleTypedNewSetup(e.target.value);
            }}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleAddNewSetup();
              }
            }}
          />

          <IconButton
            size="medium"
            onClick={handleAddNewSetup}
            disabled={!newSetupValue}
          >
            <Add />
          </IconButton>
        </FieldInsertValues>

        <SaveButton>
          <CancelButton onClick={handleRemoveAllSetupValue}>
            Remover todos os campos
          </CancelButton>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ marginRight: '3rem' }}
          >
            Salvar
          </Button>
        </SaveButton>
      </AddSetup>

      <Values>
        {typeof setupValue !== 'string' &&
          setupValue?.map((val, index) => (
            <Value key={val}>
              {val}
              <IconButton
                size="small"
                onClick={() => handleRemoveSetupValue(index)}
              >
                <GoTrashcan />
              </IconButton>
            </Value>
          ))}
      </Values>
    </FormControl>
  );
}

export default SequenceForm;
