import {
  Container,
  DataInfo,
  Divider,
  List,
} from 'components/StyledComponents';
import { Container as CardList } from 'components/CardList';
import { UtmSource } from 'models/utmSource';
import { Data, Header, DataText } from './styles';
import { BoxContainerConfiguration } from '../styles';

interface SourceListProps {
  configSourcesSelected: UtmSource[];
}
function SourceList({ configSourcesSelected }: SourceListProps) {
  return (
    <CardList>
      {configSourcesSelected.length ? (
        <>
          <Header>
            <span>Source</span>
            <span>Porcentagem</span>
          </Header>

          <List>
            {configSourcesSelected.map(config => (
              <Container key={config.id}>
                <Data>
                  <DataInfo>
                    <DataText>{config.nome}</DataText>
                    <DataText>{config.porcentagem}%</DataText>
                    <Divider />
                  </DataInfo>
                </Data>
              </Container>
            ))}
          </List>
        </>
      ) : (
        <BoxContainerConfiguration>
          <DataText>Nenhuma source encontrada</DataText>
        </BoxContainerConfiguration>
      )}
    </CardList>
  );
}

export default SourceList;
