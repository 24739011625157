import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import styled from 'styled-components';

export const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 164px 15px 38px;
  color: rgba(0, 0, 0, 0.4);
  span {
    :nth-child(1) {
      width: 275px;
      min-width: 200px;
    }
    :nth-child(2) {
      width: 80px;
    }
    :nth-child(4) {
      width: 40px;
    }
    :nth-child(6) {
      width: 75px;
    }
    :nth-child(7) {
      margin-right: 20px;
      width: 40px;
    }
    :nth-child(8) {
      margin-right: 60px;
    }

    min-width: 60px;
    width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    align-self: flex-start;
  }
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 15,
    },
    field: {
      marginBottom: 15,
    },
    box: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 12,

      [theme.breakpoints.down(1024)]: {
        flexDirection: 'column',
      },
    },
    subtitle: {
      color: 'rgba(0, 0, 0, 0.8)',
    },
    boxAlert: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      background: '#F5FCFF',
      color: '#00408C',
      justifyContent: 'space-between',
      padding: 20,
      margin: '20px 16px',
    },
    boxText: {
      display: 'flex',
      gap: '15px',
      color: '#00408C',
      textAlign: 'start',
      alignItems: 'center',
    },
    typographyText: {
      color: '#00408C',
    },
    buttonAccess: {
      width: 177,
      [theme.breakpoints.down(1024)]: {
        width: 85,
      },
    },
    endDateTimePicker: {
      marginLeft: 25,
      marginTop: 0,

      [theme.breakpoints.down(1024)]: {
        marginLeft: 0,
        marginTop: 12,
      },
    },
    formControl: {
      marginLeft: 25,
      marginTop: 8,

      [theme.breakpoints.down(1024)]: {
        marginLeft: 0,
      },
    },
    dialogActions: {
      justifyContent: 'space-between',
      display: 'flex',
      paddingRight: 24,
      paddingLeft: 16,
      marginTop: 30,
    },
    cardTotal: {
      display: 'flex',
      gap: 5,
      padding: 15,
      border: '1px solid #F2F2F2',
      boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: 6,
    },
  }),
);
