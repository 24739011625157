import { IntegrationErrorFetch } from 'fetches/integrationsErroFetch';
import { useToast } from 'hooks/toast';
import { useEffect, useState } from 'react';
import api from 'services/api';

export function useInfoIntegrationError(integrationErrorIdSelected: number) {
  const { addToast } = useToast();
  const { data: fetchResponse, mutate } = IntegrationErrorFetch(
    integrationErrorIdSelected,
  );

  const [description, setDescription] = useState('');
  const [friendlyMessage, setFriendlyMessage] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [blocker, setBlocker] = useState(false);
  const [treatable, setTreatable] = useState(false);
  const [sieve, setSieve] = useState(false);
  const [reprocessable, setReprocessable] = useState(false);

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (fetchResponse) {
      setDescription(fetchResponse?.descricao || '');
      setFriendlyMessage(fetchResponse?.mensagem_amigavel || '');
      setEmailMessage(fetchResponse?.mensagem_email || '');
      setBlocker(!!fetchResponse?.bloqueante);
      setTreatable(!!fetchResponse?.tratavel);
      setSieve(!!fetchResponse?.crivo);
      setReprocessable(!!fetchResponse?.reprocessavel);
    }

    return () => {
      setDescription('');
      setFriendlyMessage('');
      setEmailMessage('');
      setBlocker(false);
      setTreatable(false);
      setSieve(false);
      setReprocessable(false);
    };
  }, [fetchResponse]);

  function handleSave() {
    setSaving(true);

    const body = {
      descricao: description,
      mensagem_amigavel: friendlyMessage,
      mensagem_email: emailMessage,
      bloqueante: blocker,
      tratavel: treatable,
      crivo: sieve,
      reprocessavel: reprocessable,
    };

    api
      .put(`/core/erros-integracao/${integrationErrorIdSelected}`, body)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Informações salvas!',
          description:
            'As informações no erro de integração foram salvas com sucesso',
        });
        mutate();
        setSaving(false);
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro ao salvar informações',
          description: error?.data?.message,
        });
        setSaving(false);
      });
  }

  return {
    fetchResponse,
    description,
    setDescription,
    friendlyMessage,
    setFriendlyMessage,
    emailMessage,
    setEmailMessage,
    blocker,
    setBlocker,
    treatable,
    setTreatable,
    sieve,
    setSieve,
    reprocessable,
    setReprocessable,
    saving,
    handleSave,
  };
}
