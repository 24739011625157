import { TotalCreditAnalysis } from 'models/totalCreditAnalysis';
import api from 'services/api';
import userSWR from 'swr';

export const TotalCreditAnalysisFetch = () => {
  const { data, mutate: mutateTotalLeads } = userSWR<TotalCreditAnalysis>(
    `total_credit_analysis_batch`,
    async (): Promise<TotalCreditAnalysis> => {
      const { data: total } = await api.get(
        `core/elegibilidade/total-analises`,
      );

      return total as TotalCreditAnalysis;
    },
  );

  return { data, mutateTotalLeads };
};
