import { CardHeader, Grid, TextField } from '@mui/material';
import { Card } from 'components/StyledComponents';
import { useEffect } from 'react';
import { useStyles } from '../styles';

interface InfoPaymentProps {
  formaPagamento: string;
  setFormaPagamento(value: string): void;

  tipoFatura: string;
  setTipoFatura(value: string): void;

  vencimento: string;
  setVencimento(value: string): void;

  banco: string;
  setBanco(value: string): void;

  agencia: string;
  setAgencia(value: string): void;

  conta: string;
  setConta(value: string): void;

  setFormPaymentIsValid(value: boolean): void;
}

function InfoPayment({
  formaPagamento,
  setFormaPagamento,
  tipoFatura,
  setTipoFatura,
  vencimento,
  setVencimento,
  banco,
  setBanco,
  agencia,
  setAgencia,
  conta,
  setConta,
  setFormPaymentIsValid,
}: InfoPaymentProps) {
  const classes = useStyles();

  useEffect(() => {
    setFormPaymentIsValid(!!formaPagamento && !!tipoFatura && !!vencimento);
  }, [
    formaPagamento,
    tipoFatura,
    vencimento,
    banco,
    agencia,
    conta,
    setFormPaymentIsValid,
  ]);

  useEffect(() => {
    if (formaPagamento === 'Débito Automatico') {
      setFormPaymentIsValid(!!banco && !!agencia && !!conta);
    }
  }, [agencia, banco, conta, formaPagamento, setFormPaymentIsValid]);

  return (
    <Card className={classes.root}>
      <CardHeader title="Pagamento" className={classes.card} />
      <Grid container spacing={2} className={classes.grid}>
        <Grid item md={6} xs={12}>
          <TextField
            label="Forma de pagamento"
            variant="outlined"
            select
            value={formaPagamento}
            SelectProps={{ native: true }}
            onChange={e => setFormaPagamento(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            fullWidth
            error={formaPagamento === ''}
            helperText={
              formaPagamento === ''
                ? 'Campo forma de pagamento é obrigatório.'
                : ' '
            }
          >
            <option value="" disabled>
              Selecione uma forma de pagamento
            </option>

            <option value="Débito Automatico">Débito automático</option>
            <option value="Fatura">Fatura</option>
          </TextField>
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Tipo fatura"
            name="fatura"
            variant="outlined"
            select
            value={tipoFatura}
            SelectProps={{ native: true }}
            onChange={e => setTipoFatura(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            fullWidth
            error={tipoFatura === ''}
            helperText={
              tipoFatura === '' ? 'Campo tipo fatura é obrigatório.' : ' '
            }
          >
            <option value="" disabled>
              Selecione um tipo de fatura
            </option>

            <option value="Conta Online">Conta Online</option>
            <option value="Detalhada">Detalhada</option>
            <option value="Resumida">Resumida</option>
          </TextField>
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            value={vencimento}
            onChange={e => setVencimento(e.target.value)}
            aria-readonly
            fullWidth
            label="Vencimento"
            name="vencimento"
            variant="outlined"
            size="small"
            className={classes.textFieldTakesUpHalfTheScreen}
            error={vencimento === ''}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={
              vencimento === '' ? 'Campo vencimento é obrigatório.' : ' '
            }
          />
        </Grid>
        {formaPagamento === 'Débito Automatico' && (
          <>
            <Grid item md={6} xs={12}>
              <TextField
                value={banco}
                onChange={e => setBanco(e.target.value)}
                aria-readonly
                fullWidth
                label="Banco"
                name="banco"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                error={banco === ''}
                helperText={banco === '' ? 'Campo banco é obrigatório.' : ' '}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                value={agencia}
                onChange={e => setAgencia(e.target.value)}
                aria-readonly
                fullWidth
                label="Agência"
                name="agencia"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                error={agencia === ''}
                helperText={
                  agencia === '' ? 'Campo agência é obrigatório.' : ' '
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id={conta}
                value={conta}
                onChange={e => setConta(e.target.value)}
                aria-readonly
                fullWidth
                label="Conta"
                name="conta"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                error={conta === ''}
                helperText={conta === '' ? 'Campo conta é obrigatório.' : ' '}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
}

export default InfoPayment;
