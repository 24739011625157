import styled from 'styled-components';

export const BatchesPagination = styled.div`
  margin-right: 15px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

export const BackButton = styled.div`
  margin-left: 10px;
`;
