import { useEffect, useState } from 'react';
import Close from '@mui/icons-material/Close';
import {
  cell as cellFormatter,
  cep as cepFormatter,
  cpf as cpfFormatter,
  date as dateFormatter,
  dateTime as dateTimeFormatter,
  iccid as iccidFormatter,
} from 'util/formatter';
import { Grid, IconButton } from '@mui/material';
import { useSaleDrawer } from 'store/saleDrawerStore';
import {
  CloseButton,
  ContainerDrawer,
  DividerDrawer,
  SessionTitle,
} from 'components/StyledComponents';
import TextInfo from 'pages/Sale/InfoSale/TextInfo';
import Status from 'pages/Sale/InfoSale/Status';
import CancelForm from './CancelForm';
import { InfoSaleProps } from './types';

function InfoSale({
  closeDrawer,
  updateSelectedSale,
  showActionInSale = true,
}: InfoSaleProps) {
  const [status, setStatus] = useState('');
  const saleDrawer = useSaleDrawer(state => state.saleDrawer);

  useEffect(() => {
    setStatus(saleDrawer?.status || '');
    saleDrawer && updateSelectedSale && updateSelectedSale?.(saleDrawer);
  }, [saleDrawer, updateSelectedSale]);

  if (!Object.keys(saleDrawer).length) {
    return null;
  }

  return (
    <ContainerDrawer>
      <Grid container spacing={2}>
        <CloseButton onClick={() => closeDrawer()}>
          <IconButton>
            <Close />
          </IconButton>
        </CloseButton>
        <Grid item xs={12}>
          <Status loading={!saleDrawer} status={status} />
        </Grid>
        <Grid item xs={12} style={{ height: 45 }}>
          <SessionTitle>
            Dados do Pedido
            <DividerDrawer />
          </SessionTitle>
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!saleDrawer}
            label="Plano"
            info={saleDrawer?.plan?.nome}
            canCopy
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!saleDrawer}
            label="Fidelidade"
            info={saleDrawer?.loyalty?.descricao}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!saleDrawer}
            label="Segmento"
            info={saleDrawer?.plan?.segmento}
            canCopy
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!saleDrawer}
            label="Tipo"
            info={saleDrawer?.tipo}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo loading={!saleDrawer} label="DDD" info={saleDrawer?.ddd} />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!saleDrawer}
            label="MSISDN"
            info={cellFormatter(saleDrawer?.msisdn)}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextInfo
            loading={!saleDrawer}
            label="ICCID"
            info={iccidFormatter(saleDrawer?.iccid) || '-'}
            canCopy
            onlyNumbers
          />
        </Grid>
        {saleDrawer?.tipo === 'PORTABILIDADE' && (
          <>
            <Grid item xs={12} md={6}>
              <TextInfo
                loading={!saleDrawer}
                label="Provisório"
                info={cellFormatter(saleDrawer?.provisorio) || '-'}
                canCopy
                onlyNumbers
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInfo
                loading={!saleDrawer}
                label="Data Agendamento"
                info={dateTimeFormatter(saleDrawer?.data_portabilidade) || '-'}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!saleDrawer}
            label="Valor"
            info={Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(saleDrawer?.valor || 0)}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!saleDrawer}
            label="Vencimento"
            info={saleDrawer?.vencimento}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextInfo
            loading={!saleDrawer}
            label="Forma de Pagamento"
            info={saleDrawer?.forma_pagamento}
          />
        </Grid>
        <Grid item xs={12} style={{ height: 45 }}>
          <SessionTitle>
            Dados do Cliente
            <DividerDrawer />
          </SessionTitle>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!saleDrawer}
            label="Nome"
            info={saleDrawer?.customer?.nome}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!saleDrawer}
            label="CPF"
            info={saleDrawer && cpfFormatter(saleDrawer.customer.cpf)}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!saleDrawer}
            label="Email"
            info={saleDrawer?.customer.email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!saleDrawer}
            label="Data de Nascimento"
            info={dateFormatter(saleDrawer?.customer.nascimento)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!saleDrawer}
            label="Nome da Mãe"
            info={saleDrawer?.customer.filiacao}
            canCopy
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!saleDrawer}
            label="Telefone Pessoal"
            info={cellFormatter(saleDrawer?.customer.telefone)}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={12} style={{ height: 45 }}>
          <SessionTitle>
            Endereço
            <DividerDrawer />
          </SessionTitle>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextInfo
            loading={!saleDrawer}
            label="CEP"
            info={cepFormatter(saleDrawer?.cep)}
            canCopy
            onlyNumbers
          />
        </Grid>
        {saleDrawer?.tipo_identificador === 'NUMERO' && (
          <Grid item xs={12} md={12}>
            <TextInfo
              loading={!saleDrawer}
              label="Número"
              info={saleDrawer?.numero}
            />
          </Grid>
        )}
        {saleDrawer?.tipo_identificador === 'QUADRA_LOTE' && (
          <>
            <Grid item xs={6} md={6}>
              <TextInfo
                loading={!saleDrawer}
                label="Quadra"
                info={`Q${saleDrawer?.quadra}`}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextInfo
                loading={!saleDrawer}
                label="Lote"
                info={`L${saleDrawer?.lote}`}
              />
            </Grid>
          </>
        )}
        {saleDrawer?.tipo_identificador === 'SEM_NUMERO' && (
          <Grid item xs={12} md={12}>
            <TextInfo loading={!saleDrawer} label="Número" info="SN" />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!saleDrawer}
            label="Complemento ( Apto / Bloco / Sala )"
            info={saleDrawer?.complemento || '-'}
          />
        </Grid>
      </Grid>
      {showActionInSale && status === 'ANALISE_FRAUDE' && saleDrawer && (
        <CancelForm closeDrawer={closeDrawer} />
      )}
    </ContainerDrawer>
  );
}

export default InfoSale;
