import { CardHeader } from '@mui/material';
import { List } from 'components/StyledComponents';
import React from 'react';
import { Container, TopBar } from 'components/CardList';
import useBreakpoint from 'hooks/useBreakpoint';
import { Batch } from 'models/iccidBatch';
import Item from './Item';
import MobileItem from './MobileItem';

import { Header } from './styles';
import Filters from '../Filters';

interface BatchesListProps {
  batches?: Batch[];
  setSearch(search: string): void;
}

interface BatchesListBodyProps {
  batches?: Batch[];
  isTableOrLess: boolean;
}

interface LoadingListProps {
  isTableOrLess: boolean;
}

function LoadingBatchesList({ isTableOrLess }: LoadingListProps) {
  return isTableOrLess ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({ batches, isTableOrLess }: BatchesListBodyProps) {
  return (
    <List>
      {batches?.length ? (
        batches?.map((item: Batch) =>
          isTableOrLess ? (
            <MobileItem key={item.lote_id} data={item} />
          ) : (
            <Item key={item.lote_id} data={item} />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhum Lote cadastrado</h3>
      )}
    </List>
  );
}

function BatchesList({ batches, setSearch }: BatchesListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  return (
    <Container>
      <TopBar isDesktop={!isTableOrLess}>
        <CardHeader
          style={{ padding: '0px 0px 15px 0px' }}
          title="Listagem de lotes de SIM cards"
        />
        <Filters setSearch={setSearch} />
      </TopBar>

      {!isTableOrLess && (
        <Header>
          <span>Quantidade</span>
          <span>ICCID Início</span>
          <span>ICCID Fim</span>
          <span>HLR</span>
          <span>Status</span>
          <span>Data Inclusão</span>
          <span> </span>
        </Header>
      )}

      {batches ? (
        <Body batches={batches} isTableOrLess={isTableOrLess} />
      ) : (
        <LoadingBatchesList isTableOrLess={isTableOrLess} />
      )}
    </Container>
  );
}

export default BatchesList;
