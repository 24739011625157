import { Container } from 'components/CardList';
import { List } from 'components/StyledComponents';
import { PlansPriceFetch } from 'fetches/plansPriceFetch';
import useBreakpoint from 'hooks/useBreakpoint';
import { OfferMarketing } from 'models/offersMarketing';
import { useEffect, useState } from 'react';
import Item from './Item';
import MobileItem from './MobileItem';
import { Header } from './styles';

interface OffersLinksListProps {
  offers?: OfferMarketing[];
  setOfferSelected(value: OfferMarketing): void;
  handleOpenDeleteOfferDialog(): void;
}

interface BodyProps {
  offers?: OfferMarketing[];
  setOfferSelected(value: OfferMarketing): void;
  handleOpenDeleteOfferDialog(): void;
  isMobile: boolean;
}

interface LoadingListProps {
  isTableOrLess: boolean;
}

function LoadingList({ isTableOrLess }: LoadingListProps) {
  return isTableOrLess ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({
  offers,
  setOfferSelected,
  handleOpenDeleteOfferDialog,
  isMobile,
}: BodyProps) {
  const { data: prices } = PlansPriceFetch();
  const [offersList, setOfferList] = useState<OfferMarketing[]>();

  useEffect(() => {
    const filteredOffers = offers?.map(offer => {
      const matchingPrice = prices?.find(
        price => price.preco_id === offer.preco_id,
      );

      if (matchingPrice) {
        return {
          ...offer,
          preco_id: matchingPrice.descricao,
        };
      }

      return offer;
    });
    setOfferList(filteredOffers);
  }, [offers, prices]);

  return (
    <List>
      {offersList?.length ? (
        offersList?.map(data =>
          isMobile ? (
            <MobileItem
              key={data.id}
              data={data}
              setOfferSelected={setOfferSelected}
              handleOpenDeleteOfferDialog={handleOpenDeleteOfferDialog}
            />
          ) : (
            <Item
              key={data.id}
              data={data}
              setOfferSelected={setOfferSelected}
              handleOpenDeleteOfferDialog={handleOpenDeleteOfferDialog}
            />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhum FAQ encontrado</h3>
      )}
    </List>
  );
}

function OffersLinksList({
  offers,
  setOfferSelected,
  handleOpenDeleteOfferDialog,
}: OffersLinksListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Container>
      {!isTableOrLess && (
        <Header>
          <span>URL</span>
          <span>Fidelidade</span>
          <span>Plano</span>
        </Header>
      )}
      {offers ? (
        <Body
          offers={offers}
          isMobile={isTableOrLess}
          setOfferSelected={setOfferSelected}
          handleOpenDeleteOfferDialog={handleOpenDeleteOfferDialog}
        />
      ) : (
        <LoadingList isTableOrLess={isTableOrLess} />
      )}
    </Container>
  );
}

export default OffersLinksList;
