import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import pages from 'pages/_layouts/default/components/Menu/pages';
import SignIn from 'pages/SignIn';
import AuthLayout from 'pages/_layouts/auth';
import DefaultLayout from 'pages/_layouts/default';
import { useAuth } from 'hooks/auth';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import DefineHomeRoute from './RefineHomeRoute';

interface RoutesItems {
  moduleID: number;
  title: string;
  href: string;
  permission: string;
  showMenu: boolean;
  component: React.FC;
}

function MyRoutes() {
  const { hasPermission } = useAuth();
  const [routesItems, setRoutesItems] = useState<RoutesItems[]>([]);

  useEffect(() => {
    const pagesFiltered = pages
      .filter(
        item =>
          !!item.subModules.find((subitem: RoutesItems) =>
            hasPermission(subitem.permission),
          ),
      )
      .reduce((acc: RoutesItems[], current) => {
        return [...acc, ...current.subModules];
      }, []);

    setRoutesItems(pagesFiltered);
  }, [hasPermission]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DefineHomeRoute />} />
        <Route element={<DefaultLayout />}>
          {routesItems.map(route => {
            const Element = route.component;
            return (
              <Route key={route.href} path={route.href} element={<Element />} />
            );
          })}
        </Route>
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<SignIn />} />
          <Route path="/resetar-senha" element={<ForgotPassword />} />
          <Route path="/reset-senha/:token" element={<ResetPassword />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default MyRoutes;
