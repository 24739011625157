import create from 'zustand';

type SatisfactionSurveyStore = {
  showSatisfactionSurvey: boolean;
  setShowSatisfactionSurvey(showSatisfactionSurvey: boolean): void;

  idSatisfactionSurveySelected: string;
  setIdSatisfactionSurveySelected(idSatisfactionSurveySelected: string): void;

  today: boolean;
  setToday(today: boolean): void;

  yesterday: boolean;
  setYesterday(yesterday: boolean): void;

  lastWeek: boolean;
  setLastWeek(lasWeek: boolean): void;

  month: boolean;
  setMonth(month: boolean): void;

  startDate: string;
  setStartDate(startDate: string): void;

  endDate: string;
  setEndDate(endDate: string): void;
};

const defaultValues = {
  showSatisfactionSurvey: false,
  idSatisfactionSurveySelected: '',
  today: false,
  yesterday: false,
  lastWeek: false,
  month: false,
  startDate: '',
  endDate: '',
};

export const useSatisfactionSurveyStore = create<SatisfactionSurveyStore>(
  set => ({
    ...defaultValues,
    setShowSatisfactionSurvey(value) {
      set(state => ({ ...state, showSatisfactionSurvey: value }));
    },
    setIdSatisfactionSurveySelected(value) {
      set(state => ({ ...state, idSatisfactionSurveySelected: value }));
    },
    setToday(value) {
      set(state => ({ ...state, today: value }));
    },
    setYesterday(value) {
      set(state => ({ ...state, yesterday: value }));
    },
    setLastWeek(value) {
      set(state => ({ ...state, lastWeek: value }));
    },
    setMonth(value) {
      set(state => ({ ...state, month: value }));
    },
    setStartDate(value) {
      set(state => ({ ...state, startDate: value }));
    },
    setEndDate(value) {
      set(state => ({ ...state, endDate: value }));
    },
  }),
);
