import React, { useState } from 'react';
import {
  ClearOutlined,
  DeleteOutlineOutlined,
  DoneOutlined,
} from '@mui/icons-material';
import { Actions, UseStyles } from 'components/PopperActions';
import { Button, Popper } from '@mui/material';
import { useSimcardStock } from 'store/simcardStock';
import { Batch } from 'models/iccidBatch';

interface PopperActionsProps {
  id: string;
  open: boolean;
  batch: Batch;
  anchorElement: null | HTMLElement;
}

function PopperActions({ id, open, batch, anchorElement }: PopperActionsProps) {
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const classes = UseStyles();

  const setBatchSelected = useSimcardStock(state => state.setBatchSelected);
  const setShowActiveDialog = useSimcardStock(
    state => state.setShowActiveDialog,
  );
  const setShowDeactivateDialog = useSimcardStock(
    state => state.setShowDeactivateDialog,
  );
  const setShowDeleteDialog = useSimcardStock(
    state => state.setShowDeleteDialog,
  );

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorElement}
      placement="bottom"
      modifiers={[
        {
          name: 'flip',
          enabled: true,
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            boundariesElement: 'scrollParent',
          },
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      className={classes.popper}
    >
      <span className={classes.arrow} ref={setArrowRef} />
      <Actions className={classes.paper}>
        {batch?.status === 'ESTOQUE' && (
          <Button
            startIcon={<DoneOutlined />}
            fullWidth
            onClick={() => {
              batch && setBatchSelected(batch.lote_id);
              setShowActiveDialog(true);
            }}
          >
            Ativar
          </Button>
        )}

        {batch?.status === 'EM_USO' && (
          <Button
            startIcon={<ClearOutlined />}
            fullWidth
            onClick={() => {
              batch && setBatchSelected(batch.lote_id);
              setShowDeactivateDialog(true);
            }}
          >
            Desativar
          </Button>
        )}

        {batch?.can_batch_be_deleted && (
          <Button
            startIcon={<DeleteOutlineOutlined />}
            fullWidth
            onClick={() => {
              batch && setBatchSelected(batch.lote_id);
              setShowDeleteDialog(true);
            }}
          >
            Deletar
          </Button>
        )}
      </Actions>
    </Popper>
  );
}

export default PopperActions;
