import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Hidden, IconButton } from '@mui/material';
import InputIcon from '@mui/icons-material/Input';
import MenuIcon from '@mui/icons-material/Menu';

import { useAuth } from 'hooks/auth';
import useBreakpoint from 'hooks/useBreakpoint';

import logo from 'assets/Logo_Omega_Horizontal_white.svg';

import { HomeTitle, TextName, ToolBar } from './styles';

interface TopBarProps {
  onSidebarOpen(): void;
}
function Topbar({ onSidebarOpen }: TopBarProps) {
  const { signOut, user } = useAuth();
  const navigate = useNavigate();
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <AppBar>
      <ToolBar>
        <Link to="/">
          <HomeTitle src={logo} alt="Logo Omega" />
        </Link>
        <Hidden mdDown>
          <TextName isDesktop={!isTableOrLess}>
            Olá, <strong>{user.nome}</strong>
          </TextName>
          <IconButton
            onClick={() => {
              signOut();
              navigate('/');
            }}
            color="inherit"
          >
            <InputIcon style={{ color: '#FFFF' }} />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon style={{ color: '#FFFF' }} />
          </IconButton>
        </Hidden>
      </ToolBar>
    </AppBar>
  );
}

export default Topbar;
