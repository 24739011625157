import { useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
import { useSimcardStock } from 'store/simcardStock';

import { Box, Button, CardContent, CardHeader } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BatchesList from '../components/BatchesList';
import Indicators, { IndicatorsProp } from '../components/Indicators';
import palette from '../../../theme/palette';
import { Header } from '../../../components/StyledComponents';
import useBreakpoint from '../../../hooks/useBreakpoint';
import CreateDialog from '../components/CreateDialog';
import { IccidBatchesFetchByHLR } from '../../../fetches/iccidBatches';
import Pagination from '../components/BatchesList/Pagination';
import { BackButton, BatchesPagination, Footer } from './styles';
import ActiveDialog from './ActiveDialog';
import DeactivateDialog from './DeactivateDialog';
import DeleteDialog from './DeleteDialog';

function Details() {
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const hlrSelected = useSimcardStock(state => state.hlrSelected);
  const setHlrSelected = useSimcardStock(state => state.setHlrSelected);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');

  const navigate = useNavigate();

  const { data: response, mutate } = IccidBatchesFetchByHLR(
    hlrSelected,
    currentPage,
    search,
  );

  const [indicators, setIndicators] = useState<IndicatorsProp>({
    indicators: [
      {
        description: `LOTES DE`,
        value: '0',
      },
      {
        description: `SIMCARDS EM USO`,
        value: '0',
      },
      {
        description: `SIMCARDS EM ESTOQUE`,
        value: '0',
      },
    ],
  } as IndicatorsProp);

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  useEffect(() => {
    if (!response) {
      return;
    }

    const newIndicators = {
      indicators: [
        {
          description: `LOTES ${hlrSelected}`,
          value: String(response.batches.length),
        },
        {
          description: `SIMCARDS EM USO`,
          value: String(response.indicators.EM_USO.total),
        },
        {
          description: `SIMCARDS EM ESTOQUE`,
          value: String(response.indicators.ESTOQUE.total),
        },
      ],
    };

    setIndicators(newIndicators);
  }, [hlrSelected, response]);

  return (
    <Box>
      <Header>
        <CardHeader
          title="Estoque de SIM Cards"
          subheader="Painel de gestão de estoque de chips"
        />

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setShowCreateDialog(true);
          }}
        >
          {isTableOrLess ? (
            <Add style={{ color: palette.white }} />
          ) : (
            'Cadastrar simcards'
          )}
        </Button>
      </Header>

      <Indicators indicators={indicators.indicators} />

      <CardContent>
        <BatchesList batches={response?.batches} setSearch={setSearch} />
      </CardContent>

      <Footer>
        <BackButton>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setHlrSelected('');
              navigate('/estoque-simcard/');
            }}
          >
            Voltar
          </Button>
        </BackButton>

        <BatchesPagination>
          {response && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              lastPage={response?.paginationInfo?.lastPage}
              perPage={response?.paginationInfo?.perPage}
              total={response?.paginationInfo?.total}
            />
          )}
        </BatchesPagination>
      </Footer>

      <CreateDialog
        showCreateDialog={showCreateDialog}
        setShowCreateDialog={setShowCreateDialog}
        createAction={mutate}
      />

      <ActiveDialog activeAction={mutate} />

      <DeactivateDialog deactivateAction={mutate} />

      <DeleteDialog deleteAction={mutate} />
    </Box>
  );
}

export default Details;
