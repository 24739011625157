import styled from 'styled-components';

interface HeaderProps {
  isDesktop: boolean;
}

export const Header = styled.div<HeaderProps>`
  display: flex;
  align-items: ${props => (props.isDesktop ? 'center' : 'left')};
  justify-content: space-between;
  flex-direction: ${props => (props.isDesktop ? 'row' : 'column')};
  padding: 20px;
  gap: 16px;
`;

export const Actions = styled.div`
  display: flex;
  gap: 16px;

  .buttonNewOffer {
    width: 100px;
  }

  @media (max-width: 1350px) {
    gap: 20px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 15px;

    .buttonNewOffer {
      width: 100%;
    }
  }
`;
