import { Button } from '@mui/material';
import { Receipt } from '@mui/icons-material';
import React from 'react';

interface PrintTagProps {
  urlShippingLabel: string;
}

function PrintTag({ urlShippingLabel }: PrintTagProps) {
  const printTag = () => window.open(urlShippingLabel, '_blank');

  return (
    <Button
      type="button"
      color="secondary"
      fullWidth
      size="large"
      style={{ padding: 10 }}
      onClick={printTag}
      startIcon={<Receipt />}
    >
      Imprimir Etiquetas
    </Button>
  );
}

export default PrintTag;
