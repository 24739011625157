import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #daddec;
  box-shadow: 0px 0px 1px #daddec;
  border-radius: 6px;
`;

export const Title = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;

  overflow-wrap: break-word;
  width: 100%;
`;

export const NormalValue = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;

  color: #00c2ff;

  padding-top: 10px;
  padding-bottom: 10px;

  overflow-wrap: break-word;
  width: 100%;
`;

interface BooleanValueProps {
  value: string;
}

export const BooleanValue = styled.span<BooleanValueProps>`
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;

  color: ${p => (p.value === 'true' ? '#00c2ff' : 'rgba(5, 12, 39, 0.6);')};

  padding-top: 10px;
  padding-bottom: 10px;

  overflow-wrap: break-word;
  width: 100%;
`;

export const Sequence = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 10px;
`;

export const SequenceValue = styled.span`
  padding: 4px 16px;
  margin-right: 5px;
  margin-bottom: 10px;

  background: rgba(5, 12, 39, 0.6);
  border-radius: 20px;

  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #f5fcff;
`;

export const Description = styled.span`
  font-size: 12px;
  line-height: 15px;

  color: #616e98;

  overflow-wrap: break-word;
  width: 100%;
`;
