import { Bar } from 'react-chartjs-2';
import { shade } from 'polished';
import palette from 'theme/palette';
import { EvolutionTwelveMonthFetch } from 'fetches/evolutionTwelveMonthFetch';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useReportFilterFlagFetchStore } from 'store/useReportFilterFlagFetchStore';

interface TwelveMonthsEvolutionProps {
  day: string;
}

function TwelveMonthsEvolution({ day }: TwelveMonthsEvolutionProps) {
  const reprocessed = useReportFilterFlagFetchStore(
    state => state.withReprocessedFetch,
  );
  const inReprocessing = useReportFilterFlagFetchStore(
    state => state.inReprocessingFetch,
  );
  const engagement = useReportFilterFlagFetchStore(
    state => state.withEngagementFetch,
  );

  const { data: evolution } = EvolutionTwelveMonthFetch(
    day,
    reprocessed,
    inReprocessing,
    engagement,
  );

  const data = {
    labels: evolution ? Object.keys(evolution).map(key => key) : [],
    datasets: [
      {
        data: evolution
          ? Object.keys(evolution).map(key => evolution[key])
          : [],
        label: 'Total',
        backgroundColor: palette.total,
        hoverBackgroundColor: shade(0.2, palette.total),
      },
    ],
  };

  return (
    <div style={{ marginTop: 25, height: '100%', maxHeight: '250px' }}>
      <h2>Evolução 12 meses</h2>
      <Bar
        data={data}
        options={{
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              ticks: {
                minRotation: 0,
              },
            },
          },
          plugins: {
            datalabels: {
              anchor: 'end',
              align: 'end',
              offset: -2,
              color: 'rgba(0, 0, 0, 0.4)',
              font: { size: 10 },
              padding: 10,
            },
          },
        }}
        plugins={[ChartDataLabels]}
      />
    </div>
  );
}

export default TwelveMonthsEvolution;
