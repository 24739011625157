export const weekDays = [
  {
    day: 'Domingo',
    number: 0,
  },
  {
    day: 'Segunda',
    number: 1,
  },
  {
    day: 'Terça',
    number: 2,
  },
  {
    day: 'Quarta',
    number: 3,
  },
  {
    day: 'Quinta',
    number: 4,
  },
  {
    day: 'Sexta',
    number: 5,
  },
  {
    day: 'Sábado',
    number: 6,
  },
];
