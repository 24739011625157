import useSWR from 'swr';
import api from 'services/api';
import { UF } from 'models/uf';

interface Response {
  id: number;
  nome: string;
  sigla: number;
}

export const UFFetch = () => {
  const { data } = useSWR<UF[]>('uf', async (): Promise<UF[]> => {
    const { data: results } = await api.get(`/core/ufs/`);
    const ufs = results.map((result: Response) => {
      return {
        id: result.id,
        nome: result.nome.toUpperCase(),
        sigla: result.sigla,
      };
    });

    return ufs as UF[];
  });
  return { data };
};
