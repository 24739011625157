import { date as dateFormatter, hour as hourFormatter } from 'util/formatter';
import {
  MobileContainer,
  MobileData,
  MobileDataLabel,
} from 'components/StyledComponents';
import { GroupData } from 'components/MobileList';
import { Checkbox, Skeleton } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Badge } from '../Item/style';
import { ContextInfo, getContextBadgeSetup } from '../contextBadgeSetup';
import { MobileDataText, MobileDataLongText, Action, Group } from './styles';
import { ItemProps } from '../types';

function MobileItem({
  data,
  loading = false,
  setShowErrorInfo,
  setErrorSelected,
  selectedIDs,
  setSelectedIDs,
}: ItemProps) {
  const contextBadge = data
    ? getContextBadgeSetup(data.url)
    : ({} as ContextInfo);

  return (
    <MobileContainer>
      {loading ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        data && (
          <>
            <Action>
              {selectedIDs && setSelectedIDs && (
                <Checkbox
                  value={selectedIDs[data.id]}
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxOutlinedIcon color="primary" />}
                  style={{ marginBottom: 15 }}
                  onChange={e => {
                    setSelectedIDs({
                      ...selectedIDs,
                      [data.id]: e.target.checked,
                    });
                  }}
                />
              )}
            </Action>
            <Group
              onClick={() => {
                setShowErrorInfo && setShowErrorInfo(true);
                setErrorSelected && setErrorSelected(data);
              }}
            >
              <GroupData>
                <MobileData>
                  <MobileDataLabel>Hora</MobileDataLabel>
                  <MobileDataText>
                    {hourFormatter(data.datetime)}
                  </MobileDataText>
                </MobileData>
                <MobileData>
                  <MobileDataLabel>Data</MobileDataLabel>
                  <MobileDataText>
                    {dateFormatter(data.datetime)}
                  </MobileDataText>
                </MobileData>
              </GroupData>
              <MobileData>
                <MobileDataLabel>Contexto</MobileDataLabel>
                <MobileDataText>
                  <Badge badgeColor={contextBadge.color}>
                    {contextBadge.label}
                  </Badge>
                </MobileDataText>
              </MobileData>

              <MobileData>
                <MobileDataLabel>Mensagem</MobileDataLabel>
                <MobileDataLongText>
                  {data.response.message ? data.response.message : '-'}
                </MobileDataLongText>
              </MobileData>
            </Group>
          </>
        )
      )}
    </MobileContainer>
  );
}

export default MobileItem;
