import userSWR from 'swr';
import api from 'services/api';
import { User } from 'models/user';

export const UserByIdFetch = (id: number) => {
  const { data, mutate } = userSWR<User>(
    `user_${id}`,
    async (): Promise<User> => {
      const { data: results } = await api.get(`/core/usuarios/${id}`);

      return results as User;
    },
  );
  return { data, mutate };
};
