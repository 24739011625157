import Close from '@mui/icons-material/Close';
import {
  cell as cellFormatter,
  cep as cepFormatter,
  cpf as cpfFormatter,
  date as dateFormatter,
  dateTime as dateTimeFormatter,
  iccid as iccidFormatter,
} from 'util/formatter';
import {
  ActionDrawer,
  CloseButton,
  ContainerDrawer,
  DividerDrawer,
  SessionTitle,
} from 'components/StyledComponents';
import { Divider, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import Status from './Status';
import TextInfo from './TextInfo';
import { InfoSaleProps } from './types';
import LogHighlighter from './LogHighlighter';
import ActivateForm from './ActivateForm';
import OpenTracking from './OpenTracking';
import PrintTag from './PrintTag';
import CancelForm from './CancelForm';
import ChangeICCID from './ChangeICCIDForm';
import ReturnedForm from './ReturnedForm';
import DeliveredForm from './DeliveredForm';
import SIMCardTrackingForm from './SIMCardTrackingForm';
import EditCustomerForm from './EditCustomerForm';
import EditSaleDataForm from './EditSaleDataForm';
import EditAdressCustomerForm from './EditAddressCustomerForm';
import ReprocessSaleForm from './ReprocessSaleForm';
import GenerateTag from './GenerateTag';
import ActivateBlueChip from './ActivateBlueChip';
import ConsultStatus from './ConsultStatus';
import RequestedDocuments from './RequestedDocuments';
import EligibilityForm from './EligibilityForm';
import { Logs } from '../style';
import MarkAsNotFraud from './MarkAsNotFraud';
import GetLinkSerasa from './GetLinkSerasa';
import { useInfoSale } from './hooks/useInfoSale';
import ActivatedOnSiebel from './ActivatedOnSiebel';
import CrosscoreAnalysis from './CrosscoreAnalysis';

function InfoSale({
  sale,
  setSale,
  closeDrawer,
  updateSelectedSale,
  showActionInSale = true,
}: InfoSaleProps) {
  const {
    status,
    showButtonToChangeICCID,
    showButtonTrackingStatus,
    showButtonEditCustomerData,
    showButtonEditSaleData,
    loadingGetLinkSerasa,
    showGetLinkSerasa,
    linkSerasa,
    setShowGetLinkSerasa,
    handleGetLinkSerasa,
  } = useInfoSale({ sale, updateSelectedSale });

  return (
    <ContainerDrawer>
      <Grid container spacing={2}>
        <CloseButton onClick={() => closeDrawer()}>
          <IconButton>
            <Close />
          </IconButton>
        </CloseButton>
        <Grid item xs={12}>
          <Status loading={!sale} status={status} />
        </Grid>
        {showActionInSale && status === 'NOVO' && sale && (
          <Grid item xs={12}>
            <ActivateForm sale={sale} setSale={setSale} />
          </Grid>
        )}
        {showActionInSale && showButtonToChangeICCID && sale && (
          <Grid item xs={12}>
            <ChangeICCID sale={sale} setSale={setSale} />
          </Grid>
        )}
        {showActionInSale &&
          status === 'EM_TRANSPORTE_EXPRESSO' &&
          sale?.transporte_expresso &&
          sale?.iccid &&
          sale && (
            <Grid item xs={12}>
              <ActivateBlueChip sale={sale} setSale={setSale} />
            </Grid>
          )}
        {showActionInSale &&
          (status === 'ENVIADO' || status === 'AGUARDANDO_RETIRADA') &&
          sale?.tipo !== 'MIGRACAO' &&
          sale && (
            <Grid item xs={12}>
              <ReturnedForm sale={sale} setSale={setSale} />
            </Grid>
          )}
        {showActionInSale && showButtonTrackingStatus && sale && (
          <Grid item xs={12}>
            <DeliveredForm sale={sale} setSale={setSale} />
          </Grid>
        )}
        {showActionInSale && showButtonTrackingStatus && sale && (
          <Grid item xs={12}>
            <SIMCardTrackingForm sale={sale} setSale={setSale} />
          </Grid>
        )}
        {showActionInSale &&
          sale?.url_rastreamento &&
          status !== 'EM_TRANSPORTE_EXPRESSO' &&
          status !== 'ATIVADO' && (
            <Grid item xs={12} alignItems="center">
              <OpenTracking trackingURL={sale.url_rastreamento} />{' '}
            </Grid>
          )}
        {showActionInSale &&
          sale?.url_etiqueta_envio &&
          status !== 'EM_TRANSPORTE_EXPRESSO' &&
          status !== 'ATIVADO' && (
            <Grid item xs={12}>
              <PrintTag urlShippingLabel={sale.url_etiqueta_envio} />{' '}
            </Grid>
          )}
        {showActionInSale &&
          status === 'CANCELADO' &&
          (sale?.tipo === 'MIGRACAO' || sale?.tipo === 'NOVA_LINHA') &&
          sale && (
            <Grid item xs={12}>
              <ReprocessSaleForm sale={sale} setSale={setSale} />
            </Grid>
          )}
        {showActionInSale &&
          (sale?.tipo === 'NOVA_LINHA' || sale?.tipo === 'PORTABILIDADE') &&
          status === 'PENDENTE_ENVIO' &&
          sale && (
            <Grid item xs={12}>
              <GenerateTag sale={sale} setSale={setSale} />
            </Grid>
          )}
        {showActionInSale && status === 'EM_PROCESSAMENTO' && sale && (
          <Grid item xs={12}>
            <ConsultStatus sale={sale} setSale={setSale} />
          </Grid>
        )}
        {sale && (
          <Grid item xs={12}>
            <EligibilityForm sale={sale} setSale={setSale} />
          </Grid>
        )}
        {showActionInSale && !sale?.passou_antifraude && sale && (
          <Grid item xs={12}>
            <MarkAsNotFraud sale={sale} setSale={setSale} />
          </Grid>
        )}
        {showActionInSale &&
          status === 'NOVO' &&
          (sale?.tipo === 'NOVA_LINHA' || sale?.plan.segmento === 'POS_PAGO') &&
          !sale?.documentacao_solicitada &&
          sale && (
            <Grid item xs={12}>
              <RequestedDocuments sale={sale} setSale={setSale} />
            </Grid>
          )}
        {showActionInSale &&
          (status === 'NOVO' || status === 'CANCELADO') &&
          sale && (
            <Grid item xs={12}>
              <ActivatedOnSiebel sale={sale} setSale={setSale} />
            </Grid>
          )}
        {showActionInSale && !sale?.status_crosscore && sale && (
          <Grid item xs={12}>
            <CrosscoreAnalysis sale={sale} setSale={setSale} />
          </Grid>
        )}
        {showActionInSale && sale?.status_crosscore === 'REFER' && sale && (
          <Grid item xs={12}>
            <GetLinkSerasa
              loadingGetLinkSerasa={loadingGetLinkSerasa}
              showGetLinkSerasa={showGetLinkSerasa}
              linkSerasa={linkSerasa}
              setShowGetLinkSerasa={setShowGetLinkSerasa}
              handleGetLinkSerasa={handleGetLinkSerasa}
            />
          </Grid>
        )}
        <Grid item xs={12} style={{ height: 45 }}>
          <SessionTitle>
            Dados do Pedido
            {sale && showButtonEditSaleData && (
              <ActionDrawer>
                <EditSaleDataForm sale={sale} setSale={setSale} />
              </ActionDrawer>
            )}
            <DividerDrawer />
          </SessionTitle>
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Plano"
            info={sale?.plan.nome}
            canCopy
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Fidelidade"
            info={sale?.loyalty?.descricao}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Segmento"
            info={sale?.plan.segmento}
            canCopy
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo loading={!sale} label="Tipo" info={sale?.tipo} />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo loading={!sale} label="DDD" info={sale?.ddd} />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="MSISDN"
            info={cellFormatter(sale?.msisdn)}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextInfo
            loading={!sale}
            label="ICCID"
            info={iccidFormatter(sale?.iccid) || '-'}
            canCopy
            onlyNumbers
          />
        </Grid>
        {sale?.tipo === 'PORTABILIDADE' && (
          <>
            <Grid item xs={12} md={3}>
              <TextInfo
                loading={!sale}
                label="Número Provisório"
                info={cellFormatter(sale?.provisorio) || '-'}
                canCopy
                onlyNumbers
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextInfo
                loading={!sale}
                label="Possui provisório?"
                info={sale?.possui_provisorio ? 'Sim' : 'Não'}
                canCopy
                onlyNumbers
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInfo
                loading={!sale}
                label="Data Agendamento"
                info={dateTimeFormatter(sale?.data_portabilidade) || '-'}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="Valor"
            info={Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(sale?.valor || 0)}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="Vencimento"
            info={sale?.vencimento}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextInfo
            loading={!sale}
            label="Forma de Pagamento"
            info={sale?.forma_pagamento}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Data Criação"
            info={sale?.created_at ? dateTimeFormatter(sale?.created_at) : '-'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Data Ativação"
            info={
              sale?.data_ativacao ? dateTimeFormatter(sale?.data_ativacao) : '-'
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Código de Rastreamento"
            info={sale?.codigo_rastreamento || '-'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Oferta Flash"
            info={
              sale?.configuration_package_with_trashed?.flash_sale?.gb
                ? `+ ${sale?.configuration_package_with_trashed?.flash_sale?.gb}GB Promocionais`
                : '-'
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Oferta Portabilidade"
            info={
              sale?.tipo === 'PORTABILIDADE' &&
              sale?.configuration_package_with_trashed?.portability?.gb
                ? `+ ${sale?.configuration_package_with_trashed?.portability?.gb}GB`
                : '-'
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Oferta C6"
            info={
              sale?.configuration_package_with_trashed?.mostrar_bonus_c6 &&
              sale?.configuration_package_with_trashed?.c6?.gb
                ? `+ ${sale?.configuration_package_with_trashed?.c6?.gb}GB`
                : '-'
            }
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="Origem"
            info={sale?.utm_source || '-'}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="Mídia"
            info={sale?.utm_medium || '-'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Campanha"
            info={sale?.utm_campaign || '-'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Venda ID"
            info={sale?.id.toString() || '-'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Motivo Cancelamento"
            info={sale?.motivo_cancelamento || '-'}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="IP do cliente"
            info={(sale && sale.ip) || '-'}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="Reprocessada"
            info={sale?.reprocessada ? 'Sim' : 'Não'}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="Afiliado"
            info={sale?.lead_afiliado ? 'Sim' : 'Não'}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="Engajamento"
            info={sale?.engajamento ? 'Sim' : 'Não'}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="BlueChip"
            info={sale?.transporte_expresso ? 'Sim' : 'Não'}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="Rep. Manual"
            info={sale?.reprocessada_manual ? 'Sim' : 'Não'}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextInfo
            loading={!sale}
            label="Operador"
            info={(sale && sale.operator?.nome) || '-'}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={6}>
          <TextInfo
            loading={!sale}
            label="Protocolo"
            info={sale?.protocolo || '-'}
          />
        </Grid>
        {status === 'NOVO' && sale?.plan.segmento === 'POS_PAGO' && (
          <Grid item xs={6} md={3}>
            <TextInfo
              loading={!sale}
              label="Documentação solicitada"
              info={sale?.documentacao_solicitada ? 'Sim' : 'Não'}
              canCopy
              onlyNumbers
            />
          </Grid>
        )}
        <Grid item xs={12} style={{ height: 45 }}>
          <SessionTitle>
            Dados da Elegibilidade
            <DividerDrawer />
          </SessionTitle>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="ID"
            info={sale?.eligibility?.id.toString() || '-'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="DDD"
            info={sale?.eligibility?.ddd || '-'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Origem"
            info={sale?.eligibility?.origem || '-'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Token"
            info={sale?.eligibility?.token || '-'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Vendedor UF"
            info={sale?.eligibility?.vendedor_uf || '-'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Custcode"
            info={sale?.eligibility?.vendedor_custcode || '-'}
          />
        </Grid>
        <Grid item xs={12} style={{ height: 45 }}>
          <SessionTitle>
            Dados de Token
            <DividerDrawer />
          </SessionTitle>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Token Validado"
            info={sale?.token_validado ? 'Sim' : 'Não'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Token SMS"
            info={sale?.token_sms || '-'}
          />
        </Grid>
        <Grid item xs={12} style={{ height: 45 }}>
          <SessionTitle>
            Dados de Anti-Fraude
            <DividerDrawer />
          </SessionTitle>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Passou Antifraude"
            info={sale?.passou_antifraude ? 'Sim' : 'Não'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Status Crosscore"
            info={sale?.status_crosscore || 'Sem Status'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Erro Crosscore"
            info={sale?.erro_crosscore || '-'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Status Brflow"
            info={sale?.status_brflow || '-'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Protocolo Brflow"
            info={sale?.protocolo_brflow || '-'}
          />
        </Grid>
        <Grid item xs={12} style={{ height: 45 }}>
          <SessionTitle>
            Dados do Cliente
            {sale && showButtonEditCustomerData && (
              <ActionDrawer>
                <EditCustomerForm sale={sale} setSale={setSale} />
              </ActionDrawer>
            )}
            <DividerDrawer />
          </SessionTitle>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo loading={!sale} label="Nome" info={sale?.customer?.nome} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="CPF"
            info={sale && cpfFormatter(sale.customer.cpf)}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo loading={!sale} label="Email" info={sale?.customer.email} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInfo
            loading={!sale}
            label="Data de Nascimento"
            info={dateFormatter(sale?.customer.nascimento)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Nome da Mãe"
            info={sale?.customer.filiacao}
            canCopy
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInfo
            loading={!sale}
            label="Telefone Pessoal"
            info={cellFormatter(sale?.customer.telefone)}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={12} style={{ height: 45 }}>
          <SessionTitle>
            Endereço
            {showButtonEditSaleData && sale && (
              <ActionDrawer>
                <EditAdressCustomerForm sale={sale} setSale={setSale} />
              </ActionDrawer>
            )}
            <DividerDrawer />
          </SessionTitle>
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo
            loading={!sale}
            label="CEP"
            info={cepFormatter(sale?.cep)}
            canCopy
            onlyNumbers
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInfo loading={!sale} label="Estado" info={sale?.uf} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInfo loading={!sale} label="Cidade" info={sale?.cidade} />
        </Grid>
        <Grid item xs={6}>
          <TextInfo loading={!sale} label="Rua" info={sale?.logradouro} />
        </Grid>
        {sale?.tipo_identificador === 'NUMERO' && (
          <Grid item xs={6} md={3}>
            <TextInfo loading={!sale} label="Número" info={sale?.numero} />
          </Grid>
        )}
        {sale?.tipo_identificador === 'QUADRA_LOTE' && (
          <>
            <Grid item xs={3} md={3}>
              <TextInfo
                loading={!sale}
                label="Quadra"
                info={`Q${sale?.quadra}`}
              />
            </Grid>
            <Grid item xs={3} md={3}>
              <TextInfo loading={!sale} label="Lote" info={`L${sale?.lote}`} />
            </Grid>
          </>
        )}
        {sale?.tipo_identificador === 'SEM_NUMERO' && (
          <Grid item xs={6} md={3}>
            <TextInfo loading={!sale} label="Número" info="SN" />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextInfo loading={!sale} label="Bairro" info={sale?.bairro} />
        </Grid>
        <Grid item xs={12} md={6} sx={{ marginBottom: 2 }}>
          <TextInfo
            loading={!sale}
            label="Complemento ( Apto / Bloco / Sala )"
            info={sale?.complemento || '-'}
          />
        </Grid>
        {showActionInSale &&
          status !== 'CANCELADO' &&
          status !== 'ATIVADO' &&
          sale && (
            <Grid item xs={12}>
              <CancelForm sale={sale} setSale={setSale} />{' '}
            </Grid>
          )}
        {sale?.logs && sale?.logs.length > 0 && (
          <>
            <Grid item xs={12} style={{ height: 45 }}>
              <SessionTitle>
                Dados da API
                <DividerDrawer />
              </SessionTitle>
            </Grid>
            <Grid item xs={12}>
              {sale?.logs.map(item => (
                <React.Fragment key={item.id}>
                  <Logs>
                    <Typography>
                      {dateTimeFormatter(item.created_at)}
                    </Typography>
                    <LogHighlighter
                      key={item.id}
                      log={JSON.stringify(item.log, undefined, 2)}
                    />
                  </Logs>
                  <Divider />
                </React.Fragment>
              ))}
            </Grid>
          </>
        )}
        {sale?.logs_serasa && sale?.logs_serasa.length > 0 && (
          <>
            <Grid item xs={12} style={{ height: 45 }}>
              <SessionTitle>
                Logs Anti Fraude
                <DividerDrawer />
              </SessionTitle>
            </Grid>
            <Grid item xs={12}>
              {sale?.logs_serasa.map(item => (
                <>
                  <Logs>
                    <Typography>
                      {dateTimeFormatter(item.created_at)}
                    </Typography>
                    <LogHighlighter
                      key={item.id}
                      log={JSON.stringify(item.log, undefined, 2)}
                    />
                  </Logs>
                  <Divider />
                </>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </ContainerDrawer>
  );
}

export default InfoSale;
