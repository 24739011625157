import CardStar from './CardStar';
import CardTotalizer from './CardTotalizer';
import { Content, ContentRow } from './styles';
import { useTotalizerFetches } from './hooks/useTotalizerFetches';

interface TotalizerProps {
  nota: string | null;
  origem: string | null;
  onlyWithComment: boolean;
}

function Totalizer({ nota, origem, onlyWithComment }: TotalizerProps) {
  const {
    averageResponse,
    withEvaluationResponse,
    noEvaluationResponse,
    withCommentResponse,
    countStarsResponse,
  } = useTotalizerFetches({ nota, origem, onlyWithComment });

  return (
    <Content>
      <ContentRow>
        <CardTotalizer
          type="media"
          subtitle="Avaliação"
          title="Média"
          data={averageResponse}
        />
        <CardTotalizer
          type="com_avaliacao"
          subtitle="Vendas"
          title="Avaliadas"
          data={withEvaluationResponse?.toString()}
        />
        <CardTotalizer
          type="sem_avaliacao"
          subtitle="Sem"
          title="Avaliação"
          data={noEvaluationResponse?.toString()}
        />
        <CardTotalizer
          type="com_comentario"
          subtitle="Total"
          title="Comentários"
          data={withCommentResponse?.toString()}
        />
      </ContentRow>
      <ContentRow>
        <CardStar starCount="1" total={countStarsResponse?.notas[1]} />
        <CardStar starCount="2" total={countStarsResponse?.notas[2]} />
        <CardStar starCount="3" total={countStarsResponse?.notas[3]} />
        <CardStar starCount="4" total={countStarsResponse?.notas[4]} />
        <CardStar starCount="5" total={countStarsResponse?.notas[5]} />
      </ContentRow>
    </Content>
  );
}

export default Totalizer;
