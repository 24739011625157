import { MTheme } from 'models/theme';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { Box } from '@mui/material';

interface ContentProps {
  color?: string;
}

export const Content = styled(Box)<ContentProps>`
  display: flex;
  width: 100%;
  background-color: ${props => props.color};

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const BoxIcon = styled(Box)<ContentProps>`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.color};
  min-width: 54px;
  align-items: center;
  padding-top: 15px;
`;

const useStyles = makeStyles((theme: MTheme) => ({
  root: {
    display: 'flex',
    minHeight: 136,
    width: '100%',
    borderRadius: 6,
    filter: 'box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2)',
    border: '1px solid #F2F2F2',
  },
  contentTotal: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '15px',
    width: '100%',

    [theme.breakpoints.down(960)]: {
      flexDirection: 'row',
      paddingBottom: '0px',
    },
  },
  icon: {
    fontSize: 25,
  },
  subtitle: {
    fontWeight: 400,
  },
  title: {
    fontWeight: 500,
    marginBottom: 15,
  },
  h3: {
    fontWeight: 700,
  },
  h3Mobile: {
    marginLeft: 'auto',
    fontWeight: 700,
    color: theme.palette.controle,
    padding: 15,
  },
}));

export default useStyles;
