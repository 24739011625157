import { Card } from 'components/StyledComponents';
import { PlansPriceFetch } from 'fetches/plansPriceFetch';
import { useToast } from 'hooks/toast';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { ConfigurationPackageByIdPlanFetch } from 'fetches/configurationPackageByIdPlanFetch';
import { PlanPrice } from 'models/plansPrice';
import FormOfferLink from '../FormOfferLink';
import { useStyles } from '../styles';

function NewOfferLink() {
  const [urlSlug, setUrlSlug] = useState('');
  const [fidelity, setFidelity] = useState('sim');
  const [prohibitPaymentMethod, setProhibitPaymentMethod] = useState('nao');
  const [planSelected, setPlanSelected] = useState<PlanPrice | null>(null);
  const [idOfferSelected, setIdOfferSelected] = useState('');
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [segment, setSegment] = useState('');

  const { data: pricesPlan } = PlansPriceFetch(segment);
  const { data: configs } = ConfigurationPackageByIdPlanFetch(
    typeof planSelected?.plano_id === 'number' ? planSelected.plano_id : 0,
  );

  const { addToast } = useToast();
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    setIsValid(!!(urlSlug && fidelity && planSelected?.preco_id));
  }, [fidelity, planSelected?.preco_id, urlSlug]);

  const handleSave = useCallback(() => {
    setLoading(true);
    api
      .post(`/core/ofertas/criar`, {
        url_slug: urlSlug,
        fidelidade: fidelity === 'sim' ? 1 : 0,
        proibir_troca_forma_pagamento: prohibitPaymentMethod === 'sim' ? 1 : 0,
        preco_id: planSelected?.preco_id,
        pacote_configuracoes_id: idOfferSelected,
      })
      .then(() => {
        addToast({
          type: 'success',
          title: 'Link criado',
          description: `O link ${urlSlug} foi criado com sucesso no sistema.`,
        });

        navigate('/links-ofertas/');
      })
      .catch(error => {
        let { message } = error.data;

        if (!message) {
          message = error.data.errors[0]?.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao criar link!',
            description: message,
          });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    addToast,
    fidelity,
    idOfferSelected,
    navigate,
    planSelected?.preco_id,
    prohibitPaymentMethod,
    urlSlug,
  ]);

  return (
    <Card className={classes.card}>
      <FormOfferLink
        urlSlug={urlSlug}
        setUrlSlug={setUrlSlug}
        fidelity={fidelity}
        setFidelity={setFidelity}
        prohibitPaymentMethod={prohibitPaymentMethod}
        setProhibitPaymentMethod={setProhibitPaymentMethod}
        idOfferSelected={idOfferSelected}
        setIdOfferSelected={setIdOfferSelected}
        planSelected={planSelected}
        setPlanSelected={setPlanSelected}
        segment={segment}
        setSegment={setSegment}
        plans={pricesPlan}
        configs={configs}
        classes={classes}
        isValid={isValid}
        loading={loading}
        navigate={navigate}
        handleRequisitionRequest={handleSave}
      />
    </Card>
  );
}

export default NewOfferLink;
