import { useEffect, useState } from 'react';

import { Card } from 'components/StyledComponents';
import { CardHeader, SwipeableDrawer } from '@mui/material';

import { SetupsFetch } from 'fetches/setupsFetch';
import { Setup } from 'models/setup';
import useBreakpoint from 'hooks/useBreakpoint';
import { BoxHeader, TopBar } from 'components/CardList';
import { useAuth } from 'hooks/auth';
import { gestaoSetupCategoriaVendasReprocessamento } from 'constants/permissions';
import SetupEdit from './Components/SetupEdit';
import ManageSetupList from './Components/ManageSetupList';
import Totalizator from './Components/Totalizator';

function ManageSetup() {
  const { data: categories, mutate } = SetupsFetch();

  const { hasPermission } = useAuth();

  const [showSetupEdition, setShowSetupEdition] = useState(false);
  const [setupSelected, setSetupSelected] = useState<Setup>();
  const [showTotalizatorLeads, setShowTotalizatorLeads] = useState(false);

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  useEffect(() => {
    const shouldShow = hasPermission(gestaoSetupCategoriaVendasReprocessamento);

    setShowTotalizatorLeads(!!shouldShow);
  }, [hasPermission]);

  return (
    <Card>
      <TopBar isDesktop={!isTableOrLess}>
        <CardHeader
          title="Gestão de Setups"
          subheader="Edite, ative e desative os setups do admin"
        />
        {showTotalizatorLeads && (
          <BoxHeader isDesktop={!isTableOrLess}>
            <Totalizator isDesktop={!isTableOrLess} />
          </BoxHeader>
        )}
      </TopBar>

      <ManageSetupList
        categories={categories}
        setShowSetupEdition={setShowSetupEdition}
        setSetupSelected={setSetupSelected}
      />

      <SwipeableDrawer
        anchor="right"
        onClose={() => {
          setSetupSelected(undefined);
          setShowSetupEdition(false);
        }}
        onOpen={() => setShowSetupEdition(true)}
        open={showSetupEdition}
      >
        {setupSelected && (
          <SetupEdit
            setup={setupSelected}
            closeDrawer={() => {
              setSetupSelected(undefined);
              setShowSetupEdition(false);
              mutate();
            }}
          />
        )}
      </SwipeableDrawer>
    </Card>
  );
}

export default ManageSetup;
