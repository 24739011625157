import { Grid, IconButton } from '@mui/material';
import { ButtonProgress, Form } from 'components/StyledComponents';
import { GetApp } from '@mui/icons-material';
import { convertDateInString as dateFormatter } from 'util/formatter';
import { BaseData } from 'models/baseWithConfigurationById';
import React, { FormEvent, useState } from 'react';
import { useToast } from 'hooks/toast';
import api from 'services/api';
import { format } from 'date-fns';
import { downloadCsvFile } from 'util/blob';
import { BoxBase, TextBoxBase, TitleBase, TitleBoxBase } from '../../styles';

interface CardBaseViewProps {
  id: number;
  baseData: BaseData;
}

function CardBaseView({ id, baseData }: CardBaseViewProps) {
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const params = {
      base_id: id,
    };

    setLoading(true);

    const currentDate = new Date();
    const formattedCurrentDate = format(currentDate, 'yyyy-MM-dd HH:mm:ss');

    const baseName = baseData.nome;

    api
      .get('/core/impactos/clientes-engajar', {
        responseType: 'blob',
        params,
      })
      .then(({ data }) => {
        const filename = `${baseName}-${formattedCurrentDate}`;
        downloadCsvFile({ filename, file: data });
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro o baixar o relatório!',
          description:
            err.data && err.data.message ? err.data.message : err.message,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Grid container item spacing={2}>
      <Grid item md={12} xs={12}>
        <TitleBase>Visualização de base</TitleBase>
      </Grid>
      <Grid item md={6} xs={12}>
        <BoxBase>
          <TitleBoxBase>Criado</TitleBoxBase>
          <TextBoxBase>{dateFormatter(baseData.created_at)}</TextBoxBase>
        </BoxBase>
      </Grid>
      <Grid item md={6} xs={12}>
        <BoxBase>
          <TitleBoxBase>Base</TitleBoxBase>
          <TextBoxBase>{baseData.nome}</TextBoxBase>
        </BoxBase>
      </Grid>
      <Grid item md={6} xs={12}>
        <BoxBase>
          <TitleBoxBase>Status</TitleBoxBase>
          <TextBoxBase>
            {(() => {
              switch (baseData.status) {
                case 'ERRO':
                  return `${baseData.status}: ${baseData.mensagem}`;
                case 'AGENDADO':
                  return `${baseData.status}: ${baseData.data_agendamento}`;
                default:
                  return baseData.status;
              }
            })()}
          </TextBoxBase>
        </BoxBase>
      </Grid>
      <Grid item md={6} xs={12}>
        <BoxBase>
          <TitleBoxBase>Leads</TitleBoxBase>
          <TextBoxBase color="leads">{baseData.leads_criados}</TextBoxBase>
        </BoxBase>
      </Grid>
      <Grid item md={6} xs={12}>
        <BoxBase>
          <TitleBoxBase>Vendas</TitleBoxBase>
          <TextBoxBase color="vendas">{baseData.vendas}</TextBoxBase>
        </BoxBase>
      </Grid>
      <Grid item md={6} xs={12}>
        <BoxBase>
          <TitleBoxBase>Conversão</TitleBoxBase>
          <TextBoxBase>
            {baseData.leads_convertidos
              ? parseFloat(baseData.leads_convertidos).toFixed(1)
              : 0}
            %
          </TextBoxBase>
        </BoxBase>
      </Grid>
      <Grid item md={6} xs={12}>
        <BoxBase>
          <TitleBoxBase>Para Despausar</TitleBoxBase>
          <TextBoxBase>{baseData.para_despausar}</TextBoxBase>
        </BoxBase>
      </Grid>
      <Grid item md={6} xs={12}>
        <BoxBase>
          <TitleBoxBase>Para Processar</TitleBoxBase>
          <TextBoxBase>{baseData.para_processar || 0}</TextBoxBase>
        </BoxBase>
      </Grid>
      <Grid item md={6} xs={12}>
        <BoxBase>
          <TitleBoxBase>Elegíveis</TitleBoxBase>
          <TextBoxBase>{baseData.elegiveis || 0}</TextBoxBase>
        </BoxBase>
      </Grid>
      <Grid item md={6} xs={12}>
        <BoxBase>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>
              <TitleBoxBase>Não responderam</TitleBoxBase>

              <TextBoxBase>{baseData.nao_responderam || 0}</TextBoxBase>
            </div>
            <Form onSubmit={handleSubmit} noValidate autoComplete="off">
              <IconButton type="submit" color="primary" disabled={loading}>
                <GetApp fontSize="large" />
                {loading && <ButtonProgress size={30} />}
              </IconButton>
            </Form>
          </div>
        </BoxBase>
      </Grid>
      <Grid item md={6} xs={12}>
        <BoxBase>
          <TitleBoxBase>Aceitaram</TitleBoxBase>
          <TextBoxBase>{baseData.aceitaram || 0}</TextBoxBase>
        </BoxBase>
      </Grid>
      <Grid item md={6} xs={12}>
        <BoxBase>
          <TitleBoxBase>Recusaram</TitleBoxBase>
          <TextBoxBase>{baseData.recusaram || 0}</TextBoxBase>
        </BoxBase>
      </Grid>
    </Grid>
  );
}

export default CardBaseView;
