import { MTheme } from 'models/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: MTheme) => ({
  root: {
    height: 134,
    display: 'flex',
    backgroundColor: theme.palette.white,
    borderRadius: 6,
    filter: 'box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2)',
    border: '1px solid #F2F2F2',
    [theme.breakpoints.down(960)]: {
      height: 94,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: '15px',
  },
  box: {
    backgroundColor: 'rgba(5, 12, 39, 0.03)',
    width: 54,
    height: 134,
    paddingTop: 17,
    paddingLeft: 13,
    [theme.breakpoints.down(960)]: {
      height: 94,
    },
  },
  icon: {
    color: theme.palette.reprocessed,
    fontSize: 25,
  },
  title: {
    fontWeight: 400,
    color: theme.palette.reprocessed,
  },
  subtitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginBottom: 15,
    [theme.breakpoints.down(960)]: {
      marginBottom: 0,
    },
  },
  h3: {
    fontWeight: 700,
    color: theme.palette.reprocessed,
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  boxDownload: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 4,
    marginLeft: 'auto',
    [theme.breakpoints.down(960)]: {
      paddingTop: 0,
    },
  },
  h3Mobile: {
    fontWeight: 700,
    color: theme.palette.reprocessed,
    padding: '15px 15px 0px 15px',
  },
  subtitleExtractReport: {
    fontSize: 14,
    fontWeight: 400,
    color: ' rgba(0, 0, 0, 0.45)',
  },
  iconDownload: {
    fontSize: 25,
    color: '#CCCCCC',
  },
}));

export default useStyles;
