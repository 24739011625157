import * as yup from 'yup';
import {
  cpf as validateCpf,
  validateZipCode,
  validateDate,
  validateTel,
} from 'util/validator';

export interface Erros {
  cpf: string;
  nascimento: string;
  cep: string;
  msisdn: string;
  [x: string]: string;
}

yup.addMethod(
  yup.string,
  'date',
  function method(message: string, [minDate, maxDate]: number[] = [18, 120]) {
    return this.test('date', message || 'Data inválida', function test(value) {
      const existingValue = value || '';
      return validateDate.call(this, existingValue, [minDate, maxDate]);
    });
  },
);

export const infoSchema = yup.object().shape({
  cpf: yup.string().test('cpf', 'CPF inválido', value => {
    return validateCpf(value || '');
  }),
  nascimento: yup
    .string()
    .date()
    .required('A data de nascimento é obrigatória'),
  cep: yup.string().test('cep', 'CEP inválido', value => {
    return validateZipCode(value || '');
  }),
  msisdn: yup.string().test('tel', 'Telefone inválido', value => {
    return validateTel(value || '');
  }),
});

export const defaultErros = {
  cpf: '',
  nascimento: '',
  cep: '',
  msisdn: '',
};
