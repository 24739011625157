import {
  Box,
  CardContent as MuiCardContent,
  Rating,
  Typography,
} from '@mui/material';
import styled from 'styled-components';

export const CardContent = styled(MuiCardContent)`
  display: flex;
  flex-direction: column;

  @media (max-width: 1023px) {
    align-items: center;
  }
`;

export const StyledBoxRating = styled(Box)({
  display: 'flex',
});

export const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#37474F99',
  },
});

export const TopBar = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  gap: 30px;
`;

export const FilterTypography = styled(Typography)`
  margin-left: 20px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
`;

export const BoxFilters = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 15px;
  }
`;
