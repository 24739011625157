import styled from 'styled-components';

export const DataText = styled.span`
  display: flex;
  align-items: center;
  font-size: 15px;
  width: 150px;
  min-width: 150px;
`;

export const DataName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
