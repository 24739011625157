import { CardHeader, SwipeableDrawer } from '@mui/material';
import { AllIntegrationErrorFetch } from 'fetches/integrationsErroFetch';
import Pagination from 'components/Pagination';
import { useEffect, useState } from 'react';
import { CardContent, Container } from './styles';
import IntegrationErrorsList from './IntegrationErrorsList';
import InfoIntegrationError from './InfoIntegrationError';

function IntegrationErrors() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [integrationErrorIdSelected, setIntegrationErrorIdSelected] = useState<
    number | null
  >(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { data: integrationErrorsFetchResponse } =
    AllIntegrationErrorFetch(currentPage);

  useEffect(() => {
    !openDrawer && setIntegrationErrorIdSelected(null);
  }, [openDrawer]);

  return (
    <Container>
      <CardHeader
        title="Erros de Integração"
        subheader="Painel de visualização e edição de erros de integração."
      />
      <CardContent>
        <IntegrationErrorsList
          integrationErrors={integrationErrorsFetchResponse?.integrationErrors}
          setIntegrationErrorIdSelected={setIntegrationErrorIdSelected}
          setOpenDrawer={setOpenDrawer}
        />
        {integrationErrorsFetchResponse && (
          <Pagination
            currentPage={currentPage}
            lastPage={integrationErrorsFetchResponse?.paginationInfo.lastPage}
            perPage={integrationErrorsFetchResponse?.paginationInfo.perPage}
            total={integrationErrorsFetchResponse?.paginationInfo.total}
            setCurrentPage={setCurrentPage}
          />
        )}
      </CardContent>
      {integrationErrorIdSelected && (
        <SwipeableDrawer
          anchor="right"
          open={!!openDrawer}
          onOpen={() => setOpenDrawer(true)}
          onClose={() => {
            setOpenDrawer(false);
          }}
        >
          <InfoIntegrationError
            integrationErrorIdSelected={integrationErrorIdSelected}
            setOpenDrawer={setOpenDrawer}
          />
        </SwipeableDrawer>
      )}
    </Container>
  );
}

export default IntegrationErrors;
