import styled from 'styled-components';

interface HeaderProps {
  isDesktop: boolean;
}

export const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0px 15px 38px;
  color: rgba(0, 0, 0, 0.4);
  span {
    min-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    align-self: flex-start;
  }
`;

export const SubHeader = styled.div<HeaderProps>`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-direction: ${props => (props.isDesktop ? 'row' : 'column')};
`;
