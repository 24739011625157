import styled from 'styled-components';

export const DataText = styled.span`
  min-width: 90px;
  width: 100px;
  word-wrap: normal;
  word-break: break-word;
  font-size: 15px;
  display: flex;
  align-items: center;
`;

export const DataTextName = styled.span`
  min-width: 110px;
  width: 210px;
  font-size: 15px;
  display: flex;
  align-items: center;
`;
