import { useCallback } from 'react';
import { VirtualSeller } from 'models/virtualSeller';
import { Container } from 'components/CardList';
import useBreakpoint from '../../../hooks/useBreakpoint';
import { EmptyTable, Header, List } from './styles';
import MobileItem from './MobileItem';
import Item from './Item';

interface VirtualSellerListProps {
  virtualSellers?: VirtualSeller[];
  updateVirtualSellers(
    virtualSellers: VirtualSeller[],
    showRevalid?: boolean,
  ): void;
}

interface LoadingListProps {
  isMobile: boolean;
}

function LoadingList({ isMobile }: LoadingListProps) {
  if (isMobile) {
    return (
      <>
        <MobileItem loading />
        <MobileItem loading />
        <MobileItem loading />
        <MobileItem loading />
      </>
    );
  }
  return (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function VirtualSellerList({
  virtualSellers,
  updateVirtualSellers,
}: VirtualSellerListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const updateVirtualSeller = useCallback(
    (virtualSeller: VirtualSeller) => {
      if (!virtualSellers) return;
      const newVirtualSeller = virtualSellers.map(item => {
        if (item.id === virtualSeller.id) {
          return { ...item, ...virtualSeller };
        }
        return item;
      });
      updateVirtualSellers(newVirtualSeller, true);
    },
    [updateVirtualSellers, virtualSellers],
  );
  return (
    <Container>
      {!isTableOrLess && (
        <Header>
          <span>CPF</span>
          <span style={{ marginRight: 5 }}>UF</span>
          <span style={{ marginRight: 110 }}>Cust Code</span>
        </Header>
      )}
      {virtualSellers ? (
        <List>
          {virtualSellers ? (
            virtualSellers.map(item =>
              isTableOrLess ? (
                <MobileItem
                  key={item.id}
                  data={item}
                  updateVirtualSeller={updateVirtualSeller}
                />
              ) : (
                <Item
                  key={item.id}
                  data={item}
                  updateVirtualSeller={updateVirtualSeller}
                />
              ),
            )
          ) : (
            <EmptyTable>Não há vendedores virtuais</EmptyTable>
          )}
        </List>
      ) : (
        <LoadingList isMobile={isTableOrLess} />
      )}
    </Container>
  );
}

export default VirtualSellerList;
