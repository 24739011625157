import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 39px 15px 57px;
  color: rgba(0, 0, 0, 0.4);

  div {
    display: flex;
  }
`;

export const HeaderTitle = styled.span`
  width: 150px;
  white-space: nowrap;
  font-size: 15px;
  align-self: flex-start;
`;
