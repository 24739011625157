import { AffiliateGoalDefaultParameter } from 'models/affiliateGoalDefaultParameter';
import api from 'services/api';
import useSWR from 'swr';

export const AffiliateGoalDefaultParametersFetch = () => {
  const { data, mutate } = useSWR<AffiliateGoalDefaultParameter>(
    `defaultParameters`,
    async (): Promise<AffiliateGoalDefaultParameter> => {
      const { data: defaultParameters } = await api.get(
        `/core/metas-afiliados/parametros-padroes`,
      );

      return defaultParameters as AffiliateGoalDefaultParameter;
    },
  );
  return { data, mutate };
};
