import userSWR from 'swr';
import api from 'services/api';
import { EligibilityReport } from 'models/bugReport';

export const EligibilityReportFetch = (start: string, end: string) => {
  const { data, mutate } = userSWR<EligibilityReport>(
    `eligibilityFetch_${start}_${end}`,

    async (): Promise<EligibilityReport> => {
      const params = {
        inicio: start,
        fim: end,
      };
      const { data: result } = await api.get(`core/chamados/agrupados`, {
        params,
      });
      return result;
    },
  );
  return { data, mutate };
};
