import { Button, Grid } from '@mui/material';
import { Card } from 'components/StyledComponents';
import { useNavigate, useParams } from 'react-router-dom';
import { DividerBase } from './styles';
import CardBaseView from './components/CardBaseView';
import CardConfiguration from './components/CardConfiguration';
import CardResultEligibility from './components/CardResultEligibility';
import CardEligibleCustomer from './components/CardEligibleCustomers';
import { useBaseView } from './components/hooks/useBaseView';
import CancelProcessing from './components/ CancelProcessing';
import ReturnProcessing from './components/ReturnProcessing';
import DispatchImportBase from './components/DispatchImportBase';
import StoreBase from './components/StoreBase';
import InvalidateNonProcessed from './components/InvalidateNonProcessed';

function BaseView() {
  const useParameters = useParams();
  const { id } = useParameters;
  const navigate = useNavigate();

  const {
    baseWithConfiguration,
    eligibleCustomers,
    customerLoading,
    selectedCustomers,
    setSelectedCustomers,
    activeCustomers,
    isBasePaused,
    isBaseRetorned,
    fetchBaseData,
    canBeStored,
  } = useBaseView({ id });

  return (
    <Card style={{ padding: '21px 31px' }}>
      {baseWithConfiguration ? (
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            {baseWithConfiguration?.dados_base && (
              <CardBaseView
                id={Number(id)}
                baseData={baseWithConfiguration.dados_base}
              />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {baseWithConfiguration?.dados_configuracao && (
              <CardConfiguration
                configurationData={baseWithConfiguration.dados_configuracao}
              />
            )}
          </Grid>
          <Grid item md={12} xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={2}
            >
              {baseWithConfiguration?.dados_base.status === 'NOVO' && (
                <Grid item>
                  <DispatchImportBase
                    baseId={Number(id)}
                    fetchBaseData={fetchBaseData}
                  />
                </Grid>
              )}
              {isBaseRetorned && (
                <Grid item>
                  <ReturnProcessing
                    baseId={Number(id)}
                    fetchBaseData={fetchBaseData}
                  />
                </Grid>
              )}
              {isBasePaused && (
                <Grid item>
                  <CancelProcessing
                    baseId={Number(id)}
                    fetchBaseData={fetchBaseData}
                  />
                </Grid>
              )}
              {isBasePaused && (
                <Grid item>
                  <InvalidateNonProcessed
                    baseId={Number(id)}
                    fetchBaseData={fetchBaseData}
                  />
                </Grid>
              )}
              {canBeStored && (
                <Grid item>
                  <StoreBase
                    baseId={Number(id)}
                    fetchBaseData={fetchBaseData}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <DividerBase />
          </Grid>
          <Grid item md={12} xs={12}>
            {baseWithConfiguration?.dados_elegibilidade && (
              <CardResultEligibility
                eligibilityData={baseWithConfiguration.dados_elegibilidade}
                baseStatus={baseWithConfiguration.dados_base.status}
              />
            )}
          </Grid>
          <Grid item md={12} xs={12}>
            <DividerBase />
          </Grid>
          <Grid item md={12} xs={12}>
            {baseWithConfiguration?.dados_clientes_elegiveis && (
              <CardEligibleCustomer
                eligibleCustomersData={eligibleCustomers}
                onActivateCustomer={activeCustomers}
                customerLoading={customerLoading}
                selectedCustomers={selectedCustomers}
                setSelectedCustomers={setSelectedCustomers}
                baseStatus={baseWithConfiguration.dados_base.status}
                baseID={Number(id)}
                fetchBaseData={fetchBaseData}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => navigate('/reprocessamento-total/')}
              type="button"
              variant="outlined"
            >
              Voltar
            </Button>
          </Grid>
        </Grid>
      ) : (
        <div style={{ padding: 20 }}>
          <h1>Carregando... </h1>
        </div>
      )}
    </Card>
  );
}

export default BaseView;
