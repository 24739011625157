import { Button, Grid } from '@mui/material';
import { EligibleCustomersData } from 'models/baseWithConfigurationById';
import { List } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import CustomerItem from './CustomerItem';
import { Header, TitleBase } from '../../styles';
import { ContainerSelectionActions } from './styles';
import ActivateSomeLeads from '../ActivateSomeLeads';

interface CardEligibleCustomerProps {
  eligibleCustomersData: EligibleCustomersData[];
  onActivateCustomer: (customerId: number) => void;
  customerLoading: { [key: number]: boolean };
  selectedCustomers: number[];
  setSelectedCustomers(value: number[]): void;
  baseStatus: string;
  baseID: number;
  fetchBaseData(): void;
}

function CardEligibleCustomer({
  eligibleCustomersData,
  onActivateCustomer,
  customerLoading,
  selectedCustomers,
  setSelectedCustomers,
  baseStatus,
  baseID,
  fetchBaseData,
}: CardEligibleCustomerProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(900);

  const handleCheckCustomer = (customerId: number) => {
    const checkCustomer = selectedCustomers.includes(customerId)
      ? selectedCustomers.filter(id => id !== customerId)
      : [...selectedCustomers, customerId];

    setSelectedCustomers(checkCustomer);
  };

  const handleSelectAllCustomers = () => {
    setSelectedCustomers(
      selectedCustomers.length === eligibleCustomersData.length
        ? []
        : eligibleCustomersData.map(customer => customer.id),
    );
  };

  const handleActivated = async () => {
    const jobs = selectedCustomers.map(item => onActivateCustomer(item));

    await Promise.all(jobs);
  };

  const activateDisabled = selectedCustomers.length === 0;
  const verifyCustomerLoading = Object.values(customerLoading).some(
    loading => loading,
  );
  return (
    <Grid container item spacing={2}>
      <Grid item md={6} xs={6}>
        <TitleBase>Clientes Elegíveis</TitleBase>
      </Grid>
      <Grid item md={6} xs={12}>
        <ContainerSelectionActions isMobile={isTableOrLess}>
          <ActivateSomeLeads baseId={baseID} fetchBaseData={fetchBaseData} />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              handleSelectAllCustomers();
            }}
            disabled={
              verifyCustomerLoading || eligibleCustomersData.length === 0
            }
          >
            Selecionar todos
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              handleActivated();
            }}
            disabled={activateDisabled || verifyCustomerLoading}
          >
            Ativar selecionados
          </Button>
        </ContainerSelectionActions>
      </Grid>
      <Grid item md={12} xs={12}>
        {!isTableOrLess && (
          <Header style={{ margin: '10px 12px 15px 38px' }}>
            <span style={{ width: 221 }}>Nome do Cliente</span>
            <span style={{ width: 108 }}>MSISDN</span>
            <span style={{ width: 222 }}>Email</span>
            <span>Data Elegibilidade</span>
          </Header>
        )}

        <List>
          {eligibleCustomersData.length ? (
            eligibleCustomersData.map(customer => (
              <CustomerItem
                key={customer.id}
                customer={customer}
                isTableOrLess={isTableOrLess}
                selected={selectedCustomers.includes(customer.id)}
                handleCheckCustomer={handleCheckCustomer}
                loading={customerLoading[customer.id] || false}
              />
            ))
          ) : baseStatus === 'ARMAZENADO' ? (
            <span>Essa base já foi armazenada</span>
          ) : (
            <span>Não há resultados disponíveis</span>
          )}
        </List>
      </Grid>
    </Grid>
  );
}

export default CardEligibleCustomer;
