import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ButtonProgress } from 'components/StyledComponents';
import { useToast } from 'hooks/toast';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { useStyles } from './styles';

interface DialogConfirmIntractableSaleProps {
  saleId: number;
  open: boolean;
  setOpen(value: boolean): void;
  updateSales(): void;
}
function DialogConfirmIntractableSale({
  saleId,
  open,
  setOpen,
  updateSales,
}: DialogConfirmIntractableSaleProps) {
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const navigate = useNavigate();

  const { addToast } = useToast();

  const handleUpdateSaleIntractable = useCallback(() => {
    setLoading(true);
    api
      .put(`/core/correcao-vendas/intratavel/${saleId}`, {
        tratavel: '0',
      })
      .then(() => {
        addToast({
          type: 'success',
          title: 'Venda Intratável ',
          description:
            'Venda definida como ”intratável” com sucesso pelo sistema.',
        });
        updateSales();
        navigate(`/correcao-vendas/`);
      })
      .catch(error => {
        let message = error.data.errors[0]?.message;

        if (!message) {
          message = error.data.message;
        }

        message &&
          addToast({
            type: 'error',
            title: 'Erro ao definir venda como ”intratável”!',
            description: message,
          });
      })
      .finally(() => {
        setLoading(false);
        setOpen(false);
      });
  }, [addToast, navigate, saleId, setOpen, updateSales]);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className={classes.root}>
        Você tem certeza disso?
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="alert-dialog-slide-description">
          Depois de definir esta venda como intratável esta ação não pode ser
          desfeita.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogButtons}>
        <Button
          type="button"
          onClick={() => setOpen(false)}
          className={classes.cancelButton}
        >
          Cancelar
        </Button>
        <Button
          type="button"
          variant="contained"
          onClick={handleUpdateSaleIntractable}
          color="primary"
          disabled={loading}
        >
          Confirmar
          {loading && <ButtonProgress size={24} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogConfirmIntractableSale;
