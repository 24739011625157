import { AffiliateGoal } from 'models/affiliateGoal';
import api from 'services/api';
import useSWR from 'swr';

interface FetchProps {
  month: string;
  year: string;
}

export const AffiliateGoalFetch = ({ month, year }: FetchProps) => {
  const { data, mutate } = useSWR<AffiliateGoal[]>(
    `affiliate_${month}_${year}`,
    async (): Promise<AffiliateGoal[]> => {
      const { data: affiliates } = await api.get(
        `/core/metas-afiliados?mes=${month}&ano=${year}`,
      );

      return affiliates as AffiliateGoal[];
    },
  );
  return { data, mutate };
};
