import { Button, Grid } from '@mui/material';
import { Container } from 'components/CardList';
import { ButtonProgress } from 'components/StyledComponents';
import { ActivePlansFetch } from 'fetches/activePlansFetch';
import { useToast } from 'hooks/toast';
import { LPOffer } from 'models/lpOffers';
import { useCallback, useEffect, useState } from 'react';
import api from 'services/api';
import HeaderList from '../components/HeaderList';
import LoadingListOffers from '../components/LoadingListOffers';
import BestOffer from './BestOffer';
import OthersOffers from './OthersOffers';
import { ActionOffers } from './styles';
import { Plan } from './types';
import Whatsapp from './Whatsapp';

interface OfferProps {
  offers?: LPOffer;
  appendNewOffer(): void;
}

interface BodyOffersProps {
  offers: LPOffer;
  appendNewOffer(): void;
  plans: Plan[];
}

function BodyOffers({ offers, appendNewOffer, plans }: BodyOffersProps) {
  const [melhorOferta, setMelhorOferta] = useState(offers.melhor_oferta);
  const [melhorOfertaKv, setMelhorOfertaKv] = useState(offers.melhor_oferta_kv);
  const [primeiraOferta, setPrimeiraOferta] = useState(offers.primeira_oferta);
  const [segundaOferta, setSegundaOferta] = useState(offers.segunda_oferta);
  const [posPago, setPosPago] = useState(offers.pos_pago);
  const [whatsappUrl, setWhatsappUrl] = useState(offers.whatsapp_url);
  const [isValid, setIsValid] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [sameFirstOfferChecked, setSameFirstOfferChecked] = useState(false);

  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    setIsValid(
      !!(
        melhorOferta &&
        primeiraOferta &&
        segundaOferta &&
        posPago &&
        whatsappUrl
      ),
    );
  }, [melhorOferta, primeiraOferta, segundaOferta, posPago, whatsappUrl]);

  useEffect(() => {
    if (!sameFirstOfferChecked) {
      setMelhorOferta(melhorOferta);
      setIsChecked(false);
    }
    if (sameFirstOfferChecked) {
      setMelhorOferta(primeiraOferta);
      setIsChecked(true);
    }
  }, [melhorOferta, primeiraOferta, sameFirstOfferChecked]);

  useEffect(() => {
    if (melhorOferta === primeiraOferta) {
      setSameFirstOfferChecked(true);
    }
  }, [melhorOferta, primeiraOferta]);

  const handleSave = useCallback(() => {
    setLoading(true);
    api
      .post(`/core/lp-ofertas/`, {
        lp_offer_form: {
          melhor_oferta: melhorOferta,
          melhor_oferta_kv: melhorOfertaKv,
          primeira_oferta: primeiraOferta,
          segunda_oferta: segundaOferta,
          pos_pago: posPago,
          whatsapp_url: whatsappUrl,
        },
      })
      .then(() => {
        appendNewOffer();
        addToast({
          type: 'success',
          title: 'Informações salvas',
          description: 'Informações salvas com sucesso.',
        });
      })
      .catch(error => {
        let message = error.data.errors[0]?.message;
        if (!message) {
          message = error.data.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao criar!',
            description: message,
          });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    addToast,
    appendNewOffer,
    melhorOferta,
    melhorOfertaKv,
    posPago,
    primeiraOferta,
    segundaOferta,
    whatsappUrl,
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSameFirstOfferChecked(!!event.target.checked);
  };
  return (
    <>
      {offers && (
        <Container>
          <Grid container spacing={2} style={{ padding: '0px 16px' }}>
            <Grid item lg={4} md={4} xs={12} style={{ paddingTop: 0 }}>
              <BestOffer
                planos={plans}
                melhorOferta={melhorOferta}
                melhorOfertaKv={melhorOfertaKv}
                setMelhorOferta={setMelhorOferta}
                setMelhorOfertaKv={setMelhorOfertaKv}
                sameFirstOfferChecked={sameFirstOfferChecked}
                handleChangeCheckbox={handleChange}
                isChecked={isChecked}
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12} style={{ paddingTop: 0 }}>
              <OthersOffers
                planos={plans}
                primeiraOferta={primeiraOferta}
                segundaOferta={segundaOferta}
                posPago={posPago}
                setPrimeiraOferta={setPrimeiraOferta}
                setSegundaOferta={setSegundaOferta}
                setPosPago={setPosPago}
                setMelhorOferta={setMelhorOferta}
                isChecked={isChecked}
              />
            </Grid>

            <Grid item lg={4} md={4} xs={12} style={{ paddingTop: 0 }}>
              <Whatsapp
                whatsappUrl={whatsappUrl}
                setWhatsappUrl={setWhatsappUrl}
              />
            </Grid>
          </Grid>
        </Container>
      )}
      <ActionOffers>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={!isValid || loading}
        >
          Salvar ofertas
          {loading && <ButtonProgress size={24} />}
        </Button>
      </ActionOffers>
    </>
  );
}

function Offers({ offers, appendNewOffer }: OfferProps) {
  const { data: plans } = ActivePlansFetch();

  return (
    <div>
      <HeaderList title="Ofertas" />
      {offers && plans ? (
        <BodyOffers
          offers={offers}
          appendNewOffer={appendNewOffer}
          plans={plans}
        />
      ) : (
        <LoadingListOffers />
      )}
    </div>
  );
}

export default Offers;
