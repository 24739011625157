import userSWR from 'swr';
import { endOfDay, format, parseISO, startOfDay } from 'date-fns';
import api from 'services/api';
import { Avarage } from 'models/reports';

export const AverageFetch = (
  start: string,
  end: string,
  reprocessed: boolean,
  inReprocessing: boolean,
  engagement: boolean,
) => {
  const inicio = format(startOfDay(parseISO(start)), 'yyyy-MM-dd HH:mm:ss');
  const fim = format(endOfDay(parseISO(end)), 'yyyy-MM-dd HH:mm:ss');

  const { data, mutate } = userSWR<Avarage>(
    `averageFetch_${inicio}_${fim}_${reprocessed}_${inReprocessing}_${engagement}`,

    async (): Promise<Avarage> => {
      const params = {
        inicio,
        fim,
        trazer_reprocessados: reprocessed ? 1 : 0,
        trazer_em_processamento: inReprocessing ? 1 : 0,
        trazer_engajadas: engagement ? 1 : 0,
      };
      const { data: average } = await api.get(
        `core/relatorios/dashboards/media`,
        { params },
      );
      return average;
    },
  );
  return { data, mutate };
};
