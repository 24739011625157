import React, { useState } from 'react';
import clsx from 'clsx';

import { MTheme } from 'models/theme';
import { makeStyles, useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Container, Content } from './styles';
import Topbar from './components/Topbar';
import Menu from './components/Menu';

const useStyles = makeStyles((theme: MTheme) => ({
  root: {
    paddingTop: 56,
    height: '100vh',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 280,
  },
  content: {
    height: '100vh',
  },
}));

function Default() {
  const classes = useStyles();
  const theme = useTheme() as MTheme;
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Container>
        <Topbar onSidebarOpen={handleSidebarOpen} />
        <Menu
          onClose={handleSidebarClose}
          open={shouldOpenSidebar}
          variant={isDesktop ? 'persistent' : 'temporary'}
        />
        <Content className={classes.content}>
          <Outlet />
        </Content>
      </Container>
    </div>
  );
}

export default Default;
