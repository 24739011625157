import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import InputMask, { Props } from 'react-input-mask';
import useBreakpoint from 'hooks/useBreakpoint';
import { ButtonProgress } from 'components/StyledComponents';
import ufs from 'constants/ufs.json';
import { UF } from 'pages/VirtualSeller/types';
import { GroupInput } from 'pages/VirtualSeller/FormAddVirtualSellerMobile/styles';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { VirtualSeller } from 'models/virtualSeller';

interface EditFormProps {
  showEditForm: boolean;
  setShowEditForm(value: boolean): void;
  virtualSeller: VirtualSeller;
  updateVirtualSeller?(virtualSeller: VirtualSeller): void;
}

function EditForm({
  showEditForm,
  setShowEditForm,
  virtualSeller,
  updateVirtualSeller,
}: EditFormProps) {
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  const [cpf, setCpf] = useState(virtualSeller.cpf);
  const [custcode, setCustcode] = useState(virtualSeller.custcode);
  const [uf, setUf] = useState(virtualSeller.uf);
  const [isValid, setIsValid] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    setIsValid(!!(uf && custcode));
  }, [uf, custcode]);

  const handleUpdate = useCallback(() => {
    const data = {
      seller_form: {
        uf,
        custcode,
      },
    };
    setSaveLoading(true);

    api
      .put(`/core/vendedores-virtuais/${virtualSeller.id}`, data)
      .then(({ data: response }) => {
        updateVirtualSeller &&
          updateVirtualSeller({ ...virtualSeller, ...response });
        addToast({
          type: 'success',
          title: 'Cadastro atualizado',
          description: 'Vendedor virtual atualizado com sucesso ',
        });
      })
      .finally(() => {
        setSaveLoading(false);
        setShowEditForm(false);
      });
  }, [
    addToast,
    custcode,
    setShowEditForm,
    uf,
    updateVirtualSeller,
    virtualSeller,
  ]);

  return (
    <Dialog
      open={showEditForm}
      onClose={() => setShowEditForm(false)}
      aria-labelledby={`edit-virtual_${virtualSeller.id}`}
      fullScreen={isTabletSizeOrSmaller}
      style={{ height: isTabletSizeOrSmaller ? '100%' : '60%' }}
    >
      <Box padding="30px" id={`edit-virtual_${virtualSeller.id}`}>
        <CardHeader
          title="Editar vendedor virtual"
          style={{ padding: 10, marginLeft: 9 }}
        />
        <CardContent>
          <GroupInput>
            <InputMask
              mask="999.999.999-99"
              maskChar=""
              value={cpf}
              disabled
              onChange={e => setCpf(e.target.value)}
            >
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (inputProps: Props & TextFieldProps) => (
                  <TextField
                    {...inputProps}
                    aria-readonly
                    fullWidth
                    variant="outlined"
                    id="cpf"
                    label="CPF"
                    name="cpf"
                    disabled
                  />
                )
              }
            </InputMask>
          </GroupInput>
          <TextField
            aria-readonly
            fullWidth
            id="custcode"
            label="Cust Code"
            margin="dense"
            name="custCode"
            variant="outlined"
            value={custcode}
            onChange={e => setCustcode(e.target.value)}
          />
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel>UF</InputLabel>
            <Select
              value={uf}
              required
              onChange={e => {
                setUf(e.target.value as string);
              }}
              label="UF"
            >
              {ufs.map((option: UF) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </CardContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            paddingRight: '24px',
            paddingLeft: '16px',
          }}
        >
          <Button
            style={{ color: '#C92A2A' }}
            onClick={() => {
              setShowEditForm(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleUpdate()}
            variant="contained"
            color="primary"
            disabled={!isValid || saveLoading}
          >
            Salvar
            {saveLoading && <ButtonProgress size={24} />}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default EditForm;
