import { useToast } from 'hooks/toast';
import { LPBenefit } from 'models/lpBenefits';
import CMSSlidingDrawer from 'pages/CmsLP/components/CMSSlidingDrawer';
import FormBenefit from 'pages/CmsLP/components/FormBenefit';
import { useCallback, useEffect, useState } from 'react';
import api from 'services/api';

interface NewBenefitProps {
  closeDrawer(): void;

  appendNewBenefit(newBenefit: LPBenefit): void;

  lastOrderBenefit?: number;
}

function NewBenefit({
  closeDrawer,
  appendNewBenefit,
  lastOrderBenefit,
}: NewBenefitProps) {
  const [title, setTitle] = useState('');
  const [urlImage, setUrlImage] = useState('');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    setIsValid(!!(title && urlImage && text));
  }, [text, title, urlImage]);

  const handleSave = useCallback(() => {
    setLoading(true);

    api
      .post(`/core/lp-beneficios/`, {
        titulo: title,
        imagem_url: urlImage,
        texto: text,
        ordem: lastOrderBenefit && lastOrderBenefit + 1,
      })
      .then(({ data: benefit }) => {
        appendNewBenefit(benefit);

        addToast({
          type: 'success',
          title: 'Benefício criado',
          description: `O benefício ”${title}” foi criado com sucesso.`,
        });

        closeDrawer();
      })
      .catch(error => {
        let { message } = error.data;

        if (!message) {
          message = error.data.errors[0]?.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao criar Benefício!',
            description: message,
          });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    addToast,
    appendNewBenefit,
    closeDrawer,
    lastOrderBenefit,
    text,
    title,
    urlImage,
  ]);

  return (
    <CMSSlidingDrawer
      title="Criando benefício"
      subtitle="Painel de criação de um novo benefício."
      closeDrawer={closeDrawer}
    >
      <FormBenefit
        title={title}
        setTitle={setTitle}
        urlImage={urlImage}
        setUrlImage={setUrlImage}
        text={text}
        setText={setText}
        loading={loading}
        isValid={isValid}
        handleBenefitRequest={handleSave}
      />
    </CMSSlidingDrawer>
  );
}

export default NewBenefit;
