import styled from 'styled-components';

export const TextLink = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  div {
    :nth-child(1) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }
`;
