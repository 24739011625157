import { Button as MuiButton } from '@mui/material';
import styled from 'styled-components';

export const ContentDrawer = styled.div`
  width: 596px;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 20px 40px;
  gap: 25px;

  @media (max-width: 1023px) {
    width: auto;
  }
`;

export const DivClose = styled.div`
  display: flex;
  justify-content: right;
`;

export const CreatedAndType = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1023px) {
    gap: 20px;
  }
`;

export const DataLabel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DivSwitches = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1023px) {
    gap: 20px;
  }
`;

export const Button = styled(MuiButton)`
  width: 80px;
  height: 40px;
  border-radius: 6px;
`;
