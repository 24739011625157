import { useParams, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { BlacklistDataIdFetch } from 'fetches/blacklistDataByIdFetch';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import FormBlacklistData from '../FormBlacklistData';

function EditBlacklistData() {
  const params = useParams();
  const { id } = params;

  const { data: blacklist } = BlacklistDataIdFetch(parseInt(id || '0', 10));

  const [type, setType] = useState('');
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (blacklist) {
      setType(blacklist.tipo);
      setData(blacklist.dado);
    }
  }, [blacklist]);

  const handleEdit = useCallback(() => {
    const dataRequest = {
      tipo: type,
      dado: data,
    };

    setLoading(true);

    api
      .put(`/core/dados-blacklist/${id}`, dataRequest)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Dado editado',
          description: 'Dado de blacklist editado com sucesso.',
        });
        navigate('/dados-blacklist/');
      })
      .catch(error => {
        let { message } = error.data;

        if (!message) {
          message = error.data.errors[0]?.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao editar dados!',
            description: message,
          });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, data, id, navigate, type]);

  return (
    <FormBlacklistData
      id={parseInt(id || '0', 10)}
      type={type}
      setType={setType}
      data={data}
      setData={setData}
      loading={loading}
      handleRequest={handleEdit}
    />
  );
}

export default EditBlacklistData;
