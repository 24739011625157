import { SwipeableDrawer } from '@mui/material';
import { List } from 'components/StyledComponents';
import { useToast } from 'hooks/toast';
import useBreakpoint from 'hooks/useBreakpoint';
import { useCallback, useState } from 'react';
import api from 'services/api';
import DeleteModal from 'pages/CmsLP/components/DeleteModal';
import ContainerList from 'pages/CmsLP/components/ContainerList';
import { LPFAQ } from 'models/lpFaqs';
import EditFAQ from './EditFAQ';
import Item from './Item';
import MobileItem from './MobileItem';
import { HeaderFAQList } from './styles';

interface FAQListProps {
  faqs?: LPFAQ[];
  updateFAQs(faqs?: LPFAQ[], showRevalid?: boolean): void;
}

interface BodyProps {
  faqs: LPFAQ[];
  isMobile: boolean;
  setFAQSelected(value: LPFAQ): void;
  setShowFAQEdition(value: boolean): void;
  handleOpenModalDelete(): void;
}

interface LoadingListProps {
  isTableOrLess: boolean;
}

function LoadingList({ isTableOrLess }: LoadingListProps) {
  return isTableOrLess ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({
  faqs,
  isMobile,
  setFAQSelected,
  setShowFAQEdition,
  handleOpenModalDelete,
}: BodyProps) {
  return (
    <List>
      {faqs.length ? (
        faqs.map(data =>
          isMobile ? (
            <MobileItem
              key={data.id}
              data={data}
              setFAQSelected={setFAQSelected}
              setShowFAQEdition={setShowFAQEdition}
              handleOpenModalDelete={handleOpenModalDelete}
            />
          ) : (
            <Item
              key={data.id}
              data={data}
              setFAQSelected={setFAQSelected}
              setShowFAQEdition={setShowFAQEdition}
              handleOpenModalDelete={handleOpenModalDelete}
            />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhum FAQ encontrado</h3>
      )}
    </List>
  );
}

function FAQList({ faqs, updateFAQs }: FAQListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const [faqSelected, setFAQSelected] = useState<LPFAQ>();
  const [showFAQEdition, setShowFAQEdition] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const updateFaq = useCallback(
    (faq: LPFAQ) => {
      if (!faqs) return;
      const newFaq = faqs.map(item => {
        if (item.id === faq.id) {
          return { ...item, ...faq };
        }
        return item;
      });
      updateFAQs(newFaq, true);
    },
    [updateFAQs, faqs],
  );

  const handleOpen = () => {
    setOpenModalDelete(true);
  };

  const handleClose = () => {
    setOpenModalDelete(false);
  };

  const handleDelete = useCallback(() => {
    setLoading(true);
    api
      .delete(`/core/lp-faqs/${faqSelected?.id}`)
      .then(() => {
        updateFAQs(
          faqs?.filter(faq => faq.id !== faqSelected?.id),
          true,
        );
        addToast({
          type: 'success',
          title: 'Pergunta excluída',
          description: 'Pergunta excluída com sucesso do FAQ.',
        });
        setLoading(false);
        handleClose();
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Remover pergunta ',
          description: error?.data?.message,
        });
        setLoading(false);
        handleClose();
      });
  }, [faqSelected?.id, updateFAQs, faqs, addToast]);

  return (
    <ContainerList>
      {!isTableOrLess && (
        <HeaderFAQList>
          <span>Pergunta</span>
          <span>Resposta</span>
        </HeaderFAQList>
      )}

      {faqs ? (
        <Body
          faqs={faqs}
          isMobile={isTableOrLess}
          setFAQSelected={setFAQSelected}
          setShowFAQEdition={setShowFAQEdition}
          handleOpenModalDelete={handleOpen}
        />
      ) : (
        <LoadingList isTableOrLess={isTableOrLess} />
      )}

      <SwipeableDrawer
        anchor="right"
        onClose={() => {
          setFAQSelected(undefined);
          setShowFAQEdition(false);
        }}
        onOpen={() => setShowFAQEdition(true)}
        open={showFAQEdition}
      >
        {faqSelected && (
          <EditFAQ
            data={faqSelected}
            closeDrawer={() => {
              setFAQSelected(undefined);
              setShowFAQEdition(false);
            }}
            updateFAQs={updateFaq}
          />
        )}
      </SwipeableDrawer>
      <DeleteModal
        title="Deseja realmente excluir essa pergunta?"
        text="Assim que confirmado não será possível recuperar o histórico."
        loading={loading}
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        handleDelete={handleDelete}
        handleClose={handleClose}
      />
    </ContainerList>
  );
}

export default FAQList;
