import { hour as hourFormatter, date as dateFormatter } from 'util/formatter';
import { Infos, Data, DataLabel, Action } from 'components/MobileList';
import { IconButton, Skeleton } from '@mui/material';
import {
  MobileContainer,
  DataText,
  MobileTextName,
} from 'components/StyledComponents';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useCallback, useState } from 'react';
import { ItemProps } from '../types';
import { useStyles } from '../Item/styles';
import PopperActions from '../PopperActions';

function MobileItem({ data, loading, updateSales }: ItemProps) {
  const classes = useStyles();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
    setOpen(previousOpen => !previousOpen);
  }, []);

  const handleClickAway = () => {
    setOpen(false);
  };
  const canBeOpen = open && Boolean(anchorElement);
  const id = canBeOpen ? `popperAction_${data?.id}` : undefined;

  return (
    <MobileContainer>
      {loading ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        data && (
          <>
            <Action className={classes.buttonAction}>
              <IconButton disabled={loading} onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
            </Action>
            <Infos>
              <Data className={classes.dataInfo}>
                <DataLabel>Hora</DataLabel>
                <DataText> {hourFormatter(data.created_at)}</DataText>
              </Data>
              <Data className={classes.dataInfo}>
                <DataLabel>Data</DataLabel>
                <DataText>{dateFormatter(data?.created_at)}</DataText>
              </Data>
            </Infos>
            <Infos>
              <Data className={classes.dataInfo}>
                <DataLabel>Tipo</DataLabel>
                <DataText>
                  <DataText>{data.tipo}</DataText>
                </DataText>
              </Data>
            </Infos>
            <Infos>
              <Data className={classes.dataLastInfo}>
                <DataLabel>Erro</DataLabel>
                <MobileTextName>{data.erro}</MobileTextName>
              </Data>
            </Infos>
          </>
        )
      )}
      {data && updateSales && (
        <PopperActions
          id={id || ''}
          open={open}
          saleId={data.id || 0}
          anchorElement={anchorElement}
          handleClick={handleClick}
          handleClickAway={handleClickAway}
          updateSales={updateSales}
        />
      )}
    </MobileContainer>
  );
}

export default MobileItem;
