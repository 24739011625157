import styled from 'styled-components';

interface HeaderTitle {
  colorTitle: 'request' | 'response';
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #050c27;

  .highlighter {
    background: #050c27;
  }

  .button {
    color: rgba(255, 255, 255, 0.7);
    background: #050c27;
    font-size: 12px;
    padding: 0px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  padding: 3px 10px 1px 10px;
  justify-content: space-between;
`;

export const HeaderTitle = styled.div<HeaderTitle>`
  display: flex;
  gap: 4px;
  color: ${props =>
    props.colorTitle === 'request'
      ? 'rgba(3, 154, 202, 1)'
      : 'rgba(255, 153, 0, 1)'};
`;

export const Title = styled.p`
  font-size: 12px;
  font-weight: 500;
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  color: rgba(255, 255, 255, 0.7);
  background: #050c27;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  padding-left: 20px;
`;
