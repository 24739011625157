import { IconButton, TextField } from '@mui/material';
import { UtmSource } from 'models/utmSource';
import { Item } from 'pages/UtmDefinition/styles';
import { FiPieChart } from 'react-icons/fi';
import { TbTrash } from 'react-icons/tb';
import { useConfigurationUtmStore } from 'store/configurationUtmStore';
import palette from 'theme/palette';

interface SourceItemProps {
  source: UtmSource;
  index: number;
  handlePercentChange(percent: string, index: number): void;
  setShowDrawerToEditCampaign(showDrawerToEdit: boolean): void;
  setSourceIdSelected(sourceIdSelected: string): void;
  handleOpenDialog(sourceIdSelected: string): void;
}

function SourceItem({
  source,
  index,
  handlePercentChange,
  setShowDrawerToEditCampaign,
  setSourceIdSelected,
  handleOpenDialog,
}: SourceItemProps) {
  const sources = useConfigurationUtmStore(state => state.value.sources);

  return (
    <Item key={source.id}>
      <TextField
        fullWidth
        label={source.nome}
        size="small"
        value={source.porcentagem}
        placeholder="%"
        onKeyPress={event => {
          if (/[0-9]/.test(event.key)) {
            return;
          }
          event.preventDefault();
        }}
        onChange={e => handlePercentChange(e.target.value, index)}
      />
      <IconButton
        onClick={() => {
          setShowDrawerToEditCampaign(true);
          setSourceIdSelected(source.id as string);
        }}
      >
        <FiPieChart />
      </IconButton>
      {sources.length > 1 && (
        <IconButton onClick={() => handleOpenDialog(String(source.id))}>
          <TbTrash color={palette.error.main} />
        </IconButton>
      )}
    </Item>
  );
}

export default SourceItem;
