import {
  AverageFetch,
  CountStarsFetch,
  NoEvaluationFetch,
  WithCommentFetch,
  WithEvaluationFetch,
} from 'fetches/satisfactionSurvey';
import { useSatisfactionSurveyStore } from 'store/satisfactionSurveyStore';

interface useTotalizerFetchesProps {
  nota: string | null;
  origem: string | null;
  onlyWithComment: boolean;
}

export function useTotalizerFetches({
  nota,
  origem,
  onlyWithComment,
}: useTotalizerFetchesProps) {
  const startDate = useSatisfactionSurveyStore(state => state.startDate);
  const endDate = useSatisfactionSurveyStore(state => state.endDate);
  const { data: countStarsResponse } = CountStarsFetch({
    nota,
    startDate,
    endDate,
    origem,
    onlyWithComment,
  });
  const averageResponse = AverageFetch({
    nota,
    startDate,
    endDate,
    origem,
    onlyWithComment,
  });
  const withEvaluationResponse = WithEvaluationFetch({
    nota,
    startDate,
    endDate,
    origem,
    onlyWithComment,
  });
  const noEvaluationResponse = NoEvaluationFetch({
    nota,
    startDate,
    endDate,
    origem,
    onlyWithComment,
  });
  const withCommentResponse = WithCommentFetch({
    nota,
    startDate,
    endDate,
    origem,
    onlyWithComment,
  });

  return {
    countStarsResponse,
    averageResponse,
    withEvaluationResponse,
    noEvaluationResponse,
    withCommentResponse,
  };
}
