import {
  Modal,
  Backdrop,
  Fade,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { ButtonProgress } from 'components/StyledComponents';
import { useCallback, useEffect, useState } from 'react';
import { useGoalSelectStore } from 'store/goalSelectStore';
import { TbCopy } from 'react-icons/tb';
import { convertUSToBRDate } from 'util/formatter';
import { NewAffiliateGoal } from 'pages/ManagerGoal/types';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import useBreakpoint from 'hooks/useBreakpoint';
import { useStylesModal } from 'components/UseStylesModal';
import { ModalCard, TextModal, useStyles } from './styles';
import SelectDays from './SelectDays';

interface CopyAndPasteIntoProps {
  daysOfMonthByGoal: NewAffiliateGoal[];
  appendGoals(): void;
}
function CopyAndPasteInto({
  daysOfMonthByGoal,
  appendGoals,
}: CopyAndPasteIntoProps) {
  const selectedGoalsCheckbox = useGoalSelectStore(
    store => store.selectedGoalsCheckbox,
  );

  const [days, setDays] = useState<NewAffiliateGoal[]>([]);
  const [selectedDays, setSelectedDays] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const dateSelected = selectedGoalsCheckbox[0]?.date;

  const classes = useStyles();
  const classesModal = useStylesModal();

  const { addToast } = useToast();
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  useEffect(() => {
    const filteredDaysOfMonth = daysOfMonthByGoal.filter(
      day => day.dayOfMonth > selectedGoalsCheckbox[0]?.dayOfMonth,
    );

    setDays(filteredDaysOfMonth);
  }, [daysOfMonthByGoal, selectedGoalsCheckbox]);

  const handleClose = () => {
    setOpen(false);
    setSelectedDays([]);
  };

  const handleSavePaste = useCallback(() => {
    setLoading(true);

    const dataForm = {
      parametro_dia: dateSelected,
      dias_selecionados: selectedDays,
    };

    api
      .put(`/core/metas-afiliados/copiar-colar/`, dataForm)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Colado com sucesso',
          description: 'Os dados foram copiados e colados com sucesso.',
        });
        setLoading(false);
        appendGoals();
        handleClose();
      })
      .catch(error => {
        setLoading(false);
        let message = error.data.errors[0]?.message;

        if (!message) {
          message = error.data.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao copiar e colar os dados!',
            description: message,
          });
      });
  }, [addToast, appendGoals, dateSelected, selectedDays]);

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        disabled={selectedGoalsCheckbox.length !== 1}
        className={classes.buttonCopyAndPaste}
        fullWidth={isTableOrLess}
      >
        <TbCopy />
        Copiar e colar em
        {loading && <ButtonProgress size={24} />}
      </Button>
      <Modal
        aria-labelledby="tile-modal"
        aria-describedby="modal-description"
        className={classesModal.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={open}>
          <ModalCard>
            <TextModal>
              Você está copiando os parâmetros do dia{' '}
              {convertUSToBRDate(dateSelected)}. Em qual(s) dia(s) do mês você
              deseja colar estes mesmos valores?
            </TextModal>
            <SelectDays
              daysOfMonthByGoal={days}
              selectedDays={selectedDays}
              setSelectedDays={setSelectedDays}
            />

            <DialogActions className={classesModal.dialogActions}>
              <Button
                className={classesModal.buttonCancel}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSavePaste}
                disabled={!selectedDays.length || loading}
              >
                Confirmar
                {loading && <ButtonProgress size={24} />}
              </Button>
            </DialogActions>
          </ModalCard>
        </Fade>
      </Modal>
    </Box>
  );
}

export default CopyAndPasteInto;
