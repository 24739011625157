import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

type UseStylesProps = {
  backgroundColor?: string;
};

export const useStyles = makeStyles<Theme, UseStylesProps>((theme: Theme) => ({
  root: props => ({
    height: 10,
    borderRadius: 2,
    width: 175,
    backgroundColor: props.backgroundColor === 'FALHOU' ? '#C92A2A' : '#CCCCCC',
    '& .MuiLinearProgress-bar': {
      backgroundColor:
        props.backgroundColor === 'FALHOU' ? '#C92A2A' : '#2B8A3E',
    },
    [theme.breakpoints.down(1390)]: {
      width: 100,
    },
    [theme.breakpoints.down(1024)]: {
      width: '100%',
    },
  }),
}));
