import React, { FormEvent, useState } from 'react';
import * as Yup from 'yup';

import { Link } from 'react-router-dom';

import { useToast } from 'hooks/toast';
import api from 'services/api';
import AuthTextField from 'components/AuthTextField';
import { Button, Typography } from '@mui/material';
import { AnimationContainer } from 'pages/_layouts/auth/styles';

interface Erros {
  email: string;
  [x: string]: string;
}

const schema = Yup.object().shape({
  email: Yup.string().required('Campo obrigatório').email('Email inválido'),
});
const defaultErros = {
  email: '',
};

function ForgotPassword() {
  const { addToast } = useToast();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [erros, setErros] = useState<Erros>(defaultErros);

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    setErros(defaultErros);
    schema
      .validate(
        { email },
        {
          abortEarly: false,
        },
      )
      .then(async () => {
        setLoading(true);
        api
          .post('/core/senha/enviar-email', { email })
          .then(() => {
            addToast({
              type: 'success',
              title: 'Resete de senha',
              description: 'Favor verificar o email',
            });
          })
          .catch(error => {
            addToast({
              type: 'error',
              title: 'Erro na autenticação',
              description: error?.data?.message,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(err => {
        if (err instanceof Yup.ValidationError) {
          const newErros: Erros = { ...defaultErros };
          err.inner.forEach(error => {
            if (error.path) newErros[error.path] = error.message;
          });
          setErros(newErros);
        }
      });
  };

  return (
    <AnimationContainer>
      <form onSubmit={handleSubmit}>
        <Typography gutterBottom variant="h3">
          Solicite uma nova senha!
        </Typography>
        <AuthTextField
          focused
          label="Email"
          name="email"
          inputProps={{
            form: {
              autocomplete: 'off',
            },
          }}
          type="email"
          placeholder="Email"
          value={email}
          fullWidth
          margin="normal"
          required
          variant="outlined"
          color="secondary"
          error={!email && !!erros.email}
          helperText={!email && erros.email}
          onFocus={() => setErros(defaultErros)}
          onChange={e => setEmail(e.target.value)}
        />
        <Button
          style={{
            marginTop: 14,
            marginBottom: 14,
            background: '#00C2FF',
            color: '#fff',
            fontWeight: 'bold',
            fontSize: 16,
            padding: '12px 32px',
          }}
          variant="contained"
          fullWidth
          type="submit"
        >
          {loading ? 'Carregando ...' : 'Enviar Email'}
        </Button>
        <Link
          style={{
            color: '#fff',
            textDecoration: 'none',
          }}
          to="/"
        >
          Voltar para login
        </Link>
      </form>
    </AnimationContainer>
  );
}

export default ForgotPassword;
