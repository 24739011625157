import { Box, Button } from '@mui/material';
import React from 'react';

interface SideButtonsProps {
  handleSetStatusToAll(status: string | null): void;
}

function SideButtons({ handleSetStatusToAll }: SideButtonsProps) {
  return (
    <Box display="flex">
      <Button
        variant="outlined"
        onClick={() => handleSetStatusToAll('LEGITIMO')}
      >
        Marcar todos como legítimo
      </Button>
      <Button
        variant="outlined"
        style={{ marginLeft: '5px' }}
        onClick={() => handleSetStatusToAll('FRAUDE')}
      >
        Marcar todos como fraude
      </Button>
    </Box>
  );
}

export default SideButtons;
