import { useEffect, useState } from 'react';
import { Container, Data, Divider, List } from 'components/StyledComponents';
import { TotalizatorByMediaTypeFetch } from 'fetches/totalizatorByMediaTypeFetch';
import { Skeleton } from '@mui/material';
import useBreakpoint from 'hooks/useBreakpoint';
import { Indicator } from 'models/reports';
import { capitalize } from 'util/formatter';
import { useReportFilterFlagFetchStore } from 'store/useReportFilterFlagFetchStore';
import icons from './icons';

import { DataInfo, DataText, Header, TableInfo, TableRow } from './styles';
import source from './source';

export interface IndicatorsProps {
  indicators: Array<Indicator>;
}

function TotalByMedia() {
  const start = useReportFilterFlagFetchStore(state => state.startFetch);
  const end = useReportFilterFlagFetchStore(state => state.endFetch);
  const reprocessed = useReportFilterFlagFetchStore(
    state => state.withReprocessedFetch,
  );
  const inReprocessing = useReportFilterFlagFetchStore(
    state => state.inReprocessingFetch,
  );
  const engagement = useReportFilterFlagFetchStore(
    state => state.withEngagementFetch,
  );

  const { data: response } = TotalizatorByMediaTypeFetch(
    start,
    end,
    reprocessed,
    inReprocessing,
    engagement,
  );
  const [indicators, setIndicators] = useState<IndicatorsProps>({
    indicators: [
      {
        porcentagem_total: '0',
        total: 0,
      },
    ],
  } as IndicatorsProps);

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1280);

  useEffect(() => {
    if (!response) {
      return;
    }

    const newIndicators = {
      indicators: [
        {
          pedidos: response?.indicators.pedidos,
          total: response?.indicators.total,
          conversao: response?.indicators.conversao,
          porcentagem_total: response?.indicators.porcentagem_total,
        },
      ],
    };

    setIndicators(newIndicators);
  }, [response]);

  const utmsMediaSorted = response?.medias.slice().sort((a, b) => {
    const sourceA = a.source.toUpperCase();
    const sourceB = b.source.toUpperCase();
    return sourceA.localeCompare(sourceB);
  });

  return (
    <>
      <div className="App">
        <h2>Total por mídia</h2>
      </div>
      <Header>
        <span>Mídia</span>
        {!isTableOrLess ? <span>Pedidos</span> : ''}
        {!isTableOrLess ? <span>Ativados</span> : <span>Vendas</span>}
        {!isTableOrLess ? <span>Conversão</span> : ''}
        <span>Participação</span>
      </Header>
      {!response ? (
        <Skeleton variant="rectangular" width="100%" height={30} />
      ) : (
        <List>
          {utmsMediaSorted && utmsMediaSorted.length > 0 ? (
            <>
              {utmsMediaSorted.map(item => (
                <Container key={item.source}>
                  <Data>
                    <img
                      src={icons[item.source] || icons.default}
                      alt="icons"
                      width={20}
                    />
                    <DataInfo>
                      <DataText>
                        {source[item.source] || capitalize(item.source)}
                      </DataText>
                      {!isTableOrLess ? (
                        <DataText>{item.pedidos}</DataText>
                      ) : (
                        ''
                      )}
                      <DataText>{item.quantidade}</DataText>
                      {!isTableOrLess ? (
                        <DataText>{`${item.conversao}%`}</DataText>
                      ) : (
                        ''
                      )}
                      <DataText>{`${item.porcentagem}%`}</DataText>
                    </DataInfo>
                  </Data>
                  <Divider />
                </Container>
              ))}
              {indicators.indicators.map((indicator, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Container key={`${index}`}>
                  <TableRow>
                    <TableInfo>
                      {!isTableOrLess ? (
                        <DataText>{indicator.pedidos}</DataText>
                      ) : (
                        ''
                      )}
                      <DataText>{indicator.total}</DataText>
                      {!isTableOrLess ? (
                        <DataText>{indicator.conversao}</DataText>
                      ) : (
                        ''
                      )}
                      <DataText>{indicator.porcentagem_total}</DataText>
                    </TableInfo>
                  </TableRow>
                </Container>
              ))}
            </>
          ) : (
            <h3 style={{ marginTop: 15 }}>Não há dados para ser mostrado</h3>
          )}
        </List>
      )}
    </>
  );
}

export default TotalByMedia;
