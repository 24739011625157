import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import useBreakpoint from 'hooks/useBreakpoint';
import { useStyles } from 'pages/Sale/Filters/PopoverActions/styles';
import { useEffect, useState } from 'react';
import { Search } from '@mui/icons-material';
import { Container } from 'components/Filters';
import { statusOptions } from './statusOptions';
import { FilterMargin } from './styles';

interface SelectOptions {
  value: string;
  text: string;
}

interface FiltersProps {
  setStatusFetch(value: string): void;
  setSearch(search: string): void;
}
function Filters({ setStatusFetch, setSearch }: FiltersProps) {
  const [localSearch, setLocalSearch] = useState('');
  const [statusSelecionados, setStatusSelecionados] = useState<SelectOptions[]>(
    [
      { value: 'NOVO', text: 'Novo' },
      { value: 'EM_ANDAMENTO', text: 'Em andamento' },
    ],
  );

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const classes = useStyles();

  useEffect(() => {
    setStatusFetch(statusSelecionados.map(item => item.value).toString());
  }, [setStatusFetch, statusSelecionados]);

  const handleCheckStatus = (selected: SelectOptions) => {
    const statusExist = statusSelecionados.find(
      item => selected.value === item.value,
    );
    if (statusExist) {
      setStatusSelecionados(
        statusSelecionados.filter(item => selected.value !== item.value),
      );
      return;
    }
    setStatusSelecionados([...statusSelecionados, selected]);
  };
  return (
    <Container>
      <FilterMargin>
        <FormControl
          variant="outlined"
          margin="none"
          size="small"
          fullWidth={isTableOrLess}
          sx={{ width: 310 }}
        >
          <InputLabel>Buscar</InputLabel>
          <OutlinedInput
            label="Buscar"
            className="buttonSearch"
            value={localSearch}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                setSearch(localSearch);
              }
            }}
            onChange={e => {
              if (e.target.value === '') {
                setSearch('');
              }
              setLocalSearch(e.target.value as string);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setSearch(localSearch);
                  }}
                  edge="end"
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl
          variant="outlined"
          margin="none"
          size="small"
          fullWidth={isTableOrLess}
          sx={{ width: 310 }}
        >
          <InputLabel>Status</InputLabel>
          <Select
            className="buttonStatus"
            labelId="status"
            id="status"
            multiple
            value={statusSelecionados}
            input={<OutlinedInput id="select-status" label="Status" />}
            renderValue={() => (
              <Box className={classes.chips}>
                {statusSelecionados.slice(0, 2).map((value: SelectOptions) => (
                  <Chip
                    key={value.value}
                    label={value.text}
                    className={classes.chip}
                  />
                ))}
                {statusSelecionados.length > 2 && (
                  <Chip label={`+ ${statusSelecionados.length - 2}`} />
                )}
              </Box>
            )}
          >
            {statusOptions.map((item: SelectOptions) => (
              <MenuItem
                key={item.value}
                value={item.text}
                onClick={() => handleCheckStatus(item)}
              >
                <Checkbox
                  checked={
                    !!statusSelecionados.find(
                      selected => selected.value === item.value,
                    )
                  }
                />
                <ListItemText primary={`${item.text}`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FilterMargin>
    </Container>
  );
}

export default Filters;
