import { Content } from 'components/CardList';
import styled from 'styled-components';

interface HeaderProps {
  isDesktop: boolean;
}
export const Header = styled.div<HeaderProps>`
  display: flex;
  justify-content: ${props => (props.isDesktop ? 'space-between' : 'center')};
  flex-direction: ${props => (props.isDesktop ? 'row' : 'column')};
  padding-bottom: ${props => (props.isDesktop ? '20px' : '30px')};

  .cardHeader {
    padding: 0px;
    padding-top: 20px;
    padding-left: ${props => (props.isDesktop ? '20px' : '0px')};
    padding-bottom: ${props => (props.isDesktop ? '0px' : '20px')};
  }
`;

export const ContentBugReport = styled(Content)`
  @media (max-width: 1023px) {
    padding: 0 5px;
  }
`;
