import React, { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import palette from 'theme/palette';
import {
  statusConf as statusConfList,
  StatusConf as StatusConfType,
} from './statusConf';
import { Container, Descriptions, SubTitle, Title } from './styles';

interface StatusProps {
  status?: string;
  loading: boolean;
}

function Status({ loading, status }: StatusProps) {
  const [statusConf, setStatusConf] = useState<StatusConfType>(() => {
    return (status && statusConfList[status]) || ({} as StatusConfType);
  });
  useEffect(() => {
    status && setStatusConf(statusConfList[status]);
  }, [status]);

  return (
    <Container color={statusConf?.color} backgroudColor={statusConf?.backgroud}>
      {loading ? (
        <>
          <Skeleton animation="wave" width={50} height={50} />
          <Descriptions>
            <Skeleton animation="wave" width={50} height={30} />
            <Skeleton animation="wave" width={100} height={20} />
          </Descriptions>
        </>
      ) : (
        <>
          <img src={statusConf?.icon} alt={statusConf?.title} />
          <Descriptions color="black">
            <Title textColor={palette.primary.main}> {statusConf?.title}</Title>
            <SubTitle textColor={palette.primary.main}>
              {statusConf?.subTitle}
            </SubTitle>
          </Descriptions>
        </>
      )}
    </Container>
  );
}

export default Status;
