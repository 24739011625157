import { useCallback, useEffect, useState } from 'react';

import { cep as cepFormatter } from 'util/formatter';
import { TabContext, TabList } from '@mui/lab';
import { ButtonProgress } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import api from 'services/api';
import { useToast } from 'hooks/toast';

import { Edit, Search } from '@mui/icons-material';
import { CitiesByUFFetch } from 'fetches/citiesByUFFetch';
import { UFFetch } from 'fetches/ufFetch';
import { ModalCard, UseStylesModal } from 'components/ModalCard';
import {
  Backdrop,
  Box,
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  Fade,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Tab,
  TextField,
} from '@mui/material';
import { Sale } from '../types';

import { Container } from '../EditCustomerForm/style';
import { TabControl, TabPanelControl, UseStyles } from './style';

interface EditAddressCustomerFormProps {
  sale: Sale;
  setSale(sale: Sale): void;
}

function EditAdressCustomerForm({
  sale,
  setSale,
}: EditAddressCustomerFormProps) {
  const [cep, setCep] = useState(sale.cep);
  const [uf, setUf] = useState(sale.uf);
  const [cidade, setCidade] = useState(sale.cidade);
  const [logradouro, setLogradouro] = useState(sale.logradouro);
  const [bairro, setBairro] = useState(sale.bairro);
  const [tipoIdentificador, setTipoIdentificador] = useState(
    sale.tipo_identificador,
  );
  const [numero, setNumero] = useState(sale.numero);
  const [quadra, setQuadra] = useState(sale.quadra);
  const [lote, setLote] = useState(sale.lote);
  const [complemento, setComplemento] = useState(sale.complemento);
  const [loading, setLoading] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [showEditCustomer, setShowEditCustomer] = useState(false);
  const [showComplements, setShowComplements] = useState(!!tipoIdentificador);
  const [showNumber, setShowNumber] = useState(
    tipoIdentificador && tipoIdentificador === 'NUMERO',
  );
  const [showCourtLot, setShowCourtLot] = useState(
    tipoIdentificador && tipoIdentificador === 'QUADRA_LOTE',
  );
  const [openModal, setOpenModal] = useState(false);
  const [cepIsValid, setCepIsValid] = useState(true);

  const classes = UseStyles();
  const classesModal = UseStylesModal();

  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  const { addToast } = useToast();

  const { data: ufs } = UFFetch();
  const { data: cities } = CitiesByUFFetch(uf || '');

  useEffect(() => {
    setShowComplements(!!tipoIdentificador);
    setShowNumber(tipoIdentificador && tipoIdentificador === 'NUMERO');
    setShowCourtLot(tipoIdentificador && tipoIdentificador === 'QUADRA_LOTE');
    setFormIsValid(
      !!cep &&
        !!uf &&
        !!cidade &&
        !!logradouro &&
        !!bairro &&
        !!tipoIdentificador,
    );
  }, [
    bairro,
    cep,
    cidade,
    uf,
    logradouro,
    tipoIdentificador,
    numero,
    quadra,
    lote,
    complemento,
  ]);

  useEffect(() => {
    if (tipoIdentificador === 'SEM_NUMERO') {
      setFormIsValid(!!complemento);
    }
    if (tipoIdentificador === 'NUMERO') {
      setFormIsValid(!!numero);
    }
    if (tipoIdentificador === 'QUADRA_LOTE') {
      setFormIsValid(!!quadra && !!lote);
    }
  }, [complemento, lote, numero, quadra, tipoIdentificador]);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const clearNumber = useCallback(() => {
    setNumero('');
  }, []);

  const clearCourtAndLot = useCallback(() => {
    setLote('');
    setQuadra('');
  }, []);

  const handleChangeTypeIdentifier = useCallback(
    (value: string) => {
      if (value !== 'NUMERO') {
        clearNumber();
      }
      if (value !== 'QUADRA_LOTE') {
        clearCourtAndLot();
      }
    },
    [clearCourtAndLot, clearNumber],
  );

  const handleChange = (event: unknown, newValue: string | '') => {
    setTipoIdentificador(newValue);
    handleChangeTypeIdentifier(event as string);
  };

  const handleZipCodeNotFound = useCallback(() => {
    cepIsValid && setUf('');
    setCidade('');
    setLogradouro('');
    setBairro('');
    setComplemento('');
    setTipoIdentificador('NUMERO');
    setNumero('');
    setQuadra('');
    setLote('');
  }, [cepIsValid]);

  const handleAddressFields = useCallback(() => {
    setCepIsValid(true);
    setCep(sale.cep);
    setUf(sale.uf);
    setCidade(sale.cidade);
    setLogradouro(sale.logradouro);
    setBairro(sale.bairro);
    setComplemento(sale.complemento);
    setTipoIdentificador(sale.tipo_identificador);
    setNumero(sale.numero);
    setQuadra(sale.quadra);
    setLote(sale.lote);
  }, [sale]);

  const handleFindByZipCode = useCallback(() => {
    setLoading(true);
    api
      .get(`/core/enderecos/cep/${cep}`)
      .then(({ data }) => {
        setLoading(false);
        setUf(data.stateOrProvince as string);
        setCidade(
          data.city
            .normalize('NFD')
            .replace(/[^a-zA-Z\s]/g, '')
            .toUpperCase() as string,
        );
        setLogradouro(data.streetName as string);
        setBairro(data.locality as string);
      })
      .catch(error => {
        handleZipCodeNotFound();
        error.data && setCepIsValid(!cepIsValid);
        setLoading(false);
      });
  }, [cep, cepIsValid, handleZipCodeNotFound]);

  const handleUpdate = useCallback(() => {
    const dataResponse = {
      sale_form: {
        venda_id: sale.id,
        cep,
        uf,
        cidade,
        logradouro,
        bairro,
        complemento,
        tipo_identificador: tipoIdentificador,
        numero,
        quadra,
        lote,
      },
    };
    setLoading(true);
    api
      .put(`/core/vendas/endereco/${sale.id}`, dataResponse)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Edição concluída',
          description: 'Dados de endereço atualizados com sucesso!',
        });
        setLoading(false);
        setOpenModal(false);
        setShowEditCustomer(false);
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro ao Atualizar os dados do endereço.',
          description:
            err.data && err.data.message ? err.data.message : err.message,
        });
        setLoading(false);
        setOpenModal(false);
      })
      .finally(() => {
        api
          .get(`core/vendas/${sale.id}`)
          .then(({ data }) => setSale({ ...sale, ...data }));
      });
  }, [
    sale,
    cep,
    uf,
    cidade,
    logradouro,
    bairro,
    complemento,
    numero,
    quadra,
    lote,
    tipoIdentificador,
    addToast,
    setSale,
  ]);

  return (
    <Container>
      <IconButton onClick={() => setShowEditCustomer(true)}>
        <Edit />
      </IconButton>
      <Dialog
        open={showEditCustomer}
        onClose={() => setShowEditCustomer(false)}
        aria-labelledby={`edit-address_${sale.id}`}
        fullScreen={isTabletSizeOrSmaller}
      >
        <Box padding="20px">
          <CardHeader
            id={`edit-address_${sale.id}`}
            title="Editar endereço do pedido"
            subheader="Após a alteração, será necessário imprimir novamente a etiqueta de envio, caso o pedido precise ser enviado"
            style={{ padding: 10, paddingBottom: 19 }}
          />
          <CardContent>
            <FormControl
              variant="outlined"
              margin="none"
              size="small"
              fullWidth
              style={{ paddingBottom: 30 }}
            >
              <InputLabel>CEP</InputLabel>
              <OutlinedInput
                fullWidth
                label="CEP"
                value={cepFormatter(cep)}
                onKeyPress={event => {
                  if (/[0-9]/.test(event.key)) {
                    return;
                  }
                  event.preventDefault();
                }}
                onChange={e => setCep(e.target.value)}
                inputProps={{ maxLength: 8, width: '100%' }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={handleFindByZipCode}>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                }
              />
              {!cepIsValid && (
                <FormHelperText id="standard-weight-helper-text">
                  Não encontramos esse CEP em nossa base de consultas.
                </FormHelperText>
              )}
            </FormControl>

            <TextField
              label="Estado"
              variant="outlined"
              select
              value={uf}
              SelectProps={{ native: true }}
              onChange={e => setUf(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              style={{ paddingBottom: 30 }}
              disabled={loading}
            >
              <option value="" disabled>
                Selecione um estado
              </option>
              {ufs?.map(option => (
                <option key={option.id} value={option.sigla}>
                  {option.nome}
                </option>
              ))}
            </TextField>
            <TextField
              label="Cidade"
              variant="outlined"
              select
              value={cidade}
              SelectProps={{ native: true }}
              onChange={e => setCidade(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              style={{ paddingBottom: 20 }}
              disabled={loading}
            >
              <option value="" disabled>
                Selecione uma cidade
              </option>
              {cities?.map(option => (
                <option key={option.id} value={option.nome}>
                  {option.nome}
                </option>
              ))}
            </TextField>
            <TextField
              name="logradouro"
              value={logradouro}
              aria-readonly
              fullWidth
              label="Rua"
              placeholder="Informe a rua da residência"
              margin="dense"
              onChange={e => setLogradouro(e.target.value)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={loading}
              error={logradouro === ''}
              helperText={
                logradouro === '' ? 'Informe o endereço da residência.' : ' '
              }
            />
            <TextField
              name="bairro"
              value={bairro}
              aria-readonly
              fullWidth
              label="Bairro"
              placeholder="Informe o bairro da residência"
              margin="dense"
              onChange={e => setBairro(e.target.value)}
              variant="outlined"
              style={{ paddingBottom: 10 }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={loading}
              error={bairro === ''}
              helperText={
                bairro === '' ? 'Informe o bairro da residência.' : ' '
              }
            />
            <TabContext value={tipoIdentificador || ''}>
              <TabControl>
                <TabList
                  onChange={handleChange}
                  classes={{
                    indicator: classes.indicator,
                  }}
                  variant="fullWidth"
                >
                  <Tab
                    classes={{
                      root: classes.root,
                      selected: classes.selectedNumber,
                    }}
                    label="Número"
                    value="NUMERO"
                  />
                  <Tab
                    classes={{
                      root: classes.root,
                      selected: classes.selectedQuadraLote,
                    }}
                    label="Quadra / Lote"
                    value="QUADRA_LOTE"
                  />
                  <Tab
                    classes={{
                      root: classes.numberOfRoot,
                      selected: classes.selectedNumberOf,
                    }}
                    label="Sem Número"
                    value="SEM_NUMERO"
                  />
                </TabList>
              </TabControl>
              <TabPanelControl>
                {showNumber && (
                  <TextField
                    value={numero}
                    aria-readonly
                    fullWidth
                    label="Número da residência"
                    placeholder="Informe o número da residência"
                    margin="dense"
                    name="numero"
                    onKeyPress={event => {
                      if (/[0-9]/.test(event.key)) {
                        return;
                      }
                      event.preventDefault();
                    }}
                    onChange={e => setNumero(e.target.value)}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={loading}
                    error={numero === ''}
                    helperText={
                      numero === '' ? 'Informe o número da residência.' : ' '
                    }
                  />
                )}
              </TabPanelControl>

              {showCourtLot && (
                <>
                  <TabPanelControl>
                    <TextField
                      value={quadra}
                      aria-readonly
                      fullWidth
                      label="Quadra"
                      placeholder="Informe a quadra da residência"
                      margin="dense"
                      name="quadra"
                      onKeyPress={event => {
                        if (/[0-9]/.test(event.key)) {
                          return;
                        }
                        event.preventDefault();
                      }}
                      onChange={e => setQuadra(e.target.value)}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={loading}
                      error={quadra === ''}
                      helperText={
                        quadra === '' ? 'Informe a quadra da residência.' : ' '
                      }
                    />
                  </TabPanelControl>
                  <TabPanelControl>
                    <TextField
                      value={lote}
                      aria-readonly
                      fullWidth
                      label="Lote"
                      placeholder="Informe o lote da residência"
                      margin="dense"
                      name="lote"
                      onKeyPress={event => {
                        if (/[0-9]/.test(event.key)) {
                          return;
                        }
                        event.preventDefault();
                      }}
                      onChange={e => setLote(e.target.value)}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={loading}
                      error={lote === ''}
                      helperText={
                        lote === '' ? 'Informe o lote da residência.' : ' '
                      }
                    />
                  </TabPanelControl>
                </>
              )}
              <TabPanelControl>
                {showComplements && (
                  <TextField
                    value={complemento}
                    aria-readonly
                    fullWidth
                    label="Complemento"
                    placeholder="Bloco A, Apto 21"
                    margin="dense"
                    name="complemento"
                    onChange={e => setComplemento(e.target.value)}
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={loading}
                    error={
                      tipoIdentificador === 'SEM_NUMERO' && complemento === ''
                    }
                    helperText={
                      tipoIdentificador === 'SEM_NUMERO' && complemento === ''
                        ? 'O campo é obrigatório quando Sem Número.'
                        : ' '
                    }
                  />
                )}
              </TabPanelControl>
            </TabContext>
          </CardContent>
          <DialogActions
            style={{
              justifyContent: 'space-between',
              paddingRight: 15,
              paddingLeft: 15,
            }}
          >
            <Button
              onClick={() => {
                setShowEditCustomer(false);
                handleAddressFields();
              }}
              type="button"
              variant="contained"
              disabled={loading}
              style={{ backgroundColor: 'white', color: '#C92A2A' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleOpen}
              color="primary"
              type="button"
              variant="contained"
              disabled={!formIsValid || loading}
            >
              Salvar Endereço
            </Button>
          </DialogActions>
          <Modal
            aria-labelledby="tile-modal"
            aria-describedby="modal-description"
            className={classesModal.modal}
            open={openModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 200,
            }}
          >
            <Fade in={openModal}>
              <ModalCard className={classesModal.paper}>
                <h2 id="title-modal">Deseja realmente alterar o endereço?</h2>
                <p id="modal-description">
                  Após a confirmação, será gerada uma nova etiqueta do melhor
                  envio, caso seja necessário.
                </p>

                <DialogActions
                  style={{
                    justifyContent: 'space-between',
                    paddingRight: '24px',
                    paddingLeft: '32px',
                    paddingTop: '24px',
                    paddingBottom: '32px',
                  }}
                >
                  <Button
                    style={{ color: '#C92A2A' }}
                    onClick={() => setOpenModal(false)}
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={() => {
                      handleUpdate();
                    }}
                    color="primary"
                    type="button"
                    variant="contained"
                  >
                    Confirmar
                    {loading && <ButtonProgress size={24} />}
                  </Button>
                </DialogActions>
              </ModalCard>
            </Fade>
          </Modal>
        </Box>
      </Dialog>
    </Container>
  );
}

export default EditAdressCustomerForm;
