import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import InsertChartIcon from '@mui/icons-material/InsertChartOutlined';
import palette from 'theme/palette';

interface CardConfig {
  title: string;
  segmento: string;
  icon: React.ElementType;
  colorIcon: string;
  iconTypesColor: string;
  titleColor: string;
  subtitleColor: string;
  totalColor: string;
  typeIndicatorColor: string;
  countTypeIndicatorColor: string;
  boxIconColor: string;
  contentColor: string;
}

interface CardTypes {
  CONTROLE: CardConfig;
  EXPRESS: CardConfig;
  POS_PAGO: CardConfig;
  REAL: CardConfig;
  [key: string]: CardConfig;
}

export const cardConfig: CardTypes = {
  CONTROLE: {
    title: 'Controle',
    segmento: 'CONTROLE',
    icon: ReceiptOutlinedIcon,
    colorIcon: palette.controle,
    iconTypesColor: palette.primary.gray,
    titleColor: palette.black,
    subtitleColor: palette.controle,
    typeIndicatorColor: palette.primary.gray,
    countTypeIndicatorColor: palette.black,
    totalColor: palette.controle,
    boxIconColor: '#f7f7f8',
    contentColor: palette.white,
  },
  EXPRESS: {
    title: 'Express',
    segmento: 'EXPRESS',
    colorIcon: palette.controle,
    icon: CreditCardIcon,
    iconTypesColor: palette.primary.gray,
    titleColor: palette.black,
    subtitleColor: palette.controle,
    typeIndicatorColor: palette.primary.gray,
    countTypeIndicatorColor: palette.black,
    totalColor: palette.controle,
    boxIconColor: '#f7f7f8',
    contentColor: palette.white,
  },
  POS_PAGO: {
    title: 'Pós-pago',
    segmento: 'POS_PAGO',
    icon: DraftsOutlinedIcon,
    colorIcon: palette.controle,
    iconTypesColor: palette.primary.gray,
    titleColor: palette.black,
    subtitleColor: palette.controle,
    typeIndicatorColor: palette.primary.gray,
    countTypeIndicatorColor: palette.black,
    totalColor: palette.controle,
    boxIconColor: '#f7f7f8',
    contentColor: palette.white,
  },
  REAL: {
    title: 'Real',
    segmento: '',
    icon: InsertChartIcon,
    colorIcon: palette.info.light,
    iconTypesColor: palette.white,
    titleColor: palette.info.light,
    subtitleColor: palette.white,
    typeIndicatorColor: palette.info.light,
    countTypeIndicatorColor: palette.white,
    totalColor: palette.white,
    boxIconColor: palette.darkGray,
    contentColor: palette.primary.gray,
  },
};
