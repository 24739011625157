import { Grid, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { date as dateFormatter, hour as hourFormatter } from 'util/formatter';
import {
  CloseButton,
  DividerDrawer,
  SessionTitle,
} from 'components/StyledComponents';

import { Error } from 'models/bugReport';
import { Container } from './styles';
import TextInfo from './TextInfo';
import ApiLogHighlighter from './ApiLogHighlighter';

interface ErrorInfoProps {
  error: Error;
  closeDrawer(): void;
}

function ErrorInfo({ error, closeDrawer }: ErrorInfoProps) {
  const method = error.method.toUpperCase();
  const curl = `curl --location --request ${method} '${`https://api.internal.timbrasil.com.br${error.url}`}' \u005C\n`;
  const authorization = `--header 'Authorization: ${error.authorization}' \u005C\n`;
  const contentType = `--header 'Content-Type: application/json' \u005C\n`;
  const dataJson = `--data-raw '${JSON.stringify(
    error.request,
    undefined,
    2,
  )}'`;

  const screenshot = curl + authorization + contentType + dataJson;

  return (
    <Container>
      <Grid container spacing={2}>
        <CloseButton onClick={() => closeDrawer()}>
          <IconButton style={{ padding: 0 }}>
            <Close />
          </IconButton>
        </CloseButton>
        <Grid item xs={12}>
          <SessionTitle>
            Informações
            <DividerDrawer />
          </SessionTitle>
        </Grid>
        <Grid item xl={2} md={2}>
          <TextInfo
            loading={!error}
            label="Hora"
            info={hourFormatter(error.datetime)}
          />
        </Grid>
        <Grid item xl={2} md={3}>
          <TextInfo
            loading={!error}
            label="Data"
            info={dateFormatter(error.datetime)}
          />
        </Grid>
        <Grid item xl={2} md={2}>
          <TextInfo loading={!error} label="Contexto" context={error.url} />
        </Grid>
        <Grid item xl={2} md={4}>
          <TextInfo
            loading={!error}
            label="Copiar curl"
            screenshot={screenshot}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInfo
            loading={!error}
            label="Mensagem"
            info={error.response.message ? error.response.message : '-'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInfo loading={!error} label="URL" info={error.url} />
        </Grid>
        <Grid item xs={12}>
          <TextInfo
            loading={!error}
            label="Authorization Bearer"
            info={error.authorization}
            canCopy
          />
        </Grid>
        <Grid item xs={12}>
          <SessionTitle>
            Dados da API
            <DividerDrawer />
          </SessionTitle>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'flex', gap: 25, flexDirection: 'column' }}>
            <ApiLogHighlighter
              log={JSON.stringify(error.request, undefined, 2)}
              title="request"
            />
            <ApiLogHighlighter
              log={JSON.stringify(error.response, undefined, 2)}
              title="response"
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ErrorInfo;
