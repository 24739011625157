import React from 'react';
import { List } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';

import { AffiliateBase } from 'models/affiliates';
import Item from './Item';
import MobileItem from './MobileItem';

import { Container, Header } from './styles';

interface AffiliateBaseListProps {
  affiliates?: AffiliateBase[];
}

interface BodyProps {
  affiliates: AffiliateBase[];
  isMobile: boolean;
}
interface LoadingListProps {
  isTableOrLess: boolean;
}

function LoadingList({ isTableOrLess }: LoadingListProps) {
  return isTableOrLess ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({ affiliates, isMobile }: BodyProps) {
  return (
    <List>
      {affiliates.length ? (
        affiliates.map(item =>
          isMobile ? (
            <MobileItem key={item.id} data={item} />
          ) : (
            <Item key={item.id} data={item} />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhuma base encontrada</h3>
      )}
    </List>
  );
}

function AffiliateBaseList({ affiliates }: AffiliateBaseListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Container>
      {!isTableOrLess && (
        <Header>
          <span>Nome</span>
          <span>Tipo de base</span>
          <span>Status</span>
          <span>Leads Criados</span>
          <span>Leads Convertidos</span>
          <span>Total de linhas</span>
          <span>Conversão</span>
        </Header>
      )}
      {affiliates ? (
        <Body affiliates={affiliates} isMobile={isTableOrLess} />
      ) : (
        <LoadingList isTableOrLess={isTableOrLess} />
      )}
    </Container>
  );
}

export default AffiliateBaseList;
