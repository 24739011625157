import { Box, Grid, Typography } from '@mui/material';
import { ReactComponent as MigrationIcon } from 'assets/icons/small/migration.svg';
import { ReactComponent as NewLineIcon } from 'assets/icons/small/new_line.svg';
import { ReactComponent as PortabilityIcon } from 'assets/icons/small/portability.svg';
import { InfoTotalizator } from 'models/reports';
import { BoxDescriptionType } from 'pages/ManagementReport/styles';
import { cardConfig } from 'pages/ManagementReport/reports/CardTypeIndicator/cardConfig';
import useStyles from './styles';

interface TypeIndicatorProps {
  cardType: string;
  migracao?: InfoTotalizator;
  novaLinha?: InfoTotalizator;
  portabilidade?: InfoTotalizator;
}

function TypeIndicator({
  cardType,
  migracao,
  novaLinha,
  portabilidade,
}: TypeIndicatorProps) {
  const classes = useStyles();
  return (
    <Grid justifyContent="flex-end" className={classes.boxTypeIndicator}>
      <Grid item>
        <BoxDescriptionType>
          <MigrationIcon fill={cardConfig[cardType].iconTypesColor} />
          <Box className={classes.boxCount}>
            <Typography
              className={classes.typeIndicatorText}
              color={cardConfig[cardType].typeIndicatorColor}
            >
              Migração
            </Typography>
            <Typography
              className={classes.typeIndicatorText}
              color={cardConfig[cardType].countTypeIndicatorColor}
            >
              {migracao?.quantidade || 0}{' '}
              {migracao?.porcentagem
                ? `(${migracao?.porcentagem})%`
                : `(${0})%`}
            </Typography>
          </Box>
        </BoxDescriptionType>
      </Grid>
      <Grid item>
        <BoxDescriptionType>
          <NewLineIcon fill={cardConfig[cardType].iconTypesColor} />
          <Box className={classes.boxCount}>
            <Typography
              className={classes.typeIndicatorText}
              color={cardConfig[cardType].typeIndicatorColor}
            >
              Nova Linha
            </Typography>
            <Typography
              className={classes.typeIndicatorText}
              color={cardConfig[cardType].countTypeIndicatorColor}
            >
              {novaLinha?.quantidade || 0}{' '}
              {novaLinha?.porcentagem
                ? `(${novaLinha?.porcentagem})%`
                : `(${0})%`}
            </Typography>
          </Box>
        </BoxDescriptionType>
      </Grid>
      <Grid item>
        <BoxDescriptionType>
          <PortabilityIcon fill={cardConfig[cardType].iconTypesColor} />
          <Box className={classes.boxCount}>
            <Typography
              className={classes.typeIndicatorText}
              color={cardConfig[cardType].typeIndicatorColor}
            >
              Portabilidade
            </Typography>
            <Typography
              className={classes.typeIndicatorText}
              color={cardConfig[cardType].countTypeIndicatorColor}
            >
              {portabilidade?.quantidade || 0}{' '}
              {portabilidade?.porcentagem
                ? `(${portabilidade?.porcentagem})%`
                : `(${0})%`}
            </Typography>
          </Box>
        </BoxDescriptionType>
      </Grid>
    </Grid>
  );
}

export default TypeIndicator;
