import useSWR from 'swr';
import { parseISO, startOfDay, endOfDay, format } from 'date-fns';
import api from 'services/api';
import { Indicator, Media, TotalizatorByMediaType } from 'models/reports';

export const TotalizatorByMediaTypeFetch = (
  start: string,
  end: string,
  reprocessed: boolean,
  inReprocessing: boolean,
  engagement: boolean,
) => {
  const inicio = format(startOfDay(parseISO(start)), 'yyyy-MM-dd HH:mm:ss');
  const fim = format(endOfDay(parseISO(end)), 'yyyy-MM-dd HH:mm:ss');

  const { data, mutate } = useSWR<TotalizatorByMediaType>(
    `media_${inicio}_${fim}_${reprocessed}_${inReprocessing}_${engagement}`,

    async (): Promise<TotalizatorByMediaType> => {
      const params = {
        inicio,
        fim,
        trazer_reprocessados: reprocessed ? 1 : 0,
        trazer_em_processamento: inReprocessing ? 1 : 0,
        trazer_engajadas: engagement ? 1 : 0,
      };
      const response = await api.get(
        `/core/relatorios/dashboards/totalizador-por-tipo-midia`,
        { params },
      );

      const medias = response.data.midias as Media[];
      const indicators = response.data.indicadores as Indicator;

      return { medias, indicators } as TotalizatorByMediaType;
    },
  );
  return { data, mutate };
};
