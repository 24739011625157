import { Indicator } from 'models/reports';
import {
  Container,
  MobileContainer,
  MobileData,
  MobileDataLabel,
} from 'components/StyledComponents';
import {
  TableInfo,
  TableRow,
} from 'pages/ManagementReport/reports/TotalByMedia/styles';
import { GroupData, MobileDataText } from 'components/MobileList';
import { DataText } from './styles';

interface TotalProps {
  indicator: Indicator;
  index: number;
  isMobile: boolean;
}

function Total({ indicator, index, isMobile }: TotalProps) {
  return !isMobile ? (
    <Container key={`${index}`}>
      <TableRow>
        <TableInfo>
          <DataText>{indicator.pedidos}</DataText>
          <DataText>{indicator.total}</DataText>
          <DataText>{indicator.conversao}</DataText>
          <DataText>{indicator.porcentagem_total}</DataText>
        </TableInfo>
      </TableRow>
    </Container>
  ) : (
    <MobileContainer key={`${index}`}>
      <GroupData>
        <MobileData>
          <MobileDataLabel>Total Pedidos</MobileDataLabel>
          <MobileDataText>{indicator.pedidos}</MobileDataText>
        </MobileData>
        <MobileData>
          <MobileDataLabel>Total Ativados</MobileDataLabel>
          <MobileDataText>{indicator.total}</MobileDataText>
        </MobileData>
      </GroupData>
      <GroupData>
        <MobileData>
          <MobileDataLabel>Total Conversão</MobileDataLabel>
          <MobileDataText>{indicator.conversao}</MobileDataText>
        </MobileData>
        <MobileData>
          <MobileDataLabel>Total Participação</MobileDataLabel>
          <MobileDataText>{indicator.porcentagem_total}</MobileDataText>
        </MobileData>
      </GroupData>
    </MobileContainer>
  );
}

export default Total;
