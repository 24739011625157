import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';

import React, { useState } from 'react';

import { Search } from '@mui/icons-material';
import useBreakpoint from 'hooks/useBreakpoint';
import ufs from 'constants/ufs.json';
import { Container, FilterMargin } from './styles';

interface UF {
  value: string;
}

interface FiltersProps {
  setSearch(search: string): void;
  uf: string;
  setUf(uf: string): void;
}

function Filters({ setSearch, uf, setUf }: FiltersProps) {
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  const [localSearch, setLocalSearch] = useState('');

  return (
    <Container>
      <FilterMargin>
        <TextField
          label="Filtre por UF"
          variant="outlined"
          className="buttonUF"
          select
          value={uf}
          SelectProps={{ native: true }}
          onChange={e => setUf(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          fullWidth
        >
          <option aria-label="Todos" value="">
            Todos
          </option>
          {ufs?.map((option: UF) => (
            <option key={option.value} value={option.value}>
              {option.value}
            </option>
          ))}
        </TextField>
        <FormControl
          variant="outlined"
          margin="none"
          size="small"
          fullWidth={isTabletSizeOrSmaller}
        >
          <InputLabel>Buscar</InputLabel>
          <OutlinedInput
            label="Buscar"
            value={localSearch}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                setSearch(localSearch);
              }
            }}
            onChange={e => {
              if (e.target.value === '') {
                setSearch('');
              }
              setLocalSearch(e.target.value as string);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setSearch(localSearch);
                  }}
                  edge="end"
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </FilterMargin>
    </Container>
  );
}

export default Filters;
