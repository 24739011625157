import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { useCallback } from 'react';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { Container, ButtonFilter, FilterMargin, useStyles } from './styles';
import { contextOptions } from './contextOptions';

interface ContextOptions {
  value: string;
  text: string;
}

interface FiltersProps {
  fifteenMinutes: boolean;

  setFifteenMinutes(value: boolean): void;

  oneHour: boolean;

  setOneHour(value: boolean): void;

  today: boolean;

  setToday(value: boolean): void;

  yesterday: boolean;

  setYesterday(value: boolean): void;

  start: Date;

  setStart(newDate: Date): void;

  end: Date;

  setEnd(newDate: Date): void;

  context: string;

  setContext(context: string): void;

  isTableOrLess: boolean;
}

function Filters({
  fifteenMinutes,
  setFifteenMinutes,
  oneHour,
  setOneHour,
  today,
  setToday,
  yesterday,
  setYesterday,
  start,
  setStart,
  end,
  setEnd,
  context,
  setContext,
  isTableOrLess,
}: FiltersProps) {
  const classes = useStyles();

  const handleFifteenMinutes = useCallback(
    (value: boolean) => {
      if (value) {
        setOneHour(false);
        setToday(false);
        setYesterday(false);
      }
      setFifteenMinutes(value);
    },
    [setFifteenMinutes, setOneHour, setToday, setYesterday],
  );
  const handleOneHour = useCallback(
    (value: boolean) => {
      if (value) {
        setFifteenMinutes(false);
        setToday(false);
        setYesterday(false);
      }
      setOneHour(value);
    },
    [setFifteenMinutes, setOneHour, setToday, setYesterday],
  );

  const handleToday = useCallback(
    (value: boolean) => {
      if (value) {
        setFifteenMinutes(false);
        setOneHour(false);
        setYesterday(false);
      }
      setToday(value);
    },
    [setFifteenMinutes, setOneHour, setToday, setYesterday],
  );

  const handleYesterday = useCallback(
    (value: boolean) => {
      if (value) {
        setOneHour(false);
        setToday(false);
        setFifteenMinutes(false);
      }

      setYesterday(value);
    },
    [setFifteenMinutes, setOneHour, setToday, setYesterday],
  );

  const handleStartChange = (date: Date | string | null) => {
    if (typeof date === 'string') {
      setStart(new Date(date));
      return;
    }
    date && setStart(date);
  };
  const handleEndChange = (date: Date | string | null) => {
    if (typeof date === 'string') {
      setEnd(new Date(date));
      return;
    }
    date && setEnd(date);
  };

  return (
    <Container>
      <FilterMargin>
        <ButtonFilter
          onClick={() => handleFifteenMinutes(!fifteenMinutes)}
          actived={fifteenMinutes}
        >
          15 MIN
        </ButtonFilter>
        <ButtonFilter onClick={() => handleOneHour(!oneHour)} actived={oneHour}>
          1 HORA
        </ButtonFilter>
        <ButtonFilter onClick={() => handleToday(!today)} actived={today}>
          HOJE
        </ButtonFilter>
        <ButtonFilter
          onClick={() => handleYesterday(!yesterday)}
          actived={yesterday}
        >
          ONTEM
        </ButtonFilter>
      </FilterMargin>
      <FilterMargin>
        <DesktopDateTimePicker
          renderInput={params => (
            <TextField
              {...params}
              className={classes.startKeyboardDate}
              fullWidth
            />
          )}
          label="Início"
          inputFormat="dd/MM/yyyy HH:mm"
          InputAdornmentProps={{ position: 'end' }}
          value={start}
          onChange={date => handleStartChange(date)}
        />

        <DesktopDateTimePicker
          renderInput={params => (
            <TextField
              {...params}
              className={classes.endKeyboardDate}
              fullWidth
            />
          )}
          label="Fim"
          minDate={start}
          inputFormat="dd/MM/yyyy HH:mm"
          InputAdornmentProps={{ position: 'end' }}
          value={end}
          onChange={date => handleEndChange(date)}
        />
      </FilterMargin>
      <FilterMargin>
        <FormControl
          variant="outlined"
          margin="none"
          size="small"
          fullWidth={isTableOrLess}
        >
          <InputLabel htmlFor="contexto" focused shrink>
            Contexto
          </InputLabel>
          <Select
            native
            label="Contexto"
            labelId="contexto"
            id="contexto"
            className="select"
            displayEmpty
            value={context}
            onChange={e => setContext(e.target.value as string)}
            input={
              <OutlinedInput id="select-contexto" label="Contexto" notched />
            }
          >
            <option aria-label="Todos" value="">
              Todos
            </option>
            {contextOptions.map((item: ContextOptions) => (
              <option key={item.value} value={item.value}>
                {item.text}
              </option>
            ))}
          </Select>
        </FormControl>
      </FilterMargin>
    </Container>
  );
}

export default Filters;
