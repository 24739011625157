import React, { useEffect, useState } from 'react';
import { Card, Header } from 'components/StyledComponents';
import { Button, CardContent, CardHeader, Grid } from '@mui/material';
import { Add } from '@mui/icons-material';
import useBreakpoint from 'hooks/useBreakpoint';
import palette from 'theme/palette';

import TrendingDown from 'assets/icons/trending_down.svg';
import { useSimcardStock } from 'store/simcardStock';
import { useNavigate } from 'react-router-dom';
import {
  LowStockIcon,
  StockCard,
  StockCardCounter,
  StockCardCounterNumber,
  StockCardCounterUnit,
  StockCardSubTitle,
  StockCardTitle,
} from './styles';
import { StockSimcardsFetch } from '../../../fetches/simcardsFetch';
import CreateDialog from '../components/CreateDialog';

function Dashboard() {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const { data: stockResponse, mutate } = StockSimcardsFetch();
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const setHlrSelected = useSimcardStock(state => state.setHlrSelected);
  const navigate = useNavigate();

  useEffect(() => {
    setHlrSelected('');
  }, [setHlrSelected]);

  return (
    <Card>
      <Header>
        <CardHeader
          title="Estoque de SIM Cards"
          subheader="Painel de gestão de estoque de chips"
        />

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setShowCreateDialog(true);
          }}
        >
          {isTableOrLess ? (
            <Add style={{ color: palette.white }} />
          ) : (
            'Cadastrar simcards'
          )}
        </Button>
      </Header>

      <CardContent>
        <CardHeader title="Lista de SIM Cards por HLR" />

        <Grid container spacing={4} alignItems="center">
          {stockResponse &&
            stockResponse.map(simcardStock => (
              <Grid
                item
                lg={3}
                xl={3}
                sm={12}
                xs={12}
                md={6}
                key={simcardStock.id}
              >
                <StockCard
                  onClick={() => {
                    const hlr = simcardStock.hlr ?? 'SEM HLR';

                    setHlrSelected(hlr);
                    navigate('/estoque-simcard/estoque-lotes/');
                  }}
                >
                  <StockCardTitle>
                    {simcardStock.hlr ? `HLR ${simcardStock.hlr}` : 'SEM HRL'}
                  </StockCardTitle>
                  <StockCardCounter>
                    <StockCardCounterNumber>
                      {simcardStock.chips}
                    </StockCardCounterNumber>
                    <StockCardCounterUnit>/un</StockCardCounterUnit>

                    {simcardStock.lowAmountOfChips && (
                      <LowStockIcon src={TrendingDown} alt="Baixo estoque" />
                    )}
                  </StockCardCounter>
                  <StockCardSubTitle>
                    {simcardStock.caixas} caixas em estoque
                  </StockCardSubTitle>
                </StockCard>
              </Grid>
            ))}
        </Grid>
      </CardContent>

      <CreateDialog
        showCreateDialog={showCreateDialog}
        setShowCreateDialog={setShowCreateDialog}
        createAction={mutate}
      />
    </Card>
  );
}

export default Dashboard;
