import { Tooltip } from '@mui/material';
import Icons from 'pages/Sale/SaleList/Item/icons';
import { Status } from './styles';

interface StatusSaleProps {
  status: string;
}

function StatusSale({ status }: StatusSaleProps) {
  return (
    <Tooltip title={status} placement="left">
      <Status src={Icons[status]} alt={status} />
    </Tooltip>
  );
}

export default StatusSale;
