import { List } from 'components/StyledComponents';
import { BlacklistDataBase } from 'models/blacklistData';
import useBreakpoint from 'hooks/useBreakpoint';
import { useCallback, useState } from 'react';
import { useToast } from 'hooks/toast';
import api from 'services/api';
import DeleteModal from 'pages/CmsLP/components/DeleteModal';
import MobileItem from './MobileItem';
import Item from './Item';
import { Container, Header } from './styles';

interface BlacklistDataListProps {
  blacklists?: BlacklistDataBase[];
  checkData: number[];
  setCheckData(value: number[]): void;
  updateBlacklist?(): void;
}

interface BodyProps {
  blacklists: BlacklistDataBase[];
  isMobile: boolean;
  checkData: number[];
  handleCheckData(value: number): void;
  setDataSelected(value: BlacklistDataBase): void;
  handleOpenModalDelete(): void;
}
interface LoadingListProps {
  isTableOrLess: boolean;
}

function LoadingList({ isTableOrLess }: LoadingListProps) {
  return isTableOrLess ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({
  blacklists,
  isMobile,
  checkData,
  setDataSelected,
  handleCheckData,
  handleOpenModalDelete,
}: BodyProps) {
  return (
    <List>
      {blacklists.length ? (
        blacklists.map(item =>
          isMobile ? (
            <MobileItem
              key={item.id}
              data={item}
              selected={checkData?.includes(item.id)}
              handleCheckData={handleCheckData}
              setDataSelected={setDataSelected}
              handleOpenModalDelete={handleOpenModalDelete}
            />
          ) : (
            <Item
              key={item.id}
              data={item}
              selected={checkData?.includes(item.id)}
              handleCheckData={handleCheckData}
              setDataSelected={setDataSelected}
              handleOpenModalDelete={handleOpenModalDelete}
            />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhuma base encontrada</h3>
      )}
    </List>
  );
}

function BlacklistDataList({
  blacklists,
  checkData,
  setCheckData,
  updateBlacklist,
}: BlacklistDataListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const [dataSelected, setDataSelected] = useState<BlacklistDataBase>();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const handleOpen = () => {
    setOpenModalDelete(true);
  };

  const handleClose = () => {
    setOpenModalDelete(false);
  };

  const handleDelete = useCallback(() => {
    setLoading(true);

    const data = {
      id: dataSelected?.id,
    };
    api
      .put(`/core/dados-blacklist/excluir`, data)
      .then(() => {
        updateBlacklist && updateBlacklist();

        addToast({
          type: 'success',
          title: 'Dado excluído',
          description: `O dado blacklist foi excluído com sucesso!`,
        });

        setLoading(false);
        handleClose();
      })
      .catch(error => {
        let { message } = error.data;

        if (!message) {
          message = error.data.errors[0]?.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Remover dado ',
            description: error?.data?.message,
          });
        setLoading(false);
        handleClose();
      });
  }, [addToast, dataSelected, updateBlacklist]);

  const handleCheckData = (customerId: number) => {
    const newCheckData = checkData.includes(customerId)
      ? checkData.filter(id => id !== customerId)
      : [...checkData, customerId];

    setCheckData(newCheckData);
  };
  return (
    <Container>
      {!isTableOrLess && (
        <Header>
          <span>Tipo</span>
          <span>Dado</span>
        </Header>
      )}
      {blacklists ? (
        <Body
          blacklists={blacklists}
          isMobile={isTableOrLess}
          checkData={checkData}
          handleCheckData={handleCheckData}
          setDataSelected={setDataSelected}
          handleOpenModalDelete={handleOpen}
        />
      ) : (
        <LoadingList isTableOrLess={isTableOrLess} />
      )}
      <DeleteModal
        title="Deseja mesmo excluir o dado blacklist?"
        loading={loading}
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        handleDelete={handleDelete}
        handleClose={handleClose}
      />
    </Container>
  );
}

export default BlacklistDataList;
