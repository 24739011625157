import { OfferMarketing } from 'models/offersMarketing';
import api from 'services/api';
import useSWR from 'swr';

export const OffersMarketingFetch = () => {
  const { data, mutate } = useSWR<OfferMarketing[]>(
    'offers',
    async (): Promise<OfferMarketing[]> => {
      const { data: offers } = await api.get('/core/ofertas');

      return offers;
    },
  );
  return { data, mutate };
};
