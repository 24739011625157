import {
  Box,
  Button,
  CardContent,
  CardHeader,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import * as Yup from 'yup';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useToast } from 'hooks/toast';
import { ButtonProgress } from 'components/StyledComponents';
import { RolesFetch } from 'fetches/rolesFetch';
import useBreakpoint from 'hooks/useBreakpoint';
import api from 'services/api';
import { mutate } from 'swr';
import { Erros } from '../FormEditUser/types';
import { Container } from './styles';

interface FormAddUserMobile {
  showNewUserMobile: boolean;
  setShowNewUserMobile(value: boolean): void;
  currentPage: number;
}

const EmailSchema = Yup.object().shape({
  email: Yup.string().required('Campo obrigatório').email('Email inválido'),
});

const defaultErros = {
  email: '',
};
function FormAddUserMobile({
  showNewUserMobile,
  setShowNewUserMobile,
  currentPage,
}: FormAddUserMobile) {
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  const { addToast } = useToast();
  const [allFilled, setAllFilled] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [funcao, setFuncao] = useState('');
  const [loading, setLoading] = useState(false);
  const [erros, setErros] = useState<Erros>(defaultErros);
  const { data: funcoes } = RolesFetch();

  useEffect(() => {
    setAllFilled(name !== '' && email !== '' && funcao !== '');
  }, [name, email, funcao]);

  const clearForm = useCallback(() => {
    setName('');
    setEmail('');
    setFuncao('');
  }, []);

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    const data = { nome: name, email, funcao_id: funcao };
    setErros(defaultErros);
    EmailSchema.validate(
      { email },
      {
        abortEarly: false,
      },
    )
      .then(async () => {
        setLoading(true);
        api
          .post('/core/usuarios', { user_form: data })
          .then(() => {
            mutate(`users_${currentPage}`);

            api.post('/core/senha/enviar-email', { email });
            addToast({
              type: 'success',
              title: 'Usuário Inserido',
              description:
                'Usuário criado e email de troca de senha enviado com sucesso!',
            });
            setShowNewUserMobile(false);
            clearForm();
          })
          .catch(error => {
            addToast({
              type: 'error',
              title: 'Erro na autenticação',
              description: error?.data?.message,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro na Criação de Usuário.',
          description: err.message,
        });
      });
  };

  return (
    <Container>
      <Dialog
        open={showNewUserMobile}
        onClose={() => setShowNewUserMobile(false)}
        aria-labelledby="form-dialog-title"
        fullScreen={isTabletSizeOrSmaller}
      >
        <form onSubmit={handleSubmit}>
          <Box padding="30px">
            <CardHeader
              title="Cadastrar novo usuário"
              style={{ padding: 10 }}
            />
            <CardContent>
              <TextField
                id={name}
                value={name}
                aria-readonly
                fullWidth
                label="Nome"
                margin="dense"
                name="name"
                required
                variant="outlined"
                onChange={e => setName(e.target.value)}
              />
              <TextField
                id={email}
                value={email}
                inputProps={{
                  form: {
                    autocomplete: 'off',
                  },
                }}
                aria-readonly
                fullWidth
                label="Email"
                margin="dense"
                name="email"
                required
                variant="outlined"
                error={!email && !!erros.email}
                helperText={!email && erros.email}
                onFocus={() => setErros(defaultErros)}
                onChange={e => setEmail(e.target.value)}
              />
              <FormControl variant="outlined" margin="dense" fullWidth>
                <InputLabel>Função</InputLabel>
                <Select
                  value={funcao}
                  required
                  onChange={e => {
                    setFuncao(e.target.value as string);
                  }}
                  label="Função"
                >
                  {funcoes?.map(item => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.nome}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </CardContent>
            <DialogActions
              style={{
                justifyContent: 'space-between',
                paddingRight: '24px',
                paddingLeft: '16px',
              }}
            >
              <Button
                style={{ color: '#C92A2A' }}
                onClick={() => {
                  clearForm();
                  setShowNewUserMobile(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!allFilled || loading}
              >
                Salvar
                {loading && <ButtonProgress size={24} />}
              </Button>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </Container>
  );
}

export default FormAddUserMobile;
