import styled from 'styled-components';

export const DataText = styled.span`
  min-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  display: flex;
  align-items: center;

  @media (max-width: 920px) {
    min-width: 84px;
  }

  @media (max-width: 530px) {
    min-width: 60px;
  }

  @media (max-width: 404px) {
    min-width: 52px;
  }
`;
