import { IconButton, Skeleton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Action } from 'components/MobileList';
import {
  MobileContainer,
  MobileData,
  MobileDataLabel,
  MobileDataText,
} from 'components/StyledComponents';
import { cell as cellFormatter, iccid as iccidFormatter } from 'util/formatter';
import React, { useCallback, useState } from 'react';
import copyToClipboard from 'util/copyToClipboard';
import copyIcon from 'assets/icons/small/copy.svg';
import { format, parseISO } from 'date-fns';
import PopperActions from '../PopperActions';
import { MobileDataTextName, Row } from './styles';
import { ItemProps } from '../Item';

function MobileItem({
  data,
  loading = false,
  setSaleUpdateToSent,
  updateSimCards,
}: ItemProps) {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElement(anchorElement ? null : event.currentTarget);
    },
    [anchorElement],
  );
  const open = Boolean(anchorElement);
  const id = open ? `popperAction_${data?.simcard_id}` : undefined;

  return (
    <MobileContainer>
      <Action>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        {data && updateSimCards && (
          <PopperActions
            id={id || ''}
            open={open}
            data={data}
            anchorElement={anchorElement}
            setSaleUpdateToSent={setSaleUpdateToSent}
            updateSimCards={updateSimCards}
          />
        )}
      </Action>
      <MobileData>
        <MobileDataLabel>Nome do Cliente</MobileDataLabel>
        <MobileDataText>
          {loading ? <Skeleton width={180} /> : data?.nome}
        </MobileDataText>
      </MobileData>
      <MobileData>
        <MobileDataLabel>ICCID</MobileDataLabel>
        <MobileDataText>
          {loading ? <Skeleton width={180} /> : iccidFormatter(data?.iccid)}{' '}
          {data && (
            <IconButton
              style={{ padding: 5 }}
              onClick={e => {
                e.stopPropagation();
                copyToClipboard(data.iccid);
              }}
              color="inherit"
            >
              <img src={copyIcon} alt="copiar" />
            </IconButton>
          )}
        </MobileDataText>
      </MobileData>
      <Row>
        <MobileData>
          <MobileDataLabel>MSISDN</MobileDataLabel>
          <MobileDataTextName>
            {loading ? <Skeleton width={140} /> : cellFormatter(data?.msisdn)}
          </MobileDataTextName>
        </MobileData>
      </Row>
      <MobileData>
        <MobileDataLabel>Data da aprovação</MobileDataLabel>
        <MobileDataText>
          {loading ? (
            <Skeleton width={140} />
          ) : (
            data && format(parseISO(data.data), 'dd/MM/yyyy - HH:mm')
          )}
        </MobileDataText>
      </MobileData>
    </MobileContainer>
  );
}

export default MobileItem;
