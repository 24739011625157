import styled from 'styled-components';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

export const Container = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    width: 550px;
  }
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 0.5,
    },
    chip: {
      margin: 2,
    },
  }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const LineControl = styled.div`
  display: flex;
  vertical-align: top;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;

  .MuiOutlinedInput-adornedEnd {
    padding-right: 1px;
  }

  .MuiFormControl-root {
    width: 48%;
    margin-bottom: 24px;
  }

  .MuiButton-containedSizeLarge {
    width: 100%;
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 16px;
    }
    .MuiButton-containedSizeLarge {
      width: 100%;
    }
  }
`;
