import styled, { css } from 'styled-components';

interface ButtonFilterProps {
  actived: boolean;
}

export const ButtonFilter = styled.button.attrs({
  type: 'button', // eslint-disable-next-line
})<ButtonFilterProps>`
  border: none;
  background-color: transparent;
  padding: 8px 16px;
  font-size: 12px;
  line-height: 17px;
  width: 80px;
  text-align: center;
  color: #050c27;
  opacity: 0.7;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #d0edfb;
  }

  ${props =>
    props.actived &&
    css`
      background-color: #cccccc;
      color: #050c27;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;

      &:hover {
        background-color: #d0edfb;
      }
    `}
`;
