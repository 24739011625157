import { useState } from 'react';
import copyIcon from 'assets/icons/small/copy.svg';

import copyToClipboard from 'util/copyToClipboard';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip, Skeleton, IconButton } from '@mui/material';
import { Container, Label } from 'components/TextInfo';
import {
  ContextInfo,
  getContextBadgeSetup,
} from 'pages/BugReport/BugReportList/contextBadgeSetup';
import { Badge } from 'pages/BugReport/BugReportList/Item/style';
import { Info } from './styles';

interface TextInfoProps {
  label: string;
  info?: string;
  context?: string;
  screenshot?: string;
  loading?: boolean;
  canCopy?: boolean;
  onlyNumbers?: boolean;
  row?: boolean;
}

function TextInfo({
  label,
  info,
  context,
  screenshot,
  loading,
  canCopy,
  onlyNumbers = false,
  row,
}: TextInfoProps) {
  const id = `item_${uuidv4()}`;
  const [showToolTip, setShowToolTip] = useState(false);
  const contextBadge = context
    ? getContextBadgeSetup(context)
    : ({} as ContextInfo);
  return (
    <Container id={id} row={row}>
      {loading ? (
        <>
          <Skeleton animation="wave" height={20} width={30} />
          <Skeleton animation="wave" height={30} width={90} />
        </>
      ) : (
        <>
          <Label>{label}</Label>
          <Info>
            {info}
            {context && (
              <Badge badgeColor={contextBadge.color}>
                {contextBadge.label}
              </Badge>
            )}
            {screenshot && (
              <Tooltip
                leaveDelay={900}
                title="copiado"
                placement="top-end"
                open={showToolTip}
                onClose={() => {
                  setTimeout(() => {
                    setShowToolTip(false);
                  }, 1000);
                }}
              >
                <IconButton
                  onClick={() => {
                    const copied = copyToClipboard(screenshot, id);
                    setShowToolTip(copied);
                  }}
                >
                  <img src={copyIcon} width={15} alt="copiar valor" />
                </IconButton>
              </Tooltip>
            )}
            {canCopy && (
              <Tooltip
                title="copiado"
                placement="top-end"
                open={showToolTip}
                onClose={() => {
                  setTimeout(() => {
                    setShowToolTip(false);
                  }, 1000);
                }}
              >
                <button
                  type="button"
                  className="buttonCopyInfo"
                  onClick={() => {
                    const str = onlyNumbers
                      ? info && info.replace(/\D/g, '')
                      : info;
                    const copied = copyToClipboard(str as string, id);
                    setShowToolTip(copied);
                  }}
                >
                  <img src={copyIcon} alt="copiar valor" />
                </button>
              </Tooltip>
            )}
          </Info>
        </>
      )}
    </Container>
  );
}

export default TextInfo;
