import { Box, Description, Value } from './styles';

interface TotalizerCardProps {
  title: string;
  total?: number | string;
  isDesktop: boolean;
}

function TotalizerCard({ title, total, isDesktop }: TotalizerCardProps) {
  return (
    <Box isDesktop={isDesktop}>
      <Description>{title}</Description>
      <Value>{total || '0'}</Value>
    </Box>
  );
}

export default TotalizerCard;
