import React from 'react';
import { Card, Header } from 'components/StyledComponents';
import { Button, CardContent, CardHeader } from '@mui/material';
import { Add } from '@mui/icons-material';
import { RolesFetch } from 'fetches/rolesFetch';
import { useNavigate } from 'react-router-dom';
import useBreakpoint from 'hooks/useBreakpoint';
import palette from 'theme/palette';

import RoleList from './RoleList';

function ManagerRole() {
  const navigate = useNavigate();
  const { data: roles } = RolesFetch();
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Card>
      <Header>
        <CardHeader
          title="Cadastro de Funções"
          subheader="Painel de cadastro de funções, acessos e permissões"
        />

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate('/gestao-funcao/cadastro-funcao');
          }}
        >
          {isTableOrLess ? (
            <Add style={{ color: palette.white }} />
          ) : (
            'Cadastrar nova função'
          )}
        </Button>
      </Header>

      <CardContent>
        <RoleList roles={roles} />
      </CardContent>
    </Card>
  );
}

export default ManagerRole;
