import React from 'react';
import { Button, Card, Grid, TextField } from '@mui/material';
import { GetApp } from '@mui/icons-material';
import { ButtonProgress } from 'components/StyledComponents';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Container } from './styles';

interface FilterProps {
  start: Date;
  end: Date;
  loading: boolean;
  setStart(newDate: Date): void;
  setEnd(newDate: Date): void;
}

function Filter({ start, end, setStart, setEnd, loading }: FilterProps) {
  const handleStartChange = (date: Date | string | null) => {
    if (typeof date === 'string') {
      setStart(new Date(date));
      return;
    }
    date && setStart(date);
  };
  const handleEndChange = (date: Date | string | null) => {
    if (typeof date === 'string') {
      setEnd(new Date(date));
      return;
    }
    date && setEnd(date);
  };
  return (
    <Card
      style={{
        padding: 20,
      }}
    >
      <Container>
        <Grid container className="Grid">
          <Grid item lg={3} md={3} xs={12} className="Grid1">
            <DesktopDatePicker
              label="Início"
              inputFormat="dd/MM/yyyy"
              InputAdornmentProps={{ position: 'end' }}
              renderInput={params => (
                <TextField {...params} fullWidth style={{ marginBottom: 16 }} />
              )}
              value={start}
              onChange={date => handleStartChange(date)}
            />
          </Grid>
          <Grid item lg={3} md={3} xs={12}>
            <DesktopDatePicker
              label="Fim"
              minDate={start}
              inputFormat="dd/MM/yyyy"
              InputAdornmentProps={{ position: 'end' }}
              renderInput={params => <TextField {...params} fullWidth />}
              value={end}
              onChange={date => handleEndChange(date)}
            />
          </Grid>
        </Grid>

        <Button
          className="Button"
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<GetApp />}
          disabled={loading}
        >
          <span>Extrair</span>
          {loading && <ButtonProgress size={24} />}
        </Button>
      </Container>
    </Card>
  );
}

export default Filter;
