import { List } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';

import { Container } from 'components/CardList';
import Item from './Item';
import MobileItem from './MobileItem';
import { Header } from './styles';

interface SaleListProps {
  sales?: {
    id: number;
    segmento: string;
    status: string;
    data_portabilidade: string;
    cpf: string;
    nome: string;
    msisdn: string;
    provisorio: string;
    data_ativacao: string;
  }[];
  componente: string;
}

interface BodyProps {
  sales: {
    id: number;
    segmento: string;
    status: string;
    data_portabilidade: string;
    cpf: string;
    nome: string;
    msisdn: string;
    provisorio: string;
    data_ativacao: string;
  }[];
  isMobile: boolean;
  componente: string;
}

interface LoadingListProps {
  isTabletSizeOrSmaller: boolean;
}

function LoadingList({ isTabletSizeOrSmaller }: LoadingListProps) {
  return isTabletSizeOrSmaller ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({ sales, isMobile, componente }: BodyProps) {
  return (
    <List>
      {sales.length > 0 ? (
        sales.map(data =>
          isMobile ? (
            <MobileItem key={data.id} data={data} />
          ) : (
            <Item key={data.id} data={data} />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>
          Nenhuma portabilidade em {componente} encontrada
        </h3>
      )}
    </List>
  );
}

function SaleList({ sales, componente }: SaleListProps) {
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  return (
    <Container>
      {!isTabletSizeOrSmaller && (
        <Header>
          <span>Nome</span>
          <span>CPF</span>
          <span>Número Portado</span>
          <span>Data de Agendamento</span>
          <span>Data de Ativação</span>
        </Header>
      )}

      {sales ? (
        <Body
          sales={sales}
          isMobile={isTabletSizeOrSmaller}
          componente={componente}
        />
      ) : (
        <LoadingList isTabletSizeOrSmaller={isTabletSizeOrSmaller} />
      )}
    </Container>
  );
}

export default SaleList;
