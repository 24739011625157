import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
} from '@mui/material';
import { GetApp, Search } from '@mui/icons-material';
import { ButtonProgress, Form } from 'components/StyledComponents';
import { ButtonFilter, Container } from './styles';
import PopoverActions from './PopoverActions';
import { useFilters } from './hooks/useFilters';
import SearchTransactionsActions from './SearchTransactionsActions';

interface FiltersProps {
  setSearchID(searchID: string): void;
}

function Filters({ setSearchID }: FiltersProps) {
  const {
    idPopover,
    openPopover,
    openPopoverSearchTransactions,
    idPopoverSearchTransactions,
    anchorElement,
    anchorElementSearchTransactions,
    ordemFetch,
    setOrdemFetch,
    loading,
    localSearchID,
    showButtonExtractReport,
    isTableOrLess,
    today,
    yesterday,
    lastWeek,
    month,
    setLocalSearchID,
    onFilter,
    handleToday,
    handleYesterday,
    handleLastWeek,
    handleMonth,
    handleClick,
    handleClose,
    handleSubmit,
    handleClickSearchTransactions,
    handleCloseSearchTransactions,
    onFilterSearchFields,
  } = useFilters();

  return (
    <Container>
      <Grid
        container
        spacing={1.5}
        alignContent="center"
        alignItems="center"
        justifyContent="flex-end"
        textAlign="center"
      >
        <Grid item lg={3.6} md={4}>
          <Grid
            container
            justifyContent={isTableOrLess ? 'space-between' : 'flex-end'}
            spacing={0}
          >
            <Grid item>
              <ButtonFilter onClick={() => handleToday(!today)} actived={today}>
                Hoje
              </ButtonFilter>
            </Grid>
            <Grid item>
              <ButtonFilter
                onClick={() => handleYesterday(!yesterday)}
                actived={yesterday}
              >
                Ontem
              </ButtonFilter>
            </Grid>
            <Grid item>
              <ButtonFilter
                onClick={() => handleLastWeek(!lastWeek)}
                actived={lastWeek}
              >
                7 dias
              </ButtonFilter>
            </Grid>
            <Grid item>
              <ButtonFilter onClick={() => handleMonth(!month)} actived={month}>
                30 dias
              </ButtonFilter>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={1.8} md={2.5} xs={12}>
          <FormControl variant="outlined" margin="none" size="small" fullWidth>
            <InputLabel>Buscar ID</InputLabel>
            <OutlinedInput
              label="Buscar ID"
              className="buttonSearch"
              value={localSearchID}
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  setSearchID(localSearchID);
                }
              }}
              onChange={e => {
                if (e.target.value === '') {
                  setSearchID('');
                }
                setLocalSearchID(e.target.value as string);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setSearchID(localSearchID);
                    }}
                    edge="end"
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item lg={2} md={2.5} xs={12}>
          <FormControl variant="outlined" margin="none" size="small" fullWidth>
            <InputLabel htmlFor="ordem" focused>
              Ordem
            </InputLabel>
            <Select
              native
              value={ordemFetch}
              onChange={e => setOrdemFetch(e.target.value as string)}
              label="Ordem"
            >
              <option value="ordenar_data_criacao">Data da Compra</option>
              <option value="ordenar_data_ativacao">Data Ativação</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={1.6} md={2.5} xs={12}>
          <Button
            aria-describedby={idPopover}
            color="primary"
            onClick={handleClickSearchTransactions}
            fullWidth
            style={{ color: '#1976D2' }}
          >
            Buscar Transações
          </Button>
          <SearchTransactionsActions
            id={idPopoverSearchTransactions || ''}
            openPopover={openPopoverSearchTransactions}
            anchorEl={anchorElementSearchTransactions}
            handleClick={handleCloseSearchTransactions}
            onFilterSearchFields={onFilterSearchFields}
            onClose={handleCloseSearchTransactions}
          />
        </Grid>
        <Grid item lg={1.6} md={2.5} xs={12}>
          <Button
            aria-describedby={idPopover}
            color="primary"
            style={{ color: '#050C27' }}
            onClick={handleClick}
            fullWidth
          >
            Filtros Avançados
          </Button>
          <PopoverActions
            id={idPopover || ''}
            openPopover={openPopover}
            anchorEl={anchorElement}
            handleClick={handleClose}
            onFilter={onFilter}
            onClose={handleClose}
          />
        </Grid>
        <Grid item lg={1.2} md={2.5} xs={12}>
          {showButtonExtractReport && (
            <Form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                style={{ color: '#050C27', minWidth: 93 }}
                startIcon={<GetApp />}
                disabled={loading === true}
                fullWidth
              >
                Exportar
                {loading && <ButtonProgress size={24} />}
              </Button>
            </Form>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default Filters;
