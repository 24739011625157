import { IconButton, Skeleton, Typography } from '@mui/material';
import { IntegrationError } from 'models/integrationErrors';
import { TbEdit } from 'react-icons/tb';
import { date as dateFormatter, hour as hourFormatter } from 'util/formatter';
import { Card, Container, DataInfo, RowCard } from './styles';

interface MobileBodyProps {
  integrationErrors?: IntegrationError[];
  setOpenDrawer(openDrawer: boolean): void;
  setIntegrationErrorIdSelected(integrationErrorIdSelected: number): void;
}

function MobileBody({
  integrationErrors,
  setOpenDrawer,
  setIntegrationErrorIdSelected,
}: MobileBodyProps) {
  function loadingList() {
    const skeletons = [];

    for (let i = 0; i < 10; i++) {
      skeletons.push(
        <Card key={i}>
          <RowCard>
            <DataInfo>
              <Typography color="gray" fontSize={14}>
                Criado
              </Typography>
              <Typography>
                <Skeleton sx={{ width: '100%' }} />
              </Typography>
            </DataInfo>
            <DataInfo>
              <Typography color="gray" fontSize={14}>
                Tipo
              </Typography>
              <Typography>
                <Skeleton sx={{ width: '100%' }} />
              </Typography>
            </DataInfo>
            <DataInfo>
              <IconButton>
                <Skeleton sx={{ width: '30px' }} />
              </IconButton>
            </DataInfo>
          </RowCard>
          <DataInfo>
            <Typography color="gray" fontSize={14}>
              Mensagem
            </Typography>
            <Typography>
              <Skeleton sx={{ width: '100%' }} />
            </Typography>
          </DataInfo>
        </Card>,
      );
    }

    return skeletons;
  }

  return (
    <Container>
      {(integrationErrors &&
        ((integrationErrors.length &&
          integrationErrors.map(integrationError => (
            <Card key={integrationError.id}>
              <RowCard>
                <DataInfo>
                  <Typography color="gray" fontSize={14}>
                    Criado
                  </Typography>
                  <Typography>
                    {`${dateFormatter(
                      integrationError.created_at,
                    )} às ${hourFormatter(integrationError.created_at)}`}
                  </Typography>
                </DataInfo>
                <DataInfo>
                  <Typography color="gray" fontSize={14}>
                    Tipo
                  </Typography>
                  <Typography>{integrationError.tipo}</Typography>
                </DataInfo>
                <DataInfo>
                  <IconButton
                    onClick={() => {
                      setOpenDrawer(true);
                      setIntegrationErrorIdSelected(integrationError.id);
                    }}
                  >
                    <TbEdit color="gray" />
                  </IconButton>
                </DataInfo>
              </RowCard>
              <DataInfo>
                <Typography color="gray" fontSize={14}>
                  Mensagem
                </Typography>
                <Typography>{integrationError.mensagem}</Typography>
              </DataInfo>
            </Card>
          ))) || <Typography>Nenhuma mensagem de erro</Typography>)) ||
        loadingList()}
    </Container>
  );
}

export default MobileBody;
