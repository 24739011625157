import { CardHeader, SwipeableDrawer } from '@mui/material';
import { useEffect } from 'react';
import api from 'services/api';
import useBreakpoint from 'hooks/useBreakpoint';
import SimplePagination from 'components/SimplePagination';
import { Card } from 'components/StyledComponents';
import { Content } from 'components/CardList';
import Filters from './Filters';
import { Header, SalesPagination } from './style';
import SaleList from './SaleList';
import InfoSale from './InfoSale';
import { useSale } from './hooks/useSale';

function Sale() {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  const {
    response,
    saleSelected,
    showInfoSale,
    sale,
    currentPage,
    ordem,
    setSale,
    setShowInfoSale,
    setSearchID,
    setCurrentPage,
    closeDrawer,
    updateSelectedSale,
  } = useSale();

  useEffect(() => {
    setShowInfoSale(!!saleSelected);
    if (saleSelected) {
      setSale(undefined);
      api.get(`/core/vendas/${saleSelected}`).then(({ data }) => {
        setSale(data);
      });
    }
  }, [saleSelected, setSale, setShowInfoSale]);

  return (
    <Card>
      <Header isDesktop={!isTableOrLess}>
        <CardHeader
          title="Transações"
          subheader="Lista de todas as transações efetuadas"
          style={{ padding: 20 }}
        />

        <Filters setSearchID={setSearchID} />
      </Header>
      <Content>
        <SaleList sales={response?.sales} ordem={ordem} />
        <SalesPagination>
          {response && (
            <SimplePagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isEmpty={response?.paginationInfo.isEmpty}
            />
          )}
        </SalesPagination>
      </Content>
      <SwipeableDrawer
        anchor="right"
        open={showInfoSale}
        onOpen={() => setShowInfoSale(true)}
        onClose={() => {
          closeDrawer();
        }}
      >
        <InfoSale
          sale={sale}
          setSale={setSale}
          updateSelectedSale={updateSelectedSale}
          closeDrawer={closeDrawer}
        />
      </SwipeableDrawer>
    </Card>
  );
}

export default Sale;
