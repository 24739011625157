import styled from 'styled-components';

export const GroupData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

export const MobileData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MobileTextName = styled.span`
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 500;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.8);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
