import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

export const DivCloseButton = styled.div`
  display: flex;
  justify-content: end;
  button {
    svg {
      color: #b3b3b3;
      width: 30px;
      height: 30px;
    }
  }
`;

export const ContentDrawer = styled.div`
  max-width: 596px;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  gap: 15px;
`;

export const Title = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: rgba(0, 0, 0, 0.4);
`;

export const Text = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 15px;
`;

export const BoxDDDs = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 596px;
  gap: 10px;
  justify-content: start;
  margin-top: 10px;
`;

export const BoxMarkButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const useStyles = makeStyles(() => ({
  boxPrices: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '14px',
  },
  buttonDDD: {
    borderRadius: 6,
    width: 48,
    height: 36,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    boxShadow: 'none',
    fontWeight: 'normal',
    marginBottom: 16,
    opacity: '0.8',
    background: '#ffff',

    '&:hover': {
      backgroundColor: 'rgba(48,174,201,0.2)',
      boxShadow: 'none',
    },
    '&.MuiButton-containedPrimary': {
      border: '1px solid rgba(181, 210, 224, 1)',
      backgroundColor: '#F5FCFF',
      color: '#050C27',
    },
  },
  selectAllButton: {
    width: '116px',
  },
  deselectAllButton: {
    width: '140px',
  },
  saveButton: {
    marginTop: '31px',
  },
}));
