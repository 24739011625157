import { CardContent as MuiCardContent } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 15px;
  width: 100%;
  border-collapse: collapse;
`;

export const CardContent = styled(MuiCardContent)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
