import useBreakpoint from 'hooks/useBreakpoint';
import { SatisfactionSurvey } from 'models/SatisfactionSurvey';
import { List } from 'components/StyledComponents';

import { Container, Header } from './styles';
import Item from '../Item';
import MobileItem from '../MobileItem';

interface LoadingListProps {
  isTableOrLess: boolean;
}

function LoadingList({ isTableOrLess }: LoadingListProps) {
  return isTableOrLess ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

interface BodyProps {
  satisfactionSurveys: SatisfactionSurvey[];
  isMobile: boolean;
}

function Body({ satisfactionSurveys, isMobile }: BodyProps) {
  return (
    <List>
      {satisfactionSurveys.length ? (
        satisfactionSurveys.map(item =>
          isMobile ? (
            <MobileItem key={item.id} data={item} />
          ) : (
            <Item key={item.id} data={item} />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhuma base encontrada</h3>
      )}
    </List>
  );
}

interface SatisfactionSurveyListProps {
  satisfactionSurveys?: SatisfactionSurvey[];
}

function SatisfactionSurveyList({
  satisfactionSurveys,
}: SatisfactionSurveyListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Container>
      {!isTableOrLess && (
        <Header>
          <span>Data</span>
          <span>ID</span>
          <span>Origem</span>
          <span>Comentário </span>
          <span>Avaliação</span>
        </Header>
      )}
      {satisfactionSurveys ? (
        <Body
          satisfactionSurveys={satisfactionSurveys}
          isMobile={isTableOrLess}
        />
      ) : (
        <LoadingList isTableOrLess={isTableOrLess} />
      )}
    </Container>
  );
}

export default SatisfactionSurveyList;
