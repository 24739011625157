import useSWR from 'swr';
import api from 'services/api';
import {
  ConfigurationPackage,
  ConfigurationPackageFetchResponse,
} from 'models/configurationsPackage';

interface Params {
  segmento?: string;
  plano?: string;
  vigente?: boolean;
}

interface FetchProps {
  page: number;
  segment?: string;
  plan?: string;
  current?: boolean;
}

export const ConfigurationPackageFetch = ({
  page,
  segment,
  plan,
  current,
}: FetchProps) => {
  const { data, mutate } = useSWR<ConfigurationPackageFetchResponse>(
    `flashSale_${page}_${segment}_${plan}_${current}`,
    async (): Promise<ConfigurationPackageFetchResponse> => {
      const params: Params = {};

      if (segment !== 'todos') {
        params.segmento = segment;
      }

      if (plan !== 'todos') {
        params.plano = plan;
      }

      if (current) {
        params.vigente = current;
      }

      const response = await api.get(
        `/core/pacote-configuracoes?page=${page}`,
        { params },
      );

      const paginationInfo = {
        currentPage: parseInt(response.headers['x-current-page'], 10),
        lastPage: parseInt(response.headers['x-last-page'], 10),
        perPage: parseInt(response.headers['x-per-page'], 10),
        total: parseInt(response.headers['x-total-count'], 10),
      };

      const configurations = response.data as ConfigurationPackage[];

      return {
        configurations,
        paginationInfo,
      } as ConfigurationPackageFetchResponse;
    },
  );
  return { data, mutate };
};
