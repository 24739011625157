import { FormEvent, useEffect, useState } from 'react';
import {
  Box,
  Card,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import LoopIcon from '@mui/icons-material//Loop';
import { GetApp } from '@mui/icons-material/';
import { useToast } from 'hooks/toast';
import { downloadXlsFile } from 'util/blob';
import { ButtonProgress } from 'components/StyledComponents';
import api from 'services/api';
import { useAuth } from 'hooks/auth';
import { vendaExtrairRelatorio } from 'constants/permissions';
import useBreakpoint from 'hooks/useBreakpoint';
import { TotalizatorByReprocessedFetch } from 'fetches/totalizatorByReprocessedFetch';
import { useReportFilterFlagFetchStore } from 'store/useReportFilterFlagFetchStore';
import useStyles from './styles';

function Reprocessed() {
  const start = useReportFilterFlagFetchStore(state => state.startFetch);
  const end = useReportFilterFlagFetchStore(state => state.endFetch);
  const reprocessed = useReportFilterFlagFetchStore(
    state => state.withReprocessedFetch,
  );
  const inReprocessing = useReportFilterFlagFetchStore(
    state => state.inReprocessingFetch,
  );
  const engagement = useReportFilterFlagFetchStore(
    state => state.withEngagementFetch,
  );

  const { data: response } = TotalizatorByReprocessedFetch(
    start,
    end,
    inReprocessing,
  );

  const [loading, setLoading] = useState(false);
  const [showButtonExtractReport, setShowButtonExtractReport] = useState(false);

  const { hasPermission } = useAuth();
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(960);
  const { addToast } = useToast();

  const classes = useStyles();

  useEffect(() => {
    const shouldShow = hasPermission(vendaExtrairRelatorio);

    setShowButtonExtractReport(shouldShow);
  }, [hasPermission]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const params = {
      inicio: start || null,
      fim: end || null,
      tipo_relatorio: 'REPROCESSADAS',
    };

    setLoading(true);

    api
      .get('/core/relatorios', { responseType: 'blob', params })
      .then(({ data }) => {
        const filename = `relatório-${start}-${end}-REPROCESSADAS`;
        downloadXlsFile({ filename, file: data });
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro o baixar o relatório!',
          description:
            err.data && err.data.message ? err.data.message : err.message,
        });
      })
      .finally(() => setLoading(false));
  };

  let totalReprocessed: number | string = reprocessed
    ? response?.reprocessados ?? 0
    : 0;
  let totalAffiliated: number | string = reprocessed
    ? response?.afiliados ?? 0
    : 0;
  let totalReached: number | string = engagement
    ? response?.engajamento ?? 0
    : 0;
  let total: number | string =
    totalReprocessed + totalAffiliated + totalReached;

  total = total > 0 ? total : '-';
  totalReprocessed = totalReprocessed > 0 ? totalReprocessed : '-';
  totalAffiliated = totalAffiliated > 0 ? totalAffiliated : '-';
  totalReached = totalReached > 0 ? totalReached : '-';

  return (
    <Card className={classes.root}>
      <Grid item>
        <Box className={classes.box}>
          <LoopIcon className={classes.icon} />
        </Box>
      </Grid>
      <Grid item className={classes.content}>
        <Box>
          <Typography className={classes.title} variant="body2">
            Total
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle1">
            {isTableOrLess ? 'Reprocessadas' : 'Reprocessadas - t (c/m/e)'}
          </Typography>
        </Box>
        <Typography className={classes.h3} variant="h3">
          {!response ? (
            <Skeleton variant="rectangular" width={80} height={30} />
          ) : isTableOrLess ? (
            total
          ) : (
            `${total} (${totalReprocessed}/${totalAffiliated}/${totalReached})`
          )}
        </Typography>
        {isTableOrLess && (
          <Typography
            className={classes.subtitleExtractReport}
            variant="subtitle1"
          >
            Exportar relatório
          </Typography>
        )}
      </Grid>
      {showButtonExtractReport &&
        (!isTableOrLess ? (
          <Box className={classes.boxDownload}>
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <IconButton type="submit" disabled={loading === true}>
                <GetApp className={classes.iconDownload} />
                {loading && <ButtonProgress size={22} />}
              </IconButton>
            </form>
          </Box>
        ) : (
          <Box className={classes.boxDownload}>
            <Typography className={classes.h3Mobile} variant="h3">
              {!response ? (
                <Skeleton variant="rectangular" width={80} height={30} />
              ) : (
                total
              )}
            </Typography>
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <IconButton type="submit" disabled={loading}>
                <GetApp className={classes.iconDownload} />
                {loading && <ButtonProgress size={22} />}
              </IconButton>
            </form>
          </Box>
        ))}
    </Card>
  );
}

export default Reprocessed;
