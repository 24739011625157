import { useState } from 'react';
import { Button, Popper } from '@mui/material';
import { FindInPageOutlined } from '@mui/icons-material';
import { Actions, UseStyles } from 'components/PopperActions';
import { useSaleSelected } from 'store/saleSelected';
import { Sale } from 'models/tractableSales';

interface PopperActionsProps {
  id: string;
  open: boolean;
  data?: Sale;
  anchorElement: null | HTMLElement;
}

function PopperActions({ id, open, data, anchorElement }: PopperActionsProps) {
  const setSalesSelected = useSaleSelected(state => state.setSalesSelected);
  const classes = UseStyles();
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorElement}
      placement="bottom"
      modifiers={[
        {
          name: 'flip',
          enabled: true,
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            boundariesElement: 'scrollParent',
          },
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      className={classes.popper}
    >
      <span className={classes.arrow} ref={setArrowRef} />
      <Actions className={classes.paper}>
        <Button
          onClick={() => data && setSalesSelected(data.id)}
          startIcon={<FindInPageOutlined />}
          fullWidth
        >
          Visualizar pedido
        </Button>
      </Actions>
    </Popper>
  );
}

export default PopperActions;
