import { useSaleSelected } from 'store/saleSelected';
import {
  cell as cellFormatter,
  cpf as cpfFormatter,
  dateTime as dateFormatter,
} from 'util/formatter';
import copyIcon from 'assets/icons/small/copy.svg';

import copyToClipboard from 'util/copyToClipboard';

import {
  MobileContainer,
  MobileData,
  MobileDataLabel,
} from 'components/StyledComponents';
import {
  GroupData,
  MobileDataText,
  MobileTextName,
} from 'components/MobileList';
import { IconButton, Skeleton } from '@mui/material';
import StatusSale from 'components/StatusSale';
import { ItemProps } from '../Item';

function MobileItem({ data, loading = false, ordem }: ItemProps) {
  const setSalesSelected = useSaleSelected(state => state.setSalesSelected);
  return (
    <MobileContainer onClick={() => data && setSalesSelected(data.id)}>
      {loading ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        data && (
          <>
            <StatusSale status={data.status} />
            <MobileData>
              <MobileDataLabel>Nome</MobileDataLabel>
              <MobileTextName>{data.nome}</MobileTextName>
            </MobileData>
            <GroupData>
              <MobileData>
                <MobileDataLabel>CPF</MobileDataLabel>
                <MobileDataText>
                  {cpfFormatter(data.cpf)}
                  <IconButton
                    style={{ padding: 0, paddingLeft: 3 }}
                    onClick={e => {
                      e.stopPropagation();
                      copyToClipboard(data.cpf);
                    }}
                    color="inherit"
                  >
                    <img src={copyIcon} alt="copiar" />
                  </IconButton>
                </MobileDataText>
              </MobileData>
              <MobileData>
                <MobileDataLabel>MSISDN</MobileDataLabel>
                <MobileDataText>
                  {cellFormatter(data.msisdn)}
                  <IconButton
                    style={{ padding: 0, paddingLeft: 3 }}
                    onClick={e => {
                      e.stopPropagation();
                      copyToClipboard(data.msisdn);
                    }}
                    color="inherit"
                  >
                    <img src={copyIcon} alt="copiar" />
                  </IconButton>
                </MobileDataText>
              </MobileData>
            </GroupData>
            <GroupData style={{ alignItems: 'start' }}>
              <MobileData>
                <MobileDataLabel>UF</MobileDataLabel>
                <MobileDataText>{data.uf}</MobileDataText>
              </MobileData>
              <MobileData>
                <MobileDataLabel>Data da Venda</MobileDataLabel>

                {ordem === 'ordenar_data_criacao' ? (
                  <MobileDataText
                    style={{ whiteSpace: 'pre-wrap', wordBreak: 'normal' }}
                  >
                    {dateFormatter(data.created_at)?.split('-')}
                  </MobileDataText>
                ) : (
                  <MobileDataText
                    style={{ whiteSpace: 'pre-wrap', wordBreak: 'normal' }}
                  >
                    {dateFormatter(data.data_ativacao)?.split('-')}
                  </MobileDataText>
                )}
              </MobileData>
            </GroupData>
          </>
        )
      )}
    </MobileContainer>
  );
}

export default MobileItem;
