import create from 'zustand';

type EligibilityStore = {
  eligibilityResponse: string;
  setEligibilityResponse(eligibility: string): void;
};

export const useEligibilityStore = create<EligibilityStore>(set => ({
  eligibilityResponse: '',
  setEligibilityResponse(eligibility: string) {
    set(state => ({ ...state, eligibilityResponse: eligibility }));
  },
}));
