import { Skeleton } from '@mui/material';
import { GroupData } from 'components/MobileList';
import {
  MobileContainer,
  MobileData,
  MobileDataLabel,
  DataText,
  MobileTextName,
} from 'components/StyledComponents';
import { usePackageStore } from 'store/packageStore';
import { ItemProps } from '../Item';
import { Badge } from '../Item/styles';

function MobileItem({
  data,
  loading = false,
  category,
  setPackageSelected,
}: ItemProps) {
  const setShowPackageEdition = usePackageStore(
    state => state.setShowPackageEdition,
  );
  const setCategory = usePackageStore(state => state.setCategory);

  return (
    <MobileContainer
      onClick={() => {
        setShowPackageEdition(true);
        setPackageSelected && data && setPackageSelected(data);
        category && setCategory(category);
      }}
    >
      {loading ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        data && (
          <>
            <MobileData>
              <MobileDataLabel>ID</MobileDataLabel>
              <MobileTextName>{data.tim_id} </MobileTextName>
            </MobileData>
            <MobileData>
              <MobileDataLabel>Nome</MobileDataLabel>
              <MobileTextName>{data.nome}</MobileTextName>
            </MobileData>
            {category !== 'SEM_CLASSIFICACAO' && (
              <GroupData>
                <MobileData>
                  <MobileDataLabel>GB</MobileDataLabel>
                  <DataText>{data.gb}</DataText>
                </MobileData>
                <MobileData>
                  <DataText>
                    {data?.ativo ? (
                      <Badge type="ativo">Ativo</Badge>
                    ) : (
                      <Badge type="inativo">Inativo</Badge>
                    )}
                  </DataText>
                </MobileData>
              </GroupData>
            )}
          </>
        )
      )}
    </MobileContainer>
  );
}

export default MobileItem;
