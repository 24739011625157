import { Button, CardHeader } from '@mui/material';
import { ButtonProgress, Card } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { useParams } from 'react-router-dom';
import InfoAddress from './InfoAddress';
import InfoCustomer from './InfoCustomer';
import InfoPayment from './InfoPayment';
import InfoSale from './InfoSale';
import { ButtonActions } from './styles';
import DialogConfirmEditSale from './DialogConfirmEditSale';
import DialogConfirmIntractableSale from './DialogConfirmIntractableSale';
import { useEditSaleCorrection } from './hooks/useEditSaleCorrection';

function EditSaleCorrection() {
  const params = useParams();
  const { id } = params;

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  const {
    sale,
    fidelidade,
    setFidelidade,
    cpf,
    setCpf,
    email,
    setEmail,
    filiacao,
    setFiliacao,
    msisdn,
    setMsisdn,
    nascimento,
    setNascimento,
    nome,
    setNome,
    cep,
    setCep,
    bairro,
    setBairro,
    cidade,
    setCidade,
    logradouro,
    setLogradouro,
    uf,
    setUf,
    tipoIdentificador,
    setTipoIdentificador,
    numero,
    setNumero,
    quadra,
    setQuadra,
    lote,
    setLote,
    complemento,
    setComplemento,
    formaPagamento,
    setFormaPagamento,
    tipoFatura,
    setTipoFatura,
    vencimento,
    setVencimento,
    banco,
    setBanco,
    agencia,
    setAgencia,
    conta,
    setConta,
    cepIsValid,
    setCepIsValid,
    setFormCustomerIsValid,
    setFormAddressIsValid,
    setFormPaymentIsValid,
    formIsValid,
    erros,
    loading,
    openDialogEditSale,
    setOpenDialogEditSale,
    openDialogIntractableSale,
    setOpenDialogIntractableSale,
    navigate,
    mutate,
    handleSave,
  } = useEditSaleCorrection(id);

  return (
    <Card>
      <CardHeader title="Detalhes da venda" />
      <ButtonActions>
        <div>
          <Button
            variant="outlined"
            onClick={() => {
              navigate('/correcao-vendas/');
            }}
          >
            Voltar
          </Button>
          <Button
            type="button"
            variant="contained"
            fullWidth={isTableOrLess}
            onClick={() => setOpenDialogIntractableSale(true)}
            disabled={!sale}
          >
            Venda intratável
          </Button>
        </div>
        {!isTableOrLess && (
          <Button
            color="primary"
            type="button"
            variant="contained"
            onClick={() => setOpenDialogEditSale(true)}
            disabled={!formIsValid || loading}
          >
            Salvar
            {loading && <ButtonProgress size={24} />}
          </Button>
        )}
      </ButtonActions>

      {sale ? (
        <>
          <InfoSale
            data={sale}
            fidelidade={fidelidade}
            setFidelidade={setFidelidade}
          />
          <InfoCustomer
            cpf={cpf}
            setCpf={setCpf}
            email={email}
            setEmail={setEmail}
            filiacao={filiacao}
            setFiliacao={setFiliacao}
            msisdn={msisdn}
            setMsisdn={setMsisdn}
            nascimento={nascimento}
            setNascimento={setNascimento}
            nome={nome}
            setNome={setNome}
            erros={erros}
            setFormCustomerIsValid={setFormCustomerIsValid}
          />
          <InfoAddress
            cep={cep}
            setCep={setCep}
            bairro={bairro}
            setBairro={setBairro}
            cidade={cidade}
            setCidade={setCidade}
            uf={uf}
            setUf={setUf}
            logradouro={logradouro}
            setLogradouro={setLogradouro}
            tipoIdentificador={tipoIdentificador}
            setTipoIdentificador={setTipoIdentificador}
            numero={numero}
            setNumero={setNumero}
            quadra={quadra}
            setQuadra={setQuadra}
            lote={lote}
            setLote={setLote}
            complemento={complemento}
            setComplemento={setComplemento}
            setFormAddressIsValid={setFormAddressIsValid}
            cepIsValid={cepIsValid}
            setCepIsValid={setCepIsValid}
            erros={erros}
          />
          <InfoPayment
            formaPagamento={formaPagamento}
            setFormaPagamento={setFormaPagamento}
            tipoFatura={tipoFatura}
            setTipoFatura={setTipoFatura}
            vencimento={vencimento}
            setVencimento={setVencimento}
            banco={banco}
            setBanco={setBanco}
            agencia={agencia}
            setAgencia={setAgencia}
            conta={conta}
            setConta={setConta}
            setFormPaymentIsValid={setFormPaymentIsValid}
          />
        </>
      ) : (
        <div style={{ padding: 20 }}>
          <h1>Carregando... </h1>
        </div>
      )}

      <ButtonActions>
        {!isTableOrLess && (
          <div>
            <Button
              variant="outlined"
              onClick={() => {
                navigate('/correcao-vendas/');
              }}
            >
              Voltar
            </Button>
            <Button
              type="button"
              variant="contained"
              onClick={() => setOpenDialogIntractableSale(true)}
              disabled={!sale}
            >
              Venda intratável
            </Button>
          </div>
        )}
        <Button
          color="primary"
          type="button"
          variant="contained"
          fullWidth={isTableOrLess}
          onClick={() => setOpenDialogEditSale(true)}
          disabled={!formIsValid || loading}
        >
          Salvar
          {loading && <ButtonProgress size={24} />}
        </Button>
      </ButtonActions>
      <DialogConfirmEditSale
        open={openDialogEditSale}
        setOpen={setOpenDialogEditSale}
        loading={loading}
        handleSave={handleSave}
      />

      {sale && (
        <DialogConfirmIntractableSale
          saleId={sale.id}
          open={openDialogIntractableSale}
          setOpen={setOpenDialogIntractableSale}
          updateSales={mutate}
        />
      )}
    </Card>
  );
}

export default EditSaleCorrection;
