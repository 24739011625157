import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 5px 15px 0;
  width: 100%;
`;

export const TextInfo = styled.div`
  margin-top: 10px;
  div {
    div {
      height: 40px;
    }
  }
`;
