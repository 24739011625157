import React, { useCallback, useState } from 'react';
import { IconButton, Skeleton } from '@mui/material';

import {
  Container,
  Data,
  DataInfo,
  DataText,
  Divider,
} from 'components/StyledComponents';
import { cpf as cpfFormatter } from 'util/formatter';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { VirtualSeller } from 'models/virtualSeller';
import ufs from '../../../../constants/ufs.json';
import EditForm from './EditForm';
import PopperActions from '../PopperActions';

export interface ItemProps {
  data?: VirtualSeller;
  loading?: boolean;
  updateVirtualSeller?(
    virtualSeller: VirtualSeller,
    shouldRevalid?: boolean,
  ): void;
}

function Item({ data, loading = false, updateVirtualSeller }: ItemProps) {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElement(anchorElement ? null : event.currentTarget);
    },
    [anchorElement],
  );

  const open = Boolean(anchorElement);
  const id = open ? `popperAction_${data?.id}` : undefined;

  return (
    <Container>
      <Data>
        <DataInfo key={data?.id}>
          <DataText>
            {loading ? <Skeleton width={100} /> : cpfFormatter(data?.cpf)}
          </DataText>
          <DataText>
            {loading ? (
              <Skeleton width={100} />
            ) : (
              ufs.find(ufItem => ufItem.value === data?.uf)?.text
            )}
          </DataText>
          <DataText>
            {loading ? <Skeleton width={100} /> : data?.custcode}
          </DataText>
        </DataInfo>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <PopperActions
          id={id || ''}
          open={open}
          anchorElement={anchorElement}
          handleClick={handleClick}
          setShowEditFormMobile={setShowEditForm}
        />
        {data && (
          <EditForm
            showEditForm={showEditForm}
            setShowEditForm={setShowEditForm}
            virtualSeller={data}
            updateVirtualSeller={updateVirtualSeller}
          />
        )}
      </Data>
      <Divider />
    </Container>
  );
}

export default Item;
