import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;

  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  height: 100%;
  width: 100%;

  margin-bottom: 20px;
  padding: 10px 10px 0 10px;

  display: flex;
  flex-direction: column;

  position: relative;

  button {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DataLabel = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 2px;
`;

export const DataText = styled.span`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.8);
`;

export const DataTextName = styled.span`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.8);

  @media (max-width: 540px) {
    width: 250px;
  }

  @media (max-width: 350px) {
    width: 200px;
  }
`;
