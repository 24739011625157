import {
  CardHeader,
  FormControlLabel,
  SwipeableDrawer,
  Typography,
} from '@mui/material';

import { Card } from 'components/StyledComponents';
import { SwitchIOS } from 'components/SwitchIOS';
import { PackageWithCategoryFetch } from 'fetches/packageFetch';
import { Package } from 'models/packages';
import { useEffect, useState } from 'react';
import { usePackageStore } from 'store/packageStore';
import EditPackage from './EditPackage';
import PackageList from './PackageList';
import { Header } from './styles';

function ManagerPackage() {
  const [inactive, setInactive] = useState(true);
  const [statusInactive, setStatusInactive] = useState('');

  const { data: packages, mutate } = PackageWithCategoryFetch({
    status: statusInactive,
  });

  const [packageSelected, setPackageSelected] = useState<Package>();
  const showPackageEdition = usePackageStore(state => state.showPackageEdition);
  const setShowPackageEdition = usePackageStore(
    state => state.setShowPackageEdition,
  );

  const handleChangeWithInactive = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setInactive(event.target.checked);
  };

  useEffect(() => {
    if (inactive) {
      setStatusInactive('1');
    }

    if (!inactive) {
      setStatusInactive('');
    }
  }, [inactive]);

  return (
    <Card>
      <Header>
        <CardHeader
          title="Gestão de Pacotes"
          subheader="Painel de classificação de novos pacotes"
        />
        <FormControlLabel
          label={
            <Typography variant="body2">
              {inactive ? 'Mostrar inativos' : 'Ocultar inativos'}
            </Typography>
          }
          control={
            <SwitchIOS
              size="medium"
              color="secondary"
              checked={inactive}
              onChange={handleChangeWithInactive}
            />
          }
          labelPlacement="end"
        />
      </Header>

      <PackageList
        packages={packages}
        setPackageSelected={setPackageSelected}
      />

      <SwipeableDrawer
        anchor="right"
        onClose={() => {
          setPackageSelected(undefined);
          setShowPackageEdition(false);
        }}
        onOpen={() => setShowPackageEdition(true)}
        open={showPackageEdition}
      >
        {packageSelected && (
          <EditPackage
            data={packageSelected}
            closeDrawer={() => {
              setPackageSelected(undefined);
              setShowPackageEdition(false);
              mutate();
            }}
          />
        )}
      </SwipeableDrawer>
    </Card>
  );
}

export default ManagerPackage;
