import { useToast } from 'hooks/toast';
import { LPFAQ } from 'models/lpFaqs';
import CMSSlidingDrawer from 'pages/CmsLP/components/CMSSlidingDrawer';
import FormFAQ from 'pages/CmsLP/components/FormFAQ';
import { useCallback, useEffect, useState } from 'react';
import api from 'services/api';

interface EditFAQProps {
  data: LPFAQ;
  closeDrawer(): void;
  updateFAQs(faqs: LPFAQ, showRevalid?: boolean): void;
}

function EditFAQ({ data, closeDrawer, updateFAQs }: EditFAQProps) {
  const [question, setQuestion] = useState(data.pergunta || '');
  const [answer, setAnswer] = useState(data.resposta || '');
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    setIsValid(!!(question && answer));
  }, [answer, question]);

  const handleEdit = useCallback(() => {
    setLoading(true);
    api
      .put(`/core/lp-faqs/${data.id}`, {
        lp_faq_form: {
          pergunta: question,
          resposta: answer,
        },
      })
      .then(({ data: response }) => {
        updateFAQs({ ...data, ...response });
        addToast({
          type: 'success',
          title: 'Pergunta editada',
          description: 'Pergunta editada com sucesso no FAQ.',
        });

        closeDrawer();
      })
      .catch(error => {
        let { message } = error.data;

        if (!message) {
          message = error.data.errors[0]?.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao editar FAQ!',
            description: message,
          });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, answer, closeDrawer, data, question, updateFAQs]);

  return (
    <CMSSlidingDrawer
      subtitle="Defina a pergunta e resposta."
      closeDrawer={closeDrawer}
    >
      <FormFAQ
        id={data.id}
        question={question}
        setQuestion={setQuestion}
        answer={answer}
        setAnswer={setAnswer}
        loading={loading}
        isValid={isValid}
        handleBenefitRequest={handleEdit}
      />
    </CMSSlidingDrawer>
  );
}

export default EditFAQ;
