import React, { useState } from 'react';
import { Card, CardContent, CardHeader, TextField } from '@mui/material';
import DomainTable from './components/DomainTable';

interface IDomain {
  label: string;
  value: string;
}
const domains = [
  { label: 'Vencimentos', value: 'vencimentos' },
  { label: 'Bancos', value: 'bancos' },
  { label: 'Meios de Pagamento', value: 'meios-pagamento' },
  { label: 'Tipo de Fatura', value: 'tipo-fatura' },
  { label: 'Tipo de Aceite', value: 'tipo-aceite' },
  { label: 'Documentos', value: 'documentos' },
  { label: 'Locais', value: 'locais' },
];

function Domain() {
  const [domain, setDomain] = useState<string>('vencimentos');

  return (
    <>
      <CardHeader title="Domínios" subheader="Domínio usado na venda" />
      <CardContent>
        <TextField
          fullWidth
          label="Selecione um domínio"
          margin="dense"
          name="state"
          onChange={e => setDomain(e.target.value as string)}
          required
          select
          // eslint-disable-next-line react/jsx-sort-props
          SelectProps={{ native: true }}
          value={domain}
          variant="outlined"
        >
          {domains.map((option: IDomain) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>

        <Card style={{ marginTop: '15px' }}>
          <DomainTable type={domain} />
        </Card>
        <Card />
      </CardContent>
    </>
  );
}

export default Domain;
