import React, { FormEvent, useState } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';

import api from 'services/api';
import { useToast } from 'hooks/toast';
import { Button, Typography } from '@mui/material';
import AuthTextField from 'components/AuthTextField';
import { AnimationContainer } from 'pages/_layouts/auth/styles';

function ResetPassword() {
  const params = useParams();
  const { token } = params;
  const { addToast } = useToast();
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    setLoading(true);
    api
      .post(`/core/senha/resetar/${token}`, { password })
      .then(() => {
        setPassword('');

        setConfirmPassword('');
        addToast({
          type: 'success',
          title: 'Resete de senha',
          description: 'Sua senha foi atualizada com sucesso!',
        });
        setTimeout(() => {
          navigate('/');
        }, 2500);
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Resete de senha',
          description: error.data.message,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <AnimationContainer>
      <form onSubmit={handleSubmit}>
        <Typography gutterBottom variant="h3">
          Defina uma nova senha!
        </Typography>
        <AuthTextField
          autoComplete="false"
          name="password"
          type="password"
          value={password}
          aria-readonly
          fullWidth
          label="Nova senha"
          margin="normal"
          required
          color="secondary"
          variant="outlined"
          onChange={e => setPassword(e.target.value)}
        />
        <AuthTextField
          autoComplete="false"
          aria-readonly
          fullWidth
          label="Confirmar senha"
          margin="normal"
          required
          color="secondary"
          variant="outlined"
          name="confirm"
          type="password"
          error={!!(confirmPassword && confirmPassword !== password)}
          helperText={
            confirmPassword && confirmPassword !== password
              ? 'Campo confirmar deve ser diferente do campo nova senha.'
              : ''
          }
          onChange={e => setConfirmPassword(e.target.value)}
        />

        <Button
          style={{
            marginTop: 14,
            marginBottom: 14,
            background: '#00C2FF',
            color: '#fff',
            fontWeight: 'bold',
            fontSize: 16,
            padding: '12px 32px',
          }}
          variant="contained"
          fullWidth
          type="submit"
        >
          {loading ? 'Carregando ...' : 'Troca senha'}
        </Button>
        <Link
          style={{
            color: '#fff',
            textDecoration: 'none',
          }}
          to="/"
        >
          Voltar para login
        </Link>
      </form>
    </AnimationContainer>
  );
}

export default ResetPassword;
