import React, { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import useBreakpoint from 'hooks/useBreakpoint';
import { useToast } from 'hooks/toast';
import api from 'services/api';
import { useSimcardStock } from 'store/simcardStock';
import { ButtonProgress } from './styles';

interface DeleteDialogProp {
  deleteAction(): void;
}

function DeleteDialog({ deleteAction }: DeleteDialogProp) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const batchSelected = useSimcardStock(state => state.batchSelected);
  const showDeleteDialog = useSimcardStock(state => state.showDeleteDialog);
  const setShowDeleteDialog = useSimcardStock(
    state => state.setShowDeleteDialog,
  );

  const activate = useCallback(() => {
    setLoading(true);

    api
      .delete(`/core/simcards/lotes/${batchSelected}`)
      .then(() => {
        deleteAction();
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Lote ativado com sucesso',
        });
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Falha ao ativar lote',
          description: error.data.message,
        });
      })
      .finally(() => {
        setLoading(false);
        setShowDeleteDialog(false);
      });
  }, [deleteAction, addToast, batchSelected, setShowDeleteDialog]);

  return (
    <Dialog
      open={showDeleteDialog}
      maxWidth="md"
      style={{ height: isTableOrLess ? '80%' : '60%' }}
    >
      <DialogTitle id="active-batch">Remover lote</DialogTitle>

      <DialogContentText
        id="alert-dialog-description"
        style={{ padding: '16px 24px' }}
      >
        Deseja remover o lote do estoque?
      </DialogContentText>

      <DialogActions
        style={{
          justifyContent: 'space-between',
          padding: '16px 24px',
        }}
      >
        <Button
          variant="text"
          color="primary"
          onClick={() => setShowDeleteDialog(false)}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => activate()}
          style={{ color: 'white', backgroundColor: '#C92A2A' }}
        >
          {loading && <ButtonProgress size={24} />}
          Remover
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;
