import { Button, FormControl, Grid } from '@mui/material';
import { useToast } from 'hooks/toast';
import { Setup } from 'models/setup';
import { useState } from 'react';
import api from 'services/api';
import { SaveButton } from '../SequenceForm/styles';
import { useStyles } from './styles';

interface ValueSelected {
  [key: string]: boolean;
}
interface EnumSequenceFormProps {
  setup: Setup;
  closeDrawer(): void;
}

function EnumSequenceForm({ setup, closeDrawer }: EnumSequenceFormProps) {
  const [valueSelected, setValueSelected] = useState<ValueSelected>(() => {
    return (setup.options as string[]).reduce(
      (acc: ValueSelected, current: string) => {
        acc[current] = !!(setup.value as string[]).find(
          value => value === current,
        );
        return acc;
      },
      {} as ValueSelected,
    );
  });

  const { addToast } = useToast();

  const classes = useStyles();

  const handleValue = (value: string) => {
    const newValue = { ...valueSelected, [value]: !valueSelected[value] };
    setValueSelected(newValue);
  };

  const handleSave = () => {
    api
      .put(`api/setups/${setup.key}`, {
        value: Object.keys(valueSelected)
          .reduce((acc, current) => {
            if (valueSelected[current]) {
              acc.push(current);
            }
            return acc;
          }, [] as string[])
          .toString(),
      })
      .then(() => {
        addToast({
          type: 'success',
          title: setup.title,
          description: 'Setup salvo com sucesso',
        });

        closeDrawer();
      })
      .catch(err => {
        let message = err.data.errors[0]?.message;
        if (!message) {
          message = err.data.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro!',
            description: message,
          });
      });
  };

  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      style={{ paddingBottom: 30 }}
    >
      <Grid container spacing={3}>
        {typeof setup.options !== 'string' &&
          setup.options.map(val => (
            <Grid item md={2} xs={2} key={val}>
              <Button
                variant="contained"
                color={valueSelected[val] ? 'primary' : 'inherit'}
                className={classes.buttonSelect}
                onClick={() => handleValue(val.toString())}
              >
                {val}
              </Button>
            </Grid>
          ))}
      </Grid>

      <SaveButton>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Salvar
        </Button>
      </SaveButton>
    </FormControl>
  );
}

export default EnumSequenceForm;
