import create from 'zustand';

type SearchFieldsFilterStore = {
  msisdn: string;
  setMsisdn(msisdn: string): void;
  provisorio: string;
  setProvisorio(provisorio: string): void;
  iccid: string;
  setIccid(iccid: string): void;
  rastreamento: string;
  setRastreamento(rastreamento: string): void;
  protocolo: string;
  setProtocolo(protocolo: string): void;
  cpf: string;
  setCpf(cpf: string): void;
  nome: string;
  setNome(nome: string): void;
  filiacao: string;
  setFiliacao(filiacao: string): void;
  email: string;
  setEmail(email: string): void;
  rg: string;
  setRg(rg: string): void;
  token: string;
  setToken(token: string): void;
  utmMedium: string;
  setUTMMedium(utmMedium: string): void;
  utmCampaign: string;
  setUTMCampaign(utmCampaign: string): void;
  mensagemErro: string;
  setMensagemErro(mensagemErro: string): void;
};

export const useSearchFieldsFilterStore = create<SearchFieldsFilterStore>(
  set => ({
    msisdn: '',
    setMsisdn(msisdn) {
      set(state => ({ ...state, msisdn }));
    },

    provisorio: '',
    setProvisorio(provisorio) {
      set(state => ({ ...state, provisorio }));
    },

    iccid: '',
    setIccid(iccid) {
      set(state => ({ ...state, iccid }));
    },

    rastreamento: '',
    setRastreamento(rastreamento) {
      set(state => ({ ...state, rastreamento }));
    },

    protocolo: '',
    setProtocolo(protocolo) {
      set(state => ({ ...state, protocolo }));
    },

    cpf: '',
    setCpf(cpf) {
      set(state => ({ ...state, cpf }));
    },

    nome: '',
    setNome(nome) {
      set(state => ({ ...state, nome }));
    },

    filiacao: '',
    setFiliacao(filiacao) {
      set(state => ({ ...state, filiacao }));
    },

    email: '',
    setEmail(email) {
      set(state => ({ ...state, email }));
    },

    rg: '',
    setRg(rg) {
      set(state => ({ ...state, rg }));
    },

    token: '',
    setToken(token) {
      set(state => ({ ...state, token }));
    },

    utmMedium: '',
    setUTMMedium(utmMedium) {
      set(state => ({ ...state, utmMedium }));
    },

    utmCampaign: '',
    setUTMCampaign(utmCampaign) {
      set(state => ({ ...state, utmCampaign }));
    },

    mensagemErro: '',
    setMensagemErro(mensagemErro) {
      set(state => ({ ...state, mensagemErro }));
    },
  }),
);
