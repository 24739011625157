import styled from 'styled-components';

interface DataInfoProps {
  isCurrent?: boolean;
}

export const DataInfo = styled.div<DataInfoProps>`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${props => (props.isCurrent ? '0 10px 0 24px' : '0 25px 0 24px')};
`;

export const WrapperNameInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 243px;
  width: 265px;
  max-width: 303px;

  /* @media (min-width: 1200px) {
    width: 271px;
  } */
`;

export const DataText = styled.span`
  min-width: 80px;
  font-size: 15px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
`;

export const DataTextGB = styled.span`
  min-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  display: flex;
  align-items: center;
`;

export const Badge = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;

  padding: 2px 6px;
  border-radius: 6px;
  color: #30c952;
  background: rgba(48, 201, 82, 0.2);
`;

export const AddBadge = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;

  padding: 2px 6px;
  border-radius: 6 px;
  color: #00c2ff;
  background: rgba(235, 248, 255, 1);
`;
