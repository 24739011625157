import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { ButtonProgress } from 'components/StyledComponents';
import { FormEvent } from 'react';
import { useStyles } from './styles';

interface DialogConfirmEditSaleProps {
  open: boolean;
  setOpen(value: boolean): void;

  loading: boolean;

  handleSave(value: FormEvent): void;
}
function DialogConfirmEditSale({
  open,
  setOpen,
  loading,
  handleSave,
}: DialogConfirmEditSaleProps) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className={classes.root}>
        Deseja mesmo resubmeter o formulário?
      </DialogTitle>

      <DialogActions className={classes.dialogButtons}>
        <Button
          type="button"
          onClick={() => setOpen(false)}
          className={classes.cancelButton}
        >
          Cancelar
        </Button>
        <Button
          type="button"
          variant="contained"
          onClick={(e: FormEvent) => {
            handleSave(e);
          }}
          color="primary"
          disabled={loading}
        >
          Confirmar
          {loading && <ButtonProgress size={24} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogConfirmEditSale;
