import styled from 'styled-components';

export const DataTextName = styled.span`
  min-width: 100px;
  font-size: 15px;
  margin-right: 15px;
`;

export const WrapperNameInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 230px;
  width: 230px;
`;
