import React, { useEffect, useState } from 'react';
import {
  Container,
  Descriptions,
  SubTitle,
  Title,
} from 'pages/Sale/InfoSale/Status/styles';
import {
  Status as StatusSimcardType,
  statusSIMCardConf,
} from './statusSIMCardConf';

interface StatusProps {
  status: string;
}

function StatusSIMCard({ status }: StatusProps) {
  const [statusConf, setStatusConf] = useState<StatusSimcardType>(
    statusSIMCardConf[status],
  );
  useEffect(() => {
    status && setStatusConf(statusSIMCardConf[status]);
  }, [status]);

  return (
    <Container color={statusConf?.color}>
      <img src={statusConf?.icon} alt={statusConf?.title} />
      <Descriptions>
        <Title> {statusConf?.title}</Title>
        <SubTitle> {statusConf?.subTitle}</SubTitle>
      </Descriptions>
    </Container>
  );
}

export default StatusSIMCard;
