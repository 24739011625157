import { FormEvent, useEffect, useState } from 'react';
import {
  Box,
  Card,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';

import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { GetApp } from '@mui/icons-material';
import { downloadXlsFile } from 'util/blob';
import { ButtonProgress } from 'components/StyledComponents';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { useAuth } from 'hooks/auth';
import { vendaExtrairRelatorio } from 'constants/permissions';
import { TotalizatorByNonSalesFetch } from 'fetches/totalizatorByNonSalesFetch';
import useBreakpoint from 'hooks/useBreakpoint';
import { useReportFilterFlagFetchStore } from 'store/useReportFilterFlagFetchStore';
import useStyles from './styles';

function NoSales() {
  const start = useReportFilterFlagFetchStore(state => state.startFetch);
  const end = useReportFilterFlagFetchStore(state => state.endFetch);

  const { data: response } = TotalizatorByNonSalesFetch(start, end);

  const [loading, setLoading] = useState(false);
  const [showButtonExtractReport, setShowButtonExtractReport] = useState(false);

  const { hasPermission } = useAuth();
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(960);
  const { addToast } = useToast();

  const classes = useStyles();

  useEffect(() => {
    const shouldShow = hasPermission(vendaExtrairRelatorio);

    setShowButtonExtractReport(shouldShow);
  }, [hasPermission]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const params = {
      inicio: start || null,
      fim: end || null,
      tipo_relatorio: 'NAO_VENDAS',
    };

    setLoading(true);

    api
      .get('/core/relatorios', { responseType: 'blob', params })
      .then(({ data }) => {
        const filename = `relatório-${start}-${end}-NAO_VENDAS`;
        downloadXlsFile({ filename, file: data });
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro o baixar o relatório!',
          description:
            err.data && err.data.message ? err.data.message : err.message,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <Card className={classes.root}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item>
          <Box className={classes.box}>
            <NotInterestedIcon className={classes.icon} />
          </Box>
        </Grid>
        <Grid item className={classes.content}>
          <Box>
            <Typography className={classes.title} variant="body2">
              Total
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle1">
              Não-vendas
            </Typography>
          </Box>
          <Typography className={classes.h3} variant="h3">
            {!response ? (
              <Skeleton variant="rectangular" width={80} height={30} />
            ) : (
              response.total
            )}
          </Typography>
          {isTableOrLess && (
            <Typography
              className={classes.subtitleExtractReport}
              variant="subtitle1"
            >
              Exportar relatório
            </Typography>
          )}
        </Grid>
        {showButtonExtractReport &&
          (!isTableOrLess ? (
            <Box className={classes.boxDownload}>
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <IconButton type="submit" disabled={loading === true}>
                  <GetApp className={classes.iconDownload} />
                  {loading && <ButtonProgress size={22} />}
                </IconButton>
              </form>
            </Box>
          ) : (
            <Box className={classes.boxDownload}>
              <Typography className={classes.h3Mobile} variant="h3">
                {!response ? (
                  <Skeleton variant="rectangular" width={80} height={30} />
                ) : (
                  response.total
                )}
              </Typography>
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <IconButton type="submit" disabled={loading}>
                  <GetApp className={classes.iconDownload} />
                  {loading && <ButtonProgress size={22} />}
                </IconButton>
              </form>
            </Box>
          ))}
      </Grid>
    </Card>
  );
}

export default NoSales;
