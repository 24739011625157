import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import { MTheme } from '../../models/theme';

export const UseStylesModal = makeStyles((theme: MTheme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid rgba(0,0,0,0.14)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    zIndex: 9000,
  },
}));

export const ModalCard = styled.div`
  border-radius: 5px;
  width: 434px;

  h2 {
    margin-top: 32px;
    margin-left: 32px;
    font-size: 24px;
    color: #050c27;
    margin-bottom: 16px;
  }

  p {
    font-size: 14px;
    margin-left: 32px;
    margin-right: 32px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 24px;
  }
`;
