import { useToast } from 'hooks/toast';
import {
  ButtonProgress,
  CancelButton,
  ContainerForm,
} from 'components/StyledComponents';
import { useEffect, useState } from 'react';
import api from 'services/api';

import { CancellationReasonsFetch } from 'fetches/cancellationReasonsFetch';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';
import { Sale } from '../types';

interface CancelFormProps {
  sale: Sale;
  setSale(sale: Sale): void;
}

function CancelForm({ sale, setSale }: CancelFormProps) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState('');
  const [anotherReason, setAnotherReason] = useState('');
  const [isAbleToCancel, setIsAbleToCancel] = useState(false);

  const { addToast } = useToast();

  const { data: reasons } = CancellationReasonsFetch();

  useEffect(() => {
    const allValuesValid =
      reason !== '' &&
      reason !== 'none' &&
      (reason !== 'outro' || (reason === 'outro' && anotherReason !== ''));
    setIsAbleToCancel(allValuesValid);
  }, [anotherReason, reason]);

  const cancelSale = () => {
    setLoading(true);

    const url = '/core/vendas/atualizar-detalhe';
    const finalReason = reason === 'outro' ? anotherReason : reason;

    return api
      .put(`${url}/${sale?.id}`, {
        status: 'CANCELADO',
        motivo_cancelamento: finalReason,
      })
      .then(() => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Venda cancelada com sucesso!',
        });
        setOpen(false);
      })
      .catch(error => {
        setLoading(false);
        const message = error.data.errors[0]?.message;
        if (!message) {
          const errorMessage = error.data.message;
          addToast({
            type: 'error',
            title: 'Erro no cancelamento',
            description: errorMessage,
          });
        }
      })
      .finally(() => {
        api
          .get(`core/vendas/${sale.id}`)
          .then(({ data }) => setSale({ ...sale, ...data }));
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ContainerForm>
      <CancelButton
        disabled={loading}
        fullWidth
        onClick={() => setOpen(true)}
        style={{ fontWeight: 400 }}
      >
        Cancelar Pedido
        {loading && <ButtonProgress size={24} />}
      </CancelButton>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Cancelamento de Venda</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ao cancelar uma venda, o cliente será informado via e-mail que seu
            pedido não pode ser concluído.
            <b>
              Por favor, informe o motivo pelo qual você está cancelando esta
              venda.
            </b>
            Este motivo será enviado ao cliente.
          </DialogContentText>
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel htmlFor="Motivo" focused>
              Motivo
            </InputLabel>
            <Select
              fullWidth
              label="Selecione"
              margin="dense"
              name="motivo"
              variant="outlined"
              native
              placeholder="Selecione uma opção"
              value={reason}
              onChange={e => setReason(e.target.value as string)}
            >
              <option aria-label="none" value="" />
              {reasons?.map(cancellationReason => (
                <option
                  key={cancellationReason.id}
                  value={cancellationReason.motivo}
                >
                  {cancellationReason.motivo}
                </option>
              ))}
              <option value="outro">Outro</option>
            </Select>
          </FormControl>
          {reason === 'outro' && (
            <TextField
              autoFocus
              margin="dense"
              id="another-reason"
              name="anotherReason"
              value={anotherReason}
              onChange={e => {
                setAnotherReason(e.target.value);
              }}
              label="Outro motivo"
              type="text"
              fullWidth
              variant="outlined"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            Fechar
          </Button>
          <Button
            onClick={cancelSale}
            color="primary"
            disabled={!isAbleToCancel || loading}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </ContainerForm>
  );
}

export default CancelForm;
