import { TotalizatorLeads } from 'models/totalizatorLeads';
import api from 'services/api';
import userSWR from 'swr';

export const TotalizatorLeadsPerBatchFetch = () => {
  const { data, mutate: mutateTotalLeads } = userSWR<TotalizatorLeads>(
    `total_leads_batch`,
    async (): Promise<TotalizatorLeads> => {
      const { data: total } = await api.get(`api/setups/total-leads-lote/`);

      return total as TotalizatorLeads;
    },
  );

  return { data, mutateTotalLeads };
};
