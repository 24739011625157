import React, { useCallback, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import {
  MobileContainer,
  MobileData,
  MobileDataLabel,
  MobileDataText,
} from 'components/StyledComponents';
import { dateTime as dateTimeFormatter } from 'util/formatter';
import { Action } from 'components/MobileList';
import { Role } from 'models/roles';
import PopperActions from '../PopperActions';

interface MobileItemProps {
  data?: Role;
  loading?: boolean;
}

function MobileItem({ data, loading = false }: MobileItemProps) {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElement(anchorElement ? null : event.currentTarget);
    },
    [anchorElement],
  );

  const open = Boolean(anchorElement);
  const id = open ? `popperAction_${data?.id}` : undefined;

  return (
    <MobileContainer>
      <Action>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <PopperActions
          id={id || ''}
          open={open}
          roleId={data?.id || 0}
          roleNome={data?.nome || ''}
          anchorElement={anchorElement}
          handleClick={handleClick}
        />
      </Action>
      <MobileData>
        <MobileDataLabel>Nome</MobileDataLabel>
        <MobileDataText>
          {loading ? <Skeleton width={180} /> : data?.nome}
        </MobileDataText>
      </MobileData>
      <MobileData>
        <MobileDataLabel>Data de inclusão</MobileDataLabel>
        <MobileDataText>
          {loading ? (
            <Skeleton width={180} />
          ) : (
            dateTimeFormatter(data?.created_at)
          )}
        </MobileDataText>
      </MobileData>
    </MobileContainer>
  );
}

export default MobileItem;
