import {
  ButtonProgress,
  Container,
  Data,
  DataInfo,
  Divider,
  MobileContainer,
  MobileData,
  MobileDataLabel,
} from 'components/StyledComponents';
import { Action, MobileDataText, MobileTextName } from 'components/MobileList';
import { EligibleCustomersData } from 'models/baseWithConfigurationById';
import { date as dateFormatter } from 'util/formatter';

import { Checkbox } from '@mui/material';
import { DataText, DataTextName } from './styles';

interface CustomerItemProps {
  customer: EligibleCustomersData;
  isTableOrLess: boolean;
  selected: boolean;
  handleCheckCustomer(customerId: number): void;
  loading: boolean;
}

function CustomerItem({
  customer,
  isTableOrLess,
  selected,
  handleCheckCustomer,
  loading,
}: CustomerItemProps) {
  return isTableOrLess ? (
    <MobileContainer key={customer.id}>
      <Action style={{ padding: 8 }}>
        <Checkbox
          style={{ padding: 0 }}
          inputProps={{ 'aria-label': 'controlled' }}
          checked={selected}
          onChange={() => handleCheckCustomer(customer.id)}
          disabled={loading}
        />
      </Action>
      <MobileData>
        <MobileDataLabel>Nome</MobileDataLabel>
        <MobileTextName>{customer.nome}</MobileTextName>
      </MobileData>
      <MobileData>
        <MobileDataLabel>MSISDN</MobileDataLabel>
        <MobileDataText>{customer.msisdn}</MobileDataText>
      </MobileData>
      <MobileData>
        <MobileDataLabel>Email</MobileDataLabel>
        <MobileDataText>{customer.email}</MobileDataText>
      </MobileData>
      <MobileData>
        <MobileDataLabel>Data Elegibilidade</MobileDataLabel>
        <MobileDataText>
          {dateFormatter(customer.data_elegibilidade)}
        </MobileDataText>
      </MobileData>
    </MobileContainer>
  ) : (
    <Container key={customer.id} style={{ height: 75 }}>
      <Data>
        <DataInfo>
          <DataTextName>{customer.nome}</DataTextName>
          <DataText>{customer.msisdn}</DataText>
          <DataTextName>{customer.email}</DataTextName>
          <DataText>{dateFormatter(customer.data_elegibilidade)}</DataText>
          <Divider />
        </DataInfo>
        {loading && (
          <div style={{ marginBottom: 23 }}>
            <ButtonProgress size={24} />
          </div>
        )}
        <Checkbox
          style={{ padding: 0 }}
          inputProps={{ 'aria-label': 'controlled' }}
          checked={selected}
          onChange={() => handleCheckCustomer(customer.id)}
          disabled={loading}
        />
      </Data>
    </Container>
  );
}

export default CustomerItem;
