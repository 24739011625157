import { Container } from 'components/CardList';
import { List } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { SaleCorrection } from 'models/salesCorrection';
import Item from './Item';
import MobileItem from './MobileItem';
import { Header } from './styles';

interface SalesCorrectionListProps {
  sales?: SaleCorrection[];
  updateSales(): void;
}

interface BodyProps {
  sales: SaleCorrection[];
  isMobile: boolean;
  updateSales(): void;
}

interface LoadingListProps {
  isTableOrLess: boolean;
}
function LoadingList({ isTableOrLess }: LoadingListProps) {
  return isTableOrLess ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({ sales, isMobile, updateSales }: BodyProps) {
  return (
    <List>
      {sales.length ? (
        sales.map(data =>
          isMobile ? (
            <MobileItem key={data.id} data={data} updateSales={updateSales} />
          ) : (
            <Item key={data.id} data={data} updateSales={updateSales} />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhuma venda para corrigir</h3>
      )}
    </List>
  );
}

function SalesCorrectionList({ sales, updateSales }: SalesCorrectionListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Container>
      {!isTableOrLess && (
        <Header>
          <span>Hora</span>
          <span>Data</span>
          <span>Tipo</span>
          <span>Erro</span>
          <span />
        </Header>
      )}
      {sales ? (
        <Body
          sales={sales}
          isMobile={isTableOrLess}
          updateSales={updateSales}
        />
      ) : (
        <LoadingList isTableOrLess={isTableOrLess} />
      )}
    </Container>
  );
}

export default SalesCorrectionList;
