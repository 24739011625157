import * as yup from 'yup';
import {
  validateTel,
  cpf as validateCpf,
  validateZipCode,
  validateDate,
} from 'util/validator';

export interface Erros {
  cpf: string;
  email: string;
  msisdn: string;
  nascimento: string;
  cep: string;
  tipoIdentificador: string;
  numero: string;
  quadra: string;
  lote: string;
  complemento: string;
  [x: string]: string;
}

yup.addMethod(
  yup.string,
  'date',
  function method(message: string, [minDate, maxDate]: number[] = [18, 120]) {
    return this.test('date', message || 'Data inválida', function test(value) {
      const existingValue = value || '';
      return validateDate.call(this, existingValue, [minDate, maxDate]);
    });
  },
);

export const infoSchema = yup.object().shape({
  cpf: yup
    .string()
    .required('Campo obrigatório')
    .test('cpf', 'CPF inválido', value => {
      return validateCpf(value || '');
    }),
  email: yup.string().required('Campo obrigatório').email('Email inválido'),
  msisdn: yup
    .string()
    .required('Campo obrigatório')
    .test('tel', 'Telefone inválido', value => {
      return validateTel(value || '');
    }),
  nascimento: yup
    .string()
    .date()
    .required('A data de nascimento é obrigatória'),
  cep: yup
    .string()
    .required('Campo obrigatório')
    .test('cep', 'CEP inválido', value => {
      return validateZipCode(value || '');
    }),
  tipoIdentificador: yup
    .string()
    .required('O Tipo de identificador é obrigatório'),
  numero: yup.string().when('tipoIdentificador', {
    is: (tipoIdentificador: string) => tipoIdentificador === 'NUMERO',
    then: yup
      .string()
      .required('O campo é obrigatório')
      .matches(/^[0-9]+$/gi, 'Somente números')
      .max(5, 'Máximo 5 caracteres'),
  }),
  quadra: yup.string().when('tipoIdentificador', {
    is: (tipoIdentificador: string) => tipoIdentificador === 'QUADRA_LOTE',
    then: yup
      .string()
      .required('O campo é obrigatório')
      .matches(/^[0-9]+$/gi, 'Somente números')
      .max(5, 'Máximo 5 caracteres'),
  }),
  lote: yup.string().when('tipoIdentificador', {
    is: (tipoIdentificador: string) => tipoIdentificador === 'QUADRA_LOTE',
    then: yup
      .string()
      .required('O campo é obrigatório')
      .matches(/^[0-9]+$/gi, 'Somente números')
      .max(5, 'Máximo 5 caracteres'),
  }),
  complemento: yup
    .string()
    .max(50, 'Máximo 50 caracteres')
    .matches(
      /^([a-zA-Zà-úÀ-Ú0-9/]|-|,|\s)*$/,
      'Somente letras, números e / - ,',
    )
    .when('tipoIdentificador', {
      is: (tipoIdentificador: string) => tipoIdentificador === 'SEM_NUMERO',
      then: yup
        .string()
        .required('O campo é obrigatório quando Sem Número')
        .max(50, 'Máximo 50 caracteres')
        .matches(
          /^([a-zA-Zà-úÀ-Ú0-9/]|-|,|\s)*$/,
          'Somente letras, números e / - ,',
        ),
    }),
});

export const defaultErros = {
  cpf: '',
  email: '',
  msisdn: '',
  nascimento: '',
  cep: '',
  tipoIdentificador: '',
  numero: '',
  quadra: '',
  lote: '',
  complemento: '',
};
