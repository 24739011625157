import { MTheme } from 'models/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: MTheme) => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.primary.gray,
    borderRadius: 6,
    filter: 'box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2)',
    border: '1px solid rgba(55, 71, 79, 0.6)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: '15px',
  },
  box: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    width: 54,
    height: '100%',
    paddingTop: 17,
    paddingLeft: 13,
  },
  icon: {
    color: theme.palette.media,
    fontSize: 25,
  },
  title: {
    fontWeight: 400,
    color: theme.palette.white,
  },
  subtitle: {
    fontWeight: 500,
    color: theme.palette.media,
    marginBottom: 15,
  },
  h3: {
    fontWeight: 700,
    color: theme.palette.white,
  },
}));

export default useStyles;
