import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useToast } from 'hooks/toast';
import { ButtonProgress, ContainerForm } from 'components/StyledComponents';
import api from 'services/api';

import { Sale } from '../types';

interface ActivatedOnSiebelProps {
  sale: Sale;
  setSale(sale: Sale): void;
}
function ActivatedOnSiebel({ sale, setSale }: ActivatedOnSiebelProps) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [protocolo, setProtocolo] = useState('');

  const { addToast } = useToast();

  const handleSubmit = () => {
    setLoading(true);
    const url = '/core/vendas/atualizar-detalhe';

    return api
      .put(`${url}/${sale?.id}`, {
        status: 'ATIVADO_SIEBEL',
        protocolo_siebel: protocolo,
      })
      .then(() => {
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Venda marcada como ativada no siebel com Sucesso!',
        });
      })
      .catch(error => {
        error.data &&
          addToast({
            type: 'error',
            title: 'Erro ao marcar como ativada no siebel',
            description: error.data.message,
          });
      })
      .finally(() => {
        api.get(`core/vendas/${sale.id}`).then(({ data }) => {
          setSale({ ...sale, ...data });
          setLoading(false);
          setOpen(false);
        });
      });
  };
  return (
    <ContainerForm>
      <Button
        type="button"
        variant="contained"
        color="primary"
        disabled={loading}
        fullWidth
        size="large"
        onClick={() => setOpen(true)}
      >
        Ativada no Siebel
        {loading && <ButtonProgress size={24} />}
      </Button>

      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Deseja realmente marcar esta venda como ativada no Siebel?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Se possível, informe o protocolo gerado pelo siebel.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="reason"
            name="reason"
            value={protocolo}
            onChange={e => {
              setProtocolo(e.target.value);
            }}
            label="Protocolo"
            type="text"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Fechar
          </Button>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            color="primary"
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </ContainerForm>
  );
}

export default ActivatedOnSiebel;
