import { useNavigate } from 'react-router-dom';
import { Box, Button, CardHeader, TextField } from '@mui/material';
import { ButtonProgress, Card } from 'components/StyledComponents';

interface FormBlacklistData {
  id?: number;
  type: string;
  setType(type: string): void;
  data: string;
  setData(data: string): void;
  loading: boolean;
  handleRequest(): void;
}

function FormBlacklistData({
  id,
  type,
  setType,
  data,
  setData,
  loading,
  handleRequest,
}: FormBlacklistData) {
  const navigate = useNavigate();

  const title = id ? 'Editar dados de blacklist' : 'Inserir dados de blacklist';
  return (
    <Card
      style={{ minHeight: '90vh', display: 'flex', flexDirection: 'column' }}
    >
      <CardHeader
        title={title}
        subheader="Painel para inserção de dados de blacklist no sistema"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 2,
          marginTop: 4,
          gap: 2,
        }}
      >
        <TextField
          label="Tipo"
          variant="outlined"
          select
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{ native: true }}
          size="small"
          onChange={e => {
            setType(e.target.value);
          }}
          value={type}
          sx={{
            width: 260,
          }}
        >
          <option value="" disabled>
            Selecionar
          </option>
          <option value="CPF">CPF</option>
          <option value="EMAIL">E-mail</option>
          <option value="CIDADE">Cidade</option>
          <option value="MSISDN">Msisdn</option>
        </TextField>
        {id ? (
          <TextField
            id="Dados"
            onChange={e => {
              setData(e.target.value);
            }}
            value={data}
            fullWidth
          />
        ) : (
          <TextField
            id="Dados"
            label="Digite os dados"
            onChange={e => {
              setData(e.target.value);
            }}
            value={data}
            multiline
            fullWidth
            rows={4}
            helperText="Utilize vírgula para inserir múltiplos dados"
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          paddingLeft: 2,
          marginTop: 'auto',
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          type="button"
          onClick={() => {
            navigate('/dados-blacklist');
          }}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!type || !data || loading}
          onClick={() => {
            handleRequest();
          }}
        >
          Salvar
          {loading && <ButtonProgress size={24} />}
        </Button>
      </Box>
    </Card>
  );
}

export default FormBlacklistData;
