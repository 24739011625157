import { useEffect, useState } from 'react';
import { Grid, IconButton, Skeleton, SwipeableDrawer } from '@mui/material';

import { Price } from 'models/price';
import { TbEdit } from 'react-icons/tb';
import useBreakpoint from 'hooks/useBreakpoint';
import { usePlanStore } from 'store/planStore';
import { RiDeleteBinLine } from 'react-icons/ri';
import {
  CardContent,
  Data,
  Text,
  TextData,
  TextPreviousPrice,
  Title,
} from './styles';
import EditPrice from '../EditPrice';
import DialogDeletePriceGroup from '../DialogDeletePriceGroup';

interface PriceGrouped {
  [key: string]: { ddds: string[]; preco_anterior?: number };
}

interface OpenEditButtonProps {
  setShowDrawerEditGroupPrice?(showDrawerEditGroupPrice: boolean): void;
}

interface OpenRemoveButtonProps {
  setShowRemoveGroupPrice?(showRemoveGroupPrice: boolean): void;
}

function OpenEditButton({ setShowDrawerEditGroupPrice }: OpenEditButtonProps) {
  return (
    <Grid sx={{ display: 'flex', justifyContent: 'end' }} item md={1} xs={1}>
      <IconButton
        onClick={() =>
          setShowDrawerEditGroupPrice && setShowDrawerEditGroupPrice(true)
        }
      >
        <TbEdit />
      </IconButton>
    </Grid>
  );
}

function OpenRemoveButton({ setShowRemoveGroupPrice }: OpenRemoveButtonProps) {
  return (
    <Grid sx={{ display: 'flex', justifyContent: 'end' }} item md={1} xs={1}>
      <IconButton
        onClick={() => setShowRemoveGroupPrice && setShowRemoveGroupPrice(true)}
      >
        <RiDeleteBinLine color="#C92A2A" />
      </IconButton>
    </Grid>
  );
}
interface PricingTableItemProps {
  loyalty?: 'loyalty';
  prices?: Price[];
  name?: string;
  loading: boolean;
  updatePlanById?(): void;
}

function PriceTableItem({
  loyalty,
  prices,
  name,
  loading,
  updatePlanById,
}: PricingTableItemProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(900);
  const [priceGrouped, setPriceGrouped] = useState<PriceGrouped>();
  const [currentPriceSelected, setCurrentPriceSelected] = useState<number>(0.0);
  const [previousPriceSelected, setPreviousPriceSelected] = useState<
    number | null
  >(null);
  const [dddsSelecteds, setDddsSelecteds] = useState<string[]>([]);
  const [showDrawerEditGroupPrice, setShowDrawerEditGroupPrice] =
    useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const planStore = usePlanStore(state => state.plan);

  useEffect(() => {
    const newPriceGrouped = prices?.reduce((acc, current) => {
      const key = current.preco_atual.toString();
      if (!acc[key]) {
        acc[key] = {
          ddds: [],
          preco_anterior: 0.0,
        };
      }
      acc[key].ddds = [...acc[key].ddds, current.ddd];
      acc[key].preco_anterior = current.preco_anterior;
      acc[key].ddds.sort();
      return acc;
    }, {} as PriceGrouped);

    setPriceGrouped(newPriceGrouped);
  }, [prices]);

  function filterDDDsFromPrice(newCurrentPriceSelected: string) {
    return planStore.precos
      .filter(preco => preco.preco_atual === Number(newCurrentPriceSelected))
      .map(price => price.ddd);
  }

  function openDrawer(
    newCurrentPriceSelected: string,
    newPreviousPriceSelected?: number,
  ) {
    const filteredDDDsSelecteds = filterDDDsFromPrice(
      newCurrentPriceSelected.toString(),
    );
    setDddsSelecteds(filteredDDDsSelecteds);
    setCurrentPriceSelected(Number(newCurrentPriceSelected));
    setPreviousPriceSelected(newPreviousPriceSelected || null);
    setShowDrawerEditGroupPrice(true);
  }

  const handleOpenDeleteDialog = (newCurrentPriceSelected: string) => {
    setCurrentPriceSelected(Number(newCurrentPriceSelected));
    setOpenDialogDelete(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDialogDelete(false);
  };

  return (
    <div>
      {loading ? (
        <Grid container spacing={2}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={2} xs={6}>
                <Text>
                  <Skeleton />
                </Text>
              </Grid>
              <Grid item md={10} xs={6}>
                <Text>
                  <Skeleton />
                </Text>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextData>
                  <Skeleton />
                </TextData>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      ) : (
        priceGrouped &&
        Object.keys(priceGrouped).map(preco_atual => (
          <CardContent key={preco_atual}>
            <Grid container spacing={2}>
              <Grid item md={2} xs={4}>
                <Title>{loyalty ? 'Desconto' : 'Preço atual'}</Title>
                <Text>R$ {preco_atual}</Text>
              </Grid>
              <Grid item md={2} xs={4}>
                <Title>Preço anterior</Title>
                <TextPreviousPrice>
                  {priceGrouped[preco_atual].preco_anterior
                    ? `R$ ${priceGrouped[preco_atual].preco_anterior}`
                    : '-'}
                </TextPreviousPrice>
              </Grid>
              {isTableOrLess && !loyalty && (
                <Grid item xs={4}>
                  <Grid
                    container
                    item
                    spacing={5}
                    justifyContent="end"
                    alignItems="flex-end"
                  >
                    <OpenEditButton
                      setShowDrawerEditGroupPrice={() =>
                        openDrawer(
                          preco_atual,
                          priceGrouped[preco_atual].preco_anterior,
                        )
                      }
                    />
                    <OpenRemoveButton
                      setShowRemoveGroupPrice={() =>
                        handleOpenDeleteDialog(preco_atual)
                      }
                    />
                  </Grid>
                </Grid>
              )}

              <Grid item md={6} xs={6}>
                <Title>Plano</Title>
                <Text>{name}</Text>
              </Grid>
              {!isTableOrLess && !loyalty && (
                <Grid item md={2}>
                  <Grid
                    container
                    item
                    spacing={3}
                    justifyContent="end"
                    alignItems="flex-end"
                    gap={1}
                  >
                    <OpenEditButton
                      setShowDrawerEditGroupPrice={() =>
                        openDrawer(
                          preco_atual,
                          priceGrouped[preco_atual].preco_anterior,
                        )
                      }
                    />
                    <OpenRemoveButton
                      setShowRemoveGroupPrice={() =>
                        handleOpenDeleteDialog(preco_atual)
                      }
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item md={12} xs={12}>
                <Title>DDD’s</Title>
              </Grid>
              {priceGrouped[preco_atual].ddds.map(ddd => (
                <Data
                  key={ddd}
                  onClick={() =>
                    openDrawer(
                      preco_atual,
                      priceGrouped[preco_atual].preco_anterior,
                    )
                  }
                >
                  <TextData>{ddd}</TextData>
                </Data>
              ))}
            </Grid>
          </CardContent>
        ))
      )}
      <SwipeableDrawer
        anchor="right"
        open={showDrawerEditGroupPrice}
        onOpen={() => setShowDrawerEditGroupPrice(true)}
        onClose={() => {
          setShowDrawerEditGroupPrice(false);
        }}
      >
        {planStore && updatePlanById && (
          <EditPrice
            oldCurrentPrice={currentPriceSelected.toString()}
            oldPreviousPrice={previousPriceSelected?.toString() || ''}
            priceDDDsSelecteds={dddsSelecteds}
            setShowDrawerEditGroupPrice={setShowDrawerEditGroupPrice}
            updatePriceGroups={updatePlanById}
          />
        )}
      </SwipeableDrawer>
      {updatePlanById && (
        <DialogDeletePriceGroup
          id={planStore.id}
          oldCurrentPrice={currentPriceSelected.toString()}
          openDialogDeletePriceGroup={openDialogDelete}
          handleClose={handleCloseDeleteDialog}
          updatePriceGroups={updatePlanById}
        />
      )}
    </div>
  );
}

export default PriceTableItem;
