interface SourceProps {
  email: string;
  facebook: string;
  google: string;
  sms: string;
  SMSAbandonoOUCancelados: string;
  sms_plus: string;
  reaproveitamento: string;
  landingpage: string;
  whatsapp: string;
  WhatsApp: string;
  WhatsAppLPTIM: string;
  WhatsAppMETA: string;
  WhatsAppOutros: string;
  organico: string;
  afiliado: string;
  [key: string]: string;
}

const source: SourceProps = {
  email: 'Email',
  facebook: 'Facebook',
  google: 'Google',
  sms: 'SMS',
  SMSAbandonoOUCancelados: 'SMS Abandono/Cancelados',
  sms_plus: 'SMS Plus',
  reaproveitamento: 'Reaproveitamento',
  landingpage: 'Landing Page',
  whatsapp: 'Whatsapp',
  WhatsApp: 'Whatsapp',
  WhatsAppLPTIM: 'WhatsApp LP TIM',
  WhatsAppMETA: 'WhatsApp META',
  WhatsAppOutros: 'WhatsApp Outros',
  organico: 'Organico',
  afiliado: 'Afiliado',
};

export default source;
