import userSWR from 'swr';
import api from 'services/api';
import { BlacklistDataBase } from 'models/blacklistData';

export const BlacklistDataIdFetch = (id: number) => {
  const { data, mutate } = userSWR<BlacklistDataBase>(
    `blacklist_${id}`,
    async (): Promise<BlacklistDataBase> => {
      if (id === 0) {
        return {} as BlacklistDataBase;
      }
      const { data: results } = await api.get(`/core/dados-blacklist/${id}`);

      return results as BlacklistDataBase;
    },
  );

  return { data, mutate };
};
