import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  padding: 20px 0px;
`;

export const Ellipse = styled.div`
  width: 24px;
  background-color: #050c27;
  border-radius: 50%;
  margin-right: 8px;
  text-align: center;

  span {
    font-size: 14px;
    color: white;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const SubTitle = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #050c27;
`;
