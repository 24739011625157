import { Search } from '@mui/icons-material';
import {
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { Card } from 'components/StyledComponents';
import { UFFetch } from 'fetches/ufFetch';
import { useCallback, useEffect, useState } from 'react';
import api from 'services/api';
import { Erros } from '../infoShemaYup';
import { useStyles } from '../styles';
import MyFormControlRadioButton from './MyFormControlRadioButton';

interface UF {
  id: number;
  nome: string;
  sigla: number;
}

interface InfoAddressProps {
  cep: string;
  setCep(value: string): void;

  bairro: string;
  setBairro(value: string): void;

  cidade: string;
  setCidade(value: string): void;

  uf: string;
  setUf(value: string): void;

  logradouro: string;
  setLogradouro(value: string): void;

  tipoIdentificador: string;
  setTipoIdentificador(value: string): void;

  numero: string;
  setNumero(value: string): void;

  quadra: string;
  setQuadra(value: string): void;

  lote: string;
  setLote(value: string): void;

  complemento: string;
  setComplemento(value: string): void;

  setFormAddressIsValid(value: boolean): void;

  cepIsValid: boolean;
  setCepIsValid(value: boolean): void;

  erros: Erros;
}

function InfoAddress({
  cep,
  setCep,
  bairro,
  setBairro,
  cidade,
  setCidade,
  uf,
  setUf,
  logradouro,
  setLogradouro,
  tipoIdentificador,
  setTipoIdentificador,
  numero,
  setNumero,
  quadra,
  setQuadra,
  lote,
  setLote,
  complemento,
  setComplemento,
  setFormAddressIsValid,
  cepIsValid,
  setCepIsValid,
  erros,
}: InfoAddressProps) {
  const { data: ufs } = UFFetch();
  const classes = useStyles();

  const [showComplements, setShowComplements] = useState(!!tipoIdentificador);
  const [showNumber, setShowNumber] = useState(
    tipoIdentificador && tipoIdentificador === 'NUMERO',
  );
  const [showCourtLot, setShowCourtLot] = useState(
    tipoIdentificador && tipoIdentificador === 'QUADRA_LOTE',
  );

  useEffect(() => {
    setFormAddressIsValid(
      !!cep && !!bairro && !!cidade && !!logradouro && !!uf,
    );
  }, [
    bairro,
    cep,
    cidade,
    uf,
    logradouro,
    numero,
    quadra,
    complemento,
    setFormAddressIsValid,
  ]);

  useEffect(() => {
    if (tipoIdentificador === 'SEM_NUMERO') {
      setFormAddressIsValid(!!complemento);
    }
    if (tipoIdentificador === 'NUMERO') {
      setFormAddressIsValid(!!numero);
    }
    if (tipoIdentificador === 'QUADRA_LOTE') {
      setFormAddressIsValid(!!quadra && !!lote);
    }
  }, [
    complemento,
    lote,
    numero,
    quadra,
    setFormAddressIsValid,
    tipoIdentificador,
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTipoIdentificador((event.target as HTMLInputElement).value);
  };

  const handleZipCodeNotFound = useCallback(() => {
    cepIsValid && setUf('');
    setCidade('');
    setLogradouro('');
    setBairro('');
    setComplemento('');
    setTipoIdentificador('NUMERO');
    setNumero('');
    setQuadra('');
    setLote('');
  }, [
    cepIsValid,
    setBairro,
    setCidade,
    setComplemento,
    setLogradouro,
    setLote,
    setNumero,
    setQuadra,
    setTipoIdentificador,
    setUf,
  ]);

  useEffect(() => {
    setShowComplements(!!tipoIdentificador);
    setShowNumber(tipoIdentificador && tipoIdentificador === 'NUMERO');
    setShowCourtLot(tipoIdentificador && tipoIdentificador === 'QUADRA_LOTE');
    setFormAddressIsValid(
      !!cep &&
        !!uf &&
        !!cidade &&
        !!logradouro &&
        !!bairro &&
        !!tipoIdentificador,
    );
  }, [
    bairro,
    cep,
    cidade,
    uf,
    logradouro,
    tipoIdentificador,
    numero,
    quadra,
    lote,
    complemento,
    setFormAddressIsValid,
  ]);

  const handleFindByZipCode = useCallback(() => {
    api
      .get(`/core/enderecos/cep/${cep}`)
      .then(({ data }) => {
        setUf(data.stateOrProvince as string);
        setCidade(
          data.city
            .normalize('NFD')
            .replace(/[^a-zA-Z\s]/g, '')
            .toUpperCase() as string,
        );
        setLogradouro(data.streetName as string);
        setBairro(data.locality as string);
      })
      .catch(error => {
        handleZipCodeNotFound();
        error.data && setCepIsValid(!cepIsValid);
      });
  }, [
    cep,
    cepIsValid,
    handleZipCodeNotFound,
    setBairro,
    setCepIsValid,
    setCidade,
    setLogradouro,
    setUf,
  ]);

  return (
    <Card className={classes.root}>
      <CardHeader title="Endereço" className={classes.card} />
      <Grid container spacing={2} className={classes.grid}>
        <Grid item md={6} xs={12}>
          <FormControl variant="outlined" margin="none" size="small" fullWidth>
            <InputLabel>CEP</InputLabel>
            <OutlinedInput
              fullWidth
              label="CEP"
              value={cep}
              onChange={e => setCep(e.target.value)}
              inputProps={{ maxLength: 8, width: '100%' }}
              onKeyPress={event => {
                if (/[0-9]/.test(event.key)) {
                  return;
                }
                event.preventDefault();
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleFindByZipCode}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              error={!!erros.cep || cep === ''}
            />
            {!cepIsValid && (
              <FormHelperText id="cepIsValid">
                Não encontramos esse CEP em nossa base de consultas.
              </FormHelperText>
            )}
            {!!erros.cep && (
              <FormHelperText error id="cepId-error">
                {erros.cep}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            id={bairro}
            value={bairro}
            onChange={e => setBairro(e.target.value)}
            aria-readonly
            fullWidth
            label="Bairro"
            name="bairro"
            variant="outlined"
            placeholder="Não preenchido"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            error={bairro === ''}
            helperText={bairro === '' ? 'Campo bairro é obrigatório.' : ' '}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            id={cidade}
            value={cidade}
            onChange={e => setCidade(e.target.value)}
            aria-readonly
            fullWidth
            label="Cidade"
            name="cidade"
            variant="outlined"
            placeholder="Não preenchido"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            error={cidade === ''}
            helperText={cidade === '' ? 'Campo cidade é obrigatório.' : ' '}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Estado"
            variant="outlined"
            select
            value={uf}
            SelectProps={{ native: true }}
            onChange={e => setUf(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            fullWidth
            error={uf === ''}
            helperText={uf === '' ? 'Campo uf é obrigatório.' : ' '}
          >
            <option value="" disabled>
              Selecione um estado
            </option>
            {ufs?.map((option: UF) => (
              <option key={option.id} value={option.sigla}>
                {option.nome}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            id={logradouro}
            value={logradouro}
            onChange={e => setLogradouro(e.target.value)}
            aria-readonly
            fullWidth
            label="Logradouro"
            name="logradouro"
            variant="outlined"
            placeholder="Não preenchido"
            size="small"
            className={classes.textFieldTakesUpHalfTheScreen}
            InputLabelProps={{
              shrink: true,
            }}
            error={logradouro === ''}
            helperText={
              logradouro === '' ? 'Campo logradouro é obrigatório.' : ' '
            }
          />
        </Grid>
        <Grid item md={12} xs={6} style={{ paddingTop: 0 }}>
          <FormControl fullWidth className={classes.radioButtonsArea}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className={classes.radioButtons}
              onChange={handleChange}
              value={tipoIdentificador || ''}
            >
              <MyFormControlRadioButton
                value="NUMERO"
                control={<Radio />}
                label="Número"
              />
              <MyFormControlRadioButton
                value="QUADRA_LOTE"
                control={<Radio />}
                label="Quadra/Lote"
              />
              <MyFormControlRadioButton
                value="SEM_NUMERO"
                control={<Radio />}
                label="Sem Número"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {showNumber && (
          <Grid item md={6} xs={12}>
            <TextField
              value={numero}
              aria-readonly
              fullWidth
              label="Número da residência"
              placeholder="Informe o número da residência"
              size="small"
              name="numero"
              onKeyPress={event => {
                if (/[0-9]/.test(event.key)) {
                  return;
                }
                event.preventDefault();
              }}
              onChange={e => setNumero(e.target.value)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              error={!!erros.numero || numero === ''}
              helperText={
                erros.numero
                  ? erros.numero
                  : numero === ''
                  ? 'Campo número é obrigatório.'
                  : ' '
              }
            />
          </Grid>
        )}

        {showCourtLot && (
          <>
            <Grid item md={6} xs={12}>
              <TextField
                value={quadra}
                aria-readonly
                fullWidth
                label="Quadra"
                placeholder="Informe a quadra da residência"
                size="small"
                name="quadra"
                onKeyPress={event => {
                  if (/[0-9]/.test(event.key)) {
                    return;
                  }
                  event.preventDefault();
                }}
                onChange={e => setQuadra(e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!erros.quadra || quadra === ''}
                helperText={
                  erros.quadra
                    ? erros.quadra
                    : quadra === ''
                    ? 'Campo quadra é obrigatório.'
                    : ' '
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                value={lote}
                aria-readonly
                fullWidth
                label="Lote"
                placeholder="Informe o lote da residência"
                size="small"
                name="lote"
                onKeyPress={event => {
                  if (/[0-9]/.test(event.key)) {
                    return;
                  }
                  event.preventDefault();
                }}
                onChange={e => setLote(e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!erros.lote || lote === ''}
                helperText={
                  erros.lote
                    ? erros.lote
                    : lote === ''
                    ? 'Campo lote é obrigatório.'
                    : ' '
                }
              />
            </Grid>
          </>
        )}
        <Grid item md={6} xs={12}>
          {showComplements && (
            <TextField
              value={complemento}
              aria-readonly
              fullWidth
              label="Complemento"
              placeholder="Bloco A, Apto 21"
              size="small"
              name="complemento"
              onChange={e => setComplemento(e.target.value)}
              variant="outlined"
              inputProps={{ maxLength: 50 }}
              InputLabelProps={{
                shrink: true,
              }}
              error={
                !!erros.complemento ||
                (tipoIdentificador === 'SEM_NUMERO' && complemento === '')
              }
              helperText={
                erros.complemento
                  ? erros.complemento
                  : tipoIdentificador === 'SEM_NUMERO' && complemento === ''
                  ? 'O campo é obrigatório quando Sem Número.'
                  : ' '
              }
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

export default InfoAddress;
