import { useEffect, useState } from 'react';
import { format, isAfter, isEqual, isValid } from 'date-fns';
import { useAuth } from 'hooks/auth';
import { exibirTotalizadoresNaoVendasEReprocessadas } from 'constants/permissions';
import { useReportFilterFlagFetchStore } from 'store/useReportFilterFlagFetchStore';
import { useReportFilterStore } from 'store/useReportFilterStore';

export function useManagementReport() {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [day, setDay] = useState('');
  const [
    showNonSalesAndReprocessedTotalizers,
    setShowNonSalesAndReprocessedTotalizers,
  ] = useState(false);

  const startFetch = useReportFilterFlagFetchStore(state => state.startFetch);
  const setStartFetch = useReportFilterFlagFetchStore(
    state => state.setStartFetch,
  );
  const endFetch = useReportFilterFlagFetchStore(state => state.endFetch);
  const setEndFetch = useReportFilterFlagFetchStore(state => state.setEndFetch);
  const dateStart = useReportFilterFlagFetchStore(state => state.dateStart);
  const dateEnd = useReportFilterFlagFetchStore(state => state.dateEnd);
  const localToday = useReportFilterStore(state => state.localToday);
  const localYesterday = useReportFilterStore(state => state.localYesterday);
  const localLastWeek = useReportFilterStore(state => state.localLastWeek);

  const { hasPermission } = useAuth();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  useEffect(() => {
    const shouldShow = hasPermission(
      exibirTotalizadoresNaoVendasEReprocessadas,
    );

    setShowNonSalesAndReprocessedTotalizers(shouldShow);
  }, [hasPermission]);

  useEffect(() => {
    if (
      dateStart &&
      dateEnd &&
      isValid(dateStart) &&
      isValid(dateEnd) &&
      (isAfter(dateEnd, dateStart) || isEqual(dateEnd, dateStart))
    ) {
      setStartFetch(format(dateStart, 'yyyy-MM-dd'));
      setEndFetch(format(dateEnd, 'yyyy-MM-dd'));
      setDay(format(dateEnd, 'yyyy-MM-dd'));
    }
  }, [
    localToday,
    localLastWeek,
    localYesterday,
    dateStart,
    dateEnd,
    setStartFetch,
    setEndFetch,
  ]);

  const gridComponentSize = showNonSalesAndReprocessedTotalizers ? 3 : 4;

  return {
    anchorElement,
    setAnchorElement,
    startFetch,
    endFetch,
    day,
    showNonSalesAndReprocessedTotalizers,
    gridComponentSize,
    handleClick,
  };
}
