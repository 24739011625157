import { Box, Skeleton } from '@mui/material';
import { ReactComponent as VectorClose } from 'assets/icons/small/vector.svg';
import { SatisfactionSurvey as ISatisfactionSurvey } from 'models/SatisfactionSurvey';
import { StyledBoxRating, StyledRating } from '../styles';
import {
  BoxClose,
  BoxColumn,
  BoxRow,
  ContentDrawer,
  DataText,
  DataTextName,
  IconButton,
} from './styles';

function LoadingList() {
  return (
    <>
      <Box>
        <DataText>Avaliação</DataText>
        <Skeleton />
      </Box>
      <Box>
        <DataText>Avaliação</DataText>
        <Skeleton />
      </Box>
      <BoxRow>
        <BoxColumn>
          <Box>
            <DataText>ID</DataText>
            <Skeleton />
          </Box>
          <Box>
            <DataText>Fidelidade</DataText>
            <Skeleton />
          </Box>
          <Box>
            <DataText>Tipo</DataText>
            <Skeleton />
          </Box>
          <Box>
            <DataText>Mídia</DataText>
            <Skeleton />
          </Box>
        </BoxColumn>
        <BoxColumn>
          <Box>
            <DataText>Plano</DataText>
            <Skeleton />
          </Box>
          <Box>
            <DataText>Segmento</DataText>
            <Skeleton />
          </Box>
          <Box>
            <DataText>Origem</DataText>
            <Skeleton />
          </Box>
          <Box>
            <DataText>Campanha</DataText>
            <Skeleton />
          </Box>
        </BoxColumn>
      </BoxRow>
    </>
  );
}

interface BodyProps {
  satisfactionSurvey: ISatisfactionSurvey;
  isTableOrLess: boolean;
  closeDrawer(): void;
}

function Body({ satisfactionSurvey, isTableOrLess, closeDrawer }: BodyProps) {
  return (
    <Box>
      {isTableOrLess && (
        <BoxClose>
          <IconButton size="small" onClick={closeDrawer}>
            <VectorClose stroke="black" strokeOpacity="0.3" />
          </IconButton>
        </BoxClose>
      )}
      <Box>
        <DataText>Avaliação</DataText>
        <StyledBoxRating borderColor="transparent">
          <StyledRating value={satisfactionSurvey.nota} readOnly />
        </StyledBoxRating>
      </Box>
      <Box>
        <DataText>Avaliação</DataText>
        <DataTextName>{satisfactionSurvey.melhoria || '-'}</DataTextName>
      </Box>
      <BoxRow>
        <BoxColumn>
          <Box>
            <DataText>ID</DataText>
            <DataTextName>{satisfactionSurvey.id || '-'}</DataTextName>
          </Box>
          <Box>
            <DataText>Fidelidade</DataText>
            <DataTextName>{satisfactionSurvey.fidelidade || '-'}</DataTextName>
          </Box>
          <Box>
            <DataText>Tipo</DataText>
            <DataTextName>{satisfactionSurvey.tipo || '-'}</DataTextName>
          </Box>
          <Box>
            <DataText>Mídia</DataText>
            <DataTextName>{satisfactionSurvey.midia || '-'}</DataTextName>
          </Box>
        </BoxColumn>
        <BoxColumn>
          <Box>
            <DataText>Plano</DataText>
            <DataTextName>{satisfactionSurvey.plano || '-'}</DataTextName>
          </Box>
          <Box>
            <DataText>Segmento</DataText>
            <DataTextName>{satisfactionSurvey.segmento || '-'}</DataTextName>
          </Box>
          <Box>
            <DataText>Origem</DataText>
            <DataTextName>{satisfactionSurvey.origem || '-'}</DataTextName>
          </Box>
          <Box>
            <DataText>Campanha</DataText>
            <DataTextName>{satisfactionSurvey.campanha || '-'}</DataTextName>
          </Box>
        </BoxColumn>
      </BoxRow>
    </Box>
  );
}

interface InfoSatisfactionSurveyProps {
  satisfactionSurvey?: ISatisfactionSurvey;
  isTableOrLess: boolean;
  closeDrawer(): void;
}

export function InfoSatisfactionSurvey({
  satisfactionSurvey,
  isTableOrLess,
  closeDrawer,
}: InfoSatisfactionSurveyProps) {
  return (
    <ContentDrawer>
      {satisfactionSurvey ? (
        <Body
          satisfactionSurvey={satisfactionSurvey}
          isTableOrLess={isTableOrLess}
          closeDrawer={closeDrawer}
        />
      ) : (
        <LoadingList />
      )}
    </ContentDrawer>
  );
}
