import styled from 'styled-components';

export const SubHeader = styled.h2`
  display: flex;
  align-items: center;
  padding: 15px 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
`;
