import { IconButton, Skeleton } from '@mui/material';
import {
  ActionButtons,
  Container,
  Data,
  Divider,
  useStylesActionButtons,
} from 'components/StyledComponents';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { ItemProps } from '../types';
import { DataInfo, DataText } from './styles';

function Item({
  data,
  loading = false,
  setOfferSelected,
  handleOpenDeleteOfferDialog,
}: ItemProps) {
  const navigate = useNavigate();
  const classes = useStylesActionButtons();
  return (
    <Container>
      <Data>
        {loading ? (
          <DataInfo>
            <DataText style={{ width: 110 }}>
              <Skeleton width={110} />
            </DataText>
            <DataText>
              <Skeleton width={130} />
            </DataText>
            <DataText>
              <Skeleton width={150} />
            </DataText>
          </DataInfo>
        ) : (
          data && (
            <DataInfo>
              <DataText>{data.url_slug}</DataText>
              <DataText>{data.fidelidade ? 'Sim' : 'Não'}</DataText>
              <DataText>{data.preco_id}</DataText>
            </DataInfo>
          )
        )}
      </Data>
      <ActionButtons>
        <IconButton
          onClick={() => navigate(`/links-ofertas/edita-link/${data?.id}`)}
          className={classes.iconEdit}
          disabled={loading}
        >
          <FiEdit />
        </IconButton>
        <IconButton
          onClick={() => {
            handleOpenDeleteOfferDialog && handleOpenDeleteOfferDialog();
            setOfferSelected && data && setOfferSelected(data);
          }}
          className={classes.iconDelete}
          disabled={loading}
        >
          <RiDeleteBinLine />
        </IconButton>
      </ActionButtons>
      <Divider />
    </Container>
  );
}

export default Item;
