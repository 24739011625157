import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ButtonProgress, ContainerForm } from 'components/StyledComponents';
import { useToast } from 'hooks/toast';
import { Sale } from 'pages/Sale/InfoSale/types';

import api from 'services/api';

interface DeclareAsUntreatableFormFormProps {
  sale: Sale;
  setSale(sale: Sale): void;
  removeSale(sale: Sale): void;
  closeDrawer(): void;
}

function DeclareAsUntreatableForm({
  sale,
  setSale,
  removeSale,
  closeDrawer,
}: DeclareAsUntreatableFormFormProps) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { addToast } = useToast();

  const handleWithIntractableSale = () => {
    setOpen(false);
    setLoading(true);
    const url = '/core/vendas/atualizar-detalhe';

    return api
      .put(`${url}/${sale?.id}`, {
        status: 'NAO_TRATAVEL',
        tratavel: false,
        reprocessada_manual: true,
      })
      .then(({ data: res }) => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Venda declarada como não trátavel com sucesso.',
        });
        closeDrawer();
        removeSale && removeSale({ ...sale, ...res });
      })
      .catch(error => {
        setLoading(false);

        addToast({
          type: 'error',
          title: 'Erro!',
          description: error?.data?.message,
        });
      })
      .finally(() => {
        api
          .get(`core/vendas/${sale.id}`)
          .then(({ data }) => setSale({ ...sale, ...data }));
      });
  };

  return (
    <ContainerForm style={{ marginTop: 15 }}>
      <Button
        type="button"
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        disabled={loading}
        onClick={() => setOpen(true)}
      >
        Declarar como Não Tratável
        {loading && <ButtonProgress size={24} />}
      </Button>
      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Deseja realmente marcar esta venda?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Ao clicar em Sim, a venda será marcada como NÃO TRATÁVEL e não será
            mais possível desfazer esta ação.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Não
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              handleWithIntractableSale();
            }}
            color="primary"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </ContainerForm>
  );
}

export default DeclareAsUntreatableForm;
