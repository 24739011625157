import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .Button {
    max-width: 152px;
  }

  .Grid1 {
    margin-right: 16px;
  }

  @media (max-width: 960px) {
    display: flex;
    align-items: center;
    flex-direction: column;

    .Grid {
      flex-direction: column;
      max-width: 100%;
    }
    .Grid1 {
      margin-right: 0;
    }
    .Button {
      margin-top: 20px;
      max-width: 100%;
    }
  }
`;
