import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface CustomStyle {
  background: string;
}
interface LogHighlighterProps {
  log: string;
  customStyle: CustomStyle;
}

function LogHighlighter({ log, customStyle }: LogHighlighterProps) {
  return (
    <SyntaxHighlighter
      language="javascript"
      style={dracula}
      wrapLongLines
      wrapLines
      customStyle={customStyle}
    >
      {log}
    </SyntaxHighlighter>
  );
}

export default LogHighlighter;
