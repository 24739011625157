import { Button } from '@mui/material';
import { GetApp } from '@mui/icons-material';
import { ButtonProgress, List } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { FormEvent, useEffect, useState } from 'react';
import api from 'services/api';
import { downloadXlsFile } from 'util/blob';
import { useToast } from 'hooks/toast';
import { Error } from 'models/bugReport';
import Item from './Item';
import MobileItem from './MobileItem';

import { ActionButtons, Container, Header } from './styles';
import { SelectedIDs } from './types';
import Filters from './Filters';

interface BugReportListProps {
  errors?: Error[];
  setShowErrorInfo(value: boolean): void;
  setErrorSelected(setupSelected: Error): void;
  start: string;
}

interface BodyProps {
  errors?: Error[];
  isMobile: boolean;
  setShowErrorInfo(value: boolean): void;
  setErrorSelected(setupSelected: Error): void;
  selectedIDs: SelectedIDs;
  setSelectedIDs(selectedIDs: SelectedIDs): void;
}
interface LoadingListProps {
  isTableOrLess: boolean;
}

function LoadingList({ isTableOrLess }: LoadingListProps) {
  return isTableOrLess ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({
  errors,
  isMobile,
  setShowErrorInfo,
  setErrorSelected,
  selectedIDs,
  setSelectedIDs,
}: BodyProps) {
  return (
    <List>
      {errors && errors.length ? (
        errors.map(data =>
          isMobile ? (
            <MobileItem
              key={data.id}
              data={data}
              setShowErrorInfo={setShowErrorInfo}
              setErrorSelected={setErrorSelected}
              selectedIDs={selectedIDs}
              setSelectedIDs={setSelectedIDs}
            />
          ) : (
            <Item
              key={data.id}
              data={data}
              setShowErrorInfo={setShowErrorInfo}
              setErrorSelected={setErrorSelected}
              selectedIDs={selectedIDs}
              setSelectedIDs={setSelectedIDs}
            />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhum erro encontrado</h3>
      )}
    </List>
  );
}

function BugReportList({
  errors,
  setShowErrorInfo,
  setErrorSelected,
  start,
}: BugReportListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState<SelectedIDs>({});
  const [search, setSearch] = useState('');
  const [errorsList, setErrorsList] = useState(errors);

  const { addToast } = useToast();

  useEffect(() => {
    const contemIDSelecionado = !!Object.keys(selectedIDs).find(
      key => selectedIDs[key],
    );
    setIsValid(contemIDSelecionado);
  }, [selectedIDs]);

  useEffect(() => {
    if (!errors) {
      return;
    }

    let newErrorsList = [...errors];

    if (search) {
      newErrorsList = newErrorsList.filter(
        item => item?.response?.message?.indexOf(search) > -1,
      );
    }

    setErrorsList(newErrorsList);
  }, [search, errors]);

  const handleSubmitReport = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const params = {
      id: Object.keys(selectedIDs).reduce((acc, current) => {
        if (selectedIDs[current]) {
          acc.push(current);
        }
        return acc;
      }, [] as string[]),
    };
    setLoading(true);

    api
      .get('/core/relatorios/chamados', { responseType: 'blob', params })
      .then(({ data }) => {
        const filename = `${start}-omega-mascara-erros-api`;
        downloadXlsFile({ filename, file: data });
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro o baixar o relatório!',
          description:
            err.data && err.data.message ? err.data.message : err.message,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <Container>
      <ActionButtons>
        <Filters
          setSearch={setSearch}
          loading={loading}
          isValid={isValid}
          handleSubmitReport={handleSubmitReport}
        />
      </ActionButtons>
      {!isTableOrLess && (
        <Header>
          <span>Hora</span>
          <span>Data</span>
          <span>Contexto</span>
          <span>Mensagem</span>
        </Header>
      )}
      {errors ? (
        <Body
          errors={errorsList}
          isMobile={isTableOrLess}
          setShowErrorInfo={setShowErrorInfo}
          setErrorSelected={setErrorSelected}
          selectedIDs={selectedIDs}
          setSelectedIDs={setSelectedIDs}
        />
      ) : (
        <LoadingList isTableOrLess={isTableOrLess} />
      )}
      <ActionButtons>
        <form onSubmit={handleSubmitReport} noValidate autoComplete="off">
          <Button
            className="button"
            type="submit"
            variant="outlined"
            color="primary"
            startIcon={<GetApp />}
            disabled={!isValid || loading}
          >
            <span>Exportar</span>
            {loading && <ButtonProgress size={22} />}
          </Button>
        </form>
      </ActionButtons>
    </Container>
  );
}

export default BugReportList;
