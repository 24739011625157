import styled, { css } from 'styled-components';
import palette from 'theme/palette';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

interface ButtonFilterProps {
  actived: boolean;
}

interface ButtonTypeProps {
  actived: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 776px;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: 0px 2px 12px 0px #0000001a;
  border-radius: 6px;
  align-items: flex-start;
  padding: 24px;
  margin-bottom: 15px;
`;

export const ButtonFilter = styled.button.attrs({
  type: 'button', // eslint-disable-next-line
})<ButtonFilterProps>`
  border: none;
  background-color: transparent;
  padding: 15px 10px;
  font-size: 12px;
  line-height: 17px;
  width: 80px;
  text-align: center;
  color: #050c27;
  opacity: 0.7;
  cursor: pointer;

  &:hover {
    background-color: #d0edfb;
  }

  ${props =>
    props.actived &&
    css`
      background-color: ${palette.primary.main};
      color: #fff;
      font-weight: bold;
      &:hover {
        background-color: ${palette.primary.main};
      }
    `}
`;

export const GridMonths = styled.div`
  display: grid;
  grid-auto-columns: 2fr;
  grid-template-columns: repeat(6, 2fr);
  grid-column-gap: 16px;

  @media (max-width: 1024px) {
    display: grid;
    grid-auto-columns: 2fr;
    grid-template-columns: repeat(4, 2fr);
  }

  @media (max-width: 768px) {
    display: grid;
    grid-auto-columns: 2fr;
    grid-template-columns: repeat(2, 6fr);
  }
`;

export const GridReport = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;

  @media (max-width: 1024px) {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: repeat(4, 2fr);
  }

  @media (max-width: 768px) {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: repeat(1, 4fr);
    width: 100%;
  }
`;

export const ButtonType = styled.button.attrs({
  type: 'button', // eslint-disable-next-line
})<ButtonTypeProps>`
  border: none;
  background-color: transparent;
  padding: 15px 15px;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #050c27;
  opacity: 0.7;
  cursor: pointer;
  margin-bottom: 16px;

  &:hover {
    background-color: #d0edfb;
  }

  ${props =>
    props.actived &&
    css`
      background-color: ${palette.primary.main};
      color: #fff;
      font-weight: bold;
      opacity: 0.7;
      &:hover {
        background-color: ${palette.primary.main};
      }
    `}
`;

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: '#050C27',
    fontSize: 12,
    marginBottom: 10,
  },
  buttonMonth: {
    borderRadius: 6,
    width: 101,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    boxShadow: 'none',
    fontWeight: 'normal',
    marginBottom: 16,
    opacity: '0.8',
    background: '#ffff',

    '&:hover': {
      backgroundColor: 'rgba(48,174,201,0.2)',
      boxShadow: 'none',
    },
    '&.MuiButton-containedPrimary': {
      border: '1px solid rgba(181, 210, 224, 1)',
      backgroundColor: '#F5FCFF',
      color: '#050C27',
    },
  },
  keyboardDate: {
    [theme.breakpoints.down(768)]: {
      width: '100%',
    },
    '& .MuiInputBase-root': {
      height: 41,
      width: 165,
      marginRight: 15,
      fontSize: 12,
      [theme.breakpoints.down(768)]: {
        width: '100%',
        marginBottom: 15,
      },
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  buttonExport: {
    width: 165,
    marginLeft: 'auto',
  },
}));
