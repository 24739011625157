import { Checkbox, InputLabel, OutlinedInput } from '@mui/material';
import { useEffect } from 'react';
import { useSatisfactionSurveyStore } from 'store/satisfactionSurveyStore';
import {
  Box,
  BoxFilters,
  ButtonFilter,
  Divider,
  FilterBox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
} from './styles';
import { useSatisfactionSurvey } from '../hooks/useSatisfactionSurvey';

interface FiltersProps {
  ratingSelected: string;
  origemSelected: string;
  onlyWithComment: boolean;
  setRatingSelected(ratingSelected: string): void;
  setOrigemSelected(origemSelected: string): void;
  setOnlyWithComment(onlyWithComment: boolean): void;
}

function Filters({
  ratingSelected,
  origemSelected,
  onlyWithComment,
  setRatingSelected,
  setOrigemSelected,
  setOnlyWithComment,
}: FiltersProps) {
  const { handleToday, handleYesterday, handleLastWeek, handleMonth } =
    useSatisfactionSurvey();

  const today = useSatisfactionSurveyStore(state => state.today);
  const yesterday = useSatisfactionSurveyStore(state => state.yesterday);
  const lastWeek = useSatisfactionSurveyStore(state => state.lastWeek);
  const month = useSatisfactionSurveyStore(state => state.month);
  const setStartDate = useSatisfactionSurveyStore(state => state.setStartDate);
  const setEndDate = useSatisfactionSurveyStore(state => state.setEndDate);

  useEffect(() => {
    if (!today && !yesterday && !lastWeek && !month) {
      setStartDate('');
      setEndDate('');
    }
  }, [lastWeek, month, setEndDate, setStartDate, today, yesterday]);

  useEffect(() => {
    handleLastWeek(true);
  }, [handleLastWeek]);

  return (
    <FilterBox>
      <Box>
        <ButtonFilter onClick={() => handleToday(!today)} actived={today}>
          Hoje
        </ButtonFilter>
        <ButtonFilter
          onClick={() => handleYesterday(!yesterday)}
          actived={yesterday}
        >
          Ontem
        </ButtonFilter>
        <ButtonFilter
          onClick={() => handleLastWeek(!lastWeek)}
          actived={lastWeek}
        >
          7 dias
        </ButtonFilter>
        <ButtonFilter onClick={() => handleMonth(!month)} actived={month}>
          30 dias
        </ButtonFilter>
      </Box>

      <BoxFilters>
        <FormControl variant="outlined">
          <InputLabel htmlFor="origem">Origem</InputLabel>
          <Select
            value={origemSelected}
            label="Origem"
            onChange={e => {
              setOrigemSelected(e.target.value as string);
            }}
            input={<OutlinedInput id="select-origem" label="Origem" />}
            size="small"
            variant="outlined"
            labelId="origem"
            id="origem"
          >
            <MenuItem value="Todas">Todas</MenuItem>
            <Divider />
            <MenuItem value="ECOMMERCE">E-commerce</MenuItem>
            <Divider />
            <MenuItem value="TAKE">Take</MenuItem>
            <Divider />
            <MenuItem value="GALLABOX">Gallabox</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined">
          <InputLabel htmlFor="avaliacao">Avaliação</InputLabel>
          <Select
            value={ratingSelected}
            label="Avaliação"
            onChange={e => {
              setRatingSelected(e.target.value as string);
            }}
            input={<OutlinedInput id="select-rating" label="Avaliação" />}
            size="small"
            variant="outlined"
            labelId="avaliacao"
            id="avaliacao"
          >
            <MenuItem value="Todas">Todas</MenuItem>
            <Divider />
            <MenuItem value="1">1 estrela</MenuItem>
            <Divider />
            <MenuItem value="2">2 estrelas</MenuItem>
            <Divider />
            <MenuItem value="3">3 estrelas</MenuItem>
            <Divider />
            <MenuItem value="4">4 estrelas</MenuItem>
            <Divider />
            <MenuItem value="5">5 estrelas</MenuItem>
          </Select>
        </FormControl>
      </BoxFilters>

      <FormControlLabel
        control={
          <Checkbox
            checked={onlyWithComment}
            onChange={e => setOnlyWithComment(e.target.checked)}
          />
        }
        label="Comentados"
      />
    </FilterBox>
  );
}

export default Filters;
