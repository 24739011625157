import styled from 'styled-components';

interface ContainerProps {
  messagesExist: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: ${props => !props.messagesExist && 'none'};
  position: absolute;
  right: 0;
  top: 65px;
  padding: 30px;
  overflow: hidden;
`;
