import { useState } from 'react';

import { Container, Data, Divider } from 'components/StyledComponents';
import { cell as cellFormatter, date as dateFormatter } from 'util/formatter';

import { IconButton, Skeleton } from '@mui/material';
import { FindInPageOutlined, Print } from '@mui/icons-material';
import { useSaleSelected } from 'store/saleSelected';
import { SIMCard } from 'models/simcards';
import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined';
import { DataInfo, DataText, DataTextName } from './styles';
import { Sale } from '../types';
import SIMCardRecused from '../SIMCardRecused';

export interface ItemProps {
  data?: SIMCard;
  loading?: boolean;
  setSaleUpdateToSent?(sale: Sale): void;
  updateSimCards?(): void;
}

function Item({
  data,
  loading = false,
  setSaleUpdateToSent,
  updateSimCards,
}: ItemProps) {
  const setSalesSelected = useSaleSelected(state => state.setSalesSelected);
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <Data>
        <DataInfo>
          <DataText>{loading ? <Skeleton width={140} /> : data?.nome}</DataText>
          <DataText>
            {loading ? <Skeleton width={140} /> : data?.iccid}
          </DataText>
          <DataTextName>
            {loading ? <Skeleton width={140} /> : cellFormatter(data?.msisdn)}
          </DataTextName>
          <DataTextName>
            {loading ? <Skeleton width={140} /> : dateFormatter(data?.data)}
          </DataTextName>
        </DataInfo>
        <IconButton onClick={() => setOpen(true)}>
          {loading ? <Skeleton width={20} /> : <BackHandOutlinedIcon />}
        </IconButton>
        <IconButton
          onClick={() => {
            if (!data) {
              return;
            }
            setSaleUpdateToSent &&
              setSaleUpdateToSent({
                id: data.sale_id,
                url_etiqueta_envio: data.url_etiqueta_envio,
              });
            window.open(data?.url_etiqueta_envio, '_blank');
          }}
        >
          {loading ? <Skeleton width={20} /> : <Print />}
        </IconButton>
        <IconButton onClick={() => data && setSalesSelected(data.sale_id)}>
          {loading ? <Skeleton width={20} /> : <FindInPageOutlined />}
        </IconButton>
      </Data>
      <Divider />

      {data && updateSimCards && (
        <SIMCardRecused
          saleID={data.sale_id}
          open={open}
          setOpen={setOpen}
          updateSimCards={updateSimCards}
        />
      )}
    </Container>
  );
}

export default Item;
