import React, { useEffect } from 'react';

import {
  FiAlertCircle,
  FiCheckCircle,
  FiInfo,
  FiXCircle,
} from 'react-icons/fi';
import { ToastMessage, useToast } from 'hooks/toast';
import { Typography } from '@mui/material';
import { Container } from './styles';

interface ToastProps {
  message: ToastMessage;
}

const icons = {
  info: <FiInfo size={24} />,
  error: <FiAlertCircle size={24} />,
  success: <FiCheckCircle size={24} />,
};

function Toast({ message }: ToastProps) {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, message.id]);
  return (
    <Container
      type={message.type}
      has-description={message.description ? 'true' : 'false'}
    >
      {icons[message.type || 'info']}
      <div>
        <strong>{message.title}</strong>
        {message.description && (
          <Typography component="p">{message.description}</Typography>
        )}
      </div>
      <button
        type="button"
        onClick={() => {
          removeToast(message.id);
        }}
      >
        <FiXCircle size={18} />
      </button>
    </Container>
  );
}

export default Toast;
