import useSWR from 'swr';
import api from 'services/api';
import { ActivePlan } from 'models/activePlans';

interface Params {
  segmento?: string;
}

export const ActivePlansFetch = (segment?: string) => {
  const { data, mutate } = useSWR<ActivePlan[]>(
    `active_plans_${segment}`,
    async (): Promise<ActivePlan[]> => {
      const params: Params = {};

      if (segment !== 'todos') {
        params.segmento = segment;
      }

      const { data: plans } = await api.get('/core/planos/ativos', { params });

      return plans;
    },
  );
  return { data, mutate };
};
