import { CardHeader } from '@mui/material';
import { Card } from 'components/StyledComponents';
import { TopBar } from 'components/CardList';
import useBreakpoint from 'hooks/useBreakpoint';
import { useState } from 'react';
import { format, getYear } from 'date-fns';
import { AffiliateGoalFetch } from 'fetches/affiliateGoalFetch';
import { AffiliateGoalDefaultParametersFetch } from 'fetches/affiliateGoalDefaultParametersFetch';
import Filters from './Filters';
import GoalList from './GoalList';

function ManagerGoal() {
  const currentYear = getYear(new Date());
  const currentMonth = new Date();
  const currentMonthFormated = format(currentMonth, 'MM');

  const [monthSelected, setMonthSelected] = useState(currentMonthFormated);
  const [yearSelected, setYearSelected] = useState(String(currentYear));

  const { data: affiliates, mutate } = AffiliateGoalFetch({
    month: monthSelected,
    year: yearSelected,
  });
  const { data: defaultParameters } = AffiliateGoalDefaultParametersFetch();

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Card>
      <TopBar isDesktop={!isTableOrLess}>
        <CardHeader
          title="Gestão de Meta"
          subheader="Painel de edição das metas do mês."
        />
        <Filters
          monthSelected={monthSelected}
          setMonthSelected={setMonthSelected}
          yearSelected={yearSelected}
          setYearSelected={setYearSelected}
        />
      </TopBar>

      <GoalList
        affiliates={affiliates}
        defaultParameters={defaultParameters}
        monthSelected={parseInt(monthSelected, 10)}
        yearSelected={parseInt(yearSelected, 10)}
        appendGoal={mutate}
      />
    </Card>
  );
}

export default ManagerGoal;
