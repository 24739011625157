import { Li, Ul } from 'components/Pagination/styles';
import useBreakpoint from 'hooks/useBreakpoint';
import React from 'react';

import { ActionButtonPagination, Container, Item } from './styles';

interface SimplePaginationProps {
  currentPage?: number;
  isEmpty: number;
  setCurrentPage(page: number): void;
}

function SimplePagination({
  currentPage = 1,
  setCurrentPage,
  isEmpty,
}: SimplePaginationProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Container isDesktop={!isTableOrLess}>
      {currentPage > 1 && (
        <ActionButtonPagination onClick={() => setCurrentPage(currentPage - 1)}>
          Anterior
        </ActionButtonPagination>
      )}
      <Ul>
        <Li>
          <Item>{currentPage}</Item>
        </Li>
      </Ul>

      {!isEmpty && (
        <ActionButtonPagination onClick={() => setCurrentPage(currentPage + 1)}>
          Próximo
        </ActionButtonPagination>
      )}
    </Container>
  );
}

export default SimplePagination;
