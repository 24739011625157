import React, { useState } from 'react';
import { Button, Popper } from '@mui/material';
import { FindInPageOutlined, Print } from '@mui/icons-material';
import { Actions, UseStyles } from 'components/PopperActions';
import { useSaleSelected } from 'store/saleSelected';
import { SIMCard } from 'models/simcards';
import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined';
import { Sale } from '../types';
import SIMCardRecused from '../SIMCardRecused';

interface PopperActionsProps {
  id: string;
  open: boolean;
  data: SIMCard;
  anchorElement: null | HTMLElement;
  setSaleUpdateToSent?(sale: Sale): void;
  updateSimCards(): void;
}

function PopperActions({
  id,
  open,
  data,
  anchorElement,
  setSaleUpdateToSent,
  updateSimCards,
}: PopperActionsProps) {
  const setSalesSelected = useSaleSelected(state => state.setSalesSelected);
  const [openModalRecusedSale, setOpenModalRecusedSale] = useState(false);

  const classes = UseStyles();
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  return (
    <>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorElement}
        placement="bottom"
        modifiers={[
          {
            name: 'flip',
            enabled: true,
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              boundariesElement: 'scrollParent',
            },
          },
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
        className={classes.popper}
      >
        <span className={classes.arrow} ref={setArrowRef} />
        <Actions className={classes.paper}>
          <Button
            onClick={() => {
              if (!data) {
                return;
              }
              setSaleUpdateToSent &&
                setSaleUpdateToSent({
                  id: data.sale_id,
                  url_etiqueta_envio: data.url_etiqueta_envio,
                });
              window.open(data?.url_etiqueta_envio, '_blank');
            }}
            startIcon={<Print />}
            fullWidth
          >
            Imprimir etiqueta
          </Button>
          <div
            style={{
              width: '100%',
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            }}
          />
          <Button
            onClick={() => data && setSalesSelected(data.sale_id)}
            startIcon={<FindInPageOutlined />}
            fullWidth
          >
            Visualizar pedido
          </Button>
          <div
            style={{
              width: '100%',
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            }}
          />
          <Button
            onClick={() => setOpenModalRecusedSale(true)}
            startIcon={<BackHandOutlinedIcon />}
            fullWidth
          >
            Recusar pedido
          </Button>
        </Actions>
      </Popper>
      <SIMCardRecused
        saleID={data.sale_id}
        open={openModalRecusedSale}
        setOpen={setOpenModalRecusedSale}
        updateSimCards={updateSimCards}
      />
    </>
  );
}

export default PopperActions;
