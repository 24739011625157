import { Card, Skeleton } from '@mui/material';
import { Loyalty } from 'models/loyalties';
import { usePlanStore } from 'store/planStore';
import FidelityListItem from './FidelityListItem';

interface FidelityListProps {
  data: Loyalty[];
  loading?: boolean;
}

function FidelityList({ data: loyalty, loading }: FidelityListProps) {
  const planStore = usePlanStore(state => state.plan);
  const setPlanStore = usePlanStore(state => state.setPlan);

  const handleRemoveItem = (id: string) => {
    const newFidelities = planStore.fidelidades.filter(
      fidelidade => fidelidade.tim_id !== id,
    );
    setPlanStore({ ...planStore, fidelidades: newFidelities });
  };

  return (
    <Card style={{ margin: '8px 16px 16px' }}>
      {loading ? (
        <>
          <Skeleton width="30%" />
          <Skeleton width="30%" />
          <Skeleton width="30%" />
        </>
      ) : loyalty.length > 0 ? (
        loyalty.map(fidelidade => (
          <FidelityListItem
            key={fidelidade.tim_id}
            tim_id={fidelidade.tim_id}
            nome={fidelidade.nome}
            fidelidade_id={fidelidade.id}
            onRemoveItem={() => handleRemoveItem(fidelidade.tim_id)}
          />
        ))
      ) : (
        <div
          style={{
            boxShadow: 'inset 0px -1px 0px rgb(0 0 0 / 5%)',
            padding: 16,
            fontSize: 16,
          }}
        >
          O plano não possui fidelidades disponíveis
        </div>
      )}
    </Card>
  );
}

export default FidelityList;
