interface Attributes {
  label: string;
  color: string;
}

interface StatusCardSetup {
  NOVO: Attributes;
  EM_ANDAMENTO: Attributes;
  AGENDADO: Attributes;
  ARMAZENADO: Attributes;
  ERRO: Attributes;

  [key: string]: Attributes;
}

const statusCardSetup: StatusCardSetup = {
  NOVO: {
    label: 'Novo',
    color: 'rgba(48, 174, 201, 0.2)',
  },
  EM_ANDAMENTO: {
    label: 'Em Andamento',
    color: 'rgba(242, 246, 12, 0.39)',
  },
  AGENDADO: {
    label: 'Agendado',
    color: 'rgba(255, 168, 0, 0.31)',
  },
  ARMAZENADO: {
    label: 'Armazenado',
    color: 'rgba(201, 48, 85, 0.2)',
  },
  ERRO: {
    label: 'Erro',
    color: 'rgba(255, 10, 10, 0.7)',
  },
};

export default statusCardSetup;
