import React from 'react';

import { useSaleSelected } from 'store/saleSelected';
import { cell as celFormatter, cpf as cpfFormatter } from 'util/formatter';
import copyIcon from 'assets/icons/small/copy.svg';
import { IconButton, Skeleton } from '@mui/material';
import copyToClipboard from 'util/copyToClipboard';

import StatusSale from 'components/StatusSale';
import { Container, Data, DataLabel, DataText, GroupData } from './styles';
import { ItemProps } from '../Item';

function MobileItem({ data, loading = false }: ItemProps) {
  const setSalesSelected = useSaleSelected(state => state.setSalesSelected);
  return (
    <Container onClick={() => data && setSalesSelected(data.id)}>
      {loading ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        data && (
          <>
            <StatusSale status={data.status} />
            <Data>
              <DataLabel>Nome</DataLabel>
              <DataText>{data.nome}</DataText>
            </Data>
            <GroupData>
              <Data>
                <DataLabel>CPF</DataLabel>
                <DataText>
                  {cpfFormatter(data.cpf)}
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      copyToClipboard(data.cpf);
                    }}
                    color="inherit"
                  >
                    <img src={copyIcon} alt="copiar" />
                  </IconButton>
                </DataText>
              </Data>
              <Data>
                <DataLabel>Número portado</DataLabel>
                <DataText>
                  {celFormatter(data.msisdn)}
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      copyToClipboard(data.provisorio);
                    }}
                    color="inherit"
                  >
                    <img src={copyIcon} alt="copiar" />
                  </IconButton>
                </DataText>
              </Data>
            </GroupData>
            <GroupData>
              <Data>
                <DataLabel>Motivo Tratamento</DataLabel>
                <DataText>{data.motivo_tratamento}</DataText>
              </Data>
            </GroupData>
          </>
        )
      )}
    </Container>
  );
}

export default MobileItem;
