import { Container, Data, Divider } from 'components/StyledComponents';
import { Skeleton } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { SatisfactionSurvey } from 'models/SatisfactionSurvey';
import { useSatisfactionSurveyStore } from 'store/satisfactionSurveyStore';
import { DataInfo, DataText, useStyles } from './styles';
import { StyledBoxRating, StyledRating } from '../styles';

export interface ItemProps {
  loading?: boolean;
  data?: SatisfactionSurvey;
}

function Item({ data, loading = false }: ItemProps) {
  const classes = useStyles();
  const setIdSatisfactionSurveySelected = useSatisfactionSurveyStore(
    state => state.setIdSatisfactionSurveySelected,
  );

  return (
    <Container>
      <Data>
        {loading ? (
          <DataInfo>
            <Skeleton width={85} />
            <Skeleton width={75} />
            <Skeleton width={90} />
            <Skeleton width={415} />
            <Skeleton width={120} />
          </DataInfo>
        ) : (
          data && (
            <DataInfo
              onClick={() =>
                setIdSatisfactionSurveySelected(data.id.toString())
              }
            >
              <DataText className={classes.textData}>
                {`${format(
                  parseISO(data.created_at),
                  'yyyy/MM/dd',
                )} às ${format(parseISO(data.created_at), 'hh:mm')}`}
              </DataText>
              <DataText className={classes.textID}>{data.id}</DataText>
              <DataText className={classes.textData}>{data.origem}</DataText>
              <DataText className={classes.textMelhoria}>
                {data.melhoria}
              </DataText>
              <StyledBoxRating borderColor="transparent">
                <StyledRating value={data.nota} readOnly />
              </StyledBoxRating>
            </DataInfo>
          )
        )}
      </Data>
      <Divider />
    </Container>
  );
}

export default Item;
