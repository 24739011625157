import styled from 'styled-components';

export const CardShowComponentImg = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  padding: 10px 16px;
  background-color: rgba(242, 242, 242, 1);
  border-radius: 6px;

  span {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.4);
  }
`;
