import styled from 'styled-components';

export const DataInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 45px 0px 24px;
`;

export const DataTextName = styled.span`
  min-width: 150px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  display: flex;
  align-items: center;

  @media (max-width: 1560px) {
    min-width: 90px;
    width: 160px;
  }
`;

export const DataText = styled.span`
  min-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  display: flex;
  align-items: center;

  @media (max-width: 1560px) {
    min-width: 90px;
  }
`;
