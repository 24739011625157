import { useCallback, useState } from 'react';
import {
  Button,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
} from '@mui/material';
import { Add, AddCircle } from '@mui/icons-material';
import palette from 'theme/palette';

import { VirtualSellerFetch } from 'fetches/virtualSellerFetch';

import { Card, Header } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { VirtualSeller as IVirtualSeller } from 'models/virtualSeller';
import VirtualSellerList from './VirtualSellerList';
import FormAddVirtualSellerMobile from './FormAddVirtualSellerMobile';
import FormAddVirtualSeller from './FormAddVirtualSeller';

function ManageVirtualSeller() {
  const { data: response, mutate } = VirtualSellerFetch();

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  const [showNewVirtualSelllerMobile, setShowNewVirtualSelllerMobile] =
    useState(false);
  const [expanded, setExpanded] = useState(false);

  const appendNewVirtualSeller = useCallback(
    (virtualSeller: IVirtualSeller) => {
      const newVirtualSeller = response
        ? [...response, virtualSeller]
        : [virtualSeller];
      mutate(newVirtualSeller, true);
    },
    [response, mutate],
  );

  return (
    <Card>
      <Header>
        <CardHeader
          title="Vendedores Vituais"
          subheader="Vendedores utilizados nas ativações"
        />
        {isTableOrLess ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowNewVirtualSelllerMobile(true)}
              style={{
                marginRight: 20,
              }}
            >
              <Add style={{ color: palette.white }} />
            </Button>
            <FormAddVirtualSellerMobile
              showNewVirtualSelllerMobile={showNewVirtualSelllerMobile}
              setShowNewVirtualSelllerMobile={setShowNewVirtualSelllerMobile}
              appendVirtualSeller={appendNewVirtualSeller}
            />
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{ maxWidth: 125 }}
            startIcon={<AddCircle />}
            onClick={() => setExpanded(true)}
          >
            <span>Novo</span>
          </Button>
        )}
      </Header>
      <CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {isTableOrLess ? (
            <div />
          ) : (
            <>
              <Divider />
              <Card>
                <FormAddVirtualSeller
                  expanded={expanded}
                  setExpanded={setExpanded}
                  appendVirtualSeller={appendNewVirtualSeller}
                />
              </Card>
            </>
          )}
        </Collapse>
      </CardContent>
      <CardContent>
        <VirtualSellerList
          virtualSellers={response}
          updateVirtualSellers={mutate}
        />
      </CardContent>
    </Card>
  );
}

export default ManageVirtualSeller;
