import React from 'react';

import { IconButton, Skeleton } from '@mui/material';
import { cell as cellFormatter, cpf as cpfFormatter } from 'util/formatter';

import {
  Container,
  Data,
  DataInfo,
  Divider,
} from 'components/StyledComponents';
import { Search } from '@mui/icons-material';
import copyToClipboard from 'util/copyToClipboard';
import copyIcon from 'assets/icons/small/copy.svg';
import { useNavigate } from 'react-router-dom';
import { FraudKeeper } from 'models/fraudKeeper';
import ReasonCard from '../../ReasonCard';
import { DataName, DataText } from './styles';

export interface ItemProps {
  loading?: boolean;
  data?: FraudKeeper;
}

function Item({ data, loading = false }: ItemProps) {
  const navigate = useNavigate();
  if (!data) {
    return null;
  }

  return (
    <Container>
      <Data>
        <DataInfo>
          <DataText>
            {loading ? (
              <Skeleton width={140} />
            ) : (
              cpfFormatter(data.cliente_cpf)
            )}
            <IconButton
              onClick={e => {
                e.stopPropagation();
                copyToClipboard(data.cliente_cpf);
              }}
              color="inherit"
            >
              <img src={copyIcon} alt="copiar" />
            </IconButton>
          </DataText>
          <DataText>
            {loading ? (
              <Skeleton width={140} />
            ) : (
              cellFormatter(data.cliente_telefone)
            )}
            <IconButton
              style={{ padding: 8 }}
              onClick={e => {
                e.stopPropagation();
                copyToClipboard(data.cliente_telefone);
              }}
              color="inherit"
            >
              <img src={copyIcon} alt="copiar" />
            </IconButton>
          </DataText>
          <DataText style={{ width: 240 }}>
            <DataName>
              {loading ? <Skeleton width={140} /> : data.cliente_email}
            </DataName>
            <IconButton
              onClick={e => {
                e.stopPropagation();
                copyToClipboard(data.cliente_email);
              }}
              color="inherit"
            >
              <img src={copyIcon} alt="copiar" />
            </IconButton>
          </DataText>
          <DataText style={{ paddingLeft: 15 }}>
            {loading ? (
              <Skeleton width={140} />
            ) : (
              <ReasonCard
                reasons={data.motivos}
                curadoriaId={data.curadoria_id}
              />
            )}
          </DataText>
          <DataText>
            {loading ? <Skeleton width={140} /> : data.data_criacao}
          </DataText>
        </DataInfo>
        <Search
          onClick={() =>
            data && navigate(`/curadoria-fraude/${data.curadoria_id}`)
          }
        />
      </Data>
      <Divider />
    </Container>
  );
}

export default Item;
