import useSWR from 'swr';
import api from 'services/api';
import { LPBenefit } from 'models/lpBenefits';

export const LPBenefitsFetch = () => {
  const { data, mutate } = useSWR<LPBenefit[]>(
    'lpBenefits',
    async (): Promise<LPBenefit[]> => {
      const { data: benefits } = await api.get('/core/lp-beneficios');

      return benefits;
    },
  );
  return { data, mutate };
};
