import useSWR from 'swr';
import api from 'services/api';
import { Batch, ICCIDBatchesFetchResponse, Indicator } from 'models/iccidBatch';

export const IccidBatchesFetch = () => {
  const { data, mutate } = useSWR<Batch[]>(
    'iccid_batches',
    async (): Promise<Batch[]> => {
      const response = await api.get(`/core/simcards/lotes`);
      return response.data as Batch[];
    },
  );
  return { data, mutate };
};

interface Params {
  hlr?: string;
  busca?: string;
}

export const IccidBatchesFetchByHLR = (
  hlr: string,
  page: number,
  search: string,
) => {
  const { data, mutate } = useSWR<ICCIDBatchesFetchResponse>(
    `iccid_batches_by_hlr_${hlr}_${page}_${search}`,
    async (): Promise<ICCIDBatchesFetchResponse> => {
      const params: Params = {};
      params.hlr = hlr;

      if (search) {
        params.busca = search;
      }

      const response = await api.get(`/core/simcards/lotes?page=${page}`, {
        params,
      });

      const paginationInfo = {
        currentPage: parseInt(response.headers['x-current-page'], 10),
        lastPage: parseInt(response.headers['x-last-page'], 10),
        perPage: parseInt(response.headers['x-per-page'], 10),
        total: parseInt(response.headers['x-total-count'], 10),
      };

      const batches = response.data.lotes as Batch[];
      const indicators = response.data.indicadores as Indicator;

      return {
        batches,
        indicators,
        paginationInfo,
      } as ICCIDBatchesFetchResponse;
    },
  );
  return { data, mutate };
};
