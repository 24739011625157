import useSWR from 'swr';
import api from 'services/api';
import { Domain } from 'models/domain';

export const DomainFetch = (type: string) => {
  const { data } = useSWR<Domain[]>(type, async (): Promise<Domain[]> => {
    const response = await api.get(`/core/dominios/${type}`);

    return response.data as Domain[];
  });
  return { data };
};
