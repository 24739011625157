import React, { FormEvent, useEffect, useState } from 'react';
import { CardContent, CardHeader } from '@mui/material';
import { format, isAfter, isValid, subMonths } from 'date-fns';
import { useToast } from 'hooks/toast';
import { downloadZipFile } from 'util/blob';
import api from 'services/api';
import { Form } from 'components/StyledComponents';
import Filters from './Filters';

function UserJourneyReport() {
  const [dateStart, setDateStart] = useState<Date>(subMonths(new Date(), 1));
  const [dateEnd, setDateEnd] = useState<Date>(new Date());

  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    if (
      dateStart &&
      dateEnd &&
      isValid(dateStart) &&
      isValid(dateEnd) &&
      isAfter(dateEnd, dateStart)
    ) {
      setStart(format(dateStart, 'yyyy-MM-dd'));
      setEnd(format(dateEnd, 'yyyy-MM-dd'));
    }
  }, [dateStart, dateEnd]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const params = {
      inicio: start,
      fim: end,
    };
    setLoading(true);
    api
      .get('/core/jornada-usuario/relatorio', { responseType: 'blob', params })
      .then(({ data }) => {
        const filename = 'relatorio-abandono-fluxo';
        downloadZipFile({ filename, file: data });
      })
      .catch(error => {
        if (error.status === 422) {
          addToast({
            type: 'info',
            title: 'Não existe nenhum resultado para o período escolhido',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro o baixar o relatório!',
          });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <CardHeader
        title="Abandono de Fluxo"
        subheader="Módulo de extração de relatório"
      />
      <CardContent>
        <Form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Filters
            start={dateStart}
            end={dateEnd}
            setStart={setDateStart}
            setEnd={setDateEnd}
            loading={loading}
          />
        </Form>
      </CardContent>
    </>
  );
}

export default UserJourneyReport;
