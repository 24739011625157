import { useStylesModal } from 'components/UseStylesModal';
import { useCallback, useState } from 'react';
import { Button, DialogActions, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { ButtonProgress } from 'components/StyledComponents';
import { ModalCard } from 'pages/CmsLP/components/DeleteModal/styles';

interface InvalidateNonProcessedProps {
  baseId: number;
  fetchBaseData(): void;
}
function InvalidateNonProcessed({
  baseId,
  fetchBaseData,
}: InvalidateNonProcessedProps) {
  const classes = useStylesModal();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const handleClose = () => {
    setOpen(false);
  };

  const handleInvalidateNonProcessed = useCallback(() => {
    setLoading(true);
    api
      .put(`/core/impactos/invalidar-processamento/${baseId}`)
      .then(() => {
        fetchBaseData();
        addToast({
          type: 'success',
          title: 'Base invalidada',
          description: 'Os leads não processados da base foram invalidados.',
        });
        setLoading(false);
        handleClose();
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro ao invalidar base',
          description: error?.data?.message,
        });
        setLoading(false);
        handleClose();
      });
  }, [baseId, fetchBaseData, addToast]);

  return (
    <>
      <div style={{ marginLeft: 'auto' }}>
        <Button
          type="button"
          className={classes.buttonCancel}
          variant="outlined"
          onClick={() => setOpen(true)}
          disabled={loading}
        >
          Invalidar leads não processados
        </Button>
      </div>
      <Modal
        aria-labelledby="tile-modal"
        aria-describedby="modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={open}>
          <ModalCard>
            <Typography>
              Você deseja mesmo invalidar os leads não processados?
            </Typography>
            <DialogActions className={classes.dialogActions}>
              <Button variant="outlined" onClick={() => handleClose()}>
                Voltar
              </Button>
              <Button
                onClick={handleInvalidateNonProcessed}
                variant="contained"
                disabled={loading}
              >
                Invalidar leads não processados
                {loading && <ButtonProgress size={24} />}
              </Button>
            </DialogActions>
          </ModalCard>
        </Fade>
      </Modal>
    </>
  );
}

export default InvalidateNonProcessed;
