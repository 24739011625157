import {
  FormControl,
  InputLabel,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { ClassNameMap } from '@mui/styles';
import { useConfigurationPackageStore } from 'store/configurationPackageStore';
import { CategoryPackage } from 'models/packages';
import { Card } from '../styles';

interface PackageFlashSalesProps {
  planSelected: number;
  flashSale?: CategoryPackage[];
  gb: number;
  classes: ClassNameMap;
}
function PackageFlashSales({
  planSelected,
  flashSale,
  gb,
  classes,
}: PackageFlashSalesProps) {
  const configurationStore = useConfigurationPackageStore(
    state => state.configuration,
  );
  const setConfigurationStore = useConfigurationPackageStore(
    state => state.setConfigurationPackage,
  );

  const handleChangePackageFlashSale = (valor: string) => {
    const flashSaleId = parseInt(valor, 10);
    setConfigurationStore({
      ...configurationStore,
      pacote_flashsale: flashSaleId,
    });
  };
  return (
    <Card planSelected={planSelected}>
      <Typography variant="h5" gutterBottom className={classes.title}>
        Pacote Flash Sales
      </Typography>
      {planSelected !== 0 ? (
        flashSale?.length ? (
          flashSale?.map(item => (
            <div key={item.categoria}>
              <FormControl
                variant="outlined"
                margin="none"
                size="small"
                fullWidth
                className={classes.field}
              >
                <InputLabel htmlFor="pacote" focused shrink>
                  Pacote
                </InputLabel>
                <Select
                  native
                  value={configurationStore.pacote_flashsale || 0}
                  onChange={e => {
                    handleChangePackageFlashSale(e.target.value as string);
                  }}
                  label="pacote"
                >
                  <option aria-label="vazio" value="null">
                    Sem pacote
                  </option>
                  {item.pacotes?.map(value => (
                    <option aria-label="plan" key={value.id} value={value.id}>
                      {value.nome}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                label="GB"
                margin="dense"
                name="gb"
                size="small"
                variant="outlined"
                value={gb || 0}
                className={classes.field}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          ))
        ) : !flashSale ? (
          <Skeleton />
        ) : (
          <Typography
            variant="subtitle2"
            gutterBottom
            className={classes.subtitle}
          >
            Nenhum pacote encontrado
          </Typography>
        )
      ) : (
        <Typography
          variant="subtitle2"
          gutterBottom
          className={classes.subtitle}
        >
          Selecione o plano primeiro
        </Typography>
      )}
    </Card>
  );
}

export default PackageFlashSales;
