type LinkData = {
  filename: string;
  blob: Blob;
  type: string;
};

type CSVData = {
  filename: string;
  file: Blob;
};

type PDFData = {
  filename: string;
  blob: Blob;
};

export function createLink({
  filename,
  blob,
  type = 'application/pdf',
}: LinkData) {
  const blobPdf = new Blob([blob], { type });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { msSaveOrOpenBlob } = window.navigator;

  if (msSaveOrOpenBlob) {
    msSaveOrOpenBlob(blobPdf, filename);

    return undefined;
  }

  return window.URL.createObjectURL(blobPdf);
}

const { body } = document;

export function downloadXlsFile({ filename, file }: CSVData) {
  if (!body) throw new Error('Unexpectedly missing <body>.');
  const url = createLink({
    filename,
    blob: file,
    type: 'application/octet-stream',
  });

  const link = document.createElement('a');
  link.href = url || '';
  link.setAttribute('download', `${filename}.xlsx`);

  body.appendChild(link);
  link.click();
  body.removeChild(link);
}

export function downloadZipFile({ filename, file }: CSVData) {
  if (!body) throw new Error('Unexpectedly missing <body>.');
  const url = createLink({
    filename,
    blob: file,
    type: 'application/octet-stream',
  });

  const link = document.createElement('a');
  link.href = url || '';
  link.setAttribute('download', `${filename}.zip`);

  body.appendChild(link);
  link.click();
  body.removeChild(link);
}

export function downloadCsvFile({ filename, file }: CSVData) {
  if (!body) throw new Error('Unexpectedly missing <body>.');
  const url = createLink({
    filename,
    blob: file,
    type: 'text/csv',
  });

  const link = document.createElement('a');
  link.href = url || '';
  link.setAttribute('download', `${filename}.csv`);

  body.appendChild(link);
  link.click();
  body.removeChild(link);
}

export function downloadPdfFile({ filename, blob }: PDFData) {
  if (!document.body) {
    throw new Error('Element <body> is missing.');
  }

  const url = createLink({
    filename,
    blob,
    type: 'application/pdf',
  });

  const link = document.createElement('a');
  link.href = url || '';
  link.setAttribute('download', `${filename}.pdf`);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
