import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';
import ToastContainer from 'components/ToastContainer';

export interface ToastMessage {
  id: string;
  type?: 'success' | 'error' | 'info';
  title: string;
  description?: string;
}

interface ToastContextDataProps {
  addToast(message: Omit<ToastMessage, 'id'>): void;
  removeToast(id: string): void;
}
interface IToastProvider {
  children: ReactNode;
}

const ToashContext = createContext<ToastContextDataProps>(
  {} as ToastContextDataProps,
);

export function ToastProvider({ children }: IToastProvider) {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addToast = useCallback(
    ({ title, type, description }: Omit<ToastMessage, 'id'>) => {
      const id = uuid();
      const toast = { id, title, type, description };
      setMessages(state => [...state, toast]);
    },
    [],
  );
  const removeToast = useCallback((id: string) => {
    setMessages(state => state.filter(message => message.id !== id));
  }, []);

  const value = useMemo(
    () => ({
      addToast,
      removeToast,
    }),
    [addToast, removeToast],
  );

  return (
    <ToashContext.Provider value={value}>
      {children}
      <ToastContainer messages={messages} />
    </ToashContext.Provider>
  );
}

export const useToast = (): ToastContextDataProps => {
  const context = useContext(ToashContext);
  if (!context) {
    throw new Error('useToash must be used within an ToastProvider');
  }
  return context;
};
