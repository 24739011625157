import userSWR from 'swr';
import api from 'services/api';
import { OrderReport } from 'models/bugReport';

export const OrderReportFetch = (
  start: string,
  end: string,
  onlyOrderRequests: boolean,
) => {
  const { data, mutate } = userSWR<OrderReport>(
    `orderFetch_${start}_${end}_${onlyOrderRequests}`,
    async (): Promise<OrderReport> => {
      const params = {
        inicio: start,
        fim: end,
        only_order_requests: onlyOrderRequests,
      };
      const { data: result } = await api.get(`core/chamados/agrupados`, {
        params,
      });
      return result as OrderReport;
    },
  );
  return { data, mutate };
};
