import {
  Modal,
  Backdrop,
  Fade,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { Hour } from 'models/affiliateGoalByDate';
import { useState } from 'react';
import { AffiliateGoalDefaultParametersFetch } from 'fetches/affiliateGoalDefaultParametersFetch';
import { useStylesModal } from 'components/UseStylesModal';
import { ModalCard, TextModal } from './styles';

interface DefaultParameters {
  hours: Hour;
  isNotEditHour: boolean;
  setHours(hours: Hour): void;
  setTotalGoal(totalGoal: string): void;
}

function DefaultParameters({
  hours,
  isNotEditHour,
  setHours,
  setTotalGoal,
}: DefaultParameters) {
  const [open, setOpen] = useState(false);

  const { data: defaultParameters } = AffiliateGoalDefaultParametersFetch();

  const classes = useStylesModal();

  const handleClose = () => {
    setOpen(false);
  };

  const updateHoursWithDefaults = (hour: Hour) => {
    const updatedHours = { ...hour };
    Object.keys(updatedHours).forEach(hourKey => {
      const hourIndex = parseInt(hourKey, 10);
      const defaultParametersValue = defaultParameters
        ? defaultParameters.parametros_padroes[hourIndex]
        : 0;

      const isNotEdit = hourIndex < new Date().getHours();
      const value =
        isNotEdit && isNotEditHour
          ? updatedHours[hourIndex.toString()]
          : defaultParametersValue?.toString();

      updatedHours[hourIndex.toString()] = value;
    });

    const defaultMeta = defaultParameters ? defaultParameters.meta_padrao : 0;

    setTotalGoal(String(defaultMeta));
    setHours(updatedHours);
    handleClose();
  };

  return (
    <Box>
      <Button variant="contained" fullWidth onClick={() => setOpen(true)}>
        Parâmetros padrões
      </Button>
      <Modal
        aria-labelledby="tile-modal"
        aria-describedby="modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={open}>
          <ModalCard>
            <TextModal>
              Você realmente deseja definir os parâmetros padrões para todos os
              campos? Essa ação não pode ser desfeita.
            </TextModal>
            <DialogActions className={classes.dialogActions}>
              <Button
                className={classes.buttonCancel}
                variant="outlined"
                onClick={() => setOpen(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={() => updateHoursWithDefaults(hours)}
                color="primary"
              >
                Confirmar
              </Button>
            </DialogActions>
          </ModalCard>
        </Fade>
      </Modal>
    </Box>
  );
}

export default DefaultParameters;
