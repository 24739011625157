import useSWR from 'swr';
import api from 'services/api';
import { AffiliateBase, AffiliateFetchResponse } from 'models/affiliates';

interface FetchProps {
  page?: number;
}

export const AffiliatesFetch = ({ page }: FetchProps) => {
  const { data, mutate } = useSWR<AffiliateFetchResponse>(
    `affiliates_${page}`,
    async (): Promise<AffiliateFetchResponse> => {
      const response = await api.get(`/core/afiliados?page=${page}`);
      const paginationInfo = {
        currentPage: parseInt(response.headers['x-current-page'], 10),
        lastPage: parseInt(response.headers['x-last-page'], 10),
        perPage: parseInt(response.headers['x-per-page'], 10),
        total: parseInt(response.headers['x-total-count'], 10),
      };
      const affiliates = response.data as AffiliateBase[];

      return { affiliates, paginationInfo } as AffiliateFetchResponse;
    },
  );
  return { data, mutate };
};
