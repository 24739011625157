import { useEffect, useState } from 'react';
import { Button, CardContent, CardHeader } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Card, Header } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import palette from 'theme/palette';
import { PlansFetch } from 'fetches/plansFetch';
import { TopBar } from 'components/CardList';
import { useAuth } from 'hooks/auth';
import { planosSincronizar } from 'constants/permissions';
import PlanList from './PlanList';
import Filters from './Filters';
import SynchronizePlans from './SynchronizePlans';

function ManagerPlan() {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const [search, setSearch] = useState('');
  const [segmento, setSegmento] = useState('');
  const [status, setStatus] = useState('all');
  const [showSynchronizePlans, setShowSynchronizePlans] = useState(false);
  const [showButtonPlanSync, setShowButtonPlanSync] = useState(false);

  const { hasPermission } = useAuth();

  useEffect(() => {
    const shouldShow = hasPermission(planosSincronizar);

    setShowButtonPlanSync(shouldShow);
  }, [hasPermission]);

  const { data: plansResponse, mutate } = PlansFetch({
    search,
    segmento,
    status,
  });

  return (
    <Card>
      <Header>
        <CardHeader
          title="Gestão de planos"
          subheader="Painel de informações dos planos e ofertas TIM."
        />

        {showButtonPlanSync && (
          <>
            <Button
              color="primary"
              variant="contained"
              style={{
                backgroundColor: palette.primary.main,
                color: palette.white,
              }}
              onClick={() => setShowSynchronizePlans(true)}
            >
              {isTableOrLess ? (
                <Add style={{ color: palette.white }} />
              ) : (
                'Sincronizar planos'
              )}
            </Button>
            <SynchronizePlans
              showSynchronizePlans={showSynchronizePlans}
              setShowSynchronizePlans={setShowSynchronizePlans}
            />
          </>
        )}
      </Header>

      <CardContent>
        <TopBar isDesktop={!isTableOrLess}>
          <CardHeader
            style={{ padding: '0px 0px 15px 0px' }}
            title="Listagem de planos disponíveis"
          />
          <Filters
            status={status}
            segmento={segmento}
            setStatus={setStatus}
            setSegmento={setSegmento}
            setSearch={setSearch}
          />
        </TopBar>
        <PlanList plans={plansResponse} updatePlans={mutate} />
      </CardContent>
    </Card>
  );
}

export default ManagerPlan;
