import { List } from 'components/StyledComponents';
import { Container } from 'components/CardList';
import useBreakpoint from 'hooks/useBreakpoint';
import { TotalizatorByMediaType } from 'models/reports';
import { IndicatorsProps } from 'pages/ManagementReport/reports/TotalByMedia';
import Item from './Item';
import MobileItem from './MobileItem';
import { Header, HeaderTitle } from './styles';
import Total from './Total';

interface UtmDistributionListProps {
  utms?: TotalizatorByMediaType;
  indicators: IndicatorsProps;
}

interface LoadingListProps {
  isTableOrLess: boolean;
}

interface BodyProps {
  utms: TotalizatorByMediaType;
  isMobile: boolean;
  indicators: IndicatorsProps;
}
function LoadingList({ isTableOrLess }: LoadingListProps) {
  return isTableOrLess ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({ utms, isMobile, indicators }: BodyProps) {
  const utmsMediaSorted = utms.medias.slice().sort((a, b) => {
    const sourceA = a.source.toUpperCase();
    const sourceB = b.source.toUpperCase();
    return sourceA.localeCompare(sourceB);
  });

  return (
    <List>
      {utmsMediaSorted?.length ? (
        utmsMediaSorted.map(data =>
          isMobile ? (
            <MobileItem key={data.source} utm={data} />
          ) : (
            <Item key={data.source} utm={data} />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhuma utm encontrada</h3>
      )}
      {utmsMediaSorted?.length > 0 &&
        indicators.indicators.map((indicator, index) => (
          <Total
            key={indicator.total}
            indicator={indicator}
            index={index}
            isMobile={isMobile}
          />
        ))}
    </List>
  );
}

function UtmDistributionList({ utms, indicators }: UtmDistributionListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Container>
      {!isTableOrLess && (
        <Header>
          <HeaderTitle>Mídia</HeaderTitle>
          <div>
            <HeaderTitle>Pedidos</HeaderTitle>
            <HeaderTitle>Ativados</HeaderTitle>
            <HeaderTitle>Conversão</HeaderTitle>
            <HeaderTitle>Participação</HeaderTitle>
          </div>
        </Header>
      )}
      {utms ? (
        <Body utms={utms} isMobile={isTableOrLess} indicators={indicators} />
      ) : (
        <LoadingList isTableOrLess={isTableOrLess} />
      )}
    </Container>
  );
}

export default UtmDistributionList;
