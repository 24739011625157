import { ReactComponent as StarsIcons } from 'assets/icons/small/average.svg';
import { Content, Title, Total } from './styles';

interface CardStarProps {
  starCount: string;
  total?: string;
}

function CardStar({ starCount, total }: CardStarProps) {
  return (
    <Content>
      <Title>{starCount}</Title>
      <StarsIcons />
      <Total variant="h3">{total || '-'}</Total>
      <Title>avaliações</Title>
    </Content>
  );
}

export default CardStar;
