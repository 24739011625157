import styled from 'styled-components';

export const TitlePackage = styled.p`
  display: flex;
  align-items: center;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #546e7a;
`;

export const CardNoPackage = styled.div`
  box-shadow: inset 0px -1px 0px rgb(0 0 0 / 5%);
  padding: 16px;
  font-size: 16px;
`;
