import { AntSwitch } from 'components/Switch';
import { useToast } from 'hooks/toast';
import { Plan } from 'models/plans';
import { useCallback } from 'react';
import api from 'services/api';

interface FlagToggleProps {
  data: Plan;
  toggleValue: string;
  toggleName: string;
  toggleState: boolean;
  setToggleState(value: boolean): void;
  updatePlan?(data: Plan): void;
}

function FlagToggle({
  data,
  toggleValue,
  toggleName,
  toggleState,
  setToggleState,
  updatePlan,
}: FlagToggleProps) {
  const { addToast } = useToast();

  const handleToggle = useCallback(() => {
    const dataResponse = {
      [toggleValue]: !toggleState,
    };
    api
      .put(`/core/planos/atualizar-detalhe/${data?.id}`, dataResponse)
      .then(({ data: response }) => {
        updatePlan && updatePlan({ ...data, ...response });
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: `${toggleName} ${
            !toggleState ? 'ativado' : 'desativado'
          } com sucesso`,
        });
      })
      .catch(error => {
        let { message } = error.data;

        if (!message) {
          message = error.data.errors[0]?.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro!',
            description: message,
          });
        setToggleState(!!toggleState);
      });
  }, [
    toggleValue,
    data,
    updatePlan,
    addToast,
    toggleName,
    toggleState,
    setToggleState,
  ]);

  const handleChangeToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToggleState(event.target.checked);
    handleToggle();
  };

  return (
    <AntSwitch
      size="medium"
      checked={toggleState}
      onChange={handleChangeToggle}
      color="secondary"
    />
  );
}

export default FlagToggle;
