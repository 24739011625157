import styled from 'styled-components';

interface ContainerProps {
  isDesktop: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.isDesktop ? 'flex-end' : 'flex-start')};
  flex-wrap: wrap;
`;

export const Item = styled.div`
  color: black;
  float: left;
  padding: 8px 16px;
  border-radius: 3px;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 5px;
`;

export const ActionButtonPagination = styled.button.attrs(() => ({
  type: 'button',
}))`
  font-size: 14px;
  line-height: 24px;
  border: none;
  background: transparent;

  color: rgba(0, 0, 0, 0.8);
  margin: 0 10px;
  cursor: pointer;
`;

export const InforQuantityItems = styled.span`
  margin-left: 20px;
  font-size: 14px;
  line-height: 24px;

  color: rgba(0, 0, 0, 0.4);
`;
