import styled from 'styled-components';

export const CardContent = styled.div`
  height: 100%;
  border-radius: 3px;
  border: 1px solid rgba(233, 233, 238, 1);
  margin: 15px;
  padding: 15px 2px 15px 15px;
`;

export const Title = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: rgba(0, 0, 0, 0.4);
`;

export const Text = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 4px;
`;

export const TextPreviousPrice = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  color: #37474f99;
  margin-top: 4px;
`;

export const Data = styled.div`
  margin: 7px 8px;
  height: 32px;
  width: 50px;
  left: 0px;
  top: 0px;
  border-radius: 20px;
  padding: 4px 16px;
  background: rgba(5, 12, 39, 0.6);
`;

export const TextData = styled.span`
  color: rgba(245, 252, 255, 1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;
