import { Box, IconButton } from '@mui/material';
import styled from 'styled-components';
import palette from 'theme/palette';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MobileFilterButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 6px;
  background-color: ${palette.primary.main};
`;

export const BoxHeader = styled(Box)`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1023px) {
    margin: 30px 0px 15px 7px;
    align-items: center;
  }
`;

export const BoxDescriptionType = styled(Box)`
  display: flex;
  align-items: start;
  gap: 5px;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;
