import Skeleton from '@mui/material/Skeleton';
import { IconButton } from '@mui/material';
import {
  MobileContainer,
  MobileData,
  MobileDataLabel,
} from 'components/StyledComponents';
import { MobileDataTextName, MobileDataText, Row } from 'components/MobileList';
import Default from 'assets/icons/benefitIcons/default.svg';
import benefit4GInternet from 'assets/icons/benefitIcons/benefit4GInternet.svg';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useBenefitStore } from 'store/benefitStore';
import { FiEdit } from 'react-icons/fi';
import { IconsComponent } from '../Item/styles';
import Icons from '../icons';
import { Actions } from './styles';
import { ItemProps } from '../Item';

function MobileItem({
  plan_id,
  data,
  typeBenefit,
  loading = false,
  setBenefitSelected,
  handleRemove,
}: ItemProps) {
  const setShowBenefitEdit = useBenefitStore(state => state.setShowBenefitEdit);
  const setType = useBenefitStore(state => state.setType);

  return (
    <MobileContainer
      onClick={() => {
        setBenefitSelected && data && setBenefitSelected(data);
        setShowBenefitEdit(true);
        typeBenefit && setType(typeBenefit);
      }}
    >
      <Row>
        <MobileData>
          <MobileDataLabel>Componente</MobileDataLabel>
          <IconsComponent>
            {data &&
              (data.descricao === 'Pacote de internet' ? (
                <img src={benefit4GInternet} alt="componente" height={24} />
              ) : (
                <img
                  src={data.componente ? Icons[data.componente] : Default}
                  alt="componente"
                  height={data.componente && 24}
                />
              ))}
          </IconsComponent>
        </MobileData>
      </Row>
      <MobileData>
        <MobileDataLabel>Nome</MobileDataLabel>
        <MobileDataTextName>
          {loading ? <Skeleton width={150} /> : data?.descricao}
        </MobileDataTextName>
      </MobileData>
      <Row>
        <MobileData>
          <MobileDataLabel>Quantidade</MobileDataLabel>
          <MobileDataText>
            {loading ? <Skeleton width={80} /> : data?.quantidade}
          </MobileDataText>
        </MobileData>
      </Row>
      <Actions>
        <IconButton>
          <FiEdit style={{ width: 18, height: 18, color: ' #000000' }} />
        </IconButton>
        {plan_id && (
          <IconButton
            onClick={e => {
              e.stopPropagation();
              handleRemove && data && handleRemove(data.id);
            }}
          >
            <RiDeleteBinLine
              style={{ width: 18, height: 18, color: ' #000000' }}
            />
          </IconButton>
        )}
      </Actions>
    </MobileContainer>
  );
}

export default MobileItem;
