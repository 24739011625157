import styled from 'styled-components';
import palette from 'theme/palette';

interface BoxProps {
  isDesktop: boolean;
}
export const Box = styled.div<BoxProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  margin: ${props => (props.isDesktop ? '16px 0px 20px 20px' : '16px')};
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 6px;
  width: ${props => (props.isDesktop ? '261px' : '100%')};
`;

export const Description = styled.div`
  font-size: 12px;
  color: rgba(5, 12, 39, 0.6);
`;

export const Value = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${palette.primary.main};
`;
