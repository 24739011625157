import TotalizerCard from 'components/TotalizerCard';
import { WorkersRunningFetch } from '../../../../fetches/workersRunningFetch';

interface WorkersRunningProps {
  isDesktop: boolean;
  processName: string;
}

function WorkersRunning({ isDesktop, processName }: WorkersRunningProps) {
  const { data: count } = WorkersRunningFetch(processName);

  return (
    <TotalizerCard
      key="workers-running"
      title="Workers Rodando"
      total={count?.total || 0}
      isDesktop={isDesktop}
    />
  );
}

export default WorkersRunning;
