import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 15px;
  width: 100%;
  margin-bottom: 30px;
`;

export const Infos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .Button {
    padding: 5px;
    margin-bottom: auto;
  }
`;
export const Divider = styled.div`
  width: 100%;
  padding: 0 16px;
  border-top: 1px solid #f2f2f2;
`;
export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const DataColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Data = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;
export const DataLabel = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.4);
`;
export const DataInfo = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: rgba(0, 0, 0, 0.8);
`;
