import { Typography } from '@mui/material';
import { SwitchIOS } from 'components/SwitchIOS';
import { useStyles, Card, Container } from './styles';

interface IntegrationErrorSwitchProps {
  label: string;
  checked: boolean;
  setChecked(checked: boolean): void;
}

function IntegrationErrorSwitch({
  label,
  checked,
  setChecked,
}: IntegrationErrorSwitchProps) {
  const classes = useStyles();

  return (
    <Container>
      <Typography>{label}</Typography>
      <Card className={checked ? classes.switchCardCheckedOn : ''}>
        <Typography className={checked ? classes.labelSwitchChecked : ''}>
          Não
        </Typography>
        <SwitchIOS
          size="medium"
          color="secondary"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
        <Typography className={!checked ? classes.labelSwitchChecked : ''}>
          Sim
        </Typography>
      </Card>
    </Container>
  );
}

export default IntegrationErrorSwitch;
