import { Box, IconButton, Popover } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import reasonCardSetup from './reasonCardSetup';
import { Card } from './styles';

interface ReasonCardProps {
  reasons: string[];
  curadoriaId: number;
}

function ReasonCard({ reasons, curadoriaId }: ReasonCardProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
    >
      <Card bgcolor={reasonCardSetup[reasons[0]]?.color}>
        {reasonCardSetup[reasons[0]]?.label || reasons[0]}
      </Card>
      {reasons.length > 1 && (
        <>
          <IconButton onClick={handleClick}>
            <MoreHoriz />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              padding="10px"
            >
              {reasons.map(reason => (
                <Box key={`${curadoriaId}_${reason}`} marginBottom="8px">
                  <Card
                    bgcolor={reasonCardSetup[reason]?.color}
                    onClick={() =>
                      curadoriaId &&
                      navigate(`/curadoria-fraude/${curadoriaId}`)
                    }
                  >
                    {reasonCardSetup[reason]?.label || reason}
                  </Card>
                </Box>
              ))}
            </Box>
          </Popover>
        </>
      )}
    </Box>
  );
}

export default ReasonCard;
