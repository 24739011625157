import { Grid, Skeleton } from '@mui/material';
import {
  CardOffers,
  ContainerOffers,
  TitleOffers,
} from 'pages/CmsLP/Offers/styles';

function LoadingListOffers() {
  return (
    <ContainerOffers>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} xs={12}>
          <CardOffers>
            <TitleOffers>Melhor Oferta</TitleOffers>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
          </CardOffers>
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <CardOffers>
            <TitleOffers>Demais ofertas</TitleOffers>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
          </CardOffers>
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <CardOffers>
            <TitleOffers>Whatsapp</TitleOffers>
            <Skeleton width="100%" />
          </CardOffers>
        </Grid>
      </Grid>
    </ContainerOffers>
  );
}

export default LoadingListOffers;
