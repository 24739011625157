import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { ButtonProgress } from 'components/StyledComponents';
import {
  BoxDDDs,
  BoxMarkButtons,
  ContentDrawer,
  DivCloseButton,
  Text,
  Title,
  useStyles,
} from './styles';

interface FormPriceProps {
  planName: string;
  currentPrice: string;
  previousPrice: string;
  DDDsArray: number[];
  dddsSelecteds: Record<number, boolean>;
  isDisabledDDDs: {
    [ddd: string]: boolean;
  };
  loading: boolean;
  setCurrentPrice(currentPrice: string): void;
  setPreviousPrice(previousPrice: string): void;
  changeDddsSelecteds(ddd: string): void;
  uncheckAllAreaCodes(): void;
  markAllAreaCodes(): void;
  savePriceDDDs(currentPrice: string, previousPrice: string): void;
  onClose(): void;
}

function FormPrice({
  planName,
  currentPrice,
  previousPrice,
  DDDsArray,
  dddsSelecteds,
  isDisabledDDDs,
  loading,
  setCurrentPrice,
  setPreviousPrice,
  changeDddsSelecteds,
  uncheckAllAreaCodes,
  markAllAreaCodes,
  savePriceDDDs,
  onClose,
}: FormPriceProps) {
  const classes = useStyles();
  return (
    <ContentDrawer>
      <DivCloseButton onClick={onClose}>
        <IconButton>
          <Close />
        </IconButton>
      </DivCloseButton>
      <Title>Plano</Title>
      <Text>{planName}</Text>
      <Box className={classes.boxPrices}>
        <TextField
          fullWidth
          type="number"
          size="small"
          label="Preço atual"
          variant="outlined"
          value={currentPrice}
          onChange={e => {
            setCurrentPrice(e.target.value);
          }}
          InputProps={{
            startAdornment: currentPrice && (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />

        <TextField
          fullWidth
          type="number"
          size="small"
          label="Preço anterior"
          variant="outlined"
          value={previousPrice}
          onChange={e => {
            setPreviousPrice(e.target.value);
          }}
          InputProps={{
            startAdornment: previousPrice && (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
      </Box>

      <Box>
        <Title>DDD´s</Title>
        <BoxDDDs>
          {DDDsArray &&
            DDDsArray.map(ddd => (
              <Button
                key={ddd}
                variant="contained"
                color={dddsSelecteds[ddd] ? 'primary' : 'inherit'}
                className={classes.buttonDDD}
                onClick={() => changeDddsSelecteds(ddd.toString())}
                disabled={isDisabledDDDs[ddd]}
              >
                {ddd}
              </Button>
            ))}
        </BoxDDDs>
      </Box>

      <BoxMarkButtons>
        <Button
          className={classes.deselectAllButton}
          variant="outlined"
          color="error"
          onClick={uncheckAllAreaCodes}
        >
          Desmarcar todos
        </Button>

        <Button
          className={classes.selectAllButton}
          variant="outlined"
          color="primary"
          onClick={markAllAreaCodes}
        >
          Marcar todos
        </Button>
      </BoxMarkButtons>

      <Button
        className={classes.saveButton}
        variant="contained"
        color="primary"
        onClick={() => {
          savePriceDDDs(currentPrice, previousPrice);
        }}
        disabled={loading}
      >
        Salvar
        {loading && <ButtonProgress size={24} />}
      </Button>
    </ContentDrawer>
  );
}

export default FormPrice;
