import styled from 'styled-components';

export const CardBenefitComposition = styled.div`
  display: flex;
  flex-direction: column;
  width: 270px;
  border-radius: 32px;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.8);
`;

export const CardBenefitHeader = styled.h1`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  padding: 32px;
  height: 84px;
`;

export const CardBenefitImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 270px;
  background-color: #002198;
`;

export const CardBenefitText = styled.div`
  align-items: center;
  text-align: left;
  padding: 32px;
  min-height: 164px;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;
