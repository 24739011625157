import styled from 'styled-components';

export const DataText = styled.span`
  width: 175px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
`;

export const DataInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 24px;
`;

export const DataTextName = styled.div`
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
`;
