import { Grid } from '@mui/material';
import useBreakpoint from 'hooks/useBreakpoint';
import { CategorySetup, Setup } from 'models/setup';
import SetupItem from '../SetupItem';

import {
  Card,
  CategoryContainer,
  CategoryHeader,
  CategoryItems,
  Container,
} from './styles';

interface BodyProps {
  categories: CategorySetup[];
  setShowSetupEdition(value: boolean): void;
  setSetupSelected(setupSelected: Setup): void;
}

interface ManageSetupListProps {
  categories?: CategorySetup[];

  setShowSetupEdition(value: boolean): void;

  setSetupSelected(setupSelected: Setup): void;
}

function LoadingList() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <SetupItem loading />
      </Grid>
      <Grid item xs={12} md={6}>
        <SetupItem loading />
      </Grid>
      <Grid item xs={12} md={4}>
        <SetupItem loading />
      </Grid>
      <Grid item xs={12} md={4}>
        <SetupItem loading />
      </Grid>
      <Grid item xs={12} md={4}>
        <SetupItem loading />
      </Grid>
    </Grid>
  );
}

function Body({
  categories,
  setShowSetupEdition,
  setSetupSelected,
}: BodyProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  return (
    <Card>
      <Container>
        {categories && categories.length > 0 ? (
          categories?.map(category => (
            <CategoryContainer key={category.id}>
              <CategoryHeader>{category.name}</CategoryHeader>
              <CategoryItems>
                <Grid container spacing={isTableOrLess ? 2 : category.columns}>
                  {category.setups.map(setup => (
                    <Grid
                      item
                      xs={isTableOrLess ? 12 : category.columns}
                      key={setup.id}
                      onClick={() => {
                        setShowSetupEdition(true);
                        setSetupSelected(setup);
                      }}
                    >
                      <SetupItem
                        title={setup.title}
                        value={setup.value}
                        description={setup.description}
                        typeVision={setup.vision_type}
                      />
                    </Grid>
                  ))}
                </Grid>
              </CategoryItems>
            </CategoryContainer>
          ))
        ) : (
          <h3 style={{ margin: 'auto', alignItems: 'center' }}>
            Nenhum setup encontrado
          </h3>
        )}
      </Container>
    </Card>
  );
}

function ManageSetupList({
  categories,
  setShowSetupEdition,
  setSetupSelected,
}: ManageSetupListProps) {
  return (
    <div>
      {categories ? (
        <Body
          categories={categories}
          setShowSetupEdition={setShowSetupEdition}
          setSetupSelected={setSetupSelected}
        />
      ) : (
        <LoadingList />
      )}
    </div>
  );
}

export default ManageSetupList;
