import { Button, CardHeader } from '@mui/material';
import { Header } from './styles';

interface HeaderListProps {
  title: string;

  buttonInfo?: string;

  setShowRegistrationDialog?(value: boolean): void;
}

function HeaderList({
  title,
  buttonInfo,
  setShowRegistrationDialog,
}: HeaderListProps) {
  return (
    <Header>
      <CardHeader title={title} />
      {buttonInfo && setShowRegistrationDialog && (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setShowRegistrationDialog(true);
          }}
        >
          {buttonInfo}
        </Button>
      )}
    </Header>
  );
}

export default HeaderList;
