import { Button, CardHeader } from '@mui/material';
import { Content } from 'components/CardList';
import { UtmConfigurationFetch } from 'fetches/utmConfigurationFetch';
import { Card, Header } from 'components/StyledComponents';
import palette from 'theme/palette';
import { Add } from '@mui/icons-material';
import useBreakpoint from 'hooks/useBreakpoint';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { UTMConfiguration } from 'models/utmConfiguration';
import UtmDefinitionList from './components/UtmDefinitionList';

function UtmDefinition() {
  const { data: utmConfigurations, mutate } = UtmConfigurationFetch();

  const params = useParams();
  const { id } = params;

  const [configurationSelected, setConfigurationSelected] =
    useState<UTMConfiguration[]>();

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const utmConfigurationSelected = utmConfigurations?.filter(
        utm => utm.id === parseInt(id, 10),
      );

      setConfigurationSelected(utmConfigurationSelected);
    }
  }, [id, utmConfigurations]);

  return (
    <Card>
      <Header>
        {configurationSelected ? (
          <CardHeader
            title="Edição de configurações"
            subheader="Sub-painel de edição das configurações de UTMs."
          />
        ) : (
          <CardHeader
            title="Definição de UTM"
            subheader="Painel de definição das configurações de UTMs."
          />
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate('/definicao-utm/cadastro-configuracao');
          }}
        >
          {isTableOrLess ? (
            <Add style={{ color: palette.white }} />
          ) : (
            'Nova configuração'
          )}
        </Button>
      </Header>
      <Content>
        {configurationSelected ? (
          <UtmDefinitionList
            utmConfigurations={configurationSelected}
            updateUtmConfigurations={mutate}
          />
        ) : (
          <UtmDefinitionList
            utmConfigurations={utmConfigurations}
            updateUtmConfigurations={mutate}
          />
        )}
      </Content>
    </Card>
  );
}

export default UtmDefinition;
