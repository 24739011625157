import { date as dateFormatter, hour as hourFormatter } from 'util/formatter';
import {
  Container,
  Divider,
  Data,
  DataText,
} from 'components/StyledComponents';
import { Checkbox, Skeleton } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Badge, DataInfo, DataTextName } from './style';
import { ContextInfo, getContextBadgeSetup } from '../contextBadgeSetup';
import { ItemProps } from '../types';

function Item({
  data,
  loading = false,
  setShowErrorInfo,
  setErrorSelected,
  selectedIDs,
  setSelectedIDs,
}: ItemProps) {
  const contextBadge = data
    ? getContextBadgeSetup(data.url)
    : ({} as ContextInfo);

  return (
    <Container>
      <Data
        onClick={() => {
          setShowErrorInfo && setShowErrorInfo(true);
          setErrorSelected && data && setErrorSelected(data);
        }}
      >
        {loading ? (
          <DataInfo>
            <DataText>
              <Skeleton width={50} />
            </DataText>
            <DataText>
              <Skeleton width={50} />
            </DataText>
            <DataText>
              <Skeleton width={90} />
            </DataText>
            <DataText>
              <Skeleton width={250} />
            </DataText>
          </DataInfo>
        ) : (
          data && (
            <DataInfo>
              <DataText>{hourFormatter(data.datetime)}</DataText>
              <DataText>{dateFormatter(data.datetime)}</DataText>
              <DataText>
                <Badge badgeColor={contextBadge.color}>
                  {contextBadge.label}
                </Badge>
              </DataText>
              <DataTextName>
                {data.response.message ? data.response.message : '-'}
              </DataTextName>
            </DataInfo>
          )
        )}
      </Data>
      {loading ? (
        <Skeleton width={20} />
      ) : (
        data &&
        selectedIDs &&
        setSelectedIDs && (
          <Checkbox
            value={selectedIDs[data.id]}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxOutlinedIcon color="primary" />}
            style={{ marginBottom: 15 }}
            onChange={e => {
              setSelectedIDs({
                ...selectedIDs,
                [data.id]: e.target.checked,
              });
            }}
          />
        )
      )}
      <Divider />
    </Container>
  );
}

export default Item;
