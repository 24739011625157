import { ButtonProgress, ContainerForm } from 'components/StyledComponents';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import TextInfo from 'pages/BugReport/ErrorInfo/TextInfo';
import { TextLink } from './styles';

interface GetLinkSerasaProps {
  linkSerasa: string;
  loadingGetLinkSerasa: boolean;
  showGetLinkSerasa: boolean;
  setShowGetLinkSerasa(value: boolean): void;
  handleGetLinkSerasa(): void;
}

function GetLinkSerasa({
  linkSerasa,
  loadingGetLinkSerasa,
  showGetLinkSerasa,
  setShowGetLinkSerasa,
  handleGetLinkSerasa,
}: GetLinkSerasaProps) {
  return (
    <ContainerForm>
      <Button
        type="button"
        variant="contained"
        color="primary"
        disabled={loadingGetLinkSerasa}
        fullWidth
        size="large"
        onClick={() => handleGetLinkSerasa()}
      >
        Obter link SERASA
        {loadingGetLinkSerasa && <ButtonProgress size={24} />}
      </Button>

      <Dialog
        open={showGetLinkSerasa}
        keepMounted
        onClose={() => setShowGetLinkSerasa(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <TextLink>
            <DialogContentText id="alert-dialog-slide-description">
              {linkSerasa}
            </DialogContentText>
            <div>
              <TextInfo label="Copiar link" screenshot={linkSerasa} row />
            </div>
          </TextLink>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowGetLinkSerasa(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </ContainerForm>
  );
}

export default GetLinkSerasa;
