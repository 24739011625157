import { Box, IconButton as MuiIconButton } from '@mui/material';
import styled from 'styled-components';

export const ContentDrawer = styled.div`
  max-width: 596px;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin: 61px 72px 50px 40px;
  justify-content: space-between;
  gap: 30px;

  @media (max-width: 1023px) {
    margin: 61px 45px 50px 40px;
  }
`;

export const BoxClose = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: right;
`;

export const IconButton = styled(MuiIconButton)`
  display: flex;
  margin-right: -40px;
  justify-content: end;

  @media (max-width: 1023px) {
    margin-right: -10px;
  }
`;

export const DataText = styled.span`
  min-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.4);
`;

export const DataTextName = styled.span`
  min-width: 150px;
  display: flex;
  font-size: 15px;
  align-items: center;
`;

export const BoxRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
  gap: 30px;

  @media (max-width: 1023px) {
    gap: 17px;
    flex-direction: column;
  }
`;

export const BoxColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;
`;
