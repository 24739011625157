import { Card, CardContent, CardHeader } from '@mui/material';
import { Header } from 'components/StyledComponents';
import { ManualPDFFetch } from 'fetches/manualPDFFetch';
import { useStyles } from './styles';

function Manual() {
  const classes = useStyles();
  const { data: pdfFetchResponse } = ManualPDFFetch();

  return (
    <Card className={classes.card}>
      <Header className={classes.header}>
        <CardHeader
          title="Visualizar Manual"
          subheader="Manual de instruções Admin"
        />
      </Header>
      <CardContent className={classes.cardContent}>
        {pdfFetchResponse ? (
          <embed
            src={pdfFetchResponse}
            type="application/pdf"
            width="100%"
            height="100%"
          />
        ) : (
          <p>Carregando PDF...</p>
        )}
      </CardContent>
    </Card>
  );
}
export default Manual;
