import { Box, Button } from '@mui/material';
import { LocalShipping } from '@mui/icons-material/';
import React from 'react';

interface OpenTrackingProps {
  trackingURL: string;
  noHorizontalMargin?: boolean;
}

function OpenTracking({
  trackingURL,
  noHorizontalMargin = false,
}: OpenTrackingProps) {
  const openTracking = () => window.open(trackingURL, '_blank');

  return (
    <Box margin={noHorizontalMargin ? '0' : '15px 10px'}>
      <Button
        type="button"
        color="secondary"
        fullWidth
        size="large"
        variant="outlined"
        style={{ padding: noHorizontalMargin ? '10px 0' : '10px' }}
        onClick={openTracking}
        startIcon={<LocalShipping />}
      >
        Acompanhar o pedido
      </Button>
    </Box>
  );
}

export default OpenTracking;
