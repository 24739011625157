import create from 'zustand';

type BenefitStore = {
  showBenefitEdit: boolean;
  setShowBenefitEdit(showBenefitEdit: boolean): void;
  type: string;
  setType(type: string): void;
};

export const useBenefitStore = create<BenefitStore>(set => ({
  showBenefitEdit: false,
  setShowBenefitEdit(show: boolean) {
    set(state => ({ ...state, showBenefitEdit: show }));
  },
  type: '',
  setType(type: string) {
    set(state => ({ ...state, type }));
  },
}));
