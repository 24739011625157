import useBreakpoint from 'hooks/useBreakpoint';
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { MenuProps, useStyles } from '../styles';
import {
  segmentOptions,
  statusOptions,
  typeOptions,
  ufOptions,
} from '../options';
import { useSelects } from './hooks/useSelects';

export interface SelectOptions {
  value: string;
  text: string;
}

function Selects() {
  const {
    tiposSelecionados,
    segmentosSelecionados,
    statusSelecionados,
    ufsSelecionadas,
    handleCheckType,
    handleCheckSegment,
    handleCheckStatus,
    handleCheckUf,
  } = useSelects();

  const classes = useStyles();

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <>
      <FormControl
        variant="outlined"
        margin="none"
        size="small"
        fullWidth={isTableOrLess}
      >
        <InputLabel>Tipo</InputLabel>
        <Select
          labelId="tipo"
          id="tipo"
          multiple
          value={tiposSelecionados}
          input={<OutlinedInput id="select-tipo" label="Tipo" />}
          renderValue={() => (
            <Box className={classes.chips}>
              {tiposSelecionados.map((value: SelectOptions) => (
                <Chip
                  key={value.value}
                  label={value.text}
                  className={classes.chip}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          <Box style={{ boxSizing: 'border-box' }}>
            <Box
              style={{
                display: 'grid',
                gridAutoColumns: '1fr',
                gridTemplateColumns: 'repeat(1, 1fr)',
              }}
            >
              {typeOptions.map((item: SelectOptions) => (
                <MenuItem
                  key={item.value}
                  value={item.text}
                  onClick={() => handleCheckType(item)}
                >
                  <Checkbox
                    checked={
                      !!tiposSelecionados.find(
                        selected => selected.value === item.value,
                      )
                    }
                  />
                  <ListItemText primary={`${item.text}`} />
                </MenuItem>
              ))}
            </Box>
          </Box>
        </Select>
      </FormControl>
      <FormControl
        variant="outlined"
        margin="none"
        size="small"
        fullWidth={isTableOrLess}
      >
        <InputLabel>Segmento</InputLabel>
        <Select
          labelId="segmento"
          id="segmento"
          multiple
          value={segmentosSelecionados}
          input={<OutlinedInput id="select-segmento" label="Segmento" />}
          renderValue={() => (
            <Box className={classes.chips}>
              {segmentosSelecionados.map((value: SelectOptions) => (
                <Chip
                  key={value.value}
                  label={value.text}
                  className={classes.chip}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          <Box style={{ boxSizing: 'border-box' }}>
            <Box
              style={{
                display: 'grid',
                gridAutoColumns: '1fr',
                gridTemplateColumns: 'repeat(1, 1fr)',
              }}
            >
              {segmentOptions.map((item: SelectOptions) => (
                <MenuItem
                  key={item.value}
                  value={item.text}
                  onClick={() => handleCheckSegment(item)}
                >
                  <Checkbox
                    checked={
                      !!segmentosSelecionados.find(
                        selected => selected.value === item.value,
                      )
                    }
                  />
                  <ListItemText primary={`${item.text}`} />
                </MenuItem>
              ))}
            </Box>
          </Box>
        </Select>
      </FormControl>
      <FormControl
        variant="outlined"
        margin="none"
        size="small"
        fullWidth={isTableOrLess}
      >
        <InputLabel>Status</InputLabel>
        <Select
          labelId="status"
          id="status"
          multiple
          value={statusSelecionados}
          input={<OutlinedInput id="select-status" label="Status" />}
          renderValue={() => (
            <Box className={classes.chips}>
              {statusSelecionados.map((value: SelectOptions) => (
                <Chip
                  key={value.value}
                  label={value.text}
                  className={classes.chip}
                />
              ))}
            </Box>
          )}
        >
          {statusOptions.map((item: SelectOptions) => (
            <MenuItem
              key={item.value}
              value={item.text}
              onClick={() => handleCheckStatus(item)}
            >
              <Checkbox
                checked={
                  !!statusSelecionados.find(
                    selected => selected.value === item.value,
                  )
                }
              />
              <ListItemText primary={`${item.text}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        variant="outlined"
        margin="none"
        size="small"
        fullWidth={isTableOrLess}
      >
        <InputLabel>UF</InputLabel>
        <Select
          labelId="uf"
          id="uf"
          multiple
          value={ufsSelecionadas}
          input={<OutlinedInput id="select-uf" label="UF" />}
          renderValue={() => (
            <Box className={classes.chips}>
              {ufsSelecionadas.slice(0, 2).map((value: SelectOptions) => (
                <Chip
                  key={value.value}
                  label={value.value}
                  className={classes.chip}
                />
              ))}
              {ufsSelecionadas.length > 2 && (
                <Chip label={`+ ${ufsSelecionadas.length - 2}`} />
              )}
            </Box>
          )}
        >
          {ufOptions.map((value: SelectOptions) => (
            <MenuItem
              key={value.value}
              value={value.value}
              onClick={() => handleCheckUf(value)}
            >
              <Checkbox
                checked={
                  !!ufsSelecionadas.find(item => item.value === value.value)
                }
              />
              <ListItemText primary={`${value.value} - ${value.text}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default Selects;
