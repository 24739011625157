import styled from 'styled-components';

export const HeaderFAQList = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 565px 15px 38px;
  color: rgba(0, 0, 0, 0.4);

  span {
    :nth-child(1) {
      width: 150px;
    }
    min-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    align-self: flex-start;
  }
`;
