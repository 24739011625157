import { CardHeader, FormControl, InputLabel, Select } from '@mui/material';

import React from 'react';

import useBreakpoint from 'hooks/useBreakpoint';
import { Container, FilterMargin, SubHeader } from './styles';

interface FiltersProps {
  reason: string;
  setReason(reason: string): void;
}

function Filters({ reason, setReason }: FiltersProps) {
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  return (
    <SubHeader>
      <CardHeader title="Contestações" />
      <Container>
        <FilterMargin>
          <FormControl
            variant="outlined"
            margin="none"
            size="small"
            fullWidth={isTabletSizeOrSmaller}
          >
            <InputLabel htmlFor="reason" focused>
              Motivo
            </InputLabel>
            <Select
              native
              value={reason}
              onChange={e => setReason(e.target.value as string)}
              label="Motivo"
            >
              <option value="todos">Todos</option>
              <option value="cpf">CPF</option>
              <option value="endereco">Endereço</option>
              <option value="email">E-mail</option>
              <option value="telefone">Telefone</option>
              <option value="funcionario_tim">Funcionários TIM</option>
            </Select>
          </FormControl>
        </FilterMargin>
      </Container>
    </SubHeader>
  );
}

export default Filters;
