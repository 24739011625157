import styled from 'styled-components';

interface TextBoxBaseProps {
  color?: 'leads' | 'vendas';
}

export const TitleBase = styled.h1`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: rgba(5, 12, 39, 1);
  height: 24px;
`;

export const BoxBase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  width: 100%;
  border: 1px solid rgba(218, 221, 236, 1);
  text-align: left;
  gap: 4px;
  padding: 15px;
  border-radius: 6px;
`;

export const TitleBoxBase = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.4);
`;

export const TextBoxBase = styled.p<TextBoxBaseProps>`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: ${props =>
    (props.color === 'leads' && 'rgba(58, 100, 206, 1)') ||
    (props.color === 'vendas' && 'rgba(92, 148, 13, 1)') ||
    'rgba(5, 12, 39, 1)'};
`;

export const DividerBase = styled.div`
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 40px 15px 38px;
  color: rgba(0, 0, 0, 0.4);
  span {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    align-self: flex-start;
  }
`;
