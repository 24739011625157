import FormPrice from '../FormPrice';
import { usePrice } from '../hooks/usePrice';

interface EditPriceProps {
  priceDDDsSelecteds?: string[];
  oldCurrentPrice: string;
  oldPreviousPrice: string;
  setShowDrawerEditGroupPrice(showDrawerEditGroupPrice: boolean): void;
  updatePriceGroups?(): void;
}

function EditPrice({
  priceDDDsSelecteds,
  oldCurrentPrice,
  oldPreviousPrice,
  setShowDrawerEditGroupPrice,
  updatePriceGroups,
}: EditPriceProps) {
  const {
    planStore,
    currentPrice,
    previousPrice,
    DDDsArray,
    dddsSelecteds,
    isDisabledDDDs,
    loading,
    setCurrentPrice,
    setPreviousPrice,
    changeDddsSelecteds,
    uncheckAllAreaCodes,
    markAllAreaCodes,
    updatePriceDDDs,
    handleClose,
  } = usePrice({
    priceDDDsSelecteds,
    oldCurrentPrice,
    oldPreviousPrice,
    setShowDrawerEditGroupPrice,
    updatePriceGroups,
  });

  return (
    <div>
      {planStore && (
        <FormPrice
          planName={planStore.nome}
          currentPrice={currentPrice}
          previousPrice={previousPrice}
          DDDsArray={DDDsArray}
          dddsSelecteds={dddsSelecteds}
          isDisabledDDDs={isDisabledDDDs}
          loading={loading}
          setCurrentPrice={setCurrentPrice}
          setPreviousPrice={setPreviousPrice}
          changeDddsSelecteds={changeDddsSelecteds}
          uncheckAllAreaCodes={uncheckAllAreaCodes}
          markAllAreaCodes={markAllAreaCodes}
          savePriceDDDs={updatePriceDDDs}
          onClose={handleClose}
        />
      )}
    </div>
  );
}

export default EditPrice;
