import React, { useCallback, useState } from 'react';
import { Button, Popper } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { Actions, UseStyles } from 'components/PopperActions';

interface PopperActionsProps {
  id: string;
  open: boolean;
  anchorElement: null | HTMLElement;
  handleClick(event: React.MouseEvent<HTMLElement>): void;
  setShowEditFormMobile(value: boolean): void;
}

function PopperActions({
  id,
  open,
  anchorElement,
  handleClick,
  setShowEditFormMobile,
}: PopperActionsProps) {
  const classes = UseStyles();
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  const handleEdit = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      setShowEditFormMobile(true);
      handleClick(e);
    },
    [handleClick, setShowEditFormMobile],
  );

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorElement}
      placement="bottom"
      modifiers={[
        {
          name: 'flip',
          enabled: true,
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            boundariesElement: 'scrollParent',
          },
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      className={classes.popper}
    >
      <span className={classes.arrow} ref={setArrowRef} />
      <Actions className={classes.paper} style={{ height: 60 }}>
        <Button onClick={handleEdit} startIcon={<Edit />} fullWidth>
          Editar
        </Button>
      </Actions>
    </Popper>
  );
}

export default PopperActions;
