import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { format, getYear } from 'date-fns';
import { useState } from 'react';
import { useGoalSelectStore } from 'store/goalSelectStore';
import { months, startYear } from './configFilters';
import { FilterMargin } from './styles';

interface FiltersProps {
  monthSelected: string;

  setMonthSelected(month: string): void;

  yearSelected: string;

  setYearSelected(yearSelected: string): void;
}

function Filters({
  monthSelected,
  setMonthSelected,
  yearSelected,
  setYearSelected,
}: FiltersProps) {
  const setSelectedGoalsCheckbox = useGoalSelectStore(
    store => store.setSelectedGoalsCheckbox,
  );
  const setHasSelected = useGoalSelectStore(store => store.setHasSelected);

  const [localMonthSelected, setLocalMonthSelected] = useState(monthSelected);
  const [localYearSelected, setLocalYearSelected] = useState(yearSelected);

  const currentYear = getYear(new Date());
  const years = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }

  const onFilter = () => {
    setMonthSelected(localMonthSelected);
    setYearSelected(localYearSelected);
    setSelectedGoalsCheckbox([]);
    setHasSelected(false);
  };

  return (
    <FilterMargin>
      <FormControl sx={{ m: 1, minWidth: 170 }} size="small" margin="none">
        <InputLabel id="mes">Mês</InputLabel>
        <Select
          labelId="mes"
          id="mes"
          value={localMonthSelected}
          label="Mês"
          onChange={event => setLocalMonthSelected(event.target.value)}
        >
          {months.map(mes => (
            <MenuItem key={mes.value} value={mes.value}>
              {mes.mes}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 170 }} size="small" margin="none">
        <InputLabel id="ano">Ano</InputLabel>
        <Select
          labelId="ano"
          id="ano"
          value={localYearSelected}
          label="Ano"
          onChange={event => {
            const selectedYear = String(event.target.value);
            setLocalYearSelected(selectedYear);
          }}
        >
          {years.map(year => (
            <MenuItem key={year} value={year}>
              {format(new Date(year, 0, 1), 'yyyy')}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button onClick={onFilter}>Selecionar</Button>
    </FilterMargin>
  );
}
export default Filters;
