import styled, { css } from 'styled-components';
import palette from 'theme/palette';

interface ButtonFilterProps {
  actived: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px 0px 20px 20px;

  @media (max-width: 1023px) {
    width: 100%;
    justify-content: space-between;
    padding: 0 0px 20px 20px;
    gap: 10px;
  }
`;

export const ButtonFilter = styled.button.attrs({
  type: 'button', // eslint-disable-next-line
}) <ButtonFilterProps>`
  border: none;
  background-color: transparent;
  padding: 10px 10px;
  font-size: 12px;
  line-height: 17px;
  width: 59px;
  text-align: center;
  color: #050c27;
  opacity: 0.7;
  cursor: pointer;
  margin-left: auto;

  @media (max-width: 899px) {
    display: none;
  }

  &:hover {
    background-color: #d0edfb;
  }

  ${props =>
    props.actived &&
    css`
      background-color: ${palette.primary.main};
      color: #fff;
      font-weight: bold;
      &:hover {
        background-color: ${palette.primary.main};
      }
    `}
`;

export const FilterMargin = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 10px 0 10px;

  .buttonSearch {
    width: 125px;
  }

  .buttonAdvancedFilters {
    margin-right: 20px;
  }

  .selectOrderButton {
    width: 125px;
  }

  @media (max-width: 1023px) {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
    margin: 0;

    .buttonSearch {
      width: 100%;
    }

    .selectOrderButton {
      width: 100%;
    }
  }
`;
