import { Card as MuiCard } from '@mui/material';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

export const Card = styled(MuiCard)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
  width: 180px;
  border-radius: 8px;
`;

export const useStyles = makeStyles(() => ({
  switchCardCheckedOn: {
    transition: 'background 0.2s',
    backgroundColor: '#d0edfb',
    boxShadow: 'inset 0 0 0 1.5px rgba(0, 0, 0, 0.2)',
  },
  labelSwitchChecked: {
    opacity: '40%',
  },
}));
