import { useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { UTMConfigurationWithUTMSource } from 'models/utmConfiguration';
import { useConfigurationUtmStore } from 'store/configurationUtmStore';
import { UtmDefaultCampaign } from 'fetches/utmCampaignsFetch';
import { useNavigate } from 'react-router-dom';
import { useToast } from 'hooks/toast';
import api from 'services/api';

interface UseUtmDefinitionProps {
  utm?: UTMConfigurationWithUTMSource;
  updateEditUtm?(): void;
}

export function useUtmDefinition({
  utm,
  updateEditUtm,
}: UseUtmDefinitionProps) {
  const { data: campaign } = UtmDefaultCampaign();

  const configurationUtm = useConfigurationUtmStore(state => state.value);
  const setConfigurationUtm = useConfigurationUtmStore(
    state => state.setConfigurationUtm,
  );
  const clearConfigurationUtm = useConfigurationUtmStore(
    state => state.clearConfigurationUtm,
  );
  const nameConfiguration = useConfigurationUtmStore(
    state => state.nameConfiguration,
  );
  const setNameConfiguration = useConfigurationUtmStore(
    state => state.setNameConfiguration,
  );

  const [showAddSource, setShowAddSource] = useState(false);
  const [sourceName, setSourceName] = useState('');
  const [total, setTotal] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const [sourceIdSelected, setSourceIdSelected] = useState<string | null>(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showDrawerToEditCampaign, setShowDrawerToEditCampaign] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { addToast } = useToast();

  useEffect(() => {
    clearConfigurationUtm();

    if (utm) {
      const newSources = {
        sources: utm.sources,
      };

      setConfigurationUtm(newSources);
      setNameConfiguration(utm.nome);
    }
  }, [clearConfigurationUtm, setConfigurationUtm, setNameConfiguration, utm]);

  useEffect(() => {
    setIsFormValid(total === 100);
  }, [total]);

  useEffect(() => {
    !showDialog && !showDrawerToEditCampaign && setSourceIdSelected(null);
  }, [showDialog, showDrawerToEditCampaign]);

  useEffect(() => {
    const sumPercentages = configurationUtm.sources.reduce(
      (accumulator, source) => accumulator + source.porcentagem,
      0,
    );

    setTotal(sumPercentages);
  }, [configurationUtm]);

  const handlePercentChange = (percent: string, index: number) => {
    const updatedSources = [...configurationUtm.sources];
    updatedSources[index] = {
      ...updatedSources[index],
      porcentagem: (!Number.isNaN(percent) && parseInt(percent, 10)) || 0,
    };

    setConfigurationUtm({ ...configurationUtm, sources: updatedSources });
  };

  function handleOpenDialog(sourceId: string) {
    setShowDialog(true);
    setSourceIdSelected(sourceId);
  }

  function handleCreateNewSource() {
    const newIDSource = uuidv4() as unknown as number;

    const updateSourceIDInCampaign = campaign?.map(campanha => ({
      ...campanha,
      id: uuidv4() as unknown as number,
      sources_id: newIDSource,
    }));

    const newSource = {
      id: newIDSource,
      nome: sourceName,
      porcentagem: 0,
      campaigns: updateSourceIDInCampaign,
    };

    const updatedSources = [...configurationUtm.sources, newSource];

    setConfigurationUtm({ ...configurationUtm, sources: updatedSources });

    if (configurationUtm.sources[0].id === null) {
      const removeSourceNull = updatedSources.filter(
        source => source.id !== null,
      );

      setConfigurationUtm({ ...configurationUtm, sources: removeSourceNull });
    }

    setShowAddSource(false);
    setSourceName('');
  }

  function handleDeleteSource() {
    if (sourceIdSelected) {
      const updatedSourcesCopy = [...configurationUtm.sources];
      const updatedSources = updatedSourcesCopy.filter(
        source => String(source.id) !== String(sourceIdSelected),
      );

      setConfigurationUtm({ ...configurationUtm, sources: updatedSources });
      setSourceIdSelected(null);
    }
  }

  function handleResetNewConfig() {
    setShowAddSource(false);
  }

  function handleResetEditConfig() {
    if (!utm) {
      return;
    }

    const sourcesDefault = { sources: utm.sources };

    setNameConfiguration(utm.nome);
    setConfigurationUtm(sourcesDefault);
    setShowAddSource(false);
  }

  function handleSaveDistribution() {
    setLoading(true);

    const data = {
      nome: nameConfiguration,
      sources: configurationUtm.sources,
      campaigns: configurationUtm.campaigns,
    };
    api
      .post(`/core/utm-configuracoes/`, data)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Distribuição realizada',
          description:
            'Distribuição de UTM foi salva e realizada com sucesso no sistema.',
        });

        navigate('/definicao-utm');
      })
      .catch(error => {
        let { message } = error.data;

        if (!message) {
          message = error.data.errors[0]?.message;
        }

        message &&
          addToast({
            type: 'error',
            title: 'Erro ao criar!',
            description: message,
          });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleEditDistribution = useCallback(() => {
    const data = {
      nome: nameConfiguration,
      sources: configurationUtm.sources,
    };

    setLoading(true);

    api
      .put(`/core/utm-configuracoes/${utm?.id}`, data)
      .then(() => {
        updateEditUtm && updateEditUtm();

        addToast({
          type: 'success',
          title: 'Distribuição realizada',
          description:
            'Distribuição de UTM foi editada com sucesso no sistema.',
        });

        navigate('/definicao-utm');
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Falha na edição da distribuição de UTM',
          description: error?.data?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    addToast,
    configurationUtm.sources,
    nameConfiguration,
    navigate,
    updateEditUtm,
    utm?.id,
  ]);

  return {
    configurationUtm,
    nameConfiguration,
    showDrawerToEditCampaign,
    sourceIdSelected,
    showDialog,
    showAddSource,
    sourceName,
    total,
    isFormValid,
    loading,
    setShowDrawerToEditCampaign,
    setNameConfiguration,
    setSourceIdSelected,
    setSourceName,
    setShowAddSource,
    setShowDialog,
    handleOpenDialog,
    handleCreateNewSource,
    handlePercentChange,
    handleDeleteSource,
    handleResetNewConfig,
    handleResetEditConfig,
    handleSaveDistribution,
    handleEditDistribution,
    navigate,
  };
}
