import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    input: {
      '&::placeholder': {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14px',
      },
    },
  }),
);
