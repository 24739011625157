import { OfferMarketing } from 'models/offersMarketing';
import api from 'services/api';
import useSWR from 'swr';

export const OfferMarketingByIdFetch = (id: number) => {
  const { data, mutate } = useSWR<OfferMarketing>(
    `offers_${id}`,
    async (): Promise<OfferMarketing> => {
      const { data: offer } = await api.get(`/core/ofertas/detalhe/${id}`);

      return offer;
    },
  );
  return { data, mutate };
};
