import { Box, Skeleton } from '@mui/material';
import { Item } from 'pages/UtmDefinition/styles';

function SkeletionList() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Item>
        <Skeleton sx={{ width: '100%' }} />
        <Skeleton sx={{ width: '50px' }} />
        <Skeleton sx={{ width: '50px' }} />
      </Item>
      <Item>
        <Skeleton sx={{ width: '100%' }} />
        <Skeleton sx={{ width: '50px' }} />
        <Skeleton sx={{ width: '50px' }} />
      </Item>
      <Item>
        <Skeleton sx={{ width: '100%' }} />
        <Skeleton sx={{ width: '50px' }} />
        <Skeleton sx={{ width: '50px' }} />
      </Item>
    </Box>
  );
}

export default SkeletionList;
