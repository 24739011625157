import { createStyles, makeStyles } from '@mui/styles';
import { MTheme } from 'models/theme';
import styled from 'styled-components';

export const DataInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -9px 0 24px;
`;

export const DataShortText = styled.span`
  width: 90px;
  font-size: 15px;
  display: flex;
  align-items: center;
`;

export const DataText = styled.span`
  min-width: 90px;
  font-size: 15px;
  display: flex;
  align-items: center;
`;

export const DataTextName = styled.span`
  width: 350px;
  font-size: 15px;
  display: flex;
  align-items: center;
`;

export const DataName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const useStyles = makeStyles((theme: MTheme) =>
  createStyles({
    buttonAction: {
      top: 7,
      right: 3,
    },
    icon: {
      color: '#CCCCCC',
      '& svg': {
        width: 24,
        height: 24,
      },
    },
    radioButtonsArea: {
      width: '50%',
      [theme.breakpoints.down(900)]: {
        width: '100%',
      },
    },
    dataInfo: {
      marginBottom: 8,
    },
    dataLastInfo: {
      marginBottom: 0,
    },
  }),
);
