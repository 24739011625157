import TotalizerCard from 'components/TotalizerCard';
import { TotalizatorDaysForCronFetch } from 'fetches/totalizatorDaysForCronFetch';
import { TotalizatorLeadsPerBatchFetch } from 'fetches/totalizatorLeadsPerBatchFetch';

interface TotalizatorProps {
  isDesktop: boolean;
}

function Totalizator({ isDesktop }: TotalizatorProps) {
  const { data: totalDays } = TotalizatorDaysForCronFetch();
  const { data: totalLeads } = TotalizatorLeadsPerBatchFetch();

  const totalizator = [
    {
      title: 'LEADS AFILIADOS (POR DIA)',
      total: totalLeads?.total || 0,
    },
    {
      title: 'LEADS CRON (POR DIA)',
      total: totalDays?.total || 0,
    },
  ];

  return (
    <>
      {totalizator.map(item => (
        <TotalizerCard
          key={item.title}
          title={item.title}
          total={item.total}
          isDesktop={isDesktop}
        />
      ))}
    </>
  );
}

export default Totalizator;
