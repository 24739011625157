import { Add } from '@mui/icons-material';
import { Button, CardContent, CardHeader } from '@mui/material';
import { Card, Header } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { useNavigate } from 'react-router-dom';
import palette from 'theme/palette';
import { BlacklistDataFetch } from 'fetches/blacklistDataFetch';
import { useState } from 'react';
import Pagination from 'components/Pagination';
import Filters from './components/Filters';
import BlacklistDataList from './components/BlacklistDataList';
import DeleteSelected from './components/DeleteSelected';

function BlacklistData() {
  const [currentPage, setCurrentPage] = useState(1);
  const [checkData, setCheckData] = useState<number[]>([]);
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');

  const navigate = useNavigate();
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  const { data: response, mutate } = BlacklistDataFetch({
    page: currentPage,
    search,
    type,
  });

  return (
    <Card>
      <Header>
        <CardHeader
          title="Dados Blacklist"
          subheader="Módulo para gestão de dados blacklist."
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate('/dados-blacklist/cadastro-dados-blacklist');
          }}
        >
          {isTableOrLess ? (
            <Add style={{ color: palette.white }} />
          ) : (
            'Inserir dados'
          )}
        </Button>
      </Header>
      <Filters setSearch={setSearch} setType={setType} />

      <CardContent>
        <BlacklistDataList
          blacklists={response?.blacklists}
          checkData={checkData}
          setCheckData={setCheckData}
          updateBlacklist={mutate}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: `${isTableOrLess ? 'column' : 'row'}`,
            gap: 16,
            justifyContent: 'space-between',
            paddingTop: 16,
          }}
        >
          <DeleteSelected
            checkData={checkData}
            setCheckData={setCheckData}
            updateDataList={mutate}
          />
          {response && (
            <Pagination
              currentPage={currentPage}
              lastPage={response?.paginationInfo.lastPage}
              perPage={response?.paginationInfo.perPage}
              total={response?.paginationInfo.total}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
}

export default BlacklistData;
