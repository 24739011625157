import { Typography as MuiTypography } from '@mui/material';
import styled from 'styled-components';
import palette from 'theme/palette';

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  box-shadow: 2px 2px 8px 0px #00000033;
  border-radius: 6px;
  align-items: center;
  padding-left: 15px;
  gap: 15px;
`;

export const Title = styled(MuiTypography)`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
`;

export const Total = styled(MuiTypography)`
  font-weight: 700;
  color: ${palette.status.deliveryProblems.color};
`;
