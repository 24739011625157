import React, { useState } from 'react';
import { Search } from '@mui/icons-material';
import useBreakpoint from 'hooks/useBreakpoint';
import { Container } from 'components/Filters';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
} from '@mui/material';
import { FilterMargin } from './styles';

interface FiltersProps {
  setSearch(search: string): void;
  setStatus(search: string): void;
  status: string;
}

function Filters({ setSearch, setStatus, status }: FiltersProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  const [localSearch, setLocalSearch] = useState('');

  return (
    <Container>
      <FilterMargin>
        <FormControl
          variant="outlined"
          margin="none"
          size="small"
          fullWidth={isTableOrLess}
        >
          <InputLabel>Status</InputLabel>
          <Select
            className="buttonStatus"
            native
            value={status}
            onChange={e => setStatus(e.target.value as string)}
            label="Status"
          >
            <option aria-label="Todos" value="" />
            <option value="NOVO">Novo</option>
            <option value="ATIVADO">Ativado</option>
            <option value="FALHOU">Falhou</option>
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          margin="none"
          size="small"
          fullWidth={isTableOrLess}
        >
          <InputLabel>Buscar</InputLabel>
          <OutlinedInput
            className="buttonSearch"
            value={localSearch}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                setSearch(localSearch);
              }
            }}
            onChange={e => {
              if (e.target.value === '') {
                setSearch('');
              }
              setLocalSearch(e.target.value as string);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setSearch(localSearch);
                  }}
                  edge="end"
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </FilterMargin>
    </Container>
  );
}

export default Filters;
