import { RiDeleteBinLine } from 'react-icons/ri';
import DotStatus from '../../components/DotStatus';
import { PackageItemAction, PackageItemList } from './styles';

export interface PackageProps {
  nome?: string;
  onRemoveItem?: () => void;
}

function PackageListItem({ nome, onRemoveItem }: PackageProps) {
  return (
    <PackageItemList>
      <div className="status">
        <DotStatus active />
      </div>
      <p className="text description">{nome}</p>
      <div className="actions">
        <PackageItemAction onClick={onRemoveItem}>
          <RiDeleteBinLine style={{ width: 24, height: 24 }} />
        </PackageItemAction>
      </div>
    </PackageItemList>
  );
}

export default PackageListItem;
