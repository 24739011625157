import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ptBRLocale from 'date-fns/locale/pt-BR';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import AppProvider from './hooks';
import MyRoutes from './routes';
import ThemeConfig from './theme';

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  BarElement,
  Legend,
  ArcElement,
  Tooltip,
);

function App() {
  return (
    <ThemeConfig>
      <AppProvider>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={ptBRLocale}
        >
          <MyRoutes />
        </LocalizationProvider>
      </AppProvider>
    </ThemeConfig>
  );
}

export default App;
