import { useCallback, useState } from 'react';
import { Button, DialogActions, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { ButtonProgress } from 'components/StyledComponents';
import { ModalCard } from 'pages/CmsLP/components/DeleteModal/styles';
import { useStylesModal } from 'components/UseStylesModal';

interface DialogDeletePriceGroupProps {
  id: number;
  oldCurrentPrice: string;
  openDialogDeletePriceGroup: boolean;
  handleClose(): void;
  updatePriceGroups(): void;
}

function DialogDeletePriceGroup({
  id,
  oldCurrentPrice,
  openDialogDeletePriceGroup,
  handleClose,
  updatePriceGroups,
}: DialogDeletePriceGroupProps) {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();
  const classes = useStylesModal();

  const handleDelete = useCallback(() => {
    setLoading(true);
    api
      .put(`/core/planos/remover-preco/${id}`, {
        precos: {
          old_preco_atual: oldCurrentPrice,
        },
      })
      .then(() => {
        addToast({
          type: 'success',
          title: 'Grupo de preço removido',
          description: `O grupo de preço foram removidos com sucesso.`,
        });

        updatePriceGroups();
        setLoading(false);
        handleClose();
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Remover link ',
          description: error?.data?.message,
        });
        setLoading(false);
        handleClose();
      });
  }, [id, oldCurrentPrice, addToast, updatePriceGroups, handleClose]);

  return (
    <Modal
      aria-labelledby="tile-modal"
      aria-describedby="modal-description"
      className={classes.modal}
      open={openDialogDeletePriceGroup}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 200,
      }}
    >
      <Fade in={openDialogDeletePriceGroup}>
        <ModalCard>
          <Typography>
            Você deseja mesmo remover este grupo de preço? Todo o histórico será
            perdido.
          </Typography>
          <DialogActions className={classes.dialogActions}>
            <Button
              className={classes.buttonCancel}
              variant="outlined"
              onClick={() => handleClose()}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={handleDelete}
              color="primary"
              disabled={loading}
            >
              Remover
              {loading && <ButtonProgress size={24} />}
            </Button>
          </DialogActions>
        </ModalCard>
      </Fade>
    </Modal>
  );
}

export default DialogDeletePriceGroup;
