import {
  Box as MuiBox,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  FormControlLabel as MuiFormControlLabel,
} from '@mui/material';
import styled, { css } from 'styled-components';
import palette from 'theme/palette';

export const FilterBox = styled(MuiBox)`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    margin: 0px 9px 0px 25px;
  }
`;

interface ButtonFilterProps {
  actived: boolean;
}

export const ButtonFilter = styled.button.attrs({
  type: 'button', // eslint-disable-next-line
}) <ButtonFilterProps>`
  display: flex;
  border: none;
  background-color: transparent;
  padding: 10px 10px;
  font-size: 12px;
  line-height: 17px;
  width: 65px;
  justify-content: center;
  align-items: center;
  color: #050c27;
  opacity: 0.7;
  cursor: pointer;
  margin-left: auto;

  @media (max-width: 1023px) {
    margin-left: 0px;
  }

  &:hover {
    background-color: #d0edfb;
  }

  ${props =>
    props.actived &&
    css`
      background-color: ${palette.primary.main};
      color: #fff;
      font-weight: bold;
      &:hover {
        background-color: ${palette.primary.main};
      }
    `}
`;

export const FormControl = styled(MuiFormControl)`
  width: 157px;

  @media (max-width: 1024px) {
    width: 100%;
    margin-right: 16px;
  }
`;

export const Box = styled(MuiBox)`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: 1023px) {
    margin-left: -18px;
    width: 100%;
  }
`;

export const Select = styled(MuiSelect)`
  display: flex;
`;

export const BoxFilters = styled(MuiBox)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: 1023px) {
    gap: 10px;
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  display: flex;
  justify-content: left;
  text-align: center;
  padding-left: 30px;
`;

export const Divider = styled(MuiDivider)`
  width: 100px;
  margin: auto;
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  @media (max-width: 1024px) {
    align-self: start;
  }
`;
