import { Card, Skeleton } from '@mui/material';
import { Benefit } from 'models/benefits';
import { usePlanStore } from 'store/planStore';
import BenefitsListItem from './BenefitsListItem';

interface BenefitsListProps {
  data: Benefit[];
  loading?: boolean;
}

function BenefitsList({ data: benefit, loading }: BenefitsListProps) {
  const planStore = usePlanStore(state => state.plan);
  const setPlanStore = usePlanStore(state => state.setPlan);

  const handleRemoveItem = (id: number) => {
    const newPackages = planStore.beneficios.filter(
      beneficio => beneficio.id !== id,
    );
    setPlanStore({ ...planStore, beneficios: newPackages });
  };

  return (
    <Card style={{ margin: '8px 16px 16px' }}>
      {loading ? (
        <>
          <Skeleton width="30%" />
          <Skeleton width="30%" />
          <Skeleton width="30%" />
        </>
      ) : benefit.length > 0 ? (
        benefit.map(beneficio => (
          <BenefitsListItem
            key={beneficio.id}
            descricao={`${beneficio.descricao}`}
            onRemoveItem={() => handleRemoveItem(beneficio.id)}
          />
        ))
      ) : (
        <div
          style={{
            boxShadow: 'inset 0px -1px 0px rgb(0 0 0 / 5%)',
            padding: 16,
            fontSize: 16,
          }}
        >
          O plano não possui beneficios disponíveis
        </div>
      )}
    </Card>
  );
}

export default BenefitsList;
