import styled from 'styled-components';

export const MonthLabel = styled.h1`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`;

export const DayLabel = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #546e7a;
`;
