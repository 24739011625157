import {
  ActionButtons,
  MobileContainer,
  MobileData,
  MobileDataLabel,
  useStylesActionButtons,
} from 'components/StyledComponents';
import { IconButton, Skeleton } from '@mui/material';
import {
  Action,
  GroupData,
  MobileDataText,
  MobileDataTextName,
} from 'components/MobileList';
import { dateTime as dateTimeFormatter } from 'util/formatter';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ItemProps } from '../types';
import DialogDeleteUtmConfiguration from '../../DialogDeleteUtmConfiguration';

function MobileItem({
  utm,
  utmConfigurations,
  updateUtmConfigurations,
  loading,
}: ItemProps) {
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const classes = useStylesActionButtons();
  const navigate = useNavigate();
  const openDialogDeleteUtmConfiguration = () => {
    setOpenDialogDelete(true);
  };

  const handleCloseDeleteUtmConfiguration = () => {
    setOpenDialogDelete(false);
  };
  return (
    <MobileContainer>
      {loading ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        utm && (
          <>
            <Action>
              <ActionButtons>
                <IconButton
                  className={classes.iconEdit}
                  onClick={() =>
                    navigate(`/definicao-utm/edita-configuracao/${utm?.id}`)
                  }
                  disabled={loading}
                >
                  <FiEdit />
                </IconButton>
                <IconButton
                  className={classes.iconDelete}
                  onClick={() => openDialogDeleteUtmConfiguration()}
                  disabled={loading}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </ActionButtons>
            </Action>
            <>
              <GroupData>
                <MobileData>
                  <MobileDataLabel>Nome</MobileDataLabel>
                  <MobileDataText>{utm.nome}</MobileDataText>
                </MobileData>
              </GroupData>
              <GroupData>
                <MobileData>
                  <MobileDataLabel>Sources</MobileDataLabel>
                  <MobileDataTextName>{utm.sources}</MobileDataTextName>
                </MobileData>
              </GroupData>
              <GroupData>
                <MobileData>
                  <MobileDataLabel>Criado</MobileDataLabel>
                  <MobileDataText>
                    {dateTimeFormatter(utm.created_at)}
                  </MobileDataText>
                </MobileData>
              </GroupData>
            </>
          </>
        )
      )}
      {utm && utmConfigurations && updateUtmConfigurations && (
        <DialogDeleteUtmConfiguration
          id={utm.id}
          name={utm.nome}
          utmConfigurations={utmConfigurations}
          openDialogDeleteUtmConfiguration={openDialogDelete}
          handleClose={handleCloseDeleteUtmConfiguration}
          updateUtmConfigurations={updateUtmConfigurations}
        />
      )}
    </MobileContainer>
  );
}

export default MobileItem;
