export const listaComponentes = [
  'ebook',
  'newsStand',
  'unlimitedSms',
  'unlimitedCall',
  'unlimitedTalks',
  'unlimitedRoaming',
  'threeMonthsTalks',
  'unlimitedSocialNetworks',
  'threeMonthsSocialNetworks',
  'Ampli',
  'C6Bank',
  'DisneyPlus',
  'Ensinah',
  'EnsinahPremium',
  'EnsinahEquilibrah',
  'DeezerPremium',
  'YoutubePremium',
  'HBOMax',
  'ComboDisneyEStar',
  'AppleTV',
  'AmazonPrime',
  'Paramount',
  'Netflix',
  'GloboPlay',
  'PrimeCelular',
  'Audiobooks',
  'Babel',
  'Bancah',
  'Cloud',
  'Gestao',
  'Seguranca',
  'Fluid',
  'Mulheres',
  'RedesSociais',
  'WhatsApp',
  'Roaming',
  'TimOnPlane',
  'Wifi',
  'DeezerNow',
  'InternetAcumulada',
];
