interface DotStatusProps {
  active?: boolean;
}

function DotStatus({ active }: DotStatusProps) {
  const isActive = active ? '#2B8A3E' : '#E9E9EE';

  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill={isActive} />
    </svg>
  );
}

export default DotStatus;
