import { Card as MuiCard } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Card = styled(MuiCard)`
  display: flex;
  flex-direction: column;
  height: 170px;
  padding: 20px;
  gap: 20px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
`;

export const RowCard = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DataInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
