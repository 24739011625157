import { Box } from '@mui/material';
import statusCardSetup from './statusCardSetup';
import { Card } from './styles';

interface StatusCardProps {
  status: string;
}

function StatusCard({ status }: StatusCardProps) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
    >
      <Card bgcolor={statusCardSetup[status]?.color}>
        {statusCardSetup[status]?.label || status}
      </Card>
    </Box>
  );
}

export default StatusCard;
