import { Box, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './styles';

interface LinearConversionProgressProps {
  value: number;
  status: string;
}

interface StyleProps {
  backgroundColor?: string;
}

function LinearConversionProgress({
  value,
  status,
}: LinearConversionProgressProps) {
  const backgroundColor = status;
  const styleProps: StyleProps = { backgroundColor };
  const classes = useStyles(styleProps);
  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <Box style={{ width: '100%', marginRight: 7 }}>
        <LinearProgress
          variant="determinate"
          value={value}
          className={classes.root}
        />
      </Box>
      <Box style={{ minWidth: 35 }}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default LinearConversionProgress;
