import styled from 'styled-components';

interface CardProp {
  bgcolor?: string;
}

export const Card = styled.div<CardProp>`
  align-items: center;
  background-color: ${props => props.bgcolor || 'rgba(48, 174, 201, 0.2)'};
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  padding: 2px 6px 2px 6px;
  display: inline-block;
  align-content: center;
  border-radius: 6px;
  cursor: pointer;
`;
