import React from 'react';
import { CloseButton } from 'components/StyledComponents';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Setup } from 'models/setup';
import { Button, Container, Description, Title } from './styles';
import NormalForm from './components/NormalForm';
import SequenceForm from './components/SequenceForm';
import BooleanForm from './components/BooleanForm';
import EnumNormalForm from './components/EnumNormalForm';
import EnumSequenceForm from './components/EnumSequenceForm';

interface SetupEditProps {
  setup: Setup;
  closeDrawer(): void;
}

function SetupEdit({ setup, closeDrawer }: SetupEditProps) {
  return (
    <Container>
      <Button>
        <CloseButton onClick={() => closeDrawer()}>
          <IconButton>
            <Close />
          </IconButton>
        </CloseButton>
      </Button>
      <Title>{setup.title}</Title>
      <Description>{setup.description}</Description>

      {(() => {
        switch (setup.vision_type) {
          case 'sequence':
            if (setup.type === 'enum') {
              return (
                <EnumSequenceForm setup={setup} closeDrawer={closeDrawer} />
              );
            }
            return <SequenceForm setup={setup} closeDrawer={closeDrawer} />;

          case 'boolean':
            return <BooleanForm setup={setup} closeDrawer={closeDrawer} />;

          default:
            if (setup.type === 'enum') {
              return <EnumNormalForm setup={setup} closeDrawer={closeDrawer} />;
            }
            return <NormalForm setup={setup} closeDrawer={closeDrawer} />;
        }
      })()}
    </Container>
  );
}

export default SetupEdit;
