import { Search } from '@mui/icons-material';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { useState } from 'react';
import { Container } from './styles';

interface FiltersProps {
  setSearchPlanID(searchPlanID: string): void;
}

function Filters({ setSearchPlanID }: FiltersProps) {
  const [localSearchPlanID, setLocalSearchPlanID] = useState(String(''));
  return (
    <Container>
      <FormControl variant="outlined" margin="dense" size="small" fullWidth>
        <InputLabel>Buscar ID Plano</InputLabel>
        <OutlinedInput
          type="number"
          label="Buscar ID Plano"
          value={localSearchPlanID}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              setSearchPlanID(localSearchPlanID);
            }
          }}
          onChange={e => {
            if (e.target.value === '') {
              setSearchPlanID('');
            }
            setLocalSearchPlanID(e.target.value as string);
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setSearchPlanID(localSearchPlanID);
                }}
                edge="end"
              >
                <Search />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </Container>
  );
}

export default Filters;
