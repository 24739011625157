import { Bar } from 'react-chartjs-2';
import { format, parseISO } from 'date-fns';
import { shade } from 'polished';
import palette from 'theme/palette';
import { EvolutionThirtyDayFetch } from 'fetches/evolutionThirtyDayFetch';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useReportFilterFlagFetchStore } from 'store/useReportFilterFlagFetchStore';

interface ThirtyDayEvolutionProps {
  day: string;
}

function ThirtyDayEvolution({ day }: ThirtyDayEvolutionProps) {
  const reprocessed = useReportFilterFlagFetchStore(
    state => state.withReprocessedFetch,
  );
  const inReprocessing = useReportFilterFlagFetchStore(
    state => state.inReprocessingFetch,
  );
  const engagement = useReportFilterFlagFetchStore(
    state => state.withEngagementFetch,
  );

  const { data: evolution } = EvolutionThirtyDayFetch(
    day,
    reprocessed,
    inReprocessing,
    engagement,
  );

  const data = {
    labels: evolution
      ? Object.keys(evolution).map(key => format(parseISO(key), 'dd/MM'))
      : [],
    datasets: [
      {
        data: evolution
          ? Object.keys(evolution).map(key => evolution[key].total)
          : [],
        label: 'Total',
        backgroundColor: palette.total,
        hoverBackgroundColor: shade(0.2, palette.total),
      },
    ],
  };

  return (
    <div style={{ marginTop: 25, height: '100%', maxHeight: '350px' }}>
      <h2>Evolução 30 dias</h2>
      <Bar
        data={data}
        options={{
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
            },

            y: {
              stacked: true,
            },
          },
          plugins: {
            datalabels: {
              anchor: 'end',
              align: 'end',
              offset: -2,
              color: 'rgba(0, 0, 0, 0.4)',
              font: { size: 10 },
              padding: 10,
            },
          },
        }}
        plugins={[ChartDataLabels]}
      />
    </div>
  );
}

export default ThirtyDayEvolution;
