import styled from 'styled-components';

export const DataInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3px 10px;
`;

export const DataName = styled.span`
  width: 250px;
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const DataTextName = styled.span`
  width: 180px;
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 18px;
`;
