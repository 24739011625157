import { useState } from 'react';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';

import { Search } from '@mui/icons-material';
import useBreakpoint from 'hooks/useBreakpoint';
import { Container } from 'components/Filters';
import { Infos } from 'components/MobileList';
import { FilterMargin } from './styles';
import PopoverActions from './PopoverActions';

interface FiltersProps {
  status: string;
  segmento: string;
  setStatus(status: string): void;
  setSegmento(segmento: string): void;
  setSearch(search: string): void;
}

function Filters({
  status,
  segmento,
  setStatus,
  setSegmento,
  setSearch,
}: FiltersProps) {
  const [localSearch, setLocalSearch] = useState('');
  const [localSegmento, setLocalSegmento] = useState('');
  const [localStatus, setLocalStatus] = useState('');
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  const onFilter = () => {
    setSegmento(localSegmento);
    setStatus(localStatus);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };
  const openPopover = Boolean(anchorElement);
  const id = openPopover ? 'popover-actions' : undefined;
  return (
    <Container>
      <FilterMargin>
        <FormControl
          variant="outlined"
          margin="none"
          size="small"
          fullWidth={isTableOrLess}
        >
          <InputLabel>Buscar</InputLabel>
          <OutlinedInput
            label="Buscar"
            className="buttonSearch"
            value={localSearch}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                setSearch(localSearch);
              }
            }}
            onChange={e => {
              if (e.target.value === '') {
                setSearch('');
              }
              setLocalSearch(e.target.value as string);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setSearch(localSearch);
                  }}
                  edge="end"
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {isTableOrLess ? (
          <Infos>
            <Button
              aria-describedby={id}
              color="primary"
              style={{ color: '#050C27', minWidth: 135 }}
              onClick={handleClick}
              className="buttonAdvancedFilters"
            >
              filtros avançados
            </Button>
            <PopoverActions
              id={id || ''}
              openPopover={openPopover}
              anchorEl={anchorElement}
              handleClick={handleClose}
              segmento={localSegmento}
              setSegmento={setLocalSegmento}
              status={localStatus}
              setStatus={setLocalStatus}
              onClose={handleClose}
              onFilter={onFilter}
            />
          </Infos>
        ) : (
          <>
            <TextField
              className="buttonSegmento"
              label="Filtrar por segmento"
              variant="outlined"
              select
              value={segmento}
              SelectProps={{ native: true }}
              onChange={e => setSegmento(e.target.value as string)}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
            >
              <option aria-label="Todos" value="">
                Todos
              </option>
              <option value="CONTROLE">Controle</option>
              <option value="EXPRESS">Express</option>
              <option value="POS_PAGO">Pós Pago</option>
              <option value="POS_EXPRESS">Pós Express</option>
            </TextField>
            <TextField
              label="Filtrar por status"
              variant="outlined"
              className="buttonStatus"
              select
              value={status}
              SelectProps={{ native: true }}
              onChange={e => setStatus(e.target.value as string)}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
            >
              <option aria-label="Todos" value="all">
                Todos
              </option>
              <option value="1">Ativo</option>
              <option value="0">Inativo</option>
            </TextField>
          </>
        )}
      </FilterMargin>
    </Container>
  );
}

export default Filters;
