import { useEffect, useState } from 'react';
import { useAuth } from 'hooks/auth';
import {
  vendaEditar,
  vendaEditarDados,
  vendaStatusMovimentacoesSIMCard,
} from 'constants/permissions';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { Sale } from '../types';

interface useInfoSaleProps {
  sale?: Sale;
  updateSelectedSale?(sale: Sale): void;
}
export function useInfoSale({ sale, updateSelectedSale }: useInfoSaleProps) {
  const [status, setStatus] = useState('');
  const [showButtonToChangeICCID, setShowButtonToChangeICCID] = useState(false);
  const [showButtonTrackingStatus, setShowButtonTrackingStatus] =
    useState(false);
  const [showButtonEditCustomerData, setShowButtonEditCustomerData] =
    useState(false);
  const [showButtonEditSaleData, setShowButtonEditSaleData] = useState(false);
  const [loadingGetLinkSerasa, setLoadingGetLinkSerasa] = useState(false);
  const [showGetLinkSerasa, setShowGetLinkSerasa] = useState(false);
  const [linkSerasa, setLinkSerasa] = useState('');

  const { hasPermission } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    setStatus(sale?.status || '');

    if (sale && updateSelectedSale && updateSelectedSale) {
      updateSelectedSale(sale);
    }
  }, [sale, updateSelectedSale]);

  useEffect(() => {
    const shouldShow =
      hasPermission(vendaEditar) &&
      (sale?.status === 'CANCELADO' || sale?.status === 'PENDENTE_ENVIO') &&
      (sale?.tipo === 'NOVA_LINHA' || sale?.tipo === 'PORTABILIDADE');

    setShowButtonToChangeICCID(shouldShow);
  }, [hasPermission, sale]);

  useEffect(() => {
    const shouldShow =
      hasPermission(vendaStatusMovimentacoesSIMCard) &&
      sale?.tipo !== 'MIGRACAO' &&
      (sale?.status === 'ENVIADO' ||
        sale?.status === 'AGUARDANDO_RETIRADA' ||
        sale?.status === 'EM_TRANSPORTE_EXPRESSO' ||
        sale?.status === 'DEVOLVIDO');

    setShowButtonTrackingStatus(shouldShow);
  }, [hasPermission, sale]);

  useEffect(() => {
    const shouldShow = hasPermission(vendaEditarDados);

    setShowButtonEditSaleData(shouldShow);
    setShowButtonEditCustomerData(shouldShow);
  }, [hasPermission]);

  const handleGetLinkSerasa = () => {
    setLoadingGetLinkSerasa(true);
    const url = '/core/elegibilidade/gerar-link-brflow';

    if (linkSerasa) {
      setShowGetLinkSerasa(true);
      setLoadingGetLinkSerasa(false);
      return linkSerasa;
    }

    return api
      .post(`${url}/${sale?.id}`)
      .then(({ data: response }) => {
        if (response?.data?.link) {
          setLinkSerasa(response.data.link);
        }

        setShowGetLinkSerasa(true);
      })
      .catch(error => {
        error.data &&
          addToast({
            type: 'error',
            title: 'Erro ao obter o link Serasa',
            description: error.data.message,
          });
      })
      .finally(() => {
        setLoadingGetLinkSerasa(false);
      });
  };

  return {
    status,
    showButtonToChangeICCID,
    showButtonTrackingStatus,
    showButtonEditCustomerData,
    showButtonEditSaleData,
    loadingGetLinkSerasa,
    showGetLinkSerasa,
    linkSerasa,
    setShowGetLinkSerasa,
    handleGetLinkSerasa,
  };
}
