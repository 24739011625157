import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const FilterMargin = styled.div`
  margin: 0 15px;

  @media (max-width: 1023px) {
    margin: 10px 15px;
    width: 100%;
    text-align: center;
    align-self: center;
  }
`;
