import { Close } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { CloseButton } from 'components/StyledComponents';
import { ReactNode } from 'react';
import { ButtonDrawer, Container, Title } from './styles';

interface CMSSlidingDrawerProps {
  title?: string;
  subtitle: string;
  closeDrawer(): void;
  children: ReactNode;
}

function CMSSlidingDrawer({
  title,
  subtitle,
  closeDrawer,
  children,
}: CMSSlidingDrawerProps) {
  return (
    <Container>
      <ButtonDrawer>
        <CloseButton onClick={() => closeDrawer()}>
          <IconButton>
            <Close />
          </IconButton>
        </CloseButton>
      </ButtonDrawer>

      <Title>{title}</Title>

      <Typography variant="subtitle2" align="left" className="subtitleEdit">
        {subtitle}
      </Typography>

      {children}
    </Container>
  );
}

export default CMSSlidingDrawer;
