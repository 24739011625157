import styled from 'styled-components';

export const MobileTextName = styled.span`
  width: 150px;
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 500;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.8);
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Box = styled.span`
  width: 100%;
`;
