import styled, { css } from 'styled-components';
import palette from 'theme/palette';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

interface ButtonFilterProps {
  actived: boolean;
}

export const ButtonFilter = styled.button.attrs({
  type: 'button', // eslint-disable-next-line
})<ButtonFilterProps>`
  border: none;
  background-color: transparent;
  padding: 10px;
  font-size: 12px;
  line-height: 17px;
  width: 82px;
  text-align: center;
  color: #050c27;
  opacity: 0.7;
  cursor: pointer;

  &:hover {
    background-color: #d0edfb;
  }

  ${props =>
    props.actived &&
    css`
      background-color: ${palette.primary.main};
      color: #fff;
      font-weight: bold;
      &:hover {
        background-color: ${palette.primary.main};
      }
    `}
`;

export const LineControl = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;

  .MuiButton-containedSizeLarge {
    width: 100%;
  }
`;

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    maxWidth: 418,
  },
  title: {
    color: '#050C27',
    fontSize: 12,
    marginBottom: 16,
  },
  buttonMonth: {
    borderRadius: 6,
    maxWidth: 101,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    boxShadow: 'none',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    background: '#ffff',

    '&:hover': {
      backgroundColor: 'rgba(48,174,201,0.2)',
      boxShadow: 'none',
    },
    '&.MuiButton-containedPrimary': {
      border: '1px solid rgba(181, 210, 224, 1)',
      backgroundColor: '#F5FCFF',
      color: '#050C27',
    },
  },
  keyboardDate: {
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  switchButton: {
    marginBottom: 10,
    marginTop: 24,
    marginLeft: 5,
  },
}));
