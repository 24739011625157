import userSWR from 'swr';
import api from 'services/api';
import { Totalizator } from 'models/reports';

export const TotalizatorUsefulLeadsFetch = () => {
  const { data, mutate } = userSWR<Totalizator>(
    `totalizatorUsefulLeads`,

    async (): Promise<Totalizator> => {
      const { data: totalizator } = await api.get(
        `core/afiliados/totalizador-leads-uteis`,
      );
      return totalizator as Totalizator;
    },
  );
  return { data, mutate };
};
