import React, { useCallback, useState } from 'react';

import {
  Container,
  Data,
  DataInfo,
  Divider,
} from 'components/StyledComponents';
import { dateTime as dateTimeFormatter } from 'util/formatter';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { IconButton, Skeleton } from '@mui/material';
import { Role } from 'models/roles';
import { DataText } from './styles';
import PopperActions from '../PopperActions';

interface ItemProps {
  data?: Role;
  loading?: boolean;
}

function Item({ data, loading = false }: ItemProps) {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElement(anchorElement ? null : event.currentTarget);
    },
    [anchorElement],
  );

  const open = Boolean(anchorElement);
  const id = open ? `popperAction_${data?.id}` : undefined;

  return (
    <Container>
      <Data>
        <DataInfo>
          <DataText>{loading ? <Skeleton width={140} /> : data?.nome}</DataText>
          <DataText>
            {loading ? (
              <Skeleton width={140} />
            ) : (
              dateTimeFormatter(data?.created_at)
            )}
          </DataText>
        </DataInfo>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <PopperActions
          id={id || ''}
          open={open}
          roleId={data?.id || 0}
          roleNome={data?.nome || ''}
          anchorElement={anchorElement}
          handleClick={handleClick}
        />
      </Data>
      <Divider />
    </Container>
  );
}

export default Item;
