import { FormControlLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import React, { useEffect } from 'react';
import copyToClipboard from 'util/copyToClipboard';
import copyIcon from 'assets/icons/small/copy.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSaleSelected } from 'store/saleSelected';
import api from 'services/api';
import { useSaleDrawer } from 'store/saleDrawerStore';
import { Data, DataColumn, DataLabel, Infos } from 'components/MobileList';
import { cell as cellFormatter, cpf as cpfFormatter } from 'util/formatter';
import {
  Actions,
  Container,
  DataInfo,
  Divider,
  MobileTextName,
} from './styles';
import { FraudAnalysis as IFraudAnalysis, Sale } from '../types';

interface MobileSaleCardProps {
  sale: Sale;
  FraudAnalysis: IFraudAnalysis;
  setFraudAnalysis(value: IFraudAnalysis): void;
}

function MobileSaleCard({
  sale,
  setFraudAnalysis,
  FraudAnalysis,
}: MobileSaleCardProps) {
  const saleDrawer = useSaleDrawer(state => state.saleDrawer);
  const setSaleDrawer = useSaleDrawer(state => state.setSaleDrawer);
  const saleSelected = useSaleSelected(state => state.saleSelected);
  const setSalesSelected = useSaleSelected(state => state.setSalesSelected);

  useEffect(() => {
    if (saleSelected) {
      api.get(`/core/vendas/${saleSelected}`).then(({ data }) => {
        setSaleDrawer(data);
      });
    }
  }, [saleSelected, setSaleDrawer]);

  useEffect(() => {
    if (saleDrawer?.status === 'CANCELADO') {
      setFraudAnalysis({});
    }
  }, [saleDrawer, setFraudAnalysis]);

  return (
    <Container>
      <IconButton
        className="Button"
        onClick={() => sale && setSalesSelected(sale.venda_id)}
      >
        <MoreVertIcon />
      </IconButton>
      <Infos>
        <DataColumn>
          <Data>
            <DataLabel>Nome do Cliente</DataLabel>
            <DataInfo>
              {sale.cliente_nome}
              <IconButton
                style={{ padding: 5 }}
                onClick={e => {
                  e.stopPropagation();
                  copyToClipboard(sale.cliente_nome);
                }}
                color="inherit"
              >
                <img src={copyIcon} alt="copiar" />
              </IconButton>
            </DataInfo>
          </Data>
          <Data>
            <DataLabel>CPF</DataLabel>
            <DataInfo>
              {cpfFormatter(sale.cliente_cpf)}
              <IconButton
                style={{ padding: 5 }}
                onClick={e => {
                  e.stopPropagation();
                  copyToClipboard(sale.cliente_cpf);
                }}
                color="inherit"
              >
                <img src={copyIcon} alt="copiar" />
              </IconButton>
            </DataInfo>
          </Data>
          <Data>
            <DataLabel>E-mail</DataLabel>
            <DataInfo>
              <MobileTextName>{sale.cliente_email}</MobileTextName>
              <IconButton
                style={{ padding: 5 }}
                onClick={e => {
                  e.stopPropagation();
                  copyToClipboard(sale.cliente_email);
                }}
                color="inherit"
              >
                <img src={copyIcon} alt="copiar" />
              </IconButton>
            </DataInfo>
          </Data>
          <Data>
            <DataLabel>Endereço</DataLabel>
            <DataInfo>{sale.cliente_endereco}</DataInfo>
          </Data>
          <Data>
            <DataLabel>Número da Linha</DataLabel>
            <DataInfo>
              {cellFormatter(sale.cliente_telefone)}
              <IconButton
                style={{ padding: 5 }}
                onClick={e => {
                  e.stopPropagation();
                  copyToClipboard(sale.cliente_telefone);
                }}
                color="inherit"
              >
                <img src={copyIcon} alt="copiar" />
              </IconButton>
            </DataInfo>
          </Data>
          <Data>
            <DataLabel>Status</DataLabel>
            <DataInfo>{sale.status}</DataInfo>
          </Data>
          <Data>
            <DataLabel>Segmento</DataLabel>
            <DataInfo>{sale.segmento}</DataInfo>
          </Data>
          <Data>
            <DataLabel>Tipo</DataLabel>
            <DataInfo>{sale.tipo}</DataInfo>
          </Data>
          <Data>
            <DataLabel>Data e Hora</DataLabel>
            <DataInfo>{sale.data_criacao}</DataInfo>
          </Data>
          <Data>
            <DataLabel>IP</DataLabel>
            <DataInfo>{sale.ip}</DataInfo>
          </Data>
          <Data>
            <DataLabel>Mídia</DataLabel>
            <DataInfo>{sale.utm_medium ? sale.utm_medium : '-'}</DataInfo>
          </Data>
          <Data>
            <DataLabel>Campanha</DataLabel>
            <DataInfo>{sale.utm_campaign ? sale.utm_campaign : '-'}</DataInfo>
          </Data>
        </DataColumn>
      </Infos>
      {sale.status !== 'ANALISE_FRAUDE' &&
        sale.motivo_cancelamento &&
        sale.motivo_cancelamento.length > 0 && (
          <Data>
            <DataLabel>Motivo Cancelamento</DataLabel>
            <DataInfo>{sale.motivo_cancelamento}</DataInfo>
          </Data>
        )}
      {sale.status === 'ANALISE_FRAUDE' && (
        <div>
          <Divider />
          <Actions>
            <RadioGroup
              aria-label="status"
              name="status"
              value={FraudAnalysis[sale.venda_id] || null}
              onChange={e => {
                const statusValue = e.target.value;
                const newValue = (old: IFraudAnalysis) => ({
                  ...old,
                  [sale.venda_id]: statusValue,
                });
                setFraudAnalysis(newValue as unknown as IFraudAnalysis);
              }}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormControlLabel
                value="LEGITIMO"
                style={{ color: '#757575' }}
                control={<Radio />}
                label="Legítimo"
              />
              <FormControlLabel
                value="FRAUDE"
                style={{ color: '#757575' }}
                control={<Radio />}
                label="Fraude"
              />
            </RadioGroup>
          </Actions>
        </div>
      )}
    </Container>
  );
}

export default MobileSaleCard;
