import useSWR from 'swr';
import api from 'services/api';
import {
  IntegrationError,
  IntegrationErrorResponse,
} from 'models/integrationErrors';

export const IntegrationErrorFetch = (integrationErrorId: number) => {
  const { data, mutate } = useSWR<IntegrationError>(
    `integration_error_${integrationErrorId}`,
    async (): Promise<IntegrationError> => {
      const { data: results } = await api.get(
        `/core/erros-integracao/${integrationErrorId}`,
      );

      return results as IntegrationError;
    },
  );
  return { data, mutate };
};

export const AllIntegrationErrorFetch = (page?: number) => {
  const { data, mutate } = useSWR<IntegrationErrorResponse>(
    `all_integration_errors_${page}`,
    async (): Promise<IntegrationErrorResponse> => {
      const response = await api.get(`/core/erros-integracao/todos/${page}`);

      const paginationInfo = {
        currentPage: parseInt(response.headers['x-current-page'], 10),
        lastPage: parseInt(response.headers['x-last-page'], 10),
        perPage: parseInt(response.headers['x-per-page'], 10),
        total: parseInt(response.headers['x-total-count'], 10),
      };

      const integrationErrors = response.data as IntegrationError[];

      return {
        integrationErrors,
        paginationInfo,
      } as IntegrationErrorResponse;
    },
  );
  return { data, mutate };
};

export const IntegrationErrorByTIMTypeEligibilityFetch = () => {
  const { data, mutate } = useSWR<IntegrationError[]>(
    `integration_error_by_TIM_type_eligibility`,
    async (): Promise<IntegrationError[]> => {
      const { data: results } = await api.get(`/core/erros-integracao/`);

      return results as IntegrationError[];
    },
  );
  return { data, mutate };
};
