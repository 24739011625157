import { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { green } from '@mui/material/colors';
import useBreakpoint from 'hooks/useBreakpoint';
import api from 'services/api';
import { ButtonProgress } from 'components/StyledComponents';
import { Ellipse, Row, SubTitle } from './styles';
import { SynchronizePlansProps } from './types';
import AttackGroupEligibility from './AttackGroupEligibility';
import DefenseGroupEligibility from './DefenseGroupEligibility';

function SynchronizePlans({
  showSynchronizePlans,
  setShowSynchronizePlans,
}: SynchronizePlansProps) {
  const [successAttackEligibility, setSuccessAttackEligibility] =
    useState(false);
  const [successDefenseEligibility, setSuccessDefenseEligibility] =
    useState(false);
  const [successPlanPricesSync, setSuccessPlanPricesSync] = useState(false);
  const [successLoyaltyPricesSync, setSuccessLoyaltyPricesSync] =
    useState(false);
  const [loadingPlanPrices, setLoadingPlanPrices] = useState(false);
  const [loadingLoyaltyPrices, setLoadingLoyaltyPrices] = useState(false);

  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);

  const clearForm = useCallback(() => {
    setSuccessAttackEligibility(false);
    setSuccessDefenseEligibility(false);
    setSuccessPlanPricesSync(false);
    setSuccessLoyaltyPricesSync(false);
  }, []);

  const handlePlanPricesSync = () => {
    setLoadingPlanPrices(true);
    const data = {
      preco_principal_ataque_ja_sincronizado: successAttackEligibility,
      preco_principal_defesa_ja_sincronizado: successDefenseEligibility,
    };

    api
      .post(`/core/planos/sincronizar-precos-planos`, data)
      .then(() => {
        setLoadingPlanPrices(false);
        setSuccessPlanPricesSync(true);
      })
      .catch(() => {
        setLoadingPlanPrices(false);
      });
  };

  const handleLoyaltyPricesSync = () => {
    setLoadingLoyaltyPrices(true);
    const data = {
      preco_principal_ataque_ja_sincronizado: successAttackEligibility,
      preco_principal_defesa_ja_sincronizado: successDefenseEligibility,
    };

    api
      .post(`/core/planos/sincronizar-precos-fidelidades`, data)
      .then(() => {
        setLoadingLoyaltyPrices(false);
        setSuccessLoyaltyPricesSync(true);
      })
      .catch(() => {
        setLoadingLoyaltyPrices(false);
      });
  };

  return (
    <Dialog
      open={showSynchronizePlans}
      maxWidth="xs"
      fullWidth
      aria-labelledby="form-dialog-title"
      fullScreen={isTabletSizeOrSmaller}
      onClose={() => setShowSynchronizePlans(false)}
    >
      <DialogTitle style={{ padding: '16px 24px 0px' }}>
        Sincronizar planos e preços
      </DialogTitle>
      <DialogContentText
        style={{ padding: '0px 24px', fontSize: '12px', marginBottom: '0px' }}
      >
        Para realizar a sincronização dos planos e tabelas de preços é simples!{' '}
        <br />
        Basta sincronizar os planos e fidelidades dos dois grupos de DDDs.
      </DialogContentText>
      <DialogContent>
        <AttackGroupEligibility
          successAttackEligibility={successAttackEligibility}
          setSuccessAttackEligibility={setSuccessAttackEligibility}
        />

        <DefenseGroupEligibility
          successDefenseEligibility={successDefenseEligibility}
          setSuccessDefenseEligibility={setSuccessDefenseEligibility}
        />
        <Row>
          <Ellipse>
            <span>3</span>
          </Ellipse>
          <SubTitle>Sincronia de preços</SubTitle>
        </Row>
        {!successPlanPricesSync ? (
          <Button
            type="button"
            color="secondary"
            fullWidth
            size="large"
            variant="outlined"
            style={{ marginBottom: '20px' }}
            disabled={!successAttackEligibility || !successDefenseEligibility}
            onClick={() => handlePlanPricesSync()}
          >
            Sincronizar preço de planos
            {loadingPlanPrices && <ButtonProgress size={24} />}
          </Button>
        ) : (
          <Row>
            <DoneIcon style={{ color: green[500], marginRight: 5 }} />
            <SubTitle>Preço dos planos sincronizado com sucesso </SubTitle>
          </Row>
        )}
        {!successLoyaltyPricesSync ? (
          <Button
            type="button"
            color="secondary"
            fullWidth
            size="large"
            variant="outlined"
            style={{ marginBottom: '25px' }}
            disabled={!successAttackEligibility || !successDefenseEligibility}
            onClick={() => handleLoyaltyPricesSync()}
          >
            Sincronizar preço de fidelidade
            {loadingLoyaltyPrices && <ButtonProgress size={24} />}
          </Button>
        ) : (
          <Row>
            <DoneIcon style={{ color: green[500], marginRight: 5 }} />
            <SubTitle>Preço de fidelidade sincronizado com sucesso</SubTitle>
          </Row>
        )}
      </DialogContent>
      <DialogActions
        style={{
          paddingRight: '24px',
          marginLeft: 'auto',
          marginBottom: '15px',
        }}
      >
        <Button
          onClick={() => {
            setShowSynchronizePlans(false);
            clearForm();
          }}
          variant="contained"
          color="primary"
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SynchronizePlans;
