import { Grid } from '@mui/material';
import { List } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { CategoryPackage, Package } from 'models/packages';
import categoryTitleSetup from './categoryTitleSetup';
import Item from './Item';
import MobileItem from './MobileItem';

import {
  Box,
  Card,
  CategoryHeader,
  CategoryTitle,
  Container,
  NotList,
} from './styles';

interface LoadingListProps {
  isTableOrLess: boolean;
}
interface BodyProps {
  packages: CategoryPackage[];
  isMobile: boolean;
  setPackageSelected(value: Package): void;
}

interface PackageListProps {
  packages?: CategoryPackage[];
  setPackageSelected(value: Package): void;
}

function LoadingList({ isTableOrLess }: LoadingListProps) {
  return isTableOrLess ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <Grid container>
      <Grid item xs={1} md={6} container>
        <Grid item container direction="column" columns={20} spacing={4}>
          <Grid item xs={1} md={6}>
            <List>
              <Item loading />
              <Item loading />
              <Item loading />
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
function Body({ packages, isMobile, setPackageSelected }: BodyProps) {
  const differentUnratedPackages = packages?.filter(
    item => item.categoria !== 'SEM_CLASSIFICACAO',
  );

  const unClassifiedPackage = packages?.filter(
    item => item.categoria === 'SEM_CLASSIFICACAO',
  );

  return (
    <Card>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            {differentUnratedPackages.map(category => (
              <Box key={category.categoria}>
                <CategoryTitle>
                  {categoryTitleSetup[category.categoria]?.label}
                </CategoryTitle>
                {!isMobile && (
                  <CategoryHeader>
                    <span>ID</span>
                    <span>Nome</span>
                    <span>GB</span>
                  </CategoryHeader>
                )}
                <List>
                  {category.pacotes.map(item =>
                    isMobile ? (
                      <MobileItem
                        key={item.id}
                        data={item}
                        category={category.categoria}
                        setPackageSelected={setPackageSelected}
                      />
                    ) : (
                      <Item
                        key={item.id}
                        data={item}
                        category={category.categoria}
                        setPackageSelected={setPackageSelected}
                      />
                    ),
                  )}
                </List>
              </Box>
            ))}
          </Grid>

          <Grid item xs={12} md={5} className="grid-item">
            <CategoryTitle>Sem Classificação</CategoryTitle>
            {unClassifiedPackage.length > 0 ? (
              <>
                {!isMobile && (
                  <CategoryHeader>
                    <span>ID</span>
                    <span>Nome</span>
                  </CategoryHeader>
                )}
                {unClassifiedPackage.map(category => (
                  <List key={category.categoria}>
                    {category.pacotes.map(item =>
                      isMobile ? (
                        <MobileItem
                          key={item.id}
                          data={item}
                          category={category.categoria}
                          setPackageSelected={setPackageSelected}
                        />
                      ) : (
                        <Item
                          key={item.id}
                          data={item}
                          category={category.categoria}
                          setPackageSelected={setPackageSelected}
                        />
                      ),
                    )}
                  </List>
                ))}
              </>
            ) : (
              <NotList>
                <span>Sem pacotes para classificar</span>
              </NotList>
            )}
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
}

function PackageList({ packages, setPackageSelected }: PackageListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  return (
    <div>
      {packages ? (
        <Body
          packages={packages}
          isMobile={isTableOrLess}
          setPackageSelected={setPackageSelected}
        />
      ) : (
        <LoadingList isTableOrLess={isTableOrLess} />
      )}
    </div>
  );
}

export default PackageList;
