import { useCallback, useState } from 'react';
import { ButtonProgress } from 'components/StyledComponents';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useStylesModal } from 'components/UseStylesModal';
import api from 'services/api';
import { useToast } from 'hooks/toast';

interface DeleteSelectedProps {
  checkData: number[];
  setCheckData(value: number[]): void;
  updateDataList(): void;
}
function DeleteSelected({
  checkData,
  setCheckData,
  updateDataList,
}: DeleteSelectedProps) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const classes = useStylesModal();
  const { addToast } = useToast();

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = useCallback(() => {
    setLoading(true);
    api
      .put(`/core/dados-blacklist/excluir`, checkData)
      .then(() => {
        updateDataList();
        addToast({
          type: 'success',
          title: 'Dados excluídos',
          description: `Dado excluído com sucesso!`,
        });
        setLoading(false);
        handleClose();
        setCheckData([]);
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro ao excluir dado',
          description: error?.data?.message,
        });
        setLoading(false);
        handleClose();
      });
  }, [addToast, checkData, setCheckData, updateDataList]);

  const isSelected = checkData.length === 0;
  return (
    <>
      <Button
        type="button"
        variant="outlined"
        size="small"
        className={classes.buttonCancel}
        disabled={isSelected || loading}
        onClick={() => setOpen(true)}
      >
        Excluir selecionados
      </Button>

      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        maxWidth="xs"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            color="primary"
          >
            Você deseja mesmo excluir todos esses dados blacklist? Esta ação não
            poderá ser desfeita.
          </DialogContentText>
        </DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 20,
          }}
        >
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleDelete();
            }}
            color="error"
          >
            Excluir dados
            {loading && <ButtonProgress size={24} />}
          </Button>
        </div>
      </Dialog>
    </>
  );
}

export default DeleteSelected;
