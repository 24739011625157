import { useState } from 'react';
import { BsArrowUpRight } from 'react-icons/bs';
import { MdLaunch, MdContentCopy } from 'react-icons/md';

import copyToClipboard from 'util/copyToClipboard';
import { v4 as uuidv4 } from 'uuid';
import { IconButton, Tooltip } from '@mui/material';
import {
  Container,
  Header,
  Title,
  Content,
  HeaderTitle,
  ActionButtons,
} from './styles';
import LogHighlighter from '../LogHighlighter';

interface ApiLogHighlighterProps {
  log: string;
  title: 'request' | 'response';
}

function ApiLogHighlighter({ log, title }: ApiLogHighlighterProps) {
  const id = `item_${uuidv4()}`;
  const [showToolTip, setShowToolTip] = useState(false);
  return (
    <Container id={id}>
      <Header>
        <HeaderTitle colorTitle={title}>
          <BsArrowUpRight />
          <Title>{title}</Title>
        </HeaderTitle>
        <ActionButtons>
          <MdLaunch />

          <Tooltip
            title="copiado"
            placement="top-end"
            open={showToolTip}
            onClose={() => {
              setTimeout(() => {
                setShowToolTip(false);
              }, 1000);
            }}
          >
            <IconButton
              onClick={() => {
                const copied = copyToClipboard(log, id);
                setShowToolTip(copied);
              }}
              className="button"
            >
              <MdContentCopy />
            </IconButton>
          </Tooltip>
        </ActionButtons>
      </Header>
      <Content>
        <LogHighlighter log={log} customStyle={{ background: '#050c27' }} />
      </Content>
    </Container>
  );
}

export default ApiLogHighlighter;
