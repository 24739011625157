import create from 'zustand';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type GenericObject<KeyValue = any> = {
  [k: string]: KeyValue;
};

export type ConfigurationUtmStore = {
  idConfiguration: number | null;
  nameConfiguration: string;
  value: {
    sources: {
      id: string | null;
      nome: string;
      porcentagem: number;
      campaigns: {
        id: number | null;
        nome: string;
        porcentagem: number;
      }[];
    }[];
    [key: string]: any;
  };
  setIdConfiguration(id: number): void;
  setNameConfiguration(nameConfiguration: string): void;
  setConfigurationUtm(config: GenericObject): void;
  clearConfigurationUtm(): void;
};

const defaultConfigurationUtm = {
  sources: [
    {
      id: null,
      nome: '',
      porcentagem: 0,
      campaigns: [
        {
          id: null,
          nome: '',
          porcentagem: 0,
        },
      ],
    },
  ],
};

export const useConfigurationUtmStore = create<ConfigurationUtmStore>(set => ({
  idConfiguration: null,
  setIdConfiguration(id) {
    set(state => ({ ...state, configId: id }));
  },

  nameConfiguration: '',
  setNameConfiguration(name) {
    set(state => ({ ...state, nameConfiguration: name }));
  },

  value: defaultConfigurationUtm,
  setConfigurationUtm(config: GenericObject) {
    set(state => ({ ...state, value: { ...state.value, ...config } }));
  },

  clearConfigurationUtm() {
    set(state => ({
      ...state,
      value: defaultConfigurationUtm,
      nameConfiguration: '',
      idConfiguration: null,
    }));
  },
}));
