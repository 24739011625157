import userSWR from 'swr';
import api from 'services/api';
import { ConfigurationByIdPlan } from 'models/configurationsPackage';

export const ConfigurationPackageByIdPlanFetch = (idPlano: number) => {
  const { data } = userSWR<ConfigurationByIdPlan[]>(
    `configuration_${idPlano}`,
    async (): Promise<ConfigurationByIdPlan[]> => {
      const { data: results } = await api.get(
        `/core/pacote-configuracoes/ofertas-plano/${idPlano}`,
      );

      return results as ConfigurationByIdPlan[];
    },
  );

  return { data };
};
