import { PlanByID } from 'models/planById';
import create from 'zustand';

const defaultPlan: PlanByID = {
  id: 0,
  segmento: '',
  tim_id: '',
  nome: '',
  descricao: '',
  m4u_id: '',
  correspondente: '',
  correspondente_id: null,
  prioridade: 0,
  oferta_especial: 'Y',
  requer_aprovacao: true,
  ativo: true,
  travar_sincronia: false,
  exibir_ecommerce: true,
  sumario_url: '',
  ataque_url: '',
  defesa_url: '',
  regulamento: '',
  regulamento_plano: null,
  beneficios: [],
  fidelidades: [],
  pacotes: [],
  precos: [],
};

type PlanStore = {
  plan: PlanByID;
  setPlan(plan: PlanByID): void;
};

export const usePlanStore = create<PlanStore>(set => ({
  plan: defaultPlan,
  setPlan(plan: PlanByID) {
    set(state => ({ ...state, plan }));
  },
}));
