import { TotalizatorLeads } from 'models/totalizatorLeads';
import api from 'services/api';
import userSWR from 'swr';

export const TotalizatorDaysForCronFetch = () => {
  const { data, mutate: mutateTotalDays } = userSWR<TotalizatorLeads>(
    `total_days_for_cron`,
    async (): Promise<TotalizatorLeads> => {
      const { data: total } = await api.get(`core/vendas/total-dias-para-cron`);

      return total as TotalizatorLeads;
    },
  );

  return { data, mutateTotalDays };
};
