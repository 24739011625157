import { Box, Card, Grid, Icon, Skeleton, Typography } from '@mui/material';

import { TotalizatorFetch } from 'fetches/totalizatorFetch';
import useBreakpoint from 'hooks/useBreakpoint';
import TypeIndicator from 'pages/ManagementReport/components/TypeIndicator';
import { useReportFilterFlagFetchStore } from 'store/useReportFilterFlagFetchStore';
import useStyles, { BoxIcon, Content } from './styles';
import { cardConfig } from './cardConfig';

interface ControleProps {
  type: string;
}
function CardTypeIndicator({ type }: ControleProps) {
  const start = useReportFilterFlagFetchStore(state => state.startFetch);
  const end = useReportFilterFlagFetchStore(state => state.endFetch);
  const reprocessed = useReportFilterFlagFetchStore(
    state => state.withReprocessedFetch,
  );
  const inReprocessing = useReportFilterFlagFetchStore(
    state => state.inReprocessingFetch,
  );
  const engagement = useReportFilterFlagFetchStore(
    state => state.withEngagementFetch,
  );

  const { data } = TotalizatorFetch(
    cardConfig[type].segmento,
    start,
    end,
    reprocessed,
    inReprocessing,
    engagement,
  );

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(960);
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <BoxIcon color={cardConfig[type].boxIconColor}>
        <Icon
          component={cardConfig[type].icon}
          sx={{ color: cardConfig[type].colorIcon }}
        />
      </BoxIcon>
      <Content color={cardConfig[type].contentColor}>
        <Grid item className={classes.contentTotal}>
          <Box>
            <Typography
              className={classes.subtitle}
              variant="body2"
              color={cardConfig[type].subtitleColor}
            >
              Total
            </Typography>
            <Typography
              className={classes.title}
              color={cardConfig[type].titleColor}
              variant="subtitle1"
            >
              {cardConfig[type].title}
            </Typography>
          </Box>
          <Typography
            className={classes.h3}
            color={cardConfig[type].totalColor}
            variant="h3"
          >
            {!data ? (
              <Skeleton variant="rectangular" width={80} height={30} />
            ) : (
              data.total
            )}
          </Typography>
        </Grid>
        {data ? (
          <TypeIndicator
            cardType={type}
            migracao={data?.migracao}
            novaLinha={data?.nova_linha}
            portabilidade={data?.portabilidade}
          />
        ) : (
          <Skeleton
            variant="rectangular"
            width={isTableOrLess ? 150 : 40}
            height={isTableOrLess ? 40 : 120}
            sx={{ margin: '0px 5px 5px 0px', alignSelf: 'end' }}
          />
        )}
      </Content>
    </Card>
  );
}

export default CardTypeIndicator;
