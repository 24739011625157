import {
  MobileContainer,
  MobileData,
  MobileDataLabel,
  MobileTextName,
} from 'components/StyledComponents';
import { Checkbox, IconButton, Skeleton } from '@mui/material';
import { GroupData } from 'components/MobileList';
import { useNavigate } from 'react-router-dom';
import { cpf as cpfFormatter } from 'util/formatter';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ItemProps } from '../Item';
import { ActionButtons } from '../Item/styles';

function MobileItem({
  data,
  loading,
  selected,
  handleCheckData,
  setDataSelected,
  handleOpenModalDelete,
}: ItemProps) {
  const navigate = useNavigate();

  const dado =
    typeof Number(data?.dado) === 'number'
      ? cpfFormatter(data?.dado)
      : data?.dado;
  return (
    <MobileContainer>
      {loading ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        data && (
          <>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Tipo</MobileDataLabel>
                <MobileTextName>{data.tipo}</MobileTextName>
              </MobileData>
              <ActionButtons>
                <IconButton
                  onClick={() => {
                    navigate(
                      `/dados-blacklist/edita-dado-blacklist/${data.id}`,
                    );
                  }}
                  disabled={loading}
                >
                  <FiEdit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleOpenModalDelete && handleOpenModalDelete();
                    setDataSelected && setDataSelected(data);
                  }}
                  disabled={loading}
                >
                  <RiDeleteBinLine color="#C92A2A" />
                </IconButton>
                <Checkbox
                  checked={selected}
                  inputProps={{ 'aria-label': 'controlled' }}
                  disabled={loading}
                  onChange={() => handleCheckData && handleCheckData(data.id)}
                />
              </ActionButtons>
            </GroupData>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Dado</MobileDataLabel>
                <MobileTextName>{dado}</MobileTextName>
              </MobileData>
            </GroupData>
          </>
        )
      )}
    </MobileContainer>
  );
}

export default MobileItem;
