import { useEffect, useState } from 'react';
import { Button, CardHeader } from '@mui/material';
import { Card, Header } from 'components/StyledComponents';
import { useAuth } from 'hooks/auth';
import { Add } from '@mui/icons-material';
import SynchronizePlans from 'pages/ManagerPlan/SynchronizePlans';
import palette from 'theme/palette';
import { planosSincronizar } from 'constants/permissions';
import useBreakpoint from 'hooks/useBreakpoint';
import { useParams } from 'react-router-dom';
import { SubHeader } from 'pages/ManagerPlan/EditPlan/styles';
import { LoyaltyByIdFetch } from 'fetches/loyaltyByIdFetch';
import PriceTableItem from 'pages/ManagerPlan/EditPlan/components/PriceTableItem';

function LoyaltyPriceTable() {
  const params = useParams();
  const { id } = params;
  const { data: loyalty } = LoyaltyByIdFetch(parseInt(id || '0', 10));
  const [loading, setLoading] = useState(true);
  const [showSynchronizePlans, setShowSynchronizePlans] = useState(false);
  const [showButtonPlanSync, setShowButtonPlanSync] = useState(false);

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  const { hasPermission } = useAuth();

  useEffect(() => {
    if (!loyalty) return;

    setLoading(false);
  }, [loyalty]);

  useEffect(() => {
    const shouldShow = hasPermission(planosSincronizar);

    setShowButtonPlanSync(shouldShow);
  }, [hasPermission]);
  return (
    <Card>
      <Header>
        <CardHeader
          title="Gestão de planos"
          subheader="Painel de informações dos planos e ofertas TIM."
        />

        {showButtonPlanSync && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowSynchronizePlans(true)}
            >
              {isTableOrLess ? (
                <Add style={{ color: palette.white }} />
              ) : (
                'Sincronizar planos'
              )}
            </Button>
            <SynchronizePlans
              showSynchronizePlans={showSynchronizePlans}
              setShowSynchronizePlans={setShowSynchronizePlans}
            />
          </>
        )}
      </Header>
      <SubHeader>Tabela de preços de fidelidade</SubHeader>

      <PriceTableItem
        loyalty="loyalty"
        prices={loyalty?.prices}
        name={loyalty?.nome}
        loading={loading}
      />

      <Button
        onClick={() => window.history.back()}
        type="button"
        variant="outlined"
        disabled={loading}
        style={{ marginLeft: 17, marginTop: 15 }}
      >
        Voltar
      </Button>
    </Card>
  );
}

export default LoyaltyPriceTable;
