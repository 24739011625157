import { useSelectFilterStore } from 'store/selectFilterStore';
import { SelectOptions } from '../Selects';

export function usePopoverActions() {
  const statusCrossCoreSelecionados = useSelectFilterStore(
    store => store.statusCrosscoreSelecionados,
  );
  const setStatusCrosscoreSelecionados = useSelectFilterStore(
    store => store.setStatusCrosscoreSelecionados,
  );

  const requestedDocumentationSelecionado = useSelectFilterStore(
    store => store.requestedDocumentation,
  );
  const setRequestedDocumentationSelecionado = useSelectFilterStore(
    store => store.setRequestedDocumentation,
  );

  const start = useSelectFilterStore(store => store.dateStartSelecionado);
  const setStart = useSelectFilterStore(store => store.setDateStartSelecionado);
  const end = useSelectFilterStore(store => store.dateEndSelecionado);
  const setEnd = useSelectFilterStore(store => store.setDateEndSelecionado);

  const handleStartChange = (date: Date | string | null) => {
    if (typeof date === 'string') {
      setStart(new Date(date));
      return;
    }
    date && setStart(date);
  };

  const handleEndChange = (date: Date | string | null) => {
    if (typeof date === 'string') {
      setEnd(new Date(date));
      return;
    }
    date && setEnd(date);
  };

  const handleCheckStatusCrosscore = (selected: SelectOptions) => {
    const statusExist = statusCrossCoreSelecionados.find(
      item => selected.value === item.value,
    );
    if (statusExist) {
      setStatusCrosscoreSelecionados(
        statusCrossCoreSelecionados.filter(
          item => selected.value !== item.value,
        ),
      );
      return;
    }
    setStatusCrosscoreSelecionados([...statusCrossCoreSelecionados, selected]);
  };

  return {
    statusCrossCoreSelecionados,
    requestedDocumentationSelecionado,
    start,
    end,
    handleStartChange,
    handleEndChange,
    handleCheckStatusCrosscore,
    setRequestedDocumentationSelecionado,
  };
}
