import React from 'react';
import { Box, Card, Grid, Skeleton, Typography } from '@mui/material';

import { AverageFetch } from 'fetches/averageFetch';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { useReportFilterFlagFetchStore } from 'store/useReportFilterFlagFetchStore';
import useStyles from './styles';

function AverageSales() {
  const start = useReportFilterFlagFetchStore(state => state.startFetch);
  const end = useReportFilterFlagFetchStore(state => state.endFetch);
  const reprocessed = useReportFilterFlagFetchStore(
    state => state.withReprocessedFetch,
  );
  const inReprocessing = useReportFilterFlagFetchStore(
    state => state.inReprocessingFetch,
  );
  const engagement = useReportFilterFlagFetchStore(
    state => state.withEngagementFetch,
  );

  const { data } = AverageFetch(
    start,
    end,
    reprocessed,
    inReprocessing,
    engagement,
  );
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item>
          <Box className={classes.box}>
            <DateRangeOutlinedIcon className={classes.icon} />
          </Box>
        </Grid>
        <Grid item className={classes.content}>
          <Box>
            <Typography className={classes.title} variant="body2">
              Média no período
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle1">
              {!data
                ? 'Em dia(s) fechados (-)'
                : `Em ${data.dias} dia(s) fechados`}
            </Typography>
          </Box>
          <Typography className={classes.h3} variant="h3">
            {!data ? (
              <Skeleton variant="rectangular" width={80} height={30} />
            ) : (
              data.media
            )}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

export default AverageSales;
