import { CardHeader } from '@mui/material';
import { Card } from 'components/StyledComponents';
import { SalesPagination } from 'pages/Sale/style';
import Pagination from 'components/Pagination';
import { SalesCorrectionFetch } from 'fetches/salesCorrectionFetch';
import { useState } from 'react';
import { Content } from 'components/CardList';
import SalesCorrectionList from './SalesCorrectionList';

function SalesCorrection() {
  const [currentPage, setCurrentPage] = useState(1);

  const { data: response, mutate } = SalesCorrectionFetch({
    page: currentPage,
    tratavel: '1',
  });

  return (
    <Card>
      <CardHeader
        title="Correção de Vendas"
        subheader="Painel de correção de vendas"
      />
      <Content>
        <SalesCorrectionList sales={response?.sales} updateSales={mutate} />
        <SalesPagination>
          {response && (
            <Pagination
              currentPage={currentPage}
              lastPage={response?.paginationInfo?.lastPage}
              perPage={response?.paginationInfo?.perPage}
              total={response?.paginationInfo?.total}
              setCurrentPage={setCurrentPage}
            />
          )}
        </SalesPagination>
      </Content>
    </Card>
  );
}

export default SalesCorrection;
