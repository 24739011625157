import { Loyalty } from 'models/loyalties';
import { useEffect, useState } from 'react';
import DialogPlan from '../DialogPlan';

interface DialogPlanFidelityProps {
  data?: Loyalty[];
  isOpen: boolean;
  onUpdate?: (value: string) => void;
  onClose: () => void;
}

function DialogPlanFidelity({
  data,
  isOpen,
  onUpdate,
  onClose,
}: DialogPlanFidelityProps) {
  const [selectedData, setSelectedData] = useState('');

  useEffect(() => {
    if (!data?.length) return;

    setSelectedData(String(data[0].id));
  }, [data]);

  return (
    <DialogPlan
      title="Anexar Fidelidade"
      subtitle="Selecione uma fidelidade que deseja adicionar ao plano"
      label="Selecione uma fidelidade"
      isOpen={isOpen}
      onClose={onClose}
      onChange={value => {
        setSelectedData(value);
      }}
      onUpdate={() => {
        onUpdate?.(selectedData);
      }}
    >
      {data?.map(item => (
        <option key={item.id} value={String(item.id)}>
          {item.tim_id} - {item.nome}
        </option>
      ))}
    </DialogPlan>
  );
}
export default DialogPlanFidelity;
