import styled from 'styled-components';

export const Status = styled.img`
  align-self: flex-end;
`;

export const GroupCard = styled.div`
  padding: 16px;
`;

export const Action = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const MobileDataText = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 15px;

  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
`;
