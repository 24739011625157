import { Container } from 'components/CardList';
import { CardHeader } from '@mui/material';
import { List } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { Role } from 'models/roles';
import { Header } from './styles';
import Item from './Item';
import MobileItem from './MobileItem';

interface BrodyProps {
  roles: Role[];
  isMobile: boolean;
}

interface RoleListProps {
  roles?: Role[];
}
interface LoadingListProps {
  isMobile: boolean;
}

function LoadingList({ isMobile }: LoadingListProps) {
  if (isMobile) {
    return (
      <>
        <MobileItem loading />
        <MobileItem loading />
        <MobileItem loading />
        <MobileItem loading />
      </>
    );
  }
  return (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({ roles, isMobile }: BrodyProps) {
  return (
    <List>
      {roles.length ? (
        roles.map(item =>
          isMobile ? (
            <MobileItem key={String(item.id)} data={item} />
          ) : (
            <Item key={String(item.id)} data={item} />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhuma função</h3>
      )}
    </List>
  );
}

function RoleList({ roles }: RoleListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);
  return (
    <Container>
      {!isTableOrLess && (
        <>
          <CardHeader title="Funções" />
          <Header>
            <span>Nome</span>
            <span>Data de Inclusão</span>
          </Header>
        </>
      )}
      {roles ? (
        <Body roles={roles} isMobile={isTableOrLess} />
      ) : (
        <LoadingList isMobile={isTableOrLess} />
      )}
    </Container>
  );
}

export default RoleList;
