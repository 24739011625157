import styled from 'styled-components';

export const DataInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 24px;
`;

export const DataTextName = styled.span`
  min-width: 200px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  display: flex;
  align-items: center;

  @media (min-width: 1400px) {
    width: 350px;
  }
`;

export const DataText = styled.span`
  min-width: 115px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  display: flex;
  align-items: center;

  :nth-child(4) {
    min-width: 75px;
  }

  :nth-child(5) {
    color: rgb(43, 138, 62);
    min-width: 103px;
  }

  :nth-child(6) {
    min-width: 50px;
  }
`;
