import styled from 'styled-components';

export const BenefitsList = styled.div`
  padding: 16px;
  border: 1px solid #e9e9ee;
  border-radius: 3px;
  margin: 32px 0;
`;

export const BenefitsItemList = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 16px;
  gap: 16px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);

  .status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }

  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  .description {
    flex: 2;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  @media (min-width: 460px) {
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;

    .description {
      flex: 2;
    }

    .actions {
      align-items: center;
      justify-content: space-between;
    }
  }

  @media (min-width: 1024px) {
    .description {
      max-width: 100%;
    }
  }
`;

export const BenefitsItemAction = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 0;
  cursor: pointer;
`;
