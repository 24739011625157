import { makeStyles } from '@mui/styles';
import { MTheme } from 'models/theme';

export const useStyles = makeStyles((theme: MTheme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '33px',

    [theme.breakpoints.down(1024)]: {
      flexDirection: 'column',
      alignItems: 'start',
      marginBottom: '44px',
    },
  },
  buttonPlanSync: {
    [theme.breakpoints.down(1024)]: {
      width: '95%',
      margin: 'auto',
    },
  },
}));
