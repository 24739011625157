import { useStylesModal } from 'components/UseStylesModal';
import { useCallback, useState } from 'react';
import { Button, DialogActions, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { ButtonProgress } from 'components/StyledComponents';
import { ModalCard } from 'pages/CmsLP/components/DeleteModal/styles';

interface StoreBaseProps {
  baseId: number;
  fetchBaseData(): void;
}
function StoreBase({ baseId, fetchBaseData }: StoreBaseProps) {
  const classes = useStylesModal();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const handleClose = () => {
    setOpen(false);
  };

  const handleStoreBase = useCallback(() => {
    setLoading(true);
    api
      .put(`/core/bases-clientes-impactar/armazenar-base/${baseId}`)
      .then(() => {
        fetchBaseData();
        addToast({
          type: 'success',
          title: 'Base Armazenada',
          description: 'A base foi armazenada com sucesso.',
        });
        setLoading(false);
        handleClose();
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro ao armazenar base',
          description: error?.data?.message,
        });
        setLoading(false);
        handleClose();
      });
  }, [baseId, fetchBaseData, addToast]);

  return (
    <>
      <div style={{ marginLeft: 'auto' }}>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => setOpen(true)}
          disabled={loading}
        >
          Armazenar Base
        </Button>
      </div>
      <Modal
        aria-labelledby="tile-modal"
        aria-describedby="modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={open}>
          <ModalCard>
            <Typography>
              Você deseja mesmo armazenar essa base? As informações consolidadas
              continuarão disponíveis mas não será mais possível ver os leads e
              erros.
            </Typography>
            <DialogActions className={classes.dialogActions}>
              <Button variant="outlined" onClick={() => handleClose()}>
                Voltar
              </Button>
              <Button
                onClick={handleStoreBase}
                variant="contained"
                color="secondary"
                disabled={loading}
              >
                Armazenar Base
                {loading && <ButtonProgress size={24} />}
              </Button>
            </DialogActions>
          </ModalCard>
        </Fade>
      </Modal>
    </>
  );
}

export default StoreBase;
