import { IconButton, Skeleton } from '@mui/material';
import { Action, GroupData, MobileDataText } from 'components/MobileList';
import {
  ActionButtons,
  MobileContainer,
  MobileData,
  MobileDataLabel,
  useStylesActionButtons,
} from 'components/StyledComponents';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { ItemProps } from '../types';

function MobileItem({
  data,
  loading = false,
  setOfferSelected,
  handleOpenDeleteOfferDialog,
}: ItemProps) {
  const navigate = useNavigate();
  const classes = useStylesActionButtons();
  return (
    <MobileContainer>
      {loading ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        data && (
          <>
            <Action>
              <ActionButtons>
                <IconButton
                  onClick={() =>
                    navigate(`/links-ofertas/edita-link/${data?.id}`)
                  }
                  className={classes.iconEdit}
                  disabled={loading}
                >
                  <FiEdit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleOpenDeleteOfferDialog &&
                      handleOpenDeleteOfferDialog();
                    setOfferSelected && data && setOfferSelected(data);
                  }}
                  className={classes.iconDelete}
                  disabled={loading}
                >
                  <RiDeleteBinLine />
                </IconButton>
              </ActionButtons>
            </Action>
            <>
              <GroupData>
                <MobileData>
                  <MobileDataLabel>URL</MobileDataLabel>
                  <MobileDataText>{data.url_slug}</MobileDataText>
                </MobileData>
              </GroupData>
              <GroupData>
                <MobileData>
                  <MobileDataLabel>Fidelidade</MobileDataLabel>
                  <MobileDataText>{data.fidelidade}</MobileDataText>
                </MobileData>
              </GroupData>
              <MobileData>
                <MobileDataLabel>Plano</MobileDataLabel>
                <MobileDataText>{data.preco_id}</MobileDataText>
              </MobileData>
            </>
          </>
        )
      )}
    </MobileContainer>
  );
}

export default MobileItem;
