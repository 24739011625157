import styled from 'styled-components';
import palette from 'theme/palette';

export const StockCard = styled.div`
  height: 100%;
  border: 1px solid ${palette.borderWhite};
  box-sizing: border-box;
  box-shadow: 0px 0px 1px ${palette.borderWhite};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 8px 16px;

  &:hover {
    background: #d0edfb;
    cursor: pointer;
  }
`;

export const StockCardTitle = styled.span`
  font-weight: 700;
  font-size: 18px;
  color: black;
  padding-bottom: 8px;
`;

export const StockCardCounter = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
`;

export const StockCardCounterNumber = styled.span`
  font-weight: 500;
  font-size: 24px;
  color: black;
`;

export const StockCardCounterUnit = styled.span`
  font-weight: 400;
  padding-top: 4px;
  font-size: 18px;
  color: black;
`;

export const StockCardSubTitle = styled.span`
  font-weight: 400;
  color: #616e98;
  padding-top: 8px;
`;

export const LowStockIcon = styled.img`
  padding-left: 8px;
`;
