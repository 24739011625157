import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';

import React, { useState } from 'react';

import { Search } from '@mui/icons-material';
import useBreakpoint from 'hooks/useBreakpoint';
import { Container, FilterMargin } from './styles';

interface FiltersProps {
  setSearch(search: string): void;
}

function Filters({ setSearch }: FiltersProps) {
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  const [localSearch, setLocalSearch] = useState('');

  return (
    <Container>
      <FilterMargin>
        <FormControl
          variant="outlined"
          margin="none"
          size="small"
          fullWidth={isTabletSizeOrSmaller}
        >
          <InputLabel>Buscar</InputLabel>
          <OutlinedInput
            label="Buscar"
            value={localSearch}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                setSearch(localSearch);
              }
            }}
            onChange={e => {
              if (e.target.value === '') {
                setSearch('');
              }
              setLocalSearch(e.target.value as string);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setSearch(localSearch);
                  }}
                  edge="end"
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </FilterMargin>
    </Container>
  );
}

export default Filters;
