import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 5px 15px 0;
  width: 100%;
`;

export const GroupInput = styled.div`
  input {
    padding: 10.5px 14px;
  }
  label {
    margin-top: -5px;
  }
`;
