import { IconButton } from '@mui/material';
import { MonetizationOn } from '@mui/icons-material';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { FidelityItemAction, FidelityItemList } from './styles';
import DotStatus from '../../components/DotStatus';

export interface FidelityProps {
  tim_id?: string;
  fidelidade_id?: number;
  nome?: string;
  onRemoveItem?: () => void;
}

function FidelityListItem({
  fidelidade_id,
  tim_id,
  nome,
  onRemoveItem,
}: FidelityProps) {
  const navigate = useNavigate();
  return (
    <FidelityItemList>
      <div className="status">
        <DotStatus active />
      </div>
      <p className="text">{tim_id}</p>
      <p className="text description">{nome}</p>
      <div className="actions">
        <IconButton
          color="secondary"
          onClick={() =>
            navigate(`/gestao-planos/tabela-precos-fidelidade/${fidelidade_id}`)
          }
        >
          <MonetizationOn style={{ marginRight: 34 }} />
        </IconButton>
        <FidelityItemAction onClick={onRemoveItem}>
          <RiDeleteBinLine style={{ width: 24, height: 24 }} />
        </FidelityItemAction>
      </div>
    </FidelityItemList>
  );
}
export default FidelityListItem;
