import styled from 'styled-components';
import { makeStyles } from '@mui/styles';

export const TabControl = styled.div`
  border: 1px solid;
  border-radius: 4px;
  margin-bottom: 15px;

  .MuiTabs-root {
    min-height: 40px;
    height: 40px;
    align-items: center;
  }
`;

export const TabPanelControl = styled.div`
  margin: 5px 0px 0px;
`;

export const UseStyles = makeStyles({
  root: {
    borderRight: '1px solid',
    textTransform: 'capitalize',
  },
  numberOfRoot: {
    textTransform: 'capitalize',
  },
  indicator: {
    display: 'none',
    textTransform: 'capitalize',
  },
  selectedNumber: {
    background: 'black',
    color: 'white !important',
    borderRadius: '4px 0px 0px 4px',
    textTransform: 'capitalize',
  },
  selectedQuadraLote: {
    background: 'black',
    color: 'white !important',
    textTransform: 'capitalize',
  },
  selectedNumberOf: {
    background: 'black',
    color: 'white !important',
    borderRadius: '0px 4px 4px 0px',
    textTransform: 'capitalize',
  },
});
