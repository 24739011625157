import { List } from 'components/StyledComponents';
import { Container } from 'components/CardList';
import useBreakpoint from 'hooks/useBreakpoint';
import { UTMConfiguration } from 'models/utmConfiguration';
import Item from './Item';
import MobileItem from './MobileItem';
import { Header } from './styles';

interface UtmDefinitionListProps {
  utmConfigurations?: UTMConfiguration[];
  updateUtmConfigurations(
    utmConfigurations: UTMConfiguration[],
    showRevalid?: boolean,
  ): void;
}

interface LoadingListProps {
  isTableOrLess: boolean;
}

interface BodyProps {
  utmConfigurations: UTMConfiguration[];
  isMobile: boolean;
  updateUtmConfigurations(
    utmConfigurations: UTMConfiguration[],
    showRevalid?: boolean,
  ): void;
}
function LoadingList({ isTableOrLess }: LoadingListProps) {
  return isTableOrLess ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({
  utmConfigurations,
  isMobile,
  updateUtmConfigurations,
}: BodyProps) {
  return (
    <List>
      {utmConfigurations.length ? (
        utmConfigurations.map(data =>
          isMobile ? (
            <MobileItem
              key={data.id}
              utm={data}
              utmConfigurations={utmConfigurations}
              updateUtmConfigurations={updateUtmConfigurations}
            />
          ) : (
            <Item
              key={data.id}
              utm={data}
              utmConfigurations={utmConfigurations}
              updateUtmConfigurations={updateUtmConfigurations}
            />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhuma utm encontrada</h3>
      )}
    </List>
  );
}

function UtmDefinitionList({
  utmConfigurations,
  updateUtmConfigurations,
}: UtmDefinitionListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Container>
      {!isTableOrLess && (
        <Header>
          <span>Nome</span>
          <span>Sources</span>
          <span>Criado</span>
        </Header>
      )}
      {utmConfigurations ? (
        <Body
          utmConfigurations={utmConfigurations}
          updateUtmConfigurations={updateUtmConfigurations}
          isMobile={isTableOrLess}
        />
      ) : (
        <LoadingList isTableOrLess={isTableOrLess} />
      )}
    </Container>
  );
}

export default UtmDefinitionList;
