import React, { useCallback, useState } from 'react';

import {
  cell as cellFormatter,
  cpf as cpfFormatter,
  date as dateFormatter,
} from 'util/formatter';
import copyIcon from 'assets/icons/small/copy.svg';
import { IconButton, Skeleton } from '@mui/material';
import copyToClipboard from 'util/copyToClipboard';
import {
  MobileContainer,
  MobileData,
  MobileDataLabel,
} from 'components/StyledComponents';
import {
  Action,
  GroupData,
  MobileDataText,
  MobileTextName,
} from 'components/MobileList';
import { MoreVert } from '@mui/icons-material';
import { ItemProps } from '../Item';
import PopperActions from '../PopperActions';

function MobileItem({ data, loading = false }: ItemProps) {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElement(anchorElement ? null : event.currentTarget);
    },
    [anchorElement],
  );
  const open = Boolean(anchorElement);
  const id = open ? `popperAction_${data?.id}` : undefined;

  return (
    <MobileContainer>
      {loading ? (
        <Skeleton height={50} />
      ) : (
        <Action>
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>
          {data && (
            <PopperActions
              id={id || ''}
              open={open}
              data={data}
              anchorElement={anchorElement}
            />
          )}
        </Action>
      )}

      {loading ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        data && (
          <>
            <MobileData>
              <MobileDataLabel>Nome</MobileDataLabel>
              <MobileTextName>{data.nome}</MobileTextName>
            </MobileData>
            <GroupData>
              <MobileData>
                <MobileDataLabel>CPF</MobileDataLabel>
                <MobileDataText>
                  {cpfFormatter(data.cpf)}
                  <IconButton
                    style={{ padding: 0, paddingLeft: 3 }}
                    onClick={e => {
                      e.stopPropagation();
                      copyToClipboard(data.cpf);
                    }}
                    color="inherit"
                  >
                    <img src={copyIcon} alt="copiar" />
                  </IconButton>
                </MobileDataText>
              </MobileData>
              <MobileData>
                <MobileDataLabel>MSISDN</MobileDataLabel>
                <MobileDataText>
                  {cellFormatter(data.msisdn)}
                  <IconButton
                    style={{ padding: 0, paddingLeft: 3 }}
                    onClick={e => {
                      e.stopPropagation();
                      copyToClipboard(data.msisdn);
                    }}
                    color="inherit"
                  >
                    <img src={copyIcon} alt="copiar" />
                  </IconButton>
                </MobileDataText>
              </MobileData>
            </GroupData>
            <MobileData>
              <MobileDataLabel>Data da venda</MobileDataLabel>
              <MobileTextName> {dateFormatter(data.created_at)}</MobileTextName>
            </MobileData>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Motivo Tratamento</MobileDataLabel>
                <MobileTextName>
                  {data.motivo_tratamento ? data.motivo_tratamento : '-'}
                </MobileTextName>
              </MobileData>
            </GroupData>
          </>
        )
      )}
    </MobileContainer>
  );
}

export default MobileItem;
