import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 30px;
  border: 1px solid rgba(242, 242, 242, 1);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
  height: 590px;
  margin-bottom: 20px;
`;

export const Title = styled.p`
  display: flex;
  text-align: start;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;
