import { SimplifiedSetup } from 'models/simplifiedSetup';
import api from 'services/api';
import userSWR from 'swr';

export const SetupFetch = (key: string) => {
  const { data, mutate: mutateTotalLeads } = userSWR<SimplifiedSetup>(
    `setup_${key}`,
    async (): Promise<SimplifiedSetup> => {
      const { data: response } = await api.get(`/api/setups/${key}`);

      return response as SimplifiedSetup;
    },
  );

  return { data, mutateTotalLeads };
};
