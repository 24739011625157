import { useToast } from 'hooks/toast';
import React, { useState } from 'react';
import api from 'services/api';
import { ButtonProgress, ContainerForm } from 'components/StyledComponents';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Sale } from '../types';

interface GenerateTagProps {
  sale: Sale;
  setSale(sale: Sale): void;
}

function GenerateTag({ sale, setSale }: GenerateTagProps) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { addToast } = useToast();

  const handleGenerateTag = () => {
    setLoading(true);
    return api
      .post('/core/entregadores/gerar-frete', {
        venda_id: sale?.id,
      })
      .then(() => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Etiqueta gerada com sucesso!',
        });
        setOpen(false);
      })
      .catch(error => {
        setLoading(false);
        const message =
          (error.data.errors && error.data.errors[0].message) ||
          error.data.message;
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao gerar etiqueta',
            description: message,
          });
      })
      .finally(() => {
        api
          .get(`core/vendas/${sale.id}`)
          .then(({ data }) => setSale({ ...sale, ...data }));
      });
  };

  return (
    <ContainerForm>
      <Button
        type="button"
        variant="contained"
        color="primary"
        disabled={loading}
        fullWidth
        size="large"
        onClick={() => setOpen(true)}
      >
        Gerar Etiqueta
        {loading && <ButtonProgress size={24} />}
      </Button>

      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Deseja realmente gerar a etiqueta?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Caso este pedido já tenha uma etiqueta gerada, uma nova será criada.
            A etiqueta antiga continuará válida, até ser cancelada manualmente
            ou passar o prazo em que ela expira.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Não
          </Button>
          <Button
            onClick={() => {
              handleGenerateTag();
              setOpen(false);
            }}
            color="primary"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </ContainerForm>
  );
}

export default GenerateTag;
