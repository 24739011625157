import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    buttonSelect: {
      borderRadius: 6,
      width: 48,
      border: '1px solid rgba(0, 0, 0, 0.05)',
      boxShadow: 'none',
      fontWeight: 'normal',
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: 'rgba(48,174,201,0.2)',
        boxShadow: 'none',
      },
      '&.MuiButton-containedPrimary': {
        border: '1px solid rgba(181, 210, 224, 1)',
        backgroundColor: '#F5FCFF',
        color: '#050C27',
      },
    },
  }),
);
