import { ReactNode } from 'react';
import { Container } from './styles';

interface ContainerListProps {
  children: ReactNode;
}

function ContainerList({ children }: ContainerListProps) {
  return <Container>{children}</Container>;
}

export default ContainerList;
