import styled from 'styled-components';

export const DataColumn = styled.div`
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: auto auto;

  .item-email {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @media (min-width: 768px) {
    display: grid;
    grid-column-gap: 50px;
    grid-template-columns: auto auto auto auto;

    .item-email {
      grid-column-start: 3;
      grid-column-end: 5;
    }
  }
`;

export const DataText = styled.span`
  min-width: 100px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.8);
`;
