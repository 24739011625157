import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { ButtonProgress } from 'components/StyledComponents';
import ReactInputMask from 'react-input-mask';
import { DDDs as areaCodeValidator } from 'util/validator';
import { useStyles } from './styles';
import { useExecuteEligibility } from '../../hooks/useExecuteEligibility';

function EligibilityData() {
  const classes = useStyles();
  const areaCodes = Array.from(areaCodeValidator).sort();

  const {
    operation,
    setOperation,
    type,
    setType,
    method,
    setMethod,
    name,
    setName,
    cpf,
    setCpf,
    birthday,
    setBirthday,
    filiation,
    setFiliation,
    areaCode,
    setAreaCode,
    zipCode,
    setZipCode,
    msisdn,
    setMsisdn,
    cpfIsValid,
    loading,
    loadingDefault,
    isValidForm,
    erros,
    disableForm,
    executeEligibility,
    validateFormToExecute,
    clearForm,
  } = useExecuteEligibility();

  return (
    <Box className={classes.eligibilityData}>
      <Box className={classes.eligibilityAllForms}>
        <Box className={classes.eligibilityForms}>
          <FormControl fullWidth size="small">
            <InputLabel>Operação</InputLabel>
            <Select
              value={operation}
              label="Operação"
              onChange={e => setOperation(e.target.value)}
            >
              <MenuItem value="POS_PAGO">Pós-pago</MenuItem>
              <MenuItem value="CONTROLE">Controle</MenuItem>
              <MenuItem value="EXPRESS">Express</MenuItem>
              <MenuItem value="POS_EXPRESS">Pós Express</MenuItem>
              <MenuItem value="PRE_PAGO">Pré-pago</MenuItem>
              <MenuItem value="CONTROLE_FLEX">Controle Flex</MenuItem>
              <MenuItem value="DIGITALPOS">Digital Pós</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel>Tipo</InputLabel>
            <Select
              value={type}
              label="Tipo"
              onChange={e => setType(e.target.value)}
            >
              <MenuItem value="NOVA_LINHA">Nova Linha</MenuItem>
              <MenuItem value="MIGRACAO">Migração</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel>Método</InputLabel>
            <Select
              value={method}
              label="Método"
              onChange={e => setMethod(e.target.value)}
            >
              <MenuItem value="pmid">PMID</MenuItem>
              <MenuItem value="wso2">WSO2</MenuItem>
            </Select>
          </FormControl>
          <span className={classes.span} />
        </Box>
        <Box className={classes.eligibilityForms}>
          <ReactInputMask
            mask="999.999.999-99"
            type="tel"
            maskChar=" "
            value={cpf}
            onChange={e => setCpf(e.target.value)}
          >
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              params => (
                <TextField
                  aria-readonly
                  fullWidth
                  variant="outlined"
                  label="CPF"
                  size="small"
                  error={!!erros.cpf || cpfIsValid}
                  helperText={
                    erros.cpf || cpfIsValid ? erros.cpf || 'CPF inválido' : ''
                  }
                  {...params}
                />
              )
            }
          </ReactInputMask>
          <TextField
            fullWidth
            label="Nome"
            variant="outlined"
            value={name}
            disabled={disableForm}
            onChange={e => setName(e.target.value)}
            size="small"
          />

          <ReactInputMask
            mask="99/99/9999"
            type="tel"
            maskChar=" "
            value={birthday}
            disabled={disableForm}
            onChange={e => setBirthday(e.target.value)}
          >
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              params => (
                <TextField
                  aria-readonly
                  fullWidth
                  variant="outlined"
                  label="Nascimento"
                  size="small"
                  error={!!erros.nascimento}
                  disabled={disableForm}
                  helperText={erros.nascimento ? erros.nascimento : ''}
                  {...params}
                />
              )
            }
          </ReactInputMask>
        </Box>

        <Box className={classes.eligibilityForms}>
          <TextField
            fullWidth
            label="Nome da Mãe"
            variant="outlined"
            value={filiation}
            disabled={disableForm}
            onChange={e => setFiliation(e.target.value)}
            size="small"
          />
          <TextField
            fullWidth
            label="CEP"
            variant="outlined"
            value={zipCode}
            onChange={e => setZipCode(e.target.value)}
            size="small"
            inputProps={{ maxLength: 8 }}
            onKeyPress={event => {
              if (/[0-9]/.test(event.key)) {
                return;
              }
              event.preventDefault();
            }}
            error={!!erros.cep}
            helperText={erros.cep ? erros.cep : ''}
          />
          {type === 'NOVA_LINHA' ? (
            <FormControl fullWidth size="small">
              <InputLabel>DDD</InputLabel>
              <Select
                value={areaCode}
                label="DDD"
                onChange={e => setAreaCode(e.target.value)}
              >
                {areaCodes.map(aCode => (
                  <MenuItem key={aCode} value={aCode}>
                    {aCode}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <TextField
              id={msisdn}
              value={msisdn}
              onChange={e => setMsisdn(e.target.value)}
              onKeyPress={event => {
                if (/[0-9]/.test(event.key)) {
                  return;
                }
                event.preventDefault();
              }}
              inputProps={{ maxLength: 11 }}
              aria-readonly
              fullWidth
              label="MSISDN"
              name="msisdn"
              variant="outlined"
              size="small"
              error={!!erros.msisdn}
              helperText={erros.msisdn ? erros.msisdn : ''}
            />
          )}
        </Box>
      </Box>

      <Box className={classes.eligibilityButtons}>
        <Box className={classes.dataButtons}>
          <Button variant="outlined" color="error" onClick={() => clearForm()}>
            Limpar
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => executeEligibility(true)}
            disabled={loadingDefault}
          >
            {loadingDefault && <ButtonProgress size={22} />}
            Dados padrões
          </Button>
        </Box>
        <Button
          variant="contained"
          onClick={() => validateFormToExecute()}
          disabled={!isValidForm || loading}
        >
          {loading && <ButtonProgress size={22} />}
          Executar
        </Button>
      </Box>
    </Box>
  );
}

export default EligibilityData;
