import {
  ActionButtons,
  Container,
  Data,
  DataInfo,
  DataText,
  Divider,
  useStylesActionButtons,
} from 'components/StyledComponents';
import { IconButton, Skeleton } from '@mui/material';
import { dateTime as dateTimeFormatter } from 'util/formatter';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { DataTextName } from 'pages/SalesCorrection/SalesCorrectionList/Item/styles';
import { ItemProps } from '../types';
import DialogDeleteUtmConfiguration from '../../DialogDeleteUtmConfiguration';

function Item({
  utm,
  utmConfigurations,
  updateUtmConfigurations,
  loading,
}: ItemProps) {
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const classes = useStylesActionButtons();
  const navigate = useNavigate();
  const openDialogDeleteUtmConfiguration = () => {
    setOpenDialogDelete(true);
  };

  const handleCloseDeleteUtmConfiguration = () => {
    setOpenDialogDelete(false);
  };
  return (
    <Container>
      <Data>
        {loading ? (
          <DataInfo>
            <DataText style={{ width: 205 }}>
              <Skeleton width={150} />
            </DataText>
            <DataText>
              <Skeleton width={300} />
            </DataText>
            <DataText>
              <Skeleton width={150} />
            </DataText>
          </DataInfo>
        ) : (
          utm && (
            <DataInfo>
              <DataTextName>{utm.nome}</DataTextName>
              <DataTextName>{utm.sources}</DataTextName>
              <DataText>{dateTimeFormatter(utm.created_at)}</DataText>
              <Divider />
            </DataInfo>
          )
        )}
      </Data>
      <ActionButtons style={{ marginBottom: 20 }}>
        <IconButton
          className={classes.iconEdit}
          onClick={() =>
            navigate(`/definicao-utm/edita-configuracao/${utm?.id}`)
          }
          disabled={loading}
        >
          <FiEdit />
        </IconButton>
        <IconButton
          className={classes.iconDelete}
          disabled={loading}
          onClick={() => openDialogDeleteUtmConfiguration()}
        >
          <RiDeleteBinLine />
        </IconButton>
      </ActionButtons>
      {utm && utmConfigurations && updateUtmConfigurations && (
        <DialogDeleteUtmConfiguration
          id={utm.id}
          name={utm.nome}
          utmConfigurations={utmConfigurations}
          openDialogDeleteUtmConfiguration={openDialogDelete}
          handleClose={handleCloseDeleteUtmConfiguration}
          updateUtmConfigurations={updateUtmConfigurations}
        />
      )}
    </Container>
  );
}

export default Item;
