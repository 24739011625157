import styled from 'styled-components';

export const DataInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 10px;
`;

export const DataText = styled.span`
  width: 170px;
  min-width: 150px;
  white-space: nowrap;
  font-size: 15px;
  display: flex;
  align-items: center;
`;
