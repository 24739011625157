import { List } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { Sale } from 'models/sale';
import { Container } from 'components/CardList';
import Item from './Item';
import MobileItem from './MobileItem';

import { Header } from './styles';

interface SaleListProps {
  sales?: Sale[];
  ordem: string;
}
interface LoadingListProps {
  isTableOrLess: boolean;
}

function LoadingList({ isTableOrLess }: LoadingListProps) {
  return isTableOrLess ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function SaleList({ sales, ordem }: SaleListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Container>
      {!isTableOrLess && (
        <Header>
          <span>Nome</span>
          <span>CPF</span>
          <span>MSISDN</span>
          <span>Estado</span>
          {ordem === 'ordenar_data_criacao' ? (
            <span>Data da Compra</span>
          ) : (
            <span>Data Ativação</span>
          )}
        </Header>
      )}

      <List>
        {sales ? (
          sales.map(data =>
            isTableOrLess ? (
              <MobileItem key={data.id} data={data} ordem={ordem} />
            ) : (
              <Item key={data.id} data={data} ordem={ordem} />
            ),
          )
        ) : (
          <LoadingList isTableOrLess={isTableOrLess} />
        )}
      </List>
    </Container>
  );
}

export default SaleList;
