import {
  FormControl,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { ClassNameMap } from '@mui/styles';
import { SwitchIOS } from 'components/SwitchIOS';
import { useConfigurationPackageStore } from 'store/configurationPackageStore';
import { CategoryPackage } from 'models/packages';
import { Card } from '../styles';

interface PackagePortabilityProps {
  planSelected: number;
  portability?: CategoryPackage[];
  gb: number;
  handleChangeWithPortability(event: boolean): void;
  classes: ClassNameMap;
}

function PackagePortability({
  planSelected,
  portability,
  gb,
  handleChangeWithPortability,
  classes,
}: PackagePortabilityProps) {
  const configurationStore = useConfigurationPackageStore(
    state => state.configuration,
  );
  const setConfigurationStore = useConfigurationPackageStore(
    state => state.setConfigurationPackage,
  );
  const showBonusPortability = useConfigurationPackageStore(
    state => state.configuration.mostrar_bonus_portabilidade,
  );

  const handleChangePackagePortability = (valor: string) => {
    const portabilityId = parseInt(valor, 10);
    setConfigurationStore({
      ...configurationStore,
      pacote_portabilidade: portabilityId,
    });
  };

  return (
    <Card planSelected={planSelected}>
      <Typography variant="h5" gutterBottom className={classes.title}>
        Pacote Portabilidade
      </Typography>
      {planSelected !== 0 ? (
        portability?.length ? (
          portability?.map(item => (
            <div key={item.categoria}>
              <FormControl
                variant="outlined"
                margin="none"
                size="small"
                fullWidth
                className={classes.field}
              >
                <InputLabel htmlFor="pacote" focused shrink>
                  Pacote
                </InputLabel>
                <Select
                  native
                  value={configurationStore.pacote_portabilidade || 0}
                  label="pacote"
                  displayEmpty
                  onChange={e => {
                    handleChangePackagePortability(e.target.value as string);
                  }}
                  input={
                    <OutlinedInput id="select-pacote" label="pacote" notched />
                  }
                >
                  <option aria-label="vazio" value="">
                    Sem pacote
                  </option>
                  {item.pacotes?.map(value => (
                    <option aria-label="plan" key={value.id} value={value.id}>
                      {value.nome}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="GB"
                margin="dense"
                name="gb"
                size="small"
                variant="outlined"
                value={gb || 0}
                className={classes.field}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormControlLabel
                label={
                  <Typography variant="body2">
                    Somar GB no site e carrinho
                  </Typography>
                }
                control={
                  <SwitchIOS
                    size="medium"
                    color="secondary"
                    checked={!!showBonusPortability}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeWithPortability(!!e.target.checked)
                    }
                  />
                }
                style={{ marginLeft: 0 }}
                labelPlacement="end"
              />
            </div>
          ))
        ) : !portability ? (
          <Skeleton />
        ) : (
          <Typography
            variant="subtitle2"
            gutterBottom
            className={classes.subtitle}
          >
            Nenhum pacote encontrado
          </Typography>
        )
      ) : (
        <Typography
          variant="subtitle2"
          gutterBottom
          className={classes.subtitle}
        >
          Selecione o plano primeiro
        </Typography>
      )}
    </Card>
  );
}

export default PackagePortability;
