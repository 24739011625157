import React, {
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useState,
} from 'react';
import InputIcon from '@mui/icons-material/Input';
import {
  NavLink,
  NavLinkProps,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { MTheme } from 'models/theme';
import { useItemManageMenuSelected } from 'store/manageMenuSelected';

import {
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Divider, DividerMenu, TextTitle, useStyles } from './styles';
import { TextName } from '../Topbar/styles';
import pages from './pages';

const CustomRouterLink = forwardRef(
  (props: NavLinkProps, ref: Ref<never>): ReactElement => (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <NavLink {...props} />
    </div>
  ),
);
interface SubPageItems {
  title: string;
  href: string;
  permission: string;
  showMenu: boolean;
}
interface PageItems {
  id: number;
  title: string;
  icon?: JSX.Element;
  subModules: SubPageItems[];
}

function MenuItems() {
  const itemMenuManageSelected = useItemManageMenuSelected(
    state => state.itemMenuSelected,
  );
  const setItemMenuManageSelected = useItemManageMenuSelected(
    state => state.setItemMenuSelected,
  );

  const clearItemSelected = useItemManageMenuSelected(
    state => state.clearItemMenuSelected,
  );
  const { hasPermission, user } = useAuth();

  const [pagesItem, setPagesItem] = useState<PageItems[]>([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { signOut } = useAuth();

  const classes = useStyles();

  const theme = useTheme() as MTheme;

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  useEffect(() => {
    const pagesFiltered = pages.map((item: PageItems) => ({
      ...item,
      subModules: item.subModules.filter(
        (subItem: SubPageItems) =>
          subItem.showMenu && hasPermission(subItem.permission),
      ),
    }));

    const page = pagesFiltered.find(item => {
      return item.subModules.find(sub => sub.href === location.pathname);
    });

    if (page && Object.keys(itemMenuManageSelected).length === 0) {
      setItemMenuManageSelected(page.id);
    }

    setPagesItem(pagesFiltered);
  }, [
    hasPermission,
    itemMenuManageSelected,
    location.pathname,
    setItemMenuManageSelected,
  ]);

  const handleClick = (id: number) => {
    setItemMenuManageSelected(id);
  };

  return (
    <div className={classes.wrapper}>
      <List>
        {!isDesktop && (
          <>
            <TextName isDesktop={isDesktop}>
              Olá, <strong>{user.nome}</strong>
            </TextName>
            <Divider />
          </>
        )}
        {pagesItem.map(item => (
          <Box key={item.id}>
            {item.subModules.length > 0 && (
              <>
                <ListItem onClick={() => handleClick(item.id)}>
                  <ListItemIcon
                    className={classes.icon}
                    style={{ minWidth: 64 }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <TextTitle>{item.title}</TextTitle>
                  {itemMenuManageSelected[item.id] ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>
                <Collapse
                  in={itemMenuManageSelected[item.id]}
                  timeout="auto"
                  unmountOnExit
                >
                  {item.subModules.map(subItem => (
                    <Box key={subItem.title}>
                      <ListItem
                        className={classes.nested}
                        disableGutters
                        sx={{ marginLeft: '22px' }}
                      >
                        <NavLink
                          style={{ flexGrow: 1, fontSize: 14 }}
                          className={({ isActive }) => {
                            const linkClasses = [classes.button];
                            if (isActive) linkClasses.push(classes.active);
                            return linkClasses.join(' ');
                          }}
                          to={subItem.href}
                          onClick={() => clearItemSelected(item.id)}
                        >
                          {subItem.title}
                        </NavLink>
                      </ListItem>
                    </Box>
                  ))}
                </Collapse>
                <DividerMenu />
              </>
            )}
          </Box>
        ))}
      </List>
      {!isDesktop && (
        <div className={classes.item}>
          <Button
            component={CustomRouterLink}
            className={classes.button}
            to="/"
            onClick={() => {
              signOut();
              navigate('/login');
            }}
          >
            <div className={classes.icon}>
              <InputIcon />
            </div>
            Sair
          </Button>
        </div>
      )}
    </div>
  );
}

export default MenuItems;
