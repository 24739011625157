import React, { useState } from 'react';
import copyIcon from 'assets/icons/small/copy.svg';

import copyToClipboard from 'util/copyToClipboard';
import { v4 as uuidv4 } from 'uuid';
import { Container, Info, Label } from 'components/TextInfo';
import { Skeleton, Tooltip } from '@mui/material';

interface TextInfoProps {
  label: string;
  info?: string;
  loading?: boolean;
  canCopy?: boolean;
  onlyNumbers?: boolean;
}

function TextInfo({
  label,
  info,
  loading,
  canCopy,
  onlyNumbers = false,
}: TextInfoProps) {
  const id = `item_${uuidv4()}`;
  const [showToolTip, setShowToolTip] = useState(false);

  const handleTooltipClose = () => {
    setShowToolTip(false);
  };

  const handleTooltipOpen = () => {
    setShowToolTip(true);
  };

  return (
    <Container id={id}>
      {loading ? (
        <>
          <Skeleton animation="wave" height={20} width={50} />
          <Skeleton animation="wave" height={30} width={110} />
        </>
      ) : (
        <>
          <Label>{label}</Label>
          <Info>
            {info}
            {canCopy && (
              <Tooltip
                disableHoverListener
                onClose={handleTooltipClose}
                onOpen={handleTooltipOpen}
                leaveDelay={900}
                title="copiado"
                placement="top-end"
                open={showToolTip}
              >
                <button
                  type="button"
                  onClick={() => {
                    const str = onlyNumbers
                      ? info && info.replace(/\D/g, '')
                      : info;
                    const copied = copyToClipboard(str as string, id);
                    setShowToolTip(copied);
                  }}
                >
                  <img src={copyIcon} alt="copiar valor" />
                </button>
              </Tooltip>
            )}
          </Info>
        </>
      )}
    </Container>
  );
}

export default TextInfo;
