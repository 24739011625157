import {
  MobileContainer,
  MobileData,
  MobileDataLabel,
  useStylesActionButtons,
} from 'components/StyledComponents';
import { convertDateInString as dateFormatter } from 'util/formatter';
import { IconButton, Skeleton } from '@mui/material';
import { GroupData } from 'components/MobileList';
import { MobileTextName } from 'pages/AffiliateBase/AffiliateBaseList/MobileItem/styles';
import { useNavigate } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import React from 'react';
import { ItemProps } from '../Item';
import StatusCard from '../../../StatusCard';

function MobileItem({ data, loading }: ItemProps) {
  const navigate = useNavigate();

  const classes = useStylesActionButtons();
  return (
    <MobileContainer>
      {loading ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        data && (
          <>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Criado</MobileDataLabel>
                <MobileTextName>
                  {' '}
                  {dateFormatter(data.created_at)}
                </MobileTextName>
              </MobileData>
              <IconButton
                onClick={() =>
                  navigate(`/reprocessamento-total/visualiza-base/${data?.id}`)
                }
                disabled={loading}
                className={classes.iconView}
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            </GroupData>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Base</MobileDataLabel>
                <MobileTextName>{data.nome}</MobileTextName>
              </MobileData>
            </GroupData>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Status</MobileDataLabel>
                <StatusCard status={data.status} />
              </MobileData>
              <MobileData>
                <MobileDataLabel>Não Responderam</MobileDataLabel>
                <MobileTextName>{data.nao_responderam}</MobileTextName>
              </MobileData>
            </GroupData>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Para Processar</MobileDataLabel>
                <MobileTextName>{data.para_processar}</MobileTextName>
              </MobileData>
              <MobileData>
                <MobileDataLabel>Para Despausar</MobileDataLabel>
                <MobileTextName>{data.para_despausar}</MobileTextName>
              </MobileData>
            </GroupData>
          </>
        )
      )}
    </MobileContainer>
  );
}

export default MobileItem;
