import { BlacklistDataBase } from 'models/blacklistData';
import {
  ActionButtons,
  Container,
  DataTextName,
  Divider,
} from 'components/StyledComponents';
import {
  Checkbox,
  IconButton,
  Skeleton,
  Divider as VerticalDivider,
} from '@mui/material';
import { cpf as cpfFormatter } from 'util/formatter';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FiEdit } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { DataText, DataInfo, Data } from './styles';

export interface ItemProps {
  loading?: boolean;
  data?: BlacklistDataBase;
  selected?: boolean;
  handleCheckData?(dataId: number): void;
  setDataSelected?(value: BlacklistDataBase): void;
  handleOpenModalDelete?(): void;
}

function Item({
  data,
  loading,
  selected,
  handleCheckData,
  setDataSelected,
  handleOpenModalDelete,
}: ItemProps) {
  const navigate = useNavigate();

  const formatedData =
    data?.tipo === 'CPF' ? cpfFormatter(data?.dado) : data?.dado;
  return (
    <Container>
      <Data>
        {loading ? (
          <DataInfo>
            <DataText>
              <Skeleton width={85} />
            </DataText>
            <DataText>
              <Skeleton width={85} />
            </DataText>
          </DataInfo>
        ) : (
          data && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <DataInfo>
                <DataText>{data.tipo}</DataText>
                <DataTextName>{formatedData}</DataTextName>
              </DataInfo>
              <ActionButtons>
                <IconButton
                  onClick={() => {
                    navigate(
                      `/dados-blacklist/edita-dado-blacklist/${data.id}`,
                    );
                  }}
                  disabled={loading}
                >
                  <FiEdit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleOpenModalDelete && handleOpenModalDelete();
                    setDataSelected && setDataSelected(data);
                  }}
                  disabled={loading}
                >
                  <RiDeleteBinLine color="#C92A2A" />
                </IconButton>
                <VerticalDivider aria-hidden="true" orientation="vertical" />
                <Checkbox
                  checked={selected}
                  inputProps={{ 'aria-label': 'controlled' }}
                  disabled={loading}
                  onChange={() => handleCheckData && handleCheckData(data.id)}
                />
              </ActionButtons>
            </div>
          )
        )}
      </Data>

      <Divider />
    </Container>
  );
}

export default Item;
