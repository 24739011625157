import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import { relatorioGerencial } from '../constants/permissions';

function DefineHomeRoute() {
  const { user, hasPermission } = useAuth();
  if (!user?.id) {
    return <Navigate to="/login" />;
  }
  if (hasPermission(relatorioGerencial)) {
    return <Navigate to="/relatorio-gerencial" />;
  }
  return <Navigate to="/perfil" />;
}
export default DefineHomeRoute;
