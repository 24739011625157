import userSWR from 'swr';
import api from 'services/api';
import { ConfigurationByIdPackage } from 'models/configurationsPackage';

export const ConfigurationByIdFetch = (id: number) => {
  const { data, mutate } = userSWR<ConfigurationByIdPackage>(
    `package_${id}`,
    async (): Promise<ConfigurationByIdPackage> => {
      const { data: results } = await api.get(
        `/core/pacote-configuracoes/${id}`,
      );

      return results as ConfigurationByIdPackage;
    },
  );
  return { data, mutate };
};
