import { List } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { Sale } from 'models/sale';

import { Container } from 'components/CardList';
import Item from './Item';
import MobileItem from './MobileItem';

import { Header } from './styles';

interface SaleListProps {
  sales?: Sale[];
}

interface BodyProps {
  sales: Sale[];
  isMobile: boolean;
}

interface LoadingListProps {
  isTabletSizeOrSmaller: boolean;
}

function LoadingList({ isTabletSizeOrSmaller }: LoadingListProps) {
  return isTabletSizeOrSmaller ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({ sales, isMobile }: BodyProps) {
  return (
    <List>
      {sales.length > 0 ? (
        sales.map(data =>
          isMobile ? (
            <MobileItem key={data.id} data={data} />
          ) : (
            <Item key={data.id} data={data} />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhuma portabilidade em tratamento</h3>
      )}
    </List>
  );
}

function SaleList({ sales }: SaleListProps) {
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  return (
    <Container>
      {!isTabletSizeOrSmaller && (
        <Header>
          <span>Nome</span>
          <span>CPF</span>
          <span>Número Portado</span>
          <span>Motivo Tratamento</span>
        </Header>
      )}
      {sales ? (
        <Body sales={sales} isMobile={isTabletSizeOrSmaller} />
      ) : (
        <LoadingList isTabletSizeOrSmaller={isTabletSizeOrSmaller} />
      )}
    </Container>
  );
}

export default SaleList;
