import styled from 'styled-components';

interface ContainerProps {
  row?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${props => (props.row ? 'row' : 'column')};
  flex-wrap: wrap;
`;

export const Label = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 5px;
`;

export const Info = styled.span`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.8);

  button {
    margin-left: 4px;
    visibility: hidden;
    height: 19px;
    border: none;
    background: transparent;
  }
  &:hover {
    button {
      visibility: visible;
    }
  }
`;
