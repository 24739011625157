import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 20,
    },
    dialogButtons: {
      justifyContent: 'space-between',
      padding: 20,
      width: 360,
    },
    cancelButton: {
      color: '#C92A2A',
      backgroundColor: '#FFFFFF',
      border: '1px solid #C92A2A',
      width: 87,
    },
  }),
);
