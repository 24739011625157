import styled from 'styled-components';

export const ContentDrawer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 596px;
  padding: 20px 40px;
  gap: 26px;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export const HeaderDrawer = styled.div`
  display: flex;
  justify-content: space-between;
`;
