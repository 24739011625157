import { useEffect, useState } from 'react';
import { Button, CardHeader, SwipeableDrawer } from '@mui/material';
import { Card, Header } from 'components/StyledComponents';
import { useAuth } from 'hooks/auth';
import SynchronizePlans from 'pages/ManagerPlan/SynchronizePlans';
import { planosSincronizar } from 'constants/permissions';
import { useParams } from 'react-router-dom';
import { PlanByIdFetch } from 'fetches/planByIdFetch';
import { usePlanStore } from 'store/planStore';
import { SubHeader } from '../styles';
import PriceTableItem from '../components/PriceTableItem';
import NewPrice from '../components/NewPrice';
import { useStyles } from './styles';

function PlanPricingTable() {
  const classes = useStyles();
  const params = useParams();
  const { id } = params;
  const { data: plan, mutate } = PlanByIdFetch(parseInt(id || '0', 10));
  const [loading, setLoading] = useState(true);
  const [showSynchronizePlans, setShowSynchronizePlans] = useState(false);
  const [showButtonPlanSync, setShowButtonPlanSync] = useState(false);
  const [showDrawerNewGroupPrice, setShowDrawerNewGroupPrice] = useState(false);

  const planStore = usePlanStore(state => state.plan);
  const setPlanStore = usePlanStore(state => state.setPlan);

  const { hasPermission } = useAuth();

  useEffect(() => {
    const shouldShow = hasPermission(planosSincronizar);

    setShowButtonPlanSync(shouldShow);
  }, [hasPermission]);

  useEffect(() => {
    if (!plan) return;

    setPlanStore(plan);
    setLoading(false);
  }, [plan, setPlanStore]);

  return (
    <Card>
      <Header className={classes.header}>
        <CardHeader
          title="Gestão de planos"
          subheader="Painel de informações dos planos e ofertas TIM."
        />

        {showButtonPlanSync && (
          <>
            <Button
              className={classes.buttonPlanSync}
              variant="contained"
              color="primary"
              onClick={() => setShowSynchronizePlans(true)}
            >
              Sincronizar planos
            </Button>
            <SynchronizePlans
              showSynchronizePlans={showSynchronizePlans}
              setShowSynchronizePlans={setShowSynchronizePlans}
            />
          </>
        )}
      </Header>
      <SubHeader>Tabela de preços do plano</SubHeader>
      <Button
        onClick={() => setShowDrawerNewGroupPrice(true)}
        type="button"
        color="secondary"
        variant="outlined"
        disabled={loading}
        style={{ marginLeft: 15, marginBottom: 10 }}
      >
        Novo grupo de preço
      </Button>
      <PriceTableItem
        prices={planStore?.precos}
        name={planStore?.nome}
        loading={loading}
        updatePlanById={mutate}
      />
      <Button
        onClick={() => window.history.back()}
        type="button"
        variant="outlined"
        disabled={loading}
        style={{ marginLeft: 15, marginTop: 15 }}
      >
        Voltar
      </Button>
      <SwipeableDrawer
        anchor="right"
        open={showDrawerNewGroupPrice}
        onOpen={() => setShowDrawerNewGroupPrice(true)}
        onClose={() => {
          setShowDrawerNewGroupPrice(false);
        }}
      >
        <NewPrice
          setShowDrawerNewGroupPrice={setShowDrawerNewGroupPrice}
          updatePriceGroups={mutate}
        />
      </SwipeableDrawer>
    </Card>
  );
}

export default PlanPricingTable;
