import { NewAffiliateGoal } from 'pages/ManagerGoal/types';
import create from 'zustand';

type GoalSelectStore = {
  selectedGoalsCheckbox: NewAffiliateGoal[];
  setSelectedGoalsCheckbox(selectedGoalsCheckbox: NewAffiliateGoal[]): void;
  hasSelected: boolean;
  setHasSelected(hasSelected: boolean): void;
};

export const useGoalSelectStore = create<GoalSelectStore>(set => ({
  selectedGoalsCheckbox: [],
  setSelectedGoalsCheckbox(selected) {
    set(state => ({
      ...state,
      selectedGoalsCheckbox: selected,
    }));
  },
  hasSelected: false,
  setHasSelected(has: boolean) {
    set(state => ({ ...state, hasSelected: has }));
  },
}));
