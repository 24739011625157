import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import styled from 'styled-components';

export const ContainerOffers = styled.div`
  display: flex;
  padding: 0px 16px 0px 16px;
  gap: 16px;
`;

export const CardOffers = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 15px;
  border: 1px solid #f2f2f2;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
`;

export const TitleOffers = styled.h1`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  align-items: flex-start;
`;

export const TextHelpOffers = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.4);
`;

export const ActionOffers = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 16px;

  button {
    width: 120px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding: 16px 16px 30px 16px;

    button {
      width: 100%;
    }
  }
`;

export const BoxTextAreaImg = styled.div`
  display: flex;
  align-items: center;
  width: 355px;
  justify-content: center;

  @media (max-width: 900px) {
    margin-top: -115px;
    margin-bottom: 125px;
    width: 100%;
  }
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      '&::placeholder': {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '14.6px',
      },
    },
    selectPlaceholder: {
      color: 'rgba(0, 0, 0, 0.4)',
    },
    checkBox: {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      gap: 10,

      '& .MuiCheckbox-root': {
        padding: '0px 5px 0px 0px',
      },
      '& .MuiFormControlLabel-root': {
        marginLeft: 0,
        marginRight: 0,
      },
      '& .MuiFormControlLabel-label': {
        fontSize: 12,
        lineHeight: '14px',
        width: 80,
      },
      [theme.breakpoints.down(900)]: {
        flexDirection: 'column',
        alignItems: 'start',

        '& .MuiFormControlLabel-label': {
          width: '100%',
        },
      },
    },
    boxAreaImg: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      gap: 16,
      marginTop: 15,
    },
    cardImg: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '15px 0px',
      gap: '15px',
      width: '355px',
      background: '#002198',
      borderRadius: '6px',
      [theme.breakpoints.down(900)]: {
        width: '100%',
      },
    },
    subtitleImg: {
      color: 'rgba(0, 0, 0, 0.5)',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '14px',
    },
  }),
);
