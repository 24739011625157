import useSWR from 'swr';
import api from 'services/api';
import { FraudKeeper, FraudKeeperFetchResponse } from 'models/fraudKeeper';

interface Params {
  motivo?: string;
}

export const FraudKeepersFetch = (page: number, reason: string) => {
  const { data, mutate } = useSWR<FraudKeeperFetchResponse>(
    `frauds_${page}_${reason}`,
    async (): Promise<FraudKeeperFetchResponse> => {
      const params: Params = {};
      if (reason !== 'todos') {
        params.motivo = reason;
      }
      const response = await api.get(`/core/curadorias?page=${page}`, {
        params,
      });

      const paginationInfo = {
        currentPage: parseInt(response.headers['x-current-page'], 10),
        lastPage: parseInt(response.headers['x-last-page'], 10),
        perPage: parseInt(response.headers['x-per-page'], 10),
        total: parseInt(response.headers['x-total-count'], 10),
      };

      const fraudKeepers = response.data as FraudKeeper[];

      return { fraudKeepers, paginationInfo } as FraudKeeperFetchResponse;
    },
  );
  return { data, mutate };
};
