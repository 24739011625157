import Email from 'assets/icons/small/email.svg';
import Facebook from 'assets/icons/small/facebook.svg';
import Google from 'assets/icons/small/google.svg';
import Sms from 'assets/icons/small/sms.svg';
import SmsAbandono from 'assets/icons/small/sms_abandono.svg';
import SmsPlus from 'assets/icons/small/sms_plus.svg';
import Reaproveitamento from 'assets/icons/small/reaproveitamento.svg';
import LandingPage from 'assets/icons/small/landing_page.svg';
import Whatsapp from 'assets/icons/small/whatsapp.svg';
import Default from 'assets/icons/small/default.svg';

interface IconProps {
  email: string;
  facebook: string;
  google: string;
  sms: string;
  SMSAbandonoOUCancelados: string;
  sms_plus: string;
  afiliado: string;
  reaproveitamento: string;
  landingpage: string;
  whatsapp: string;
  WhatsApp: string;
  WhatsAppLPTIM: string;
  WhatsAppMETA: string;
  WhatsAppOutros: string;
  organico: string;
  default: string;
  [key: string]: string;
}

const icons: IconProps = {
  email: Email,
  facebook: Facebook,
  google: Google,
  sms: Sms,
  SMSAbandonoOUCancelados: SmsAbandono,
  sms_plus: SmsPlus,
  reaproveitamento: Reaproveitamento,
  landingpage: LandingPage,
  whatsapp: Whatsapp,
  WhatsApp: Whatsapp,
  WhatsAppLPTIM: Whatsapp,
  WhatsAppMETA: Whatsapp,
  WhatsAppOutros: Whatsapp,
  afiliado: Default,
  organico: Default,
  default: Default,
};
export default icons;
