import useSWR from 'swr';
import api from 'services/api';
import { City } from 'models/cityByUf';

interface Response {
  id: number;
  nome: string;
  uf_id: number;
}

export const CitiesByUFFetch = (uf: string) => {
  const { data } = useSWR<City[]>(`city_${uf}`, async (): Promise<City[]> => {
    const { data: results } = await api.get(`/core/ufs/${uf}/cities`);
    const cities = results.map((result: Response) => {
      return {
        id: result.id,
        nome: result.nome
          .normalize('NFD')
          .replace(/[^a-zA-Z\s]/g, '')
          .toUpperCase(),
        uf_id: result.uf_id,
      };
    });

    return cities;
  });

  return { data };
};
