import React, { useState } from 'react';
import { Search } from '@mui/icons-material';
import useBreakpoint from 'hooks/useBreakpoint';
import { Container } from 'components/Filters';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { FilterMargin } from './styles';

interface FiltersProps {
  setSearch(search: string): void;
}

function Filters({ setSearch }: FiltersProps) {
  const [localSearch, setLocalSearch] = useState('');

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Container>
      <FilterMargin>
        <FormControl
          variant="outlined"
          margin="none"
          size="small"
          fullWidth={isTableOrLess}
        >
          <InputLabel>Buscar</InputLabel>
          <OutlinedInput
            label="Buscar"
            className="buttonSearch"
            value={localSearch}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                setSearch(localSearch);
              }
            }}
            onChange={e => {
              if (e.target.value === '') {
                setSearch('');
              }
              setLocalSearch(e.target.value as string);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setSearch(localSearch);
                  }}
                  edge="end"
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </FilterMargin>
    </Container>
  );
}

export default Filters;
