import styled from 'styled-components';

export const MobileDataText = styled.span`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 14px;

  min-width: 130px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Action = styled.div`
  display: flex;
  position: absolute;
  top: 0px;
  right: 0px;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileDataLongText = styled.span`
  width: 280px;
  font-size: 14px;
  word-break: break-word;
  font-weight: 400;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
`;
