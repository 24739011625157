import userSWR from 'swr';
import { parseISO, startOfDay, endOfDay, format } from 'date-fns';
import api from 'services/api';
import { Totalizator } from 'models/reports';

export const TotalizatorByNonSalesFetch = (start: string, end: string) => {
  const inicio = format(startOfDay(parseISO(start)), 'yyyy-MM-dd HH:mm:ss');
  const fim = format(endOfDay(parseISO(end)), 'yyyy-MM-dd HH:mm:ss');

  const { data, mutate } = userSWR<Totalizator>(
    `nonSalesFetch_${inicio}_${fim}`,

    async (): Promise<Totalizator> => {
      const params = {
        inicio,
        fim,
      };
      const { data: result } = await api.get(
        `core/relatorios/dashboards/totalizador-nao-vendas`,
        {
          params,
        },
      );

      return result as Totalizator;
    },
  );
  return { data, mutate };
};
