import { Box, Button, IconButton, Popover } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import React from 'react';

interface MobileSideButtonsProps {
  handleSetStatusToAll(status: string | null): void;
}

function MobileSideButtons({ handleSetStatusToAll }: MobileSideButtonsProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          padding="15px 5px"
        >
          <Button
            variant="text"
            onClick={() => {
              handleSetStatusToAll('LEGITIMO');
              handleClose();
            }}
          >
            Marcar todos como legítimo
          </Button>
          <Button
            variant="text"
            onClick={() => {
              handleSetStatusToAll('FRAUDE');
              handleClose();
            }}
          >
            Marcar todos como fraude
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default MobileSideButtons;
