import { Container, FilterMargin } from 'pages/Sale/Filters/styles';
import { Button, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import useBreakpoint from 'hooks/useBreakpoint';
import { useStyles } from './PopoverActions/style';
import PopoverActions from './PopoverActions';
import { useFilters } from './hooks/useFilters';

interface FilterProps {
  showReprocessedSalesButton: boolean;
  anchorElement: null | HTMLElement;
  setAnchorElement(anchorElement: null | HTMLElement): void;
  handleClick(event: React.MouseEvent<HTMLElement>): void;
}

function Filter({
  showReprocessedSalesButton,
  anchorElement,
  setAnchorElement,
  handleClick,
}: FilterProps) {
  const classes = useStyles();
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  const {
    id,
    openPopover,
    dateStart,
    dateEnd,
    monthsSelected,
    initMonthSelected,
    onFilter,
    handleRangeMonth,
    handleClose,
    handleStartChange,
    handleEndChange,
  } = useFilters({
    anchorElement,
    setAnchorElement,
  });

  return (
    <Container>
      <FilterMargin>
        <DesktopDatePicker
          label="Início"
          openTo="day"
          inputFormat="dd/MM/yyyy"
          // invalidDateMessage="Data inválida"
          value={dateStart}
          onChange={date => handleStartChange(date)}
          renderInput={params => <TextField {...params} fullWidth />}
          className={classes.keyboardDate}
        />
      </FilterMargin>
      <FilterMargin>
        <DesktopDatePicker
          label="Fim"
          minDate={dateStart}
          inputFormat="dd/MM/yyyy"
          InputAdornmentProps={{ position: 'end' }}
          // invalidDateMessage="Data inválida"
          value={dateEnd}
          onChange={date => handleEndChange(date)}
          renderInput={params => <TextField fullWidth {...params} />}
          className={classes.keyboardDate}
        />
      </FilterMargin>
      {!isTableOrLess && (
        <FilterMargin>
          <Button
            aria-describedby={id}
            color="primary"
            style={{ color: '#050C27', minWidth: 135 }}
            onClick={handleClick}
            className="buttonAdvancedFilters"
            sx={{ textTransform: 'capitalize' }}
          >
            filtros avançados
          </Button>
        </FilterMargin>
      )}
      <PopoverActions
        id={id || ''}
        openPopover={openPopover}
        anchorEl={anchorElement}
        handleClick={handleClose}
        monthsSelected={monthsSelected}
        initMonthSelected={initMonthSelected}
        handleRangeMonth={handleRangeMonth}
        showReprocessedSalesButton={showReprocessedSalesButton}
        onFilter={onFilter}
        onClose={handleClose}
      />
    </Container>
  );
}

export default Filter;
