import { sanitize } from 'dompurify';
import parse from 'html-react-parser';

export const cleanHtmlString = (value: string) => {
  const cleanString = sanitize(value, {
    USE_PROFILES: { html: true },
  });

  const html = parse(cleanString);

  return html;
};
