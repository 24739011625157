import { List } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { Sale } from 'models/tractableSales';
import { Container } from 'components/CardList';

import Item from './Item';
import MobileItem from './MobileItem';

import { Header } from './styles';

interface ErrorTreatmentListProps {
  sales?: Sale[];
}

interface BodyProps {
  sales: Sale[];
  isMobile: boolean;
}
interface LoadingListProps {
  isTableOrLess: boolean;
}

function LoadingList({ isTableOrLess }: LoadingListProps) {
  return isTableOrLess ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({ sales, isMobile }: BodyProps) {
  return (
    <List>
      {sales.length ? (
        sales.map(data =>
          isMobile ? (
            <MobileItem key={data.id} data={data} />
          ) : (
            <Item key={data.id} data={data} />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhum pedido pendente tratavel</h3>
      )}
    </List>
  );
}

function ErrorTreatmentList({ sales }: ErrorTreatmentListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Container>
      {!isTableOrLess && (
        <Header>
          <span>Nome</span>
          <span>CPF</span>
          <span>MSISDN</span>
          <span>Data da venda</span>
          <span>Motivo Tratamento</span>
        </Header>
      )}
      {sales ? (
        <Body sales={sales} isMobile={isTableOrLess} />
      ) : (
        <LoadingList isTableOrLess={isTableOrLess} />
      )}
    </Container>
  );
}

export default ErrorTreatmentList;
