import userSWR from 'swr';
import api from 'services/api';
import { PlanByID } from 'models/planById';

export const PlanByIdFetch = (id: number) => {
  const { data, mutate } = userSWR<PlanByID>(
    `plan_${id}`,
    async (): Promise<PlanByID> => {
      if (id === 0) {
        return {} as PlanByID;
      }
      const { data: results } = await api.get(`/core/planos/${id}`);

      return results as PlanByID;
    },
  );

  return { data, mutate };
};
