import { Skeleton } from '@mui/material';
import {
  Container,
  DataInfo,
  DataText,
  DataTextName,
  Data,
  Divider,
} from 'components/StyledComponents';
import { Package } from 'models/packages';
import { usePackageStore } from 'store/packageStore';
import { Badge } from './styles';

export interface ItemProps {
  data?: Package;
  loading?: boolean;
  category?: string;
  setPackageSelected?(value: Package): void;
}

function Item({ data, loading, category, setPackageSelected }: ItemProps) {
  const setShowPackageEdition = usePackageStore(
    state => state.setShowPackageEdition,
  );
  const setCategory = usePackageStore(state => state.setCategory);

  return (
    <Container
      onClick={() => {
        setShowPackageEdition(true);
        setPackageSelected && data && setPackageSelected(data);
        category && setCategory(category);
      }}
    >
      <Data>
        <DataInfo>
          <DataText>
            {loading ? <Skeleton width={140} /> : data?.tim_id}
          </DataText>
          <DataTextName>
            {loading ? <Skeleton width={140} /> : data?.nome}
          </DataTextName>
          {category !== 'SEM_CLASSIFICACAO' && (
            <>
              <DataText>
                {loading ? <Skeleton width={140} /> : data?.gb}
              </DataText>
              {loading ? (
                <Skeleton width={140} />
              ) : (
                <div>
                  {data?.ativo ? (
                    <Badge type="ativo">Ativo</Badge>
                  ) : (
                    <Badge type="inativo">Inativo</Badge>
                  )}
                </div>
              )}
            </>
          )}
        </DataInfo>
      </Data>
      <Divider />
    </Container>
  );
}

export default Item;
