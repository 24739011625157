import { Grid, TextField } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { hour as hourFormatter, date as dateFormatter } from 'util/formatter';
import { LoyaltyByPlanFetch } from 'fetches/loyaltyByPlanFetch';
import { Card, CardError, CardInfo, CardText, CardTitle } from './styles';
import { InfoProps } from '../types';

function InfoSale({ data, fidelidade, setFidelidade }: InfoProps) {
  const { data: loyalty } = LoyaltyByPlanFetch(data.plano);

  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <CardError>
            <ErrorIcon />
            {data?.erro}
          </CardError>
        </Grid>
        <Grid item md={2} xs={6}>
          <CardInfo>
            <CardTitle variant="body2">Hora</CardTitle>
            <CardText variant="subtitle1">
              {dateFormatter(data?.created_at)} -
              {hourFormatter(data?.created_at)}
            </CardText>
          </CardInfo>
        </Grid>
        <Grid item md={2} xs={6}>
          <CardInfo>
            <CardTitle variant="body2">Tipo</CardTitle>
            <CardText variant="subtitle1">{data?.tipo}</CardText>
          </CardInfo>
        </Grid>
        <Grid item md={2} xs={6}>
          <CardInfo>
            <CardTitle variant="body2">Plano </CardTitle>
            <CardText variant="subtitle1">{data?.plano}</CardText>
          </CardInfo>
        </Grid>

        <Grid item md={1} xs={3} lg={1}>
          <CardInfo>
            <CardTitle variant="body2">Medium</CardTitle>
            <CardText variant="subtitle1">
              {data?.utm_medium ?? 'Orgânico'}
            </CardText>
          </CardInfo>
        </Grid>
        <Grid item md={1} xs={3} lg={1}>
          <CardInfo>
            <CardTitle variant="body2">Campaign</CardTitle>
            <CardText variant="subtitle1">
              {data?.utm_campaign ?? 'Orgânico'}
            </CardText>
          </CardInfo>
        </Grid>
        <Grid item md={6} xs={6}>
          <TextField
            label="Fidelidade"
            variant="outlined"
            value={fidelidade}
            select
            SelectProps={{ native: true }}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            fullWidth
            onChange={e => setFidelidade(e.target.value)}
          >
            <option value="">Sem fidelidade</option>
            {loyalty &&
              loyalty.map(option => (
                <option key={option.id} value={option.tim_id}>
                  {option.nome} -{option.tim_id}
                </option>
              ))}
          </TextField>
        </Grid>
      </Grid>
    </Card>
  );
}

export default InfoSale;
