import userSWR from 'swr';
import api from 'services/api';
import { VirtualSeller } from 'models/virtualSeller';

export const VirtualSellerFetch = () => {
  const { data, mutate } = userSWR<VirtualSeller[]>(
    'virtualSeller',
    async (): Promise<VirtualSeller[]> => {
      const { data: virtualSeller } = await api.get(
        '/core/vendedores-virtuais',
      );
      return virtualSeller as VirtualSeller[];
    },
  );
  return { data, mutate };
};
