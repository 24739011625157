import { usePrice } from '../hooks/usePrice';
import FormPrice from '../FormPrice';

interface EditPriceProps {
  setShowDrawerNewGroupPrice(showDrawerNewGroupPrice: boolean): void;
  updatePriceGroups?(): void;
}

function EditPrice({
  setShowDrawerNewGroupPrice,
  updatePriceGroups,
}: EditPriceProps) {
  const {
    planStore,
    currentPrice,
    previousPrice,
    DDDsArray,
    dddsSelecteds,
    isDisabledDDDs,
    loading,
    setCurrentPrice,
    setPreviousPrice,
    changeDddsSelecteds,
    uncheckAllAreaCodes,
    markAllAreaCodes,
    savePriceDDDs,
    handleClose,
  } = usePrice({
    setShowDrawerNewGroupPrice,
    updatePriceGroups,
  });

  return (
    <div>
      {planStore && (
        <FormPrice
          planName={planStore.nome}
          currentPrice={currentPrice}
          previousPrice={previousPrice}
          DDDsArray={DDDsArray}
          dddsSelecteds={dddsSelecteds}
          isDisabledDDDs={isDisabledDDDs}
          loading={loading}
          setCurrentPrice={setCurrentPrice}
          setPreviousPrice={setPreviousPrice}
          changeDddsSelecteds={changeDddsSelecteds}
          uncheckAllAreaCodes={uncheckAllAreaCodes}
          markAllAreaCodes={markAllAreaCodes}
          savePriceDDDs={savePriceDDDs}
          onClose={handleClose}
        />
      )}
    </div>
  );
}

export default EditPrice;
