import React, { useCallback, useEffect, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import {
  MobileContainer,
  MobileData,
  MobileDataLabel,
} from 'components/StyledComponents';
import {
  Action,
  MobileDataTextName,
  MobileDataText,
  Row,
} from 'components/MobileList';
import { Plan } from 'models/plans';
import PopperActions from '../PopperActions';
import FlagToggle from '../FlagToggle';

interface MobileItemProps {
  data?: Plan;
  loading?: boolean;
  updatePlan?(data: Plan): void;
}

function MobileItem({ data, loading = false, updatePlan }: MobileItemProps) {
  const [status, setStatus] = useState(!!data?.ativo);
  const [showEcommerce, setShowEcommerce] = useState(!!data?.exibir_ecommerce);
  const [lockSync, setLockSync] = useState(!!data?.travar_sincronia);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  useEffect(() => {
    setStatus(!!data?.ativo);
    setShowEcommerce(!!data?.exibir_ecommerce);
    setLockSync(!!data?.travar_sincronia);
  }, [data, updatePlan]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElement(anchorElement ? null : event.currentTarget);
    },
    [anchorElement],
  );

  const open = Boolean(anchorElement);
  const id = open ? `popperAction_${data?.id}` : undefined;

  return (
    <MobileContainer>
      <Action>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        {data && (
          <PopperActions
            id={id || ''}
            open={open}
            planId={data.id || 0}
            anchorElement={anchorElement}
            handleClick={handleClick}
          />
        )}
      </Action>
      <Row>
        <MobileData>
          <MobileDataLabel>TIM ID</MobileDataLabel>
          <MobileDataText>
            {loading ? <Skeleton width={80} /> : data?.tim_id}
          </MobileDataText>
        </MobileData>
      </Row>
      <Row>
        <MobileData>
          <MobileDataLabel>Nome</MobileDataLabel>
          <MobileDataTextName>
            {loading ? <Skeleton width={150} /> : data?.nome}
          </MobileDataTextName>
        </MobileData>
      </Row>
      <Row>
        <MobileData>
          <MobileDataLabel>GB</MobileDataLabel>
          <MobileDataText>
            {loading ? <Skeleton width={80} /> : data?.gb}
          </MobileDataText>
        </MobileData>
        <MobileData>
          <MobileDataLabel>Segmento</MobileDataLabel>
          <MobileDataText>
            {loading ? <Skeleton width={80} /> : data?.segmento}
          </MobileDataText>
        </MobileData>
      </Row>
      <Row>
        <MobileData>
          <MobileDataLabel>Exibir ecommerce</MobileDataLabel>
          <MobileDataText>
            {loading ? (
              <Skeleton width={80} />
            ) : (
              data && (
                <FlagToggle
                  data={data}
                  toggleValue="exibir_ecommerce"
                  toggleName="Exibir ecommerce"
                  toggleState={showEcommerce}
                  setToggleState={setShowEcommerce}
                  updatePlan={updatePlan}
                />
              )
            )}
          </MobileDataText>
        </MobileData>
        <MobileData>
          <MobileDataLabel>Travar sincronia</MobileDataLabel>
          <MobileDataText>
            {loading ? (
              <Skeleton width={80} />
            ) : (
              data && (
                <FlagToggle
                  data={data}
                  toggleValue="travar_sincronia"
                  toggleName="Travar sincronia"
                  toggleState={lockSync}
                  setToggleState={setLockSync}
                  updatePlan={updatePlan}
                />
              )
            )}
          </MobileDataText>
        </MobileData>
      </Row>
      <Row>
        <MobileData>
          <MobileDataLabel>Status</MobileDataLabel>
          <MobileDataText>
            {loading ? (
              <Skeleton width={80} />
            ) : (
              data && (
                <FlagToggle
                  data={data}
                  toggleValue="ativo"
                  toggleName="Status"
                  toggleState={status}
                  setToggleState={setStatus}
                  updatePlan={updatePlan}
                />
              )
            )}
          </MobileDataText>
        </MobileData>
      </Row>
    </MobileContainer>
  );
}

export default MobileItem;
