import { GetApp, Search } from '@mui/icons-material';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { ButtonProgress } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { FormEvent, useState } from 'react';
import { FilterMargin } from './styles';

interface FiltersProps {
  setSearch(search: string): void;
  loading: boolean;
  isValid: boolean;
  handleSubmitReport(event: FormEvent<HTMLFormElement>): void;
}

function Filters({
  setSearch,
  loading,
  isValid,
  handleSubmitReport,
}: FiltersProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  const [localSearch, setLocalSearch] = useState('');
  return (
    <FilterMargin>
      <FormControl
        variant="outlined"
        margin="none"
        size="small"
        fullWidth={isTableOrLess}
      >
        <InputLabel>Buscar</InputLabel>
        <OutlinedInput
          value={localSearch}
          label="Buscar"
          onKeyUp={e => {
            if (e.key === 'Enter') {
              setSearch(localSearch);
            }
          }}
          onChange={e => {
            if (e.target.value === '') {
              setSearch('');
            }
            setLocalSearch(e.target.value as string);
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setSearch(localSearch);
                }}
                edge="end"
              >
                <Search />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <form onSubmit={handleSubmitReport} noValidate autoComplete="off">
        <Button
          className="button"
          type="submit"
          variant="outlined"
          color="primary"
          startIcon={<GetApp />}
          disabled={!isValid || loading}
        >
          <span>Exportar</span>
          {loading && <ButtonProgress size={22} />}
        </Button>
      </form>
    </FilterMargin>
  );
}

export default Filters;
