import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  TextField,
  TextFieldProps,
} from '@mui/material';
import * as Yup from 'yup';
import { ButtonProgress } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { Edit } from '@mui/icons-material';
import { useAuth } from 'hooks/auth';
import { vendaEditarStatus } from 'constants/permissions';
import { validateTel } from 'util/validator';
import ReactInputMask, { Props } from 'react-input-mask';
import { Sale } from '../types';

import { Container } from '../EditCustomerForm/style';

interface Erros {
  msisdn: string;
  provisorio: string;
  [x: string]: string;
}

const saleSchema = Yup.object().shape({
  msisdn: Yup.string().test('tel', 'Telefone inválido', value => {
    return validateTel(value || '');
  }),
  provisorio: Yup.string().when('showProvisorio', {
    is: true,
    then: Yup.string().test('tel', 'Telefone inválido', value => {
      return validateTel(value || '');
    }),
  }),
});

const defaultErros = {
  msisdn: '',
  provisorio: '',
};
interface EditSaleDataFormProps {
  sale: Sale;
  setSale(sale: Sale): void;
}

function EditSaleDataForm({ sale, setSale }: EditSaleDataFormProps) {
  const [status, setStatus] = useState(sale.status);
  const [protocolo, setProtocolo] = useState(sale.protocolo || '');
  const [provisorio, setProvisorio] = useState(sale.provisorio || '');
  const [msisdn, setMsisdn] = useState(sale.msisdn || '');
  const [showEditSaleData, setShowEditSaleData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSaleStatusEditButton, setShowhowSaleStatusEditButton] =
    useState(false);
  const [erros, setErros] = useState<Erros>(defaultErros);
  const [formIsValid, setFormIsValid] = useState(false);
  const [showProvisorio, setShowProvisorio] = useState(false);

  const { hasPermission } = useAuth();

  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  const { addToast } = useToast();

  useEffect(() => {
    setShowProvisorio(sale.tipo === 'PORTABILIDADE');
  }, [sale]);

  useEffect(() => {
    setFormIsValid(!!status);
  }, [status]);

  useEffect(() => {
    const shouldShow = hasPermission(vendaEditarStatus);

    setShowhowSaleStatusEditButton(shouldShow);
  }, [hasPermission]);

  const clearForm = useCallback(() => {
    setStatus(sale.status);
    setProtocolo(sale.protocolo || '');
    setMsisdn(sale.msisdn || '');
    setProvisorio(sale.provisorio || '');
    setErros(defaultErros);
  }, [sale]);

  const handleUpdate = (e: FormEvent): void => {
    e.preventDefault();
    setErros(defaultErros);

    saleSchema
      .validate(
        { msisdn, provisorio, showProvisorio },
        {
          abortEarly: false,
        },
      )
      .then(async () => {
        const dataResponse = {
          sale_form: {
            status,
            protocolo,
            provisorio,
            msisdn,
          },
        };
        setLoading(true);
        api
          .put(
            `/core/vendas/atualizar-dados-do-pedido/${sale.id}`,
            dataResponse,
          )
          .then(() => {
            setLoading(false);
            addToast({
              type: 'success',
              title: 'Edição concluída',
              description: 'Os dados do pedido foram atualizados com sucesso!',
            });

            setShowEditSaleData(false);
          })
          .catch(error => {
            let message = error.data.errors[0]?.message;
            if (!message) {
              message = error.data.message;
            }
            message &&
              addToast({
                type: 'error',
                title: 'Erro ao Atualizar os Dados do Pedido.',
                description: message,
              });
            setLoading(false);
          })
          .finally(() => {
            api
              .get(`core/vendas/${sale.id}`)
              .then(({ data }) => setSale({ ...sale, ...data }));
          });
      })
      .catch(err => {
        if (err instanceof Yup.ValidationError) {
          const newErros: Erros = { ...defaultErros };
          err.inner.forEach(error => {
            if (error.path) {
              newErros[error.path] = error.message;
            }
          });
          setErros(newErros);
        }
      });
  };

  return (
    <Container>
      <IconButton onClick={() => setShowEditSaleData(true)}>
        <Edit />
      </IconButton>
      <Dialog
        open={showEditSaleData}
        onClose={() => setShowEditSaleData(false)}
        aria-labelledby={`edit-sale_${sale.id}`}
        fullScreen={isTabletSizeOrSmaller}
      >
        <form>
          <Box padding="20px">
            <CardHeader
              id={`edit-sale_${sale.id}`}
              title="Editar informações do pedido"
              subheader="Atualização das informações da venda"
              style={{ padding: 10, paddingBottom: 19 }}
            />
            <CardContent>
              {sale && showSaleStatusEditButton && (
                <FormControl
                  variant="outlined"
                  margin="none"
                  size="small"
                  fullWidth
                >
                  <InputLabel htmlFor="status" focused>
                    Status
                  </InputLabel>
                  <Select
                    native
                    value={status}
                    onChange={e => setStatus(e.target.value as string)}
                    label="Status"
                    style={{ marginBottom: 15 }}
                  >
                    <option aria-label="Todos" value="" disabled />
                    <option value="NOVO">Novo</option>
                    <option value="EM_PROCESSAMENTO">Em processamento</option>
                    <option value="PENDENTE_ENVIO">Pendente envio</option>
                    <option value="ENVIADO">Enviado</option>
                    <option value="EM_TRANSPORTE_EXPRESSO">
                      Transporte Expresso
                    </option>
                    <option value="ATIVADO">Ativado</option>
                    <option value="APROVADO">Aprovado</option>
                    <option value="DIVERGENTE">Divergente</option>
                    <option value="CANCELADO">Cancelado</option>
                    <option value="RECUSADO">Recusado</option>
                    /atualizar-detalhe/
                    <option value="DEVOLVIDO">Devolvido</option>
                    <option value="AGUARDANDO_RETIRADA">
                      Aguardando Retirada
                    </option>
                    <option value="EXTRAVIADO">Extraviado</option>
                    <option value="ANALISE_FRAUDE">Análise Fraude</option>
                    <option value="LEGITIMO">Legítimo</option>
                    <option value="AGUARDANDO_JANELA">
                      Janela de Portabilidade
                    </option>
                    <option value="AGUARDANDO_PORTABILIDADE">
                      Aguardando Portabilidade
                    </option>
                    <option value="ANALISE_PORTABILIDADE">
                      Analíse de Portabilidade
                    </option>
                    <option value="LEAD_TRATAVEL">Lead Tratável</option>
                  </Select>
                </FormControl>
              )}
              <TextField
                value={protocolo}
                aria-readonly
                fullWidth
                label="Protocolo"
                margin="dense"
                name="protocolo"
                onKeyPress={event => {
                  if (/[0-9]/.test(event.key)) {
                    return;
                  }
                  event.preventDefault();
                }}
                onChange={e => setProtocolo(e.target.value)}
                variant="outlined"
                style={{ paddingBottom: 10 }}
              />
              {showProvisorio && (
                <ReactInputMask
                  mask="99 99999-9999"
                  value={provisorio}
                  onChange={e => setProvisorio(e.target.value)}
                >
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    InputProps => (
                      <TextField
                        aria-readonly
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        label="Provisório"
                        name="provisorio"
                        style={{ paddingBottom: 10 }}
                        error={!!erros.provisorio}
                        helperText={erros.provisorio}
                        {...InputProps}
                      />
                    )
                  }
                </ReactInputMask>
              )}

              <ReactInputMask
                mask="99 99999-9999"
                value={msisdn}
                onChange={e => setMsisdn(e.target.value)}
              >
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (inputProps: Props & TextFieldProps) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Msisdn"
                      name="msisdn"
                      margin="dense"
                      style={{ paddingBottom: 10 }}
                      error={!!erros.msisdn}
                      helperText={erros.msisdn}
                      {...inputProps}
                    />
                  )
                }
              </ReactInputMask>
            </CardContent>
            <DialogActions
              style={{
                paddingTop: 14,
                justifyContent: 'space-between',
                paddingRight: 15,
                paddingLeft: 15,
              }}
            >
              <Button
                onClick={() => {
                  setShowEditSaleData(false);
                  clearForm();
                }}
                type="button"
                variant="contained"
                disabled={loading}
                style={{ backgroundColor: 'white', color: '#C92A2A' }}
              >
                Cancelar
              </Button>
              <Button
                onClick={handleUpdate}
                color="primary"
                type="button"
                variant="contained"
                disabled={!formIsValid || loading}
              >
                Salvar
                {loading && <ButtonProgress size={24} />}
              </Button>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </Container>
  );
}

export default EditSaleDataForm;
