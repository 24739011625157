import { useToast } from 'hooks/toast';
import { LPBenefit } from 'models/lpBenefits';
import CMSSlidingDrawer from 'pages/CmsLP/components/CMSSlidingDrawer';
import FormBenefit from 'pages/CmsLP/components/FormBenefit';
import { useCallback, useEffect, useState } from 'react';
import api from 'services/api';

interface EditBenefitProps {
  data: LPBenefit;
  closeDrawer(): void;
  updateBenefits(faqs: LPBenefit, showRevalid?: boolean): void;
}

function EditBenefit({ data, closeDrawer, updateBenefits }: EditBenefitProps) {
  const [title, setTitle] = useState(data.titulo || '');
  const [urlImage, setUrlImage] = useState(data.imagem_url || '');
  const [text, setText] = useState(data.texto || '');
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    setIsValid(!!(title && urlImage && text));
  }, [text, title, urlImage]);

  const handleEdit = useCallback(() => {
    setLoading(true);

    api
      .put(`/core/lp-beneficios/${data.id}`, {
        titulo: title,
        imagem_url: urlImage,
        texto: text,
      })
      .then(({ data: response }) => {
        updateBenefits({ ...data, ...response });

        addToast({
          type: 'success',
          title: 'Benefício editado',
          description: `O benefício ”${title}” foi editado  com sucesso.`,
        });

        closeDrawer();
      })
      .catch(error => {
        let { message } = error.data;

        if (!message) {
          message = error.data.errors[0]?.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao editar Benefício!',
            description: message,
          });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, closeDrawer, data, text, title, updateBenefits, urlImage]);

  return (
    <CMSSlidingDrawer
      title="Editando benefício"
      subtitle="Painel de edição de um benefício."
      closeDrawer={closeDrawer}
    >
      <FormBenefit
        title={title}
        setTitle={setTitle}
        urlImage={urlImage}
        setUrlImage={setUrlImage}
        text={text}
        setText={setText}
        loading={loading}
        isValid={isValid}
        handleBenefitRequest={handleEdit}
      />
    </CMSSlidingDrawer>
  );
}
export default EditBenefit;
