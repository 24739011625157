import userSWR from 'swr';
import api from 'services/api';
import { LoyaltyById } from 'models/loyaltyById';

export const LoyaltyByIdFetch = (id: number) => {
  const { data, mutate } = userSWR<LoyaltyById>(
    `loyalty_${id}`,
    async (): Promise<LoyaltyById> => {
      const { data: results } = await api.get(`/core/fidelidades/${id}`);

      return results as LoyaltyById;
    },
  );
  return { data, mutate };
};
