import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';

import React, { useState } from 'react';

import { Search } from '@mui/icons-material';
import useBreakpoint from 'hooks/useBreakpoint';
import { IntegrationErrorByTIMTypeEligibilityFetch } from 'fetches/integrationsErroFetch';
import {
  Container,
  Fieldset,
  FilterMargin,
  FilterReason,
  Input,
  Item,
  Label,
  Legend,
} from './styles';

interface FiltersProps {
  setSearch(search: string): void;
  treatmentReason: string;
  setTreatmentReason(treatmentReason: string): void;
}

function Filters({
  setSearch,
  treatmentReason,
  setTreatmentReason,
}: FiltersProps) {
  const { data: integrationsErrors } =
    IntegrationErrorByTIMTypeEligibilityFetch();
  const [localSearch, setLocalSearch] = useState('');
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);

  return (
    <Container>
      <FilterMargin>
        <FilterReason>
          <Item>
            <Input
              value={treatmentReason}
              list="motivos"
              onChange={e => setTreatmentReason(e.target.value)}
            />
            <Label htmlFor="motivos">Filtre por motivo</Label>
            <datalist id="motivos" style={{ height: 65, overflow: 'hidden' }}>
              {integrationsErrors?.map(option => (
                <option key={option.id} value={option.mensagem}>
                  {option.mensagem}
                </option>
              ))}
            </datalist>
            <Fieldset aria-hidden="true">
              <Legend>
                <span>Filtre por motivo</span>
              </Legend>
            </Fieldset>
          </Item>
        </FilterReason>

        <FormControl
          variant="outlined"
          margin="none"
          size="small"
          fullWidth={isTabletSizeOrSmaller}
        >
          <InputLabel>Buscar</InputLabel>
          <OutlinedInput
            label="Buscar"
            value={localSearch}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                setSearch(localSearch);
              }
            }}
            onChange={e => {
              if (e.target.value === '') {
                setSearch('');
              }
              setLocalSearch(e.target.value as string);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setSearch(localSearch);
                  }}
                  edge="end"
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </FilterMargin>
    </Container>
  );
}

export default Filters;
