import { createStyles, makeStyles } from '@mui/styles';
import { MTheme } from 'models/theme';
import styled from 'styled-components';

export const ButtonActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px;

  div {
    display: flex;
    gap: 15px;

    button {
      :nth-child(2) {
        width: 169px;
        background-color: #e5b300;
        color: white;
      }
    }

    @media (max-width: 1024px) {
      width: 100%;

      button {
        :nth-child(2) {
          width: 100%;
        }
      }
    }
  }
`;

export const useStyles = makeStyles((theme: MTheme) =>
  createStyles({
    root: {
      paddingTop: 20,
    },
    card: {
      padding: '0px 0px 20px 0px',
    },
    textFieldTakesUpHalfTheScreen: {
      width: '50%',
      paddingRight: '7px',
      [theme.breakpoints.down(900)]: {
        width: '100%',
        paddingRight: '0px',
      },
    },
    radioButtonsArea: {
      width: '50%',
      [theme.breakpoints.down(900)]: {
        width: '100%',
      },
    },
    radioButtons: {
      justifyContent: 'space-between',
      width: '100%',
      color: '#757575',
      '&.Mui-checked': {
        color: '#050C27',
      },
    },
    grid: {
      paddingTop: '9px',
    },
  }),
);
