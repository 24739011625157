import styled from 'styled-components';
import { withStyles } from '@mui/styles';
import { Button } from '@mui/material';

export const CancelPortabilityButton = withStyles(() => ({
  root: {
    color: 'red',
    padding: 15,
  },
}))(Button);

export const Container = styled.div`
  margin-top: auto;
`;
