import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  IconButton,
  TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import * as Yup from 'yup';

import { ButtonProgress } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { Edit } from '@mui/icons-material';
import { parseISO } from 'date-fns';
import { Sale } from '../types';

import { Container, TextInfo } from './style';

interface Erros {
  email: string;
  [x: string]: string;
}

const customerSchema = Yup.object().shape({
  email: Yup.string().email('Email inválido'),
});
const defaultErros = {
  email: '',
};
interface EditCustomerFormProps {
  sale: Sale;
  setSale(sale: Sale): void;
}

function EditCustomerForm({ sale, setSale }: EditCustomerFormProps) {
  const [nome, setNome] = useState(sale.customer.nome);
  const [filiacao, setFiliacao] = useState(sale.customer.filiacao);
  const [nascimento, setNascimento] = useState(() => {
    return typeof sale.customer.nascimento === 'string'
      ? new Date(sale.customer.nascimento)
      : sale.customer.nascimento;
  });
  const [email, setEmail] = useState(sale.customer.email);
  const [loading, setLoading] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [showEditCustomer, setShowEditCustomer] = useState(false);
  const [erros, setErros] = useState<Erros>(defaultErros);

  const emailIsValid = email === '' ? 'Campo email é obrigatório.' : ' ';

  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  const { addToast } = useToast();

  useEffect(() => {
    setFormIsValid(!!nome && !!filiacao && !!nascimento && !!email);
  }, [filiacao, nascimento, nome, email]);

  const clearForm = useCallback(() => {
    setNome(sale.customer.nome);
    setEmail(sale.customer.email);
    setFiliacao(sale.customer.filiacao);
    setNascimento(new Date(parseISO(sale.customer.nascimento)));
    setErros(defaultErros);
  }, [sale]);

  const handleUpdate = (e: FormEvent): void => {
    e.preventDefault();
    setErros(defaultErros);
    customerSchema
      .validate(
        { email },
        {
          abortEarly: false,
        },
      )
      .then(async () => {
        const dataResponse = {
          customer_form: {
            venda_id: sale.id,
            nome,
            email,
            filiacao,
            nascimento,
          },
        };
        setLoading(true);
        api
          .put(`/core/clientes/${sale.customer.id}`, dataResponse)
          .then(() => {
            setLoading(false);
            addToast({
              type: 'success',
              title: 'Edição concluída',
              description: 'Os dados do cliente foram atualizados com sucesso!',
            });

            setShowEditCustomer(false);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: 'Erro ao Atualizar o Cliente.',
              description:
                err.data && err.data.message ? err.data.message : err.message,
            });
            setLoading(false);
          })
          .finally(() => {
            api
              .get(`core/vendas/${sale.id}`)
              .then(({ data }) => setSale({ ...sale, ...data }));
          });
      })
      .catch(err => {
        if (err instanceof Yup.ValidationError) {
          const newErros: Erros = { ...defaultErros };
          err.inner.forEach(error => {
            if (error.path) {
              newErros[error.path] = error.message;
            }
          });
          setErros(newErros);
        }
      });
  };

  return (
    <Container>
      <IconButton onClick={() => setShowEditCustomer(true)}>
        <Edit />
      </IconButton>
      <Dialog
        open={showEditCustomer}
        onClose={() => setShowEditCustomer(false)}
        aria-labelledby={`edit-customer_${sale.id}`}
        fullScreen={isTabletSizeOrSmaller}
      >
        <Box padding="20px">
          <CardHeader
            id={`edit-customer_${sale.id}`}
            title="Editar dados do cliente"
            subheader="Atualização dos dados pessoais do cliente."
            style={{ padding: 10, paddingBottom: 19 }}
          />
          <CardContent>
            <TextField
              type="email"
              id={nome}
              value={nome}
              aria-readonly
              fullWidth
              label="Nome"
              margin="dense"
              name="nome"
              onChange={e => setNome(e.target.value)}
              variant="outlined"
              style={{ paddingBottom: 10 }}
              error={nome === ''}
              helperText={nome === '' ? 'Campo nome é obrigatório.' : ' '}
            />
            <TextField
              id={email}
              value={email}
              aria-readonly
              fullWidth
              label="Email"
              margin="dense"
              name="email"
              onChange={e => setEmail(e.target.value)}
              variant="outlined"
              style={{ paddingBottom: 10 }}
              error={!!erros.email || email === ''}
              helperText={erros.email ? erros.email : emailIsValid}
            />
            <TextField
              id={filiacao}
              value={filiacao}
              aria-readonly
              fullWidth
              label="Nome da mãe"
              margin="dense"
              name="filiacao"
              onChange={e => setFiliacao(e.target.value)}
              variant="outlined"
              style={{ paddingBottom: 10 }}
              error={filiacao === ''}
              helperText={
                filiacao === '' ? 'Campo nome da mãe é obrigatório.' : ' '
              }
            />
            <TextInfo>
              <DesktopDatePicker
                label="Data de Nascimento"
                inputFormat="dd/MM/yyyy"
                renderInput={params => <TextField {...params} fullWidth />}
                value={nascimento}
                // invalidDateMessage="Data inválida"
                onChange={e => setNascimento(e as Date)}
              />
            </TextInfo>
          </CardContent>
          <DialogActions
            style={{
              paddingTop: 14,
              justifyContent: 'space-between',
              paddingRight: 15,
              paddingLeft: 15,
            }}
          >
            <Button
              onClick={() => {
                setShowEditCustomer(false);
                clearForm();
              }}
              type="button"
              variant="contained"
              disabled={loading}
              style={{ backgroundColor: 'white', color: '#C92A2A' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleUpdate}
              color="primary"
              type="button"
              variant="contained"
              disabled={!formIsValid || loading}
            >
              Salvar
              {loading && <ButtonProgress size={24} />}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Container>
  );
}

export default EditCustomerForm;
