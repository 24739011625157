import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

export const DataText = styled.span`
  display: flex;
  justify-content: space-between;
  word-wrap: break-word;
  max-width: 400px;
  font-size: 14px;

  @media (max-width: 1400px) {
    max-width: 200px;
  }

  @media (max-width: 1280px) {
    max-width: 150px;
  }
`;

export const DataTextName = styled.div`
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  direction: ltr;
`;

export const ActionFAQMobile = styled.div`
  display: flex;
  position: absolute;
  top: 5px;
  right: 0;
`;

export const useStyles = makeStyles(() => ({
  icon: {
    color: 'rgba(204, 204, 204, 1)',
    display: 'flex',
    alignItems: 'center',
    minWidth: 30,
    '& svg': {
      height: 22,
      width: 22,
    },
  },
}));
