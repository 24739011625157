import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import useStyles from './styles';

interface StatusProps {
  status: string;
}

interface StatusConfType {
  name: string;
  color: string;
  backgroundColor: string;
}

interface statusConfListType {
  NOVO: StatusConfType;
  ATIVADO: StatusConfType;
  FALHOU: StatusConfType;
  [key: string]: StatusConfType;
}

const statusConfList: statusConfListType = {
  NOVO: {
    name: 'Novo',
    color: '#004966',
    backgroundColor: '#E3F5FC',
  },
  ATIVADO: {
    name: 'Ativado',
    color: '#006644',
    backgroundColor: '#E3FCEF',
  },
  FALHOU: {
    name: 'Falhou',
    color: '#660000',
    backgroundColor: '#FCE3E3',
  },
};

function Status({ status }: StatusProps) {
  const [statusConf, setStatusConf] = useState<StatusConfType>(() => {
    return (status && statusConfList[status]) || ({} as StatusConfType);
  });
  useEffect(() => {
    status && setStatusConf(statusConfList[status]);
  }, [status]);

  const classes = useStyles();

  return (
    <Box
      className={classes.status}
      style={{
        backgroundColor: statusConf.backgroundColor,
        color: statusConf.color,
      }}
    >
      <span>{statusConf.name}</span>
    </Box>
  );
}

export default Status;
