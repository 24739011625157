import useSWR from 'swr';
import api from 'services/api';
import { Plan } from 'models/plans';

interface Params {
  search?: string;
  segmento?: string;
  ativo?: string | null;
}
interface FetchProps {
  search?: string;
  segmento?: string;
  status?: string;
}

export const PlansFetch = ({ search, segmento, status }: FetchProps) => {
  const { data, mutate } = useSWR<Plan[]>(
    `plans_${search}_${status}_${segmento}`,
    async (): Promise<Plan[]> => {
      const params: Params = {};

      if (search) {
        params.search = search;
      }

      if (status) {
        params.ativo = status;
      }

      if (segmento) {
        params.segmento = segmento;
      }

      const { data: results } = await api.get(`/core/planos/todos`, {
        params,
      });

      return results as Plan[];
    },
  );

  return { data, mutate };
};
