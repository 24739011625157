import { List } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { FraudKeeper } from 'models/fraudKeeper';
import { Container } from 'components/CardList';
import Item from './Item';
import MobileItem from './MobileItem';
import { EmptyTable, Header } from './styles';

interface FraudKeeperListProps {
  fraudKeepers?: FraudKeeper[];
  loading: boolean;
}

interface LoadingListProps {
  isTabletSizeOrSmaller: boolean;
}

function LoadingList({ isTabletSizeOrSmaller }: LoadingListProps) {
  return isTabletSizeOrSmaller ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function FraudKeeperList({ fraudKeepers, loading }: FraudKeeperListProps) {
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);

  return (
    <Container>
      {!isTabletSizeOrSmaller && (
        <Header>
          <span>CPF</span>
          <span style={{ paddingLeft: 5 }}>Número da linha</span>
          <span>E-mail</span>
          <span style={{ paddingLeft: 85 }}>Motivo de fraude</span>
          <span style={{ paddingRight: 60 }}>Último pedido</span>
        </Header>
      )}

      {loading && <LoadingList isTabletSizeOrSmaller={isTabletSizeOrSmaller} />}

      <List>
        {fraudKeepers && fraudKeepers.length > 0 ? (
          fraudKeepers.map(data =>
            isTabletSizeOrSmaller ? (
              <MobileItem key={data.curadoria_id} data={data} />
            ) : (
              <Item key={data.curadoria_id} data={data} />
            ),
          )
        ) : (
          <EmptyTable>Não há curadorias disponíveis</EmptyTable>
        )}
      </List>
    </Container>
  );
}

export default FraudKeeperList;
