import { useState } from 'react';
import { format, getDaysInMonth, startOfMonth } from 'date-fns/esm';
import { ptBR } from 'date-fns/esm/locale';
import { AffiliateGoalDefaultParameter } from 'models/affiliateGoalDefaultParameter';
import { AffiliateGoal } from 'models/affiliateGoal';
import { NewAffiliateGoal } from '../types';
import { weekDays } from '../GoalList/weekDays';

interface UseAffiliateGoalProps {
  yearSelected: number;
  monthSelected: number;
  defaultParameters?: AffiliateGoalDefaultParameter;
  affiliates?: AffiliateGoal[];
}

export function UseAffiliateGoal({
  yearSelected,
  monthSelected,
  defaultParameters,
  affiliates,
}: UseAffiliateGoalProps) {
  const [goalSelected, setGoalSelected] = useState<NewAffiliateGoal>();
  const [showEditGoal, setShowEditGoal] = useState(false);

  const currentDate = format(new Date(), 'yyyy-MM-dd');

  const firstDateMonth = new Date(yearSelected, monthSelected - 1, 1);

  const monthInPt = format(firstDateMonth, 'LLLL', {
    locale: ptBR,
  });

  const daysInMonth = getDaysInMonth(new Date(yearSelected, monthSelected - 1));
  const firstDayOfMonth = startOfMonth(
    new Date(yearSelected, monthSelected - 1),
  );
  const startDayOfWeekIndex = weekDays.findIndex(
    day => day.number === firstDayOfMonth.getDay(),
  );

  const daysOfMonthByGoal: NewAffiliateGoal[] = [];
  const monthFormat = monthSelected < 10 ? `0${monthSelected}` : monthSelected;

  const defaultMeta = defaultParameters?.meta_padrao;

  for (let dayOfMonth = 0; dayOfMonth < daysInMonth; dayOfMonth++) {
    const currentDayOfWeekIndex = (dayOfMonth + startDayOfWeekIndex) % 7;
    const currentDayOfWeek = weekDays[currentDayOfWeekIndex];
    const currentDayOfMonth = dayOfMonth + 1;
    const dateFormat =
      currentDayOfMonth < 10 ? `0${currentDayOfMonth}` : currentDayOfMonth;

    const affiliate = affiliates?.find(a => a.dia === String(dateFormat));
    const meta = affiliate ? affiliate.meta : defaultMeta;
    const edited = !!affiliate?.editado;

    daysOfMonthByGoal.push({
      date: `${yearSelected}-${monthFormat}-${dateFormat}`,
      dayOfWeek: currentDayOfWeek?.day,
      dayOfMonth: currentDayOfMonth,
      meta: meta || 0,
      edited,
      column:
        currentDayOfWeek != null
          ? weekDays[currentDayOfWeek.number].number + 1
          : 1,
    });
  }

  return {
    goalSelected,
    setGoalSelected,
    showEditGoal,
    setShowEditGoal,
    monthInPt,
    currentDate,
    daysOfMonthByGoal,
  };
}
