import { IconButton, Skeleton } from '@mui/material';
import React, { useCallback, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { dateTime as dateFormatter } from 'util/formatter';

import {
  Badge,
  Container,
  Data,
  DataInfo,
  DataText,
  DataTextName,
  Divider,
} from './styles';

import ItemProps from './types';
import PopperActions from '../PopperActions';

function Item({ user, currentPage, loading }: ItemProps) {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElement(anchorElement ? null : event.currentTarget);
    },
    [anchorElement],
  );

  return (
    <Container>
      <Data>
        {loading ? (
          <>
            <DataText>
              <Skeleton width={210} />
            </DataText>
            <DataText>
              <Skeleton width={100} />
            </DataText>
            <DataText>
              <Skeleton width={100} style={{ marginLeft: 38 }} />
            </DataText>
            <DataText>
              <Skeleton width={120} style={{ marginLeft: 26 }} />
            </DataText>
            <DataText>
              <Skeleton width={100} style={{ marginLeft: 14 }} />
            </DataText>
          </>
        ) : (
          user && (
            <>
              <DataInfo>
                <DataTextName>{user.nome}</DataTextName>
                <DataTextName>{user.email}</DataTextName>
                <DataText>{user.role?.nome}</DataText>
                <DataText>{dateFormatter(user.ultimo_acesso)}</DataText>
                {user.ativo ? (
                  <Badge type="ativo">Ativo</Badge>
                ) : (
                  <Badge type="inativo">Inativo</Badge>
                )}
              </DataInfo>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <PopperActions
                anchorElement={anchorElement}
                handleClick={handleClick}
                user={user}
                currentPage={currentPage}
              />
            </>
          )
        )}
      </Data>
      <Divider />
    </Container>
  );
}

export default Item;
