import { AffiliateGoalByDate } from 'models/affiliateGoalByDate';
import api from 'services/api';
import useSWR from 'swr';

export const AffiliateGoalByDateFetch = (date: string) => {
  const { data, mutate } = useSWR<AffiliateGoalByDate>(
    `affiliate_${date}`,
    async (): Promise<AffiliateGoalByDate> => {
      const { data: affiliates } = await api.get(
        `/core/metas-afiliados/${date}`,
      );

      return affiliates as AffiliateGoalByDate;
    },
  );
  return { data, mutate };
};
