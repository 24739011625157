import { format } from 'date-fns';

export const cpf = (value?: string): string | undefined => {
  return value && value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export const cell = (value?: string): string | undefined => {
  return value && value.replace(/(\d{2})(\d)(\d{4})(\d{4})/, '($1) $2 $3-$4');
};

export const date = (value?: string): string | undefined => {
  return value && format(new Date(value), 'dd/MM/yyyy');
};

export const dateTime = (value?: string): string | undefined => {
  return value && format(new Date(value), 'dd/MM/yyyy - HH:mm');
};

export const hour = (value?: string): string | undefined => {
  return value && format(new Date(value), 'HH:mm');
};

export const cep = (value?: string): string | undefined => {
  return value && value.replace(/(\d{5})(\d{3})/, '$1-$2');
};

export const iccid = (value?: string): string | undefined => {
  return (
    value &&
    value.replace(/(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4 $5')
  );
};

export const capitalize = (value?: string) => {
  return value && value.charAt(0).toUpperCase() + value.slice(1);
};

export const convertBRToUSDate = (brDate: string) => {
  return brDate.split('/').reverse().join('-');
};

export const convertUSToBRDate = (usDate?: string) => {
  return usDate && usDate.split('-').reverse().join('/');
};

export const convertDateInString = (value: string): string => {
  const newDate = new Date(value);

  if (Number.isNaN(newDate.getTime())) {
    return 'Data inválida';
  }

  const day = String(newDate.getDate()).padStart(2, '0');
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  const year = newDate.getFullYear();
  const hours = String(newDate.getHours()).padStart(2, '0');
  const minutes = String(newDate.getMinutes()).padStart(2, '0');

  const formattedDate = `${day}/${month}/${year} às ${hours}:${minutes}`;

  return formattedDate;
};
