import { CardHeader, Divider } from '@mui/material';
import { Card } from 'components/StyledComponents';
import { LPOffersFetch } from 'fetches/lpOffersFetch';
import { useCallback, useEffect, useState } from 'react';
import { LPBenefitsFetch } from 'fetches/lpBenefitsFetch';
import { LPBenefit } from 'models/lpBenefits';
import { LPFAQsFetch } from 'fetches/lpFaqsFetch';
import { LPFAQ } from 'models/lpFaqs';
import FAQ from './FAQ';
import Offers from './Offers';
import Benefits from './Benefits';
import Baseboard from './Baseboard';

function CmsLP() {
  const { data: offersResponse, mutate: mutateOffers } = LPOffersFetch();
  const { data: faqsResponse, mutate: mutateFaqs } = LPFAQsFetch();
  const { data: benefitsResponse, mutate: mutateBenefits } = LPBenefitsFetch();

  const [benefitsList, setBenefitsList] = useState<LPBenefit[]>();

  useEffect(() => {
    setBenefitsList(benefitsResponse);
  }, [benefitsResponse]);

  const appendNewBenefit = useCallback(
    (benefit: LPBenefit) => {
      const newBenefit = benefitsList ? [...benefitsList, benefit] : [benefit];
      mutateBenefits(newBenefit, true);
    },
    [benefitsList, mutateBenefits],
  );

  const appendNewFAQ = useCallback(
    (faq: LPFAQ) => {
      const newFaq = faqsResponse ? [...faqsResponse, faq] : [faq];
      mutateFaqs(newFaq, true);
    },
    [faqsResponse, mutateFaqs],
  );

  return (
    <Card>
      <CardHeader
        title="CMS LP"
        subheader="Painel de classificação de ofertas na LP"
      />
      <Offers offers={offersResponse} appendNewOffer={mutateOffers} />

      <Divider style={{ margin: '30px 16px 15px 16px' }} />

      <Benefits
        benefits={benefitsList}
        setBenefits={setBenefitsList}
        appendNewBenefit={appendNewBenefit}
        updateBenefits={mutateBenefits}
      />

      <Divider style={{ margin: '30px 16px 15px 16px' }} />

      <FAQ
        faqs={faqsResponse}
        appendNewFAQ={appendNewFAQ}
        updateFAQs={mutateFaqs}
      />

      <Divider style={{ margin: '30px 16px 0px 16px' }} />

      <Baseboard offers={offersResponse} appendNewOffer={mutateOffers} />
    </Card>
  );
}

export default CmsLP;
