import palette from 'theme/palette';
import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';

const AuthTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: palette.info.light,
    },
    '& .MuiFormLabel-root': {
      color: 'rgba(255, 255, 255, 0.5)',
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#e53935',
    },
    '& .MuiFormLabel-root.Mui-error.Mui-focused': {
      color: palette.info.light,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.5)',
      },
    },
  },
})(TextField);
export default AuthTextField;
