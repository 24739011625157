import palette from '../palette';

export default function MuiTableCell() {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: palette.text.primary,
          fontSize: '16px',
          letterSpacing: '-0.05px',
          lineHeight: '21px',
          borderBottom: `1px solid ${palette.divider}`,
        },
      },
    },
  };
}
