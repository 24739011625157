import React, { useEffect, useState } from 'react';
import { CardHeader, Divider } from '@mui/material';

import { useAuth } from 'hooks/auth';
import { trocaSenha } from 'constants/permissions';
import UserInfo from './UserInfo';
import ResetPassword from './ResetPassword';

function Profile() {
  const { hasPermission } = useAuth();
  const [canEditPassword, setCanEditPassword] = useState(false);
  useEffect(() => {
    setCanEditPassword(hasPermission(trocaSenha));
  }, [hasPermission]);
  return (
    <form autoComplete="off" noValidate>
      <CardHeader subheader="Perfil do usuário" title="Perfil" />
      <Divider />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <UserInfo />
        {canEditPassword && <ResetPassword />}
      </div>
    </form>
  );
}

export default Profile;
