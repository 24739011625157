import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  padding: 15px 16px;
`;

export const CardError = styled.div`
  display: flex;
  height: 67px;
  justify-content: start;
  align-items: center;
  gap: 16px;
  padding: 20px 15px;
  border-radius: 6px;
  background-color: #fddede;
  color: #c53030;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 67px;
  gap: 4px;
  padding: 15px;
  border: 1px solid #daddec;
  border-radius: 6px;
`;

export const CardTitle = withStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.4)',
    lineHeight: '14px',
  },
})(Typography);

export const CardText = withStyles({
  root: {
    color: 'rgba(0, 64, 140, 1)',
    fontWeight: '500',
    lineHeight: '19px',
  },
})(Typography);
