import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import useBreakpoint from 'hooks/useBreakpoint';
import { useEffect, useState } from 'react';
import {
  BoxTextAreaImg,
  CardOffers,
  TextHelpOffers,
  TitleOffers,
  useStyles,
} from '../styles';
import { Plan } from '../types';

interface BestOfferProps {
  planos: Plan[];

  melhorOferta: string;

  setMelhorOferta(melhorOferta: string): void;

  melhorOfertaKv: string;

  setMelhorOfertaKv(melhorOfertaKv: string): void;

  sameFirstOfferChecked: boolean;

  isChecked: boolean;

  handleChangeCheckbox(event: React.ChangeEvent<HTMLInputElement>): void;
}

function BestOffer({
  planos,
  melhorOferta,
  melhorOfertaKv,
  setMelhorOferta,
  setMelhorOfertaKv,
  sameFirstOfferChecked,
  isChecked,
  handleChangeCheckbox,
}: BestOfferProps) {
  const classes = useStyles();
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(900);

  useEffect(() => {
    setImageUrl(melhorOfertaKv);
  }, [melhorOfertaKv]);

  return (
    <div>
      <CardOffers>
        <TitleOffers>Melhor Oferta</TitleOffers>
        <Box className={classes.checkBox}>
          <FormControl variant="outlined" margin="none" size="small" fullWidth>
            <InputLabel>Selecione</InputLabel>
            {planos && (
              <Select
                native
                value={melhorOferta}
                onChange={e => {
                  setMelhorOferta(e.target.value as string);
                }}
                label="Selecione"
                disabled={isChecked}
              >
                {planos.map(plan => (
                  <option key={plan.id} aria-label="plan" value={plan.tim_id}>
                    {plan.tim_id} - {plan.nome}
                  </option>
                ))}
              </Select>
            )}
          </FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sameFirstOfferChecked}
                  onChange={handleChangeCheckbox}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Igual a primeira oferta"
            />
          </FormGroup>
        </Box>
        <TextField
          fullWidth
          label="Imagem"
          margin="dense"
          name="imagem"
          value={melhorOfertaKv || ''}
          onChange={e => {
            setMelhorOfertaKv(e.target.value);
          }}
          size="small"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Url"
          InputProps={{
            classes: { input: classes.input },
          }}
        />
        <TextHelpOffers>Tamanho recomendado: 322x335px</TextHelpOffers>
      </CardOffers>

      {imageUrl && (
        <Box className={classes.boxAreaImg}>
          <Card className={classes.cardImg}>
            <img
              src={imageUrl}
              alt=""
              height={isTableOrLess ? '304px' : '335px'}
            />
          </Card>
          <BoxTextAreaImg>
            <Typography variant="subtitle2" className={classes.subtitleImg}>
              Simulação
            </Typography>
          </BoxTextAreaImg>
        </Box>
      )}
    </div>
  );
}

export default BestOffer;
