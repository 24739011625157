import { Button, Popover, TextField } from '@mui/material';
import { useSearchFieldsFilterStore } from 'store/searchFieldsFilterStore';
import { Container } from './styles';

interface SearchTransactionsActionsProps {
  id: string;
  openPopover: boolean;
  anchorEl: null | HTMLElement;
  handleClick(event: React.MouseEvent<HTMLElement>): void;
  onFilterSearchFields(): void;
  onClose(): void;
}

function SearchTransactionsActions({
  id,
  openPopover,
  anchorEl,
  handleClick,
  onFilterSearchFields,
  onClose,
}: SearchTransactionsActionsProps) {
  const msisdn = useSearchFieldsFilterStore(state => state.msisdn);
  const setMsisdn = useSearchFieldsFilterStore(state => state.setMsisdn);

  const provisorio = useSearchFieldsFilterStore(state => state.provisorio);
  const setProvisorio = useSearchFieldsFilterStore(
    state => state.setProvisorio,
  );

  const iccid = useSearchFieldsFilterStore(state => state.iccid);
  const setIccid = useSearchFieldsFilterStore(state => state.setIccid);

  const rastreamento = useSearchFieldsFilterStore(state => state.rastreamento);
  const setRastreamento = useSearchFieldsFilterStore(
    state => state.setRastreamento,
  );

  const protocolo = useSearchFieldsFilterStore(state => state.protocolo);
  const setProtocolo = useSearchFieldsFilterStore(state => state.setProtocolo);

  const cpf = useSearchFieldsFilterStore(state => state.cpf);
  const setCpf = useSearchFieldsFilterStore(state => state.setCpf);

  const nome = useSearchFieldsFilterStore(state => state.nome);
  const setNome = useSearchFieldsFilterStore(state => state.setNome);

  const filiacao = useSearchFieldsFilterStore(state => state.filiacao);
  const setFiliacao = useSearchFieldsFilterStore(state => state.setFiliacao);

  const email = useSearchFieldsFilterStore(state => state.email);
  const setEmail = useSearchFieldsFilterStore(state => state.setEmail);

  const rg = useSearchFieldsFilterStore(state => state.rg);
  const setRg = useSearchFieldsFilterStore(state => state.setRg);

  const token = useSearchFieldsFilterStore(state => state.token);
  const setToken = useSearchFieldsFilterStore(state => state.setToken);

  const utmMedium = useSearchFieldsFilterStore(state => state.utmMedium);
  const setUTMMedium = useSearchFieldsFilterStore(state => state.setUTMMedium);

  const utmCampaign = useSearchFieldsFilterStore(state => state.utmCampaign);
  const setUTMCampaign = useSearchFieldsFilterStore(
    state => state.setUTMCampaign,
  );

  const mensagemErro = useSearchFieldsFilterStore(state => state.mensagemErro);
  const setMensagemErro = useSearchFieldsFilterStore(
    state => state.setMensagemErro,
  );

  return (
    <Popover
      id={id}
      open={openPopover}
      onClose={handleClick}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Container>
        <TextField
          id="msisdn"
          label="MSISDN"
          variant="outlined"
          size="small"
          value={msisdn}
          onChange={e => setMsisdn(e.target.value)}
        />
        <TextField
          id="provisorio"
          label="Provisório"
          variant="outlined"
          size="small"
          value={provisorio}
          onChange={e => setProvisorio(e.target.value)}
        />
        <TextField
          id="iccid"
          label="ICCID"
          variant="outlined"
          size="small"
          value={iccid}
          onChange={e => setIccid(e.target.value)}
        />
        <TextField
          id="rastreamento"
          label="Rastreamento"
          variant="outlined"
          size="small"
          value={rastreamento}
          onChange={e => setRastreamento(e.target.value)}
        />
        <TextField
          id="protocolo"
          label="Protocolo"
          variant="outlined"
          size="small"
          value={protocolo}
          onChange={e => setProtocolo(e.target.value)}
        />
        <TextField
          id="cpf"
          label="CPF"
          variant="outlined"
          size="small"
          value={cpf}
          onChange={e => setCpf(e.target.value)}
        />
        <TextField
          id="nome"
          label="Nome"
          variant="outlined"
          size="small"
          value={nome}
          onChange={e => setNome(e.target.value)}
        />
        <TextField
          id="filiacao"
          label="Filiação"
          variant="outlined"
          size="small"
          value={filiacao}
          onChange={e => setFiliacao(e.target.value)}
        />
        <TextField
          id="email"
          label="E-mail"
          variant="outlined"
          size="small"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          id="rg"
          label="Rg"
          variant="outlined"
          size="small"
          value={rg}
          onChange={e => setRg(e.target.value)}
        />
        <TextField
          id="utm-medium"
          label="UTM Medium"
          variant="outlined"
          size="small"
          value={utmMedium}
          onChange={e => setUTMMedium(e.target.value)}
        />
        <TextField
          id="utm-campaign"
          label="UTM Campaign"
          variant="outlined"
          size="small"
          value={utmCampaign}
          onChange={e => setUTMCampaign(e.target.value)}
        />
        <TextField
          id="token"
          label="Token"
          variant="outlined"
          size="small"
          value={token}
          onChange={e => setToken(e.target.value)}
        />
        <TextField
          id="mensagem-erro"
          label="Mensagem de Erro"
          variant="outlined"
          size="small"
          value={mensagemErro}
          onChange={e => setMensagemErro(e.target.value)}
        />
        <Button
          variant="outlined"
          size="large"
          onClick={() => {
            onFilterSearchFields();
            onClose();
          }}
        >
          Buscar
        </Button>
      </Container>
    </Popover>
  );
}

export default SearchTransactionsActions;
