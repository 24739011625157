export interface ContextInfo {
  label: string;
  color: string;
}

interface ContextBadgeSetup {
  '/b2b/geographicAddressManagement/v1/geographicAddressManagement/address?postCode=': ContextInfo;
  '/b2b/salesProductOrdering/v1/productOrder': ContextInfo;
  '/b2b/salesProductOrdering/v1/productOrder/eligibility': ContextInfo;
  '/b2b/partnerIntegration/v1/transactionToken': ContextInfo;
  indefinido: ContextInfo;

  [key: string]: ContextInfo;
}

const contextBadgeSetup: ContextBadgeSetup = {
  '/b2b/geographicAddressManagement/v1/geographicAddressManagement/address?postCode=':
    {
      label: 'CEP',
      color: 'rgba(242, 246, 12, 0.39)',
    },
  '/b2b/salesProductOrdering/v1/productOrder': {
    label: 'Ordem',
    color: 'rgba(201, 48, 85, 0.2)',
  },
  '/b2b/salesProductOrdering/v1/productOrder/eligibility': {
    label: 'Elegibilidade',
    color: 'rgba(48, 174, 201, 0.2)',
  },
  '/b2b/partnerIntegration/v1/transactionToken': {
    label: 'Transação',
    color: 'rgba(162, 48, 201, 0.2)',
  },
  '/b2b/configurationManagement/v1/config/sales/content': {
    label: 'Domínio',
    color: 'rgba(195, 237, 217, 1)',
  },
  indefinido: {
    label: 'Indefinido',
    color: 'rgba(255, 168, 0, 0.31)',
  },
};

export const getContextBadgeSetup = (key: string) => {
  if (key.includes('address?postCode=')) {
    return contextBadgeSetup[
      '/b2b/geographicAddressManagement/v1/geographicAddressManagement/address?postCode='
    ];
  }
  return contextBadgeSetup[key] || contextBadgeSetup.indefinido;
};

export default contextBadgeSetup;
