import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 32px;
  width: 100%;
  border-collapse: collapse;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 70px 15px 37px;
  color: rgba(0, 0, 0, 0.4);
  span {
    :nth-child(1) {
      width: 300px;

      @media (min-width: 1290px) {
        width: 352px;
      }
    }
    :nth-child(2) {
      width: 115px;
    }
    :nth-child(5) {
      min-width: 80px;
      width: 100px;
    }
    min-width: 85px;
    white-space: nowrap;
    font-size: 12px;
    align-self: flex-start;
    font-weight: 400;
  }

  @media (min-width: 1320px) {
    margin: 0px 145px 15px 37px;
  }
`;
