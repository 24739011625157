import TotalizerCard from 'components/TotalizerCard';
import { SetupFetch } from '../../../../fetches/setupFetch';

interface SetupProps {
  isDesktop: boolean;
  chave: string;
  title: string;
}

function Setup({ isDesktop, chave, title }: SetupProps) {
  const { data: setup } = SetupFetch(chave);

  return (
    <TotalizerCard
      key={chave}
      title={title}
      total={setup?.valor === 'true' ? 'Ligado' : 'Desligado'}
      isDesktop={isDesktop}
    />
  );
}

export default Setup;
