import { Backdrop, Button, Dialog, DialogActions } from '@mui/material';
import { ButtonProgress } from 'components/StyledComponents';

import { ModalCard as Modal, UseStylesModal } from 'components/ModalCard';

interface CardCTAProps {
  title: string;
  subtitle: string;
  loading: boolean;
  openModal: boolean;
  setOpenModal(value: boolean): void;
  handleRequest(): void;
}

function CardCTA({
  title,
  subtitle,
  loading,
  openModal,
  setOpenModal,
  handleRequest,
}: CardCTAProps) {
  const classesModal = UseStylesModal();
  return (
    <Dialog
      aria-labelledby="tile-modal"
      aria-describedby="modal-description"
      className={classesModal.modal}
      open={openModal}
      onClose={() => setOpenModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 200,
      }}
    >
      <Modal className={classesModal.paper}>
        <h1
          style={{
            padding: 16,
          }}
          id="title-modal"
        >
          {title}
        </h1>
        <p
          style={{
            marginLeft: 16,
            marginRight: 16,
          }}
          id="modal-description"
        >
          {subtitle}
        </p>
        <DialogActions
          style={{
            paddingRight: 16,
            paddingTop: '24px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button onClick={() => setOpenModal(false)} style={{ color: 'red' }}>
            Não
          </Button>
          <Button
            onClick={() => {
              handleRequest();
            }}
            disabled={loading}
            color="primary"
            variant="contained"
          >
            Sim
            {loading && <ButtonProgress size={24} />}
          </Button>
        </DialogActions>
      </Modal>
    </Dialog>
  );
}

export default CardCTA;
