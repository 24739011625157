const copyToClipboard = (str: string, id?: string): boolean => {
  const textArea: HTMLTextAreaElement = document.createElement('textarea');

  const body: HTMLElement =
    (id && document.getElementById(id)) || document.body;

  textArea.value = str;
  textArea.setAttribute('readonly', '');
  textArea.style.position = 'absolute';
  textArea.style.left = '-9999px';

  body.appendChild(textArea);
  textArea.select();
  const copied = document.execCommand('copy');

  body.removeChild(textArea);
  return copied;
};
export default copyToClipboard;
