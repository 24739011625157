import userSWR from 'swr';
import api from 'services/api';
import { Role } from 'models/roles';

export const RoleByIdFetch = (id: number) => {
  const { data, mutate } = userSWR<Role>(
    `role_${id}`,
    async (): Promise<Role> => {
      const { data: results } = await api.get(`/core/funcoes/${id}`);

      return results as Role;
    },
  );
  return { data, mutate };
};
