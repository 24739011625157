import React, { ReactNode } from 'react';
import { ToastProvider } from './toast';
import { AuthProvider } from './auth';

interface AppProvider {
  children: ReactNode;
}
function AppProvider({ children }: AppProvider) {
  return (
    <AuthProvider>
      <ToastProvider>{children}</ToastProvider>
    </AuthProvider>
  );
}
export default AppProvider;
