import { Box } from '@mui/material';
import styled from 'styled-components';

export const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Alert = styled.div`
  padding: 20px 20px 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  background: #f5fcff;
`;

export const AlertText = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: #00408c;
`;
