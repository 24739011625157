import { Box, Button, Card, IconButton, Typography } from '@mui/material';
import { Label } from 'components/TextInfo';
import { useEligibilityStore } from 'store/eligibilityStore';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { ReactComponent as DownloadIcon } from 'assets/icons/small/download.svg';
import { dateTime } from 'util/formatter';
import { useStyles } from './styles';

function EligibilityResponse() {
  const classes = useStyles();
  const eligibility = useEligibilityStore(state => state.eligibilityResponse);
  const setEligibilityResponse = useEligibilityStore(
    state => state.setEligibilityResponse,
  );
  const [expandHighlighter, setExpandHighlighter] = useState(false);

  useEffect(() => {
    if (!eligibility) {
      setExpandHighlighter(false);
    }
  }, [eligibility]);

  useEffect(() => {
    return () => {
      setEligibilityResponse('');
    };
  }, [setEligibilityResponse]);

  const handleDownload = () => {
    const blob = new Blob([eligibility], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `elegibilidade_${dateTime(new Date().toString())}.json`);
  };

  return (
    <Box className={classes.eligibilityResponse}>
      <Card className={classes.card}>
        <Label>Retorno</Label>
        <Box className={classes.boxResponse}>
          {(eligibility && (
            <>
              <IconButton
                className={classes.downloadButton}
                onClick={handleDownload}
              >
                <DownloadIcon />
              </IconButton>
              <SyntaxHighlighter
                language="json"
                style={dracula}
                showLineNumbers
                wrapLongLines
                wrapLines
                className={
                  expandHighlighter
                    ? classes.expandedHighlighter
                    : classes.highlighter
                }
              >
                {eligibility}
              </SyntaxHighlighter>
              <Button
                className={classes.expandButton}
                onClick={() => setExpandHighlighter(!expandHighlighter)}
              >
                {expandHighlighter ? 'contrair' : 'expandir'}
              </Button>
            </>
          )) || <Typography>Execute uma elegibilidade primeiro</Typography>}
        </Box>
      </Card>
    </Box>
  );
}

export default EligibilityResponse;
