import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  gap: 15px;
  margin-top: -30px;
`;

export const ContentRow = styled.div`
  display: flex;
  gap: 15px;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;
