import { makeStyles } from '@mui/styles';

export const statusColors = {
  new: 'rgba(0, 0, 0, 0.4)',
  processing: '#2381F0',
  processed: '#2B8A3E',
  failed: '#C92A2A',
};

export const useStyles = makeStyles(() => ({
  status: {
    width: 90,
    display: 'flex',
    fontSize: 15,
  },
}));
