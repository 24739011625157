import { styled } from '@mui/material/styles';
import { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ checked }) => ({
  '.MuiRadio-root': checked
    ? {
        color: '#050C27',
      }
    : { color: '#757575' },
  '.MuiFormControlLabel-label': checked
    ? {
        color: '#050C27',
      }
    : { color: '#757575' },
}));

function MyFormControlRadioButton(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    // eslint-disable-next-line
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

export default MyFormControlRadioButton;
