import React, { useCallback, useEffect, useState } from 'react';
import { CardHeader, SwipeableDrawer } from '@mui/material';
import { SalesFetch } from 'fetches/salesFetch';
import { useSaleSelected } from 'store/saleSelected';
import api from 'services/api';
import useBreakpoint from 'hooks/useBreakpoint';
import SimplePagination from 'components/SimplePagination';
import { Sale } from 'models/sale';
import Filters from '../components/Filters';
import {
  Content,
  Header,
  SalesPagination,
} from '../components/styledComponents';
import InfoSale from '../components/InfoSale';
import { Sale as ISale } from '../components/InfoSale/types';
import CancelPortability from '../components/InfoSale/CancelPortability';
import SaleList from './SaleList';

function PortabilityInTreatment() {
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  const saleSelected = useSaleSelected(state => state.saleSelected);
  const setSalesSelected = useSaleSelected(state => state.setSalesSelected);
  const [showInfoSale, setShowInfoSale] = useState(false);
  const [sale, setSale] = useState<ISale>();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [uf, setUf] = useState('');

  const { data: response, mutate } = SalesFetch({
    page: currentPage,
    tipo: 'PORTABILIDADE',
    status: '',
    uf,
    search,
    order: 'vendas.data_portabilidade',
    orderType: 'ASC',
    tratamento_portabilidade: '1',
  });

  useEffect(() => {
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    setShowInfoSale(!!saleSelected);
    if (saleSelected) {
      setSale(undefined);
      api.get(`/core/vendas/${saleSelected}`).then(({ data }) => {
        setSale(data);
      });
    }
  }, [saleSelected]);

  const removeSale = useCallback(() => {
    if (!response) {
      return;
    }

    const newListSale = response?.sales.filter(
      (item: Sale) => item.id !== sale?.id,
    );
    const newResponse = { ...response, ...{ sales: newListSale } };
    mutate(newResponse, true);
  }, [mutate, response, sale]);

  const closeDrawer = useCallback(() => {
    setSalesSelected(0);
    setShowInfoSale(false);
  }, [setSalesSelected]);

  const updateSelectedSale = (newSale: ISale) => {
    if (!response) {
      return;
    }

    const newListSale = response?.sales.map((item: Sale) => {
      if (item.id === newSale.id) {
        return {
          ...item,
          tratamento_portabilidade: newSale.tratamento_portabilidade
            ? newSale.tratamento_portabilidade
            : item.tratamento_portabilidade,
        };
      }
      return item;
    });
    const newResponse = { ...response, ...{ sales: newListSale } };
    mutate(newResponse, false);
  };

  return (
    <>
      <Header isDesktop={!isTabletSizeOrSmaller}>
        <CardHeader
          title="Portabilidade"
          subheader="Linhas com portabilidade em tratamento"
        />
        <Filters setSearch={setSearch} uf={uf} setUf={setUf} />
      </Header>
      <Content>
        <SaleList sales={response?.sales} />
        <SalesPagination>
          {response && (
            <SimplePagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isEmpty={response?.paginationInfo.isEmpty}
            />
          )}
        </SalesPagination>
      </Content>
      <SwipeableDrawer
        anchor="right"
        open={showInfoSale}
        onOpen={() => setShowInfoSale(true)}
        onClose={() => {
          closeDrawer();
        }}
      >
        {sale && (
          <>
            <InfoSale
              sale={sale}
              setSale={setSale}
              updateSelectedSale={updateSelectedSale}
              closeDrawer={closeDrawer}
              removeSale={removeSale}
              module="Treatment"
            />

            <CancelPortability
              sale={sale}
              setSale={setSale}
              closeDrawer={closeDrawer}
              updateSelectedSale={removeSale}
            />
          </>
        )}
      </SwipeableDrawer>
    </>
  );
}

export default PortabilityInTreatment;
