import React, { FormEvent, useEffect, useState } from 'react';
import {
  Button,
  CardContent,
  colors,
  TextField,
  TextFieldProps,
} from '@mui/material';
import InputMask, { Props } from 'react-input-mask';
import { cpf as cpfIsValid } from 'util/validator';
import ufs from 'constants/ufs.json';
import { ButtonProgress, Form } from 'components/StyledComponents';
import { useToast } from 'hooks/toast';
import api from 'services/api';
import { VirtualSeller } from 'models/virtualSeller';
import { Actions, GroupInput } from './styles';
import { UF } from '../types';

interface FormProps {
  expanded: boolean;
  setExpanded(value: boolean): void;
  appendVirtualSeller(virtualSeller: VirtualSeller): void;
}
const url = '/core/vendedores-virtuais';
function FormAddVirtualSeller({ setExpanded, appendVirtualSeller }: FormProps) {
  const [cpf, setCpf] = useState('');
  const [custcode, setCustcode] = useState('');
  const [uf, setUf] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    setIsValid(!!(cpf && cpfIsValid(cpf) && uf && custcode));
  }, [cpf, uf, custcode]);

  const clearForm = () => {
    setExpanded(false);
    setCpf('');
    setUf('');
    setCustcode('');
    setSaveLoading(false);
  };
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSaveLoading(true);
    const data = {
      seller_form: {
        cpf: cpf.replace(/[^0-9]+/g, ''),
        uf,
        custcode,
      },
    };
    api
      .post(url, data)
      .then(({ data: virtualSeller }) => {
        clearForm();
        appendVirtualSeller(virtualSeller);
        addToast({
          type: 'success',
          title: 'Vendedor Cadastrado',
          description: 'Novo vendedor virtual cadastrado com sucesso',
        });
      })
      .catch(err => {
        setSaveLoading(false);
        addToast({
          type: 'error',
          title: 'Erro ao criar venderdor Virtual',
          description: err?.data?.message,
        });
      });
  };

  const handleCancel = () => {
    clearForm();
  };

  return (
    <CardContent>
      <Form onSubmit={handleSubmit} noValidate autoComplete="off">
        <div style={{ width: 600 }}>
          <InputMask
            mask="999.999.999-99"
            maskChar=""
            value={cpf}
            onChange={e => setCpf(e.target.value)}
          >
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (inputProps: Props & TextFieldProps) => (
                <TextField
                  {...inputProps}
                  variant="outlined"
                  type="text"
                  label="CPF"
                  fullWidth
                  error={cpf ? !cpfIsValid(cpf) : false}
                  style={{ marginTop: 10 }}
                />
              )
            }
          </InputMask>
          <GroupInput>
            <TextField
              id="uf"
              label="UF"
              variant="outlined"
              style={{ marginTop: 10 }}
              select
              value={uf}
              SelectProps={{ native: true }}
              onChange={e => setUf(e.target.value)}
            >
              <option value="0">UF</option>
              {ufs.map((option: UF) => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </TextField>

            <TextField
              id="custcode"
              label="Cust Code"
              variant="outlined"
              value={custcode}
              onChange={e => setCustcode(e.target.value)}
              style={{ marginTop: 10, marginLeft: 10, flex: 1 }}
            />
          </GroupInput>
        </div>

        <Actions>
          <Button
            onClick={handleCancel}
            style={{
              color: colors.red['500'],
              marginRight: 10,
            }}
          >
            Cancelar
          </Button>
          <Button
            disabled={!isValid || saveLoading}
            type="submit"
            variant="contained"
            color="primary"
          >
            Salvar
            {saveLoading && <ButtonProgress size={24} />}
          </Button>
        </Actions>
      </Form>
    </CardContent>
  );
}

export default FormAddVirtualSeller;
