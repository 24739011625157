import userSWR from 'swr';
import api from 'services/api';
import { Reason } from 'models/reasons';

export const TreatmentReasonsFetch = () => {
  const { data, mutate } = userSWR<Reason[]>(
    'motives',
    async (): Promise<Reason[]> => {
      const { data: response } = await api.get(
        '/core/vendas/motivos-tratamento',
      );
      return response as Reason[];
    },
  );
  return { data, mutate };
};
