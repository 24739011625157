import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import styled, { css } from 'styled-components';
import palette from 'theme/palette';

interface ButtonFilterProps {
  actived: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;

  .MuiSelect-root {
    text-align: start;
  }

  @media (max-width: 1023px) {
    padding-top: 0px;
    padding-bottom: 0px;
    justify-content: center;
    flex-direction: column;
  }
`;

export const ButtonFilter = styled.button.attrs({
  type: 'button', // eslint-disable-next-line
})<ButtonFilterProps>`
  border: none;
  background-color: transparent;
  padding: 10px 10px;
  font-size: 12px;
  line-height: 17px;
  width: 65px;
  text-align: center;
  color: #050c27;
  opacity: 0.7;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    background-color: #d0edfb;
  }

  ${props =>
    props.actived &&
    css`
      background-color: ${palette.primary.main};
      color: #fff;
      font-weight: bold;
      &:hover {
        background-color: ${palette.primary.main};
      }
    `}

  @media (max-width: 1023px) {
    margin-left: 0px;
  }
`;

export const FilterMargin = styled.div`
  display: flex;

  .select {
    margin-right: 16px;
    width: 200px;
  }

  @media (max-width: 1023px) {
    margin: 5px 0;
    width: 100%;
    text-align: center;
    align-self: center;
    justify-content: space-between;

    .select {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 16px;
    }
  }
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    startKeyboardDate: {
      [theme.breakpoints.down(1023)]: {
        width: '100%',
        marginRight: 10,
      },
      '& .MuiOutlinedInput-root': {
        height: 41,
        width: 165,
        fontSize: 12,
        marginRight: 24,
        [theme.breakpoints.down(1023)]: {
          width: '100%',
          marginBottom: 15,
        },
      },
      '& .MuiIconButton-root': {
        fontSize: 15,
      },
    },
    endKeyboardDate: {
      [theme.breakpoints.down(1023)]: {
        width: '100%',
      },
      '& .MuiOutlinedInput-root': {
        height: 41,
        width: 165,
        fontSize: 12,
        [theme.breakpoints.down(1023)]: {
          width: '100%',
          marginBottom: 15,
        },
      },
      '& .MuiIconButton-root': {
        fontSize: 15,
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 0.5,
      height: 16,
      fontSize: 12,
    },
    chip: {
      margin: 2,
      height: 20,
      fontSize: 12,
    },
  }),
);
