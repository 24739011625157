import { GetApp, NoteAddOutlined } from '@mui/icons-material';
import { Button, Grid, IconButton } from '@mui/material';
import {
  ButtonProgress,
  Form,
  useStylesActionButtons,
} from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { useRef, useState } from 'react';
import { useConfigurationPackageStore } from 'store/configurationPackageStore';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { downloadPdfFile } from 'util/blob';
import { CardFormPDF, TextFormPDF, TitleFormPDF } from './styles';

interface FormPDFProps {
  handleChangeUploadPDFAdicionalPackage(files: FileList | null): void;
  handleChangeUploadPDFC6(files: FileList | null): void;
}

function FormPDF({
  handleChangeUploadPDFAdicionalPackage,
  handleChangeUploadPDFC6,
}: FormPDFProps) {
  const fileRef = useRef<HTMLInputElement>(null);

  const configurationStore = useConfigurationPackageStore(
    state => state.configuration,
  );

  const [loadingAdicionalPackage, setLoadingAdicionalPackage] = useState(false);
  const [loadingC6, setLoadingC6] = useState(false);

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(900);

  const { addToast } = useToast();

  const classesUpload = useStylesActionButtons();

  const isSpacing = isTableOrLess ? 0 : 4;

  const handleDownload = (path: string, nome: string) => {
    const params = {
      id: configurationStore.id,
      path_regulamento: path,
      time: new Date().getTime(),
    };
    api
      .get('/core/pdf/download-regulamento', {
        responseType: 'blob',
        params,
      })
      .then(response => {
        const filename = `regulamento-${nome}`;

        const blob = new Blob([response.data], { type: 'application/pdf' });

        downloadPdfFile({ filename, blob });
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao baixar PDF.',
        });
      })
      .finally(() => {
        setLoadingC6(false);

        setLoadingAdicionalPackage(false);
      });
  };

  return (
    <Grid container spacing={isSpacing} alignItems="center">
      <Grid item md={6} xs={12}>
        <CardFormPDF>
          <Grid container item spacing={1} alignItems="end">
            <Grid
              item
              md={
                configurationStore.path_regulamento_pacotes_adicionais ? 8 : 12
              }
              xs={12}
            >
              <Grid container item spacing={1} flexDirection="column">
                <Grid item md={12}>
                  <TitleFormPDF>Regulamento Pacotes Adicionais</TitleFormPDF>
                </Grid>
                <Grid item md={12}>
                  <div>
                    <input
                      ref={fileRef}
                      accept=".pdf"
                      id="file-adicional-package"
                      type="file"
                      onChange={e => {
                        handleChangeUploadPDFAdicionalPackage(e.target.files);
                      }}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="file-adicional-package">
                      <Button
                        variant="outlined"
                        component="span"
                        className={`${
                          configurationStore.promotionalFile
                            ? classesUpload.pdfFile
                            : classesUpload.buttonUpload
                        }`}
                        fullWidth
                        endIcon={<NoteAddOutlined />}
                      >
                        {configurationStore.promotionalFile
                          ? configurationStore.promotionalFile.name
                          : `Anexar arquivo`}
                      </Button>
                    </label>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {configurationStore.path_regulamento_pacotes_adicionais && (
              <Grid item md={4} xs={12}>
                <Grid
                  container
                  item
                  spacing={0}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent={isTableOrLess ? 'end' : 'center'}
                >
                  <Grid item md={8}>
                    <TextFormPDF>Baixar arquivo atual</TextFormPDF>
                  </Grid>
                  <Grid item md="auto">
                    <Form noValidate autoComplete="off">
                      <IconButton
                        type="submit"
                        color="primary"
                        disabled={loadingAdicionalPackage}
                        onClick={() => {
                          setLoadingAdicionalPackage(true);
                          handleDownload(
                            'path_regulamento_pacotes_adicionais',
                            'pacotes-adicionais',
                          );
                        }}
                      >
                        <GetApp fontSize="medium" sx={{ color: '#b4b6be' }} />
                        {loadingAdicionalPackage && (
                          <ButtonProgress size={24} />
                        )}
                      </IconButton>
                    </Form>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardFormPDF>
      </Grid>
      <Grid item md={6} xs={12}>
        <CardFormPDF>
          <Grid container item spacing={1} alignItems="end">
            <Grid
              item
              md={configurationStore.path_regulamento_c6 ? 8 : 12}
              xs={12}
            >
              <Grid container item spacing={1} flexDirection="column">
                <Grid item md={12}>
                  <TitleFormPDF>Regulamento C6</TitleFormPDF>
                </Grid>
                <Grid item md={12}>
                  <div>
                    <input
                      ref={fileRef}
                      accept=".pdf"
                      id="file-c6"
                      type="file"
                      onChange={e => {
                        handleChangeUploadPDFC6(e.target.files);
                      }}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="file-c6">
                      <Button
                        variant="outlined"
                        component="span"
                        className={`${
                          configurationStore.c6File
                            ? classesUpload.pdfFile
                            : classesUpload.buttonUpload
                        }`}
                        fullWidth
                        endIcon={<NoteAddOutlined />}
                      >
                        {configurationStore.c6File
                          ? configurationStore.c6File.name
                          : `Anexar arquivo`}
                      </Button>
                    </label>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {configurationStore.path_regulamento_c6 && (
              <Grid item md={4} xs={12}>
                <Grid
                  container
                  item
                  spacing={0}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent={isTableOrLess ? 'end' : 'center'}
                >
                  <Grid item md={8}>
                    <TextFormPDF>Baixar arquivo atual</TextFormPDF>
                  </Grid>
                  <Grid item md="auto">
                    <Form noValidate autoComplete="off">
                      <IconButton
                        type="submit"
                        color="primary"
                        disabled={loadingC6}
                        onClick={() => {
                          setLoadingC6(true);
                          handleDownload('path_regulamento_c6', 'c6');
                        }}
                      >
                        <GetApp fontSize="medium" sx={{ color: '#b4b6be' }} />
                        {loadingC6 && <ButtonProgress size={24} />}
                      </IconButton>
                    </Form>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardFormPDF>
      </Grid>
    </Grid>
  );
}

export default FormPDF;
