import React from 'react';
import { Box, Card, Grid, Typography } from '@mui/material';
import logo from 'assets/logo_grafana.svg';
import useStyles, { Logo } from './styles';

function Grafana() {
  const linkGrafana = process.env.REACT_APP_LINK_GRAFANA;
  const linkProps = {
    href: linkGrafana,
    target: '_blank',
  };

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <a {...linkProps}>
        <Box className={classes.dividerTop} />
        <Grid container justifyContent="flex-start">
          <Grid item>
            <Box className={classes.box}>
              <Logo src={logo} alt="Logo Grafana" className={classes.icon} />
            </Box>
          </Grid>
          <Grid item style={{ padding: '15px 0px 0px 15px' }}>
            <Typography className={classes.title} variant="h4">
              Grafana
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle1">
              Sistema de visualização de métricas das vendas
            </Typography>
          </Grid>
        </Grid>
      </a>
    </Card>
  );
}

export default Grafana;
