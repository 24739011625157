import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import useBreakpoint from 'hooks/useBreakpoint';
import { Package } from 'models/packages';
import { useEffect, useState } from 'react';

interface DialogPlanPackageProps {
  data?: Package[];
  isOpen: boolean;
  onUpdate?: (value: string) => void;
  onClose: () => void;
}

function DialogPlanPackage({
  data,
  isOpen,
  onUpdate,
  onClose,
}: DialogPlanPackageProps) {
  const [packageSelected, setPackageSelected] = useState('');
  const [categorySelected, setCategorySelected] = useState('');
  const [packagesFilteredByCategory, setPackagesFilteredByCategory] = useState<
    Package[]
  >([]);
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(!!(packageSelected && categorySelected));
  }, [packageSelected, categorySelected]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (categorySelected === 'FLASHSALE') {
      const packages = data.filter(pacote => pacote.categoria === 'FLASHSALE');

      setPackagesFilteredByCategory(packages);
    }
    if (categorySelected === 'C6') {
      const packages = data.filter(pacote => pacote.categoria === 'C6');

      setPackagesFilteredByCategory(packages);
    }
    if (categorySelected === 'PORTABILIDADE') {
      const packages = data.filter(
        pacote => pacote.categoria === 'PORTABILIDADE',
      );

      setPackagesFilteredByCategory(packages);
    }
  }, [categorySelected, data]);

  const clearForm = () => {
    setPackageSelected('');
    setCategorySelected('');
  };
  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      style={{ height: isTableOrLess ? '80%' : '60%' }}
    >
      <DialogTitle
        id="form-dialog-title"
        style={{ padding: '16px 24px 0px 24px' }}
      >
        Anexar Pacote Tim
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 30 }}>
          Selecione um pacote que deseja adicionar ao plano
        </DialogContentText>
        <TextField
          label="Selecione uma categoria"
          fullWidth
          margin="dense"
          name="categoria"
          onChange={e => {
            setCategorySelected(e.target.value);
          }}
          select
          SelectProps={{ native: true }}
          variant="outlined"
          size="small"
        >
          <option aria-label="nenhum" value="" />
          <option aria-label="flashsale" value="FLASHSALE">
            FLASH SALE
          </option>
          <option aria-label="c6" value="C6">
            C6
          </option>
          <option aria-label="portabilidade" value="PORTABILIDADE">
            PORTABILIDADE
          </option>
        </TextField>

        <FormControl variant="outlined" margin="dense" size="small" fullWidth>
          <InputLabel htmlFor="pacote" focused shrink>
            Pacote
          </InputLabel>
          {data && (
            <Select
              value={packageSelected}
              label="Pacote"
              onChange={e => {
                setPackageSelected(e.target.value);
              }}
              input={<OutlinedInput id="pacote" label=" Pacote" notched />}
              disabled={!categorySelected}
              displayEmpty
              renderValue={(selected: string) => {
                if (selected === '') {
                  return (
                    <span style={{ color: 'rgba(0, 0, 0, 0.4)' }}>
                      Selecione um pacote
                    </span>
                  );
                }

                const packageFound = packagesFilteredByCategory.find(
                  pacote => String(pacote.id) === selected,
                );

                return (
                  <span>
                    {packageFound?.tim_id} - {packageFound?.nome}
                  </span>
                );
              }}
            >
              <MenuItem value="">
                <span style={{ color: 'rgba(0, 0, 0, 0.4)' }} />
              </MenuItem>
              {packagesFilteredByCategory.map(item => (
                <MenuItem key={item.id} value={String(item.id)}>
                  {item.tim_id} - {item.nome}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'space-between',
          paddingRight: '24px',
          paddingLeft: '16px',
        }}
      >
        <Button
          onClick={() => {
            onClose();
            clearForm();
          }}
          style={{ color: '#C92A2A' }}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => {
            onUpdate?.(packageSelected);
            setPackageSelected(String(data?.[0].id));
            clearForm();
          }}
          variant="contained"
          color="primary"
          disabled={!isValid}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogPlanPackage;
