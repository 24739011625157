import { useState } from 'react';
import { Button, Card, CardContent, CardHeader } from '@mui/material';
import { UsersFetch } from 'fetches/usersFetch';
import Pagination from 'components/Pagination';
import { Add } from '@mui/icons-material';
import useBreakpoint from 'hooks/useBreakpoint';
import palette from 'theme/palette';
import { Header } from 'components/StyledComponents';
import FormAddUserMobile from './components/FormAddUserMobile';
import UserList from './components/UserList';

function ManagerUsers() {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  const [currentPage, setCurrentPage] = useState(1);
  const [showNewUserMobile, setShowNewUserMobile] = useState(false);
  const [search, setSearch] = useState('');

  const { data: usersResponse } = UsersFetch(currentPage, search);
  return (
    <Card>
      <Header>
        <CardHeader
          title="Gestão Usuário"
          subheader="Painel de cadastro de usuários no sistema"
        />
        {isTableOrLess && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowNewUserMobile(true)}
            style={{
              marginRight: 20,
            }}
          >
            <Add style={{ color: palette.white }} />
          </Button>
        )}
      </Header>

      <>
        <CardContent>
          <UserList
            users={usersResponse?.users}
            currentPage={currentPage}
            setSearch={setSearch}
          />
          {usersResponse && (
            <Pagination
              currentPage={currentPage}
              lastPage={usersResponse?.paginationInfo.lastPage}
              perPage={usersResponse?.paginationInfo.perPage}
              total={usersResponse?.paginationInfo.total}
              setCurrentPage={setCurrentPage}
            />
          )}
        </CardContent>
        <FormAddUserMobile
          showNewUserMobile={showNewUserMobile}
          setShowNewUserMobile={setShowNewUserMobile}
          currentPage={currentPage}
        />
      </>
    </Card>
  );
}

export default ManagerUsers;
