import { useFlagFilterStore } from 'store/flagFilterStore';

export function useFlags() {
  const withReprocessed = useFlagFilterStore(store => store.withReprocessed);
  const setWithReprocessed = useFlagFilterStore(
    store => store.setWithReprocessed,
  );
  const withManuallyProcessed = useFlagFilterStore(
    store => store.withManuallyProcessed,
  );
  const setWithManuallyProcessed = useFlagFilterStore(
    store => store.setWithManuallyProcessed,
  );
  const withAffiliates = useFlagFilterStore(store => store.withAffiliates);
  const setWithAffiliates = useFlagFilterStore(
    store => store.setWithAffiliates,
  );
  const withTreatables = useFlagFilterStore(store => store.withTreatables);
  const setWithTreatables = useFlagFilterStore(
    store => store.setWithTreatables,
  );
  const withPortabilityTreatment = useFlagFilterStore(
    store => store.withPortabilityTreatment,
  );
  const setWithPortabilityTreatment = useFlagFilterStore(
    store => store.setWithPortabilityTreatment,
  );
  const withExpressShipping = useFlagFilterStore(
    store => store.withExpressShipping,
  );
  const setWithExpressShipping = useFlagFilterStore(
    store => store.setWithExpressShipping,
  );
  const withOrderError = useFlagFilterStore(store => store.withOrderError);
  const setWithOrderError = useFlagFilterStore(
    store => store.setWithOrderError,
  );
  const withEngagement = useFlagFilterStore(store => store.withEngagement);
  const setWithEngagement = useFlagFilterStore(
    store => store.setWithEngagement,
  );

  const onlyNew = useFlagFilterStore(store => store.onlyNew);
  const setOnlyNew = useFlagFilterStore(store => store.setOnlyNew);

  const handleChangeWithReprocessed = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setWithReprocessed(event.target.checked);
  };

  const handleChangeWithManuallyProcessed = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setWithManuallyProcessed(event.target.checked);
  };

  const handleChangeWithAffiliates = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setWithAffiliates(event.target.checked);
  };

  const handleChangeWithTreatables = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setWithTreatables(event.target.checked);
  };

  const handleChangeWithPortabilityTreatment = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setWithPortabilityTreatment(event.target.checked);
  };

  const handleChangeWithExpressShipping = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setWithExpressShipping(event.target.checked);
  };

  const handleChangeWithOrderError = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setWithOrderError(event.target.checked);
  };

  const handleChangeWithEngagement = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setWithEngagement(event.target.checked);
  };

  const handleChangeOnlyNew = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyNew(event.target.checked);
  };

  return {
    withReprocessed,
    withManuallyProcessed,
    withAffiliates,
    withTreatables,
    withPortabilityTreatment,
    withExpressShipping,
    withOrderError,
    withEngagement,
    onlyNew,
    handleChangeWithReprocessed,
    handleChangeWithManuallyProcessed,
    handleChangeWithAffiliates,
    handleChangeWithTreatables,
    handleChangeWithPortabilityTreatment,
    handleChangeWithExpressShipping,
    handleChangeWithOrderError,
    handleChangeWithEngagement,
    handleChangeOnlyNew,
  };
}
