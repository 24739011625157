import create from 'zustand';

type PackageStore = {
  showPackageEdition: boolean;
  setShowPackageEdition(showPackageEdition: boolean): void;
  category: string | null;
  setCategory(category: string): void;
};

export const usePackageStore = create<PackageStore>(set => ({
  showPackageEdition: false,
  setShowPackageEdition(show: boolean) {
    set(state => ({ ...state, showPackageEdition: show }));
  },
  category: null,
  setCategory(category: string) {
    set(state => ({ ...state, category }));
  },
}));
